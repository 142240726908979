import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import { QuoteStatusEnum } from "../../enum/quote-status.enum";
import { AirlineResponseRateRequest } from "../../model/airline-response-rate-request.model";
import { AirlineResponseRate } from "../../model/airline-response-rate.model";
import { IAirlineBrand, IAirlineConfig } from "../../model/airline.model";
import { API_CORE } from "../../constant/api-core.const";
import { ErrorUtil } from "../../utils/error.util";

@Injectable({
  "providedIn": "root",
})
export class AirlineService {
  async getAirlines (): Promise<IAirlineBrand[]> {
    return API.get(API_CORE.name, API_CORE.URLs.Airline.Airlines, {}).catch(
      ErrorUtil.handleError
    );
  }
  async getAirlineConfig (
    airlineId: string,
    provider: string,
    country: string,
    status: QuoteStatusEnum
  ): Promise<IAirlineConfig> {
    if (provider.includes("MOCK")) {
      provider = "MOCK";
    }
    const extraOptions = {
      "queryStringParameters": {
        provider,
        country,
        status,
      },
    },
     url = API_CORE.URLs.Airline.AirlineConfig.replace(
      "{airlineId}",
      airlineId
    );
    return API.get(API_CORE.name, url, extraOptions).catch(
      ErrorUtil.handleError
    );
  }

  async calculateResponseRate (
    request: AirlineResponseRateRequest
  ): Promise<AirlineResponseRate[]> {
    const extraOptions = {
      "body": request,
    };
    return API.post(
      API_CORE.name,
      API_CORE.URLs.Airline.ResponseRate,
      extraOptions
    ).catch(ErrorUtil.handleError);
  }
}
