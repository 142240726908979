<ng-container *ngIf="isLoading; else showButton">
  <div class="spinner">
    <mat-spinner [diameter]="22" [strokeWidth]="3"></mat-spinner>
    <span i18n="@@global.request-in-progress">Request in progress</span>
  </div>
</ng-container>
<ng-template #showButton
  ><div
    class="request-button"
    [class.disabled]="disabled"
    [class.invalid]="!disabled && invalid"
  >
    <div>
      <span i18n="@@global.request">Request</span>
      <span i18n="@@global.booking">Booking</span>
    </div>
  </div></ng-template
>
