<div
  ngbDropdown
  placement="bottom-right"
  autoClose="outside"
  class="kt-header__topbar-item"
  *ngIf="userType && !isCargoWallet"
>
  <div ngbDropdownToggle class="kt-header__topbar-wrapper">
    <span class="kt-header__topbar-icon">
      <em class="far fa-question-circle"></em>
    </span>
  </div>

  <div
    ngbDropdownMenu
    class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
  >
    <div
      class="kt-user-card kt-user-card--skin-light kt-notification-item-padding-x"
    >
      <h3 class="title" i18n="@@help.help-title">Help</h3>
    </div>

    <div class="notification-wrapper" (click)="openNewTab('HELP_CENTER')">
      <div class="notification-avatar">
        <img src="/assets/media/icons/help-center.svg" alt="help-center-icon" />
      </div>
      <div class="notification-main">
        <span class="notification-title" i18n="@@help.help-center">
          Help Center
        </span>
        <span class="notification-description" i18n="@@help.help-center-link">
          CargoAi Help Center Link
        </span>
      </div>
    </div>
    <div class="notification-wrapper" (click)="openNewTab('FAQ')">
      <div class="notification-avatar">
        <img src="/assets/media/icons/faq.svg" alt="faq-icon" />
      </div>
      <div class="notification-main">
        <span class="notification-title" i18n="@@global.faq"> FAQ </span>
        <span
          class="notification-description"
          i18n="@@help.faq-help-center-link"
        >
          FAQ | CargoAi Help Center Link
        </span>
      </div>
    </div>
    <div class="notification-wrapper" (click)="openNewTab('CONTACT_US')">
      <div class="notification-avatar">
        <img src="/assets/media/icons/24-7.svg" alt="contact-us-icon" />
      </div>
      <div class="notification-main-title" i18n="@@global.contact-us">
        Contact Us
      </div>
    </div>
    <div *ngIf="productTours.length !== 0">
      <hr />
      <div class="row">
        <ng-container *ngFor="let tour of productTours; let index = index">
          <div class="tour-card" (click)="openNewTab('PRODUCT_TOUR', index)">
            <span class="tour-title">{{ tour.name }}</span>
            <div class="bottom-strip">
              <span class="duration">{{ tour.duration }}</span>
              <ng-container *ngIf="tour.watched">
                <div class="watched-tag">
                  <img
                    class="play-icon-circle"
                    src="/assets/media/icons/play-circle.svg"
                    alt=""
                  />
                  <ng-container i18n="@@help.watched">watched</ng-container>
                </div>
              </ng-container>
            </div>
            <div class="popup">
              <span class="play-icon">
                <img src="/assets/media/icons/play-icon.svg" alt="" />
              </span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
