import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  CommodityResultTypeEnum,
  CommodityService,
  ICommodity,
  ICommoditySearchResult,
  RequirementCodeEnum,
} from "@cai-services";
import Popper from "popper.js";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  Subject,
  takeWhile,
} from "rxjs";
import { CommoditySuggestions } from "../../constant/commodity-search.const";

@Component({
  "selector": "cai-commodity-search",
  "templateUrl": "./commodity-search.component.html",
  "styleUrls": ["./commodity-search.component.scss"],
})
export class CaiCommoditySearchComponent implements OnInit, OnChanges {
  @ViewChild("dropdown", { "read": TemplateRef }) dropdown: TemplateRef<any>;
  @ViewChild("input") input: ElementRef;
  @Input() noResultsText = "";
  @Input() label = "";
  @Input() viewOnly: boolean;
  @Input() required: boolean;
  @Input() maxWidth = 864;
  @Input() noUpdateOnBlur: boolean;
  @Input() selected: ICommoditySearchResult;
  @Output() selectedChange = new EventEmitter<ICommoditySearchResult>();

  display: string;
  options: ICommoditySearchResult[] = [];
  latestQuery: string;
  popperRef: Popper;
  view: EmbeddedViewRef<any>;
  touched: boolean;
  showNoResults: boolean;
  showSuggestions: boolean;
  commoditySearchTypes = CommodityResultTypeEnum;
  commoditySuggestions = CommoditySuggestions;
  readonly queryChange: Subject<string> = new Subject();

  constructor (
    @Self() private readonly el: ElementRef,
    private readonly vcr: ViewContainerRef,
    private readonly zone: NgZone,
    private readonly cdr: ChangeDetectorRef,
    private readonly commodityService: CommodityService,
  ) {}

  ngOnInit (): void {
    this.queryChange
      .pipe(
        debounceTime(300),
        map((query) => query.substring(0, 30).trim()),
        distinctUntilChanged(),
      )
      .subscribe((query) => {
        this.searchCommodity(query);
      });
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.hasOwnProperty("selected")) {
      this.setDisplay(this.selected);
    }
  }

  isOpen (): boolean {
    return !!this.popperRef;
  }

  enableSuggestions () {
    if (!this.display) {
      this.showSuggestions = true;
      this.showNoResults = false;
      this.open();
      this.cdr.detectChanges();
    }
  }

  changeQuery (text: string) {
    if (this.selected?.name !== text.trim()) {
      this.selected = null;
      this.queryChange.next(text);
    }
    this.cdr.detectChanges();
  }

  async searchCommodity (query: string) {
    this.options = [];
    this.latestQuery = query;
    this.close();
    this.cdr.detectChanges();
    if (query) {
      const res = await this.commodityService.searchCommodities(query);
      if (this.latestQuery === query) {
        this.options = res
          .filter((item) => item.object)
          .filter((item) => {
            if (item.type === CommodityResultTypeEnum.COMMODITY) {
              const commodity: ICommodity = item.object;
              return !(
                commodity.level === 1 &&
                commodity.name.toLowerCase() ===
                  RequirementCodeEnum.EXPRESS.toLowerCase()
              );
            }
            return true;
          });
        this.showNoResults = !this.options.length;
      }
      this.open();
      this.cdr.detectChanges();
    }
  }

  open () {
    if (this.isOpen()) {
      this.close();
    }
    if (
      this.input.nativeElement === document.activeElement &&
      (this.options.length || this.showNoResults || this.showSuggestions)
    ) {
      this.view = this.vcr.createEmbeddedView(this.dropdown);
      const dropdown = this.view.rootNodes[0],
       input = this.input.nativeElement,
       maxHeight =
        window.innerHeight -
        (this.el.nativeElement.getBoundingClientRect().top + window.scrollY);
      document.body.appendChild(dropdown);
      dropdown.style["z-index"] = "9999";
      dropdown.style["min-width"] = `${this.maxWidth}px`;
      dropdown.style["max-width"] = `${this.maxWidth}px`;
      dropdown.style["min-height"] = `${
        this.showNoResults || this.showSuggestions ? 140 : 0
      }px`;
      dropdown.style["max-height"] = `${
        this.showNoResults || this.showSuggestions ? 140 : maxHeight - 100
      }px`;
      dropdown.style["margin-right"] = "-150px";

      this.zone.runOutsideAngular(() => {
        this.popperRef = new Popper(input, dropdown, {
          "placement": "bottom",
          "removeOnDestroy": true,
          "modifiers": {
            "offset": {
              "enabled": true,
              "offset": "0, 10",
            },
            "preventOverflow": {
              "enabled": true,
              "boundariesElement": "viewport",
              "escapeWithReference": true,
            },
          },
        });
      });

      fromEvent(document, "click")
        .pipe(
          takeWhile(() => this.isOpen()),
          filter(({ target }) => {
            const origin = this.popperRef?.reference as HTMLElement;
            return !origin?.contains(target as HTMLElement);
          }),
        )
        .subscribe(() => {
          this.close();
        });
    }
  }

  close () {
    this.popperRef?.destroy();
    this.view?.destroy();
    this.popperRef = null;
    this.view = null;
    this.options = [];
    this.showSuggestions = false;
    this.showNoResults = false;
    this.cdr.detectChanges();
  }

  markAsTouched () {
    this.touched = true;
  }

  keyup ($event) {
    const key = $event.keyCode || $event.charCode;
    if (key === 9) {
      return;
    }
    this.markAsTouched();
  }

  blur () {
    if (!this.noUpdateOnBlur) {
      this.setDisplay(this.selected);
      this.selectedChange.emit(this.selected);
    }

    this.close();
    this.markAsTouched();
  }

  select (option: ICommoditySearchResult) {
    this.selected = option;
    this.setDisplay(option);
    this.selectedChange.emit(option);
  }

  setDisplay (option: ICommoditySearchResult) {
    if (option) {
      if (option.type === CommodityResultTypeEnum.COMMODITY) {
        this.display = option?.name;
      } else if (option.type === CommodityResultTypeEnum.DANGEROUS_GOOD) {
        this.display = option?.name ? `UN ${option.name}` : "";
      }
    } else {
      this.display = "";
    }
  }

  getGroupItems (groupItems: string): string[] {
    if (groupItems) {
      const descArr = groupItems.split(",");
      return descArr.map((d) => d.trim());
    }
    return [];
  }

  getSHCs (commodity: ICommodity): string[] {
    return commodity.specialHandlingCodes.map((shc) => shc.shcCode);
  }

  getSCRs (commodity: ICommodity): string[] {
    return commodity.scrs.map((shc) => shc.code);
  }

  getHS (commodity: ICommodity): string {
    return [commodity.globalHS, commodity.europeanHS]
      .filter((hs) => hs)
      .join(" ");
  }

  getHighlightedText (text: string): string {
    return text.replace(new RegExp(`(^|\\W)${this.display}`, "ig"), (match) => {
      if (match[0]) {
        const firstCharLetter = Boolean(match[0].match(/[a-zA-Z0-9]/));
        if (firstCharLetter) {
          return `<mark>${match}</mark>`;
        }
        return `${match[0]}<mark>${match.substring(1)}</mark>`;
      }
      return match;
    });
  }

  get boundingClientRect () {
    return this.el.nativeElement.getBoundingClientRect();
  }

  get errorMessage (): string {
    return `${this.label} is required`;
  }

  get showError (): boolean {
    return this.required && this.touched && !this.selected;
  }

  get isFocused (): boolean {
    return this.input?.nativeElement === document.activeElement;
  }
}
