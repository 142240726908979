import { Component, Input } from "@angular/core";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { OfficeService } from "@cai-services";
import Swal from "sweetalert2";
import { Office, User } from "../../../../core";
import { OfficeUtil } from "../../../../utils";

@Component({
  "selector": "cai-office-remove-user-modal",
  "templateUrl": "./office-remove-user-modal.component.html",
  "styleUrls": ["./office-remove-user-modal.component.scss"],
})
export class OfficeRemoveUserModal {
  @Input()
  activeOffice: Office;

  @Input()
  userToRemove: User;

  @Input()
  modalRef: NgbModalRef;
  @Input()
  isWalletApp: boolean;
  getLongOfficeName = OfficeUtil.getLongOfficeName;

  constructor (private officeService: OfficeService) {}

  async removeUserFromOffice (): Promise<void> {
    try {
      await this.officeService.removeUserFromOffice(
        this.activeOffice.id,
        this.userToRemove.userId,
      );
      Swal.fire({
        "text": $localize`:@@office-management.success.remove-office-member:Success! The user has been removed from the office.`,
        "icon": "success",
        "showCancelButton": false,
        "allowOutsideClick": false,
      });
    } catch {
      Swal.fire(
        "",
        $localize`:@@office-management.error.remove-office-member:Sorry, The user could not be removed from the office at this time. Please try again in 30 minutes, or reach out to us at support@cargoai.co`,
        "error",
      );
    }
    this.modalRef.close(true);
  }
}
