import {
  Component,
  Input,
  Self,
  Optional,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";

@Component({
  "selector": "kt-custom-input",
  "templateUrl": "./custom-input.component.html",
  "styleUrls": ["./custom-input.component.scss"],
})
export class CustomInputComponent implements ControlValueAccessor {
  @Input() placeholder: string;
  @Input() type: string;
  @Input() id: string;
  @Input() hasError: boolean;
  @Input() otherErrorMessage: string;
  @Input() linkMessage: string;
  @Input() linkUrl: string;
  @Input() autoSelect: boolean;
  @ViewChild("input") input: ElementRef;
  @Input() isWalletApp: boolean;

  constructor (
    @Self()
    @Optional()
    public control: NgControl,
    private readonly cdr: ChangeDetectorRef,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }
  _value: string;

  get value () {
    return this._value;
  }
  set value ($event: string) {
    this._value = $event;
    if (this.value) {
      this.onChange(this.value);
    }
    this.cdr.detectChanges();
  }

  onChange = (value: string) => {
    // This is intentional
  };
  onTouched = () => {
    // This is intentional
  };
  isDisabled: boolean;

  writeValue (value: string) {
    if (value) {
      this.value = value;
    } else {
      this.value = null;
    }
  }

  registerOnChange (onChange: () => any): void {
    this.onChange = onChange;
  }
  registerOnTouched (onTouched: () => any): void {
    this.onTouched = onTouched;
  }
  setDisabledState (isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public get errorMessage (): string {
    if (this.control?.errors) {
      const error = Object.values(this.control?.errors)[0].message || null;
      if (error) {
        return error;
      }
      return null;
    }
    return null;
  }

  public get invalid (): boolean {
    return this.control ? this.control.invalid : false;
  }

  public get showError (): boolean {
    if (!this.control) {
      return false;
    }
    const { dirty, touched } = this.control;
    return this.invalid ? dirty || touched : false;
  }

  handleClick (event) {
    if (this.autoSelect) {
      return (event.target as HTMLInputElement).select();
    }
    return null;
  }
}
