<div
  #wrapper
  class="unit-selector"
  [class.advanced-bookings-unit-selector]="isAdvancedBookings"
  [ngStyle]="{ width: width ? width + 'px' : 'auto' }"
>
  <div
    class="d-flex justify-content-between w-100"
    [class.metric]="isMetric"
    [class.imperial]="isImperial"
    [style.font-size.px]="fontSize"
    (click)="open(dropdown, wrapper)"
  >
    <span>{{ selected?.display }}</span>
    <span class="caret">
      <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.00787 9C7.36059 9 11.6848 2.34803 10.9072 1.59336C10.1296 0.838686 1.96048 0.766564 1.10857 1.59336C0.256657 2.4212 4.65623 9 6.00787 9Z"
          [attr.fill]="isMetric ? '#679EC6' : '#9f66ff'"
          [attr.stroke]="isMetric ? '#679EC6' : '#9f66ff'"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  </div>
  <ng-template #dropdown>
    <div class="unit-selector-menu">
      <ng-container *ngFor="let option of options">
        <span
          *ngIf="option?.code !== selected?.code"
          (click)="select(option)"
          [class.metric]="!isMetric"
          [class.imperial]="!isImperial"
          >{{ option.name }} ({{ option.display }})</span
        >
      </ng-container>
    </div>
  </ng-template>
</div>
