<form class="form personal-settings-form" [formGroup]="integrationsForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-lg-12">
      <div>
        <h2 i18n="@@settings.integrations">Integrations</h2>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="form-group col-12">
            <h2
              i18n="@@settings.allow-access-through"
              style="margin-bottom: 16px"
            >
              Allow access through:
            </h2>
            <div *ngFor="let tab of selectedTab; let last = last">
              <div class="row">
                <div class="col-6">
                  <span style="font-size: 16px; color: #4d578d">{{ tab }}</span>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <kt-custom-switch
                    formControlName="{{ tab }}"
                    [isActive]="isActive[tab]"
                    (change)="clickSwitch(tab)"
                  ></kt-custom-switch>
                </div>
              </div>
              <hr *ngIf="!last" />
            </div>
            <div class="row" style="margin-top: 20px">
              <img
                class="ml-2"
                src="/assets/media/icons/info.svg"
                alt="info icon"
              />
              <div
                class="ml-2"
                i18n="
                  @@settings.text-enabling-the-access-to-third-party-providers"
              >
                Enabling the toggle will grant access to third-party providers.
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        [disabled]="!integrationsForm.dirty || !integrationsForm.valid"
        [ngClass]="{
          'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
            loading
        }"
        class="btn btn-save"
        i18n="@@global.save-changes"
      >
        Save Changes
      </button>
    </div>
  </div>
  <!--end::Body-->
</form>
<!--end::Form-->
