import { ChangeDetectorRef, Component, Input, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs/internal/Subscription";
import { Router } from "@angular/router";
import {
  NotificationService,
  AppNotificationTypeEnum,
  QuoteStatusEnum,
  UserTypeEnum,
} from "@cai-services";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/en-gb";
import { SessionService } from "../../core/_services/session.service";
import { RegisterService } from "../../core/_services/register.service";
import { AdvancedBookingsRequestService } from "../../core/_services/advanced-bookings.service";
import { Notification } from "../../core/_models/notification.model";

@Component({
  "selector": "kt-notification",
  "templateUrl": "./notification.component.html",
  "styleUrls": ["notification.component.scss"],
})
export class NotificationComponent implements OnInit, OnDestroy {
  // Show dot on top of the icon
  @Input() dot: string;

  // Show pulse on icon
  @Input() pulse: boolean;

  @Input() pulseLight: boolean;

  // Set icon class name
  @Input() icon = "flaticon2-bell-alarm-symbol";
  @Input() iconType: "" | "success";

  // Set true to icon as SVG or false as icon class
  @Input() useSVG: boolean;

  // Set bg image path
  @Input() bgImage: string;

  // Set skin color, default to light
  @Input() skin: "light" | "dark" = "light";

  @Input() type: "brand" | "success" = "success";

  userId: string;
  userGroup: string;
  notifications: Notification[];
  retrieveNotiError: boolean;
  message: string;
  showNotificationBadge = true;
  isNotificationTodaySet: boolean;
  isNotificationOlderSet: boolean;

  subscriptionNotifications: Subscription;

  /**
   * Component constructor
   *
   * @param sanitizer: DomSanitizer
   */
  constructor (
    private readonly sessionService: SessionService,
    private readonly notificationService: NotificationService,
    private readonly registerService: RegisterService,
    private readonly ref: ChangeDetectorRef,
    private readonly router: Router,
    private readonly advancedBookingsService: AdvancedBookingsRequestService
  ) {
    this.notifications = [];
  }

  async ngOnInit () {
    const currentUser = this.sessionService.getCurrentUser();
    if (!currentUser) {
      return;
    }
    this.userId = "" + currentUser.userId;
    this.userGroup = currentUser.type;
    this.notificationService
      .getNotifications(this.userId)
      .then((result) => {
        if (result.data.getNotifications) {
          this.notifications = result.data.getNotifications.filter(
            (item) => !!item.type && !!item.title
          );
          this.notifications = this.notifications.map((notification) => {
            notification.parameters = this.parseJSON(notification.parameters);
            return notification;
          });
          if (this.notifications.length > 20) {
            this.displayOnlyLatestPerQuote();
          }
          this.ref.detectChanges();
        }
      })
      .catch(() => {
        this.retrieveNotiError = true;
      });
    this.subscriptionNotifications = this.notificationService
      .subscribeNotifications(this.userId)
      .subscribe({
        "next": (newResult) => {
          const newNotification: Notification =
            newResult.value.data?.Notification;
          if (newNotification?.type === AppNotificationTypeEnum.USER_UPDATE) {
            this.onUserUpdate();
            this.ref.detectChanges();
            return;
          }
          if (newNotification.seen) {
            this.showNotificationBadge = false;
            this.ref.detectChanges();
            return;
          }
          if (newNotification?.push) {
            this.showNotificationBadge = true;

            if (
              newNotification.type === AppNotificationTypeEnum.QUOTE_PING_UPDATE
            ) {
              const parsedParameter = this.parseJSON(
                newNotification.parameters
              );
              this.onQuoteUpdate(parsedParameter);

              this.ref.detectChanges();
              return;
            }

            newNotification.parameters = this.parseJSON(
              newNotification.parameters
            );
            this.notifications = [newNotification, ...this.notifications];
            this.ref.detectChanges();
          }
        },
        "error": (error) => {
          console.warn(error);
        },
      });
  }

  displayOnlyLatestPerQuote () {
    const qIds: string[] = [],
     notifications: Notification[] = [];
    this.notifications.forEach((noti) => {
      const qId = this.getQuoteId(noti);
      if (!qIds.includes(qId)) {
        notifications.push(noti);
        qIds.push(qId);
      }
    });
    this.notifications = notifications;
  }

  ngOnDestroy () {
    if (this.subscriptionNotifications) {
      this.subscriptionNotifications.unsubscribe();
      this.ref.detach();
    }
  }

  get notificationUnread (): Notification[] {
    return (this.notifications || []).filter((noti) => !noti.read);
  }

  get notificationUnseen (): Notification[] {
    return (this.notifications || []).filter((noti) => !noti.seen);
  }

  async onUserUpdate (): Promise<void> {
    const newUser = await this.registerService.getUser();
    this.sessionService.setCurrentUser(newUser);
  }

  async onQuoteUpdate (parameters: any): Promise<void> {
    const updatedQuoteId = parameters?.id;
    if (updatedQuoteId) {
      this.advancedBookingsService.updatedShipmentStatus(updatedQuoteId);
      if (window.location.href.includes("shipment-details/" + updatedQuoteId)) {
        window.location.reload();
      }
    }
  }

  formatTimeFromNow (notification: Notification): string {
    const localMoment = moment;
    if (window.location.href?.indexOf("/fr/") >= 0) {
      localMoment.locale("fr");
    } else {
      localMoment.locale("en");
    }
    const timeFomNow = localMoment(
      notification.timestamp.toString().length === 10
        ? notification.timestamp * 1000
        : notification.timestamp
    ).fromNow();
    localMoment.locale("en");
    return timeFomNow;
  }

  updateNotification (timestamp: number, read: boolean, seen: boolean) {
    this.notificationService
      .updateNotification({
        "user": this.userId,
        timestamp,
        read,
        seen,
      })
      .then(() => {
        this.notifications.forEach((noti) => {
          if (noti.timestamp === timestamp) {
            noti.read = read;
            noti.seen = seen;
          }
        });

        this.ref.detectChanges();
      });
  }

  onMarkAllRead (): void {
    this.notificationUnread.forEach((noti) => {
      const { timestamp } = noti;
      this.updateNotification(timestamp, true, true);
    });
  }

  onSeenNotification (open: boolean): void {
    if (this.showNotificationBadge) {
      this.showNotificationBadge = false;
    }

    if (open) {
      this.notificationUnseen.forEach((noti) => {
        const { timestamp, read } = noti;
        this.updateNotification(timestamp, read || false, true);
      });
    }
  }

  getQuoteRequestedTitle (noti: Notification): string {
    if (noti?.parameters?.current?.quoteRequest?.forwarder) {
      return `${noti.parameters.current.quoteRequest.forwarder?.firstName} from ${noti.parameters.current.quoteRequest.forwarder?.company?.companyName} has requested a quote`;
    }
    return "Quote Requested";
  }

  getBookingRequestedTitle (noti: Notification): string {
    if (noti?.parameters?.current?.quoteRequest?.forwarder) {
      return `${noti.parameters.current.quoteRequest.forwarder?.firstName} from ${noti.parameters.current.quoteRequest.forwarder?.company?.companyName} has requested a booking`;
    }
    return "Booking Requested";
  }

  getChatMessageTitle (noti: Notification): string {
    if (noti?.parameters?.SenderType === UserTypeEnum.FORWARDER) {
      return `${noti?.parameters?.current?.quoteRequest?.forwarder?.firstName} from ${noti?.parameters?.current?.quoteRequest?.forwarder?.company?.companyName} has left a message in the chat`;
    } else if (noti?.parameters?.SenderType === UserTypeEnum.AIRLINE) {
      return (
        noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
        $localize`:@@notification.message-left: has left a message in the chat`
      );
    }
    return noti.title;
  }

  getBookingConfirmedTitle (noti: Notification): string {
    if (noti?.parameters?.current?.airlineBrand?.airlineCompanyName) {
      return (
        noti.parameters.current.airlineBrand.airlineCompanyName +
        $localize`:@@notification.booking-confirmed: has confirmed the booking`
      );
    }
    return "Booking Confirmed";
  }

  getBookingCancelledTitle (noti: Notification): string {
    if (noti?.parameters?.SenderType === UserTypeEnum.FORWARDER) {
      return `${noti?.parameters?.current?.quoteRequest?.forwarder?.firstName} from ${noti?.parameters?.current?.quoteRequest?.forwarder?.company?.companyName} has cancelled the booking`;
    } else if (noti?.parameters?.SenderType === UserTypeEnum.AIRLINE) {
      if (
        noti?.parameters?.former?.quoteStatus ===
        QuoteStatusEnum.CANCELLATION_REQUESTED
      ) {
        return (
          noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
          $localize`:@@notification.booking-cancelled-accepted: has confirmed the cancellation of the booking`
        );
      }
      return (
        noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
        $localize`:@@notification.booking-cancelled: has cancelled the booking`
      );
    }
    return "Booking Cancelled";
  }

  getBookingCancellationRequestedTitle (noti: Notification): string {
    if (noti?.parameters?.current?.quoteRequest?.forwarder) {
      return (
        noti.parameters.current.quoteRequest.forwarder?.firstName +
        $localize`:@@notification.booking-cancellation-requested: has requested to cancel the booking`
      );
    }
    return "Booking Cancellation Requested";
  }

  getBookingModificationRequestedTitle (noti: Notification): string {
    if (noti?.parameters?.current?.quoteRequest?.forwarder) {
      return (
        noti.parameters.current.quoteRequest.forwarder?.firstName +
        $localize`:@@notification.booking-modification-requested: has requested to modify the booking`
      );
    }
    return "Booking Modification Requested";
  }

  getBookingModificationAcceptedTitle (noti: Notification): string {
    if (noti?.parameters?.current?.airlineBrand?.airlineCompanyName) {
      return (
        noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
        $localize`:@@notification.booking-modification-accepted: has accepted the booking modification`
      );
    }
    return "Booking Modification Accepted";
  }

  getBookingModificationRejectedTitle (noti: Notification): string {
    if (noti?.parameters?.current?.airlineBrand?.airlineCompanyName) {
      return (
        noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
        $localize`:@@notification.booking-modification-rejected: has rejected the booking modification`
      );
    }
    return "Booking Modification Rejected";
  }

  getOptionTitle (noti: Notification): string {
    return (
      $localize`:@@notification.your-option-with:Your option with ` +
      noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
      (noti.type === AppNotificationTypeEnum.OPTION_PLACED
        ? $localize`:@@notification.has-been-placed: has been placed`
        : $localize`:@@notification.has-been-cancelled: has been cancelled`)
    );
  }

  formatNotificationTitle (noti: Notification): string {
    switch (noti.type) {
      case AppNotificationTypeEnum.AIRLINE_BOOKING_CONFIRMATION:
        return this.getBookingConfirmedTitle(noti);
      case AppNotificationTypeEnum.QUOTE_REQUEST:
        return this.getQuoteRequestedTitle(noti);
      case AppNotificationTypeEnum.BOOKING_REQUEST:
        return this.getBookingRequestedTitle(noti);
      case AppNotificationTypeEnum.BOOKING_CANCEL_REQUEST:
        return this.getBookingCancellationRequestedTitle(noti);
      case AppNotificationTypeEnum.BOOKING_MODIFY_REQUEST:
        return this.getBookingModificationRequestedTitle(noti);
      case AppNotificationTypeEnum.BOOKING_MODIFY_ACCEPT:
        return this.getBookingModificationAcceptedTitle(noti);
      case AppNotificationTypeEnum.BOOKING_MODIFY_REJECT:
        return this.getBookingModificationRejectedTitle(noti);
      case AppNotificationTypeEnum.QUOTE_UPDATE:
      case AppNotificationTypeEnum.QUOTE_ACCEPT:
      case AppNotificationTypeEnum.QUOTE_REJECT:
      case AppNotificationTypeEnum.BOOKING_UPDATE:
      case AppNotificationTypeEnum.BOOKING_REJECT:
      case AppNotificationTypeEnum.BOOKING_CANCEL:
      case AppNotificationTypeEnum.QUOTE_IN_TRANSIT:
      case AppNotificationTypeEnum.QUOTE_AT_DESTINATION:
      case AppNotificationTypeEnum.QUOTE_DELIVERED:
        return this.getQuoteUpdateTitle(noti);
      case AppNotificationTypeEnum.QUOTE_CHAT_MESSAGE:
      case AppNotificationTypeEnum.BOOKING_CHAT_MESSAGE:
        return this.getChatMessageTitle(noti);
      case AppNotificationTypeEnum.OPTION_PLACED:
      case AppNotificationTypeEnum.OPTION_CANCEL:
        return this.getOptionTitle(noti);
      case AppNotificationTypeEnum.CANCEL_REJECT:
        return (
          noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
          $localize`:@@notification.rejected-cancel: has rejected the cancellation request`
        );
      case AppNotificationTypeEnum.FWB_ACCEPTED:
        return (
          noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
          $localize`:@@notification.master-awb-accepted: has accepted the Master AWB`
        );
      case AppNotificationTypeEnum.FWB_REJECTED:
        return (
          noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
          $localize`:@@notification.master-awb-rejected: has rejected the Master AWB`
        );
      case AppNotificationTypeEnum.FHL_ACCEPTED:
        return (
          noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
          $localize`:@@notification.house-awb-accepted: has accepted the HOUSE AWB ` +
          noti?.parameters?.houseAwb
        );
      case AppNotificationTypeEnum.FHL_REJECTED:
        return (
          noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
          $localize`:@@notification.house-awb-rejected: has rejected the HOUSE AWB ` +
          noti?.parameters?.houseAwb
        );
      default:
        return null;
    }
  }

  getQuoteUpdateTitle (noti: Notification): string {
    if (
      [
        AppNotificationTypeEnum.QUOTE_UPDATE,
        AppNotificationTypeEnum.BOOKING_UPDATE,
      ].includes(noti.type)
    ) {
      return this.getQuoteUpdateDetails(noti);
    }

    const quoteStatus = this.getQuoteStatus(noti);
    switch (quoteStatus) {
      case QuoteStatusEnum.ACCEPTED:
        return (
          noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
          $localize`:@@notification.accepted-the-quote: has accepted the quote`
        );
      case QuoteStatusEnum.REJECTED:
        return (
          noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
          $localize`:@@notification.rejected-the-quote: has rejected the quote`
        );
      case QuoteStatusEnum.BOOKING_REJECTED:
        return (
          noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
          $localize`:@@notification.rejected-the-booking: has rejected the booking`
        );
      case QuoteStatusEnum.CANCELLATION_REQUESTED:
      case QuoteStatusEnum.CANCELLED:
        return this.getBookingCancelledTitle(noti);
      case QuoteStatusEnum.IN_TRANSIT:
        return (
          $localize`:@@notification.your-shipment:Your shipment with ` +
          noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
          $localize`:@@notification.in-transit: is in 'In Transit'`
        );
      case QuoteStatusEnum.AT_DESTINATION:
        return (
          $localize`:@@notification.your-shipment:Your shipment with ` +
          noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
          $localize`:@@notification.in-transit: has arrived at the destination`
        );
      case QuoteStatusEnum.DELIVERED:
        return (
          $localize`:@@notification.your-shipment:Your shipment with ` +
          noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
          $localize`:@@notification.delivered: has been Delivered`
        );
      default:
        return noti.title;
    }
  }

  getQuoteUpdateDetails (noti: Notification): string {
    const updates = [];
    noti?.description?.split(",").forEach((detail) => {
      switch (detail) {
        case AppNotificationTypeEnum.RATE_UPDATE:
          updates.push($localize`:@@notification.rate: rate`);
          break;
        case AppNotificationTypeEnum.DEPARTURE_UPDATE:
          updates.push(
            $localize`:@@notification.departure-date: departure date`
          );
          break;
        case AppNotificationTypeEnum.ARRIVAL_UPDATE:
          updates.push($localize`:@@notification.arrival-date: arrival date`);
          break;
        case AppNotificationTypeEnum.AIRCRAFT_UPDATE:
          updates.push($localize`:@@notification.aircraft-type: aircraft type`);
          break;
        case AppNotificationTypeEnum.FLIGHT_NUMBER_UPDATE:
          updates.push($localize`:@@notification.flight-number: flight number`);
          break;
        case AppNotificationTypeEnum.ORIGIN_UPDATE:
          updates.push(
            $localize`:@@notification.departure-airport: departure airport`
          );
          break;
        case AppNotificationTypeEnum.DESTINATION_UPDATE:
          updates.push(
            $localize`:@@notification.arrival-airport: arrival airport`
          );
          break;
        case AppNotificationTypeEnum.QUOTE_VALIDITY_UPDATE:
          updates.push(
            $localize`:@@notification.quote-validity: quote validity`
          );
          break;
        case AppNotificationTypeEnum.WEIGHT_VOLUME_PIECES_UPDATE:
          updates.push(
            $localize`:@@notification.total-weight-volume-pieces-information: total weight / volume / pieces information`
          );
          break;
        case AppNotificationTypeEnum.CHARGEABLE_WEIGHT_UPDATE:
          updates.push(
            $localize`:@@notification.chargeable-weight: chargeable weight`
          );
          break;
        case AppNotificationTypeEnum.ULD_UPDATE:
          updates.push($localize`:@@notification.uld-details: ULD details`);
          break;
        case AppNotificationTypeEnum.ALLOTMENT_UPDATE:
          updates.push($localize`:@@notification.allotment-id: Allotment ID`);
          break;
        case AppNotificationTypeEnum.DIMENSIONS_UPDATE:
          updates.push($localize`:@@notification.dimensions: dimensions`);
          break;
      }
    });

    if (updates.length === 0) {
      return (
        noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
        (noti?.type === AppNotificationTypeEnum.QUOTE_UPDATE
          ? $localize`:@@notification.quote-updated: has updated the quote`
          : $localize`:@@notification.booking-updated: has updated the booking`)
      );
    }

    return (
      noti?.parameters?.current?.airlineBrand?.airlineCompanyName +
      " has changed" +
      updates.join(",")
    );
  }

  formatBidInformation (noti: Notification): string {
    switch (noti.type) {
      case AppNotificationTypeEnum.AIRLINE_BOOKING_CONFIRMATION:
      case AppNotificationTypeEnum.BOOKING_UPDATE:
      case AppNotificationTypeEnum.QUOTE_REQUEST:
      case AppNotificationTypeEnum.BOOKING_REQUEST:
      case AppNotificationTypeEnum.QUOTE_IN_TRANSIT:
      case AppNotificationTypeEnum.QUOTE_AT_DESTINATION:
      case AppNotificationTypeEnum.QUOTE_DELIVERED:
      case AppNotificationTypeEnum.QUOTE_CHAT_MESSAGE:
      case AppNotificationTypeEnum.BOOKING_CHAT_MESSAGE:
      case AppNotificationTypeEnum.OPTION_PLACED:
        if (noti.parameters?.current?.trips) {
          const flights = noti.parameters?.current?.trips[0]?.flights;
          if (typeof flights !== "undefined" && flights.length > 0) {
            let route = "";
            for (let i = 0; i < flights.length; i++) {
              route += flights[i]?.originRegionAirport?.airportCode;
              route += " > ";
              if (i === flights.length - 1) {
                route += flights[i]?.destRegionAirport?.airportCode;
                break;
              }
            }
            return route;
          } else {
            const { originAirport, destinationAirport } =
              noti.parameters.current;
            return `${originAirport?.airportCode} > ${destinationAirport?.airportCode}`;
          }
        } else {
          return this.generateLegacyDescription(noti);
        }
      default:
        return null;
    }
  }

  getNotificationDate (noti: Notification): string {
    return noti.parameters.current?.trips[0]?.startDepartureDate;
  }

  private generateLegacyDescription (noti: Notification) {
    let listValue = {} as any;
    if (noti.parameters && noti.parameters.length) {
      listValue = noti.parameters.reduce((acc, curr) => {
        if (["origin", "destination", "departureDate"].includes(curr.key)) {
          acc[curr.key] = curr.value;
        }
        return acc;
      }, {});
    }
    if (!Object.keys(listValue).length) {
      return null;
    }

    return (
      " " +
      `<span>${listValue.origin} - ${listValue.destination}</span>` +
      " | " +
      `<span>${listValue.departureDate}</span>`
    );
  }

  getAvatar (noti: Notification): string {
    if (noti?.parameters?.current) {
      if (this.userGroup === UserTypeEnum.FORWARDER) {
        return `/static/airline-logos/${noti.parameters.current.airlineBrand.airlineCompanyCode}.png`;
      } else {
        const { logoHash, logoType } =
          noti.parameters.current.quoteRequest.forwarder.company;
        return `/static/company-logos/${logoHash}.${logoType}`;
      }
    }
    if (noti?.parameters?.length) {
      if (this.userGroup === UserTypeEnum.FORWARDER) {
        const airlineCode = noti.parameters.find(
          (item) => item.key === "airlineLogo"
        );
        if (airlineCode) {
          return `/static/airline-logos/${airlineCode.value}`;
        }
        console.warn(
          `Missing airlineLogo: ${airlineCode?.value} in notification: ${noti.type}`
        );
        return null;
      } else if (this.userGroup === UserTypeEnum.AIRLINE) {
        const companyLogo = noti.parameters.find(
          (item) => item.key === "forwarderLogo"
        );
        if (companyLogo) {
          return `/static/company-logos/${companyLogo?.value}`;
        }
        console.warn(
          `Missing companyLogo: ${companyLogo.value} in notification: ${noti.type}`
        );
        return null;
      } else {
        console.warn(`Group ${this.userGroup} not defined`);
        return null;
      }
    }
    return null;
  }

  onQuoteDetailWrapperClick (noti: Notification): void {
    if (this.router.url === this.getQuoteDetailLink(noti)) {
      this.sessionService.sameRouteNotificationQuoteSelected.next(
        +this.getQuoteId(noti)
      );
    } else {
      this.router.navigateByUrl(this.getQuoteDetailLink(noti));
    }
    this.updateNotification(noti.timestamp, true, true);
  }

  getQuoteDetailLink (noti: Notification): string {
    return `/shipment-details/${this.getQuoteId(noti)}`;
  }

  getQuoteId (noti: Notification): string {
    if (noti?.parameters?.current) {
      return noti.parameters.current.quoteId;
    }
    if (noti?.parameters?.length) {
      const quoteId = noti.parameters.find((item) => item.key === "quoteId");
      return quoteId && quoteId.value;
    }
  }

  getCommodity (noti: Notification): string {
    if (noti?.parameters?.current?.quoteRequest?.quoteItems?.length > 0) {
      return noti.parameters.current.quoteRequest.quoteItems[0].typeOfProduct
        ?.productCode;
    }
    return "";
  }

  getQuoteStatus (noti: Notification): string {
    const params = noti?.parameters;
    if (!params) {
      return undefined;
    }
    if (Array.isArray(params)) {
      const quoteStatus = params.find((item) => item.key === "quoteStatus");
      return quoteStatus?.value;
    }
    if (params?.current?.quoteStatus) {
      return params.current.quoteStatus;
    }
    return undefined;
  }

  getQuoteAWBNumber (noti: Notification): string {
    if (noti?.parameters?.current?.awb) {
      const awb = noti.parameters.current.awb;
      return `${awb.substring(0, 3)}-${awb.substring(3)}`;
    }
    return undefined;
  }

  backGroundStyle (): string {
    if (!this.bgImage) {
      return "none";
    }

    return "url(" + this.bgImage + ")";
  }

  getNotificationsList (): Notification[] {
    this.isNotificationTodaySet = false;
    this.isNotificationOlderSet = false;
    return this.notifications;
  }

  notificationToday (noti: Notification): boolean {
    const timeStamp = moment(
      noti.timestamp.toString().length === 10
        ? noti.timestamp * 1000
        : noti.timestamp
    ).fromNow(true);
    if (
      ["seconds", "minute", "minutes", "hour", "hours"].some((v) =>
        timeStamp.includes(v)
      ) &&
      !this.isNotificationTodaySet
    ) {
      this.isNotificationTodaySet = true;
      return true;
    }
    return false;
  }

  notificationOlder (noti: Notification): boolean {
    const timeStamp = moment(
      noti.timestamp.toString().length === 10
        ? noti.timestamp * 1000
        : noti.timestamp
    ).fromNow(true);
    if (
      !["seconds", "minute", "minutes", "hour", "hours"].some((v) =>
        timeStamp.includes(v)
      ) &&
      !this.isNotificationOlderSet
    ) {
      this.isNotificationOlderSet = true;
      return true;
    }
    return false;
  }

  private parseJSON (str: string): string {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  }
}
