import {
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import Popper, { Placement } from "popper.js";

@Directive({
  "selector": "[caiTooltip]",
})
export class CaiTooltipDirective implements OnDestroy {
  @Input() tooltip = "";
  @Input() tooltipTpl: TemplateRef<any>;
  @Input() target: Element;
  @Input() placement: Placement = "bottom";
  @Input() detectOverflow: boolean;
  @Input() tooltipStyles: { [key: string]: string };

  private popperRef: Popper;
  private view: EmbeddedViewRef<any>;

  constructor (
    private readonly el: ElementRef,
    private readonly zone: NgZone,
    private readonly vcr: ViewContainerRef
  ) {}

  ngOnDestroy (): void {
    if (this.isOpen()) {
      this.close();
    }
  }

  @HostListener("keyup") onKeyUp () {
    if (this.isOpen()) {
      this.createTooltipPopup();
    }
  }

  @HostListener("mouseenter") onMouseEnter () {
    this.createTooltipPopup();
  }

  @HostListener("mouseleave") onMouseLeave () {
    if (this.isOpen()) {
      this.close();
    }
  }

  isOpen (): boolean {
    return !!this.popperRef;
  }

  private createTooltipPopup () {
    if (this.isOpen() || !this.tooltip) {
      return this.close();
    }
    if (this.detectOverflow && !this.isOverflow) {
      return;
    }
    let tooltip;
    if (this.tooltipTpl) {
      this.view = this.vcr.createEmbeddedView(this.tooltipTpl);
      tooltip = this.view.rootNodes[0];
    } else {
      tooltip = document.createElement("span");
      tooltip.innerHTML = this.tooltip;
      tooltip.style.color = "#ffffff";
      tooltip.style["background-color"] = "#646464";
      tooltip.style["border-radius"] = "4px";
      tooltip.style.padding = "2px 4px";
      tooltip.style["z-index"] = "9999";
      tooltip.style["max-width"] = "80%";
      if (this.tooltipStyles) {
        Object.keys(this.tooltipStyles).forEach((key) => {
          tooltip.style[key] = this.tooltipStyles[key];
        });
      }
    }
    document.body.appendChild(tooltip);

    const reference = this.target || this.el.nativeElement;
    this.zone.runOutsideAngular(() => {
      this.popperRef = new Popper(reference, tooltip, {
        "placement": this.placement,
        "removeOnDestroy": true,
        "modifiers": {
          "preventOverflow": {
            "enabled": true,
            "boundariesElement": "viewport",
            "escapeWithReference": false,
          },
        },
      });
    });
  }

  close () {
    this.popperRef?.destroy();
    this.view?.destroy();
    this.popperRef = null;
    this.view = null;
  }

  get isOverflow (): boolean {
    const reference = this.target || this.el.nativeElement;
    return reference.clientWidth < reference.scrollWidth;
  }
}
