import { ULDTypeEnum } from "@cai-services";
import { BUPFieldsEnum } from "../enum/bup.enum";

export const ULDTypes = [
  {
    "name": ULDTypeEnum.AKE,
    "description": "Container",
    "weight": 750,
    "volume": 4.3,
  },
  {
    "name": ULDTypeEnum.LDP,
    "description": "Lower deck pallet",
    "weight": 1600,
    "volume": 10,
  },
  {
    "name": ULDTypeEnum.MDP,
    "description": "Main deck pallet",
    "weight": 2500,
    "volume": 17,
  },
];

export const BUPConfig = [
  {
    "field": BUPFieldsEnum.ULD_TYPE,
    "validators": {
      "required": (value: number) =>
        [null, undefined].includes(value) || value.toString().length < 1
          ? "ULD Type is required"
          : null,
    },
  },
  {
    "field": BUPFieldsEnum.QUANTITY,
    "validators": {
      "required": (value: number) =>
        [null, undefined].includes(value) || value.toString().length < 1
          ? "Quantity is required"
          : null,
      "min": (value: number) => (+value <= 0 ? "Quantity must be above 0" : null),
      "max": (value: number) =>
        +value >= 1000 ? "Quantity must be below 1,000" : null,
    },
  },
  {
    "field": BUPFieldsEnum.WEIGHT,
    "validators": {
      "required": (value: number) =>
        [null, undefined].includes(value) || value.toString().length < 1
          ? "Weight is required"
          : null,
      "min": (
        value: number,
        weightUnit?: string,
        maxValue?: number,
        minValue?: number
      ) =>
        +value <= minValue
          ? `Weight must be above ${minValue} ${weightUnit}`
          : null,
      "max": (value: number, weightUnit?: string, maxValue?: number) =>
        +value >= maxValue
          ? `Weight must be below ${maxValue?.toLocaleString()} ${weightUnit}`
          : null,
    },
  },
];
