import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  EventEmitter,
  Input,
  NgZone,
  Output,
  Self,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  EmbeddedViewRef,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, takeWhile } from 'rxjs/operators';
import Popper, { Modifiers } from 'popper.js';
import { IDgdDictionnary } from '@cai-services';
import { EventsUtil } from '@cai-framework';

const INPUT_REGEX_NUMERIC = /^\d/g,
  FORMAT_REGEX_NUMERIC = /^\d{0,10}$/;

@Component({
  selector: 'kt-unid-selector',
  templateUrl: './unid-selector.component.html',
  styleUrls: ['./unid-selector.component.scss'],
})
export class UnidSelectorComponent implements OnInit, OnChanges {
  @ViewChild('input') input: ElementRef;
  @ViewChild('dropdown', { read: TemplateRef }) dropdown: TemplateRef<any>;
  @Input() options: IDgdDictionnary[] = [];
  @Input() selected: IDgdDictionnary;
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() value = '';
  @Input() invalid: boolean;
  @Input() height: string;
  @Input() blurOnSelect: boolean;
  @Output() valueChange = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onLostFocus = new EventEmitter();
  @Output() onKeyDownEvent = new EventEmitter();
  @Output() onSelect = new EventEmitter();

  lastValue: any;
  isFocused: boolean;
  popupModifiers: Modifiers = {
    offset: {
      enabled: true,
      offset: '-95, 2',
    },
    preventOverflow: { escapeWithReference: true },
  };

  view: EmbeddedViewRef<any>;
  popperRef: Popper;
  private readonly textInput: Subject<string> = new Subject();

  constructor(
    @Self() private readonly element: ElementRef,
    private readonly vcr: ViewContainerRef,
    private readonly zone: NgZone,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.textInput.pipe(debounceTime(300)).subscribe((value) => {
      this.valueChange.emit(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('options')) {
      if (this.dropdown && this.isFocused) {
        this.open();
      }
    }
  }

  textChange(value: string) {
    this.textInput.next(value);
  }

  getGroupItems(groupItems: string): string[] {
    if (groupItems) {
      const descArr = groupItems.split(',');
      return descArr.map((d) => d.trim());
    }
    return [];
  }

  isOpen(): boolean {
    return !!this.popperRef;
  }

  focus() {
    this.onFocus.emit();
    this.isFocused = true;
    if (!this.value) {
      this.open();
    }
  }

  open() {
    if (!!this.popperRef) {
      this.close();
    }

    if (this.filteredOptions.length || !this.value) {
      this.view = this.vcr.createEmbeddedView(this.dropdown);
      const dropdown = this.view.rootNodes[0],
        maxHeight =
          window.innerHeight -
          (this.element.nativeElement.getBoundingClientRect().top +
            window.scrollY);

      document.body.appendChild(dropdown);
      dropdown.style['z-index'] = '1305';
      dropdown.style['min-width'] = `${this.input.nativeElement.offsetWidth}px`;
      if (!this.height) {
        dropdown.style['max-height'] = `${maxHeight - 100}px`;
      } else {
        dropdown.style['max-height'] = this.height;
      }

      this.zone.runOutsideAngular(() => {
        this.popperRef = new Popper(this.input.nativeElement, dropdown, {
          placement: 'bottom-start',
          removeOnDestroy: true,
          modifiers: {
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport',
              escapeWithReference: true,
            },
            flip: {
              enabled: false,
            },
            hide: {
              enabled: true,
            },
          },
        });
      });
      this.cdr.detectChanges();
      this.handleClickOutside();
      this.handleScrollOutside();
    }
  }

  get filteredOptions(): IDgdDictionnary[] {
    return (this.options || []).filter((option) => {
      const id = option.id.replace('UN', ''),
        keyword = this.value;
      return id.startsWith(keyword) && keyword;
    });
  }

  blur(inputText: string) {
    this.isFocused = false;
    this.onLostFocus.emit();
    if (this.isOpen() && inputText) {
      if (this.filteredOptions.length) {
        this.select(this.filteredOptions[0]);
      }
    } else {
      const matched = this.filteredOptions.find(
        (item) => item.id.replace('UN', '') === inputText,
      );
      this.select(matched ? matched : inputText);
    }
    this.cdr.detectChanges();
  }

  select(option) {
    if (option && option.id) {
      this.value = option.id.replace('UN', '');
      if (this.selected !== option) {
        this.onSelect.emit(option);
      }
      this.close();
      if (this.blurOnSelect) {
        this.onLostFocus.emit();
      } else {
        this.onFocus.emit();
      }
    } else {
      this.onSelect.emit(null);
      if (this.blurOnSelect) {
        this.onLostFocus.emit();
      }
      this.close();
    }
    this.cdr.detectChanges();
  }

  handleClickOutside() {
    fromEvent(document, 'click')
      .pipe(
        takeWhile(() => this.isOpen()),
        filter(({ target }) => {
          const origin = this.popperRef.reference as HTMLElement;
          return origin.contains(target as HTMLElement) === false;
        }),
        takeUntil(this.onClose),
      )
      .subscribe(() => {
        this.close();
        this.cdr.detectChanges();
      });
  }

  handleScrollOutside() {
    fromEvent(document, 'wheel')
      .pipe(
        takeWhile(() => this.isOpen()),
        filter(({ target }) => {
          const origin = document.getElementsByClassName('unid-selector')[0];
          return origin && origin.contains(target as HTMLElement) === false;
        }),
        takeUntil(this.onClose),
      )
      .subscribe(() => {
        this.close();
        this.cdr.detectChanges();
      });
  }

  close() {
    if (this.view) {
      this.view.destroy();
      this.view = null;
    }
    if (this.popperRef) {
      this.popperRef?.destroy();

      this.popperRef = null;
    }
  }

  onKeyPress($event) {
    EventsUtil.handleInputFromKeyPress(
      $event,
      this.value,
      this.input,
      this.validateInput,
    );
  }

  onKeyDown($event) {
    const key = $event.keyCode || $event.charCode;
    if (key === 8 || key === 46) {
      this.lastValue = this.value != null ? this.value : null;
    } else if (
      EventsUtil.validateInputByRegExp(INPUT_REGEX_NUMERIC, $event.key)
    ) {
      this.onKeyDownEvent.emit();
    }
  }

  onKeyUp($event) {
    if (
      !EventsUtil.validateInputFromKeyup($event, this.value, this.validateInput)
    ) {
      this.value = this.lastValue;
    }
  }

  onPaste($event) {
    EventsUtil.handleInputFromPaste($event, this.validateInput);
  }

  validateInput(value: string): boolean {
    for (let i = 0; i < value.length; i++) {
      const char = value.charAt(i),
        _inputRegex = new RegExp(INPUT_REGEX_NUMERIC);
      if (!_inputRegex.test(char)) {
        return false;
      }
    }
    const _formatRegex = new RegExp(FORMAT_REGEX_NUMERIC);
    return _formatRegex.test(value);
  }
}
