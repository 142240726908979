import { MatSelectChange } from "@angular/material/select";
import {
  Component,
  OnInit,
  LOCALE_ID,
  Inject,
  ChangeDetectorRef,
  Input,
} from "@angular/core";
import { Router } from "@angular/router";
import { datadogRum } from "@datadog/browser-rum";
import { SessionService } from "../../core/_services/session.service";
import { environment } from "../../environments";
import { Language, languages } from "./language";

const DEFAULT_LANGUAGE = {
  "country": "United States",
  "flag": "us",
  "language": "English",
  "locale": "en",
};
@Component({
  "selector": "kt-language-selector",
  "templateUrl": "./language-selector.component.html",
  "styleUrls": ["./language-selector.component.scss"],
})
export class LanguageSelectorComponent implements OnInit {
  @Input() isLoginPage: boolean;
  @Input() isWalletApp: boolean;

  languageSelected: Language;
  languages = languages();
  features = environment.features;

  constructor (
    @Inject(LOCALE_ID) public locale: string,
    private router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly sessionService: SessionService
  ) {}

  ngOnInit (): void {
    this.locale = this.locale ? this.locale : "en";
    this.languageSelected = this.languages.find(
      (l) => l.locale === this.locale
    );
    this.languageSelected = this.languageSelected?.flag
      ? this.languageSelected
      : DEFAULT_LANGUAGE;
    this.cdr.detectChanges();
    if (environment.datadog) {
      datadogRum.addFeatureFlagEvaluation(
        "newLanguages",
        this.features.newLanguages
      );
    }
  }

  changeLocale (locale: string): void {
    this.sessionService.setSelectedLanguage(locale);
    this.languageSelected = this.languages.find((l) => l.locale == locale);
    this.cdr.detectChanges();
    if (locale != this.locale) {
      if (this.router.url.indexOf(`/${this.locale}/`) >= 0) {
        const newUrl = this.router.url.replace(
          `/${this.locale}/`,
          `/${locale}/`
        );
        this.router.navigateByUrl(newUrl);
        return;
      }
      let new_route = (locale != "en" ? "/" + locale : "") + this.router.url;
      if (!new_route.includes("?")) {new_route += window.location.search;}
      window.location.replace(new_route);
    }
  }

  onSelectionChange (event: MatSelectChange): void {
    this.changeLocale(event.value);
  }
}
