import { RequirementConfig } from "../../core/_models/requirement-config.model";

export enum DiplomaticAnswer {
  NO = "default_no",
  YES = "dip_yes",
}

const DEFAULT_OPTION = {
  "label": "No",
  "value": DiplomaticAnswer.NO,
},

 ACTIVE_OPTION = {
  "label": "YES",
  "value": DiplomaticAnswer.YES,
};

export const DiplomaticConfig: RequirementConfig = {
  "summaryLabel": "",
  "question": "",
  "options": [DEFAULT_OPTION, ACTIVE_OPTION],
  "defaultOption": DEFAULT_OPTION,
  "activeOption": ACTIVE_OPTION,
};
