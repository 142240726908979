import { RequirementConfig } from "../../core/_models/requirement-config.model";

export enum LithiumBatteryDetailAnswer {
  LITHIUM_ION = "lithium_ion",
  LITHIUM_METAL = "lithium_metal",
  BOTH = "both",
}

export const LithiumBatteryDetailConfig: RequirementConfig = {
  "summaryLabel": "",
  "question": "",
  "options": [
    {
      "label": "Lithium Ion",
      "value": LithiumBatteryDetailAnswer.LITHIUM_ION,
      "description": "UN3481, PI966/PI967, Section II",
    },
    {
      "label": "Lithium Metal",
      "value": LithiumBatteryDetailAnswer.LITHIUM_METAL,
      "description": "UN3091, PI969/PI970, Section II",
    },
    {
      "label": $localize`:@@global.both:Both`,
      "value": LithiumBatteryDetailAnswer.BOTH,
      "description":
        "UN3481 Section II, PI966/PI967 & UN3091 Section II, PI969/PI970",
    },
  ],
};
