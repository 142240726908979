import {
  Component,
  Input,
  ChangeDetectorRef,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { Subscription } from "rxjs";
import { Office } from "../../core/_models/office.model";
import { SessionService } from "../../core/_services/session.service";
import { getDefaultOffice } from "../../helpers/office-helpers";

@Component({
  "selector": "kt-office-selector",
  "templateUrl": "./office-selector.component.html",
  "styleUrls": ["./office-selector.component.scss"],
})
export class OfficeSelectorComponent implements OnInit, OnDestroy {
  @Input() type: string;
  @Input() appearance: "icon" | "expanded";
  @Input() showIataCass: boolean;
  @Input() showIcon: boolean;
  @Output() onSelect = new EventEmitter();

  companyOffices: Office[] = [];
  companyOfficesView: OfficeView[];
  selectedOfficeView: OfficeView;

  private officeChangedStatusSub: Subscription;

  constructor (
    private readonly cdr: ChangeDetectorRef,
    private readonly sessionService: SessionService,
  ) {
    this.officeChangedStatusSub =
      this.sessionService.officeChangedStatusObs.subscribe(async (_) => {
        if (!this.companyOffices?.length) {
          await this.initCompanyOfficesView();
        }
        this.initSelectedOfficeView();
      });
  }

  async ngOnInit () {
    this.initSelectedOfficeView();
  }

  ngOnDestroy (): void {
    this.companyOffices = null;
    if (this.officeChangedStatusSub) {
      this.officeChangedStatusSub.unsubscribe();
    }
  }

  async initCompanyOfficesView () {
    this.companyOffices = (await this.sessionService.getOffices()) as Office[];
    const defaultOffice = getDefaultOffice(this.companyOffices);

    this.companyOfficesView = this.companyOffices.map(this.getView);
    const defaultOfficeView = this.companyOfficesView.find(
      (office) => office.id === defaultOffice.id,
    );
    if (defaultOfficeView !== undefined) {
      defaultOfficeView.isDefault = true;
    }
    this.companyOfficesView.sort((officeViewA, officeViewB) => {
      if (officeViewA.isDefault) {
        return -1;
      }
      if (officeViewB.isDefault) {
        return 1;
      }
      return officeViewA.airportCode?.localeCompare(officeViewB.airportCode);
    });
  }

  initSelectedOfficeView () {
    if (!this.companyOffices?.length) {
      return;
    }

    const selectedOffice = this.sessionService.getSelectedOffice(),
     companySelectedOffice = this.companyOffices.find(
      (office) => office.id === selectedOffice?.id,
    );

    if (selectedOffice && companySelectedOffice) {
      this.selectedOfficeView = this.getView(selectedOffice);
    } else {
      this.onSelectOffice(getDefaultOffice(this.companyOffices).id.toString());
    }
    this.cdr.detectChanges();
  }

  getView (office: Office): OfficeView {
    return {
      "id": office.id,
      "countryName": office.country.countryName,
      "countryCode": office.country.countryCode,
      "iata": office.iata,
      "cass": office.cass,
      "value": office.id.toString(),
      "airportCode": office?.airports?.[0]?.airportCode,
      "isDefault": false,
    };
  }

  onSelectOffice (officeId: string) {
    const selectedOffice = this.companyOffices.find(
      (office) => office.id === +officeId,
    );
    this.sessionService.setSelectedOffice(selectedOffice);
  }

  getValue (text: string): string {
    if (!!text && ![null, undefined, "null", "undefined"].includes(text)) {
      return text;
    }
    return "";
  }
}

interface OfficeView {
  id: number;
  countryName: string;
  countryCode: string;
  iata: string;
  cass: string;
  value: string;
  airportCode: string;
  isDefault: boolean;
}
