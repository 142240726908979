import {
  CompanyTypeEnum,
  MeasurementSystemEnum,
  UserStatusEnum,
  UserTypeEnum,
} from "../../enum";
import { IAirlineUser } from "../../model/airline-user.model";

export const mockedAirlineUser: IAirlineUser = {
  "type": UserTypeEnum.AIRLINE,
  "userId": 1447,
  "userName": "Test Airline",
  "userEmail": "tester+automationairline39a@alasdoo.com",
  "lastName": "Airline",
  "firstName": "Test",
  "contact": {
    "countryCode": null,
    "cityCode": null,
    "phoneNumber": "111-333",
  },
  "userStatus": UserStatusEnum.ACTIVATED,
  "defaultCurrency": "SGD",
  "company": {
    "id": 7,
    "companyCode": "Singapore Airlines",
    "companyName": "Singapore Airlines",
    "companyType": CompanyTypeEnum.AIRLINE,
    "airlineBrands": [
      {
        "airlineCompanyId": 7,
        "airlineCompanyName": "Singapore Airlines",
        "airlineCompanyCode": "SQ",
        "awbPrefix": "618",
        "mainWebsiteUrl": "http://www.siacargo.com/",
      },
    ],
    "emailDomains": [],
    "providers": [],
    "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
  },
  "country": {
    "countryCode": "SG",
    "countryName": "Singapore",
  },
  "measurementSystem": MeasurementSystemEnum.METRIC,
  "defaultSearchDay": 1,
  "language": "en",
  "originAirport": {
    "airportCode": "SIN",
    "airportName": "Changi",
    "regionCode": "ASPAC",
    "regionName": "Asia & Pacific",
    "city": {
      "cityId": 1455,
      "cityCode": "SIN",
      "cityName": "Singapore",
      "state": {
        "stateId": 266,
        "stateName": "Singapore",
        "stateCode": "SG",
        "country": {
          "countryCode": "SG",
          "countryName": "Singapore",
        },
      },
    },
    "timezone": "Asia/Singapore",
  },
  "createdBy": "",
  "createdAt": new Date("2021-04-22T14:42:50.915+00:00"),
  "updatedBy": "1447",
  "updatedAt": new Date("2021-09-07T18:56:45.549+00:00"),
  "prefix": "",
  "compuClearing": false,
  "champ": false,
  "isHQ": false,
  "isAutoAccept": false,
  "iacao": "",
  "websiteURL": "",
  "cassCode": "",
} as unknown as IAirlineUser;
