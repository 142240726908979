<svg
  width="29"
  height="28"
  viewBox="0 0 29 28"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.125 25.5C20.3737 25.5 26.25 19.7916 26.25 12.75C26.25 5.70837 20.3737 0 13.125 0C5.87626 0 0 5.70837 0 12.75C0 19.7916 5.87626 25.5 13.125 25.5ZM25.3143 23.5454C25.0172 23.2568 24.5423 23.2636 24.2537 23.5607C23.9651 23.8579 23.972 24.3327 24.2691 24.6213L27.1857 27.4546C27.4829 27.7432 27.9577 27.7364 28.2463 27.4393C28.5349 27.1421 28.528 26.6673 28.2309 26.3787L25.3143 23.5454Z"
    fill="url(#paint0_linear_609:4568)"
  />
  <defs>
    <linearGradient
      id="paint0_linear_609:4568"
      x1="14.2292"
      y1="0"
      x2="14.2292"
      y2="27.6667"
      gradientUnits="userSpaceOnUse"
    >
      <stop [attr.stop-color]="startClr" />
      <stop offset="1" [attr.stop-color]="endClr" />
    </linearGradient>
  </defs>
</svg>
