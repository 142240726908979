<div class="popup" *ngIf="popupDetails">
  <div class="popup__header">
    <span class="title">ROUTE DETAILS</span>
    <div class="actions">
      <button
        (mouseenter)="onActionMouseEnter(flightDetailActionsEnum.EMAIL_DETAILS)"
        (mouseleave)="onActionMouseLeave()"
        (mousedown)="onActionClick(flightDetailActionsEnum.EMAIL_DETAILS)"
      >
        <icon-email
          [isHovered]="isActionHovered(flightDetailActionsEnum.EMAIL_DETAILS)"
          i18n-tooltipText="@@global.share-via-email"
          [tooltipText]="'Share via email'"
        >
        </icon-email>
      </button>
      <button
        (mouseenter)="onActionMouseEnter(flightDetailActionsEnum.COPY_DETAILS)"
        (mouseleave)="onActionMouseLeave()"
        (mousedown)="onActionClick(flightDetailActionsEnum.COPY_DETAILS)"
      >
        <icon-copy
          [isHovered]="isActionHovered(flightDetailActionsEnum.COPY_DETAILS)"
          [activeColor]="'#B37DE8'"
          i18n-copyTooltip="@@global.copy-to-clipboard"
          [copyText]="'Copy to clipboard'"
          [copiedField]="doneAction"
          [field]="flightDetailActionsEnum.COPY_DETAILS"
        ></icon-copy>
      </button>
    </div>
  </div>
  <div class="popup__body">
    <div class="summary">
      <div class="summary-item">
        <img src="/assets/media/icons/search-results/clock.svg" alt="" />
        <span class="summary-item__value">
          {{ popupDetails.flightDuration }}
        </span>
      </div>
      <div class="summary-item">
        <img
          src="/assets/media/icons/search-results/map-marker-alt.svg"
          alt=""
        />
        <span class="summary-item__value">
          {{ popupDetails.noOfStops }} Stop(s)
        </span>
      </div>
      <div class="summary-item">
        <img src="/assets/media/icons/search-results/leaf.svg" alt="" />
        <span class="summary-item__value">
          {{ popupDetails?.co2 }}
        </span>
      </div>
    </div>
    <div class="routes">
      <figure>
        <figcaption></figcaption>
        <table class="routes__table" aria-describedby="routes">
          <tr *ngIf="popupDetails.routes[0]; let departurePath">
            <th scope="col">
              <img
                src="/assets/media/icons/search-results/map-marker.svg"
                alt=""
              />
            </th>
            <th scope="col" class="city">
              <span>{{ departurePath.departureAirport }}</span>
            </th>
            <th scope="col" class="date">
              <div class="d-flex">
                <div class="date-icon">
                  <img
                    src="/assets/media/icons/search-results/departure.svg"
                    alt=""
                  />
                  <span>{{ formatDate(departurePath.departureTime) }}</span>
                </div>
                <div>
                  <span>{{ departurePath.departureTime | time }}</span>
                </div>
              </div>
            </th>
            <td class="td-space"></td>
          </tr>
          <ng-container *ngFor="let path of popupDetails.routes; let i = index">
            <tr *ngIf="i > 0">
              <td>
                <img
                  src="/assets/media/icons/search-results/via.svg"
                  alt=""
                  class="icon-via"
                />
              </td>
              <td class="city stop">
                <span>{{ path.departureAirport }}</span>
              </td>
              <td class="date stop" colspan="2">
                <div>
                  <img
                    src="/assets/media/icons/search-results/arrival.svg"
                    alt=""
                  />
                  <span class="pl-2 date-value">{{
                    formatDate(popupDetails.routes[i - 1].arrivalTime)
                  }}</span>
                  <span class="pl-2">{{
                    popupDetails.routes[i - 1].arrivalTime | time
                  }}</span>
                </div>
                <div class="pt-1">
                  <img
                    src="/assets/media/icons/search-results/departure.svg"
                    alt=""
                  />
                  <span class="pl-2 date-value">{{
                    formatDate(path.departureTime)
                  }}</span>
                  <span class="pl-2">{{ path.departureTime | time }}</span>
                </div>
              </td>
              <td class="td-space"></td>
            </tr>
            <tr
              *ngIf="i >= 0"
              class="with-co2"
              [attr.data-after-content]="formatCO2(path?.co2?.value)"
            >
              <td colspan="3">
                <span class="flight-info">{{ path.flightNumber }}</span>
                <span class="flight-info">{{
                  getAirCraft(path)?.aircraftName
                }}</span>
                <span class="flight-info">{{
                  getAirCraft(path)?.aircraftType?.aircraftTypeName
                }}</span>
              </td>
              <td class="td-space"></td>
            </tr>
          </ng-container>
          <tr
            *ngIf="
              popupDetails.routes[popupDetails.routes.length - 1];
              let arrivalPath
            "
          >
            <td>
              <img
                src="/assets/media/icons/search-results/map-marker-check.svg"
                alt=""
              />
            </td>
            <td class="city">
              <span>{{ arrivalPath.arrivalAirport }}</span>
            </td>
            <td class="date">
              <div class="d-flex">
                <div class="date-icon">
                  <img
                    src="/assets/media/icons/search-results/arrival.svg"
                    alt=""
                  />
                  <span>{{ formatDate(arrivalPath.arrivalTime) }}</span>
                </div>
                <div>
                  <span>{{ arrivalPath.arrivalTime | time }}</span>
                </div>
              </div>
            </td>
            <td class="td-space"></td>
          </tr>
        </table>
      </figure>
    </div>
    <div *ngIf="showRateSection && !isInterlineEnabled" class="rate">
      <div
        class="rate-selector d-flex"
        *ngIf="flightDetails?.value?.rates?.length"
      >
        <div
          class="rate-button"
          *ngFor="let rate of flightDetails?.value?.rates; let i = index"
          [class.selected-rate]="i === selectedRateIndex"
          (click)="handleRateChange(i)"
          [title]="getFormattedRateName(rate)"
        >
          {{ getFormattedRateName(rate) }}
        </div>
      </div>
      <div class="rate__wrapper">
        <table
          *ngIf="
            popupDetails.selectedRate &&
              (popupDetails.isInstantBooking || popupDetails.isRequestBooking);
            else notAvailable
          "
          class="rate__table"
          aria-describedby="rates"
        >
          <tr>
            <th scope="col">
              <span i18n="@@global.ALL-IN-rate">ALL IN Rate</span>
              <span class="rate-name">{{
                getFormattedRateName(popupDetails.selectedRate)
              }}</span>
              <span
                class="d-block"
                *ngIf="popupDetails.selectedRate.minimumRate"
                >(minimum amount applied)</span
              >
            </th>
            <th scope="col" class="total-rate">
              {{ popupDetails.selectedRate.allInRate | caiNumberFormatter }}
              {{ popupDetails.selectedRate.currency || currency }}/{{
                measurementUnit?.WEIGHT?.code
              }}
            </th>
          </tr>
          <tr>
            <td i18n="@@rate.net-rate">NET Rate</td>
            <td class="net-rate">
              {{ popupDetails.selectedRate.netRate | caiNumberFormatter }}
              {{ popupDetails.selectedRate.currency || currency }}/{{
                measurementUnit?.WEIGHT?.code
              }}
            </td>
          </tr>
          <tr *ngFor="let charge of popupDetails.selectedRate.charges">
            <td>{{ formatChargeLabel(charge.label) | titlecase }}</td>
            <td class="charge">
              <span class="charge-rate"
                >{{ charge.rate | caiNumberFormatter }}
                {{ popupDetails.selectedRate.currency || currency }}
                {{ getWeightType(charge.type) }}</span
              >
            </td>
          </tr>
          <tr>
            <td i18n="@@global.total-all-in-cost">
              Total ALL IN Cost
              <span
                caiPopover
                [enablePopover]="true"
                [target]="idTooltip"
                [allowHoverOnTarget]="true"
                [placement]="'right-start'"
              >
                <svg
                  class="info-icon"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9ZM9 4.91667C9.41421 4.91667 9.75 5.25245 9.75 5.66667V9.83333C9.75 10.2475 9.41421 10.5833 9 10.5833C8.58579 10.5833 8.25 10.2475 8.25 9.83333V5.66667C8.25 5.25245 8.58579 4.91667 9 4.91667ZM9 13.1667C9.46024 13.1667 9.83333 12.7936 9.83333 12.3333C9.83333 11.8731 9.46024 11.5 9 11.5C8.53976 11.5 8.16667 11.8731 8.16667 12.3333C8.16667 12.7936 8.53976 13.1667 9 13.1667Z"
                    fill="#679EC6"
                  />
                </svg>
                <ng-template #idTooltip>
                  <kt-popover
                    [arrowColor]="'#679EC6'"
                    [bgColor]="'#679EC6'"
                    [textColor]="'white'"
                    [message]="
                      'All calculations are done before rounding the values'
                    "
                    placement="right-start"
                  >
                  </kt-popover>
                </ng-template>
              </span>
            </td>
            <td class="total-cost">
              {{ popupDetails.selectedRate.total | caiNumberFormatter }}
              {{ popupDetails.selectedRate.currency || currency }}
            </td>
          </tr>
          <tr
            *ngIf="
              !!popupDetails.selectedRate.service &&
              popupDetails.selectedRate.service !== 'ALL'
            "
          >
            <td>
              <span i18n="@@global.remarks">Service</span>
            </td>
            <td scope="col" class="latest-acceptance-time">
              {{ popupDetails.selectedRate.service }}
            </td>
          </tr>
          <tr
            *ngIf="
              !!popupDetails.selectedRate.density &&
              popupDetails.selectedRate.density != '1:6'
            "
          >
            <td>
              <span i18n="@@flight-details.density">Density</span>
            </td>
            <td scope="col" class="latest-acceptance-time">
              {{ popupDetails.selectedRate.density }}
            </td>
          </tr>
          <tr
            *ngIf="
              !!popupDetails.selectedRate.day &&
              popupDetails.selectedRate.day !== '1234567'
            "
          >
            <td>
              <span i18n="@@flight-details.day">Day</span>
            </td>
            <td scope="col" class="latest-acceptance-time">
              {{ popupDetails.selectedRate.day }}
            </td>
          </tr>
          <tr *ngIf="!!popupDetails.selectedRate.airlineProdCode">
            <td>
              <span i18n="@@global.product-code">Airline product code</span>
            </td>
            <td scope="col" class="latest-acceptance-time">
              {{ popupDetails.selectedRate.airlineProdCode }}
            </td>
          </tr>
          <tr *ngIf="!!popupDetails.selectedRate.airlineRateRemarks">
            <td class="d-flex">
              <span i18n="@@global.remarks">Remarks</span>
            </td>
            <td scope="col" class="latest-acceptance-time text-wrap">
              {{ popupDetails?.selectedRate?.airlineRateRemarks }}
            </td>
          </tr>
          <tr *ngIf="!!popupDetails.selectedRate.rateId">
            <td>
              <span i18n="@@global.source">Source</span>
            </td>
            <td scope="col" class="latest-acceptance-time text-wrap">
              Static rate
            </td>
          </tr>
          <tr *ngIf="popupDetails.selectedRate.latestAcceptanceTime">
            <th scope="col">
              <span i18n="@@global.latest-acceptance-time"
                >Latest Acceptance Time (LAT)</span
              >
            </th>
            <td scope="col" class="latest-acceptance-time">
              {{
                popupDetails.selectedRate.latestAcceptanceTime
                  | caiTime: "MMM DD, YYYY | HH:mm"
              }}
            </td>
          </tr>
        </table>
        <ng-template #notAvailable>
          <div class="no-booking">
            <span class="no-booking__remarks">
              <span
                i18n="@@global.this-flight-is-not-available-for-instant-booking"
                >This flight is not available for instant booking</span
              >{{ popupDetails.reason ? ":" : "" }}
            </span>
            <span class="no-booking__reason" *ngIf="popupDetails.reason">
              {{ popupDetails.reason }}
            </span>
          </div></ng-template
        >
      </div>
    </div>
    <div class="actions-wrapper d-flex justify-content-between">
      <div class="align-items-center d-flex">
        <ng-container *ngFor="let airlinelogo of popupDetails.airlineLogo">
          <img [src]="airlinelogo" class="airline-logo" />
        </ng-container>
      </div>
      <div class="actions align-items-center d-flex" *ngIf="!isShipperQuote">
        <button
          *ngIf="popupDetails.isInstantBooking"
          class="booking-btn book"
          [disabled]="!canCreateBooking"
          [matTooltip]="bookDisabledReason"
          [matTooltipDisabled]="bookDisabledReason.length === 0"
          matTooltipClass="multiline"
          (click)="onBookClick()"
        >
          <span i18n="@@global.book" class="text-uppercase">Book</span>
          <span i18n="@@global.now">NOW</span>
        </button>
        <button
          *ngIf="popupDetails.isRequestBooking"
          class="booking-btn request-book"
          [disabled]="!canCreateBooking || isUserAirline"
          [matTooltip]="bookDisabledReason"
          [matTooltipDisabled]="bookDisabledReason.length === 0"
          matTooltipClass="multiline"
          (click)="onRequestBookClick()"
        >
          <span i18n="@@global.request">REQUEST</span>
          <span i18n="@@global.booking" class="text-uppercase">Booking</span>
        </button>
        <span
          *ngIf="
            popupDetails.isEquote && isEnableQuoteRequest && !isUserAirline
          "
          class="equote"
          i18n="@@global.available-for-equote"
        >
          available for eQuote
        </span>
      </div>
    </div>
  </div>
  <div class="popup__footer">
    <div class="rate-alert" *ngIf="popupDetails.isRequestBooking">
      <div class="rate-alert__icon">
        <img
          alt="info icon"
          src="/assets/media/icons/search-results/info.svg"
        />
      </div>
      <div class="rate-alert__message">
        <span i18n="@@rate.alert-info"
          >This rate is for reference purpose only.</span
        >
        <span i18n="@@rate.alert-message"
          >The final rate will be confirmed by the airline.</span
        >
      </div>
    </div>
  </div>
</div>
