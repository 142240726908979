<kt-new-popup (onClose)="close()">
  <div header class="advanced-search-result__header">
    <span i18n="@@global.flight-alert" class="pt-1 d-block">{{
      headers?.[type]
    }}</span>
  </div>
  <div body class="body">
    <div class="pt-2" *ngIf="!advancedBookingDetailsList?.length">
      <kt-booking-details-item
        [advancedBookingDetails]="advancedBookingDetails"
        [parentCommodities]="commodities"
        [airlines]="airlines"
        [regionAirports]="regionAirports"
        [hideFavorite]="true"
        [readOnly]="true"
        [popup]="true"
      ></kt-booking-details-item>
    </div>
    <div
      class="advanced-booking-list-container"
      *ngIf="advancedBookingDetailsList?.length"
    >
      <div
        class="warning-message"
        i18n="@@global.requested-in-progress-warning"
      >
        The following request(s) are still being processed; proceeding might
        affect them. Do you still wish to continue?
      </div>
      <div #el class="advanced-booking-list">
        <div
          class="advanced-booking-card"
          *ngFor="let advancedBookingcmp of advancedBookingDetailsList"
        >
          <kt-booking-details-item
            [advancedBookingDetails]="
              advancedBookingcmp?.advancedBookingDetails
            "
            [parentCommodities]="commodities"
            [airlines]="airlines"
            [regionAirports]="regionAirports"
            [hideFavorite]="true"
            [readOnly]="true"
            [popup]="true"
          ></kt-booking-details-item>
        </div>
      </div>
      <div [class.show-fade]="hasOverflown(el)"></div>
    </div>
    <div
      class="body-inner-container"
      *ngIf="!advancedBookingDetailsList?.length && type !== 'cancel'"
    >
      <div
        class="message flight-message"
        *ngIf="
          type === 'flightNo' &&
          (payload?.flightNumber || advancedBookingDetails.flightNo)
        "
        [class.no-flight-preview]="!payload?.legs?.length"
      >
        <span
          i18n="@@global.requested-flight-not-available"
          [innerHtml]="highlightKey()"
        >
        </span>
      </div>
      <div class="message" *ngIf="type !== 'flightNo'">
        <span>{{ dialogueList[this.type] }}</span>
      </div>
      <div>
        <div
          class="advanced-search-result__awb d-flex flex-column"
          *ngIf="type?.toLowerCase()?.includes('awb')"
          [formGroup]="awbForm"
        >
          <input
            caiAwbFormat
            numbersAndHyphenOnly
            [style.text-transform]="'uppercase'"
            placeholder="XXX-XXXXXXXX"
            formControlName="awb"
            (ngModelChange)="handleAwbUpdate()"
            [attr.maxLength]="12"
          />
          <span class="error-msg">
            <span *ngIf="isControlHasError">
              {{ isControlHasError("awb") }}
            </span>
          </span>
        </div>
      </div>
      <div *ngIf="type === 'flightNo'">
        <div
          class="advanced-search-result__flights d-flex align-items-center"
          *ngIf="payload?.legs?.length"
        >
          <kt-flight-preview [flightDetails]="payload"></kt-flight-preview>
          <div
            i18n="@@advanced-search-result.booking-not-available"
            class="advanced-search-result__booking-not-available"
          >
            BOOKING NOT AVAILABLE
          </div>
        </div>
      </div>
      <div
        *ngIf="type === 'airlineContact'"
        class="advanced-search-result__airline-contact"
        [formGroup]="airlineContactForm"
      >
        <input
          type="text"
          i18n-placeholder="@@add-airline-contact.email-address"
          placeholder="Email address"
          formControlName="email"
          (change)="isUserExists = false"
          [class.invalid]="isControlHasError('airlineContact')"
        />
        <span class="check-icon" *ngIf="isEmailValid">
          <img
            alt="check icon"
            src="/assets/media/icons/search-results/check-solid.svg"
          />
        </span>
        <span class="error-msg">
          <span *ngIf="isControlHasError">
            {{ isControlHasError("airlineContact") }}
          </span>
        </span>
      </div>
    </div>
    <div *ngIf="type === 'flightNo'" class="flight-search-container">
      <kt-flight-search
        [showErrorMessage]="showErrorMessage"
        (flightSelected)="selectedFlight = $event"
        [sliderLastArr]="sliderLastArr"
        [airlineName]="getAirlineName()"
        [sliderFirstDep]="sliderFirstDep"
        (moveFlightDaysView)="moveFlightDaysView($event.type, $event.item)"
        [selectedFlightDays]="selectedFlightDays"
        [originalFlightDateMax]="originalFlightDateMax"
        [searchResults]="searchResults"
        [prevConditions]="prevConditions()"
        [departureDate]="advancedBookingDetails.departureDate"
        [getFlightDaysToView]="
          getFlightDaysToView(
            currentSearchDate
              ? currentSearchDate
              : advancedBookingDetails.departureDate,
            'next'
          )
        "
        [maxArrivalDate]="maxArrivalDate"
      ></kt-flight-search>
    </div>
  </div>
  <div>
    <!--place search results table here-->
  </div>
  <div footer class="advanced-search-result__footer pb-2">
    <span
      i18n="@@global.cancel"
      class="cancel-button"
      (click)="this.close()"
      *ngIf="!advancedBookingDetailsList?.length"
      >cancel</span
    >
    <button
      *ngIf="!advancedBookingDetailsList?.length && type === 'flightNo'"
      class="btn-modify-booking"
      i18n="@@global.modify-details:Modify Details"
      (click)="handleModifyDetails()"
    >
      Modify Details
    </button>
    <button
      *ngIf="!advancedBookingDetailsList?.length && type !== 'cancel'"
      class="btn-request-booking"
      i18n="@@global.request-booking"
      [class.active]="isRequestButtonActive()"
      (click)="handleSubmit()"
    >
      REQUEST BOOKING
    </button>

    <button
      *ngIf="type === 'cancel'"
      class="btn-request-booking"
      i18n="@@advanced-booking.cancel-booking"
      [class.active]="true"
      (click)="handleCancelBooking()"
    >
      CANCEL BOOKING REQUEST
    </button>

    <div
      class="button-actions-container"
      *ngIf="advancedBookingDetailsList?.length"
    >
      <button
        class="btn-request-booking stay-button"
        [class.active]="true"
        style="width: 190px"
        i18n="@@advanced-search-results.stay"
        (click)="handleStay()"
      >
        STAY ON THE PAGE
      </button>
      <div>
        <span
          class="proceed-button"
          (click)="handleStopProgress()"
          i18n="@@advanced-search-results.stopProgress"
          >PROCEED ANYWAY</span
        >
        <span
          class="proceed-button open-new-tab-button"
          (click)="handleOpenNewTab()"
          i18n="@@advanced-search-results.new-tab"
          >OPEN IN A NEW TAB</span
        >
      </div>
    </div>
  </div>
</kt-new-popup>
