import { ChangeDetectorRef, Component, EventEmitter, Input, Optional, Output, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  AdvancedBookingAlertTypeEnum,
  AwbService,
  AwbStatusEnum,
  AwbTypeEnum,
  ICommodity,
  UserTypeEnum
} from "@cai-services";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep } from "lodash";
import moment from "moment";
import { debounceTime, filter, Subscription, switchMap } from "rxjs";
import { DayDiffer } from "../../core/_base/layout/pipes/day-diff.pipe";
import { EMAIL_REGEX } from "../../core/_constants/constants";
import { AdvancedBookingDetails } from "../../core/_models/advanced-booking-details.model";
import { AirlineBrand } from "../../core/_models/airline.model";
import { AirportLight } from "../../core/_models/airport-light.model";
import { CustomFlightDetails, FlightInput } from "../../core/_models/flight-input.model";
import { User } from "../../core/_models/user.model";
import { RegisterService } from "../../core/_services/register.service";
import { SessionService } from "../../core/_services/session.service";
import { SearchResultUtil } from "../../utils/search-result.util";
import { AwbValidators } from "../../validators/awb.validator";
import { LoginFormValidators } from "../../validators/loginForm.validator";
import { BookingDetailsItemComponent } from "../booking-details-item/booking-details-item.component";

const dialogueList = {
  "awbRequired": $localize`:@@advanced-search-result.awbDetails:Please provide a valid AWB number below to continue with the above booking request.`,
  "usedAWB": $localize`:@@advanced-search-result.awbDetails:Please provide a valid AWB number below to continue with the above booking request.`,
  "airlineContact": $localize`:@@advanced-search-result.airlineEmailAddress:To proceed further with the above request, please provide a valid airline email address to which this ‘Booking Request’ can be send.`,
  "flightNo": $localize`:@@advanced-search-result.flightMissingDialogue:Sorry, looks like requested flight key is not available for booking. You can select one of the other options below or modify your request and try again.`,
},

 headers = {
  "awbRequired": $localize`:@@advanced-search-result.awbRequired:AWB is required`,
  "usedAWB": $localize`:@@advanced-search-result.usedAWB:AWB already used`,
  "airlineContact": $localize`:@@advanced-search-result.airlineContactMissing:Airline contact is required`,
  "flightNo": $localize`:@@advanced-search-result.flightMissing:Requested flight is  not available for your booking. Please try another option`,
  "warning": $localize`:@@advanced-search-result-warning:One or more requests are still in progress`,
  "cancel": $localize`:@@advanced-search-result-cancel-booking-warning:Are you sure you want to cancel this booking request?`,
};

@Component({
  "selector": "kt-advanced-search-results",
  "templateUrl": "./advanced-search-results.component.html",
  "styleUrls": ["./advanced-search-results.component.scss"],
  "providers": [DayDiffer],
})
export class AdvancedSearchResultsComponent implements OnInit {
  @Input() advancedBookingDetails: AdvancedBookingDetails;
  @Input() commodities: ICommodity[] = [];
  @Input() airlines: AirlineBrand[];
  @Input() type: AdvancedBookingAlertTypeEnum;
  @Input() highlightText: string;
  @Input() advancedBookingDetailsList: BookingDetailsItemComponent[];
  @Input() regionAirports: AirportLight[] = [];
  @Input() payload: FlightInput;
  @Input() awbPrefix: string;
  @Input() searchResults: FlightInput[];
  @Output() onModify = new EventEmitter();
  @Output() onRequest = new EventEmitter();

  awbForm: FormGroup;
  airlineContactForm: FormGroup;
  userType = UserTypeEnum;
  AwbTypeEnum = AwbTypeEnum;
  dialogueList = dialogueList;
  subscription$ = new Subscription();
  isAwbUpdated: boolean;
  isUserExists: boolean;
  headers = headers;
  awbStatus: boolean;
  selectedFlightDays = [];
  selectedFlight: CustomFlightDetails;
  flightDateMin: number;
  flightDateMax: number;
  originalFlightDateMin: Date;
  originalFlightDateMax: Date;
  maxArrivalDate: Date;
  sliderLastArr: Date;
  sliderFirstDep: Date;
  currentSearchDate: Date;
  isRequestButtonClicked: boolean;

  constructor (
    @Optional()
    public activeModal: NgbActiveModal,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly registerService: RegisterService,
    private readonly awbService: AwbService,
    private readonly sessionService: SessionService
  ) {}

  ngOnInit () {
    if (this.type?.toLowerCase()?.includes("awb")) {
      this.initAwbForm();
    }
    if (this.type === "airlineContact") {
      this.initAirlineContactForm();
    }
    if (this.searchResults?.length) {
      this.computeVariables();
    }
    this.subscription$ = this?.airlineContactForm?.valueChanges
      .pipe(
        debounceTime(300),
        filter(() => this.airlineContactForm.valid),
        switchMap(() =>
          this.registerService.checkExistingEmail(
            this?.airlineContactForm.get("email").value
          , UserTypeEnum.AIRLINE)
        )
      )
      .subscribe({
        "next": (isUserExists) => {
          this.isUserExists = isUserExists;
          this.cdr.detectChanges();
        },
      });
    this.searchResults = this.getSortedFlightsByBest(this.searchResults);
  }

  private getSortedFlightsByBest (flightInputs: FlightInput[]) {
    let sortedFlights = [];

    const sortedAirlineFlights = this.sortAirlineFlightsByBest(flightInputs);
    sortedFlights = sortedFlights.concat(sortedAirlineFlights);

    return sortedFlights;
  }

  private sortAirlineFlightsByBest (flightInputs: FlightInput[]): FlightInput[] {
    const groupAirlineFlightsByRate = (flights) => {
      let sortedByRate = [];

      const flightsWithRate = flights?.filter(
        (flight) => flight.rates && flight.rates.length
      ),
       flightsUnavailable = flightsWithRate?.filter(
        (flight) => !flight.available
      ),
       flightsAvailable = flightsWithRate?.filter(
        (flight) => flight.available
      ),
       flightsWithoutRate = flights?.filter(
        (flight) => !flight.rates || !flight.rates.length
      ),
       groupedByRate =
        SearchResultUtil.groupFlightsByRate(flightsAvailable);

      groupedByRate?.forEach((group) => {
        sortedByRate = sortedByRate.concat(
          SearchResultUtil.sortByArrivalDate(group.flights)
        );
      });

      return [
        ...sortedByRate,
        ...SearchResultUtil.sortByArrivalDate(flightsUnavailable),
        ...SearchResultUtil.sortByArrivalDate(flightsWithoutRate),
      ];
    };
    let sortedFlights = [];
    if (flightInputs) {
      sortedFlights = groupAirlineFlightsByRate(flightInputs);
    }
    return sortedFlights;
  }

  get awb (): string {
    if (
      this.advancedBookingDetails?.awb &&
      !this.advancedBookingDetails?.awb.includes("-")
    ) {
      return `${this.advancedBookingDetails?.awb.substring(
        0,
        3
      )}-${this.advancedBookingDetails?.awb.substring(
        3,
        this.advancedBookingDetails?.awb.length
      )}`;
    }
    return this.advancedBookingDetails?.awb || "";
  }

  initAwbForm () {
    this.awbForm = this.fb.group({
      "awb": [
        this.awb || this.awbPrefix,
        [
          LoginFormValidators.required("AWB"),
          AwbValidators.valid(),
          AwbValidators.format(),
        ],
      ],
    });
  }

  isFormValid (isAwb = false): boolean {
    const form = isAwb ? this.awbForm : this.airlineContactForm;
    if (form?.invalid) {
      Object.keys(form.controls).forEach((key) => {
        const control = form.controls[key];
        if (control && control.invalid) {
          control.markAsTouched();
          if (control.value === undefined || control.value === null) {
            control.setValue(null);
          }
        }
      });
      return false;
    }
    return true;
  }

  initAirlineContactForm () {
    this.airlineContactForm = this.fb.group({
      "email": [
        "",
        Validators.compose([
          LoginFormValidators.required("Email"),
          LoginFormValidators.email(),
          LoginFormValidators.maxLength(320),
        ]),
      ],
    });
  }

  getAirlineName () {
    return (
      this.payload?.airlineCompany?.airlineCompanyName ||
      this.advancedBookingDetails?.airlineCompanyName ||
      this.airlines?.find((airline) => {
        if (
          this.advancedBookingDetails.airline === airline.airlineCompanyCode
        ) {
          return airline;
        }
        return false;
      })?.airlineCompanyName
    );
  }

  close () {
    if (this.advancedBookingDetailsList?.length) {
      this.activeModal.close({ "stay": true });
    } else {
      this.activeModal.close(false);
    }
  }

  handleStay () {
    this.activeModal.close({ "stay": true });
  }

  handleStopProgress () {
    this.activeModal.close({ "close": true });
  }

  handleOpenNewTab () {
    this.activeModal.close({ "newTab": true });
  }

  handleModifyDetails () {
    this.activeModal.close({ "modify": true });
  }

  isControlHasError (controlName: string): string {
    const control =
      controlName === "awb"
        ? this.awbForm?.controls.awb
        : this.airlineContactForm?.controls.email;
    if (this.type === AdvancedBookingAlertTypeEnum.AIRLINE_CONTACT) {
      if (this.isUserExists) {
        return $localize`:@@global.error-email-used:This email is already used by another user.`;
      }
    }
    if (!control) {
      return "";
    }

    if (control?.errors?.alreadyUsed) {
      return $localize`:@@global.text-error-awb-already-used:This AWB is already used by an another quote. Please use another AWB`;
    }
    if (control.status === "INVALID" && (control.dirty || control.touched)) {
      return Object.values(control?.errors)?.[0]?.message;
    }
    return "";
  }

  getFlightDaysToView (searchDate, nav) {
    return SearchResultUtil.getFlightDaysToView(
      moment(this.advancedBookingDetails.departureDate).toDate(),
      this.searchResults,
      searchDate,
      nav
    );
  }

  computeVariables (nav?: "next" | "prev", requestedDate?: string) {
    const flightDate = this.currentSearchDate
      ? this.currentSearchDate
      : this.advancedBookingDetails.departureDate;
    this.selectedFlightDays = [...Array(7).keys()].map((i) =>
      moment(flightDate).add(i, "d").format()
    );
    const lastDayArr = moment(new Date(this.selectedFlightDays[6]))
      .startOf("day")
      .toDate(),
     firstDayDep = moment(new Date(this.selectedFlightDays[0]))
      .startOf("day")
      .toDate();

    this.flightDateMin = firstDayDep.getTime();

    this.flightDateMax = moment(lastDayArr).endOf("day").toDate().getTime();

    this.originalFlightDateMin = new Date(this.flightDateMin);
    this.originalFlightDateMax = new Date(this.flightDateMax);
    this.maxArrivalDate = SearchResultUtil.getMaxArrivalDate(
      this.searchResults
    );

    this.sliderFirstDep = new Date(this.flightDateMin);

    this.sliderLastArr = new Date(this.flightDateMax);

    if (
      (requestedDate &&
        this.sliderLastArr < new Date(requestedDate) &&
        nav === "next") ||
      (this.sliderFirstDep > new Date(requestedDate) && nav === "prev")
    ) {
      return this.moveFlightDaysView(nav, requestedDate);
    }
  }

  prevConditions () {
    return (
      this.originalFlightDateMin >
      new Date(this.advancedBookingDetails.departureDate)
    );
  }

  moveFlightDaysView (nav: "prev" | "next", requestedDate?: string) {
    const searchDate = this.currentSearchDate
      ? this.currentSearchDate
      : moment(this.advancedBookingDetails?.departureDate).toDate();
    let days = SearchResultUtil.getFlightDaysToView(
      moment(this.advancedBookingDetails?.departureDate).toDate(),
      this.searchResults,
      searchDate,
      nav
    );
    if (nav === "prev") {
      days = -days;
    }

    this.currentSearchDate = SearchResultUtil.addDaysToDate(searchDate, days);
    this.sessionService.setItemSessionStorage(
      "currentSearchDate",
      this.currentSearchDate
    );
    this.sessionService.setItemSessionStorage("flightStartDate", null);
    this.sessionService.setItemSessionStorage("flightEndDate", null);
    this.selectedFlightDays = [];
    this.computeVariables(nav, requestedDate);
  }

  handleSubmit () {
    if (this.type?.toLowerCase()?.includes("flightno")) {
      if (!this.isRequestButtonActive()) {
        this.isRequestButtonClicked = true;
        return;
      }
    } else if (this.type?.toLowerCase()?.includes("awb")) {
      if (!this.isFormValid(true)) {
        return;
      }
    } else if (this.type?.toLowerCase()?.includes("airlinecontact")) {
      if (!this.isFormValid()) {
        return;
      }
    }
    const clonedShipmentDetails = cloneDeep(this.advancedBookingDetails);
    let email;
    if (
      this.type === AdvancedBookingAlertTypeEnum.AWB ||
      this.type === AdvancedBookingAlertTypeEnum.DUPLICATE_AWB
    ) {
      const awb = this.awbForm.get("awb").value;
      clonedShipmentDetails.awb = awb;
    }
    if (this.type === AdvancedBookingAlertTypeEnum.AIRLINE_CONTACT) {
      email = new User(
        "TEST-EMAIL",
        this.airlineContactForm.get("email").value,
        UserTypeEnum.AIRLINE
      );
    }
    this.activeModal.dismiss({
      "shipmentDetails": clonedShipmentDetails,
      "emailContact": email,
      "selectedFlight": this.selectedFlight,
    });
  }

  hasOverflown (element: HTMLDivElement) {
    if (element.offsetHeight < element.scrollHeight) {
      return true;
    }
    return false;
  }

  handleAwbUpdate () {
    if (!this.awbForm.valid || this.awbStatus) {
      return;
    }
    this.isAwbUpdated = true;
    this.checkAwbStatus();
    this.cdr.detectChanges();
  }

  checkAwbStatus (): void {
    if (this.awbForm.controls.awb.valid) {
      this.awbStatus = true;
      const awb = this.awbForm.controls.awb.value.split("-").join("");
      this.awbService
        .checkAwbStatus(this.advancedBookingDetails?.shipmentId, awb)
        .then((awbResponse) => {
          const awbNumberControl = this.awbForm.controls.awb;
          if (awbNumberControl.errors && awbNumberControl.errors.alreadyUsed) {
            delete awbNumberControl.errors.alreadyUsed;
          }
          if (awbResponse?.awbStatus === AwbStatusEnum.USED) {
            awbNumberControl.setErrors({
              "alreadyUsed": true,
            });
          }
          this.cdr.detectChanges();
        });
      this.awbStatus = false;
    }
  }

  isRequestButtonActive () {
    if (
      (this.awbForm?.valid && this.isAwbUpdated) ||
      (this.airlineContactForm?.valid && !this.isUserExists) ||
      this.selectedFlight
    ) {
      return true;
    }
    return false;
  }

  handleCancelBooking () {
    this.activeModal.close({ "cancel": true });
  }

  highlightKey () {
    if (this.payload?.flightNumber || this.advancedBookingDetails?.flightNo) {
      return dialogueList.flightNo?.replace(
        "key",
        `<span class="highlightText">${
          this.payload?.flightNumber || this.advancedBookingDetails.flightNo
        }</span>`
      );
    }
    return null;
  }

  get isEmailValid (): boolean {
    return !this.isEmailEmpty && this.isEmailFormatValid && !this.isUserExists;
  }

  get isEmailEmpty (): boolean {
    const email = this.airlineContactForm.get("email").value || "";
    return !email || !email.length;
  }

  get isEmailFormatValid (): boolean {
    const email = this.airlineContactForm.get("email").value || "";
    return this.isEmailEmpty || EMAIL_REGEX.test(email?.toLowerCase());
  }

  get showErrorMessage (): boolean {
    return (
      !this.selectedFlight &&
      this.isRequestButtonClicked &&
      this.type?.toLowerCase()?.includes("flightno")
    );
  }
}
