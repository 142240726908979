import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { RateService } from '@cai-services';
import moment from 'moment';
import { AircraftColorPipe } from '../../core/_base/layout/pipes/aircraft-color.pipe';
import { TimeDiffer } from '../../core/_base/layout/pipes/time-diff.pipe';
import { TimeFromStartDifferPipe } from '../../core/_base/layout/pipes/time-start-diff.pipe';
import { Aircraft } from '../../core/_models/aircraft.model';
import { AirlineBrand } from '../../core/_models/airline.model';
import {
  CustomFlightDetails,
  FlightInput,
} from '../../core/_models/flight-input.model';
import { UserMeasurementSystem } from '../../core/_models/measurement-system.model';
import { SearchTemplate } from '../../core/_models/search-template.model';
import { FlightRate } from '../../core';

@Component({
  selector: 'kt-smart-view-table',
  templateUrl: './smart-view-table.component.html',
  styleUrls: ['./smart-view-table.component.scss'],
  providers: [TimeDiffer, TimeFromStartDifferPipe, AircraftColorPipe],
})
export class SmartViewTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() searchUUID: string;
  @Input() weight: number;
  @Input() isSelectAll: boolean;
  @Input() chargeableWeight: number;
  @Input() measurementUnit: UserMeasurementSystem;
  @Input() allFlights: CustomFlightDetails[] = [];
  @Input() sliderFirstDep: Date;
  @Input() sliderLastArr: Date;
  @Input() flightDays: number;
  @Input() isShipperQuote: boolean;
  @Input() selectedFlightDetails: FlightInput[] = [];
  @Input() isBatchLoading: boolean;
  @Input() isEnableQuoteRequest: boolean;
  @Input() activeColumn: number;
  @Input() aircraftList: Aircraft[] = [];
  @Input() searchTemplate: SearchTemplate;
  @Input() selectedFlight: CustomFlightDetails;
  @Input() flightIndex: number;
  @Input() popover: boolean;
  @Input() isNewSearch: boolean;
  @Input() isShipmentDetailsPage: boolean;
  @Input() isExpressOnly: boolean;

  @Input() qualityScoreList: { [key: string]: string }[];

  @Output() flightSelected = new EventEmitter<CustomFlightDetails>();
  @Output() onSelectSingleFlight = new EventEmitter<{
    payload: { checked: boolean; sortKey: string };
    airlineCompany: AirlineBrand;
  }>();
  @Output() onSelectAll = new EventEmitter<{
    checked: boolean;
    payload: string[];
  }>();
  @Output() onFlightBook = new EventEmitter<{
    payload: any;
  }>();
  @Output() onRequestBook = new EventEmitter<{
    payload: any;
  }>();
  @Output() onRateChange = new EventEmitter<{
    sortKey: string;
    selectedRate: FlightRate;
  }>();
  @Output() onMoveFlightDaysView = new EventEmitter();
  flightsByAirline: {
    airlineCompany: AirlineBrand;
    flights: CustomFlightDetails[];
  }[] = [];
  airlinesWithRateInsight: string[] = [];
  isLoading = false;
  expandFlightState: any;
  airlinesToShow = 5;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly rateService: RateService,
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.flightsByAirline = [];
    this.getFlightByAirline();
    if (!this.isNewSearch) {
      this.getCoverage();
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.allFlights) {
      this.flightsByAirline = [];
      this.getFlightByAirline();
    }
  }

  showMoreAirlines() {
    this.airlinesToShow += 100;
  }

  updateExpandFlightState(event) {
    if (!this.expandFlightState) {
      this.expandFlightState = {};
    }
    this.expandFlightState[event.key] = event.value;
    this.cdr.detectChanges();
  }

  getFlightByAirline(): void {
    this.allFlights.forEach((flight) => {
      const currentFlightGroup = this.flightsByAirline.find(
        (f) =>
          f.airlineCompany.airlineCompanyCode ===
          flight.airlineCompany.airlineCompanyCode,
      );
      if (!currentFlightGroup) {
        this.flightsByAirline.push({
          airlineCompany: flight.airlineCompany,
          flights: [flight],
        });
      }
      if (
        currentFlightGroup &&
        !currentFlightGroup.flights.find(
          (f) => f.sortingFlightKey === flight.sortingFlightKey,
        )
      ) {
        currentFlightGroup.flights.push(flight);
      }
    });

    if (this.flightsByAirline.length) {
      setTimeout(() => {
        this.isLoading = false;
        this.cdr?.detectChanges();
      }, 333);
    }
  }

  isExpress(): boolean {
    return !!this.searchTemplate?.requirements?.find((req) =>
      req?.answer?.specialHandlingCodes?.some((shc) => shc?.shcCode === 'XPS'),
    );
  }

  getProductCode(): string {
    if (this.isExpress()) {
      return 'XPS';
    }
    return this.searchTemplate?.typeOfProduct?.productCode;
  }

  async getCoverage() {
    let officeId = this.allFlights
      ?.find((flight) => flight.rates?.some((rate) => !!rate?.coloaderOfficeId))
      ?.rates?.find((rate) => !!rate?.coloaderOfficeId)?.coloaderOfficeId;
    if (!officeId) {
      officeId = this.searchTemplate?.office?.id;
    }
    const departureDate = moment(
      new Date(this.searchTemplate?.searchDate),
    ).format('YYYY-MM-DD');
    this.airlinesWithRateInsight = await this.rateService.getRateCoverage(
      this.searchTemplate?.origin,
      this.searchTemplate?.destination,
      this.getProductCode(),
      officeId,
      departureDate?.replace(/\//g, '-'),
    );
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  onToggleAllFlight(checked: boolean) {
    const flights = [];
    if (checked) {
      this.flightsByAirline.forEach((item) => {
        if (item.flights.length > 0) {
          flights.push(item.flights[0]?.sortingFlightKey);
        }
      });
    }
    this.onSelectAll.emit({ checked, payload: flights });
    this.isSelectAll = checked;
    this.cdr.detectChanges();
  }

  onSelectFlight(event: any, airlineCompany: AirlineBrand): void {
    this.onSelectSingleFlight.emit({ payload: event, airlineCompany });
  }

  onBookClicked(event: any): void {
    this.onFlightBook.emit(event);
  }

  onRequestBookClicked(event: any): void {
    this.onRequestBook.emit(event);
  }

  onRateChangeEvent(event: any): void {
    this.onRateChange.emit(event);
  }

  getQualityScore(airline): number {
    const qualityScore = this.qualityScoreList?.find((qualityScore) =>
      qualityScore.airlineCode.includes(airline.airlineCompanyCode),
    );
    return +(+qualityScore?.qualityScore * 100).toFixed(2) || undefined;
  }

  getAvgQualityScore(airline): number {
    const avgQualityScore = this.qualityScoreList?.find(
      (avgQualityScoreByAirline) =>
        avgQualityScoreByAirline.airlineCode.includes(
          airline.airlineCompanyCode,
        ),
    );
    return (
      +(+avgQualityScore?.avgQualityScoreByAirline * 100).toFixed(2) ||
      undefined
    );
  }
}
