import { MeasurementSystemEnum } from "@cai-services";
import { ConversionRate } from "../../../_models/measurement-system.model";

export class MeasurementUtil {
  static calculateVolume (
    width: number,
    length: number,
    height: number,
    pieces: number,
    measurementSystem = MeasurementSystemEnum.METRIC
  ): number {
    let conversionRate = ConversionRate.CM3_TO_M3;
    if (measurementSystem === MeasurementSystemEnum.IMPERIAL) {
      conversionRate = ConversionRate.IN3_TO_FT3;
    }
    const volume =
      (Math.abs(width) * Math.abs(length) * Math.abs(height) * pieces) /
      conversionRate;
    return Math.round(volume * 1e9) / 1e9;
  }

  static calculateChargeableWeight (
    volume: number,
    weight: number,
    measurementSystem = MeasurementSystemEnum.METRIC
  ): number {
    const conversionRate = ConversionRate.VOLUME_TO_WEIGHT[measurementSystem];
    return Math.ceil(Math.max(weight, volume / conversionRate) * 2) / 2;
  }

  static convertVolume (
    value: number,
    originalSystem: MeasurementSystemEnum,
    convertedSystem: MeasurementSystemEnum
  ) {
    if (originalSystem === convertedSystem) {
      return value;
    }
    if (originalSystem === MeasurementSystemEnum.IMPERIAL) {
      const convertedValue = value / ConversionRate.CF_TO_CM;
      if (convertedValue > 0 && convertedValue < 1) {
        return this.roundTo(
          convertedValue,
          this.getDecimalPlace(convertedValue)
        );
      }
      return Math.round(convertedValue * 1e2) / 1e2;
    }
    const convertedValue = value * ConversionRate.CF_TO_CM;
    if (convertedValue > 0 && convertedValue < 1) {
      return this.roundTo(convertedValue, this.getDecimalPlace(convertedValue));
    }

    return Math.round(convertedValue * 1e2) / 1e2;
  }

  static convertMeasure (
    value: number,
    originalSystem: MeasurementSystemEnum,
    convertedSystem: MeasurementSystemEnum
  ) {
    if (originalSystem === convertedSystem) {
      return value;
    }
    if (originalSystem === MeasurementSystemEnum.IMPERIAL) {
      const convertedValue = value / ConversionRate.CM_TO_IN;
      if (convertedValue > 0 && convertedValue < 1) {
        return this.roundTo(
          convertedValue,
          this.getDecimalPlace(convertedValue)
        );
      }
      return Math.round((value / ConversionRate.CM_TO_IN) * 1e2) / 1e2;
    }
    const convertedValue = value * ConversionRate.CM_TO_IN;
    if (convertedValue > 0 && convertedValue < 1) {
      return this.roundTo(convertedValue, this.getDecimalPlace(convertedValue));
    }
    return Math.round(value * ConversionRate.CM_TO_IN * 1e2) / 1e2;
  }

  static convertWeight (
    value: number,
    originalSystem: MeasurementSystemEnum,
    convertedSystem: MeasurementSystemEnum
  ) {
    if (originalSystem === convertedSystem) {
      return value;
    }
    if (originalSystem === MeasurementSystemEnum.IMPERIAL) {
      return Math.round((value / ConversionRate.KG_TO_LB) * 1e2) / 1e2;
    }
    return Math.round(value * ConversionRate.KG_TO_LB * 1e2) / 1e2;
  }

  static toggleMeasurementSystem (measurementSystem): MeasurementSystemEnum {
    return measurementSystem === MeasurementSystemEnum.IMPERIAL
      ? MeasurementSystemEnum.METRIC
      : MeasurementSystemEnum.IMPERIAL;
  }

  static roundTo (nb, decimals) {
    return (
      Math.round(nb * Math.pow(10, parseFloat(decimals))) /
      Math.pow(10, parseFloat(decimals))
    );
  }

  static getDecimalPlace (value) {
    const splittedValue = value.toString().split(".")[1];
    let place;
    for (const [index, num] of splittedValue.split("").entries()) {
      if (!place && +num > 0) {
        place = +index + 1;
      }
    }
    if (value > 1) {
      return 2;
    }
    return place > 2 ? place : 2;
  }
}
