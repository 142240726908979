import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Auth } from "@aws-amplify/auth";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthNoticeService } from "../../../core/_misc/auth-notice/auth-notice.service";

@Component({
  "selector": "kt-new-password",
  "templateUrl": "./new-password.component.html",
  "styleUrls": ["./new-password.component.scss"],
})
export class NewPasswordComponent implements OnInit, OnDestroy {
  hasFormErrors = false;
  viewLoading = false;
  loadingAfterSubmit = false;
  isNew: boolean;

  password: string;
  confirmPassword: string;
  invalidEmail: boolean;
  errorMessage: string;
  newPasswordForm: FormGroup;
  loading = false;
  /**
   * Component constructor
   *
   * @param dialogRef: MatDialogRef<RoleEditDialogComponent>
   * @param data: any
   * @param store: Store<AppState>
   */
  constructor (
    public dialogRef: MatDialogRef<NewPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private authNoticeService: AuthNoticeService
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit () {
    this.initNewPassForm();
  }

  initNewPassForm () {
    this.newPasswordForm = this.fb.group({
      "password": [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ]),
      ],
      "confirmPassword": [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
      ],
      "agree": [false, Validators.compose([Validators.required])],
    });
  }
  /**
   * On destroy
   */
  ngOnDestroy () {}

  /**
   * Save data
   */
  onSubmit () {
    console.log("Submitting request");

    const controls = this.newPasswordForm.controls;
    /** check form */
    if (this.newPasswordForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      this.authNoticeService.setNotice(
        $localize`:@@global.please-enter-the-same-password:Please enter the same password!`,
        "danger"
      );
      return;
    }

    return Auth.completeNewPassword(this.data.user, this.confirmPassword, {})
      .then((user) => {
        this.dialogRef.close({
          user,
        });
      })
      .catch((e) => {
        alert(
          e.message ||
            $localize`:@@global.cant-complete-new-password:Can't complete new password`
        );
      });
  }

  isControlHasError (controlName: string, validationType: string): boolean {
    const control = this.newPasswordForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  /** UI */
  /**
   * Returns component title
   */
  getTitle (): string {
    return $localize`:@@auth.new-password:New password`;
  }
}
