import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild, OnInit,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";
import { ICommodity } from "@cai-services";
import { DayDiffer } from "../../core/_base/layout/pipes/day-diff.pipe";
import {
  CustomFlightDetails,
  FlightInput,
} from "../../core/_models/flight-input.model";
import { UserMeasurementSystem } from "../../core/_models/measurement-system.model";
import {
  AdvancedBookingDetails,
  AirlineBrand,
  AirportLight,
  GlobalService,
} from "../../core";
import { BookingDetailsItemComponent } from "../booking-details-item/booking-details-item.component";

@Component({
  "selector": "kt-flight-search",
  "templateUrl": "./flight-search.component.html",
  "styleUrls": ["./flight-search.component.scss"],
  "providers": [DayDiffer, DatePipe],
})
export class FlightSearchComponent implements OnInit {
  @ViewChild("bookingDetailsItem")
  bookingDetailsItem: BookingDetailsItemComponent;

  @Input() showErrorMessage: boolean;
  @Output() flightSelected = new EventEmitter<CustomFlightDetails>();
  @Input() sliderLastArr: Date;
  @Input() airlineName: string;
  @Input() sliderFirstDep: Date;
  @Input() allotmentDetails: AdvancedBookingDetails;
  @Input() selectedFlightDays = [];
  @Input() originalFlightDateMax: Date;
  @Input() searchResults: FlightInput[];
  @Input() prevConditions: boolean;
  @Input() departureDate: string | Date;
  @Input() getFlightDaysToView: number;
  @Input() maxArrivalDate: Date;
  @Input() isAllotment: boolean;
  @Input() header: string;
  @Input() isShipmentDetailsPage: boolean;
  @Input() isPopover: boolean;
  @Input() parentCommodities: ICommodity[] = [];
  @Input() requestedFlight: CustomFlightDetails;
  @Input() allInRate: number;
  @Input() disableUnitSelector: boolean;
  @Input() noUpdateOnBlur: boolean;
  @Input() isAllotmentModification: boolean;
  @Input() measurementUnit: UserMeasurementSystem;
  @Input() minDate: Date = new Date();
  @Output() onRateChange = new EventEmitter<{
    sortKey: string;
    selectedRate: any;
  }>();
  @Output() moveFlightDaysView = new EventEmitter();
  @Output() dateChanged = new EventEmitter();

  selectedFlight: CustomFlightDetails;
  defaultHeader = "Edit Flight";
  flightIndex: number;
  regionAirports: AirportLight[] = [];
  airlines: AirlineBrand[];

  constructor (
    public activeModal: NgbActiveModal,
    private readonly globalService: GlobalService,
  ) {}

  async ngOnInit () {
    if (this.isAllotment) {
      this.regionAirports = await this.globalService.getAirports();
      this.airlines = await this.globalService.getAirlineCompanies();
    }
  }

  get isFormGroupDirty (): boolean {
    return (
      !!this.bookingDetailsItem?.formGroup?.dirty &&
      this.bookingDetailsItem?.formGroup?.valid
    );
  }

  checkForSelectedFlight () {
    this.flightIndex = this.findFlightIndex();

    if (this.flightIndex >= 0) {
      this.selectedFlight = this.searchResults[+this.flightIndex];
    } else {
      this.selectedFlight = null;
    }
  }

  public findFlightIndex (): number {
    return this.searchResults?.findIndex((flight) => (
        flight.airlineCode === this.requestedFlight.airlineCode &&
        flight.departureAirport === this.requestedFlight.departureAirport &&
        flight.arrivalAirport === this.requestedFlight.arrivalAirport &&
        (flight.flightNumber === this.requestedFlight.flightNumber ||
          !!flight.legs.find(
            (leg) => leg.flightNumber === this.requestedFlight.flightNumber,
          )) &&
        moment(flight.departureTime.split("T")[0]).format("yyyy-MM-DD") ===
          moment(this.requestedFlight.departureTime).format("yyyy-MM-DD") &&
        flight.rates.map((rate) => rate.allInRate).includes(this.allInRate)
      ));
  }

  onFlightSelect (selectedFlight: CustomFlightDetails) {
    this.selectedFlight = selectedFlight;
    this.flightSelected.emit(selectedFlight);
  }

  handleChevronClick (value: "prev" | "next") {
    this.moveFlightDaysView.emit({ "type": value });
  }

  onClose () {
    this.activeModal.close(false);
  }

  handleSubmit () {
    if (this.selectedFlight || this.bookingDetailsItem?.formGroup) {
      this.activeModal.dismiss({
        "selectedFlight": this.selectedFlight,
        "formGroup": this.bookingDetailsItem?.formGroup,
      });
    }
  }
  handleDepartureDateChange (newDate: string | Date) {
    if (this.departureDate !== newDate) {
      this.dateChanged.emit(moment(newDate).format("yyyy-MM-DD"));
    }
  }
}
