<div class="wrapper" #wrapper>
  <div
    class="dropdown"
    [class.readonly]="readonly"
    [class.disabled]="disabled"
    [class.mandatory-disabled]="mandatoryDisabled"
    [class.editable]="isEditable"
  >
    <input
      #input
      class="select-trigger"
      [class.invalid]="invalid"
      [placeholder]="placeholder || ''"
      [maxlength]="maxlength"
      [style.text-transform]="textTransform"
      [attr.readonly]="isEditable ? null : true"
      [disabled]="disabled || readonly ? true : null"
      [style.text-align]="alignment"
      [ngStyle]="{
        'padding-right': options?.length > 1 && !readonly ? '13px' : '5'
      }"
      [(ngModel)]="value"
      (mousedown)="open(dropdown, input)"
      (focus)="onFocus.emit()"
      (ngModelChange)="valueChange.emit($event)"
      (keypress)="onKeyPress($event)"
      (keydown)="onKeyDown($event)"
      (keyup)="onKeyUp($event)"
      (paste)="onPaste($event)"
      (blur)="blur()"
    />
    <span
      *ngIf="options?.length"
      (mousedown)="
        $event.preventDefault(); $event.stopPropagation(); open(dropdown, input)
      "
      class="caret"
    >
      <svg
        width="10"
        height="8"
        viewBox="0 0 10 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.19895 7C6.33317 7 9.95887 2.01102 9.30687 1.44502C8.65488 0.879015 1.80534 0.824923 1.09103 1.44502C0.376729 2.0659 4.06564 7 5.19895 7Z"
          [attr.fill]="mandatoryDisabled ? '#DADADA' : '#679EC6'"
          [attr.stroke]="mandatoryDisabled ? '#DADADA' : '#679EC6'"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
    <ng-template #dropdown>
      <div class="cai-input-select">
        <cdk-virtual-scroll-viewport
          class="cai-input-select-inner"
          [itemSize]="25"
          [style.height.px]="options.length * 25"
        >
          <div
            *cdkVirtualFor="let option of options"
            class="cai-input-select-item"
            [class.active]="[option?.label, option?.value].includes(value)"
            (mousedown)="multiselect ? check(option) : select(option)"
          >
            <span *ngIf="multiselect" class="mr-1" style="margin-left: -4px">
              <cai-checkbox
                [size]="20"
                [colorTick]="'#4D578D'"
                [isActive]="isChecked(option)"
              ></cai-checkbox>
            </span>
            <span class="d-flex w-100" [title]="option.label">{{
              option.label
            }}</span>
          </div>
          <div
            *ngIf="enableManualAdd && options.length"
            class="add-value"
            (mousedown)="$event.preventDefault(); enableEdit()"
          >
            <span>add value</span>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </ng-template>
  </div>
</div>
