<svg
  width="33"
  height="31"
  viewBox="0 0 33 31"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M21.0537 30.7135L23.8614 29.0506L23.8127 12.9623L31.5339 8.38944C32.6991 7.69936 33.0826 6.20185 32.3924 5.03679C31.7022 3.87174 30.2045 3.48833 29.0393 4.17841L21.3181 8.75122L7.23085 0.977052L4.42313 2.63989L15.0008 12.4926L7.27953 17.0654L3.09493 14.8034L0.989141 16.0505L5.71911 20.8338L7.64136 27.2799L9.74714 26.0328L9.77411 21.2765L17.4953 16.7037L21.0537 30.7135Z"
    fill="url(#paint0_linear_609:4574)"
  />
  <defs>
    <linearGradient
      id="paint0_linear_609:4574"
      x1="32.3924"
      y1="5.03679"
      x2="4.31711"
      y2="21.6683"
      gradientUnits="userSpaceOnUse"
    >
      <stop [attr.stop-color]="startClr" />
      <stop offset="1" [attr.stop-color]="endClr" />
    </linearGradient>
  </defs>
</svg>
