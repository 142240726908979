/**
 *
 */
export class Contact {
  /**
   *
   */
  countryCode: string;
  /**
   *
   */
  cityCode: string;
  /**
   *
   */
  phoneNumber: string;

  constructor (countryCode?: string, cityCode?: string, phoneNumber?: string) {
    this.countryCode = countryCode;
    this.cityCode = cityCode;
    this.phoneNumber = phoneNumber;
  }
}
