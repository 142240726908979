export const CopyDetailsTemplate =
  "<p>" +
  $localize`:@@email.route-options:route options for the shipment` +
  ": {origin} > {destination} | {commodityCode} | {chargeableWeight} <br/>" +
  "<b>----" +
  $localize`:@@email.route-summary:Route Summary` +
  ": {airlineName}</b>----<br/>" +
  "{duration} - {noOfStops} stop(s) - CO2 {co2}<br/>" +
  "{routeDetails}" +
  "{rateDetails}<p>";

export const CopyRouteDetailTemplate =
  "{origin} > {destination} | {flightNo} | {departureDateTime} - {arrivalDateTime}<br/>";

export const CopyRateDetailTemplate =
  "<b>-" +
  $localize`:@@email.rate-details:rate details` +
  "-</b><br/>" +
  $localize`:@@email.all-in-rate:all in rate` +
  ": {allInRate} | " +
  $localize`:@@email.total-cost:total all in cost` +
  ": {allInCost}<br/>" +
  "(" +
  $localize`:@@email.net-rate:net rate` +
  ": {totalNetRate}({netRate})" +
  "; " +
  $localize`:@@email.surcharge:surcharge` +
  ": {totalSurcharge}({surcharge})" +
  ")";
