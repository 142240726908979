import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { MeasurementSystemEnum } from "@cai-services";
import { MeasurementUnit } from "../../../../core/_models/measurement-system.model";

const INPUT_REGEX_DEFAULT = /[0-9]/g,
 INPUT_REGEX_DECIMAL = /[0-9.]/g,
 FORMAT_REGEX_DEFAULT = /^\d{0,15}?$/,
 FORMAT_REGEX_DECIMAL = /^\d{0,15}(\.\d{0,4})?$/;

@Component({
  "template": "",
})
export class CaiLoadTypeQuantityInputBaseComponent
  implements ControlValueAccessor, OnChanges
{
  @ViewChild("input") input: ElementRef;
  @Input() id: string;
  @Input() unit: string;
  @Input() label: string;
  @Input() readonly: boolean;
  @Input() addErrorLabel: boolean;
  @Input() floatLabel: boolean;
  @Input() isDisabled: boolean;
  @Input() viewOnly: boolean;
  @Input() disableDecimal: boolean;
  @Input() decimalPlace = 2;
  @Input() disableUnitSelector: boolean;
  @Input() measurementSystem: MeasurementSystemEnum;
  @Input() selectedUnit: MeasurementUnit;
  @Input() measurementUnits: MeasurementUnit[];
  @Input() noUpdateOnBlur: boolean;
  @Input() customValue: number;
  @Output() selectedUnitChange = new EventEmitter<MeasurementUnit>();
  @Output() measurementSystemChange = new EventEmitter<MeasurementSystemEnum>();

  value: number;
  focused: boolean;
  touched: boolean;
  lastValue: number;

  onChange = (value: number) => {};
  onTouched = () => {};

  constructor (
    @Self()
    @Optional()
    protected readonly control: NgControl,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }
  ngOnChanges (changes: SimpleChanges): void {
    if (changes.customValue) {
      this.writeValue(+this.customValue);
    }
  }
  writeValue (value: number) {
    this.value =
      value !== null && value !== undefined && !isNaN(value) ? +value : null;
    this.cdr.detectChanges();
  }

  registerOnChange (onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched (onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched () {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState (disabled: boolean) {
    this.isDisabled = disabled;
  }

  onKeyPress ($event) {
    const selectionStart = this.input.nativeElement.selectionStart,
     selectionEnd = this.input.nativeElement.selectionEnd,
     text = this.value != null ? this.value.toString() : "",

     value =
      text.substring(0, selectionStart) +
      $event.key +
      text.substring(selectionEnd);
    if (!this.validateInput(value)) {
      $event.preventDefault();
    }
  }

  onKeyDown ($event) {
    const key = $event.keyCode || $event.charCode;
    if (key && (+key === 8 || +key === 46)) {
      this.lastValue = this.value != null ? this.value : null;
    }
  }

  onKeyUp ($event) {
    const key = $event.keyCode || $event.charCode;
    if (key === 9) {
      return;
    }
    const value = this.value;
    if (
      [8, 49].includes(key) &&
      value &&
      !this.validateInput(value.toString())
    ) {
      this.value = this.lastValue;
    }
    if (
      ![null, undefined].includes(value) ||
      (value && this.validateInput(value.toString()))
    ) {
      this.markAsTouched();
      this.onChange(this.value);
    }
  }

  onPaste ($event) {
    const clipboardData = $event.clipboardData,
     pastedText: string = clipboardData.getData("text");

    if (pastedText && pastedText.length) {
      if (!this.validateInput(pastedText)) {
        $event.preventDefault();
      }
    }
  }

  onBlur () {
    this.markAsTouched();
    if (!this.noUpdateOnBlur) {
      this.onChange(this.value);
    }
  }

  validateInput (value: string): boolean {
    for (let i = 0; i < value.length; i++) {
      const char = value.charAt(i),
       inputRegex = new RegExp(
        this.disableDecimal ? INPUT_REGEX_DEFAULT : INPUT_REGEX_DECIMAL,
      );
      if (!inputRegex.test(char)) {
        return false;
      }
    }
    const formatRegex = new RegExp(
      this.disableDecimal ? FORMAT_REGEX_DEFAULT : FORMAT_REGEX_DECIMAL,
    );
    return formatRegex.test(value);
  }

  get showUnitSelector (): boolean {
    return (
      !this.isDisabled &&
      !this.readonly &&
      !this.disableUnitSelector &&
      !this.viewOnly &&
      this.measurementUnits?.length > 1
    );
  }

  public get invalid (): boolean {
    if (!this.control) {
      return false;
    }
    const value = this.control.value;
    if (value && value.toString() === ".") {
      return true;
    }
    return this.control.invalid;
  }

  public get showError (): boolean {
    if (!this.control) {
      return false;
    }
    const { dirty, touched } = this.control;
    return this.invalid ? dirty || touched : false;
  }

  public get errorMessage (): string {
    const error = Object.values(this.control.errors)[0]?.message || null;
    if (error) {
      return error.replace("{field}", this.label);
    }
    return null;
  }
}
