import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  LoadTypeEnum,
  MeasurementSystemEnum,
  WeightTypeEnum,
} from "@cai-services";
import { cloneDeep } from "lodash";
import { MeasurementUtil } from "../../core/_base/crud/utils/measurement.util";
import {
  MeasurementUnit,
  MeasurementUnits,
} from "../../core/_models/measurement-system.model";
import { Dimension } from "../../core/_models/dimension.model";
import { BUP } from "../../core/_models/bup.model";
import { FormValidators } from "../../validators/form.validator";
import { CaiLoadTypeBupComponent } from "./components/load-type-bup/load-type-bup.component";
import { CaiLoadTypeDimensionComponent } from "./components/load-type-dimension/load-type-dimension.component";
import { LoadTypeMeasurementUnits } from "./constants/measurements.const";
import { MeasurementTypeEnum } from "./enum/measurement-type.enum";
import { LoadTypeUnit } from "./model/load-type-unit.model";
import { CaiBUPUtil } from "./utils/bup.util";
import { CaiDimensionUtil } from "./utils/dimension.util";
import { CaiLoadTypeMeasurementUtil } from "./utils/measurement.util";

const LOAD_TYPE_FIELDS = ["weight", "volume", "pieces", "chargeableWeight"];

@Component({
  "template": "",
})
export class CaiLoadTypeBaseComponent implements OnInit, OnChanges {
  @ViewChild("dimensionTable") dimensionTable: CaiLoadTypeDimensionComponent;
  @ViewChild("bupTable") bupTable: CaiLoadTypeBupComponent;
  @Input() formGroup: FormGroup;
  @Input() dimensions: Dimension[] = [];
  @Input() bups: BUP[] = [];
  @Input() viewOnly: boolean;
  @Input() floatLabel: boolean;
  @Input() loadTypeMode: LoadTypeEnum = LoadTypeEnum.TOTAL;
  @Input() measurementSystem: MeasurementSystemEnum =
    MeasurementSystemEnum.METRIC;
  @Input() enableUnitSwitch: boolean;
  @Input() isEditableChargeableWeight: boolean;
  @Input() defaultMeasurementSystem: MeasurementSystemEnum;
  @Input() weightUnit: MeasurementUnit;
  @Input() disableUnitSelector: boolean;
  @Input() dimensionUnitDetail: MeasurementUnit;
  @Output() bupsChargeableWeightChange = new EventEmitter<BUP[]>();
  @Output() loadTypeModeChange = new EventEmitter<LoadTypeEnum>();
  @Output() measurementUnitChange = new EventEmitter<LoadTypeUnit>();
  @Output() formGroupChange = new EventEmitter<FormGroup>();
  @Output() onFocus = new EventEmitter();
  @Output() weightMeasurementSystemChanged =
    new EventEmitter<MeasurementSystemEnum>();

  piecesLabel = $localize`:@@load-type.pieces:Pieces`;
  weightLabel = $localize`:@@load-type.weight:Weight`;
  touchedDimension: boolean;
  touchedBUP: boolean;
  measurementUnit = MeasurementUnits.METRIC;
  volumeUnit = MeasurementUnits.METRIC;
  loadTypeEnum = LoadTypeEnum;
  measurementTypeEnum = MeasurementTypeEnum;
  loadTypeUnitState: { [key: string]: LoadTypeUnit } = {};
  savedDimensions: Dimension[] = [];
  savedFormGroup: FormGroup;
  displayErrors: boolean;
  updateCount = 0;
  prevDimensionUnitsState: LoadTypeUnit;
  prevLoadType: LoadTypeEnum;
  chargeableUnitChangeCount = 0;

  constructor (
    protected readonly fb: FormBuilder,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    if (!this.formGroup) {
      this.formGroup = this.fb.group({
        "weight": [
          null,
          [
            FormValidators.required(),
            FormValidators.noDecimalPoint(),
            FormValidators.minValue(0.1),
            FormValidators.lessThan(
              MeasurementUtil.convertWeight(
                1000000,
                MeasurementSystemEnum.METRIC,
                this.defaultMeasurementSystem,
              ),
              this.totalUnitsState?.weightUnitCode,
            ),
          ],
        ],
        "volume": [
          null,
          [
            FormValidators.required(),
            FormValidators.minValue(0.0001),
            FormValidators.noDecimalPoint(),
            FormValidators.lessThan(
              MeasurementUtil.convertVolume(
                1000,
                MeasurementSystemEnum.METRIC,
                this.defaultMeasurementSystem,
              ),
              this.volumeUnit?.VOLUME?.code,
            ),
          ],
        ],
        "pieces": [
          null,
          [
            FormValidators.required(),
            FormValidators.minValue(1),
            FormValidators.lessThan(1000),
          ],
        ],
        "chargeableWeight": [null],
      });
    }
  }

  ngOnInit (): void {
    this.volumeUnit = this.measurementUnit;
    this.formGroup.get("volume").valueChanges.subscribe(() => {
      if (this.checkUpdateCondition()) {
        this.calculateChargeableWeight();
      }
      this.updateCount += 1;
      this.prevDimensionUnitsState = this.dimensionUnitsState
        ? JSON.parse(JSON.stringify(this.dimensionUnitsState))
        : null;
      this.prevLoadType = this.loadTypeMode;
    });
    this.formGroup.get("weight").valueChanges.subscribe(() => {
      if (
        this.prevLoadType === LoadTypeEnum.DIMENSIONS &&
        this.loadTypeMode === LoadTypeEnum.DIMENSIONS &&
        !this.formGroup.get("chargeableWeight").value
      ) {
        this.updateCount = 6;
      }
      if (this.checkUpdateCondition()) {
        this.calculateChargeableWeight();
      }
      this.prevDimensionUnitsState = this.dimensionUnitsState
        ? JSON.parse(JSON.stringify(this.dimensionUnitsState))
        : null;
      this.updateCount += 1;
      this.prevLoadType = this.loadTypeMode;
    });
    this.formGroup.get("chargeableWeight").valueChanges.subscribe((value) => {
      if (
        this.loadTypeMode === LoadTypeEnum.BUP &&
        this.isEditableChargeableWeight &&
        value !== this.formGroup.value.chargeableWeight
      ) {
        this.bupsChargeableWeightChange.emit(this.bups);
      }
    });
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.hasOwnProperty("measurementSystem")) {
      this.savedFormGroup = this.fb.group({
        "weight": [null],
        "volume": [null],
        "pieces": [null],
        "chargeableWeight": [null],
      });
      this.measurementUnit = cloneDeep(
        LoadTypeMeasurementUnits[this.measurementSystem],
      );
      this.volumeUnit = this.measurementUnit;
      if (this.measurementUnit?.WEIGHT) {
        this.measurementUnit.WEIGHT = cloneDeep(
          LoadTypeMeasurementUnits[MeasurementSystemEnum.METRIC].WEIGHT,
        );
      }
      this.upsertLoadTypeUnit(this.measurementSystem, LoadTypeEnum.TOTAL);
      this.upsertLoadTypeUnit(this.measurementSystem, LoadTypeEnum.DIMENSIONS);
      this.upsertLoadTypeUnit(this.measurementSystem, LoadTypeEnum.BUP);
    }

    if (changes.hasOwnProperty("defaultMeasurementSystem")) {
      this.formGroup.get("weight").clearValidators();
      this.formGroup
        .get("weight")
        .setValidators([
          FormValidators.required(),
          FormValidators.minValue(0.1),
          FormValidators.noDecimalPoint(),
          FormValidators.lessThan(
            MeasurementUtil.convertWeight(
              1000000,
              MeasurementSystemEnum.METRIC,
              this.totalUnitsState.measurementSystem,
            ),
            this.totalUnitsState?.weightUnitCode,
          ),
        ]);
    }

    if (
      changes.hasOwnProperty("dimensionUnitDetail") &&
      this.dimensionUnitDetail &&
      JSON.stringify(changes?.dimensionUnitDetail?.currentValue) !==
        JSON.stringify(changes?.dimensionUnitDetail?.previousValue)
    ) {
      this.measurementUnit.MEASURE = cloneDeep(this.dimensionUnitDetail);
      this.upsertLoadTypeUnit(this.measurementSystem, LoadTypeEnum.DIMENSIONS);
      this.updateTotals();
      this.cdr.detectChanges();
    }
    if (changes.hasOwnProperty("defaultMeasurementSystem")) {
      this.formGroup.get("volume").clearValidators();
      this.formGroup
        .get("volume")
        .setValidators([
          FormValidators.required(),
          FormValidators.minValue(0.0001),
          FormValidators.noDecimalPoint(),
          FormValidators.lessThan(
            MeasurementUtil.convertVolume(
              1000,
              MeasurementSystemEnum.METRIC,
              this.defaultMeasurementSystem,
            ),
            this.volumeUnit?.VOLUME?.code,
          ),
        ]);
    }
    this.cdr.detectChanges();
  }

  checkUpdateCondition (): boolean {
    const dimensionCondition =
      this.updateCount > 5 ||
      !this.isEditableChargeableWeight ||
      (this.prevDimensionUnitsState &&
        JSON.stringify(this.dimensionUnitsState) !==
          JSON.stringify(this.prevDimensionUnitsState)),
     otherTypeCondition =
      this.updateCount > 1 ||
      this.loadTypeMode === LoadTypeEnum.TOTAL ||
      !this.isEditableChargeableWeight;
    return this.loadTypeMode === LoadTypeEnum.DIMENSIONS
      ? dimensionCondition
      : otherTypeCondition;
  }

  handleDimensionUnitChange (event: any) {
    this.dimensionUnitsState.dimensionUnitCode = event.code;
    if (this.isEditableChargeableWeight) {
      this.measurementUnitChange.emit(this.dimensionUnitsState);
    }
  }

  upsertLoadTypeUnit (
    measurementSystem: MeasurementSystemEnum,
    type: LoadTypeEnum,
  ) {
    if (!measurementSystem) {
      return;
    }
    const measurementUnit = this.measurementUnit;
    this.loadTypeUnitState[type] = {
      measurementSystem,
      "weightUnitCode": measurementUnit[MeasurementTypeEnum.WEIGHT].code,
      "weightUnitDetail": null,
      "dimensionUnitCode": measurementUnit[MeasurementTypeEnum.MEASURE].code,
      "dimensionUnitDetail": null,
    } as LoadTypeUnit;

    Object.keys(measurementUnit).forEach((key) => {
      switch (measurementUnit[key]?.code) {
        case this.loadTypeUnitState[type].weightUnitCode: {
          this.loadTypeUnitState[type].weightUnitDetail = measurementUnit[key];
          break;
        }
        case this.loadTypeUnitState[type].dimensionUnitCode: {
          this.loadTypeUnitState[type].dimensionUnitDetail =
            measurementUnit[key];
          break;
        }
      }
    });
  }

  validate (): void {
    if (this.dimensionTable) {
      if (this.dimensions.length > 1) {
        this.displayErrors = true;
      }
      this.dimensionTable.validate();
    }
    if (this.bupTable) {
      this.bupTable.validate();
    }
  }

  updateTotals (): void {
    this.calculateTotalPiece();
    this.calculateTotalVolume();
    this.calculateTotalWeight();
    this.cdr.detectChanges();
  }

  calculateChargeableWeight () {
    const field = this.formGroup.get("chargeableWeight"),
     weight = this.formGroup.get("weight").value,
     volume = this.formGroup.get("volume").value;
    if (weight && volume && weight !== "." && volume !== ".") {
      let chargeableWeight = null,
       weightConvert: MeasurementSystemEnum[] = [];
      const convertedWeightUnit = this.totalUnitsState?.weightUnitCode;
      if (convertedWeightUnit === "lb") {
        weightConvert = [
          MeasurementSystemEnum.IMPERIAL,
          MeasurementSystemEnum.METRIC,
        ];
      }

      chargeableWeight = this.getChargeableWeight(weightConvert);
      field.setValue(chargeableWeight);
      field.setErrors(null);
    } else {
      field.setValue(null);
      field.setErrors({ "required": {} });
    }
  }

  getChargeableWeight (weightConversion: MeasurementSystemEnum[]): number {
    let totalVolume = this.formGroup.get("volume").value,
     totalWeight = this.formGroup.get("weight").value;
    if (this.volumeUnit?.VOLUME?.code === "ft3") {
      totalVolume = MeasurementUtil.convertVolume(
        totalVolume,
        MeasurementSystemEnum.IMPERIAL,
        MeasurementSystemEnum.METRIC,
      );
    }
    if (weightConversion?.length === 2) {
      totalWeight = MeasurementUtil.convertWeight(
        totalWeight,
        weightConversion[0],
        weightConversion[1],
      );
    }
    let chargeableWeight = MeasurementUtil.calculateChargeableWeight(
      totalVolume,
      totalWeight,
      MeasurementSystemEnum.METRIC,
    );
    if (weightConversion?.length === 2) {
      chargeableWeight = MeasurementUtil.convertWeight(
        chargeableWeight,
        weightConversion[1],
        weightConversion[0],
      );
    }

    return chargeableWeight;
  }

  calculateTotalWeight () {
    let weight = [],
     shouldCalculate = false;
    if (this.loadTypeMode === LoadTypeEnum.DIMENSIONS) {
      weight = this.dimensions
        .filter((item) =>
          CaiDimensionUtil.isDimensionValid(
            item,
            this.loadTypeUnitState[LoadTypeEnum.DIMENSIONS]?.weightUnitDetail,
            this.loadTypeUnitState[LoadTypeEnum.DIMENSIONS]
              ?.dimensionUnitDetail,
          ),
        )
        .map((item) => {
          if (item.weightType === WeightTypeEnum.PER_ITEM) {
            return +item.weight * +item.numOfItems;
          } else {
            return +item.weight;
          }
        });
      if (weight.length) {
        this.touchedDimension = true;
      }
      shouldCalculate = this.touchedDimension;
    } else if (this.loadTypeMode === LoadTypeEnum.BUP) {
      weight = this.bups
        .filter((item) =>
          CaiBUPUtil.isBUPValid(
            item,
            this.loadTypeUnitState[LoadTypeEnum.BUP]?.weightUnitDetail,
          ),
        )
        .map((item) => {
          if (item.weightType === WeightTypeEnum.PER_ITEM) {
            return +item.weight * +item.quantity;
          } else {
            return +item.weight;
          }
        });
      if (weight.length) {
        this.touchedBUP = true;
      }
      shouldCalculate = this.touchedBUP;
    }
    if (weight.length || shouldCalculate) {
      const totalWeight = weight.reduce((a, b) => a + b, 0);
      this.formGroup.get("weight").setValue(totalWeight);
    }
  }

  calculateTotalVolume () {
    let volume = [],
     shouldCalculate = false;

    if (this.loadTypeMode === LoadTypeEnum.DIMENSIONS) {
      volume = this.dimensions
        .filter((item) =>
          CaiDimensionUtil.isDimensionValid(
            item,
            this.loadTypeUnitState[LoadTypeEnum.DIMENSIONS]?.weightUnitDetail,
            this.loadTypeUnitState[LoadTypeEnum.DIMENSIONS]
              ?.dimensionUnitDetail,
          ),
        )
        .map((item) => {
          const _volume = MeasurementUtil.calculateVolume(
            item.dimensionWidth,
            item.dimensionLength,
            item.dimensionHeight,
            item.numOfItems,
            this.totalUnitsState?.dimensionUnitDetail.code === "cm"
              ? MeasurementSystemEnum.METRIC
              : MeasurementSystemEnum.IMPERIAL,
          );
          return MeasurementUtil.convertVolume(
            _volume,
            this.totalUnitsState?.dimensionUnitDetail.code === "cm"
              ? MeasurementSystemEnum.METRIC
              : MeasurementSystemEnum.IMPERIAL,
            this.measurementSystem,
          );
        });
      if (volume.length) {
        this.touchedDimension = true;
      }
      shouldCalculate = this.touchedDimension;
    } else if (this.loadTypeMode === LoadTypeEnum.BUP) {
      volume = this.bups
        .filter((item) =>
          CaiBUPUtil.isBUPValid(
            item,
            this.loadTypeUnitState[LoadTypeEnum.BUP]?.weightUnitDetail,
          ),
        )
        .map((item) => +item.volume * +item.quantity);
      if (volume.length) {
        this.touchedBUP = true;
      }
      shouldCalculate = this.touchedBUP;
    }
    if (volume.length || shouldCalculate) {
      const totalVolume = volume.reduce((a, b) => a + b, 0);
      this.formGroup.get("volume").setValue(totalVolume);
    }
  }

  calculateTotalPiece () {
    let pieces = [],
     shouldCalculate = false,
     field = null;
    if (this.loadTypeMode === LoadTypeEnum.DIMENSIONS) {
      pieces = this.dimensions.filter((item) =>
        CaiDimensionUtil.isDimensionValid(
          item,
          this.loadTypeUnitState[LoadTypeEnum.DIMENSIONS]?.weightUnitDetail,
          this.loadTypeUnitState[LoadTypeEnum.DIMENSIONS]?.dimensionUnitDetail,
        ),
      );
      if (pieces.length) {
        this.touchedDimension = true;
      }
      field = "numOfItems";
      shouldCalculate = this.touchedDimension;
    } else if (this.loadTypeMode === LoadTypeEnum.BUP) {
      pieces = this.bups.filter((item) =>
        CaiBUPUtil.isBUPValid(
          item,
          this.loadTypeUnitState[LoadTypeEnum.BUP]?.weightUnitDetail,
        ),
      );
      if (pieces.length) {
        this.touchedBUP = true;
      }
      field = "quantity";
      shouldCalculate = this.touchedBUP;
    }
    if (field && (pieces.length || shouldCalculate)) {
      const totalPiece = pieces.reduce((acc, curr) => {
        acc += +curr[field];
        return acc;
      }, 0);
      this.formGroup.get("pieces").setValue(totalPiece);
    }
  }

  changeMode (mode: LoadTypeEnum) {
    this.chargeableUnitChangeCount = 0;
    switch (mode) {
      case LoadTypeEnum.TOTAL: {
        this.copyFormValues(this.formGroup, this.savedFormGroup);
        this.savedDimensions = [...this.dimensions];
        break;
      }
      case LoadTypeEnum.DIMENSIONS: {
        if (this.loadTypeMode === LoadTypeEnum.TOTAL) {
          this.copyFormValues(this.savedFormGroup, this.formGroup);
        }
        this.copyFormValues(this.formGroup, null);
        this.dimensions = [...this.savedDimensions];
        break;
      }
      case LoadTypeEnum.BUP: {
        if (this.loadTypeMode === LoadTypeEnum.TOTAL) {
          this.copyFormValues(this.savedFormGroup, this.formGroup);
        }
        this.copyFormValues(this.formGroup, null);
        this.savedDimensions = [...this.dimensions];
        break;
      }
    }
    this.loadTypeMode = mode;
    this.onFocus.emit();
    this.loadTypeModeChange.emit(mode);
    this.updateCount = 0;
    this.cdr.detectChanges();
  }

  copyFormValues (targetForm: FormGroup, sourceForm): void {
    LOAD_TYPE_FIELDS.forEach((field) => {
      const previousTargetValue = targetForm.get(field).value;
      if (sourceForm && sourceForm.get(field) && sourceForm.get(field).value) {
        targetForm.get(field).setValue(sourceForm.get(field).value);
        targetForm.get(field).markAsDirty();
      } else {
        targetForm.get(field).setValue(null);
        targetForm.get(field).markAsUntouched();
        targetForm.get(field).markAsPristine();
      }
      let shouldMarkAsTouched = false;
      if (
        targetForm.get(field).value !== previousTargetValue &&
        targetForm.get(field).touched
      ) {
        shouldMarkAsTouched = true;
      }
      if (shouldMarkAsTouched) {
        targetForm.get(field).markAsTouched();
      }
    });
  }

  convertTotalWeight (unitState: LoadTypeUnit, $event: MeasurementUnit) {
    this.weightMeasurementSystemChanged.emit(unitState.measurementSystem);
    unitState.weightUnitCode = $event?.code;
    const field = this.formGroup.get("weight");
    if (field?.value) {
      const totalWeight = MeasurementUtil.convertWeight(
        field.value,
        MeasurementUtil.toggleMeasurementSystem(
          unitState.weightUnitCode === "kg"
            ? MeasurementSystemEnum.METRIC
            : MeasurementSystemEnum.IMPERIAL,
        ),
        unitState.weightUnitCode === "kg"
          ? MeasurementSystemEnum.METRIC
          : MeasurementSystemEnum.IMPERIAL,
      );
      field.setValue(totalWeight);
    }
    field.clearValidators();
    field.setValidators([
      FormValidators.required(),
      FormValidators.minValue(0.1),
      FormValidators.noDecimalPoint(),
      FormValidators.lessThan(
        MeasurementUtil.convertWeight(
          1000000,
          MeasurementSystemEnum.METRIC,
          this.totalUnitsState.measurementSystem,
        ),
        this.totalUnitsState?.weightUnitCode,
      ),
    ]);
    field.updateValueAndValidity();
    if (this.isEditableChargeableWeight) {
      this.measurementUnitChange.emit(unitState);
    }
  }

  getMeasurementUnits (type: MeasurementTypeEnum) {
    if (this.enableUnitSwitch) {
      return CaiLoadTypeMeasurementUtil.getMeasurementUnits(type);
    }
    return [];
  }

  get totalUnitsState (): LoadTypeUnit {
    switch (this.loadTypeMode) {
      case LoadTypeEnum.TOTAL:
        return this.loadTypeUnitState[LoadTypeEnum.TOTAL];
      case LoadTypeEnum.DIMENSIONS:
        return this.loadTypeUnitState[LoadTypeEnum.DIMENSIONS];
      case LoadTypeEnum.BUP:
        return this.loadTypeUnitState[LoadTypeEnum.BUP];
      default:
        return null;
    }
  }

  get dimensionUnitsState (): LoadTypeUnit {
    return this.loadTypeUnitState[LoadTypeEnum.DIMENSIONS];
  }

  get bupUnitsState (): LoadTypeUnit {
    return this.loadTypeUnitState[LoadTypeEnum.BUP];
  }

  get shouldForceDisplayErrors (): boolean {
    return this.displayErrors && this.showDimensions;
  }

  get disableTotals (): boolean {
    return this.showDimensions || this.showBUP;
  }

  get showDimensions (): boolean {
    return this.loadTypeMode === LoadTypeEnum.DIMENSIONS && !this.viewOnly;
  }

  get showBUP (): boolean {
    return this.loadTypeMode === LoadTypeEnum.BUP && !this.viewOnly;
  }

  public get errorMessage (): any {
    let piecesError = "",
     weightError = "",
     volumeError = "",
     chargeableWeightError = "";
    const piecesFormControl = this.formGroup.controls?.pieces,
     weightFormControl = this.formGroup.controls?.weight,
     volumeFormControl = this.formGroup.controls?.volume,
     chargeableWeight = this.formGroup.controls?.chargeableWeight;
    if (piecesFormControl?.errors) {
      piecesError =
        piecesFormControl.dirty || piecesFormControl.touched
          ? Object.values(piecesFormControl.errors)?.[0]?.message?.replace(
              "{field}",
              "Pieces",
            )
          : "";
    }
    if (weightFormControl?.errors) {
      weightError =
        weightFormControl.dirty ||
        weightFormControl.touched ||
        Object.keys(weightFormControl.errors)?.includes("lessThan")
          ? Object.values(weightFormControl.errors)?.[0]?.message?.replace(
              "{field}",
              "Total Weight",
            )
          : "";
    }
    if (volumeFormControl?.errors) {
      volumeError =
        volumeFormControl.dirty || volumeFormControl.touched
          ? Object.values(volumeFormControl.errors)?.[0]?.message?.replace(
              "{field}",
              "Volume",
            )
          : "";
    }
    if (chargeableWeight?.errors) {
      chargeableWeightError =
        chargeableWeight.dirty || chargeableWeight.touched
          ? Object.values(chargeableWeight.errors)?.[0]?.message?.replace(
              "{field}",
              "Chargeable Weight",
            )
          : "";
    }
    return [
      piecesError,
      weightError,
      volumeError,
      chargeableWeightError,
    ].filter((error) => !!error);
  }
}
