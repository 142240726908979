// Angular
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  "selector": "kt-copy-banner-dialog",
  "templateUrl": "./copy-banner-dialog.component.html",
})
export class CopyBannerDialogComponent {
  constructor (
    public dialogRef: MatDialogRef<CopyBannerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick (): void {
    this.dialogRef.close();
  }
}
