<svg
  class="svg-office"
  viewBox="0 0 30 27"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M3.00016 24.3333V6.6C3.00008 6.3263 3.08423 6.05921 3.24119 5.83499C3.39814 5.61076 3.6203 5.44027 3.8775 5.34667L16.7722 0.658669C16.8729 0.622011 16.981 0.61018 17.0872 0.624181C17.1935 0.638181 17.2948 0.677601 17.3826 0.739096C17.4704 0.800592 17.5421 0.88235 17.5916 0.97744C17.641 1.07253 17.6669 1.17815 17.6668 1.28534V7.88934L26.0882 10.696C26.3538 10.7845 26.5848 10.9543 26.7485 11.1814C26.9122 11.4085 27.0002 11.6814 27.0002 11.9613V24.3333H29.6668V27H0.333496V24.3333H3.00016ZM5.66683 24.3333H15.0002V4.14L5.66683 7.53467V24.3333ZM24.3335 24.3333V12.9227L17.6668 10.7V24.3333H24.3335Z"
    fill="currentColor"
  />
</svg>
