import { Inject, Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import { Observable } from "rxjs";
import { getFlights } from "../../graphql/query/flight.query";
import { getSearchUUID } from "../../graphql/query/search.query";
import { updateFlights } from "../../graphql/subscription/flight.subscription";
import { ISearchRequest } from "../../model/search-request.model";
import { GraphqlUtil } from "../../utils/graphql.util";
import { ENV } from "../../../cai-services.module";
import { IFlightSearchDetail } from "../../model/flight-search-detail.model";

@Injectable({
  "providedIn": "root",
})
export class SearchService {
  constructor (@Inject(ENV) private environment) {}

  async getSearchUUID (
    request: ISearchRequest,
    isPublicSearch: boolean
  ): Promise<{
    data: {
      getSearchUUID: string;
    };
  }> {
    request.clientEnv = this.environment.name;
    return API.graphql({
      "query": getSearchUUID,
      "variables": request,
      "authMode": GraphqlUtil.getAuthMode(isPublicSearch),
    }) as any;
  }

  async getFlights (
    id: string,
    isPublicPage?: boolean
  ): Promise<{
    data: {
      getFlights: IFlightSearchDetail[];
    };
  }> {
    return API.graphql({
      "query": getFlights,
      "variables": { id },
      "authMode": GraphqlUtil.getAuthMode(isPublicPage),
    }) as any;
  }

  subscribeFlight (
    id: string,
    isPublicPage?: boolean
  ): Observable<{
    value: {
      data: { FlightUpdated: IFlightSearchDetail };
      errors?: any;
    };
  }> {
    return API.graphql({
      "query": updateFlights,
      "variables": { id },
      "authMode": GraphqlUtil.getAuthMode(isPublicPage),
    }) as any;
  }
}
