<div class="kt-login__container">
  <kt-login-lhs class="kt-login__lhsContainer"></kt-login-lhs>
  <div class="kt-login__rhs">
    <div class="kt-login__head">
      <kt-language-selector *ngIf="features.i18n" class="language-selector">
      </kt-language-selector>
    </div>
    <div class="kt-login__body" *ngIf="!requireOTP">
      <div
        class="kt-login__logo"
        [class.other-brand]="otherBrand"
        (click)="openSite()"
        [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')' }"
      ></div>
      <div
        class="kt-forgot-password__bodyWrapper"
        *ngIf="!showSuccessMessage && !requireNewPassword"
      >
        <h3
          class="title"
          i18n="@@forgotPassword.forgotPassword"
          *ngIf="OTPStatus !== 'correct'"
        >
          Forgot your Password
          <span
            style="cursor: pointer"
            caiPopover
            [enablePopover]="true"
            [target]="cargoaiAppsTooltip"
            [allowHoverOnTarget]="true"
            [placement]="'bottom'"
          >
            <img src="/assets/media/icons/info-med.svg" alt="info icon" />
            <ng-template #cargoaiAppsTooltip>
              <kt-popover
                [arrowColor]="'#679EC6'"
                [bgColor]="'#679EC6'"
                [textColor]="'white'"
                message="Your password is the same for all applications powered by CargoAi including CargoMART, CargoWALLET and CargoGATE"
                placement="bottom"
              >
              </kt-popover>
            </ng-template>
          </span>
        </h3>
        <p
          class="otp-help text-info"
          i18n="@@forgotPassword.enter-email-for-password-reset"
          *ngIf="OTPStatus !== 'correct'"
        >
          Enter the email associated with this account to receive to receive
          password reset instructions
        </p>
        <form
          class="kt-form"
          [formGroup]="forgotPasswordForm"
          *ngIf="forgotPasswordForm"
          autocomplete="off"
        >
          <div class="form-group">
            <mat-label i18n="@@global.email" class="text-uppercase"
              >Email</mat-label
            >
            <kt-custom-input
              id="email"
              formControlName="email"
              type="email"
              placeholder=""
              autocomplete="off"
              [hasError]="isControlHasError('email', '', true)"
            ></kt-custom-input>
          </div>
        </form>
        <div class="kt-forgotPassword__actions">
          <button
            class="otp-container__actionButton backButton"
            i18n="@@otp.back"
            routerLink="/auth/login"
          >
            BACK
          </button>
          <button
            (click)="submit()"
            [ngClass]="{
              'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                loading
            }"
            id="kt_login_signin_submit"
            class="btn btn-primary kt-login__btn-primary d-flex align-items-center justify-content-center resetButton"
            i18n="@@forgot-password.resetPassword"
            [disabled]="isDisabled()"
          >
            RESET PASSWORD
          </button>
        </div>
        <div class="kt-login__signUp-wrapper" *ngIf="registerUrl">
          <span class="kt-login__signup-label" i18n="registeration text"
            >Don't have an account yet?</span
          >&nbsp;&nbsp;
          <a
            href="javascript:;"
            [routerLink]="registerUrl"
            class="kt-link kt-login__signup-link"
            i18n="@@global.sign-up"
            >Sign Up</a
          >
        </div>
      </div>
      <cai-success-message
        [isShow]="showSuccessMessage"
        [title]="forgotPasswordSuccessTitle"
        [message]="forgotPasswordSuccessMessage"
      ></cai-success-message>
      <div
        class="container"
        *ngIf="requireNewPassword && !showResetPasswordSuccessMessage"
      >
        <h3
          class="title"
          i18n="@@forgotPassword.reset-password"
          *ngIf="OTPStatus !== 'correct'"
        >
          Reset password
        </h3>
        <form class="kt-form" [formGroup]="newPasswordForm" autocomplete="off">
          <div class="col-xl-12">
            <div class="form-group">
              <mat-label i18n="@@global.new-password" class="text-uppercase"
                >New Password</mat-label
              >
              <kt-custom-input
                id="password"
                [type]="isShowNewPassword ? 'text' : 'password'"
                formControlName="password"
                placeholder=""
                autocomplete="off"
                [hasError]="
                  isControlHasErrorNew('passwordConfirmation', '', true)
                "
                [autoSelect]="true"
              >
                <span
                  class="kt-form__eye"
                  [class.activeEye]="isShowNewPassword"
                  icon
                  (click)="toggleEye1()"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 4.25C8.30768 4.25 5.62703 5.5736 3.86629 7.1989C2.99041 8.0074 2.34365 8.88909 1.91294 9.71605C1.48923 10.5296 1.25 11.3384 1.25 12C1.25 12.6616 1.48923 13.4704 1.91294 14.284C2.34365 15.1109 2.99041 15.9926 3.86629 16.8011C5.62703 18.4264 8.30768 19.75 12 19.75C15.6923 19.75 18.373 18.4264 20.1337 16.8011C21.0096 15.9926 21.6564 15.1109 22.0871 14.284C22.5108 13.4704 22.75 12.6616 22.75 12C22.75 11.3384 22.5108 10.5296 22.0871 9.71605C21.6564 8.88909 21.0096 8.0074 20.1337 7.1989C18.373 5.5736 15.6923 4.25 12 4.25ZM11.9999 9.75003C10.7572 9.75003 9.74988 10.7574 9.74988 12C9.74988 13.2427 10.7572 14.25 11.9999 14.25C13.2425 14.25 14.2499 13.2427 14.2499 12C14.2499 10.7574 13.2425 9.75003 11.9999 9.75003ZM8.24988 12C8.24988 9.92896 9.92881 8.25003 11.9999 8.25003C14.0709 8.25003 15.7499 9.92896 15.7499 12C15.7499 14.0711 14.0709 15.75 11.9999 15.75C9.92881 15.75 8.24988 14.0711 8.24988 12Z"
                    />
                  </svg> </span
              ></kt-custom-input>
            </div>
            <div class="form-group">
              <mat-label
                i18n="@@global.confirm-new-password"
                class="text-uppercase"
                >Confirm New Password</mat-label
              >
              <kt-custom-input
                id="passwordConfirmation"
                formControlName="passwordConfirmation"
                [type]="isShowConfirmNewPassword ? 'text' : 'password'"
                placeholder=""
                autocomplete="off"
                [hasError]="
                  isControlHasErrorNew('passwordConfirmation', '', true)
                "
                [autoSelect]="true"
              >
                <span
                  class="kt-form__eye"
                  [class.activeEye]="isShowConfirmNewPassword"
                  icon
                  (click)="toggleEye2()"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 4.25C8.30768 4.25 5.62703 5.5736 3.86629 7.1989C2.99041 8.0074 2.34365 8.88909 1.91294 9.71605C1.48923 10.5296 1.25 11.3384 1.25 12C1.25 12.6616 1.48923 13.4704 1.91294 14.284C2.34365 15.1109 2.99041 15.9926 3.86629 16.8011C5.62703 18.4264 8.30768 19.75 12 19.75C15.6923 19.75 18.373 18.4264 20.1337 16.8011C21.0096 15.9926 21.6564 15.1109 22.0871 14.284C22.5108 13.4704 22.75 12.6616 22.75 12C22.75 11.3384 22.5108 10.5296 22.0871 9.71605C21.6564 8.88909 21.0096 8.0074 20.1337 7.1989C18.373 5.5736 15.6923 4.25 12 4.25ZM11.9999 9.75003C10.7572 9.75003 9.74988 10.7574 9.74988 12C9.74988 13.2427 10.7572 14.25 11.9999 14.25C13.2425 14.25 14.2499 13.2427 14.2499 12C14.2499 10.7574 13.2425 9.75003 11.9999 9.75003ZM8.24988 12C8.24988 9.92896 9.92881 8.25003 11.9999 8.25003C14.0709 8.25003 15.7499 9.92896 15.7499 12C15.7499 14.0711 14.0709 15.75 11.9999 15.75C9.92881 15.75 8.24988 14.0711 8.24988 12Z"
                    />
                  </svg> </span
              ></kt-custom-input>
            </div>
          </div>
          <div class="kt-login__actions">
            <button
              (click)="submitOTP()"
              [ngClass]="{
                'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                  loading
              }"
              id="kt_login_signin_submit"
              class="btn btn-primary kt-login__btn-primary d-flex align-items-center justify-content-center resetButton"
              i18n="@@forgot-password.resetPassword"
              [disabled]="isDisabledResetPassword()"
            >
              RESET PASSWORD
            </button>
          </div>
          <div class="kt-login__signUp-wrapper">
            <span
              class="kt-login__signup-label"
              i18n="@@resetPassword.backOption"
              >Back to</span
            >&nbsp;&nbsp;
            <a
              href="javascript:;"
              routerLink="/auth/login"
              class="kt-link kt-login__signup-link"
              i18n="@@resetPassword.logIn"
              >Log in</a
            >
          </div>
        </form>
      </div>
      <cai-success-message
        [isShow]="showResetPasswordSuccessMessage"
        [title]="forgotPasswordSuccessTitle"
        [message]="resetPasswordSuccessMessage"
        [otherMessage]="timer"
      ></cai-success-message>
    </div>
  </div>
</div>
