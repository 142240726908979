import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";

export class LoginFormValidators {
  private static general (
    validatorFn: any,
    error: ValidationErrors,
    criterion?: any,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (criterion !== undefined) {
        if (validatorFn(criterion)(control) === null) {
          return null;
        }
      } else {
        if (validatorFn(control) === null) {
          return null;
        }
      }
      return error;
    };
  }

  static required: any = (field: string): ValidatorFn =>
    LoginFormValidators.general(Validators.required, {
      "required": {
        "message": `${field} is required`,
      },
    });

  static email: any = (): ValidatorFn =>
    LoginFormValidators.general(
      Validators.pattern("^[^@]+@[a-zA-Z0-9._-]+\\.+[a-z._-]+$"),
      {
        "invalidEmail": {
          "message": $localize`:@@login.validEmail:Please enter a valid email address`,
        },
      },
    );

  static maxLength: any = (value: number): ValidatorFn =>
    LoginFormValidators.general(
      Validators.maxLength,
      {
        "max": {
          "message": `Maximum field length: ${value}`,
        },
      },
      value,
    );

  static minLength: any = (value: number, field: string): ValidatorFn =>
    LoginFormValidators.general(
      Validators.minLength,
      {
        "min": {
          "message": `${field} must be at least ${value} digits`,
        },
      },
      value,
    );

  static pattern: any = (
    value: string,
    isResetPassword?: boolean,
    field = "password",
  ): ValidatorFn =>
    LoginFormValidators.general(
      Validators.pattern,
      {
        "pattern": {
          "message": isResetPassword
            ? $localize`:@@resetPassword.validResetPassword:Password must have at least 8 characters and contain: Uppercase character(s), lowercase character(s), number(s), and no spaces`
            : $localize`:@@login.validPassword:Please enter a valid ${field}`,
        },
      },
      value,
    );
}
