import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import saveAs from "file-saver";
import { UploadFileTypeEnum } from "../../enum/upload-file-type.enum";
import { IBlobFile } from "../../model/blob-file.model";
import { IFilesToUpload } from "../../model/file-to-upload.model";
import { IPresignedURL } from "../../model/presigned-url.model";
import { ErrorUtil } from "../../utils/error.util";
import { API_CORE } from "../../constant/api-core.const";

@Injectable({
  "providedIn": "root",
})
export class FileService {
  async uploadFile (
    uploadFileType: UploadFileTypeEnum,
    files: IBlobFile[]
  ): Promise<any> {
    const requestBody = {
      files,
      uploadFileType,
    } as IFilesToUpload;
    return API.post(API_CORE.name, API_CORE.URLs.File.Files, {
      "body": requestBody,
    }).catch(ErrorUtil.handleError);
  }

  async generateUploadURL (
    uploadFileType: UploadFileTypeEnum,
    files: IBlobFile[]
  ): Promise<IPresignedURL[]> {
    const requestBody = {
      files,
      uploadFileType,
    } as IFilesToUpload;
    return API.post(API_CORE.name, API_CORE.URLs.File.PresignedUrl, {
      "body": requestBody,
    }).catch(ErrorUtil.handleError);
  }

 async downloadFile (url: string, filename: string): Promise<void> {
    fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
            saveAs(blob, filename);
        })
        .catch((error) => {
            console.error("Error downloading file:", error);
        });
}

}
