import { FlightRateTypeEnum } from "../../enum/flight-rate-type.enum";
import { IFlightRate } from "../../model/flight-rate.model";
import { mockedFlightCharges } from "./flight-rate-charge.data";

export const mockedFlightRates: IFlightRate[] = [
  {
    "id": "0",
    "name": "",
    "type": FlightRateTypeEnum.MARKET_RATE,
    "currency": "EUR",
    "netRate": 100,
    "allInRate": 100,
    "total": 16700,
    "minimumRate": false,
    "charges": mockedFlightCharges,
    "ocdc": null,
    "lastUpdatedDate": "2022-01-12T07:17:48.720+00:00",
    "isManualRate": true,
  },
];
