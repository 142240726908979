export enum PermissionNameEnum {
  REMOVE_USER = "Remove Users",
  EDIT_USER = "Edit Users",
  LIST_SHIPMENT = "List Shipments",
  LIST_FLIGHT = "List Flights",

  READ_QUOTE = "View Quotes",
  CREATE_QUOTE = "Create Quotes",
  MODIFY_QUOTE = "Modify Quotes",
  READ_BOOKING = "View Bookings",
  CREATE_BOOKING = "Create Bookings",
  MODIFY_BOOKING = "Modify Bookings",

  ACCESS_MARKET_RATE = "View Contract Rates",
  ACCESS_CONTRACT_RATE = "View Market Rates",

  READ_RATE = "View ratesheets",
  EDIT_RATE = "Revise ratesheets",

  PAY_TO = "Make payment",
  // TODO: should be PAYMENT_LINK
  TOP_UP = "Access to wallet link",
  GET_PAID = "Request payment",
  PAYOUT = "Request PayOut",
}
