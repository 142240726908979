<svg
  width="30"
  height="33"
  viewBox="0 0 30 33"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.3585 0.575318L13.3585 0.575323L1.64609 7.08142V7.08142C1.2468 7.30322 1.26082 7.83824 1.66012 8.06005L14.1355 14.9899C14.4191 15.1475 14.5609 15.2262 14.711 15.2571C14.8439 15.2844 14.981 15.2844 15.1139 15.2571C15.264 15.2262 15.4058 15.1475 15.6894 14.9899L28.1648 8.06005C28.5641 7.83825 28.5781 7.30322 28.1788 7.08142V7.08142L16.4664 0.575323C15.8992 0.260276 15.6156 0.102752 15.3153 0.0409943C15.0495 -0.0136648 14.7754 -0.0136648 14.5096 0.0409943C14.2093 0.102752 13.9257 0.260274 13.3585 0.575318ZM29.8249 9.8788C29.8249 9.39596 29.3344 9.12624 28.9123 9.36071L16.4855 16.2636C16.1859 16.43 16.0362 16.5132 15.9271 16.6315C15.8306 16.7362 15.7576 16.8603 15.7129 16.9955C15.6624 17.1483 15.6624 17.3196 15.6624 17.6623V31.549C15.6624 31.9535 16.0669 32.2054 16.4206 32.009C16.4356 32.0006 16.4509 31.9922 16.4663 31.9836C16.4663 31.9836 16.4663 31.9836 16.4663 31.9836V31.9836C16.4664 31.9836 16.4664 31.9836 16.4664 31.9836L28.1788 25.4775C28.7779 25.1447 29.0775 24.9783 29.2956 24.7416C29.4885 24.5322 29.6346 24.2841 29.7239 24.0137C29.8249 23.7081 29.8249 23.3654 29.8249 22.6801V9.8788V9.8788ZM13.3586 31.9836C13.7338 32.192 14.1624 31.926 14.1624 31.4968V17.6623C14.1624 17.3196 14.1624 17.1483 14.112 16.9955C14.0673 16.8603 13.9943 16.7362 13.8978 16.6315C13.7887 16.5132 13.639 16.43 13.3394 16.2636L0.912614 9.36071C0.49052 9.12624 0 9.39596 0 9.8788V9.8788V22.6801C0 23.3654 0 23.7081 0.10098 24.0137C0.190314 24.2841 0.336349 24.5322 0.529317 24.7416C0.747442 24.9783 1.04699 25.1447 1.64609 25.4775L13.3585 31.9836L13.3586 31.9836V31.9836Z"
    fill="url(#paint0_linear_609:4575)"
  />
  <defs>
    <linearGradient
      id="paint0_linear_609:4575"
      x1="14.9124"
      y1="0"
      x2="14.9124"
      y2="32.077"
      gradientUnits="userSpaceOnUse"
    >
      <stop [attr.stop-color]="startClr" />
      <stop offset="1" [attr.stop-color]="endClr" />
    </linearGradient>
  </defs>
</svg>
