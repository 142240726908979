<div class="kt-customInput__container">
  <div [class.error]="hasError" class="kt-customInput__inputContainer">
    <input
      [value]="value"
      (input)="onChange($any($event.target).value)"
      (blur)="onTouched()"
      (change)="onChange($any($event.target).value)"
      (click)="handleClick($event)"
      [disabled]="isDisabled"
      autocomplete="off"
      [type]="type"
      i18n-placeholder
      autocomplete="off"
      #input
      [placeholder]="placeholder"
      [class.wallet-black]="isWalletApp"
    />
    <ng-content select="[icon]"> </ng-content>
  </div>
  <span *ngIf="errorMessage && showError" class="error-msg">
    {{ errorMessage }}
  </span>
  <div
    class="kt-customInput__errorsContainer"
    [class.multipleErrors]="errorMessage"
  >
    <mat-error *ngIf="otherErrorMessage">
      <span>{{ otherErrorMessage }}</span>
      <span
        class="kt-customInput__linkMessage"
        *ngIf="linkMessage"
        [routerLink]="linkUrl"
        >{{ linkMessage }}</span
      >
    </mat-error>
  </div>
</div>
