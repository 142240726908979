// Angular
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  "name": "aircraftColor",
})
export class AircraftColorPipe implements PipeTransform {
  transform (type: string): string {
    switch (type) {
      case "Narrowbody":
        return "#f8ebf5";

      case "Widebody":
        return "#d6b1da";

      case "Freighter":
        return "#e499d1";

      case "Unknown":
        return "#808080";

      default:
        return "#ad66b4";
    }
  }
}
