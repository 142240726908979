import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  "selector": "kt-requirement-radio-group",
  "templateUrl": "./requirement-radio-group.component.html",
  "styleUrls": ["./requirement-radio-group.component.scss"],
})
export class RequirementRadioGroupComponent {
  @Input() selected: any;
  @Input() question: string;
  @Input() visible: boolean;
  @Input() options: any[] = [];
  @Output() selectedChange = new EventEmitter();

  selectAnswer (option) {
    if (this.selected !== option) {
      this.selectedChange.emit(option);
    }
  }
}
