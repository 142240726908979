import { NgModule } from "@angular/core";
import {
  DayToStringPipe,
  MonthToStringPipe,
  formatDatePipe,
} from "./datetime.pipe";
import { HighlightSearchPipe } from "./highlight-search.pipe";
import { SearchPipe } from "./search.pipe";

@NgModule({
  "declarations": [
    DayToStringPipe,
    MonthToStringPipe,
    SearchPipe,
    HighlightSearchPipe,
    formatDatePipe,
  ],
  "exports": [
    DayToStringPipe,
    MonthToStringPipe,
    SearchPipe,
    HighlightSearchPipe,
    formatDatePipe,
  ],
})
export class CustomPipesModule {}
