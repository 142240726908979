<label
  class="advanced-input"
  [attr.for]="undefined"
  [class.viewonly]="viewOnly"
  [class.invalid]="showError"
  [class.empty-placeholder]="viewOnly && !value"
  [class.focused]="isFocused"
  caiTooltip
  [tooltip]="input.value"
  [detectOverflow]="true"
  [target]="input"
>
  <input
    #input
    caiAwbFormat
    numbersAndHyphenOnly
    [disabled]="viewOnly ? true : null"
    [style.text-transform]="textTransform"
    [class.float-label]="floatLabel"
    [placeholder]="label"
    [ngModel]="value"
    (ngModelChange)="writeValue($event)"
    (keyup)="keyup($event)"
    (blur)="blur()"
    [attr.maxLength]="maxLength"
  />
  <span class="label" [class.empty-placeholder]="viewOnly && !value">{{
    viewOnly && !value ? "" : label
  }}</span>
</label>
