<div
  class="booking-details-card"
  [class.collapsed-card]="isCollapsed"
  [class.active]="isActive"
  [class.with-footer]="showFooter"
  [class.popupCard]="popup"
>
  <div class="body d-flex">
    <div class="card-tail" [style.background]="bgColor"></div>
    <ng-content select="[body]"></ng-content>
  </div>
  <div class="footer" *ngIf="showFooter">
    <ng-content select="[footer]"></ng-content>
  </div>
  <div
    *ngIf="showMenu"
    #menuToggler
    class="menu-toggle"
    [class.active]="isOpen()"
    [class.disabled]="disableMenu"
    caiTooltip
    i18n-tooltip="@@global.click-for-actions"
    [tooltip]="'Click for actions'"
    [tooltipStyles]="{
      'margin-top': '3px'
    }"
    (click)="open($event)"
  >
    <span>
      <svg
        width="19"
        height="5"
        viewBox="0 0 19 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.875 2.5C0.875 1.12329 1.99829 0 3.375 0C4.75171 0 5.875 1.12329 5.875 2.5C5.875 3.87671 4.75171 5 3.375 5C1.99829 5 0.875 3.87671 0.875 2.5ZM3.375 1.5C2.82671 1.5 2.375 1.95171 2.375 2.5C2.375 3.04829 2.82671 3.5 3.375 3.5C3.92329 3.5 4.375 3.04829 4.375 2.5C4.375 1.95171 3.92329 1.5 3.375 1.5ZM13.125 2.5C13.125 1.12329 14.2483 0 15.625 0C17.0017 0 18.125 1.12329 18.125 2.5C18.125 3.87671 17.0017 5 15.625 5C14.2483 5 13.125 3.87671 13.125 2.5ZM15.625 1.5C15.0767 1.5 14.625 1.95171 14.625 2.5C14.625 3.04829 15.0767 3.5 15.625 3.5C16.1733 3.5 16.625 3.04829 16.625 2.5C16.625 1.95171 16.1733 1.5 15.625 1.5ZM7 2.5C7 1.12329 8.12329 0 9.5 0C10.8767 0 12 1.12329 12 2.5C12 3.87671 10.8767 5 9.5 5C8.12329 5 7 3.87671 7 2.5ZM9.5 1.5C8.95171 1.5 8.5 1.95171 8.5 2.5C8.5 3.04829 8.95171 3.5 9.5 3.5C10.0483 3.5 10.5 3.04829 10.5 2.5C10.5 1.95171 10.0483 1.5 9.5 1.5Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM16 0C14.9 0 14 0.9 14 2C14 3.1 14.9 4 16 4C17.1 4 18 3.1 18 2C18 0.9 17.1 0 16 0ZM7 2C7 0.9 7.9 0 9 0C10.1 0 11 0.9 11 2C11 3.1 10.1 4 9 4C7.9 4 7 3.1 7 2Z"
          fill="white"
        />
      </svg>
    </span>
  </div>
  <ng-template #menuItemsTpl>
    <div class="menu-items">
      <ng-content select="[menu]"></ng-content>
    </div>
  </ng-template>
</div>
