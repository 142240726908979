import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { UploadFileTypeEnum, UserService, UserTypeEnum } from "@cai-services";
import Swal from "sweetalert2";
import { ActivatedRoute } from "@angular/router";
import { BlobFile } from "../../../../core/_models/blob-file.model";
import { PresignedURL } from "../../../../core/_models/presigned-url.model";
import { AppFileService } from "../../../../core/_services/app-file.service";
import { BrandIconUtil } from "../../../../utils/brand.util";
import { RegisterService } from "../../../../core/_services/register.service";
import { User } from "../../../../core/_models/user.model";
import { SessionService } from "../../../../core/_services/session.service";
import { APP_PROPS } from "../../../../cai-common.module";
import { ApplicationProperties } from "../../../../core/_base/layout/models/app-properties.model";

@Component({
  "selector": "kt-personal-settings",
  "templateUrl": "./personal-settings.component.html",
  "styleUrls": ["personal-settings.component.scss"],
})
export class PersonalSettingsComponent implements OnInit {
  currentUser: User;
  selectedTab: Tabs = Tabs.ABOUT;
  logoFile: File;
  logoPath: string;
  loading: boolean;
  userType = UserTypeEnum;
  navItems: any;

  constructor (
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private readonly registrerService: RegisterService,
    private readonly fileService: AppFileService,
    private readonly userService: UserService,
    private readonly sessionService: SessionService,
    private readonly ref: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    public titleService: Title,
  ) {
    this.route.queryParams.subscribe(async (params) => {
      if (params.tab) {
        this.selectedTab = params.tab;
      }
    });
  }

  ngOnInit () {
    this.initNavItems();
    this.setupData();
    this.titleService.setTitle(
      `Personal Settings - ${BrandIconUtil.fetchDomainTitle()}`,
    );
  }

  private initNavItems () {
    this.navItems = [{
      "groupName": "USER",
      "groupItems": [
        {
          "key": Tabs.ABOUT,
          "label": "About",
          "iconUrl": "/assets/media/icons/account-purple.svg",
        },
      ]
    }];

    if (this.appProperties.name !== "cargowallet") {
      this.navItems[0].groupItems.push(
        {
          "key": Tabs.PREFERENCES,
          "label": "Preferences",
          "iconUrl": "/assets/media/icons/settings-purple.svg",
        },
        {
          "key": Tabs.NOTIFICATIONS,
          "label": "Notifications",
          "iconUrl": "/assets/media/icons/bell-purple.svg",
        },
        {
          "key": Tabs.INTEGRATIONS,
          "label": "Integrations",
          "iconUrl": "/assets/media/icons/puzzle-purple.svg",
        }
      );
    }
  }

  async setupData () {
    this.currentUser = await this.registrerService.getUser();
    this.logoPath = this.currentUser.logoPath;
    this.ref.detectChanges();
  }

  onSelectTab (tab: string) {
    this.selectedTab = tab as Tabs;
  }

  handleUpdateSuccess (newUser): void {
    this.currentUser = newUser;
    this.sessionService.setCurrentUser(newUser);
    Swal.fire("Successfully updated!", "", "success");
    this.loading = false;
    this.ref.detectChanges();
  }

  handleUpdateError (err): void {
    Swal.fire("Something went wrong!", err?.response?.message, "error");
    this.loading = false;
    this.ref.detectChanges();
  }

  async updateProfilePicture (logoFile: File): Promise<void> {
    this.loading = true;
    this.logoFile = logoFile;
    if (!this.logoFile) {
      this.currentUser.logoPath = undefined;
      await this.userService.updateUser(this.currentUser);
      this.handleUpdateSuccess(this.currentUser);
      return;
    }

    if (logoFile.type === "change") {
      return;
    }

    const uploadedBlobFile = new BlobFile(
      this.logoFile.name,
      this.logoFile.type,
      null,
    );
    try {
      const preSignedUrlResponse = await this.fileService.generateUploadURL(
        UploadFileTypeEnum.PROFILE_PIC,
        [uploadedBlobFile],
      ),
       preSignedUrl = preSignedUrlResponse[0] as PresignedURL;
      this.fileService
        .uploadViaPresignedUrl(preSignedUrl.url, this.logoFile)
        .subscribe(async () => {
          this.currentUser.logoPath = `${preSignedUrl.uploadedFilePath}/${preSignedUrl.uploadedFileName}`;
          await this.userService.updateUser(this.currentUser);
          this.handleUpdateSuccess(this.currentUser);
        });
    } catch (err) {
      this.handleUpdateError(err);
    }
  }

  get Tabs () {
    return Tabs;
  }
}

enum Tabs {
  ABOUT = "about",
  SECURITY = "security",
  PREFERENCES = "preferences",
  NOTIFICATIONS = "notifications",
  INTEGRATIONS = "integrations",
}
