<svg
  width="41"
  height="33"
  viewBox="0 0 41 33"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M8.42514 27.2045C7.78809 26.9871 7.26038 26.7007 6.83529 26.4054C6.87947 24.5792 7.17981 22.648 7.75635 20.8096C9.29481 15.8911 12.5525 12.1641 16.9268 10.3113C24.8214 6.97125 30.4387 3.99254 33.3865 -9.98924e-06C30.7931 1.23669 27.1476 2.08307 23.283 2.96446C15.9365 4.651 7.6019 6.56362 4.25022 11.9718C2.67399 14.5158 2.32312 17.6653 3.20753 21.3327C3.61109 23.0068 5.3196 25.3648 6.83401 26.4339C6.78769 28.4947 7.06848 30.4191 7.69947 31.9099C7.85294 32.2735 8.24611 32.464 8.6188 32.3738C8.66056 32.3636 8.70147 32.3501 8.7429 32.3319C9.14669 32.1604 9.3363 31.6926 9.16527 31.2904C8.72072 30.2451 8.42279 27.1069 8.42514 27.2045Z"
    fill="url(#paint0_linear_644:4571)"
  />
  <path
    d="M17.5471 11.7782C12.5011 13.913 10.2636 18.1282 9.2748 21.2864C8.92179 22.4184 8.69812 23.53 8.56771 24.5942C14.7907 27.6803 20.0872 28.048 24.3148 25.6905C32.4041 21.177 34.4085 8.05705 34.8826 0.621369C31.7774 5.06619 25.8826 8.25285 17.5471 11.7782Z"
    fill="url(#paint1_linear_644:4571)"
  />
  <defs>
    <linearGradient
      id="paint0_linear_644:4571"
      x1="16.6932"
      y1="4.04057"
      x2="22.7248"
      y2="28.9594"
      gradientUnits="userSpaceOnUse"
    >
      <stop [attr.stop-color]="startClr" />
      <stop offset="1" [attr.stop-color]="endClr" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_644:4571"
      x1="19.7126"
      y1="4.29324"
      x2="24.9623"
      y2="25.9817"
      gradientUnits="userSpaceOnUse"
    >
      <stop [attr.stop-color]="startClr" />
      <stop offset="1" [attr.stop-color]="endClr" />
    </linearGradient>
  </defs>
</svg>
