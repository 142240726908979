export const updateFlights = `subscription updateFlights(
  $id: ID!
) {
  FlightUpdated(id: $id) {
    id
    batchTotal
    sources
    metadata {
      aircraftBodyTypes
      airlineCodes
    }
    request {
      clientEnv
      clientID
      originCriteria
      destinationCriteria
      requestDate
      airline
      offset
      product
      volume
      weight
      pieces
      chargeableWeight
      specialHandlingCodes
      dangerousGoods
      dangerousGoodsInfo {
        unIdNo
        classDiv
        pg
        pi
        totalQty
        uom
        shc
      }
      scrs
      europeanHS
      globalHS
      forwarder
      isScreened
      providers
      officeId
      measurementUnit
      commodityIDs
      commodities {
        id
        name
      }
      dimensions {
        length
        width
        height
        pieces
        weight
        tiltable
        stackable
        weightType
        loadType
        uldType
        volume
      }
    }
    flights {
      flightUUID
      flightUID
      source
      arrivalTime
      arrivalAirport
      airlineCode
      departureTime
      departureAirport
      latestAcceptanceTime
      timeOfAvailability
      handlingInfoLink
      ghaName
      ghaAddress
      hle
      flightNumber
      sortingFlightKey
      loading
      available
      availableReason
      awbPrefixes
      features {
        equote
        bookable
        instantBookable
        isInterline
        awbOptional
        awbDisabled
        eap
        eaw
        allotment
        awbCommentDisabled
        dimensionDetails
        maxAttachmentCount
        maxAttachmentTotalSize
        mailQuotable
        walletConfig
        militaryOrDualUseGoods
        checkoutUrl
      }
      legs {
        flightNumber
        arrivalTime
        arrivalAirport
        departureTime
        departureAirport
        bodyType
        aircraftCode
        co2 {
          value
          estimation
        }
      }
      rates {
        id
        name
        type
        currency
        netRate
        allInRate
        total
        minimumRate
        charges {
          code
          label
          type
          rate
          minAmount
        }
        ocdc {
          code
          label
          type
          rate
          minAmount
        }
        lastUpdatedDate
        isManualRate
        isSpotQuoteRate
        latestAcceptanceTime
        timeOfAvailability
        handlingCodes
        coloaderOfficeId
        allotmentCode
        rateId
        airlineProdCode
        airlineRateRemarks
        day
        density
        service
      }
      airlineConditions
      salesConditions
      contacts
    }
  }
}
`;
