<div class="requirement-radio" [class.d-none]="!visible">
  <div class="requirement-radio__body">
    <div class="question">
      {{ question }}
    </div>
    <div class="options">
      <div *ngFor="let option of options">
        <ng-container
          *ngTemplateOutlet="
            radioButtonTpl;
            context: {
              option: option
            }
          "
        ></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #radioButtonTpl let-option="option">
  <div
    class="requirement-radio__button"
    [id]="option.value"
    [class.selected]="selected === option"
  >
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      (click)="selectAnswer(option)"
    >
      <ng-container *ngIf="selected === option">
        <path
          d="M17.9965 9C17.9965 13.6912 14.1667 17.5 9.43525 17.5C4.70381 17.5 0.874023 13.6912 0.874023 9C0.874023 4.30877 4.70381 0.5 9.43525 0.5C14.1667 0.5 17.9965 4.30877 17.9965 9Z"
          stroke="#4D578D"
        />
        <ellipse cx="9.43538" cy="9" rx="5.03401" ry="5" fill="#4D578D" />
      </ng-container>
      <ng-container *ngIf="selected !== option">
        <path
          d="M17.9965 9C17.9965 13.6912 14.1667 17.5 9.43525 17.5C4.70381 17.5 0.874023 13.6912 0.874023 9C0.874023 4.30877 4.70381 0.5 9.43525 0.5C14.1667 0.5 17.9965 4.30877 17.9965 9Z"
          stroke="#9B9B9F"
        />
      </ng-container>
    </svg>
    <span class="label">{{ option.label }}</span>
  </div>
</ng-template>
