import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Inject,
} from "@angular/core";

import { ApplicationProperties } from "../../../../core/_base/layout/models/app-properties.model";
import { APP_PROPS } from "../../../../cai-common.module";

@Component({
  "selector": "kt-login-lhs",
  "templateUrl": "./login-lhs.component.html",
  "styleUrls": ["./login-lhs.component.scss"],
  "changeDetection": ChangeDetectionStrategy.OnPush,
})
export class LoginLhsComponent implements OnInit, OnDestroy {
  currentSlider = 1;
  interval: NodeJS.Timeout;

  constructor (
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private readonly cdr: ChangeDetectorRef
  ) {}

  sliderInterval () {
    this.interval = setInterval(() => {
      if (this.currentSlider === 3) {
        this.currentSlider = 1;
      } else {
        this.currentSlider += 1;
      }
      this.cdr.detectChanges();
    }, 3000);
  }
  ngOnInit (): void {
    this.sliderInterval();
  }
  ngOnDestroy (): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  handleController (value: number): void {
    this.currentSlider = value;
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.sliderInterval();
  }

  createRange (number): any[] {
    return Array.from({ "length": number });
  }

  get isWalletApp (): boolean {
    return this.appProperties.name === "cargowallet";
  }
  getLaptopScreen1 (index: number) {
    const url = window.location.href,
     subDomain = url.split(".")[0].split("://")[1];
    if (this.appProperties.name === "cargowallet") {
      return "/assets/media/login/walletlaptopScreen.png";
    } else if (url.includes("localhost") || subDomain.includes("app")) {
      return `/assets/media/login/laptopScreen${index}.png`;
    }
    return `/assets/media/login/${subDomain}laptopScreen${index}.png`;
  }
}
