import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import { ICity } from "../../model";
import { ErrorUtil } from "../../utils/error.util";
import { API_CORE } from "../../constant/api-core.const";

@Injectable({
  "providedIn": "root",
})
export class CityService {
  async getCities (): Promise<ICity[]> {
    return API.get(API_CORE.name, API_CORE.URLs.City.Cities, {}).catch(
      ErrorUtil.handleError
    );
  }
}
