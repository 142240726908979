import { AppUser } from "./app-user.model";

export class Admin extends AppUser {
  mostLikelyOriginAirport: string;
  firstName: any;
  lastName: any;
  userEmail: any;
  userStatus: any;
  class: string;
  userName: string;
  userId: number;

  constructor (mostLikelyOriginAirport?: string) {
    super();
    this.mostLikelyOriginAirport = mostLikelyOriginAirport;
  }
}
