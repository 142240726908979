<form [formGroup]="accountForm" (ngSubmit)="onSubmit()">
  <h3 i18n="@@office-settings.company-account-settings">Company Account Settings</h3>
  <div class="card-body">
    <div class="form-group">
      <div class="pb-1 pt-3">
        <span i18n="@@global.full-company-name">Full Company Name</span>
        <em
          [ngbPopover]="fullCompanyNamePoppover"
          container="body"
          triggers="mouseenter:mouseleave"
          class="fa fa-exclamation-circle pl-2 text-danger"
        ></em>
      </div>
      <ng-template
        #fullCompanyNamePoppover
        i18n="@@settings.text-please-contact-support-change-this-field"
      >
        Please contact our support if you wish to change this field
      </ng-template>
      <div class="row col-6">
        <input
          readonly
          matInput
          type="text"
          formControlName="companyName"
          class="form-control form-control-solid"
        />
        <mat-error *ngIf="accountForm.get('companyName')?.errors">
          <span>{{ accountForm.get("companyName")?.errors | json }}</span>
        </mat-error>
      </div>
    </div>
    <div class="form-group">
      <div class="pb-1 pt-3">
        <span i18n="@@global.short-company-name">Short Company Name</span>
        <em
          [ngbPopover]="shortCompanyNamePoppover"
          container="body"
          triggers="mouseenter:mouseleave"
          class="fa fa-exclamation-circle pl-2 text-danger"
        ></em>
      </div>
      <ng-template
        #shortCompanyNamePoppover
        i18n="@@settings.text-please-contact-support-change-this-field"
      >
        Please contact our support if you wish to change this field
      </ng-template>
      <div class="row col-6">
        <input
          readonly
          matInput
          type="text"
          formControlName="companyCode"
          class="form-control form-control-solid"
        />
        <mat-error *ngIf="accountForm.get('companyCode')?.errors">
          <span>{{ accountForm.get("companyCode")?.errors | json }}</span>
        </mat-error>
      </div>
    </div>

    <div class="form-group">
      <div class="pb-3 pt-3">
        <span i18n="@@global.company-logo">Company Logo</span>
      </div>
      <div class="row col-6">
        <kt-profile-image
          (change)="onProfilePictureChanged($event)"
          [logoPath]="logoPath"
          [disableUpload]="false"
          type="company-logo"
        ></kt-profile-image>
      </div>
    </div>
  </div>
  <div class="submit-button">
    <button
      type="submit"
      [disabled]="isSubmitBtnDisable"
      [ngClass]="{
        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
          loading
      }"
      class="btn btn-brand btn-elevate btn-pill"
      i18n="@@global.save-changes"
    >
      Save Changes
    </button>
  </div>
</form>
