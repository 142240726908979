import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { FileService, UploadFileTypeEnum } from "@cai-services";
import { BlobFile } from "../_models/blob-file.model";
import { getImageOrFallback } from "../../helpers/image-helpers";
import { environment } from "../../environments";

@Injectable({
  "providedIn": "root",
})
export class AppFileService {
  envName: string = environment.name;

  constructor (
    private httpClient: HttpClient,
    private readonly fileService: FileService,
  ) {}

  getAirlineLogoPath (airlineCompanyCode: string): string {
    if (!airlineCompanyCode) {
      return "/assets/media/airlinesLogo/default.png";
    }
    airlineCompanyCode = airlineCompanyCode.replace("*", "star");

    return `${this.envName === "local" ? "https://app.dev.cargoai.co" : ""}/static/airline-logos/${airlineCompanyCode}.png`;
  }

  getCompanyLogo (hash: string, type: string): Promise<any> {
    if (!hash || !type) {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = "/assets/media/icons/company-account.svg";
        img.onload = () => resolve(img.src);
      });
    }
    const logoPath = `/static/company-logos/${hash}.${type}`;
    return getImageOrFallback(
      logoPath,
      "/assets/media/icons/company-account.svg",
    );
  }

  getProfilePicPath (fileName: string): string {
    if (!fileName || fileName === "hardCode") {
      return "/assets/media/icons/profile-icon.jpg";
    }
    if (fileName.startsWith("http")) {
      return fileName;
    }

    return "/documents/profile-pics/" + fileName;
  }

  public uploadFile (
    uploadFileType: UploadFileTypeEnum,
    files: BlobFile[],
  ): Observable<any> {
    if (!files || !uploadFileType) {
      throw new Error(
        "Required parameter body was null or undefined when calling uploadFile.",
      );
    }
    return from(this.fileService.uploadFile(uploadFileType, files));
  }

  public generateUploadURL (
    uploadFileType: UploadFileTypeEnum,
    files: BlobFile[],
  ): Promise<any> {
    if (!files || !uploadFileType) {
      throw new Error(
        "Required parameter body was null or undefined when calling uploadFile.",
      );
    }
    return this.fileService.generateUploadURL(uploadFileType, files);
  }

  uploadViaPresignedUrl (uploadUrl: string, file: File): Observable<any> {
    return this.httpClient.put<any>(uploadUrl, file, {
      "headers": {
        "content-type": file.type,
      },
    });
  }
}
