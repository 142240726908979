// Angular
import { Injectable } from "@angular/core";
// RxJS
import { BehaviorSubject } from "rxjs";
// Object path
import * as objectPath from "object-path";
// Services
import { CargoMartPlansType } from "@cai-services";
import { SessionService } from "../../../_services/session.service";
import { MenuConfigService } from "./menu-config.service";

@Injectable()
export class MenuHorizontalService {
  // Public properties
  menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  /**
   *
   * @param menuConfigService
   * @param sessionService
   */
  constructor (
    private menuConfigService: MenuConfigService,
    private readonly sessionService: SessionService,
  ) {
    this.loadMenu();
    this.sessionService.watchSessionStorage().subscribe((res) => {
      if (res.key === "currentUser") {
        this.loadMenu();
      }
    });
  }
  /**
   * Load menu list
   */
  loadMenu () {
    // get menu list
    const menuItems: any[] = objectPath.get(
      this.menuConfigService.getMenus(),
      "header.items",
    );
    this.menuList$.next(menuItems);
  }

  async shouldShowUpgradeButton (currentUser) {
    const currentOffice = this.sessionService.getSelectedOffice(),
     updatedOffice = currentUser?.officeAccesses?.find(
      (officeAccess) => currentOffice?.id === officeAccess?.office?.id,
    );

    return (
      updatedOffice?.office?.plan === CargoMartPlansType.FREE
    );
  }

  shouldDisplayProLogo (CurrentUser) {
    const selectedOfficeId = this.sessionService.getSelectedOfficeId(),
     officeAccess = CurrentUser?.officeAccesses?.find(
      (oa) => oa.office.id == selectedOfficeId,
    ),
     office = officeAccess ? officeAccess.office : null;
    return (
      office?.plan === CargoMartPlansType.PRO ||
      office?.plan === CargoMartPlansType.TRIAL
    );
  }

}
