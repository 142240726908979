import { ICommodity } from "@cai-services";

export class CommodityUtil {
  static getCommodityByLevel (level: number, commodity: ICommodity): ICommodity {
    if (commodity?.level !== level) {
      if (commodity?.parent) {
        return this.getCommodityByLevel(level, commodity?.parent);
      }
      return null;
    }
    return commodity;
  }
}
