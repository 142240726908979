import {
  ChangeDetectorRef,
  Component,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter, takeUntil, takeWhile } from 'rxjs/operators';
import Popper from 'popper.js';
import { cloneDeep, unionBy } from 'lodash';

const NO_RESULT_FOUND = 'No result found';

@Component({
  selector: 'kt-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
})
export class MultiselectComponent implements OnDestroy, OnChanges {
  @Input() id: string;
  @Input() options: any[] = [];
  @Input() class: string;
  @Input() selectedOptions: any[] = [];
  @Input() placeholder: string;
  @Input() selectAllText: string;
  @Input() allowNoneSelected = false;
  @Input() hideValue: boolean;
  @Input() isFilter: boolean;
  @Input() enableSearch = true;
  @Input() defaultMsg = NO_RESULT_FOUND;
  @Output() selectedOptionsChange = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Input() optionKey = 'value';
  @Input() description = 'description';
  @Input() returnStrings?: boolean;

  private view: EmbeddedViewRef<any>;
  private popperRef: Popper;
  clonedOptions: any[];
  searchKey = '';

  constructor(
    private readonly vcr: ViewContainerRef,
    private readonly zone: NgZone,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('options')) {
      this.clonedOptions = cloneDeep(this.options);
    }
  }

  ngOnDestroy(): void {
    this.close();
  }

  getHighlightedText(text: string): string {
    if (this.searchKey) {
      return text
        .replace(new RegExp(this.searchKey, 'gi'), (match) => {
          if (match[0]) {
            const firstCharLetter = Boolean(match[0].match(/[a-zA-Z0-9]/));
            if (firstCharLetter) {
              return `<mark>${match}</mark>`;
            }
            return `${match[0]}<mark>${match.substring(1)}</mark>`;
          }
          return match;
        })
        .replace(/ /gi, '&nbsp;');
    } else {
      return text;
    }
  }

  onTabPressed(dropdownTpl: TemplateRef<any>, input: HTMLElement): void {
    this.open(dropdownTpl, input);
  }

  labelCondition(option) {
    return ['airportCode', 'id'].includes(this.optionKey)
      ? this.getLabel(option)
      : option.label || option[this.optionKey];
  }

  handleKeywordChange(event) {
    this.searchKey = event;
    this.clonedOptions = this.options.filter((option) => {
      const optionLabel = this.labelCondition(option)?.toString().toLowerCase(),
        optionDescription = option[this.description]?.toString()?.toLowerCase();
      if (
        optionLabel?.includes(event.toLowerCase()) ||
        optionDescription?.includes(event.toLowerCase())
      ) {
        return option;
      }
      return false;
    });
    if (!event) {
      this.clonedOptions = cloneDeep(this.options);
    }
    this.cdr.detectChanges();
  }

  isOpen(): boolean {
    return !!this.popperRef;
  }

  isCheckboxActive(): boolean {
    let isActive = true;
    const newSelectedOptions = this.returnStrings
      ? [...this.selectedOptions]
      : [
          ...this.selectedOptions.map((selectedOption) =>
            selectedOption[this.optionKey]?.toString(),
          ),
        ];
    this.clonedOptions?.forEach((option) => {
      if (!newSelectedOptions.includes(option[this.optionKey]?.toString())) {
        isActive = false;
      }
    });
    if (!this.searchKey) {
      return this.selectedOptions.length === this.options.length;
    }
    return isActive;
  }

  open(dropdownTpl: TemplateRef<any>, input: HTMLElement) {
    this.onFocus.emit();
    if (!!this.popperRef) {
      this.close();
    } else if (this.options.length || this.defaultMsg) {
      this.options = this.sortByLabel(this.options);
      this.clonedOptions = this.sortByLabel(this.clonedOptions);
      this.view = this.vcr.createEmbeddedView(dropdownTpl);
      const dropdown = this.view.rootNodes[0];

      document.body.appendChild(dropdown);
      dropdown.style['z-index'] = '1';
      dropdown.style['min-width'] = `${input.offsetWidth}px`;

      if (!this.options.length && this.defaultMsg) {
        dropdown.style.width = `${input.offsetWidth}px`;
      }

      this.zone.runOutsideAngular(() => {
        this.popperRef = new Popper(input, dropdown, {
          placement: 'bottom-start',
          removeOnDestroy: true,
          modifiers: {
            preventOverflow: { escapeWithReference: true },
          },
        });
      });
      this.handleClickOutside();
    }
  }

  toggleSelect(option) {
    const index = this.selectedOptions.indexOf(
      this.selectedOptions.find(
        (selected) =>
          (selected?.[this.optionKey] || selected)?.toString() ===
          option[this.optionKey]?.toString(),
      ),
      0,
    );
    if (index > -1) {
      this.selectedOptions.splice(index, 1);
    } else {
      if (this.returnStrings) {
        this.selectedOptions.push(option[this.optionKey]?.toString());
      } else {
        this.selectedOptions.push(option);
      }
    }
    this.selectedOptionsChange.emit(this.selectedOptions);
    this.cdr.detectChanges();
  }

  getLabel(option) {
    if (option?.city) {
      return `${option?.airportCode} - ${option.city.cityName}`;
    }
    if (option?.name) {
      return option.name;
    }
    if (option?.companyName) {
      return option.companyName;
    }
    return null;
  }

  toggleSelectAll() {
    if (this.isCheckboxActive()) {
      const clonedOptionsLabelList = [
          ...this.clonedOptions.map((option) =>
            option[this.optionKey]?.toString(),
          ),
        ],
        newSelectedOptions = [];
      this.selectedOptions.forEach((selectedOption) => {
        if (
          clonedOptionsLabelList?.includes(
            selectedOption[this.optionKey]?.toString() ||
              selectedOption?.toString(),
          )
        ) {
          return false;
        }
        return newSelectedOptions.push(selectedOption);
      });

      this.selectedOptions.splice(0, this.selectedOptions.length);
      this.selectedOptions.push(...newSelectedOptions);
    } else {
      if (this.returnStrings) {
        const clonedSelectedOptions = cloneDeep(this.selectedOptions);
        this.selectedOptions.splice(0, this.selectedOptions.length);
        this.selectedOptions.push(
          ...new Set([
            ...this.clonedOptions.map((option) =>
              option[this.optionKey]?.toString(),
            ),
            ...clonedSelectedOptions,
          ]),
        );
      } else {
        this.selectedOptions = unionBy(
          this.clonedOptions,
          this.selectedOptions,
          this.optionKey,
        );
      }
    }
    this.selectedOptionsChange.emit(this.selectedOptions);
    this.cdr.detectChanges();
  }

  private handleClickOutside() {
    fromEvent(document, 'click')
      .pipe(
        takeWhile(() => this.isOpen()),
        filter(({ target }) => {
          const origin = this.popperRef.reference as HTMLElement;
          return (
            origin.contains(target as HTMLElement) === false ||
            (!this.options.length && !this.defaultMsg)
          );
        }),
        takeUntil(this.onClose),
      )
      .subscribe(() => {
        this.close();
        this.cdr.detectChanges();
      });
    fromEvent(document, 'keydown')
      .pipe(
        takeWhile(() => this.isOpen()),
        filter(
          (event: KeyboardEvent) =>
            // Check if the key pressed is the Tab key
            event.key === 'Tab',
        ),
        takeUntil(this.onClose),
      )
      .subscribe(() => {
        this.close();
        this.cdr.detectChanges();
      });
  }

  close() {
    this.onClose.emit();
    if (this.view) {
      this.view.destroy();
      this.view = null;
    }
    if (this.popperRef) {
      this.popperRef?.destroy();

      this.popperRef = null;
    }
  }

  get invalid(): boolean {
    if (this.allowNoneSelected) {
      return false;
    }
    return this.options?.length && !this.selectedOptions?.length;
  }
  get inputText() {
    if (this.options?.length) {
      if (
        this.selectedOptions.length > 1 &&
        this.selectedOptions.length === this.options.length
      ) {
        return `${this.selectAllText} (${this.clonedOptions?.length})`;
      } else {
        const matchedOptions = this.options.filter((opt) =>
          this.isSelected(opt),
        );
        if (matchedOptions.length) {
          const firstOptionText =
            this.description === 'city'
              ? matchedOptions[0]?.[this.description]?.cityName
              : matchedOptions[0]?.[this.description];
          if (this.selectedOptions.length > 1) {
            const secondOptionText =
                this.description === 'city'
                  ? matchedOptions[1]?.[this.description]?.cityName
                  : matchedOptions[1]?.[this.description],
              firstOptionLength = firstOptionText?.length || 0,
              secondOptionLength = secondOptionText?.length || 0,
              limit = 7;
            if (firstOptionLength + secondOptionLength > limit) {
              const remainingCount = this.selectedOptions.length - 1;
              return `${firstOptionText} (+${remainingCount})`;
            }
          }
          return firstOptionText;
        }

        if (this.isFilter) {
          return `${this.selectAllText} (${this.clonedOptions?.length})`;
        }
      }
    }
    return null;
  }

  isSelected(option): boolean {
    return !!this.selectedOptions.find(
      (selected) =>
        (selected?.[this.optionKey] || selected)?.toString() ===
        option[this.optionKey]?.toString(),
    );
  }

  sortByLabel(options: any[]) {
    return options.sort(
      (a, b) =>
        a?.label?.localeCompare(b.label) ||
        a?.description?.localeCompare(b.description) ||
        a[this.optionKey]
          ?.toString()
          ?.localeCompare(b[this.optionKey]?.toString()),
    );
  }
}
