import { LoadTypeMeasurementUnits } from "../constants/measurements.const";
import { MeasurementTypeEnum } from "../enum/measurement-type.enum";

export class CaiLoadTypeMeasurementUtil {
  static getMeasurementUnits (type: MeasurementTypeEnum): any[] {
    const measurementUnits = [];
    Object.keys(LoadTypeMeasurementUnits).forEach((key) => {
      measurementUnits.push(LoadTypeMeasurementUnits[key][type]);
    });
    return measurementUnits.filter((unit) => unit);
  }
}
