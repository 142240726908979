import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ "name": "search" })
export class SearchPipe implements PipeTransform {
  transform (values: any[], searchValue: string, ...properties: string[]): any {
    if (searchValue && searchValue.length > 0) {
      return values.filter((data) => {
        for (const property of properties) {
          if (
            data[property] &&
            (data[property] as string)
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > -1
          ) {
            return true;
          }
        }
        return false;
      });
    }
    return values;
  }
}
