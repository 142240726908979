import { Component, Input } from "@angular/core";
import { Language } from "../language";

@Component({
  "selector": "kt-language-option",
  "templateUrl": "./language-option.component.html",
  "styleUrls": ["./language-option.component.scss"],
})
export class LanguageOptionComponent {
  @Input() language: Language;
  @Input() verbose = false;
}
