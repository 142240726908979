import { TitleCasePipe } from "@angular/common";
import { BUP } from "../../../core/_models/bup.model";
import { MeasurementUnit } from "../../../core/_models/measurement-system.model";
import { minMaxUnitValues } from "../components/load-type/constants/measurements.const";
import { BUPConfig } from "../constants/bup.const";

export class CaiBUPUtil {
  constructor (public readonly titleCasePipe: TitleCasePipe) {}

  static isPartiallyFilled (bup: BUP): boolean {
    return !!bup?.uldType;
  }

  static isBUPValid (bup, weightUnit: MeasurementUnit): boolean {
    return this.validateBUP(bup, weightUnit).length === 0;
  }

  static validateBUP (bup, weightUnit: MeasurementUnit): string[] {
    const bupErrors = [];
    let minValue,
     maxValue;

    BUPConfig.forEach((config) => {
      const value = bup[config.field];
      if (config.field === "weight" && weightUnit?.code === "lb") {
        maxValue = minMaxUnitValues.maxLBValue;
        minValue = minMaxUnitValues.minLBValue;
      } else if (config.field === "weight") {
        maxValue = minMaxUnitValues.maxKGValue;
        minValue = minMaxUnitValues.minKGValue;
      }
      for (const key of Object.keys(config.validators)) {
        const error = config.validators[key](
          value,
          weightUnit?.code,
          maxValue,
          minValue
        ),
         exists = bupErrors.find((err) => err.field === config.field);
        if (error && !exists) {
          bupErrors.push({
            "field": config.field,
            "message": error,
          });
        }
      }
    });
    return bupErrors.map((err) => err.message);
  }
}
