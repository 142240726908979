import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import moment from "moment";

export class FormValidators {
  static lettersOrDigits: any =
    (): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const text = control.value || "",
        isValid = /[a-zA-Z0-9]+/.test(text);
      return isValid || !text.length
        ? null
        : { "lettersOrDigits": "Must include letters or digits" };
    };

  private static general (
    validatorFn: any,
    error: ValidationErrors,
    criterion?: any,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (criterion !== undefined) {
        if (validatorFn(criterion)(control) === null) {
          return null;
        }
      } else if (validatorFn(control) === null) {
        return null;
      }
      return error;
    };
  }

  static required: any = (): ValidatorFn =>
    FormValidators.general(Validators.required, {
      "required": { "message": "{field} is required" },
    });

  static minValue: any =
    (value = -Infinity): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      let num = Number(control.value || 0);
      if (num <= 0.01) {
        num = Number(num.toPrecision(1));
      }
      if (+num.toFixed(4) < +value.toFixed(4)) {
        return {
          "minValue": { "message": `Minimum {field} is ${value}` },
        };
      }
      return null;
    };

  static minValueAttr: any =
    (controlId: string): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      let num = Number(control.value || 0);
      if (num <= 0.01) {
        num = Number(num.toPrecision(1));
      }
      const domControl = document.getElementById(controlId);
      if (domControl != null) {
        const value = Number(domControl.getAttribute("data-min-amount"));
        if (+num.toFixed(4) < +value.toFixed(4)) {
          return {
            "minValue": { "message": `Minimum {field} is ${value}` },
          };
        }
      }
      return null;
    };
  static maxValueAttr: any =
    (controlId: string): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      let num = Number(control.value?.replace(/[^0-9.]/g, "") || 0);
      if (num <= 0.01) {
        num = Number(num.toPrecision(1));
      }
      const domControl = document.getElementById(controlId);
      let maxLimit = 100000;
      if (domControl != null) {
        maxLimit = Number(domControl.getAttribute("data-max-amount"));
      }
      if (+num.toFixed(4) > +maxLimit.toFixed(4)) {
        return {
          "maxValue": { "message": `Maximum {field} is ${maxLimit}` },
        };
      }
      return null;
    };

  static maxValue: any = (value = Infinity): ValidatorFn =>
    FormValidators.general(
      Validators.max,
      {
        "maxValue": { "message": `Maximum {field} is ${value}` },
      },
      value,
    );

  static maxLength: any = (value = Infinity): ValidatorFn =>
    FormValidators.general(
      Validators.maxLength,
      {
        "maxLength": {
          "message": `{field} must not be greater than ${value} characters`,
        },
      },
      value,
    );

  static noLettersValidator: any =
    (field: string): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const text = (control.value?.toString() || "").trim();
      return text
        ? null
        : {
            "noLetter": {
              "message": `${field} cannot be empty`,
            },
          };
    };

  static lessThan: any =
    (value = -Infinity, weightUnit?: string): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        if (control.value >= value) {
          return {
            "lessThan": {
              "message": `{field} must be below ${value?.toLocaleString()} ${
                weightUnit || ""
              }`,
            },
          };
        }
      }
      return null;
    };

  static noDecimalPoint: any =
    (): ValidatorFn =>
    (control: AbstractControl): ValidationErrors => {
      if (control.value === ".") {
        return {
          "noDecimalPoint": {
            "message": "{field} cannot only contain a decimal point.",
          },
        };
      }
      return null;
    };

  static notPastDate: any =
    (): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const value = control.value || "";
      if (
        value &&
        moment(value).startOf("day").isBefore(moment(new Date()).startOf("day"))
      ) {
        return {
          "departureDate": {
            "message": "{field} cannot be a past date",
          },
        };
      }
      return null;
    };
}
