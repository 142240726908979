import {
  ChangeDetectorRef,
  Output,
  Component,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  Self,
  Optional,
  TemplateRef,
  ViewContainerRef,
  NgZone,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
} from "@angular/core";
import Popper from "popper.js";
import { filter, takeUntil, takeWhile } from "rxjs/operators";
import { fromEvent } from "rxjs";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { TitleCasePipe } from "@angular/common";
import { cloneDeep } from "lodash";

@Component({
  "selector": "cai-select",
  "templateUrl": "./select.component.html",
  "styleUrls": ["./select.component.scss"],
  "providers": [TitleCasePipe],
})
export class CaiSelect implements OnChanges, ControlValueAccessor {
  @ViewChild("input") input: ElementRef;
  @Input() label: string;
  @Input() width: number;
  @Input() height: string | number;
  @Input() maxDropdownHeight: string | number;
  @Input() inputHeight: number;
  @Input() isCurrencySelector: boolean;
  @Input() isTopCurrencySelector: boolean;
  @Input() inputWidth: number;
  @Input() showBlackChevron: boolean;
  @Input() options: any[] = [];
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() inputDisabled: boolean;
  @Input() viewOnly: boolean;
  @Input() alwaysFloat: boolean;
  @Input() showIcon: boolean;
  @Input() showCurrencyIcon: boolean;
  @Input() showTotal: boolean;
  @Input() secondaryTextLabel: string;
  @Input() customLabelColor: string;
  @Input() isDashboard: boolean;
  @Input() emitOptionChangeEvent: boolean;
  @Input() customValue: any;
  @Input() isReadOnly = true;
  @Input() showLabelIcon = false;
  @Input() fontColor = "";
  @Input() avoidTitleCase = false;
  @Input() hoverWalletEffect = false;
  @Output() onFocus = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onChangeEmit = new EventEmitter();
  @Output() onChangeOption = new EventEmitter();

  selected: any;
  originalOptions = [];
  focused: boolean;
  text = "";
  tooltipModifiers = {
    "preventOverflow": { "escapeWithReference": false },
  };

  _value: string;
  lastSelectedOption: any;
  get value () {
    return this._value;
  }
  set value ($event: string) {
    this._value = $event;
    if (this.value && this.options.length) {
      this.selected = this.options.find((item) => item.value === this.value);
      const label = this.selected ? this.selected.label : "";
      this.text =
        this.showCurrencyIcon || this.avoidTitleCase
          ? label
          : this.titleCase.transform(label);
      this.cdr.detectChanges();
    } else {
      this.select(null);
    }
  }

  private touched: boolean;
  private view: EmbeddedViewRef<any>;
  private popperRef: Popper;

  onChange = (value: string) => {
    this.onChangeEmit.emit(value);
  };
  onTouched = () => {};

  constructor (
    @Optional()
    @Self()
    private readonly element: ElementRef,
    @Optional()
    @Self()
    public control: NgControl,
    private vcr: ViewContainerRef,
    private zone: NgZone,
    private readonly cdr: ChangeDetectorRef,
    private readonly titleCase: TitleCasePipe,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }
  get flagValue (): string {
    return this.value == "CNH" ? "CNY" : this.value;
  }
  getFlagName (value): string {
    return value == "CNH" ? "CNY" : value;
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.hasOwnProperty("options")) {
      if (this.value) {
        this.selected = this.options.find((item) => item.value === this.value);

        const label = this.selected ? this.selected.label : "";
        this.text = this.showCurrencyIcon
          ? label
          : this.titleCase.transform(label);
      }

      this.originalOptions = cloneDeep(this.options);
    }
    if (changes.hasOwnProperty("customValue")) {
      if (this.customValue) {this.value = this.customValue;}
    }
  }

  public get invalid (): boolean {
    return this.control ? this.control.invalid : false;
  }
  get hasSelectedCurrency (): boolean {
    return this.originalOptions.some((option) => option.value == this.value);
  }
  public get showError (): boolean {
    if (!this.control) {
      return false;
    }
    const { dirty, touched } = this.control;
    return this.invalid ? dirty || touched : false;
  }

  public get errorMessage (): string {
    const error = Object.values(this.control.errors)[0].message || null;
    if (error) {
      return error.replace("{field}", this.label);
    }
    return null;
  }

  writeValue (value: string) {
    this.value = value;
  }

  registerOnChange (onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched (onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched () {
    this.control?.control?.markAsTouched();
    if (!this.touched && typeof this.onTouched === "function") {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState (disabled: boolean) {
    this.disabled = disabled;
  }

  isOpen (): boolean {
    return !!this.popperRef;
  }

  openDropdown (dropdownTpl: TemplateRef<any>, input: HTMLElement): void {
    setTimeout(() => {
      this.open(dropdownTpl, input);
      this.cdr.detectChanges();
    });
  }

  filterOption () {
    if (this.isReadOnly) {
      return;
    }
    const inputText = this.input?.nativeElement?.value?.toLowerCase();

    if (inputText) {
      this.options = cloneDeep(
        this.originalOptions.filter((option) => (
            option.value.indexOf(inputText?.toUpperCase()) > -1 ||
            option.label.indexOf(inputText?.toUpperCase()) > -1 ||
            option.uppercaseMatch?.toUpperCase() === inputText?.toUpperCase() ||
            option.anycaseMatch
              ?.toLowerCase()
              .startsWith(inputText?.toLowerCase())
          )),
      );
    } else {
      this.options = cloneDeep(this.originalOptions);
    }
    this.cdr.detectChanges();
  }

  blur () {
    if (this.hoverWalletEffect) {
      this.markAsTouched();
    }
    if (this.isReadOnly) {
      return;
    }
    const inputText = this.input?.nativeElement?.value?.toLowerCase();

    if (!inputText && this.lastSelectedOption) {
      this.select(this.lastSelectedOption);
    } else if (inputText && !this.options.length) {
      if (!this.lastSelectedOption) {
        this.text = "";
      } else {
        this.select(this.lastSelectedOption);
      }
    } else if (this.isOpen() && inputText) {
      if (this.options.length) {
        this.select(this.options[0]);
      } else if (this.originalOptions?.length) {
        this.select(this.originalOptions?.[0]);
      }
    } else {
      const matched = this.options.find(
        (item) =>
          item.label?.toLowerCase() === inputText ||
          item?.display?.toLowerCase() === inputText,
      );
      this.select(
        matched ? matched : this.options[0] || this.originalOptions?.[0],
      );
    }

    this.markAsTouched();
    this.cdr.detectChanges();
  }

  open (dropdownTpl: TemplateRef<any>, input: HTMLElement) {
    if (!this.isCurrencySelector) {
      this.selected = null;

      this.options = cloneDeep(this.originalOptions);
    }
    if (this.showCurrencyIcon) {
      this.text = "";
    }
    this.onFocus.emit();
    if (!!this.popperRef) {
      this.close();
    }
    if (this.options.length) {
      this.view = this.vcr.createEmbeddedView(dropdownTpl);
      const dropdown = this.view.rootNodes[0];
      document.body.appendChild(dropdown);
      dropdown.style["z-index"] = "1300";
      dropdown.style["margin-top"] = "7px";
      dropdown.style["min-width"] = `${this.input.nativeElement.offsetWidth}px`;
      dropdown.style.width = `${this.width}px`;

      if (this.height) {
        dropdown.style.height =
          typeof this.height === "string" ? this.height : `${this.height}px`;
      } else {
        const maxHeight =
          window.innerHeight -
          (this.element.nativeElement.getBoundingClientRect().top +
            window.scrollY);
        if (maxHeight - 107 < 100) {
          dropdown.style["max-height"] = "300px";
        } else {
          dropdown.style["max-height"] = `${maxHeight - 107}px`;
        }
      }
      if (this.maxDropdownHeight) {
        dropdown.style["max-height"] = `${this.maxDropdownHeight}px`;
      }
      if (this.isCurrencySelector) {
        this.text = undefined;
      }

      this.zone.runOutsideAngular(() => {
        this.popperRef = new Popper(input, dropdown, {
          "placement": "bottom-end",
          "removeOnDestroy": true,
          "modifiers": {
            "preventOverflow": {
              "enabled": true,
              "boundariesElement": "viewport",
              "escapeWithReference": true,
            },
            "flip": {
              "enabled": false,
            },
            "hide": {
              "enabled": true,
            },
            "keepTogether": {
              "enabled": true,
            },
          },
        });
      });
      this.handleClickOutside();
    }
  }

  select (option) {
    if (this.selected !== option && option) {
      const value = option ? option.value : null,
       label = option ? option.label : "";
      this.selected = option;
      this.text = this.showCurrencyIcon
        ? label
        : this.titleCase.transform(label);
      this.value = value;
      if (this.emitOptionChangeEvent) {
        this.onChangeOption.emit(option);
      }
      if (typeof this.onChange === "function") {
        this.onChange(this.value);
      }
      this.lastSelectedOption = option;
      this.close();
      this.cdr.detectChanges();
    } else {
      this.text = "";
    }
  }

  private handleClickOutside () {
    fromEvent(document, "click")
      .pipe(
        takeWhile(() => this.isOpen()),
        filter(({ target }) => {
          const origin = this.popperRef.reference as HTMLElement;
          return origin.contains(target as HTMLElement) === false;
        }),
        takeUntil(this.onClose),
      )
      .subscribe(() => {
        this.close();
        this.cdr.detectChanges();
      });
  }

  private close () {
    this.onClose.emit();
    this.markAsTouched();
    if (this.view) {
      this.view.destroy();
      this.view = null;
    }
    if (this.popperRef) {
      this.popperRef = null;
    }
    if (this.isCurrencySelector) {
      this.text = this.selected?.label;
    }
  }

  get isTextOverflow (): boolean {
    return (
      this.input.nativeElement.clientWidth <
      this.input.nativeElement.scrollWidth
    );
  }
}
