import { Injectable } from "@angular/core";
import { AdvancedBookingCardTypeEnum } from "@cai-services";
import { Subject } from "rxjs";
import { BookingDetailsItemComponent } from "../../components/booking-details-item/booking-details-item.component";
import { AdvancedBookingDetails } from "../_models/advanced-booking-details.model";

@Injectable({
  "providedIn": "root",
})
export class AdvancedBookingsRequestService {
  requests$: Subject<any> = new Subject<any>();
  requestInProgress$: Subject<any> = new Subject<any>();
  requestList: BookingDetailsItemComponent[];
  updatedShipmentStatus$: Subject<string> = new Subject<string>();
  resetCopyFlag$: Subject<{
    index?: number;
    cardType?: AdvancedBookingCardTypeEnum;
  }> = new Subject<{
    index?: number;
    cardType?: AdvancedBookingCardTypeEnum;
  }>();
  copiedAdvancedBookingItem: AdvancedBookingDetails;

  updateAdvancedBookingDetails (value, index) {
    this.requests$.next({ value, index });
  }

  copyAdvancedBooking (value: AdvancedBookingDetails) {
    if (!value?.departureDate) {
      this.resetCopyFlag$.next({
        "index": this.copiedAdvancedBookingItem?.index,
        "cardType": this.copiedAdvancedBookingItem?.cardType,
      });
    }
    if (this.copiedAdvancedBookingItem?.departureDate) {
      this.resetCopyFlag$.next({
        "index": this.copiedAdvancedBookingItem?.index,
        "cardType": this.copiedAdvancedBookingItem?.cardType,
      });
    }
    this.copiedAdvancedBookingItem = value;
  }

  setInProgressRequests (value: BookingDetailsItemComponent[]) {
    this.requestList = value;
    this.requestInProgress$.next(value);
  }

  updatedShipmentStatus (value: string) {
    this.updatedShipmentStatus$.next(value);
  }

  getInProgressRequests () {
    return this.requestList;
  }

  returnUpdatedShipmentStatusObs () {
    return this.updatedShipmentStatus$.asObservable();
  }

  returnResetCopyFlagObs () {
    return this.resetCopyFlag$.asObservable();
  }

  returnRequestObs () {
    return this.requests$.asObservable();
  }
}
