<div
  class="kt-portlet"
  [ngClass]="{
    'kt-portlet--body-progress': viewLoading,
    'kt-portlet--body-progress-overlay': loadingAfterSubmit
  }"
>
  <div class="kt-portlet__head kt-portlet__head__custom">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">{{ getTitle() }}</h3>
    </div>
  </div>
  <form class="kt-form" [formGroup]="newPasswordForm">
    <div class="kt-portlet__body">
      <p i18n="@@auth.text-you-are-required-to-change-password">
        You are required to change your password, please enter your new password
        below
      </p>

      <div class="kt-portlet__body-progress">
        <mat-spinner [diameter]="10"></mat-spinner>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <mat-form-field>
              <mat-label i18n="@@global.password" class="text-uppercase"
                >Password</mat-label
              >
              <input
                matInput
                [(ngModel)]="password"
                type="password"
                placeholder="Password"
                formControlName="password"
                autocomplete="off"
              />
              <mat-error *ngIf="isControlHasError('password', 'required')">
                <strong i18n="@@global.text-error-required-field"
                  >Required field</strong
                >
              </mat-error>
              <mat-error *ngIf="isControlHasError('password', 'minlength')">
                <strong
                  ><span i18n="@@global.text-error-minimum-field-length"
                    >Minimum field length</span
                  >: 3</strong
                >
              </mat-error>
              <mat-error *ngIf="isControlHasError('password', 'maxlength')">
                <strong
                  ><span i18n="@@global.text-error-maximum-field-length"
                    >Maximum field length</span
                  >: 100</strong
                >
              </mat-error>
              <mat-error></mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <mat-form-field>
            <mat-label i18n="@@global.confirm-password"
              >Confirm password</mat-label
            >
            <input
              matInput
              [(ngModel)]="confirmPassword"
              type="password"
              i18n-placeholder="@@global.confirm-password"
              placeholder="Confirm password"
              formControlName="confirmPassword"
              autocomplete="off"
            />
            <mat-error *ngIf="isControlHasError('confirmPassword', 'required')">
              <strong i18n="@@global.text-error-required-field"
                >Required field</strong
              >
            </mat-error>
            <mat-error
              *ngIf="isControlHasError('confirmPassword', 'minlength')"
            >
              <strong
                ><span i18n="@@global.text-error-minimum-field-length"
                  >Minimum field length</span
                >: 3</strong
              >
            </mat-error>
            <mat-error
              *ngIf="isControlHasError('confirmPassword', 'maxlength')"
            >
              <strong
                ><span i18n="@@global.text-error-maximum-field-length"
                  >Maximum field length</span
                >: 100</strong
              >
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <br /><br />
    </div>

    <div class="text-right">
      <div class="col-lg-11">
        <button
          type="button"
          mat-raised-button
          [mat-dialog-close]="data.animal"
          cdkFocusInitial
          i18n-matTooltip="@@global.cancel"
          matTooltip="Cancel"
        >
          <span i18n="@@global.cancel">Cancel</span></button
        >&nbsp;
        <button
          type="button"
          mat-raised-button
          color="primary"
          matTooltip="Save"
          (click)="onSubmit()"
          i18n-matTooltip="@@global.save"
        >
          <span i18n="@@global.save">Save</span>
        </button>
      </div>
    </div>

    <br />
    <br />
  </form>
</div>
