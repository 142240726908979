import { RequirementConfig } from "../../core/_models/requirement-config.model";

export enum LithiumBatteryAnswer {
  NO = "default_no",
  YES = "default_yes",
}

export const LithiumBatteryConfig: RequirementConfig = {
  "summaryLabel": "",
  "question": $localize`:@@lithium-battery.question:Contain lithium batteries ?`,
  "options": [
    {
      "label": $localize`:@@lithium-battery.no:No`,
      "value": LithiumBatteryAnswer.NO,
      "description": "",
    },
    {
      "label": $localize`:@@lithium-battery.yes:Yes`,
      "value": LithiumBatteryAnswer.YES,
      "description": "",
    },
  ],
};
