import { RequirementConfig } from "../../core/_models/requirement-config.model";

export enum ActiveContainerAnswer {
  NO = "default_no",
  YES = "act_yes",
}

export const ActiveContainerConfig: RequirementConfig = {
  "summaryLabel": "Active container :",
  "question": "I will need an ‘Active’ container",
  "options": [
    {
      "label": "No",
      "value": ActiveContainerAnswer.NO,
    },
    {
      "label": "Yes",
      "value": ActiveContainerAnswer.YES,
    },
  ],
};
