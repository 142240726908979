import { Injectable } from "@angular/core";
import { QuoteService } from "@cai-services";
import { from, Observable } from "rxjs";
import { Quote } from "../../_models/quote.model";

@Injectable({
  "providedIn": "root",
})
export class AcceptQuoteService {
  constructor (private readonly quoteService: QuoteService) {}

  updateConfirmPage (quote: Quote): Observable<any> {
    return from(this.quoteService.updateQuote(quote));
  }
}
