import { RequirementConfig } from "../../core/_models/requirement-config.model";

export enum ScreenedAnswer {
  NO = "default_no",
  YES = "spx_yes",
}

const DEFAULT_OPTION = {
  "label": "Yes",
  "value": ScreenedAnswer.YES,
};

export const ScreenedConfig: RequirementConfig = {
  "summaryLabel": "",
  "question": "",
  "options": [
    DEFAULT_OPTION,
    {
      "label": "No",
      "value": ScreenedAnswer.NO,
    },
  ],
  "defaultOption": DEFAULT_OPTION,
  "activeOption": DEFAULT_OPTION,
};
