import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import { AdvancedBookingApiDetails } from "../../model/advanced-bookings-api-details.model";
import { ErrorUtil } from "../../utils/error.util";
import { API_CORE } from "../../constant/api-core.const";

@Injectable({
  "providedIn": "root",
})
export class AdvancedBookingsService {
  async getBookings (favorite: boolean): Promise<AdvancedBookingApiDetails[]> {
    const extraOptions = {
      "queryStringParameters": {
        favorite,
      },
    };
    return API.get(
      API_CORE.name,
      API_CORE.URLs.AdvancedBookings.AdvancedBookings,
      extraOptions
    ).catch(ErrorUtil.handleError);
  }

  async setFavoriteById (
    id: number,
    favorite: boolean
  ): Promise<AdvancedBookingApiDetails> {
    const url = API_CORE.URLs.AdvancedBookings.AdvancedBookingsById.replace(
      "{id}",
      id.toString()
    );
    return API.patch(API_CORE.name, url, {
      "body": { favorite },
    });
  }

  async setFavorite (
    request: AdvancedBookingApiDetails
  ): Promise<AdvancedBookingApiDetails> {
    return API.post(
      API_CORE.name,
      API_CORE.URLs.AdvancedBookings.AdvancedBookings,
      {
        "body": request,
      }
    );
  }

  async autoFill (airline: string, origin?: string, destination?: string) {
    const extraOptions = {
      "queryStringParameters": {
        airline,
        origin,
        destination,
      },
    };
    return API.get(
      API_CORE.name,
      API_CORE.URLs.AdvancedBookings.AutoFills,
      extraOptions
    );
  }

  async getSuggestions (): Promise<AdvancedBookingApiDetails[]> {
    const extraOptions = {};
    return API.get(
      API_CORE.name,
      API_CORE.URLs.AdvancedBookings.Suggestions,
      extraOptions
    ).catch(ErrorUtil.handleError);
  }

  async getRecentSuggestion (
    group: string,
    airline?: string,
    origin?: string,
    destination?: string
  ) {
    const extraOptions = {
      "queryStringParameters": { airline, origin },
    },
     url = `${API_CORE.URLs.AdvancedBookings.RecentInput}${group}`;
    return API.get(API_CORE.name, url, extraOptions).catch(
      ErrorUtil.handleError
    );
  }
}
