import {
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";

@Component({
  "selector": "kt-checkbox",
  "templateUrl": "./checkbox.component.html",
  "styleUrls": ["./checkbox.component.scss"],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() id: string;
  @Input() label: string;
  @Input() labelHtml: string;
  @Input() size = 24;
  @Input() fontSize = 16;
  @Input() disabled: boolean;
  @Input() checked: boolean;
  @Input() value = false;
  @Input() disableUntick = false;
  @Output() onToggle = new EventEmitter<boolean>();
  @Input() isLoginPage: boolean;
  @Input() isWalletApp: boolean;

  private touched: boolean;

  onChange = (value: boolean) => {
    this.value = value;
  };
  onTouched = () => {
    // This is intentional
  };

  constructor (
    @Self()
    @Optional()
    public control: NgControl
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  public get invalid (): boolean {
    return this.control ? this.control.invalid : false;
  }

  public get showError (): boolean {
    if (!this.control) {
      return false;
    }
    const { dirty, touched } = this.control;
    return this.invalid ? dirty || touched : false;
  }

  public get errorMessage (): string {
    const error = this?.control?.errors
      ? Object.values(this?.control?.errors)[0].message || null
      : null;
    if (error) {
      return error.replace("{field}", this.label);
    }
    return null;
  }

  writeValue (value: boolean) {
    this.value = value;
  }

  registerOnChange (onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched (onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched () {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState (disabled: boolean) {
    this.disabled = disabled;
  }

  onToggleCheckbox () {
    if (!this.disabled) {
      if (!this.disableUntick || (this.disableUntick && !this.value)) {
        this.value = this.value ? false : true;
        this.onToggle.emit(this.value);
        this.onChange(this.value);
        this.markAsTouched();
      }
    }
  }
}
