import { Aircraft } from "../core/_models/aircraft.model";
import { AirlineBrand } from "../core/_models/airline.model";
import { AirportLight } from "../core/_models/airport-light.model";
import { FlightInput } from "../core/_models/flight-input.model";
import { Trip } from "../core/_models/trip.model";

export const SPECIAL_AIRLINE_CASES = [
  { "id": "BY", "group": "X3" },
  { "id": "X3", "group": "X3" },
  { "id": "TB", "group": "X3" },
  { "id": "OR", "group": "X3" },
  { "id": "6B", "group": "X3" },
  { "id": "TB!", "group": "X3" },
  { "id": "X3!", "group": "X3" },
  { "id": "6B!", "group": "X3" },
  { "id": "CL", "group": "LH" },
  { "id": "LH", "group": "LH" },
  { "id": "WA", "group": "KL" },
  { "id": "IB", "group": "IB" },
  { "id": "YW", "group": "IB" },
  { "id": "AZ", "group": "AZ" },
  { "id": "CT", "group": "AZ" },
  { "id": "AV", "group": "AV" },
  { "id": "QT", "group": "AV" },
  { "id": "KF", "group": "KF" },
  { "id": "X6", "group": "5C" },
  { "id": "X7", "group": "5C" },
  { "id": "5C", "group": "5C" },
];

export const getDimensionFromQuoteItemList = (quoteItemLists) => quoteItemLists.map((item) => ({
    "width": item.quoteItem.dimensionWidth,
    "height": item.quoteItem.dimensionHeight,
    "length": item.quoteItem.dimensionLength,
    "pieces": item.quoteItem.numOfItems,
    "tiltable": false,
    "weight": item.quoteItem.weight,
  }));

export const getDefaultAircraft = (): Aircraft => ({
    "aircraftCode": "UNKN",
    "aircraftName": "Unknown Aircraft",
    "aircraftType": {
      "aircraftTypeId": 5,
      "aircraftTypeName": "Unknown",
      "dimensionHeight": 0,
      "dimensionLength": 0,
      "dimensionWidth": 0,
    },
    "capacity": 50000,
  } as Aircraft);

export const mapFlightSearchToTrip = (
  flight: FlightInput,
  airCrafts?: Aircraft[],
): Trip => ({
    "endArrivalDate": flight.arrivalTime,
    "startDepartureDate": flight.departureTime,
    "flightNo": flight.flightNumber,
    "id": 0,
    "flights": flight?.legs?.map((leg) => {
      if (airCrafts?.length && !leg?.aircraft) {
        leg.aircraft = airCrafts.find(
          (airCraft) => airCraft?.aircraftCode === leg?.aircraftCode,
        );
      }
      return {
        "id": +leg.legId,
        "arrivalDate": leg.arrivalTime,
        "departureDate": leg.departureTime,
        "flightNo": leg.flightNumber,
        "co2": leg.co2.value,
        "originRegionAirport": {
          "regionCode": undefined,
          "regionName": undefined,
          "timezone": undefined,
          "city": undefined,
          "airportName": undefined,
          "destFlights": undefined,
          "originFlights": undefined,
          "airportCode": leg.departureAirport,
        },
        "destRegionAirport": {
          "regionCode": undefined,
          "regionName": undefined,
          "timezone": undefined,
          "city": undefined,
          "airportName": undefined,
          "destFlights": undefined,
          "originFlights": undefined,
          "airportCode": leg.arrivalAirport,
        },
        "aircraft": {
          "aircraftCode": leg.aircraftCode || "UNKN",
          "aircraftName": leg.aircraft?.aircraftName,
          "aircraftType": leg.aircraft?.aircraftType,
        },
        "airlineBrand": {
          "airlineCompanyId": flight.airlineCompany?.airlineCompanyId,
          "airlineCompanyName": undefined,
          "airlineCompanyCode": flight.airlineCode,
          "company": undefined,
          "routes": undefined,
          "negoRoutes": undefined,
          "emailToConfirms": undefined,
          "awbPrefix": flight.airlineCompany?.awbPrefix,
        } as any,
        "trips": [],
        "manageable": false,
        "disabled": false,
      };
    }) as any,
  } as Trip);

export const mapTripToFlightInput = (
  originalTrip: Trip,
  originAirport: AirportLight,
  destinationAirport: AirportLight,
  airlineCompany: AirlineBrand,
): FlightInput => {
  const { flightNo, startDepartureDate, endArrivalDate, id, flights } =
    originalTrip,
   co2 = flights.reduce((acc, curr) => {
    acc += curr.co2 ?? 0;
    return acc;
  }, 0);
  return {
    "flightUUID": `${id}`,
    "source": "",
    "airlineCode": airlineCompany.airlineCompanyCode,
    "flightNumber": flightNo,
    "arrivalTime": endArrivalDate.toString(),
    "arrivalAirport": destinationAirport?.airportCode,
    "departureTime": startDepartureDate.toString(),
    "departureAirport": originAirport?.airportCode,
    "latestAcceptanceTime": undefined,
    "timeOfAvailability": undefined,
    "handlingInfoLink": "",
    "ghaName": "",
    "ghaAddress": "",
    "hle": "",
    "stops": "",
    "elapsedTime": "",
    "freightRate": undefined,
    "sortingFlightKey": `${flightNo}-${startDepartureDate}`,
    "loading": false,
    "available": false,
    "availableReason": "",
    "checkoutUrl": "",
    "features": {
      "awbOptional": false,
      "awbDisabled": false,
      "equote": false,
      "eaw": false,
      "eap": false,
      "bookable": false,
      "instantBookable": false,
      "checkoutUrl": "",
      "maxAttachmentCount": 3,
      "maxAttachmentTotalSize": 9000000,
    },
    "legs": [
      {
        "flightNumber": flightNo,
        "arrivalTime": endArrivalDate.toString(),
        "arrivalAirport": destinationAirport?.airportCode,
        "departureTime": startDepartureDate.toString(),
        "departureAirport": originAirport?.airportCode,
        "bodyType": "Unknown",
        "legId": "",
        "aircraftCode": "UNKN",
        "description": "",
        "parentFlightUUID": "",
        "aircraft": getDefaultAircraft(),
        "co2": { "value": co2, "estimation": true },
      },
    ],
    airlineCompany,
    "rates": [],
    "airlineConditions": "",
    "salesConditions": "",
  };
};
