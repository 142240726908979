import { FormGroup } from "@angular/forms";
import {
  ICommodity,
  RequirementCodeEnum,
  IRequirementSearchTemplate,
} from "@cai-services";
import { RequirementsCmp } from "../constant/requirements-components.const";

export class RequirementUtil {
  static prepareRequirementsAnswer (
    goodsType: ICommodity,
    mainFormGroup: FormGroup,
    requirementFormGroup: FormGroup
  ): IRequirementSearchTemplate[] {
    const specialRequirements = RequirementsCmp.filter(
      (req) => req.isSpecial && req.isEnabled
    ),
     requirementsAnswer: IRequirementSearchTemplate[] = [];
    goodsType?.requirements?.forEach((requirement) => {
      let requirementAnswer = null,
       freeChoice = null,
       field = null;
      const specialRequirement = specialRequirements.find(
        (req) => req.code === requirement.code
      );
      if (specialRequirement) {
        if (specialRequirement.isDynamic) {
          field = requirementFormGroup.get(requirement.code);
        } else {
          field = mainFormGroup.get(requirement.code);
        }
        if (specialRequirement.config.defaultOption) {
          if (!field?.value) {
            requirementAnswer = requirement.answers.find(
              (ans) => ans.code !== specialRequirement.config.activeOption.value
            );
          } else {
            requirementAnswer = requirement.answers.find(
              (ans) => ans.code === specialRequirement.config.activeOption.value
            );
          }
        } else {
          requirementAnswer = requirement.answers.find(
            (ans) => field && field.value === ans.code
          );
        }
      } else {
        field = requirementFormGroup.get(requirement.code);
        requirementAnswer = requirement.answers.find(
          (ans) => field && field.value === ans.code
        );
      }
      if (
        field &&
        requirement.code !== RequirementCodeEnum.DANGEROUS_GOOD_TABLE
      ) {
        if (!requirementAnswer) {
          freeChoice = field.value;
        }
      }
      if (requirementAnswer || freeChoice) {
        requirementsAnswer.push({
          requirement,
          "answer": requirementAnswer,
          freeChoice,
        } as IRequirementSearchTemplate);
      }
    });
    return requirementsAnswer;
  }
}
