declare let window: any;

export class DynamicEnvironment {
  public get datadog () {
    return window.config.datadog;
  }

  public get awsRUM () {
    return window.config.awsRUM;
  }

  public get usetiful () {
    return window.config.usetiful;
  }

  public get name () {
    return window.config.name;
  }

  public get amplify () {
    return window.config.amplify;
  }

  public get intercomAppId () {
    return window.config.intercomAppId;
  }

  public get gtmId () {
    return window.config.gtmId;
  }

  public get recaptchaSiteKey () {
    return window.config.recaptchaSiteKey;
  }

  public get baseURL () {
    return window.config.baseURL;
  }

  public get version () {
    return window.config.version;
  }

  public get alternateProvider (): any {
    return window.config.alternateProvider;
  }

  public get banners (): any[] {
    return window.config.banners;
  }

  public get features () {
    return window.config.features;
  }

  public get siteSupport (): any[] {
    return window.config.siteSupport;
  }

  public get supersetDashboards () {
    return window.config.supersetDashboards;
  }

  public get supersetDomain () {
    return window.config.supersetDomain;
  }

  public get hubSpot () {
    return window.config.hubSpot;
  }

  public get walletConfig () {
    return window.config.walletConfig;
  }

  public get co2OffsetLink () {
    return window.config.co2OffsetLink;
  }

  public get chartsAndAds () {
    return window.config.ChartsAndAds;
  }

  public get ssoConfig () {
    return window.config.ssoConfig;
  }

  public get cargoGateConfig () {
    return window.config.cargoGateConfig;
  }

  public get airlineInviteDisabled () {
    return window.config.airlineInviteDisabled;
  }
}
