import { Injectable } from '@angular/core';
import { environment } from '../../environments';
import { BrandIconUtil } from '../../utils/brand.util';
import { IntercomEventEnum } from '../_enums/intercom-event.enum';
import { User } from '../_models/user.model';
import { SessionService } from './session.service';
import { CargoGateService } from './cargogate.service';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  private readonly cargoGateSubdomain: string;

  constructor(
    private sessionService: SessionService,
    private cargoGateService: CargoGateService,
  ) {
    this.cargoGateSubdomain = cargoGateService.getCargoGateSubdomain();

    if (
      BrandIconUtil.isCargoAiDomain() ||
      this.cargoGateSubdomain === 'ccn' ||
      this.cargoGateSubdomain === 'nap'
    ) {
      this.bootIntercom();
    }
  }

  private fetchCurrentUserEmail() {
    const user: User = this.sessionService.getCurrentUser();
    if (user) {
      return user.userEmail;
    }
    return undefined;
  }

  private bootIntercom() {
    if (window.Intercom) {
      return;
    }

    (window as any).intercomSettings = {
      app_id: environment.intercomAppId,
      updateOnRouterChange: true,
      hide_default_launcher:
        !BrandIconUtil.isCargoAiDomain() &&
        this.cargoGateSubdomain !== 'ccn' &&
        this.cargoGateSubdomain !== 'nap',
    };

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src =
      'https://widget.intercom.io/widget/' + environment.intercomAppId;

    script.onload = () => {
      window.Intercom('reattach_activator');
      window.Intercom('update', window.intercomSettings);
    };

    script.onerror = () => {
      console.error('Error loading Intercom script.');
    };

    document.head.appendChild(script);
  }

  trackEvent(event: IntercomEventEnum, userEmail?: string) {
    if (!window?.Intercom) {
      return;
    }
    if (!userEmail) {
      userEmail = this.fetchCurrentUserEmail();
    }
    const metadata = {
      email: userEmail,
    };
    this.update(metadata);

    window.Intercom('trackEvent', event, metadata);
  }

  openIntercom() {
    if (!window?.Intercom) {
      return;
    }
    window.Intercom('show');
  }

  update(metadata: any) {
    if (!window?.Intercom) {
      return;
    }
    window.Intercom('update', metadata);
  }

  logOut() {
    if (!window?.Intercom) {
      return;
    }
    window.Intercom('shutdown');
  }
}
