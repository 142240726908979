import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { AdvancedBookingCardTypeEnum } from "@cai-services";
import _ from "lodash";
import Popper from "popper.js";
import { filter, fromEvent, takeWhile } from "rxjs";
import { DropdownOption } from "../../core/_models/dropdown-option.model";
import { AdvancedBookingsRequestService } from "../../core/_services/advanced-bookings.service";

@Component({
  "selector": "common-advanced-search",
  "templateUrl": "./advanced-search.component.html",
  "styleUrl": "./advanced-search.component.scss",
})
export class AdvancedSearchComponent
  implements OnChanges, OnInit, ControlValueAccessor
{
  @ViewChild("input") input: ElementRef;
  @Input() label: string;
  @Input() errorLabel: string;
  @Input() noResultsText: string;
  @Input() customClass: string;
  @Input() showIcon: boolean;
  @Input() showCurrencyIcon: boolean;
  @Input() floatLabel: boolean;
  @Input() textCenter: boolean;
  @Input() viewOnly: boolean;
  @Input() uppercase: boolean;
  @Input() popup: boolean;
  @Input() options: DropdownOption[] = [];
  @Input() recentOptions: DropdownOption[] = [];
  @Input() excludeValues: string[] = [];
  @Input() minWidth = 800;
  @Input() maxWidth = 800;
  @Input() index: number;
  @Input() allowManualInput: boolean;
  @Input() showAllOnFocus: boolean;
  @Input() inputType: string;
  @Input() cardType: AdvancedBookingCardTypeEnum;
  @Output() onBlur = new EventEmitter();
  @Output() onFocus = new EventEmitter();

  display = "";
  popperRef: Popper;
  selected: DropdownOption;
  filteredOptions: DropdownOption[] = [];
  filteredRecentOptions: DropdownOption[] = [];
  view: EmbeddedViewRef<any>;
  searchKey: string;

  private _value: string;
  get value () {
    return this._value;
  }
  set value (e: string) {
    this.findOption(e);
    this.onChange(this.selected?.value);
    this._value = this.selected?.value;
    this.cdr.detectChanges();
  }

  onChange = (value: any) => {};
  onTouched = () => {};

  constructor (
    @Self()
    @Optional()
    private readonly control: NgControl,
    private readonly el: ElementRef,
    private readonly zone: NgZone,
    private readonly vcr: ViewContainerRef,
    private readonly cdr: ChangeDetectorRef,
    private readonly advancedBookingsRequestService: AdvancedBookingsRequestService,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit () {
    this.minWidth = this.label === "Allotment" ? 200 : 500;
  }

  constructGroupedOption () {
    if (this.options?.length >= 0) {
      this.options = _.uniqBy(this.options, "value");
      this.options = [...this.recentOptions, ...this.options];
    }
    this.cdr.detectChanges();
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty("options") ||
      changes.hasOwnProperty("recentOptions")
    ) {
      this.constructGroupedOption();
      if (this.allowManualInput) {
        this.filterOptions(this.searchKey || this.display);
        if (this.value) {
          this.searchKey = this.value;
        }
        return;
      }
      if (!changes.hasOwnProperty("recentOptions")) {
        this.findOption(this.value);
      }
      this.filterOptions(this.searchKey || this.display);

      this.cdr.detectChanges();
    }
  }

  registerOnChange (onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched (onTouched: any) {
    this.onTouched = onTouched;
  }

  writeValue (value: any) {
    this.value = value;
    this.onChange(value);
  }

  markAsTouched () {
    this.onTouched();
  }

  get boundingClientRect () {
    return this.el.nativeElement.getBoundingClientRect();
  }

  get invalid (): boolean {
    return this.control?.touched ? this.control.invalid : false;
  }

  get showError (): boolean {
    if (!this.control) {
      return false;
    }
    const { dirty, touched } = this.control;
    return this.invalid ? dirty || touched : false;
  }

  get errorMessage (): string {
    if (this.control?.errors) {
      const error = Object.values(this.control.errors)[0].message || null;
      if (error) {
        return error.replace("{field}", this.errorLabel || this.label);
      }
    }
    return null;
  }

  get isOpen (): boolean {
    return !!this.popperRef;
  }

  get showNoResults (): boolean {
    return (
      this.display &&
      !this.filteredOptions.length &&
      !this.filteredRecentOptions.length
    );
  }

  get isFocused (): boolean {
    return this.input?.nativeElement === document.activeElement;
  }

  keyup ($event, template: TemplateRef<any>, wrapper: HTMLElement) {
    const key = $event.keyCode || $event.charCode;
    if (key === 13) {
      this.blur($event.target.value);
      return;
    }

    if (key === 9) {
      return;
    }
    this.markAsTouched();
    this.filterOptions(this.display);

    this.searchKey = $event.target.value;

    if (!this.isOpen) {
      this.open(template, wrapper, false);
    }
  }

  blur (keyword: string) {
    if (keyword) {
      if (this.isOpen && this.filteredOptions.length) {
        if (!this.selected && !this.searchKey) {
          this.filterOptions(keyword);
          this.select(this.filteredOptions[0]);
        }
      }
    } else {
      this.selected = null;
    }
    this.close();
    this.markAsTouched();
    const newSearchKey = this.uppercase
      ? this.searchKey?.toUpperCase()
      : this.searchKey;
    this.onChange(
      this.allowManualInput ? newSearchKey || "" : this.value || null,
    );

    this.onBlur.emit();
    this.cdr.detectChanges();
  }

  select (option: DropdownOption) {
    this.selected = option;
    this.display = option?.display || option?.label || this.searchKey;
    this.value = option?.value;
    if (this.allowManualInput) {
      this.searchKey = option?.value;
    }
    this.close();
    this.cdr.detectChanges();
  }

  open (template: TemplateRef<any>, wrapper: HTMLElement, showAllOnFocus) {
    if (
      !(
        this.advancedBookingsRequestService?.copiedAdvancedBookingItem
          ?.cardType === this.cardType &&
        this.advancedBookingsRequestService?.copiedAdvancedBookingItem
          ?.index === this.index
      ) &&
      this.advancedBookingsRequestService?.copiedAdvancedBookingItem &&
      Object.values(
        this.advancedBookingsRequestService?.copiedAdvancedBookingItem,
      )?.length
    ) {
      return this.close();
    }
    this.onFocus.emit();
    if (this.isOpen) {
      this.close();
    }
    this.filterOptions(showAllOnFocus ? null : this.display);
    if (
      this.filteredOptions?.length ||
      this.showNoResults ||
      this.allowManualInput
    ) {
      this.view = this.vcr.createEmbeddedView(template);
      const dropdown = this.view.rootNodes[0];
      document.body.appendChild(dropdown);

      this.zone.runOutsideAngular(() => {
        this.popperRef = new Popper(wrapper, dropdown, {
          "placement": "bottom-start",
          "modifiers": {
            "offset": {
              "enabled": true,
              "offset": "0, 10",
            },
            "keepTogether": {
              "enabled": true,
            },
            "preventOverflow": {
              "enabled": true,
              "escapeWithReference": true,
            },
          },
        });
      });

      fromEvent(document, "click")
        .pipe(
          takeWhile(() => this.isOpen),
          filter(({ target }) => {
            const origin = this.popperRef?.reference as HTMLElement;
            return !origin?.contains(target as HTMLElement);
          }),
        )
        .subscribe(() => {
          this.close();
        });
    }
  }

  close () {
    this.popperRef?.destroy();
    this.view?.destroy();
    this.popperRef = null;
    this.view = null;
    this.cdr.detectChanges();
  }

  trackBy (index, item) {
    return item.value;
  }

  convertCase (
    text: string,
    textCase: "lowercase" | "uppercase" = "lowercase",
  ): string {
    if (text) {
      return textCase === "uppercase" ? text.toUpperCase() : text.toLowerCase();
    }
    return text;
  }

  filterOptions (keyword: string): void {
    let filteredOptions = [],
     filteredRecentOptions = [];
    if (keyword) {
      filteredOptions = this.findMatches(keyword, this.options);
      filteredRecentOptions = this.findMatches(keyword, this.recentOptions);
    } else {
      filteredOptions = this.options;
      filteredRecentOptions = this.recentOptions;
    }

    this.filteredRecentOptions = filteredRecentOptions.filter(
      (recentOption) => !this.excludeValues
          .filter((value) => value)
          .includes(recentOption.value),
    );

    this.filteredOptions = filteredOptions?.filter((option) => !this.excludeValues
        .filter((value) => value)
        .includes(option.value));
    this.cdr.detectChanges();
  }

  findMatches (keyword: string, options: DropdownOption[]) {
    return options.filter((option) => {
      const defaultMatch: boolean = this.convertCase(option.label).includes(
        this.convertCase(keyword),
      );
      let criteriaMatch: boolean;
      if (option?.criteria?.length) {
        criteriaMatch =
          option?.criteria.filter((item) => this.convertCase(item.value, item.textCase).includes(
              this.convertCase(keyword, item.textCase),
            )).length > 0;
      }
      return criteriaMatch ? criteriaMatch : defaultMatch;
    });
  }

  shouldHide (index) {
    if (
      this.filteredOptions.length === 2 &&
      this.filteredRecentOptions.length === 1 &&
      index === 1
    ) {
      return true;
    }
    return false;
  }

  findOption (value: string) {
    const selected = this.options?.find(
      (item) =>
        item.value === value && !this.excludeValues.includes(item.value),
    );
    this.selected = selected;
    const userValue = this.allowManualInput ? value || "" : "";
    this.display =
      this.selected?.display ||
      this.selected?.label ||
      this.searchKey ||
      userValue;

    this.cdr.detectChanges();
  }

  getHighlightedText (text: string): string {
    if (this.searchKey) {
      return text
        .replace(new RegExp(this.searchKey, "gi"), (match) => {
          if (match[0]) {
            const firstCharLetter = Boolean(match[0].match(/[a-zA-Z0-9]/));
            if (firstCharLetter) {
              return `<mark>${match}</mark>`;
            }
            return `${match[0]}<mark>${match.substring(1)}</mark>`;
          }
          return match;
        })
        .replace(/ /gi, "&nbsp;");
    } else {
      return text;
    }
  }

  calculateDropdownHeightPx (): number {
    const itemCount = this.filteredOptions.length;
    return itemCount * 30;
  }
}
