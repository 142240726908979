import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import { API_CORE } from "../../constant/api-core.const";
import { IPermissionSet } from "../../model";

@Injectable({
  "providedIn": "root",
})
export class PermissionSetService {
  public getPermissionSets (): Promise<IPermissionSet[]> {
    return API.get(API_CORE.name, API_CORE.URLs.PermissionSet.PermissionSets, {});
  }
}
