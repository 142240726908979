export enum QuoteStatusEnum {
  FAILED = "FAILED",
  PENDING_AIRLINE = "PENDING_AIRLINE",
  ACCEPTED = "ACCEPTED",
  COUNTER_OFFER = "COUNTER_OFFER",
  REJECTED = "REJECTED",
  REJECTED_WITH_NEW_CAPACITY = "REJECTED_WITH_NEW_CAPACITY",
  BOOKING_REJECTED = "BOOKING_REJECTED",
  NOT_REPRESENTATIVE = "NOT_REPRESENTATIVE",
  PENDING_DELIVERY = "PENDING_DELIVERY",
  IN_TRANSIT = "IN_TRANSIT",
  AT_DESTINATION = "AT_DESTINATION",
  DELIVERED = "DELIVERED",
  EXPIRED = "EXPIRED",
  INVALID = "INVALID",
  PENDING_AIRLINE_INFORMATION = "PENDING_AIRLINE_INFORMATION",
  CANCELLED = "CANCELLED",
  CANCELLATION_REQUESTED = "CANCELLATION_REQUESTED",
  REQUEST_FAILED = "REQUEST_FAILED",
  BOOKING_CONFIRMED = "BOOKING_CONFIRMED",
  BOOKING_FAILED = "BOOKING_FAILED",
  QUOTE_CANCELLED = "QUOTE_CANCELLED",
  PENDING_BOOKING_MODIFY = "PENDING_BOOKING_MODIFY",
  QUOTE_CANCELLATION_REQUESTED = "QUOTE_CANCELLATION_REQUESTED",
  OPTION_REQUESTED = "OPTION_REQUESTED",
  OPTION_PLACED = "OPTION_PLACED",
  OPTION_REJECTED = "OPTION_REJECTED",
  OPTION_CANCELLED = "OPTION_CANCELLED",
  PENDING_OPTION_MODIFY = "PENDING_OPTION_MODIFY",
}
export enum QuoteStatusEnumWithString {
  FAILED = "FAILED",
  PENDING_AIRLINE = "REQUESTED",
  ACCEPTED = "ACCEPTED",
  COUNTER_OFFER = "COUNTER OFFER",
  REJECTED = "REJECTED",
  REJECTED_WITH_NEW_CAPACITY = "NEW CAPACITY",
  BOOKING_REJECTED = "REJECTED",
  NOT_REPRESENTATIVE = "NOT REPRESENTATIVE",
  PENDING_DELIVERY = "CONFIRMED",
  IN_TRANSIT = "IN TRANSIT",
  AT_DESTINATION = "AT DESTINATION",
  DELIVERED = "DELIVERED",
  EXPIRED = "EXPIRED",
  INVALID = "INVALID",
  PENDING_AIRLINE_INFORMATION = "REQUESTED",
  CANCELLED = "CANCELLED",
  CANCELLATION_REQUESTED = "CANCELLATION REQUESTED",
  REQUEST_FAILED = "REQUEST FAILED",
  BOOKING_CONFIRMED = "CONFIRMED",
  BOOKING_FAILED = "FAILED",
  QUOTE_CANCELLED = "QUOTE CANCELLED",
  PENDING_BOOKING_MODIFY = "PENDING BOOKING MODIFY",
  OPTION_REQUESTED = "OPTION REQUESTED",
  OPTION_PLACED = "OPTION PLACED",
  OPTION_REJECTED = "OPTION REJECTED",
  OPTION_CANCELLED = "OPTION CANCELLED",
}
