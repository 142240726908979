import { RequirementTableConfig } from "../../core/_models/requirement-config.model";

export const DangerousGoodTableConfig: RequirementTableConfig = {
  "columns": [
    {
      "name": "unid",
      "title": $localize`:@@dangerous-goods.unid-display:UN/ID No`,
      "tooltip": null,
      "showRequired": true,
      "width": "90px",
      "customClass": "ml-0",
      "validators": {
        "required": (value: number) =>
          [null, undefined].includes(value) || value.toString().length < 1
            ? "UN/ID is required"
            : null,
      },
    },
    {
      "name": "goodClass",
      "title": $localize`:@@dangerous-goods.classDiv-display:Class/Div`,
      "tooltip": null,
      "showRequired": false,
      "width": "83px",
      "validators": {
        "required": (value: number) =>
          [null, undefined].includes(value) || value.toString().length < 1
            ? "Class/Div cannot be empty"
            : null,
        "min": (value: string) => {
          const parsedValue = +value?.toString().replace(/[^0-9.]/g, "");
          return value && parsedValue < 1
            ? "Class/Div value is minimum is 1"
            : null;
        },

        "max": (value: string) => {
          const parsedValue = +value?.toString().replace(/[^0-9.]/g, "");
          return value && parsedValue > 9
            ? "Class/Div value is maximum is 9"
            : null;
        },
        "format": (value: string) =>
          value && !new RegExp(/^\d{0,3}(?:[.,]\d{1,2})?[A-Za-z]?$/).test(value)
            ? "Class/Div format is incorrect. Example : 8 / 4.9 / 1.2H / 5.27G"
            : null,
      },
    },
    {
      "name": "packagingGroup",
      "title": $localize`:@@dangerous-goods.packingGroup-display:PG`,
      "tooltip": $localize`:@@dangerous-goods.packing-group-tooltip:Packing Group`,
      "showRequired": false,
      "width": "75px",
      "validators": {
        "required": (value: number) =>
          [null, undefined].includes(value) || value.toString().length < 1
            ? "Packing Group cannot be empty"
            : null,
        "format": (value: string) =>
          value && !["I", "II", "III"].includes(value)
            ? "Packing Group value is incorrect. Values : I / II / III"
            : null,
      },
    },
    {
      "name": "packagingInstruction",
      "title": $localize`:@@dangerous-goods.packingInstruction-display:PI`,
      "tooltip": $localize`:@@dangerous-goods.packingInstruction-tooltip:Package Instruction`,
      "showRequired": false,
      "width": "70px",
      "customClass": "mr-0 rounded-right-0",
      "validators": {
        "required": (value: number) =>
          [null, undefined].includes(value) || value.toString().length < 1
            ? "Packing Instruction cannot be empty"
            : null,
        "format": (value: string) =>
          value && !new RegExp(/^[Yy]{0,1}?\d{3}$/).test(value)
            ? "Packing Instruction format is incorrect. Example : Y111 / 111"
            : null,
      },
    },
    {
      "name": "totalNet",
      "title": $localize`:@@dangerous-goods.totalNet-display:Total Net Qty`,
      "tooltip": $localize`:@@dangerous-goods.totalNet-tooltip:Total net quantity`,
      "showRequired": false,
      "width": "100px",
      "customClass": "mx-0 rounded-0",
      "validators": {
        "required": (value: number) =>
          [null, undefined].includes(value) || value.toString().length < 1
            ? "Total Net Quantity is required"
            : null,
      },
    },
    {
      "name": "uom",
      "title": $localize`:@@dangerous-goods.uom-display:UOM`,
      "tooltip": $localize`:@@dangerous-goods.uom-tooltip:Unit of measurement`,
      "showRequired": false,
      "width": "55px",
      "customClass": "ml-0 rounded-left-0",
    },
    {
      "name": "caoPackagingInstruction",
      "title": $localize`:@@dangerous-goods.packingInstruction-display:PI`,
      "tooltip": $localize`:@@dangerous-goods.packingInstruction-tooltip:Package Instruction`,
      "showRequired": false,
      "isCAO": true,
      "width": "70px",
      "customClass": "mr-0 rounded-right-0",
      "validators": {
        "required": (value: number) =>
          [null, undefined].includes(value) || value.toString().length < 1
            ? "Packing Instruction cannot be empty"
            : null,
        "format": (value: string) =>
          value && !new RegExp(/^[Yy]{0,1}?\d{3}$/).test(value)
            ? "Packing Instruction format is incorrect. Example : Y111 / 111"
            : null,
      },
    },
    {
      "name": "caoTotalNet",
      "title": $localize`:@@dangerous-goods.totalNet-display:Total Net Qty`,
      "tooltip": $localize`:@@dangerous-goods.totalNet-tooltip:Total net quantity`,
      "showRequired": false,
      "isCAO": true,
      "isCAOLabelHolder": true,
      "width": "100px",
      "customClass": "mx-0 pl-2 rounded-0",
      "validators": {
        "required": (value: number) =>
          [null, undefined].includes(value) || value.toString().length < 1
            ? "Total Net Quantity is required"
            : null,
      },
    },
    {
      "name": "caoUom",
      "title": $localize`:@@dangerous-goods.uom-display:UOM`,
      "tooltip": $localize`:@@dangerous-goods.uom-tooltip:Unit of measurement`,
      "showRequired": false,
      "isCAO": true,
      "width": "55px",
      "customClass": "ml-0 rounded-left-0",
    },
    {
      "name": "shc",
      "title": $localize`:@@dangerous-goods.shc-display:SHC`,
      "tooltip": $localize`:@@dangerous-goods.shc-tooltip:Special handling code`,
      "showRequired": false,
      "width": "120px",
      "validators": {
        "required": (value: number) =>
          [null, undefined].includes(value) || value.toString().length < 1
            ? "SHC cannot be empty"
            : null,
      },
    },
  ],
};

export const DangerousGoodUOM = [
  {
    "label": "Kilograms (kg)",
    "value": "kg",
  },
  {
    "label": "Litres (L)",
    "value": "L",
  },
];
