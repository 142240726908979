import {
  AuthenticationMethodEnum,
  IAirport,
  ICompany,
  ICountry,
  IOfficeAccess,
  IUser,
  IUserContactInfo,
  MeasurementSystemEnum,
  UserStatusEnum,
  UserTypeEnum,
} from '@cai-services';
import { Airport } from './region-airport.model';

export class User implements IUser {
  userId: number;
  type: UserTypeEnum;
  authenticationMethod: AuthenticationMethodEnum;
  userName: string;
  userEmail: string;
  lastName: string;
  comments: string;
  firstName: string;
  contact: IUserContactInfo;
  userStatus: UserStatusEnum;
  branchName: string;
  branchCode: string;
  logoPath: string;
  sita: string;
  smtp: string;
  pima: string;
  defaultCurrency: string;
  company: ICompany;
  country: ICountry;
  linkedInProfile: string;
  measurementSystem: MeasurementSystemEnum;
  defaultSearchDay: number;
  defaultTimezone: string;
  language: string;
  originAirport: IAirport;
  officeAccesses: IOfficeAccess[];
  createdBy: string;
  createdAt: Date;
  updatedBy: string;
  updatedAt: Date;
  createdDomain: string;
  providerId?: string;
  hasAccessToBi?: boolean;
  activatedFeatures?: { activatedCount: number; featureName: string }[];
  emailVerified?: any;
  cognitoEnabled?: any;
  companyName?: string;
  companyId?: any;

  constructor(
    userName?: string,
    userEmail?: string,
    userType?: UserTypeEnum,
    defaultCurrency?: string,
    originAirport?: Airport,
    measurementSystem?: MeasurementSystemEnum,
  ) {
    this.userEmail = userEmail;
    this.userName = userName;
    this.type = userType;
    this.defaultCurrency = defaultCurrency;
    this.originAirport = originAirport;
    this.measurementSystem = measurementSystem
      ? measurementSystem
      : MeasurementSystemEnum.METRIC;
  }
}
