import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AccountWalletDetails,
  CustomerService,
  TazapayMethodsEnum,
  TazapayPaymentMethod,
  WalletBankAccountDetails,
  WalletCurrency,
  WalletService,
  WalletStatusTypeEnum,
  WalletTopUp,
  WalletTopupPaymentTypeEnum,
} from '@cai-services';
import { v4 as uuid } from 'uuid';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { getCurrency } from 'country-currency-map';
import _, { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { PaymentPopupComponent } from '../../../payment-popup/payment-popup.component';
import { ApplicationProperties } from '../../../../core/_base/layout/models/app-properties.model';
import { APP_PROPS } from '../../../../cai-common.module';
import { Office } from '../../../../core/_models/office.model';
import { SessionService } from '../../../../core/_services/session.service';
import { User } from '../../../../core/_models/user.model';
import { WalletUtil } from '../../../../utils/wallet.util';
import { SUPPORTED_CREDIT_CARD_CURRENCIES } from '../../../../../lib/core/_constants/constants';

@Component({
  selector: 'kt-wallet-topup',
  templateUrl: './wallet-topup.component.html',
  styleUrls: ['./wallet-topup.component.scss'],
})
export class WalletTopupComponent implements OnInit, OnDestroy {
  public walletStatus: WalletStatusTypeEnum = WalletStatusTypeEnum.INACTIVE;
  walletDetails: AccountWalletDetails;
  topupData: WalletTopUp = new WalletTopUp();
  bankDetails: Array<WalletBankAccountDetails> =
    new Array<WalletBankAccountDetails>();
  minAmount = 1;
  maxAmount = 500000;
  initMaxAmount = 500000;
  initMinAmount = 1;
  paymentFees = '';
  isProcessCompleted: boolean;
  walletLoader = true;
  currencyList: Array<WalletCurrency> = [];
  transactionId = '';
  referenceId = '';
  isCreditCardEnabled = false;
  isLocalEnabled = false;
  isInternationEnabled = false;
  isOtherEnabled = false;
  isPaymentPage = false;
  paymentStatus = '';
  user: User;
  modalRef: NgbModalRef;
  paymentMethodDetails: TazapayPaymentMethod[];
  customCountryMapping = [
    { currency: 'EUR', country: 'DE' },
    { currency: 'XAF', country: 'GQ' },
    { currency: 'XOF', country: 'NE' },
    { currency: 'XPF', country: 'PF' },
  ];
  wireTransferCurrencies = [
    'CAD',
    'BHD',
    'SAR',
    'KWD',
    'SGD',
    'ZAR',
    'CZK',
    'SEK',
    'DKK',
    'NZD',
    'CHF',
    'EUR',
    'NOK',
    'HKD',
    'OMR',
    'TRY',
    'HUF',
    'PLN',
    'GBP',
    'QAR',
    'AED',
    'ILS',
    'RON',
    'USD',
  ];
  supportCreditCardCurrencies = cloneDeep(SUPPORTED_CREDIT_CARD_CURRENCIES);
  private officeChangedStatusSub: Subscription;

  constructor(
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private readonly sessionService: SessionService,
    private readonly walletService: WalletService,
    private readonly customerService: CustomerService,
    private readonly router: Router,
    private readonly ref: ChangeDetectorRef,
    private readonly modalService: NgbModal,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    const officeStatus = this.sessionService.getSelectedOffice()?.walletStatus;
    if (officeStatus != WalletStatusTypeEnum.ACTIVE) {
      this.router.navigateByUrl(this.appProperties.walletBaseUrl);
      return;
    }

    this.topupData.paymentMethod =
      WalletTopupPaymentTypeEnum.INTERNATIONAL_BANK;
  }

  async ngOnInit(): Promise<void> {
    this.user = this.sessionService.getCurrentUser();

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.token && params.transactionid) {
        this.transactionId = params.transactionid;
        this.topupData.paymentMethod = WalletTopupPaymentTypeEnum.OTHER;
        this.handleCreditCardPayment(params.token);
      }
      if (params.transactionId && params.status) {
        this.paymentStatus = params.status;
        this.topupData.currency = params.currency;
        this.topupData.paymentMethod = WalletTopupPaymentTypeEnum.OTHER;
        this.transactionId = params.transactionId;
        this.walletLoader = false;
        this.paymentSucceed();
        this.ref.detectChanges();
        console.log('payment successful');
      }
    });

    if (
      this.router.url.includes('token') ||
      this.router.url.includes('transactionId') ||
      this.router.url.includes('status')
    ) {
      this.isPaymentPage = true;
      return;
    }

    this.officeChangedStatusSub =
      this.sessionService.officeChangedStatusObs.subscribe(async (_) => {
        const office = this.sessionService.getSelectedOffice();
        this.supportCreditCardCurrencies = cloneDeep(
          SUPPORTED_CREDIT_CARD_CURRENCIES,
        );
        this.supportCreditCardCurrencies.push(
          office.country?.localCurrency?.currencyCode,
        );
        this.isLocalEnabled = false;
        await this.getWalletAccountDetails();
        this.onChangeCurrency();
      });
  }

  ngOnDestroy(): void {
    if (this.officeChangedStatusSub) {
      this.officeChangedStatusSub.unsubscribe();
    }
  }

  getMatchingPaymentMethod(
    paymentType: WalletTopupPaymentTypeEnum,
  ): TazapayPaymentMethod {
    return this.paymentMethodDetails?.find((method) => {
      if (
        paymentType === WalletTopupPaymentTypeEnum.LOCAL_BANK &&
        method.PaymentName === 'Local Bank Transfer'
      ) {
        return true;
      } else if (
        paymentType === WalletTopupPaymentTypeEnum.INTERNATIONAL_BANK &&
        method.PaymentName === 'Wire Transfer'
      ) {
        return true;
      } else if (
        paymentType === WalletTopupPaymentTypeEnum.CREDIT_CARD &&
        method.PaymentName === 'Card'
      ) {
        return true;
      } else if (
        paymentType === WalletTopupPaymentTypeEnum.OTHER &&
        method.Group === 'apm'
      ) {
        return true;
      }
      return false;
    });
  }

  async onChangePaymentType(
    paymentType: WalletTopupPaymentTypeEnum,
  ): Promise<void> {
    if (this.topupData.currency) {
      this.topupData.paymentMethod = paymentType;
      this.paymentFees = '';
      if (this.topupData.currency != 'USD') {
        this.maxAmount = this.initMaxAmount;
      }
      this.minAmount = this.initMinAmount;

      if (
        paymentType === WalletTopupPaymentTypeEnum.CREDIT_CARD ||
        paymentType === WalletTopupPaymentTypeEnum.OTHER ||
        this.walletDetails?.provider === 'tazapay'
      ) {
        const matchingMethod = this.getMatchingPaymentMethod(paymentType);
        if (this.paymentMethodDetails?.length > 0 && matchingMethod) {
          if (this.paymentMethodDetails?.[0]?.MaxLimit) {
            this.maxAmount =
              +matchingMethod.MaxLimit ||
              +this.paymentMethodDetails?.[0]?.MaxLimit;
          } else {
            const tazapayBankMaxLimit = '500000',
              tazapayCardOtherMaxLimit = '14000',
              convertedMaxLimit =
                await this.walletService.getWalletConverterdPayLaterBalances(
                  'USD',
                  this.topupData.currency,
                  this.topupData.paymentMethod ===
                    WalletTopupPaymentTypeEnum.CREDIT_CARD ||
                    this.topupData.paymentMethod ===
                      WalletTopupPaymentTypeEnum.OTHER
                    ? tazapayCardOtherMaxLimit
                    : tazapayBankMaxLimit,
                  false,
                );
            this.maxAmount = convertedMaxLimit.converted_amount;
          }
          if (this.paymentMethodDetails[0].MinTopupAmount) {
            const matchingMethod = this.getMatchingPaymentMethod(paymentType);
            this.minAmount =
              +matchingMethod?.MinTopupAmount ||
              +this.paymentMethodDetails?.[0]?.MinTopupAmount;
          }
          this.maxAmount = Math.round(this.maxAmount);
          this.minAmount = Math.round(this.minAmount);
          if (
            paymentType === WalletTopupPaymentTypeEnum.CREDIT_CARD ||
            paymentType === WalletTopupPaymentTypeEnum.OTHER
          ) {
            if (paymentType === WalletTopupPaymentTypeEnum.CREDIT_CARD) {
              this.paymentFees = WalletUtil.getCreditCardFee(
                this.getCountryCode(),
              );
            } else {
              this.paymentFees = '1.70';
            }
          }
        } else if (
          paymentType === WalletTopupPaymentTypeEnum.INTERNATIONAL_BANK
        ) {
          const localBankTransfer = this.paymentMethodDetails?.find(
            (method) => method?.Family === 'local_bank_transfer',
          );
          if (
            localBankTransfer &&
            localBankTransfer?.MaxLimit &&
            localBankTransfer?.MinTopupAmount
          ) {
            this.maxAmount = +localBankTransfer.MaxLimit;
            this.minAmount = +localBankTransfer.MinTopupAmount;
          } else {
            const tazapayBankMaxLimit = '500000',
              tazapayBankMinLimit = '50',
              convertedMaxLimit =
                await this.walletService.getWalletConverterdPayLaterBalances(
                  'USD',
                  this.topupData?.currency,
                  tazapayBankMaxLimit,
                  false,
                ),
              convertedMinLimit =
                await this.walletService.getWalletConverterdPayLaterBalances(
                  'USD',
                  this.topupData?.currency,
                  tazapayBankMinLimit,
                  false,
                );
            this.maxAmount = convertedMaxLimit.converted_amount;
            this.minAmount = convertedMinLimit.converted_amount;
          }
          this.ref.detectChanges();
        }
      } else {
        const convertedMaxLimit =
          await this.walletService.getWalletConverterdPayLaterBalances(
            'USD',
            this.topupData.currency,
            this.initMaxAmount.toString(),
            false,
          );
        this.maxAmount = Math.round(convertedMaxLimit.converted_amount);

        const convertedMinLimit =
          await this.walletService.getWalletConverterdPayLaterBalances(
            'USD',
            this.topupData.currency,
            this.initMinAmount.toString(),
            false,
          );
        this.minAmount = Math.round(convertedMinLimit.converted_amount);
      }
      this.ref.detectChanges();
    }
  }

  getCountryCode(): string {
    return this.customCountryMapping.some(
      (x) => x.currency == this.topupData?.currency,
    )
      ? this.customCountryMapping.find(
          (x) => x.currency == this.topupData?.currency,
        ).country
      : this.topupData?.currency?.slice(0, 2);
  }

  async onChangeCurrency(): Promise<void> {
    let tazapayPaymentMethod = await this.customerService.getPaymentTypes(
      this.topupData.currency,
      this.getCountryCode(),
      '',
    );

    tazapayPaymentMethod = tazapayPaymentMethod?.filter(
      (method) => method.Currency === method.PaymentCurrency,
    );
    this.paymentMethodDetails = tazapayPaymentMethod;
    this.topupData.paymentMethod = null;
    this.isCreditCardEnabled = tazapayPaymentMethod?.some(
      (type) => type.Family === TazapayMethodsEnum.CARD,
    );
    this.isOtherEnabled = tazapayPaymentMethod?.some(
      (type) => type.Group === 'apm',
    );
    if (this.walletDetails?.provider === 'tazapay') {
      this.isLocalEnabled = tazapayPaymentMethod?.some(
        (type) => type.Family === TazapayMethodsEnum.LOCAL,
      );
      if (this.wireTransferCurrencies?.includes(this.topupData?.currency)) {
        this.isInternationEnabled = true;
      } else {
        this.isInternationEnabled = tazapayPaymentMethod?.some(
          (type) => type.Family === TazapayMethodsEnum.INTERNATIONAL,
        );
      }
      await this.updateDefaultPaymentMethod();
    } else {
      await this.getBankDetails();
    }
    this.ref.detectChanges();
  }
  async updateDefaultPaymentMethod(): Promise<void> {
    if (this.isLocalEnabled) {
      this.topupData.paymentMethod = WalletTopupPaymentTypeEnum.LOCAL_BANK;
    } else if (this.isInternationEnabled) {
      this.topupData.paymentMethod =
        WalletTopupPaymentTypeEnum.INTERNATIONAL_BANK;
    } else if (
      this.walletDetails?.provider === 'currencycloud' &&
      this.hasLocalBank
    ) {
      this.topupData.paymentMethod = WalletTopupPaymentTypeEnum.LOCAL_BANK;
    } else if (
      this.walletDetails?.provider === 'currencycloud' &&
      this.hasInternationalBank
    ) {
      this.topupData.paymentMethod =
        WalletTopupPaymentTypeEnum.INTERNATIONAL_BANK;
    } else if (this.isCreditCardEnabled) {
      this.topupData.paymentMethod = WalletTopupPaymentTypeEnum.CREDIT_CARD;
    } else if (this.isOtherEnabled) {
      this.topupData.paymentMethod = WalletTopupPaymentTypeEnum.OTHER;
    }
    this.ref.detectChanges();
    await this.onChangePaymentType(this.topupData.paymentMethod);
  }
  get hasLocalBank(): boolean {
    return this.bankDetails?.some((r) => !r.payment_type.includes('priority'));
  }
  get hasInternationalBank(): boolean {
    return this.bankDetails?.some((r) => r.payment_type.includes('priority'));
  }
  async getBankDetails(): Promise<void> {
    try {
      const office = await this.sessionService.getSelectedOfficeAsync(),
        officeId = office?.id?.toString(),
        status = office?.walletStatus?.toString();
      if (
        officeId &&
        this.topupData.currency &&
        (status === WalletStatusTypeEnum.ACTIVE ||
          status == WalletStatusTypeEnum.BLOCKED)
      ) {
        this.walletLoader = true;
        this.ref.detectChanges();
        let getSwifBankDetails$;
        if (this.isCargoWallet) {
          getSwifBankDetails$ = this.customerService.getSwifBankDetails(
            office.walletId,
            this.topupData.currency,
          );
        } else {
          getSwifBankDetails$ = this.walletService.getSwifBankDetails(
            officeId,
            this.topupData.currency,
          );
        }
        const bank = await getSwifBankDetails$;
        this.bankDetails = bank;
        this.walletLoader = false;
        this.ref.detectChanges();
        await this.updateDefaultPaymentMethod();
      }
    } catch (e) {
      this.walletLoader = false;
      this.bankDetails = undefined;
      await this.updateDefaultPaymentMethod();
      this.ref.detectChanges();
      console.error(e);
    }
  }

  async fetchCurrencies(office: Office): Promise<void> {
    try {
      let getWalletCurrencyList$;
      if (this.isCargoWallet) {
        getWalletCurrencyList$ =
          this.customerService.getTazapayCurrencies(true);
      } else {
        getWalletCurrencyList$ = this.walletService.getWalletCurrencyList(
          office.id.toString(),
        );
      }
      const currencyData = await getWalletCurrencyList$;
      this.currencyList = currencyData.map((x) => {
        x.name = x.currency + ' - ' + this.getModifiedCurrencyName(x.currency);
        x.code = x.currency;
        return x;
      });
      this.currencyList = _.chain(this.currencyList)
        .uniqBy('name')
        .sortBy('name')
        .value();

      this.ref.detectChanges();
    } catch (err) {
      console.error(err);
    }
  }
  getModifiedCurrencyName(code: string): string {
    const currencyMap = {
        RON: 'Romanian Leu',
        TRY: 'Turkish Lira',
        GHS: 'Ghanaian Cedi',
        MGA: 'Malagasy Ariary',
        TJS: 'Tajikistani Somoni',
        TMT: 'Turkmenistani Manat',
      },
      currency = getCurrency(code);
    if (currencyMap[code]) {
      return currencyMap[code];
    }
    if (!currency) {
      return '';
    }

    const splittedName = currency?.name?.split(' ');
    splittedName?.pop();
    return splittedName?.join(' ');
  }
  async getWalletAccountDetails(): Promise<void> {
    const office = this.sessionService.getSelectedOffice(),
      officeId = office?.id?.toString(),
      status = office?.walletStatus?.toString();
    if (status !== WalletStatusTypeEnum.ACTIVE) {
      this.router.navigateByUrl(this.appProperties.walletBaseUrl);
      return;
    }
    if (
      officeId &&
      (status === WalletStatusTypeEnum.ACTIVE ||
        status == WalletStatusTypeEnum.BLOCKED)
    ) {
      let walletAccountDetails;
      if (this.isCargoWallet) {
        walletAccountDetails =
          await this.customerService.getAccountWalletDetails(office.walletId);
      } else {
        walletAccountDetails =
          await this.walletService.getAccountWalletDetails(officeId);
      }

      this.walletDetails = walletAccountDetails;
      this.walletStatus = walletAccountDetails.status;
      this.walletLoader = false;
      this.ref.detectChanges();

      await this.fetchCurrencies(office);
      this.activatedRoute.queryParams.subscribe((params) => {
        const currency = params.currency;
        if (
          currency &&
          this.currencyList?.map((curr) => curr.code)?.includes(currency)
        ) {
          this.topupData.currency = currency;
          this.ref.detectChanges();
        }
      });
    } else {
      this.walletStatus = WalletStatusTypeEnum.INACTIVE;
    }
  }
  async topUpBalance(): Promise<void> {
    const office = this.sessionService.getSelectedOffice(),
      { userEmail, userName } = this.user,
      isSpecialCaseUSSG =
        this.wireTransferCurrencies?.includes(this.topupData.currency) &&
        this.walletDetails?.provider === 'tazapay' &&
        this.topupData.paymentMethod ===
          WalletTopupPaymentTypeEnum.INTERNATIONAL_BANK,
      buyerCountryIBT =
        this.topupData?.currency ===
        office?.country?.defaultCurrency?.currencyCode
          ? this.topupData?.currency === 'SGD'
            ? 'US'
            : 'SG'
          : office.country.countryCode,
      buyerCountry = isSpecialCaseUSSG
        ? buyerCountryIBT
        : this.getCountryCode(),
      checkout_info = {
        customer_details: {
          name: userName,
          email: this.prepareBuyerEmail(userEmail, buyerCountry),
          country: buyerCountry,
        },
      },
      officeId = office?.id?.toString();
    if (officeId) {
      this.walletLoader = true;
      this.ref.detectChanges();
      let addTopupBalance$;
      if (this.isCargoWallet) {
        const body: any = {
          amount: this.topupData.amount?.split(',')?.join(''),
          currency: this.topupData.currency,
          customer_id: this.walletDetails.customer_id,
          reference_id: uuid(),
          funding_account: this.topupData.funding_account,
          region_name: office?.airports?.[0]?.regionName,
        };
        body.checkout_info = checkout_info;
        if (
          this.walletDetails.provider === 'tazapay' ||
          this.topupData.paymentMethod ===
            WalletTopupPaymentTypeEnum.CREDIT_CARD ||
          this.topupData.paymentMethod === WalletTopupPaymentTypeEnum.OTHER
        ) {
          body.payment_methods = this.getPaymentMethods();
          body.payment_type = this.getPaymentTypeForTazaPay();
        }

        addTopupBalance$ = this.customerService.addTopupBalance(
          office.walletId,
          body,
        );
      } else {
        addTopupBalance$ = this.walletService.addTopupBalance(
          officeId,
          this.topupData,
        );
      }
      addTopupBalance$.then(
        async (res) => {
          const txn = res?.find(
            (txn) => !txn?.type?.toLowerCase()?.includes('fee'),
          );
          this.transactionId = txn?.transaction_id;
          this.referenceId = txn?.reference_id;
          this.walletLoader = false;
          if (
            this.topupData.paymentMethod !==
              WalletTopupPaymentTypeEnum.CREDIT_CARD &&
            this.topupData.paymentMethod !== WalletTopupPaymentTypeEnum.OTHER &&
            this.walletDetails.provider === 'currencycloud'
          ) {
            this.paymentSucceed();
          } else if (res[0].token) {
            if (
              this.topupData.paymentMethod === WalletTopupPaymentTypeEnum.OTHER
            ) {
              window.open(
                `${this.router.url}?transactionid=${this.transactionId}&token=${res[0].token}`,
                '_blank',
              );
            } else {
              await this.handleCreditCardPayment(res[0].token);
            }
          }
          this.ref.detectChanges();
        },
        () => {
          this.walletLoader = false;
          Swal.fire(
            '',
            $localize`:@@wallet.topup-amount-error:Something went wrong.`,
            'error',
          );
          this.ref.detectChanges();
        },
      );
    }
  }

  getPaymentMethods(): string[] {
    if (
      this.topupData.paymentMethod === WalletTopupPaymentTypeEnum.CREDIT_CARD
    ) {
      return this.paymentMethodDetails
        ?.map((paymentMethod) => {
          if (paymentMethod?.Family === 'cards') {
            return paymentMethod?.Type;
          }
        })
        ?.filter((name) => name);
    } else if (
      this.topupData.paymentMethod ===
      WalletTopupPaymentTypeEnum.INTERNATIONAL_BANK
    ) {
      if (this.wireTransferCurrencies?.includes(this.topupData.currency)) {
        return ['wire_transfer'];
      }
      return this.paymentMethodDetails
        ?.map((paymentMethod) => {
          if (paymentMethod?.Family === 'wire_transfer') {
            return paymentMethod?.Type;
          }
        })
        ?.filter((name) => name);
    } else if (
      this.topupData.paymentMethod === WalletTopupPaymentTypeEnum.LOCAL_BANK
    ) {
      return this.paymentMethodDetails
        ?.map((paymentMethod) => {
          if (paymentMethod?.Family === 'local_bank_transfer') {
            return paymentMethod?.Type;
          }
        })
        ?.filter((name) => name);
    } else if (
      this.topupData.paymentMethod === WalletTopupPaymentTypeEnum.OTHER
    ) {
      return this.paymentMethodDetails
        ?.map((paymentMethod) => {
          if (paymentMethod?.Group === 'apm') {
            return paymentMethod?.Type;
          }
        })
        ?.filter((name) => name);
    }
  }

  getPaymentTypeForTazaPay(): string {
    if (
      this.topupData.paymentMethod === WalletTopupPaymentTypeEnum.CREDIT_CARD
    ) {
      return TazapayMethodsEnum.CARD;
    } else if (
      this.topupData.paymentMethod ===
      WalletTopupPaymentTypeEnum.INTERNATIONAL_BANK
    ) {
      return TazapayMethodsEnum.INTERNATIONAL;
    } else if (
      this.topupData.paymentMethod === WalletTopupPaymentTypeEnum.LOCAL_BANK
    ) {
      return TazapayMethodsEnum.LOCAL;
    } else {
      return TazapayMethodsEnum.OTHER;
    }
  }
  paymentSucceed(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    this.isProcessCompleted = true;
  }
  prepareBuyerEmail(email: string, countryCode: string): string {
    const splittedEmail = email.split('@');
    return `${splittedEmail[0]}+${countryCode}@${splittedEmail[1]}`;
  }
  get isTopupDisabled(): boolean {
    return (
      !this.topupData.amount ||
      !this.topupData.currency ||
      (!this.isCreditCardEnabled &&
        !this.isInternationEnabled &&
        !this.isLocalEnabled &&
        !this.isOtherEnabled &&
        this.walletDetails?.provider === 'tazapay')
    );
  }
  get isInvalidAmount(): boolean {
    return !(
      this.replacedAmountValue >= this.minAmount &&
      this.replacedAmountValue <= this.maxAmount
    );
  }

  get replacedAmountValue(): number {
    return +this.topupData.amount?.replace(/[^\d.]/g, '');
  }
  get prevURL(): string {
    if (this.isProcessCompleted) {
      return window.location.pathname;
    }
    return this.appProperties.walletBaseUrl;
  }

  get isCargoWallet(): boolean {
    return this.appProperties.name === 'cargowallet';
  }

  async handleCreditCardPayment(token): Promise<void> {
    try {
      const options = {
        clientToken: token,
        callbacks: {
          onPaymentSuccess: () => {
            setTimeout(() => {
              this.modalService?.dismissAll();
              this.paymentSucceed();
              console.log('payment successful');
              this.walletLoader = false;
              this.ref.detectChanges();
            }, 1000);
          },
          onPaymentFail: () => {
            this.walletLoader = false;
            this.ref.detectChanges();

            console.log('payment failed');
          },
          onPaymentMethodSelected: () => {
            console.log('onPaymentMethodSelected');
          },
          onPaymentCancel: () => {
            if (this.router.url.includes('token')) {
              window.close();
            }
          },
        },
        style: {
          primaryButton_margin: '0px 0px 0px 0px',
          primaryButton_padding: '1rem',
          primaryButton_outline: 'none',
          primaryButton_borderRadius: '32px',
          primaryButton_width: '100%',
          primaryButton_height: '56px',
          primaryButton_fontFamily: 'Arial, sans-serif',
          primaryButton_fontSize: '1.38rem',
          primaryButton_fontWeight: '600',
          primaryButton_color: 'white',
          primaryButton_background: '#53A8E7',
          primaryButton_boxSizing: 'border-box',
          'container-iframe_background': 'transparent',
          container_background: 'transparent',
          modal_minHeight: '300px',
          iconSuccess_background: '#32CB8C',
          'container-iframe_boxshadow': 'none',
          'container-iframe_minWidth': '100%',
          'container-iframe_maxWidth': '750px',
          'container-iframe_minHeight': '300px',
          'container-iframe_maxHeight': '70vh',
        },
      };
      this.modalRef = this.modalService.open(PaymentPopupComponent, {
        windowClass: 'payment-popup',
        keyboard: false,
        backdrop: 'static',
        centered: true,
      });
      if (this.getPaymentTypeForTazaPay() === TazapayMethodsEnum.OTHER) {
        this.modalRef.componentInstance.headerText = $localize`:@@wallet.select-payment-method:Select Payment method`;
        this.modalRef.componentInstance.onSuccess =
          options.callbacks.onPaymentSuccess;
        this.modalRef.componentInstance.onCancel =
          options.callbacks.onPaymentCancel;
      } else if (this.getPaymentTypeForTazaPay() !== TazapayMethodsEnum.CARD) {
        this.modalRef.componentInstance.headerText = $localize`:@@wallet.check-bank-details:Check the bank details`;
        this.modalRef.componentInstance.showTopupButton = true;
        this.modalRef.componentInstance.onSuccess =
          options.callbacks.onPaymentSuccess;
      }
      if (!window?.tazapay) {
        const appUrl = window.location.href,
          tzScript = document.createElement('script');
        tzScript.onload = function () {
          window.tazapay.checkout(options);
        };
        tzScript.onerror = function () {
          console.error('Error loading Tazapay script');
        };
        tzScript.src =
          appUrl.includes('app.wallet.cargoai.co') ||
          appUrl.includes('app.cargo-wallet.com')
            ? 'https://js.tazapay.com/v2.0.js'
            : 'https://js-sandbox.tazapay.com/v2.0-sandbox.js';
        tzScript.type = 'text/javascript';
        document.head.appendChild(tzScript);
      } else {
        window.tazapay.checkout(options);
      }
    } catch (e) {
      console.error('error creating checkout', e);
    }
  }
}
