import { Component, Input } from "@angular/core";

@Component({
  "selector": "kt-loading-component",
  "templateUrl": "./loading-component.component.html",
  "styleUrls": ["./loading-component.component.scss"],
})
export class LoadingComponent {
  @Input() isLoading: boolean;
}
