import { Component, Input, Inject } from "@angular/core";
import { ApplicationProperties } from "../../../../../core/_base/layout/models/app-properties.model";
import { APP_PROPS } from "../../../../../cai-common.module";

@Component({
  "selector": "kt-sliders",
  "templateUrl": "./sliders.component.html",
  "styleUrls": ["./sliders.component.scss"],
})
export class SlidersComponent {
  constructor (
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties
  ) {}
  @Input() currentSlider: number;

  get isWalletApp (): boolean {
    return this.appProperties.name === "cargowallet";
  }
}
