<label
  class="advanced-qty-input"
  [class.disabled]="isDisabled"
  [class.readonly]="readonly"
  [class.viewonly]="viewOnly"
  [class.invalid]="showError"
  [class.focused]="isFocused"
  [attr.for]="undefined"
>
  <input
    #input
    caiTooltip
    [tooltip]="input.value"
    [detectOverflow]="true"
    [placeholder]="label"
    [class.readonly]="readonly || viewOnly"
    [attr.readonly]="readonly || viewOnly ? true : null"
    [class.float-label]="floatLabel"
    [class.label-center]="labelCenter"
    [ngModel]="
      readonly ? (value | caiNumberFormatter : true : decimalPlace) : value
    "
    (ngModelChange)="value = $event"
    (focusin)="focused = true"
    (focusout)="focused = false"
    (keypress)="onKeyPress($event)"
    (keydown)="onKeyDown($event)"
    (keyup)="onKeyUp($event)"
    (paste)="onPaste($event)"
    (blur)="onBlur()"
    autocomplete="off"
  />
  <div class="label">
    <span>
      {{ label }}
    </span>
    <span class="unit">
      <span *ngIf="unit" class="parenthesis">(</span>
      <span *ngIf="unit && !showUnitSelector">{{ unit }}</span>
      <span *ngIf="showUnitSelector">&nbsp;</span>
      <cai-load-type-unit-selector
        *ngIf="showUnitSelector"
        (click)="$event.preventDefault()"
        [(selected)]="selectedUnit"
        (selectedChange)="selectedUnitChange.emit($event)"
        [(measurementSystem)]="measurementSystem"
        (measurementSystemChange)="measurementSystemChange.emit($event)"
        [isAdvancedBookings]="true"
        [isParentMetric]="
          unit
            ? matchMeasurementSystem(measurementSystemEnum.METRIC)
            : undefined
        "
        [isParentImperial]="
          unit
            ? matchMeasurementSystem(measurementSystemEnum.IMPERIAL)
            : undefined
        "
        [options]="measurementUnits"
      ></cai-load-type-unit-selector>
      <span *ngIf="showUnitSelector">&nbsp;</span>
      <span *ngIf="unit" class="parenthesis">)</span></span
    >
  </div>
</label>
