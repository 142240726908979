import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import Popper, { Modifiers, Placement, PopperOptions } from 'popper.js';
import { fromEvent, merge, Subject, Subscription } from 'rxjs';
import { filter, pluck, takeUntil, takeWhile } from 'rxjs/operators';

@Directive({
  selector: '[caiPopover]',
})
export class CaiPopoverDirective implements OnInit, OnDestroy, OnChanges {
  @Input() reference: any;
  @Input() targetId: string;
  @Input() target: TemplateRef<any>;
  @Input() zIndex = 100;
  @Input() width: string;
  @Input() enablePopover = true;
  @Input() allowHoverOnTarget: boolean;
  @Input() placement: Placement = 'bottom-end';
  @Input() modifiers: Modifiers;
  @Input() openOnClick = false;
  @Output() isOpen: EventEmitter<boolean> = new EventEmitter();

  readonly destroy$ = new Subject<void>();
  readonly defaultConfig: PopperOptions = {
    placement: this.placement,
    removeOnDestroy: true,
    modifiers: {
      preventOverflow: { escapeWithReference: false },
    },
  };

  view: EmbeddedViewRef<any>;
  popperRef: Popper;
  subscription: Subscription;
  documentClickSubscription: Subscription;

  constructor(
    readonly cdr: ChangeDetectorRef,
    private readonly el: ElementRef,
    private readonly vcr: ViewContainerRef,
    private readonly zone: NgZone,
  ) {}

  ngOnInit(): void {
    this.subscribeToEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.placement) {
      this.defaultConfig.placement = this.placement;
    }
    if (changes.modifiers) {
      this.defaultConfig.modifiers = {
        ...this.defaultConfig.modifiers,
        ...this.modifiers,
      };
    }
    if (changes.openOnClick) {
      this.subscribeToEvents();
    }
  }

  ngOnDestroy(): void {
    this.cleanup();
    this.destroy$.next();
    this.destroy$.complete();
  }

  subscribeToEvents(target?: any) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    const reference = this.el.nativeElement,
      events = this.openOnClick
        ? [fromEvent(reference, 'click')]
        : [
            fromEvent(reference, 'mouseenter'),
            fromEvent(reference, 'mouseleave'),
          ];

    if (target) {
      if (this.openOnClick) {
        events.push(fromEvent(target, 'click'));
      } else {
        events.push(
          fromEvent(target, 'mouseenter'),
          fromEvent(target, 'mouseleave'),
        );
      }
    }

    this.subscription = merge(...events)
      .pipe(
        filter(() => reference && this.enablePopover),
        pluck('type'),
        takeUntil(this.destroy$),
      )
      .subscribe((e: any) =>
        this.openOnClick
          ? this.mouseClickHandler(e)
          : this.mouseHoverHandler(e),
      );

    this.handleScroll();
  }

  handleScroll() {
    fromEvent(document, 'wheel')
      .pipe(
        takeWhile(() => !!this.popperRef),
        filter(() => !!this.targetId),
        filter(({ target }) => {
          const origin = document.getElementById(this.targetId);
          return origin && origin.contains(target as HTMLElement) === false;
        }),
      )
      .subscribe(() => {
        this.close();
        this.cdr.detectChanges();
      });
  }

  mouseHoverHandler(e: string): void {
    if (!this.defaultConfig.placement) {
      return;
    }
    if (e === 'mouseenter') {
      this.openPopover();
    } else {
      this.close();
    }
    this.cdr.detectChanges();
  }

  mouseClickHandler(e: string): void {
    if (!this.defaultConfig.placement) {
      return;
    }
    if (e === 'click') {
      if (!this.popperRef && this.target) {
        this.openPopover();
        this.documentClickSubscription = fromEvent(document, 'click')
          .pipe(
            filter((event) => {
              const clickTarget = event.target as HTMLElement;
              return (
                !this.el.nativeElement.contains(clickTarget) &&
                !this.view.rootNodes[0].contains(clickTarget)
              );
            }),
            takeUntil(this.destroy$),
          )
          .subscribe(() => this.close());
      } else {
        this.close();
      }
    }
    this.cdr.detectChanges();
  }

  openPopover() {
    if (!this.popperRef && this.target) {
      const reference = this.reference ? this.reference : this.el.nativeElement;

      this.view = this.vcr.createEmbeddedView(this.target);
      const popup = this.view.rootNodes[0];
      document.body.appendChild(popup);
      if (popup?.style) {
        popup.style['z-index'] = this.zIndex.toString();
      }
      if (this.width) {
        popup.style.width = `${this.width}px`;
      }
      this.zone.runOutsideAngular(() => {
        this.popperRef = new Popper(reference, popup, this.defaultConfig);
      });

      if (this.allowHoverOnTarget) {
        this.subscription.unsubscribe();
        this.subscribeToEvents(popup);
      }
    }
    this.isOpen.emit(true);
  }

  close() {
    if (this.view) {
      this.view.destroy();
      this.view = null;
    }
    if (this.popperRef) {
      this.popperRef?.destroy();

      this.popperRef = null;
    }
    if (this.documentClickSubscription) {
      this.documentClickSubscription.unsubscribe();
    }
    this.isOpen.emit(false);
  }

  cleanup() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.documentClickSubscription) {
      this.documentClickSubscription.unsubscribe();
    }
    if (this.popperRef) {
      this.popperRef?.destroy();
    }
    this.view = null;
    this.popperRef = null;
  }
}
