import { Injectable } from "@angular/core";
import { QuoteRequestService, QuoteService } from "@cai-services";
import { from, Observable } from "rxjs";
import { QuoteRequest } from "../../_models/quote-request.model";
import { Quote } from "../../_models/quote.model";

@Injectable({
  "providedIn": "root",
})
export class QuoteListService {
  constructor (
    private readonly quoteService: QuoteService,
    private readonly quoteRequestService: QuoteRequestService
  ) {}

  retrieveAllQuotesByUser (): Observable<any> {
    return from(this.quoteService.retrieveQuotesLight());
  }

  retrieveQuote (quoteId: number, token: string): Observable<any> {
    return from(this.quoteService.retrieveQuoteById(quoteId, token));
  }

  updateQuoteStatus (quote: Quote, token?: string): Observable<any> {
    return from(this.quoteService.updateQuote(quote, token));
  }

  archiveQuoteRequest (quoteRequest: QuoteRequest): Observable<any> {
    return from(
      this.quoteRequestService.partialUpdateQuoteRequest({
        "quoteRequestId": quoteRequest.quoteRequestId,
        "quoteRequestStatus": quoteRequest.quoteRequestStatus,
        "quotes": quoteRequest.quotes,
      } as QuoteRequest)
    );
  }
}
