<div
  class="icon-wrapper"
  caiPopover
  [target]="tooltip"
  [allowHoverOnTarget]="true"
  placement="bottom-end"
>
  <span class="email-icon" [class.hover]="isHovered"></span>
</div>
<ng-template #tooltip>
  <div class="tooltip-text">
    {{ tooltipText }}
  </div>
</ng-template>
