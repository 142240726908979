import { getCurrencySymbol } from "@angular/common";
import {
  TransactionTypes,
  WalletFeesTypes,
  WalletTransaction,
} from "@cai-services";
import { ApplicationProperties } from "../core/_base/layout/models/app-properties.model";
import { SessionService } from "../core";

const customCountryMapping = [
  { "currency": "EUR", "country": "DE" },
  { "currency": "XAF", "country": "GQ" },
  { "currency": "XOF", "country": "NE" },
  { "currency": "XPF", "country": "PF" },
];

export class WalletUtil {
  private static sessionService: SessionService;

  constructor (private readonly sessionService: SessionService) {
    WalletUtil.sessionService = sessionService;
  }

  static transactionType = TransactionTypes;
  static getTransactionType (item: WalletTransaction): string {
    if (this.getMetadata(item, "fee_type")) {
      if (item.transaction_type === "PREPAID_RECONCILIATION") {
        return this.transactionType.PREPAID_RECONCILIATION;
      }
      if (item.transaction_type === "PREPAID_RECONCILIATION_FEE") {
        return this.transactionType.PREPAID_RECONCILIATION_FEE;
      }

      if (
        item.transaction_type === WalletFeesTypes.PrepaidPayTo ||
        item.transaction_type === WalletFeesTypes.LaterPayTo ||
        item.transaction_type === WalletFeesTypes.PrepaidPayToCargoFee ||
        item.transaction_type === WalletFeesTypes.LaterPaytoCargoFee
      )
        {return this.transactionType.PAYMENT_FEE;}

      if (item.transaction_type === "TOP_UP_FEE") {
        return this.transactionType.TOP_UP_FEE;
      }

      if (this.getMetadata(item, "fee_type") === WalletFeesTypes.TopUpFee) {
        if (item.transaction_type === WalletFeesTypes.TazapayTransactionFee) {
          if (item.payment_information.type === "TAZAPAY_CARDS") {
            return this.transactionType.CREDIT_CARD_FEE;
          }
          if (item.payment_information.type === "TAZAPAY_APM") {
            return this.transactionType.APM_FEE;
          }
        }
        return this.transactionType.TOP_UP_FEE;
      }
      if (this.getMetadata(item, "fee_type") === WalletFeesTypes.ExportFee)
        {return this.transactionType.LATER_CARGOAI_FEE_Export;}
      if (
        item.metadata_user.type != "NONE" &&
        item.destination_pay_to &&
        this.getMetadata(item, "fee_type") === WalletFeesTypes.BookingFee
      )
        {return this.transactionType.PAYMENT_FEE;}
      if (
        this.getMetadata(item, "fee_type") === WalletFeesTypes.BookingFee &&
        item.transaction_type !== WalletFeesTypes.PayLaterFee
      )
        {return this.transactionType.PREPAID_CARGOAI_FEE;}
      if (item.transaction_type === WalletFeesTypes.TazapayTransactionFee) {
        if (item.payment_information.type === "TAZAPAY_CARDS") {
          return this.transactionType.CREDIT_CARD_FEE;
        }
        if (item.payment_information.type === "TAZAPAY_APM") {
          return this.transactionType.APM_FEE;
        }
      }
      if (item.transaction_type === "GET_PAID") {
        return this.transactionType.PAYMENT_FEE;
      } else {
        return this.getMetadata(item, "fee_type") ===
          WalletFeesTypes.ConversionFee
          ? this.transactionType.CONVERSION_FEE
          : this.transactionType[
              "LATER_CARGOAI_FEE_Booking_" + this.getMetadata(item, "pay_term")
            ];
      }
    }
    if (
      item.metadata_user?.type != "NONE" &&
      (item?.destination_pay_to || item?.email_pay_to)
    ) {
      if (item.operation == "DEBIT") {
        if (item.payment_information.type === "TAZAPAY_CARDS") {
          return `${this.transactionType.PAYMENT_ISSUE} - Credit Card`;
        }
        if (item.payment_information.type === "TAZAPAY_APM") {
          return `${this.transactionType.PAYMENT_ISSUE} - Local Payment Method`;
        }
        if (item.transaction_status === "OVERDUE") {
          return this.transactionType.OVERDUE;
        }
        return this.transactionType.PAYMENT_ISSUE;
      }
      if (item.transaction_type == "GET_PAID" && item.destination_pay_from) {
        return this.transactionType.GET_PAID;
      }
    }

    if (
      item.metadata_user.type != "NONE" &&
      item.destination_pay_from &&
      item.operation == "CREDIT" &&
      item.transaction_type.includes("TAZAPAY")
    ) {
      return this.transactionType.PAYMENT_RECEIVED;
    }

    return this.transactionType[
      item.transaction_type +
        (this.getMetadata(item, "pay_term") &&
        item.transaction_type == "LATER_BOOKING"
          ? "_" + this.getMetadata(item, "pay_term")
          : "")
    ];
  }

  static getCustomCurrencyCode (
    currencyCode: string,
    isSymbol?: boolean,
  ): string {
    const customCurrencyList = [
      {
        "currencyCode": "AUD",
        "symbol": "A$",
      },
      {
        "currencyCode": "BSD",
        "symbol": "B$",
      },
      {
        "currencyCode": "BBD",
        "symbol": "Bds$",
      },
      {
        "currencyCode": "BZD",
        "symbol": "BZ$",
      },
      {
        "currencyCode": "BMD",
        "symbol": "Ber$",
      },
      {
        "currencyCode": "BND",
        "symbol": "B$",
      },
      {
        "currencyCode": "CAD",
        "symbol": "C$",
      },
      {
        "currencyCode": "KY",
        "symbol": "CI$",
      },
      {
        "currencyCode": "XCD",
        "symbol": "EC$",
      },
      {
        "currencyCode": "FJD",
        "symbol": "FJ$",
      },
      {
        "currencyCode": "GYD",
        "symbol": "G$",
      },
      {
        "currencyCode": "HKD",
        "symbol": "HK$",
      },
      {
        "currencyCode": "JMD",
        "symbol": "J$",
      },
      {
        "currencyCode": "KI",
        "symbol": "$",
      },
      {
        "currencyCode": "LRD",
        "symbol": "L$",
      },
      {
        "currencyCode": "NAD",
        "symbol": "N$",
      },
      {
        "currencyCode": "NZD",
        "symbol": "$NZ",
      },
      {
        "currencyCode": "SGD",
        "symbol": "S$",
      },
      {
        "currencyCode": "SBD",
        "symbol": "SI$",
      },
      {
        "currencyCode": "SRD",
        "symbol": "SRD",
      },
      {
        "currencyCode": "TWD",
        "symbol": "NT$",
      },
      {
        "currencyCode": "TTD",
        "symbol": "TT$",
      },
      {
        "currencyCode": "TVD",
        "symbol": "TV$",
      },
      {
        "currencyCode": "USD",
        "symbol": "$",
      },
    ],
     currency = customCurrencyList.find(
      (x) => x.currencyCode === currencyCode,
    )?.symbol;

    if (
      getCurrencySymbol(currencyCode, "narrow")?.toLowerCase() ===
        currencyCode?.toLocaleLowerCase() &&
      isSymbol
    ) {
      return "";
    }
    return currency ? currency : getCurrencySymbol(currencyCode, "narrow");
  }

  static getMetadata (item: WalletTransaction, parameter: string): any {
    if (item.metadata_user.type != "NONE") {
      return item.metadata_user.data[parameter] || "";
    } else if (item.user_data) {
      return item.user_data[parameter] || "";
    }
    return null;
  }

  static isEmbedMode (
    appProperties: ApplicationProperties,
    queryParams: any,
  ): boolean {
    return (
      appProperties.name === "cargowallet" &&
      WalletUtil.validateEmbedData(queryParams)
    );
  }

  static validateEmbedData (queryParams: any): boolean {
    return !!queryParams.prefillId || !!this.validateEmbedParams(queryParams);
  }

  static validateEmbedParams (queryParams: any): boolean {
    return (
      !!queryParams.beneficiary &&
      (!!queryParams.category ||
        !!queryParams.reference ||
        !!queryParams.currency ||
        !!queryParams.amount)
    );
  }

  static getWalletManagementUrl (currentUrl: string): string {
    const url = new URL(currentUrl);
    let subdomain = url.hostname?.split(".")?.[1];

    if (subdomain === "dev" || url?.hostname?.includes("localhost")) {
      subdomain = "app.dev";
    } else if (subdomain === "testing" || subdomain === "staging") {
      subdomain = "app.staging";
    } else {
      subdomain = "app";
    }

    return `https://${subdomain}.wallet.cargoai.co/wallet-management`;
  }

  static getCreditCardFee (country: string): string {
    if (country == "SG" || country == "US") {
      return "2.60";
    } else if (country == "GB" || country == "DE") {
      return "2.10";
    } else {
      return "3.10";
    }
  }

  static getCountryCode (currency: string, isCreditCard?: boolean): string {
    if (isCreditCard) {
      return customCountryMapping.some((x) => x.currency == currency)
        ? customCountryMapping.find((x) => x.currency == currency).country
        : currency?.slice(0, 2);
    }

    const selectedOffice = this.sessionService.getSelectedOffice();
    return selectedOffice?.country?.countryCode;
  }
}
