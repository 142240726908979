import { Injectable } from '@angular/core';
import { API } from '@aws-amplify/api';
import { API_CORE } from '../../constant/api-core.const';
import {
  AccountWalletDetails,
  DefaultWalletBalance,
  GetEventResponse,
  IBeneficiary,
  IDocumentExport,
  IOffice,
  WalletBankAccountDetails,
  WalletConvertedPayLaterBalance,
  WalletCurrency,
  WalletGlobalBalance,
  WalletPayLaterBalance,
  WalletTopUp,
  WalletTransaction,
} from '../../model';
import { ErrorUtil } from '../../utils/error.util';
import { CompanySubTypeEnum, CompanyTypeEnum } from '@cai-services';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  async postWalletAccount(
    officeId: string,
    officeName: string,
    currency: string,
  ): Promise<AccountWalletDetails> {
    const requestURL = API_CORE.URLs.Wallet.PostWallet.replace(
        '{officeId}',
        officeId,
      ),
      body = {
        customer_name: officeName,
        provider_user: 'tazapay',
        provider_account: 'tazapay',
        provider: 'tazapay',
        currency,
      };
    return API.post(API_CORE.name, requestURL, { body }).catch(
      ErrorUtil.handleError,
    );
  }

  async getDefaultWalletBalance(
    officeId: string,
  ): Promise<DefaultWalletBalance> {
    const requestURL = API_CORE.URLs.Wallet.DefaultBalance.replace(
      '{officeId}',
      officeId,
    );
    return API.get(API_CORE.name, requestURL, {}).catch(ErrorUtil.handleError);
  }

  async getAccountWalletDetails(
    officeId: string,
  ): Promise<AccountWalletDetails> {
    const requestURL = API_CORE.URLs.Wallet.AccountWalletDetails.replace(
      '{officeId}',
      officeId,
    );
    return API.get(API_CORE.name, requestURL, {}).catch(ErrorUtil.handleError);
  }

  async updateOffice(officeId: number, isPublic: boolean): Promise<IOffice> {
    const url = API_CORE.URLs.Wallet.WalletPrivacy.replace(
      '{officeId}',
      officeId.toString(),
    ).replace('{bool}', isPublic.toString());
    try {
      return await API.patch(API_CORE.name, url, {});
    } catch (error) {
      ErrorUtil.handleError(error);
      throw error;
    }
  }
  async updateWalletStatus(
    officeId: string,
    body: any,
  ): Promise<AccountWalletDetails> {
    const requestURL = API_CORE.URLs.Wallet.UpdateWalletStatus.replace(
      '{officeId}',
      officeId,
    );
    return API.patch(API_CORE.name, requestURL, { body }).catch(
      ErrorUtil.handleError,
    );
  }

  async getTransactionsList(
    officeId: string,
    referenceId?: string,
  ): Promise<GetEventResponse> {
    const requestURL = API_CORE.URLs.Wallet.TransactionsList.replace(
      '{officeId}',
      officeId,
    );
    let extraOptions = {};
    if (referenceId) {
      extraOptions = {
        queryStringParameters: { referenceId },
      };
    }
    return API.get(API_CORE.name, requestURL, extraOptions).catch(
      ErrorUtil.handleError,
    );
  }

  async getSwifBankDetails(
    officeId: string,
    currency: string,
  ): Promise<Array<WalletBankAccountDetails>> {
    const requestURL = API_CORE.URLs.Wallet.FundingAccounts.replace(
      '{officeId}',
      officeId,
    ).replace('{currency}', currency);
    return API.get(API_CORE.name, requestURL, {}).catch(ErrorUtil.handleError);
  }

  async addTopupBalance(
    officeId: string,
    body: WalletTopUp,
  ): Promise<WalletTransaction> {
    const requestURL = API_CORE.URLs.Wallet.TopUp.replace(
      '{officeId}',
      officeId,
    );
    return API.post(API_CORE.name, requestURL, { body }).catch(
      ErrorUtil.handleError,
    );
  }

  async getWalletBalancesByCurrencies(
    officeId: string,
    currency: string,
  ): Promise<WalletTopUp> {
    const requestURL = API_CORE.URLs.Wallet.GetBalances.replace(
      '{officeId}',
      officeId,
    ).replace('{currency}', currency);
    return API.get(API_CORE.name, requestURL, {}).catch(ErrorUtil.handleError);
  }

  async getWalletPayLaterBalances(
    officeId: string,
  ): Promise<WalletPayLaterBalance> {
    const requestURL = API_CORE.URLs.Wallet.GetPaylaterBalances.replace(
      '{officeId}',
      officeId,
    );
    return API.get(API_CORE.name, requestURL, {}).catch(ErrorUtil.handleError);
  }

  async getWalletConverterdPayLaterBalances(
    from: string,
    to: string,
    amount: string,
    sale = false,
  ): Promise<WalletConvertedPayLaterBalance> {
    const requestURL = API_CORE.URLs.Wallet.GetConvertPaylaterBalances,
      queryStringParameters = {
        from,
        to,
        amount,
        sale,
      };
    return API.get(API_CORE.name, requestURL, {
      queryStringParameters,
    }).catch(ErrorUtil.handleError);
  }

  async getWalletCurrencyList(
    officeId: string,
  ): Promise<Array<WalletCurrency>> {
    const requestURL = API_CORE.URLs.Wallet.GetCurrencyList.replace(
      '{officeId}',
      officeId,
    );
    return API.get(API_CORE.name, requestURL, {}).catch(ErrorUtil.handleError);
  }

  async downloadInvoiceOrRecipt(
    transactionId: string,
    documentType: string,
  ): Promise<IDocumentExport> {
    const requestURL = API_CORE.URLs.Wallet.DownloadInvoiceOrReceipt.replace(
      '{transactionId}',
      transactionId,
    ).replace('{documentType}', documentType);
    return API.get(API_CORE.name, requestURL, {}).catch(ErrorUtil.handleError);
  }

  async patchDefaultBalance(officeId: string, currency: string): Promise<null> {
    const requestURL = API_CORE.URLs.Wallet.DefaultBalance.replace(
        '{officeId}',
        officeId,
      ),
      body = { default_currency: currency };
    return API.patch(API_CORE.name, requestURL, { body }).catch(
      ErrorUtil.handleError,
    );
  }

  async getGlobalPrepaidBalance(
    officeId: string,
    currency: string,
  ): Promise<WalletGlobalBalance> {
    const requestURL = API_CORE.URLs.Wallet.GlobalBalance.replace(
        '{officeId}',
        officeId,
      ),
      queryStringParameters = {
        currency,
      };
    return API.get(API_CORE.name, requestURL, {
      queryStringParameters,
    }).catch(ErrorUtil.handleError);
  }

  async getBeneficiaries(
    officeId: string,
    searchInput?: string,
  ): Promise<IBeneficiary[]> {
    const requestURL = API_CORE.URLs.Wallet.Beneficiaries.replace(
        '{officeId}',
        officeId,
      ),
      queryStringParameters = {} as any;
    if (!!searchInput) {
      queryStringParameters.input = searchInput;
    }

    return API.get(API_CORE.name, requestURL, {
      queryStringParameters,
    }).catch(ErrorUtil.handleError);
  }

  async getGlobalBeneficiaries(
    searchInput?: string,
    companyType?: CompanyTypeEnum[],
    subType?: CompanySubTypeEnum[],
    countryFilter?: string,
    beneficiary?: string,
  ): Promise<IBeneficiary[]> {
    const queryStringParameters = {} as any;
    if (!!searchInput) {
      queryStringParameters.input = searchInput;
    }
    if (!!companyType) {
      queryStringParameters.type = companyType.join(',');
    }
    if (!!subType) {
      queryStringParameters.subType = subType.join(',');
    }
    if (!!countryFilter) {
      queryStringParameters.country = countryFilter;
    }
    if (!!beneficiary) {
      queryStringParameters.beneficiary = beneficiary;
    }

    const requestURL = API_CORE.URLs.Wallet.GlobalBeneficiaries;
    return API.get(API_CORE.name, requestURL, { queryStringParameters }).catch(
      ErrorUtil.handleError,
    );
  }

  async getBeneficiaryContact(
    officeId: string,
    beneficiary: string,
  ): Promise<IBeneficiary> {
    const requestURL = API_CORE.URLs.Wallet.Beneficiaries.replace(
        '{officeId}',
        officeId,
      ),
      queryStringParameters = {
        beneficiary,
      };
    return API.get(API_CORE.name, requestURL, {
      queryStringParameters,
    }).catch(ErrorUtil.handleError);
  }
}
