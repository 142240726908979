import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IRequirementAnswer, RequirementCodeEnum } from "@cai-services";
import {
  CargoAircraftOnlyAnswer,
  CargoAircraftOnlyConfig,
} from "../../../constant/requirements/cargo-aircraft-only.const";

@Component({
  "selector": "kt-requirement-cargo-aircraft-only",
  "templateUrl": "./requirement-cargo-aircraft-only.component.html",
  "styleUrls": ["./requirement-cargo-aircraft-only.component.scss"],
})
export class RequirementCargoAircraftOnlyComponent implements OnChanges {
  @Input() formGroup: FormGroup = new FormGroup({});
  @Input() defaultAnswer = {} as IRequirementAnswer;
  @Output() onFocus = new EventEmitter();

  code: string = RequirementCodeEnum.CARGO_AIRCRAFT_ONLY;

  selectedAnswer: any = {};
  question: string = CargoAircraftOnlyConfig.question;
  options: any[] = CargoAircraftOnlyConfig.options;
  answers = CargoAircraftOnlyAnswer;

  constructor (private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.hasOwnProperty("formGroup")) {
      const field = this.formGroup.get(this.code);
      if (field) {
        this.updateAnswer(field.value);
        field.valueChanges.subscribe((value) => {
          this.updateAnswer(value);
        });
      }
    }
  }

  selectAnswer (option) {
    if (option && this.selectedAnswer !== option) {
      this.selectedAnswer = option;
      this.formGroup.get(this.code).setValue(option.value);
      this.onFocus.emit();
      this.cdr.detectChanges();
    }
  }

  private updateAnswer (value) {
    const selected = this.options.find((opt) => opt.value === value);
    if (selected) {
      this.selectedAnswer = selected;
      this.cdr.detectChanges();
    } else if (this.defaultAnswer) {
      const defaultAnswer = this.options.find(
        (opt) => opt.value === this.defaultAnswer.code
      );
      this.selectAnswer(defaultAnswer);
    }
  }
}
