export const getSearchUUID = `query GetSearchUUID(
  $clientEnv: String!
  $clientID: String!
  $originCriteria: String!
  $destinationCriteria: String!
  $requestDate: String!
  $offset: Int!
  $product: String!,
  $weight: Float!,
  $pieces: Int,
  $chargeableWeight: Float!
  $volume: Float!,
  $dimensions: [DimensionInput]!,
  $specialHandlingCodes: [String]!,
  $dangerousGoods: [String],
  $dangerousGoodsInfo: [DangerousGoodsInfoInput],
  $scrs: [String],
  $europeanHS: [String],
  $globalHS: [String],
  $providers: [String],
  $forwarder: Int,
  $officeId: String!,
  $airline: String,
  $measurementUnit: String!,
  $commodities: [CommodityLightInput],
  $awb: String,
  $allotmentCode: String,
  $isAllotment: Boolean,
  $isAdvancedBooking: Boolean,
  $isMultiCallBlocked: Boolean,
  $isInterlineEnabled: Boolean,
  $filters: FilterInput
) {
  getSearchUUID(
    clientEnv: $clientEnv,
    clientID: $clientID,
    originCriteria: $originCriteria,
    destinationCriteria: $destinationCriteria,
    requestDate: $requestDate,
    offset: $offset,
    product: $product,
    weight: $weight,
    pieces: $pieces,
    chargeableWeight: $chargeableWeight,
    volume: $volume,
    dimensions: $dimensions,
    specialHandlingCodes: $specialHandlingCodes,
    dangerousGoods: $dangerousGoods,
    dangerousGoodsInfo: $dangerousGoodsInfo,
    scrs: $scrs,
    europeanHS: $europeanHS,
    globalHS: $globalHS,
    providers: $providers,
    forwarder: $forwarder,
    officeId: $officeId,
    airline: $airline,
    measurementUnit: $measurementUnit,
    commodities: $commodities,
    awb: $awb,
    allotmentCode: $allotmentCode
    isAllotment: $isAllotment,
    isAdvancedBooking: $isAdvancedBooking
    isMultiCallBlocked: $isMultiCallBlocked
    isInterlineEnabled: $isInterlineEnabled
    filters: $filters
  )
}
`;
