import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import { ObjectReturnModeEnum } from "../../enum/object-return-mode.enum";
import { IAirportLight } from "../../model/airport-light.model";
import { IAirport } from "../../model/airport.model";
import { ErrorUtil } from "../../utils/error.util";
import { API_CORE } from "../../constant/api-core.const";

@Injectable({
  "providedIn": "root",
})
export class AirportService {
  async getAirports (): Promise<IAirport[]> {
    return API.get(API_CORE.name, API_CORE.URLs.Airport.Airports, {}).catch(
      ErrorUtil.handleError
    );
  }

  async getAirportsLight (): Promise<IAirportLight[]> {
    const extraOptions: any = {
      "queryStringParameters": {
        "mode": ObjectReturnModeEnum.LIGHT,
      },
    };
    return API.get(
      API_CORE.name,
      API_CORE.URLs.Airport.Airports,
      extraOptions
    ).catch(ErrorUtil.handleError);
  }
}
