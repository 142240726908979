import { DecimalPipe } from "@angular/common";
import { ChangeDetectorRef, Component, Input } from "@angular/core";
import {
  AccountWalletDetails,
  WalletBankAccountDetails,
  WalletTopUp,
  WalletTopupPaymentTypeEnum,
} from "@cai-services";
import Swal from "sweetalert2";

@Component({
  "selector": "kt-wallet-topup-amount",
  "templateUrl": "./wallet-topup-amount.component.html",
  "styleUrls": ["./wallet-topup-amount.component.scss"],
  "providers": [DecimalPipe],
})
export class WalletTopupAmountComponent {
  @Input() topupData: WalletTopUp = new WalletTopUp();
  @Input() minAmount: number;
  @Input() maxAmount: number;
  @Input() paymentFees: string;
  @Input() walletTopUp: WalletTopUp = new WalletTopUp();
  @Input() walletDetails: AccountWalletDetails =
    new AccountWalletDetails();
  @Input() bankDetails: Array<WalletBankAccountDetails>;
  @Input() isCreditCardEnabled: boolean;
  @Input() isLocalEnabled: boolean;
  @Input() isInternationEnabled: boolean;
  @Input() isOtherEnabled: boolean;
  constructor (
    private decimalPipe: DecimalPipe,
    private _ref: ChangeDetectorRef,
  ) {}
  get isInvalidAmount (): boolean {
    return (
      this.topupData.amount != "" &&
      this.topupData?.amount != undefined &&
      !(
        this.replacedValue >= this.minAmount &&
        this.replacedValue <= this.maxAmount
      )
    );
  }

  formatInput () {
    const amt = this.topupData.amount,
     digits = amt?.replace(/[^\d]/g, "");
    if (digits && +digits > 0) {
      this.topupData.amount = this.formatCurrency(digits);
      this._ref.detectChanges();
    }
  }

  formatCurrency (value: string): string {
    const parts = value.split(".");
    let integerPart = parts[0];
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const formattedValue = integerPart;
    return formattedValue;
  }

  get replacedValue (): number {
    return +this.topupData.amount.replace(/[^\d.]/g, "");
  }
  getFlagName (value): string {
    return value == "CNH" ? "CNY" : value;
  }
  onKeyPressAvoidDot (evt, isChanged?): boolean {
    if (isChanged && this.topupData.amount.toString().includes(".")) {
      this.topupData.amount = "";
      evt.preventDefault();
      Swal.fire("", "Please enter integer value only", "error");
      return;
    }
    setTimeout(() => {
      this.formatInput();
    });
  }
  get getCalcPaymentFee () {
    return this.paymentFees
      ? this.decimalPipe.transform(
          (
            (Number(this.replacedValue ?? 0) * Number(this.paymentFees)) /
            100
          ).toFixed(2),
          "1.2-2",
        )
      : "";
  }

  get isTazapayBankPayment (): boolean {
    return (
      (this.topupData?.paymentMethod ===
        WalletTopupPaymentTypeEnum.LOCAL_BANK ||
        this.topupData?.paymentMethod ===
          WalletTopupPaymentTypeEnum.INTERNATIONAL_BANK) &&
      this.walletDetails?.provider === "tazapay"
    );
  }

  get taxFieldMinWidth () {
    return this.paymentFees ? this.paymentFees.length * 7.5 + "px" : "";
  }
}
