import { CdkScrollableModule, ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NgxEchartsModule } from "ngx-echarts";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { CaiFrameworkModule } from "@cai-framework";
import { CaiServicesModule } from "@cai-services";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgOtpInputModule } from "ng-otp-input";
import { CountdownModule } from "ngx-countdown";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatPaginatorModule } from "@angular/material/paginator";
import { NgxPaginationModule } from "ngx-pagination";
import { MatSortModule } from "@angular/material/sort";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatRadioModule } from "@angular/material/radio";
import { MatDividerModule } from "@angular/material/divider";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { CoreModule } from "../core/core.module";
import { CommonDirectivesModule } from "../directives/directives.module";
import { CommonPipesModule } from "../pipes";
import { ActionNotificationComponent } from "./action-notification/action-notification.component";
import { AdvancedAutocompleteComponent } from "./advanced-autocomplete/advanced-autocomplete.component";
import { AdvancedAwbInputComponent } from "./advanced-awb-input/advanced-awb-input.component";
import { AdvancedDatepickerComponent } from "./advanced-datepicker/advanced-datepicker.component";
import { AdvancedErrorsComponent } from "./advanced-errors/advanced-errors.component";
import { AdvancedInputBaseComponent } from "./advanced-input/advanced-input-base.component";
import { AdvancedInputComponent } from "./advanced-input/advanced-input.component";
import { AdvancedLoadTypeSwitchComponent } from "./advanced-load-type-switch/advanced-load-type-switch.component";
import { AdvancedLoadTypeComponent } from "./advanced-load-type/advanced-load-type.component";
import { AdvancedQtyInputComponent } from "./advanced-qty-input/advanced-qty-input.component";
import { AdvancedSearchResultsComponent } from "./advanced-search-results/advanced-search-results.component";
import { AdvancedShipmentStatusComponent } from "./advanced-shipment-status/advanced-shipment-status.component";
import { BackButtonComponent } from "./back-button/back-button.component";
import { BookingDetailsCardComponent } from "./booking-details-card/booking-details-card.component";
import { BookingDetailsItemComponent } from "./booking-details-item/booking-details-item.component";
import { BookingDetailsMenuComponent } from "./booking-details-menu/booking-details-menu.component";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { CaiCommoditySearchComponent } from "./commodity-search/commodity-search.component";
import { CustomInputComponent } from "./custom-input/custom-input.component";
import { DeleteEntityDialogComponent } from "./delete-entity-dialog/delete-entity-dialog.component";
import { FetchEntityDialogComponent } from "./fetch-entity-dialog/fetch-entity-dialog.component";
import { FlightDetailPopupComponent } from "./flight-detail-popup/flight-detail-popup.component";
import { FlightPathsComponent } from "./flight-paths/flight-paths.component";
import { FlightPreviewComponent } from "./flight-preview/flight-preview.component";
import { FlightSearchComponent } from "./flight-search/flight-search.component";
import { IconCopyComponent } from "./icon-copy/icon-copy.component";
import { IconEmailComponent } from "./icon-email/icon-email.component";
import { LanguageOptionComponent } from "./language-selector/language-option/language-option.component";
import { LanguageSelectorComponent } from "./language-selector/language-selector.component";
import {
  ContextMenu2Component,
  ContextMenuComponent,
  HelpComponent,
  LeftSideSettingsPage,
  NewOTPContainerComponent,
  QuickActionComponent,
  ScrollTopComponent,
  SearchResultComponent,
  SplashScreenComponent,
  Subheader1Component,
  SubheaderSearchComponent,
  SettingsSelectComponent,
  UserProfile2Component,
  CompanySettingsComponent,
  ProfileImageComponent,
  PersonalSettingsComponent,
  AboutPersonalSettingsComponent,
  IntegrationsComponent,
  NotificationsPersonalSettingsComponent,
  PreferencesPersonalSettingsComponent,
  SecurityPersonalSettingsComponent,
  CustomSwitchComponent,
  SettingsLayoutComponent,
} from "./layout";
import { CaiLoadTypeBupComponent } from "./load-type/components/load-type-bup/load-type-bup.component";
import { CaiLoadTypeAutofillDimensions } from "./load-type/components/load-type-dimension-autofill/load-type-dimension-autofill.component";
import { CaiLoadTypeDimensionComponent } from "./load-type/components/load-type-dimension/load-type-dimension.component";
import { CaiLoadTypeInputComponent } from "./load-type/components/load-type-input/load-type-input.component";
import { CaiLoadTypeQuantityInputBaseComponent } from "./load-type/components/load-type-quantity-input/load-type-quantity-input-base.component";
import { CaiLoadTypeQuantityInputComponent } from "./load-type/components/load-type-quantity-input/load-type-quantity-input.component";
import { CaiLoadTypeSelectComponent } from "./load-type/components/load-type-select/load-type-select.component";
import { CaiLoadTypeSwitchComponent } from "./load-type/components/load-type-switch/load-type-switch.component";
import { CaiLoadTypeUnitSelectorComponent } from "./load-type/components/load-type-unit-selector/load-type-unit-selector.component";
import { CaiLoadTypeWeightSwitchComponent } from "./load-type/components/load-type-weight-switch/load-type-weight-switch.component";
import { CaiLoadTypeBaseComponent } from "./load-type/load-type-base.component";
import { CaiLoadTypeComponent } from "./load-type/load-type.component";
import { CaiLoadTypeAutofillText } from "./load-type/modals/load-type-autofill-text-modal/load-type-autofill-text-modal.component";
import { LoadingComponent } from "./loading-component/loading-component.component";
import { MultiselectComponent } from "./multiselect/multiselect.component";
import { NewPopupComponent } from "./new-popup/new-popup.component";
import { NotificationComponent } from "./notification/notification.component";
import { OfficeSelectorComponent } from "./office-selector/office-selector.component";
import { OfficeTextSelectorComponent } from "./office-text-selector/office-text-selector.component";
import { PopoverComponent } from "./popover/popover.component";
import { BasicPopupComponent } from "./popup/basic-popup.component";
import { RequestButtonComponent } from "./request-button/request-button.component";
import { RequirementRadioGroupComponent } from "./requirement-radio-group/requirement-radio-group.component";
import { RequirementActiveContainerComponent } from "./requirements/active-container/requirement-active-container.component";
import { RequirementCargoAircraftOnlyComponent } from "./requirements/cargo-aircraft-only/requirement-cargo-aircraft-only.component";
import { RequirementCoolingPackagingComponent } from "./requirements/cooling-packaging/requirement-cooling-packaging.component";
import { RequirementDangerousGoodTableComponent } from "./requirements/dangerous-good-table/requirement-dangerous-good-table.component";
import { UnidSelectorComponent } from "./requirements/dangerous-good-table/unid-selector/unid-selector.component";
import { RequirementLithiumBatteryDetailComponent } from "./requirements/lithium-battery-detail/requirement-lithium-battery-detail.component";
import { RequirementLithiumBatteryComponent } from "./requirements/lithium-battery/requirement-lithium-battery.component";
import { RequirementPharmaDangerousGoodsComponent } from "./requirements/pharma-dangerous-goods/requirement-pharma-dangerous-goods.component";
import { RequirementTemperatureFullComponent } from "./requirements/temperature-full/requirement-temperature-full.component";
import { RequirementTemperatureLivingComponent } from "./requirements/temperature-living/requirement-temperature-living.component";
import { RequirementTemperatureTrackingBatteryComponent } from "./requirements/temperature-tracking-battery/requirement-temperature-tracking-battery.component";
import { RequirementTemperatureTrackingDeviceComponent } from "./requirements/temperature-tracking-device/requirement-temperature-tracking-device.component";
import { RequirementTemperatureTrackingNumberComponent } from "./requirements/temperature-tracking-number/requirement-temperature-tracking-number.component";
import { CaiRoutePathsComponent } from "./route-paths/route-paths.component";
import { SmartViewTableRowComponent } from "./smart-view-table-row/smart-view-table-row.component";
import { SmartViewTableComponent } from "./smart-view-table/smart-view-table.component";
import { CaiSuccessMessageComponent } from "./success-message/success-message.component";
import { SvgAutoAwbComponent } from "./svg-auto-awb/svg-auto-awb.component";
import { SvgOfficeComponent } from "./svg-office/svg-office.component";
import { SvgQualityScoreComponent } from "./svg-quality-score/svg-quality-score.component";
import { UpdateStatusDialogComponent } from "./update-status-dialog/update-status-dialog.component";
import {
  CargoWalletManagementComponent,
  LatestTransactionsComponent,
  TopupThankyouComponent,
  WalletActivationFormComponent,
  WalletActivationThankyouComponent,
  WalletStatusComponent,
  WalletTopupAmountComponent,
  WalletTopupComponent,
  WalletTopupCurrencyComponent,
  WalletTopupPaymentMethodComponent,
  WalletTransactionsComponent,
} from "./wallet-management";

import { CopyBannerDialogComponent } from "./copy-banner-dialog/copy-banner-dialog.component";
import { PaymentPopupComponent } from "./payment-popup/payment-popup.component";
import { CargomatePopupComponent } from "./cargomate-popup/cargomate-popup.component";
import { AdvancedMultiselectComponent } from "./advanced-multiselect/advanced-multiselect.component";
import { AdvancedSelectComponent } from "./advanced-select/advanced-select.component";
import { SupersetDashboardComponent } from "./superset-dashboard/superset-dashboard.component";
import {
  OfficeSettingsComponent,
  OfficeManagementComponent,
  OfficeChargesComponent,
  OfficeTermsAndConditionsComponent,
  OfficeAddUserModal,
  OfficeEditUserModal,
  OfficeRemoveUserModal,
  CargoMartPlanSelectorModal,
  ProGenericModal,
  OfficeDeleteChargeModal,
  OfficeDeleteTermsModal,
  OfficeCustomersComponent,
  OfficeAddAirportChargeModal,
} from "./office-settings";
import { SummaryWidgetsComponent } from "./summary-widgets/summary-widgets.component";
import { SummaryWidgetsBaseComponent } from "./summary-widgets/summary-widgets-base.component";
import { AdvancedDaterangePickerComponent } from "./advanced-daterange-picker/advanced-daterange-picker.component";
import { AdvancedSearchComponent } from "./advanced-search/advanced-search.component";
import { SearchPopupComponent } from "./search-popup/search-popup.component";

const CAI_COMPONENTS = [
  CustomSwitchComponent,
  SettingsSelectComponent,
  SettingsLayoutComponent,
  AboutPersonalSettingsComponent,
  IntegrationsComponent,
  AdvancedDaterangePickerComponent,
  NotificationsPersonalSettingsComponent,
  PreferencesPersonalSettingsComponent,
  SecurityPersonalSettingsComponent,
  PersonalSettingsComponent,
  ProfileImageComponent,
  CompanySettingsComponent,
  CaiCommoditySearchComponent,
  CaiRoutePathsComponent,
  CaiLoadTypeComponent,
  CaiLoadTypeBaseComponent,
  CaiLoadTypeQuantityInputComponent,
  CaiLoadTypeQuantityInputBaseComponent,
  CaiLoadTypeSwitchComponent,
  CaiLoadTypeDimensionComponent,
  CaiLoadTypeInputComponent,
  CaiLoadTypeWeightSwitchComponent,
  CaiLoadTypeBupComponent,
  CaiLoadTypeSelectComponent,
  CaiLoadTypeUnitSelectorComponent,
  CaiLoadTypeAutofillDimensions,
  CaiLoadTypeAutofillText,
  CaiSuccessMessageComponent,
  ActionNotificationComponent,
  DeleteEntityDialogComponent,
  FetchEntityDialogComponent,
  LanguageSelectorComponent,
  LanguageOptionComponent,
  ContextMenuComponent,
  ContextMenu2Component,
  NewOTPContainerComponent,
  SearchResultComponent,
  ScrollTopComponent,
  SubheaderSearchComponent,
  Subheader1Component,
  HelpComponent,
  QuickActionComponent,
  UserProfile2Component,
  LoadingComponent,
  MultiselectComponent,
  OfficeSelectorComponent,
  OfficeTextSelectorComponent,
  UpdateStatusDialogComponent,
  NotificationComponent,
  LeftSideSettingsPage,
  SvgQualityScoreComponent,
  SvgOfficeComponent,
  SplashScreenComponent,
  RequirementActiveContainerComponent,
  RequirementCargoAircraftOnlyComponent,
  RequirementCoolingPackagingComponent,
  RequirementDangerousGoodTableComponent,
  RequirementLithiumBatteryComponent,
  RequirementLithiumBatteryDetailComponent,
  RequirementPharmaDangerousGoodsComponent,
  RequirementTemperatureFullComponent,
  RequirementTemperatureLivingComponent,
  RequirementTemperatureTrackingBatteryComponent,
  RequirementTemperatureTrackingDeviceComponent,
  RequirementTemperatureTrackingNumberComponent,
  BookingDetailsItemComponent,
  RequirementRadioGroupComponent,
  UnidSelectorComponent,
  CheckboxComponent,
  AdvancedLoadTypeComponent,
  AdvancedQtyInputComponent,
  AdvancedSearchResultsComponent,
  RequestButtonComponent,
  AdvancedAutocompleteComponent,
  AdvancedInputBaseComponent,
  AdvancedInputComponent,
  AdvancedDatepickerComponent,
  AdvancedLoadTypeSwitchComponent,
  BookingDetailsMenuComponent,
  BookingDetailsCardComponent,
  AdvancedShipmentStatusComponent,
  AdvancedErrorsComponent,
  AdvancedAwbInputComponent,
  FlightPreviewComponent,
  NewPopupComponent,
  FlightSearchComponent,
  SmartViewTableComponent,
  SmartViewTableRowComponent,
  FlightPathsComponent,
  SvgAutoAwbComponent,
  FlightDetailPopupComponent,
  PopoverComponent,
  IconCopyComponent,
  IconEmailComponent,
  CustomInputComponent,
  BackButtonComponent,
  CargoWalletManagementComponent,
  LatestTransactionsComponent,
  TopupThankyouComponent,
  WalletTransactionsComponent,
  WalletActivationFormComponent,
  WalletActivationThankyouComponent,
  WalletStatusComponent,
  WalletTopupComponent,
  WalletTopupAmountComponent,
  WalletTopupCurrencyComponent,
  WalletTopupPaymentMethodComponent,
  CopyBannerDialogComponent,
  PaymentPopupComponent,
  BasicPopupComponent,
  CargomatePopupComponent,
  AdvancedMultiselectComponent,
  AdvancedSelectComponent,
  SupersetDashboardComponent,
  LatestTransactionsComponent,
  OfficeSettingsComponent,
  OfficeManagementComponent,
  OfficeChargesComponent,
  OfficeTermsAndConditionsComponent,
  OfficeCustomersComponent,
  OfficeAddUserModal,
  OfficeEditUserModal,
  OfficeRemoveUserModal,
  CargoMartPlanSelectorModal,
  ProGenericModal,
  OfficeDeleteChargeModal,
  SummaryWidgetsComponent,
  SummaryWidgetsBaseComponent,
  OfficeDeleteTermsModal,
  AdvancedSearchComponent,
  OfficeAddAirportChargeModal,
  SearchPopupComponent,
];

@NgModule({
  "declarations": [...CAI_COMPONENTS],
  "exports": [...CAI_COMPONENTS],
  "imports": [
    CommonModule,
    FormsModule,
    RouterModule,
    ScrollingModule,
    ReactiveFormsModule,
    CaiFrameworkModule,
    CaiServicesModule,
    MatTooltipModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSortModule,
    NgxPaginationModule,
    NgbModule,
    MatDatepickerModule,
    CdkScrollableModule,
    MatProgressSpinnerModule,
    CoreModule,
    MatIconModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatDialogModule,
    CommonPipesModule,
    CommonDirectivesModule,
    MatFormFieldModule,
    MatSelectModule,
    NgOtpInputModule,
    CountdownModule,
    MatExpansionModule,
    ClipboardModule,
    MatDividerModule,
    NgxEchartsModule.forRoot({
      "echarts": () => import("echarts"),
    }),
  ],
})
export class CommonComponentsModule {}
