// Angular
import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import {
  MatNativeDateModule,
  MatRippleModule,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// Material

import { MatCheckboxModule } from "@angular/material/checkbox";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";

// NGRX
import { StoreModule } from "@ngrx/store";
// Recaptcha
import {
  RECAPTCHA_BASE_URL,
  RecaptchaModule,
  RecaptchaFormsModule,
} from "ng-recaptcha";
// CRUD
// Module components

// Auth
import { NgxFileDropModule } from "ngx-file-drop";

import { NgxPaginationModule } from "ngx-pagination";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatChipsModule } from "@angular/material/chips";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";

import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatMenuModule } from "@angular/material/menu";
import { MatSliderModule } from "@angular/material/slider";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatListModule } from "@angular/material/list";

import { MatStepperModule } from "@angular/material/stepper";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatRadioModule } from "@angular/material/radio";
import { CaiFrameworkModule } from "@cai-framework";
import { BrandIconUtil } from "../../utils/brand.util";
import { InterceptService } from "../../core/_base/crud/utils/intercept.service";
import { CoreModule } from "../../core/core.module";
import { authReducer } from "../../core/_misc/_reducers/auth.reducers";
import { CustomPipesModule } from "../../core/_pipes/pipe.module";
import { CommonComponentsModule } from "../components.module";
import { LoginLhsComponent } from "./login/loginLhs/login-lhs.component";
import { SlidersComponent } from "./login/loginLhs/sliders/sliders.component";
import { SvgLocationComponent } from "./login/loginLhs/sliders/svg-location/svg-location.component";
import { SvgSearchComponent } from "./login/loginLhs/sliders/svg-search/svg-search.component";
import { SvgBigPackageComponent } from "./login/loginLhs/sliders/svg-big-package/svg-big-package.component";
import { SvgClockComponent } from "./login/loginLhs/sliders/svg-clock/svg-clock.component";
import { SvgBigLeafComponent } from "./login/loginLhs/sliders/svg-big-leaf/svg-big-leaf.component";
import { SvgFlightComponent } from "./login/loginLhs/sliders/svg-flight/svg-flight.component";
import { SvgBigFlightComponent } from "./login/loginLhs/sliders/svg-big-flight/svg-big-flight.component";
import { SvgPackageComponent } from "./login/loginLhs/sliders/svg-package/svg-package.component";
import { SvgLeafComponent } from "./login/loginLhs/sliders/svg-leaf/svg-leaf.component";
import { NewForgotPasswordComponent } from "./new-forgot-password/new-forgot-password.component";
import { NewPasswordComponent } from "./new-password/new-password.component";
import { AuthAddAirlineComponent } from "./auth-add-airline/auth-add-airline.component";
import { AuthNoticeComponent } from "./auth-notice/auth-notice.component";
import { LoginComponent } from "./login/login.component";
import { AuthComponent } from "./auth.component";

const routes: Routes = [
  {
    "path": "",
    "component": AuthComponent,
    "children": [
      {
        "path": "",
        "redirectTo": "auth-search",
        "pathMatch": "full",
      },
      {
        "path": "login",
        "component": LoginComponent,
        "title": `${BrandIconUtil.fetchDomainTitle()}: Login`,
        "data": {
          "description":
            "Sign in with your CargoAi account. Easily manage your bookings and shipment tracking using CargoMART. Welcome to your account dashboard.",
        },
      },
      {
        "path": "forgot-password",
        "component": NewForgotPasswordComponent,
        "title": `Forgot password | Can\'t log in - ${BrandIconUtil.fetchDomainTitle()}`,
        "data": {
          "description":
            "Simply enter the login email for the CargoAi account which you are now resetting to receive password reset instructions.",
        },
      },
      {
        "path": "auth-add-airline",
        "component": AuthAddAirlineComponent,
      },
    ],
  },
];

@NgModule({
  "imports": [
    CommonModule,
    FormsModule,
    CoreModule,
    ReactiveFormsModule,
    MatButtonModule,
    RouterModule.forChild(routes),
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,
    StoreModule.forFeature("auth", authReducer),
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatListModule,
    MatSliderModule,
    MatCardModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatMenuModule,
    MatTabsModule,
    MatTooltipModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTableModule,
    MatGridListModule,
    MatToolbarModule,
    MatExpansionModule,
    MatDividerModule,
    MatSortModule,
    MatStepperModule,
    MatChipsModule,
    MatPaginatorModule,
    CommonModule,
    MatRadioModule,
    MatButtonToggleModule,
    FormsModule,
    MatDialogModule,
    MatRippleModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RouterModule.forChild(routes),
    NgxFileDropModule,
    // used on search results page
    NgbModule,
    CustomPipesModule,
    // PartialsModule,
    NgxPaginationModule,
    // SharedModule,
    CommonComponentsModule,
    CaiFrameworkModule,
  ],
  "providers": [
    {
      "provide": RECAPTCHA_BASE_URL,
      "useValue": "https://recaptcha.net/recaptcha/api.js", // use recaptcha.net script source for some of our users
    },
    InterceptService,
    DatePipe,
    {
      "provide": HTTP_INTERCEPTORS,
      "useClass": InterceptService,
      "multi": true,
    },
    MatIconRegistry,
    { "provide": MAT_DATE_LOCALE, "useValue": "en-GB" },
    { "provide": MAT_MOMENT_DATE_ADAPTER_OPTIONS, "useValue": { "useUtc": true } },
  ],
  "exports": [AuthNoticeComponent],
  "declarations": [
    AuthComponent,
    LoginComponent,
    AuthNoticeComponent,
    AuthAddAirlineComponent,
    NewPasswordComponent,
    LoginLhsComponent,
    SlidersComponent,
    SvgLocationComponent,
    SvgSearchComponent,
    SvgBigPackageComponent,
    SvgClockComponent,
    SvgBigLeafComponent,
    SvgFlightComponent,
    SvgBigFlightComponent,
    SvgPackageComponent,
    SvgLeafComponent,
    NewForgotPasswordComponent,
  ],
})
export class AuthModule {
  static forRoot (): ModuleWithProviders<AuthModule> {
    return {
      "ngModule": AuthModule,
      "providers": [],
    };
  }
}
