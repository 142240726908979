import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  "selector": "icon-email",
  "templateUrl": "./icon-email.component.html",
  "styleUrls": ["./icon-email.component.scss"],
  "changeDetection": ChangeDetectionStrategy.OnPush,
})
export class IconEmailComponent {
  @Input() isHovered: boolean;
  @Input() tooltipText: string;
}
