<section class="px-3">
  <kt-back-button *ngIf="false" [previousUrl]="walletBaseUrl"></kt-back-button>
  <div class="pt-3 table-responsive table-wrapper" style="overflow: visible">
    <div class="section-topup breadcrum-topup">
      <p i18n="@@wallet-transaction-heading">Transactions History</p>
    </div>
    <table
      aria-describedby="wallet transactions list"
      class="table table-hover table-border-radius custom-transation-table no-overflow-block"
    >
      <thead>
        <tr>
          <th colspan="8" class="text-right bg-white" scope="col">
            <div class="section-filter-wrapper position-relative">
              <div role="button" (click)="openFilter()">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.25 7C10.25 4.92893 11.9289 3.25 14 3.25C15.8142 3.25 17.3275 4.53832 17.675 6.25H21C21.4142 6.25 21.75 6.58579 21.75 7C21.75 7.41421 21.4142 7.75 21 7.75H17.675C17.3275 9.46168 15.8142 10.75 14 10.75C11.9289 10.75 10.25 9.07107 10.25 7ZM14 4.75C15.2426 4.75 16.25 5.75736 16.25 7C16.25 8.24264 15.2426 9.25 14 9.25C12.7574 9.25 11.75 8.24264 11.75 7C11.75 5.75736 12.7574 4.75 14 4.75ZM2 6.25C1.58579 6.25 1.25 6.58579 1.25 7C1.25 7.41421 1.58579 7.75 2 7.75H8C8.41421 7.75 8.75 7.41421 8.75 7C8.75 6.58579 8.41421 6.25 8 6.25H2ZM2 16.25C1.58579 16.25 1.25 16.5858 1.25 17C1.25 17.4142 1.58579 17.75 2 17.75H5.32501C5.67247 19.4617 7.18578 20.75 9 20.75C11.0711 20.75 12.75 19.0711 12.75 17C12.75 14.9289 11.0711 13.25 9 13.25C7.18578 13.25 5.67247 14.5383 5.32501 16.25H2ZM6.75 17C6.75 18.2426 7.75736 19.25 9 19.25C10.2426 19.25 11.25 18.2426 11.25 17C11.25 15.7574 10.2426 14.75 9 14.75C7.75736 14.75 6.75 15.7574 6.75 17ZM15 16.25C14.5858 16.25 14.25 16.5858 14.25 17C14.25 17.4142 14.5858 17.75 15 17.75H21C21.4142 17.75 21.75 17.4142 21.75 17C21.75 16.5858 21.4142 16.25 21 16.25H15Z"
                    fill="#B6B6B6"
                  />
                </svg>
                <p
                  class="filter-heading d-inline-block mx-2 mb-0"
                  i18n="@@wallet-transaction-filters"
                >
                  Filters
                </p>
              </div>
              <div
                class="section-filter text-left p-3"
                [ngClass]="{
                  'd-none': !showFilter,
                  'd-inline-block': showFilter
                }"
              >
                <div>
                  <p class="filter-heading-color">Type :</p>
                  <div
                    class="section-credit-debit d-inline-block text-center"
                    role="button"
                    [ngClass]="{ 'selected-tran': selectedType === 'Debit' }"
                    (click)="setTranType('Debit')"
                  >
                    <svg
                      width="22"
                      height="20"
                      viewBox="0 0 22 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 17.9784V4.53464C1.99994 4.32714 2.06305 4.12466 2.18077 3.95467C2.29849 3.78469 2.4651 3.65544 2.658 3.58448L12.329 0.030476C12.4045 0.0026847 12.4856 -0.00628412 12.5653 0.00432991C12.645 0.0149439 12.721 0.0448279 12.7869 0.0914481C12.8527 0.138068 12.9065 0.20005 12.9436 0.272138C12.9807 0.344226 13 0.424295 13 0.505556V5.51209L19.316 7.63984C19.5152 7.70691 19.6885 7.83565 19.8112 8.00782C19.934 8.18 20.0001 8.38686 20 8.5991V17.9784H22V20H0V17.9784H2ZM4 17.9784H11V2.6697L4 5.24321V17.9784ZM18 17.9784V9.32789L13 7.64287V17.9784H18Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p i18n="@@wallet-transaction-filter-debit">Debit</p>
                  </div>
                  <div
                    class="section-credit-debit d-inline-block text-center ml-3"
                    role="button"
                    [ngClass]="{ 'selected-tran': selectedType === 'Credit' }"
                    (click)="setTranType('Credit')"
                  >
                    <svg
                      width="22"
                      height="20"
                      viewBox="0 0 22 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 17.9784V4.53464C1.99994 4.32714 2.06305 4.12466 2.18077 3.95467C2.29849 3.78469 2.4651 3.65544 2.658 3.58448L12.329 0.030476C12.4045 0.0026847 12.4856 -0.00628412 12.5653 0.00432991C12.645 0.0149439 12.721 0.0448279 12.7869 0.0914481C12.8527 0.138068 12.9065 0.20005 12.9436 0.272138C12.9807 0.344226 13 0.424295 13 0.505556V5.51209L19.316 7.63984C19.5152 7.70691 19.6885 7.83565 19.8112 8.00782C19.934 8.18 20.0001 8.38686 20 8.5991V17.9784H22V20H0V17.9784H2ZM4 17.9784H11V2.6697L4 5.24321V17.9784ZM18 17.9784V9.32789L13 7.64287V17.9784H18Z"
                        fill="currentColor"
                      />
                    </svg>
                    <p i18n="@@wallet-transaction-filter-credit">Credit</p>
                  </div>
                </div>
                <div class="d-inline-block filter-type-multiselector">
                  <p
                    class="filter-heading-color text-left"
                    i18n="@@wallet-transaction-filter-transaction"
                  >
                    Transactions :
                  </p>
                  <div
                    class="tran-type-items"
                    *ngFor="let item of transactionTypeFilters"
                  >
                    <kt-checkbox
                      [label]="item.label"
                      (onToggle)="onChangeTransaction($event, item.value)"
                    ></kt-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </th>
        </tr>
        <tr class="bg-light-gray" [ngClass]="{ walletapp: isCargoWallet }">
          <th scope="col" i18n="@@wallet-transaction-heading-date">DATE</th>
          <th scope="col" i18n="@@wallet-transaction-heading-due-date">
            DUE DATE
          </th>
          <th scope="col" i18n="@@wallet-transaction-heading-amount">AMOUNT</th>
          <th scope="col" i18n="@@wallet-transaction-heading-currency">
            CURRENCY
          </th>
          <th scope="col" i18n="@@wallet-transaction-heading-transaction">
            TRANSACTION
          </th>
          <th
            scope="col"
            i18n="@@wallet-transaction-heading-to-from"
            *ngIf="isCargoWallet"
          >
            TO/FROM
          </th>
          <th scope="col" i18n="@@wallet-transaction-heading-refrence">
            REFERENCE
          </th>
          <th
            scope="col"
            i18n="@@wallet-transaction-heading-status"
            class="text-center"
          >
            STATUS
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngIf="
            visibleOnHoldTransactionsList &&
            visibleOnHoldTransactionsList.length
          "
          class="bg-custom-transaction-blue"
        >
          <td colspan="8" class="py-0">
            <p class="on-hold-text m-0 text-white">
              <span
                caiPopover
                [enablePopover]="true"
                [target]="idTooltip"
                [allowHoverOnTarget]="true"
                [placement]="'right-start'"
                class="tooltip-top-index"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 19 19"
                  fill="none"
                  class="mr-2 tooltip-top-index"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.47596 1.5C5.07096 1.5 1.5 5.07096 1.5 9.47596C1.5 13.881 5.07096 17.4519 9.47596 17.4519C13.881 17.4519 17.4519 13.881 17.4519 9.47596C17.4519 5.07096 13.881 1.5 9.47596 1.5ZM0 9.47596C0 4.24253 4.24253 0 9.47596 0C14.7094 0 18.9519 4.24253 18.9519 9.47596C18.9519 14.7094 14.7094 18.9519 9.47596 18.9519C4.24253 18.9519 0 14.7094 0 9.47596ZM9.47595 4.69775C9.97301 4.69775 10.376 5.10069 10.376 5.59775V10.4455C10.376 10.9426 9.97301 11.3455 9.47595 11.3455C8.9789 11.3455 8.57595 10.9426 8.57595 10.4455V5.59775C8.57595 5.10069 8.9789 4.69775 9.47595 4.69775ZM9.65403 14.9965C10.1786 14.9965 10.6038 14.5712 10.6038 14.0467C10.6038 13.5221 10.1786 13.0969 9.65403 13.0969C9.12949 13.0969 8.70427 13.5221 8.70427 14.0467C8.70427 14.5712 9.12949 14.9965 9.65403 14.9965Z"
                    fill="white"
                  />
                </svg>
                <ng-template #idTooltip>
                  <kt-popover
                    [showIcon]="true"
                    [arrowColor]="'rgba(233, 246, 255, 0.66)'"
                    [width]="800"
                    [maxWidth]="'85vw'"
                    [bgColor]="
                      'linear-gradient(262.9deg, #e9f6ff 0.66%, #ffffff 109.77%)'
                    "
                    [arrowColor]="'#679EC6'"
                    [textColor]="'#505050'"
                    placement="right-start"
                  >
                    <div
                      body
                      class="popover-body pt-2 pb-0 adjust-onhold-tooltip"
                    >
                      <p i18n="@@wallet-transaction-heading-onhold">
                        <strong>On Hold Amount</strong>
                      </p>
                      <p>
                        <span i18n="@@wallet-transaction-description-onhold">
                          "On Hold" transactions correspond to bookings that
                          have been paid with ''CargoWALLET Prepaid'' (Booking
                          Prepayment) that&nbsp;</span
                        >
                        <span
                          i18n="
                            @@wallet-transaction-description-onhold-highlighted"
                          class="text-orange-transaction"
                          >have not yet been accepted by the airline</span
                        ><span
                          i18n="@@wallet-transaction-description-onhold-normal"
                          >. If you wish to know more about ''On Hold''
                          transactions, please see our&nbsp;</span
                        >
                        <a
                          i18n="@@wallet-transaction-description-onhold-link"
                          href="https://app.intercom.com/a/apps/yeay9emt/articles/articles/6878767/show"
                          >article</a
                        >
                        .
                      </p>
                    </div>
                  </kt-popover>
                </ng-template> </span
              ><span i18n="@@wallet-transaction-description-onhold-amount"
                >ON HOLD AMOUNT</span
              >
            </p>
          </td>
        </tr>
        <tr
          *ngFor="let item of visibleOnHoldTransactionsList"
          class="bg-custom-transaction-blue-2"
        >
          <td>{{ item.started_at | date }}</td>
          <td>
            {{
              item.due_date &&
              item.transaction_status != transactionStatusEnum.WAITING_FUND
                ? (item.due_date | date)
                : "N/A"
            }}
          </td>
          <td>
            {{
              item.operation === "CREDIT" && item.balance_movement > 0
                ? "+"
                : "-"
            }}
            {{ Math.abs(item.balance_movement) }}
          </td>
          <td>{{ item.currency }}</td>
          <td>{{ item.tran_type }}{{ item.additional_info }}</td>
          <td *ngIf="isCargoWallet">{{ getFromTo(item) }}</td>
          <td>{{ referenceType(item) }}{{ referenceValue(item) }}</td>
          <td class="text-center">
            <p
              class="mb-0 transaction_status px-3 d-inline-block status_{{
                item.transaction_status.toLowerCase()
              }}"
            >
              {{ transactionStatus[item.transaction_status] }}
            </p>
          </td>
        </tr>
        <tr *ngFor="let item of visibleTransactionsList">
          <td>{{ item.started_at | date }}</td>
          <td>
            {{
              item.due_date &&
              item.transaction_status != transactionStatusEnum.WAITING_FUND
                ? (item.due_date | date)
                : "N/A"
            }}
          </td>
          <td>
            {{
              item.operation === "CREDIT" && item.balance_movement > 0
                ? "+"
                : "-"
            }}
            {{ Math.abs(item.balance_movement) }}
          </td>
          <td>{{ item.currency }}</td>
          <td>{{ item.tran_type }}{{ item.additional_info }}</td>
          <td *ngIf="isCargoWallet">{{ getFromTo(item) }}</td>
          <td>{{ referenceType(item) }}{{ referenceValue(item) }}</td>
          <td class="text-center">
            <p
              class="mb-0 transaction_status d-inline-block px-3 status_{{
                item.transaction_status.toLowerCase()
              }}"
            >
              {{ transactionStatus[item.transaction_status] }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
<div *ngIf="walletLoader" class="kt-splash-screen bg-white">
  <div class="section--white">
    <div class="loader--roller">
      <div class="blue-theme-loader-point"></div>
      <div class="blue-theme-loader-point"></div>
      <div class="blue-theme-loader-point"></div>
      <div class="blue-theme-loader-point"></div>
      <div class="blue-theme-loader-point"></div>
      <div class="blue-theme-loader-point"></div>
      <div class="blue-theme-loader-point"></div>
    </div>
  </div>
</div>
