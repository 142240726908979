import { RequirementConfig } from "../../core/_models/requirement-config.model";

export enum CargoAircraftOnlyAnswer {
  NO = "pax_no",
  YES = "cao_yes",
}

export const CargoAircraftOnlyConfig: RequirementConfig = {
  "summaryLabel": "Cargo aircraft only shipment :",
  "question": "CARGO AIRCRAFT ONLY SHIPMENT",
  "options": [
    {
      "label": "No",
      "value": CargoAircraftOnlyAnswer.NO,
    },
    {
      "label": "Yes",
      "value": CargoAircraftOnlyAnswer.YES,
    },
  ],
};
