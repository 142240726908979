<div
  [class.smart-view-table]="!isShipmentDetailsPage"
  [class.popoverTable]="popover && !isShipmentDetailsPage"
  [class.popover-scrollable]="isShipmentDetailsPage"
>
  <hr />
  <kt-loading-component [isLoading]="isLoading"> </kt-loading-component>
  <ng-container *ngFor="let airlineFlight of flightsByAirline; let i = index">
    <ng-container *ngIf="!isLoading && i < airlinesToShow">
      <ng-container *ngIf="i !== 0">
        <hr />
      </ng-container>
      <kt-smart-view-table-row
        [searchUUID]="searchUUID"
        [isBatchLoading]="isBatchLoading"
        [flightDays]="flightDays"
        [isNewSearch]="isNewSearch"
        [weight]="weight"
        [chargeableWeight]="chargeableWeight"
        [measurementUnit]="measurementUnit"
        [airlineCompany]="airlineFlight.airlineCompany"
        [selectedFlightDetails]="selectedFlightDetails"
        [allFlights]="airlineFlight.flights"
        [airlinesWithRateInsight]="airlinesWithRateInsight"
        [sliderFirstDep]="sliderFirstDep"
        [qualityScore]="getQualityScore(airlineFlight.airlineCompany)"
        [avgQualityScore]="getAvgQualityScore(airlineFlight.airlineCompany)"
        [sliderLastArr]="sliderLastArr"
        [isSelectAll]="isSelectAll"
        [activeColumn]="activeColumn"
        [aircraftList]="aircraftList"
        [searchTemplate]="searchTemplate"
        [isEnableQuoteRequest]="isEnableQuoteRequest"
        [measurementUnit]="measurementUnit"
        [popover]="popover"
        [isExpressOnly]="isExpressOnly"
        [expandFlightState]="expandFlightState"
        [index]="i"
        [isShipperQuote]="isShipperQuote"
        [requestedFlight]="selectedFlight"
        [flightIndex]="flightIndex"
        [airlineCount]="flightsByAirline.length"
        (onSelectAllChange)="onToggleAllFlight(true)"
        (onSelectFlight)="onSelectFlight($event, airlineFlight.airlineCompany)"
        (onBookClicked)="onBookClicked($event)"
        (onRequestBookClicked)="onRequestBookClicked($event)"
        (onRateChange)="onRateChangeEvent($event)"
        (flightSelected)="flightSelected.emit($event)"
        (onMoveFlightDaysView)="onMoveFlightDaysView.emit($event)"
        (expandFlightStateUpdated)="updateExpandFlightState($event)"
      ></kt-smart-view-table-row>
    </ng-container>
  </ng-container>
  <button
    class="show-more-airlines"
    (click)="showMoreAirlines()"
    *ngIf="airlinesToShow < flightsByAirline.length"
  >
    <span i18n="@@global.more-airlines"> Click here to load more flights </span>
    <span class="la la-angle-down pl-3"></span>
  </button>
</div>
