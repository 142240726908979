import {
  BookingTypeEnum,
  IAllotment,
  ICommodity,
  IDangerousGood,
  IForwarderOffice,
  IQuoteError,
  IQuoteRequest,
  IQuoteRequestWithErrors,
  IRequirementQuote,
  ISpecialHandlingCode,
  ITypeOfProduct,
  MeasurementSystemEnum,
  QuoteRequestStatusEnum,
} from "@cai-services";
import { Forwarder } from "./forwarder.model";
import { QuoteItem } from "./quote-item.model";
import { Quote } from "./quote.model";

export class QuoteRequest implements IQuoteRequest {
  quoteRequestId: number;
  quality: number;
  delayTime: number;
  qualityReasons: string;
  quoteRequestStatus: QuoteRequestStatusEnum;
  transCurrencyAmount: number;
  originalQuotePrice: number;
  originalQuoteCapacity: number;
  bookingType: BookingTypeEnum;
  partnerEmail: string;
  maxDepartureDays: number;
  forwarderOffice: IForwarderOffice;
  isShipmentScreened: boolean;
  loadBoardConsent: boolean;
  specialHandlingCodes: ISpecialHandlingCode[];
  iata: number;
  cass: string;
  typeOfProduct: ITypeOfProduct;
  commodities: ICommodity[];
  requirements: IRequirementQuote[];
  dangerousGoods: IDangerousGood[];
  measurementSystem: MeasurementSystemEnum;
  quotes: Quote[];
  quoteItems: QuoteItem[];
  forwarder: Forwarder;
  forwarderCreator: Forwarder;
  allotment?: IAllotment;
  filename?: string;

  constructor (
    originalQuotePrice?: number,
    originalQuoteCapacity?: number,
    transCurrencyAmount?: number,
    isShipmentScreened?: boolean
  ) {
    this.originalQuotePrice = originalQuotePrice;
    this.originalQuoteCapacity = originalQuoteCapacity;
    this.transCurrencyAmount = transCurrencyAmount;
    this.quoteRequestStatus = QuoteRequestStatusEnum.PENDING;
    this.isShipmentScreened = isShipmentScreened;
  }
}

export class QuoteRequestWithErrors implements IQuoteRequestWithErrors {
  quoteRequest: QuoteRequest;
  errors: IQuoteError[];
}
