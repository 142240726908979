import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import { IBlobFile } from "../../model/blob-file.model";
import { API_CORE } from "../../constant/api-core.const";
import { IPaginationResults } from "../../model";
import { IAirlineRate } from "../../model/airline-rate.model";
import { IRateInsight } from "../../model/rate-insight.model";
import { IRateMetadata } from "../../model/rate-metadata.model";
import { IRateAirlineProdCode } from "../../model/rate-airline-prod-code.model";
import { ErrorUtil } from "../../utils/error.util";

@Injectable({
  "providedIn": "root",
})
export class RateService {
  async getAllRates (
    page: number,
    size: number,
    sort: string,
    origin?: string,
    airline?: string,
    selectedOfficeId?: number,
    country?: string,
    filterHistory?: boolean,
    search?: string,
    commodity?: string,
    destination?: string,
    rateCategory?: string,
    service?: string,
    type?: string,
    iatacass?: string,
    departureDate?: string,
  ): Promise<IPaginationResults<IAirlineRate>> {
    const extraOptions: any = {
      "queryStringParameters": {
        page,
        size,
        sort,
      },
    };
    if (country) {
      extraOptions.queryStringParameters.country = country;
    }
    if (origin) {
      extraOptions.queryStringParameters.origin = origin;
    }
    if (airline) {
      extraOptions.queryStringParameters.airline = airline;
    }
    if (selectedOfficeId) {
      extraOptions.queryStringParameters.officeId = selectedOfficeId;
    }
    if (filterHistory) {
      extraOptions.queryStringParameters.filterHistory = filterHistory;
    }
    if (search) {
      extraOptions.queryStringParameters.search = search;
    }
    if (commodity) {
      extraOptions.queryStringParameters.commodity = commodity;
    }
    if (destination) {
      extraOptions.queryStringParameters.destination = destination;
    }
    if (rateCategory) {
      extraOptions.queryStringParameters.rateCategory = rateCategory;
    }
    if (service) {
      extraOptions.queryStringParameters.service = service;
    }
    if (type) {
      extraOptions.queryStringParameters.type = type;
    }
    if (iatacass) {
      extraOptions.queryStringParameters.iatacass = iatacass;
    }
    if (departureDate) {
      extraOptions.queryStringParameters.departureDate = departureDate;
    }
    return API.get(API_CORE.name, API_CORE.URLs.Rate.Rates, extraOptions).catch(
      ErrorUtil.handleError,
    );
  }

  async exportExcel (
    airline?: string,
    selectedOfficeId?: number,
    origin?: string,
    country?: string,
    search?: string,
    commodity?: string,
    destination?: string,
    rateCategory?: string,
    service?: string,
    type?: string,
    iatacass?: string,
    departureDate?: string,
  ): Promise<IBlobFile> {
    const extraOptions: any = {
      "queryStringParameters": {},
    };
    if (airline) {
      extraOptions.queryStringParameters.airline = airline;
    }
    if (selectedOfficeId) {
      extraOptions.queryStringParameters.officeId = selectedOfficeId;
    }
    if (country) {
      extraOptions.queryStringParameters.country = country;
    }
    if (origin) {
      extraOptions.queryStringParameters.origin = origin;
    }
    if (rateCategory) {
      extraOptions.queryStringParameters.rateCategory = rateCategory;
    }
    if (service) {
      extraOptions.queryStringParameters.service = service;
    }
    if (destination) {
      extraOptions.queryStringParameters.destination = destination;
    }
    if (commodity) {
      extraOptions.queryStringParameters.commodity = commodity;
    }
    if (type) {
      extraOptions.queryStringParameters.type = type;
    }
    if (iatacass) {
      extraOptions.queryStringParameters.iatacass = iatacass;
    }
    if (search) {
      extraOptions.queryStringParameters.search = search;
    }
    if (departureDate) {
      extraOptions.queryStringParameters.departureDate = departureDate;
    }
    return API.get(
      API_CORE.name,
      API_CORE.URLs.Rate.ExportCSV,
      extraOptions,
    ).catch(ErrorUtil.handleError);
  }
  async getRatesForMetadata (
    airline?: string,
    selectedOfficeId?: number,
    country?: string,
    rateCategory?: string,
    iatacass?: string,
  ): Promise<IRateMetadata> {
    const extraOptions: any = {
      "queryStringParameters": {},
    };
    if (airline) {
      extraOptions.queryStringParameters.airline = airline;
    }
    if (selectedOfficeId) {
      extraOptions.queryStringParameters.officeId = selectedOfficeId;
    }
    if (country) {
      extraOptions.queryStringParameters.country = country;
    }
    if (rateCategory) {
      extraOptions.queryStringParameters.rateCategory = rateCategory;
    }
    if (iatacass) {
      extraOptions.queryStringParameters.iatacass = iatacass;
    }
    return API.get(
      API_CORE.name,
      API_CORE.URLs.Rate.Metadata,
      extraOptions,
    ).catch(ErrorUtil.handleError);
  }

  async getRateCoverage (
    origin: string,
    destination: string,
    commodity: string,
    officeId: number,
    departureDate: string,
  ): Promise<string[]> {
    const extraOptions: any = {
      "queryStringParameters": {
        origin,
        destination,
        commodity,
        officeId,
        departureDate,
      },
    };
    return API.get(
      API_CORE.name,
      API_CORE.URLs.Rate.Coverage,
      extraOptions,
    ).catch(ErrorUtil.handleError);
  }

  async getRateInsight (
    airline: string,
    origin: string,
    destination: string,
    commodity: string,
    chargeableWeight: number,
    officeId: number,
    departureDate: string,
    originCountry?: string,
    destinationCountry?: string,
  ): Promise<IRateInsight> {
    const extraOptions: any = {
      "queryStringParameters": {
        airline,
        origin,
        destination,
        commodity,
        chargeableWeight,
        officeId,
        departureDate,
      },
    };
    if (originCountry) {
      extraOptions.queryStringParameters.originCountry = originCountry;
    }
    if (destinationCountry) {
      extraOptions.queryStringParameters.destinationCountry =
        destinationCountry;
    }
    return API.get(
      API_CORE.name,
      API_CORE.URLs.Rate.Insights,
      extraOptions,
    ).catch(ErrorUtil.handleError);
  }

  async getAirlineProdCodes (
    officeId: number,
    airline?: string,
  ): Promise<IRateAirlineProdCode[]> {
    const extraOptions: any = {
      "queryStringParameters": {
        officeId,
      },
    };
    if (airline) {
      extraOptions.queryStringParameters.airline = airline;
    }
    return API.get(
      API_CORE.name,
      API_CORE.URLs.Rate.ProdCodes,
      extraOptions,
    ).catch(ErrorUtil.handleError);
  }
}
