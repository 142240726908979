<div class="bup-container">
  <figure>
    <figcaption></figcaption>
    <table class="bup-table" aria-describedby="dimensions">
      <thead>
        <tr>
          <th scope="col">
            <div class="col">
              <span i18n="@@load-type.uld-type">ULD Type</span>
            </div>
          </th>
          <th scope="col">
            <div class="col">
              <span i18n="@@global.quantity">Quantity</span>
            </div>
          </th>
          <th scope="col">
            <div class="col">
              <span i18n="@@global.weight">Weight</span>
              <span
                class="unit-switch"
                [class.disabled]="!enableUnitSwitch"
                [class.metric]="
                  matchMeasurementUnit(measurementSystemEnum.METRIC)
                "
                [class.imperial]="
                  matchMeasurementUnit(measurementSystemEnum.IMPERIAL)
                "
              >
                <cai-load-type-unit-selector
                  *ngIf="enableUnitSwitch; else defaultWeightUnit"
                  [(selected)]="weightUnit"
                  (selectedChange)="changeWeightUnit($event)"
                  [(measurementSystem)]="measurementSystem"
                  (measurementSystemChange)="
                    measurementSystemChange.emit($event)
                  "
                  [isParentMetric]="
                    weightUnit?.code
                      ? matchMeasurementUnit(measurementSystemEnum.METRIC)
                      : undefined
                  "
                  [isParentImperial]="
                    weightUnit?.code
                      ? matchMeasurementUnit(measurementSystemEnum.IMPERIAL)
                      : undefined
                  "
                  [options]="getMeasurementUnits(measurementTypeEnum.WEIGHT)"
                  [marginTop]="2"
                  [fontSize]="14"
                  [width]="41"
                ></cai-load-type-unit-selector>
                <ng-template #defaultWeightUnit>
                  <span>{{ measurementUnit.WEIGHT.display }}</span>
                </ng-template>
              </span>
            </div>
          </th>
          <th scope="col">
            <div class="col" i18n="@@global.volume">Volume</div>
          </th>
          <th scope="col" class="w-100"></th>
        </tr>
      </thead>
      <tbody #tbody [style.max-height.px]="maxHeight">
        <ng-container *ngFor="let bup of bups; let index = index">
          <tr>
            <td>
              <div
                class="col"
                [attr.data-before]="(index + 1).toString() + '.'"
              >
                <cai-load-type-select
                  [(value)]="bup[BUPFieldsEnum.ULD_TYPE]"
                  (onFocus)="focusRow(bup, index)"
                  (onLostFocus)="unfocusRow(bup)"
                  (onKeyDownEvent)="touchRow(bup)"
                  [placeholder]="placeholder"
                  [options]="uldTypeOptions"
                  [invalid]="isInvalidField(BUPFieldsEnum.ULD_TYPE, bup, index)"
                  (valueChange)="changeULDType($event, bup)"
                ></cai-load-type-select>
              </div>
            </td>
            <td>
              <div class="col" [class.disabled]="bup.isNewRow">
                <cai-load-type-input
                  [disabled]="bup.isNewRow"
                  [type]="loadTypeInputTypesEnum.NUMERIC"
                  (onFocus)="focusRow(bup, index)"
                  (onLostFocus)="unfocusRow(bup)"
                  (onKeyDownEvent)="touchRow(bup)"
                  [decimalPlace]="null"
                  [invalid]="isInvalidField(BUPFieldsEnum.QUANTITY, bup, index)"
                  [(value)]="bup[BUPFieldsEnum.QUANTITY]"
                  (valueChange)="changeValue()"
                ></cai-load-type-input>
              </div>
            </td>
            <td>
              <div class="col" [class.disabled]="bup.isNewRow">
                <div>
                  <cai-load-type-input
                    [disabled]="bup.isNewRow"
                    [type]="loadTypeInputTypesEnum.DECIMAL"
                    (onFocus)="focusRow(bup, index)"
                    (onLostFocus)="unfocusRow(bup)"
                    (onKeyDownEvent)="touchRow(bup)"
                    [decimalPlace]="null"
                    [invalid]="isInvalidField(BUPFieldsEnum.WEIGHT, bup, index)"
                    [(value)]="bup[BUPFieldsEnum.WEIGHT]"
                    (valueChange)="changeValue()"
                  ></cai-load-type-input>
                </div>
                <div class="unit">
                  <span *ngIf="!bup.isNewRow">{{ bup.weightUnit }}</span>
                </div>
                <div>
                  <cai-load-type-weight-switch
                    [disabled]="bup.isNewRow"
                    [(value)]="bup['weightType']"
                    (valueChange)="changeValue()"
                  ></cai-load-type-weight-switch>
                </div>
              </div>
            </td>
            <td>
              <div class="col disabled">
                <div>
                  <cai-load-type-input
                    [disabled]="bup.isNewRow"
                    [readonly]="!bup.isNewRow"
                    [value]="
                      bup['volume']
                        ? (bup['volume'] | caiNumberFormatter : true)
                        : bup['volume']
                    "
                    (valueChange)="changeValue()"
                  ></cai-load-type-input>
                </div>
                <div class="unit">
                  <span *ngIf="!bup.isNewRow">{{ bup.volumeUnit }}</span>
                </div>
              </div>
            </td>
            <td class="w-100">
              <div class="col justify-content-center mx-0 px-0">
                <div style="width: 20px">
                  <span
                    class="btn-delete"
                    *ngIf="showDelete(bup, index)"
                    (click)="deleteRow(index)"
                  ></span>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="isInvalidRow(bup, index)" colspan="4" class="error-row">
            <div class="error-container">
              <span
                *ngFor="let error of bup.errors"
                [title]="error?.message"
                class="error-item"
              >
                {{ error?.message }}
              </span>
            </div>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </figure>
</div>
