import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class FlightNoValidators {
  static format: any =
    (): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const text = control.value || "";
      let isValid;
      const splittedText = text.split("-");
      if (splittedText.length) {
        splittedText.forEach((newText) => {
          if (
            /^([A-Za-z]{1,3}|[0-9A-Za-z]{1,2})\d{1,4}([A-Za-z]{1,2})?$/.test(
              newText,
            )
          ) {
            isValid = true;
          } else {
            isValid = false;
          }
        });
      }

      return isValid || !text.length
        ? null
        : { "format": { "message": "{field} is not in correct format" } };
    };
}
