import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  PermissionNameEnum,
  RateWeightTypeEnum,
  UserService,
  UserTypeEnum,
} from "@cai-services";
import {
  CopyDetailsTemplate,
  CopyRateDetailTemplate,
  CopyRouteDetailTemplate,
} from "../../constant/copy-template-popup.const";
import {
  EmailDetailsTemplate,
  EmailRateDetailTemplate,
  EmailRouteDetailTemplate,
} from "../../constant/email-template.const";
import { TimeFormatPipe } from "../../core/_base/layout/pipes/time-format.pipe";
import {
  WeightTypeDisplay,
  WeightTypeFrench,
} from "../../core/_enums/weight-type-display-enum";
import { Aircraft } from "../../core/_models/aircraft.model";
import { PopupFlightDetail } from "../../core/_models/flight-detail-popup.model";
import {
  CustomFlightDetails,
  FlightInput,
  Leg,
} from "../../core/_models/flight-input.model";
import { FlightRate } from "../../core/_models/flight-rate.model";
import { UserMeasurementSystem } from "../../core/_models/measurement-system.model";
import { SearchTemplate } from "../../core/_models/search-template.model";
import {
  TemplateDetails,
  TemplateRateDetails,
  TemplateRouteDetails,
} from "../../core/_models/template-details.model";
import { SessionService } from "../../core/_services/session.service";
import { FlightDetailActionsEnum } from "../../enum/flight-details-actions.enum";
import {
  getDifferenceInDays,
  getDuration,
  getStringDate,
} from "../../helpers/date-helpers";
import { getDefaultAircraft } from "../../helpers/search_helpers";
import { CaiNumberFormatterPipe } from "../../pipes/number-formatter.pipe";
import {
  User,
} from "../../core/_models/user.model";
import { SearchResultUtil } from "../../utils";

@Component({
  "selector": "kt-flight-detail-popup",
  "templateUrl": "./flight-detail-popup.component.html",
  "styleUrls": ["./flight-detail-popup.component.scss"],
})
export class FlightDetailPopupComponent implements OnInit, OnChanges {
  @Input() searchTemplate: SearchTemplate;
  @Input() flightDetails: any;
  @Input() aircraftList: Aircraft[] = [];
  @Input() measurementUnit: UserMeasurementSystem;
  @Input() isShipperQuote: boolean;
  @Input() isEnableQuoteRequest: boolean;
  @Output() onBookClicked = new EventEmitter<{ payload: FlightInput }>();
  @Output() onRequestBookClicked = new EventEmitter<{
    payload: FlightInput;
  }>();
  @Output() onSelectRate = new EventEmitter<{
    flight: CustomFlightDetails;
    rate: FlightRate;
  }>();

  currentUser: User;
  flightDetailActionsEnum = FlightDetailActionsEnum;
  hoveredAction: FlightDetailActionsEnum;
  doneAction: FlightDetailActionsEnum;
  popupDetails = {} as PopupFlightDetail;
  currency: string;
  flightGap: string;
  selectedRateIndex = 0;
  canCreateBooking: boolean;
  bookDisabledReason = "";

  constructor (
    private readonly sessionService: SessionService,
    private readonly userService: UserService,
    private readonly numberFormatter: CaiNumberFormatterPipe,
    private readonly timeFormatPipe: TimeFormatPipe,
  ) {}

  async ngOnInit (): Promise<void> {
    this.currentUser = this.sessionService.getCurrentUser();
    let selectedOffice = this.sessionService.getSelectedOffice();
    if (!selectedOffice) {
      await this.sessionService.initSelectedOffice();
      selectedOffice = this.sessionService.getSelectedOffice();
    }
    this.currency = this.currentUser?.defaultCurrency;
    this.canCreateBooking =
      this.userService.hasPermission(
        this.currentUser,
        selectedOffice?.id,
        PermissionNameEnum.CREATE_BOOKING,
      ) || this.isInterlineEnabled;
    if (!this.canCreateBooking) {
      this.bookDisabledReason = $localize`:@@global.booking-unauthorized:Booking permissions \nare not authorized \nfor your role.`;
    }
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.hasOwnProperty("flightDetails")) {
      if (this.flightDetails) {
        this.popupDetails = this.parsePopupDetails(this.flightDetails);
        this.getFlightGap();
      }
    }
  }

  onActionClick (action: FlightDetailActionsEnum) {
    this.doneAction = action;
    this.hoveredAction = null;
    if (action === FlightDetailActionsEnum.EMAIL_DETAILS) {
      const emailBody = this.prepareTemplateDetails(
        action,
        EmailRouteDetailTemplate,
        EmailRateDetailTemplate,
      );
      this.sendViaEmail(emailBody);
    } else if (action === FlightDetailActionsEnum.COPY_DETAILS) {
      const copyBody = this.prepareTemplateDetails(
        action,
        CopyRouteDetailTemplate,
        CopyRateDetailTemplate,
      );
      this.copyToClipboard(copyBody);
    }
  }

  handleRateChange (index: number): void {
    this.selectedRateIndex = index;
    this.onSelectRate.emit({
      "rate": this.flightDetails?.value?.rates?.[index],
      "flight": this.flightDetails.value,
    });
  }

  prepareTemplateDetails (
    action: FlightDetailActionsEnum,
    routeDetailTemplate: string,
    rateDetailTemplate: string,
  ): TemplateDetails {
    const origin = this.searchTemplate.origin,
     destination = this.searchTemplate.destination,
     commodityCode = this.searchTemplate.commodities.find(
      (commodity) => commodity.level === 1,
    )?.specialHandlingCodes[0]?.shcCode,
     chargeableWeight = `${this.numberFormatter.transform(
      this.searchTemplate.chargeableWeight,
      false,
      2,
    )} ${this.measurementUnit.WEIGHT.display}`,
     co2 = `${this.popupDetails.co2}${this.measurementUnit.WEIGHT.display}`,
     [hour, hourLabel, minute, minuteLabel] =
      this.popupDetails.flightDuration.split(" "),
     duration = `${hour}${hourLabel} ${minute ? minute : ""}${
      minuteLabel ? minuteLabel : ""
    }`,
     airlineName =
      this.flightDetails.value.airlineCompany.airlineCompanyName,
     routeDetailsString = this.popupDetails.routes
      .map((route) => {
        const routeDetailsObj = this.prepareRouteDetails(route);
        let routeDetailsText = routeDetailTemplate;
        Object.keys(routeDetailsObj).forEach((key) => {
          let value = routeDetailsObj[key];
          if (
            action === FlightDetailActionsEnum.COPY_DETAILS &&
            ((key === "origin" && value === origin) ||
              (key === "destination" && value === destination))
          ) {
            value = `<b>${value}</b>`;
          }
          routeDetailsText = routeDetailsText.replace(`{${key}}`, value);
        });
        return routeDetailsText;
      })
      .join("");
    let rateDetailsString = "";
    if (this.popupDetails.selectedRate) {
      rateDetailsString = rateDetailTemplate;
      const rateDetailsObj = this.prepareRateDetails(
        this.popupDetails.selectedRate,
      );
      Object.keys(rateDetailsObj).forEach((key) => {
        rateDetailsString = rateDetailsString.replace(
          `{${key}}`,
          rateDetailsObj[key],
        );
      });
    }
    return {
      chargeableWeight,
      commodityCode,
      origin,
      destination,
      airlineName,
      duration,
      "noOfStops": this.popupDetails.noOfStops.toString(),
      co2,
      "routeDetails": routeDetailsString,
      "rateDetails": rateDetailsString,
    };
  }

  prepareRouteDetails (route): TemplateRouteDetails {
    return {
      "origin": route.departureAirport,
      "destination": route.arrivalAirport,
      "flightNo": route.flightNumber,
      "departureDateTime": `${this.formatDate(
        route.departureTime,
      )} ${this.timeFormatPipe.transform(route.departureTime)}`,
      "arrivalDateTime": `${this.formatDate(
        route.arrivalTime,
      )} ${this.timeFormatPipe.transform(route.arrivalTime)}`,
    };
  }

  prepareRateDetails (rate): TemplateRateDetails {
    const formatRate = (value) =>
      this.numberFormatter.transform(value, false, 2),
     totalNetRate = formatRate(
      rate.netRate * this.searchTemplate.chargeableWeight,
    ),
     surcharge = rate.charges
      ?.map(
        (charge) =>
          `${charge.label}: ${formatRate(charge.rate)} ${rate.currency}/${
            charge.type.includes("WEIGHT")
              ? this.measurementUnit.WEIGHT.display
              : charge.type
          }`,
      )
      .join(", "),
     totalSurcharge = formatRate(
      rate.charges
        ?.map((charge) => {
          if (charge.type === "CHARGEABLE_WEIGHT") {
            return this.searchTemplate.chargeableWeight * charge.rate;
          } else if (charge.type === "GROSS_WEIGHT") {
            return this.searchTemplate.totalWeight * charge.rate;
          } else if (charge.type === "PIECES") {
            return this.searchTemplate.searchItemTemplates.length * charge.rate;
          } else {
            return charge.rate;
          }
        })
        .reduce((a, b) => a + b, 0),
    );

    return {
      "allInRate": `${formatRate(rate.allInRate)} ${rate.currency}/${
        this.measurementUnit.WEIGHT.display
      }`,
      "allInCost": `${formatRate(rate.total)} ${rate.currency}`,
      "netRate": `${formatRate(rate.netRate)} ${rate.currency}/${
        this.measurementUnit.WEIGHT.display
      }`,
      "totalNetRate": `${totalNetRate} ${rate.currency}`,
      surcharge,
      "totalSurcharge": `${totalSurcharge} ${rate.currency}`,
    };
  }

  sendViaEmail (templateDetails: TemplateDetails): void {
    let emailBody = EmailDetailsTemplate;
    Object.keys(templateDetails).forEach((key) => {
      emailBody = emailBody.replace(`{${key}}`, templateDetails[key]);
    });
    document.location = `mailto:?body=${encodeURIComponent(emailBody)}`;
  }

  copyToClipboard (templateDetails: TemplateDetails): void {
    let clipboardData = CopyDetailsTemplate;
    Object.keys(templateDetails).forEach((key) => {
      clipboardData = clipboardData.replace(`{${key}}`, templateDetails[key]);
    });
    const container = document.createElement("div");
    container.innerHTML = clipboardData;
    container.style.pointerEvents = "none";
    container.style.position = "fixed";
    container.style.left = "0";
    container.style.top = "0";
    container.style.opacity = "0";
    container.style.color = "black";
    container.style.background = "white";
    container.style.fontFamily = "Arial";
    container.style.fontSize = "18px";

    document.body.appendChild(container);
    window.getSelection().removeAllRanges();
    const range = document.createRange();
    range.selectNode(container);
    window.getSelection().addRange(range);
    document.execCommand("copy");
    document?.body?.removeChild(container);
  }

  isActionHovered (action: FlightDetailActionsEnum) {
    return action === this.hoveredAction;
  }

  onActionMouseEnter (action: FlightDetailActionsEnum) {
    this.hoveredAction = action;
    this.doneAction = null;
  }

  onActionMouseLeave () {
    this.hoveredAction = null;
    this.doneAction = null;
  }

  formatChargeLabel (label: string) {
    return label.replace("_", " ");
  }

  formatCO2 (value: number): string {
    return Math.ceil(value).toFixed();
  }

  formatDate (datetime: string) {
    return getStringDate(datetime, "MMM DD, YYYY");
  }

  getAirCraft (path: Leg): Aircraft {
    const foundAircraft = this.aircraftList.find(
      (aircraft) => aircraft.aircraftCode === path.aircraftCode,
    );
    if (foundAircraft) {
      return foundAircraft;
    }
    return getDefaultAircraft();
  }

  getFlightGap () {
    const flightInput = this.flightDetails.value,
     dayDiff = getDifferenceInDays(
      flightInput.departureTime,
      flightInput.arrivalTime,
    );
    if (dayDiff > 0) {
      this.flightGap = `+${dayDiff}`;
    }
  }

  onBookClick () {
    this.onBookClicked.emit({ "payload": this.flightDetails.value });
  }

  onRequestBookClick () {
    this.onRequestBookClicked.emit({ "payload": this.flightDetails.value });
  }

  get showRateSection (): boolean {
    return (
      this.popupDetails.isInstantBooking ||
      this.popupDetails.isRequestBooking ||
      this.popupDetails.isNotAvailable
    );
  }

  parsePopupDetails (flight: any): PopupFlightDetail {
    const flightInput: FlightInput = flight.value;
    return {
      "airlineLogo": this.getAirlinesLogo(flight),
      "flightDuration": getDuration(
        flightInput.departureTime,
        flightInput.arrivalTime,
      ),
      "noOfStops": flightInput?.legs.length - 1,
      "co2": this.getCO2(flightInput?.legs),
      "routes": flightInput?.legs,
      "selectedRate": flight.selectedRate,
      "reason": flight.reason,
      "isInstantBooking": flight.isInstantBooking && !flight.isNotAvailable,
      "isRequestBooking":
        flight.isRequestBooking &&
        !flight.isNotAvailable &&
        this.searchTemplate?.office?.country?.countryCode ==
          this.searchTemplate?.originCountry,
      "isEquote": flight.isEquote && flightInput.features.mailQuotable !== false,
      "isNotAvailable": flight.isNotAvailable,
    };
  }

  getAirlinesLogo (flight: any): string[] {
    if (flight.value.legs.length > 1 && flight.value.features.isInterline) {
      const mainCarrierCode = flight.value.legs[0].flightNumber.replace(
        /[0-9]/g,
        "",
      )

      const interlineCarrierCode = flight.value.legs[1].flightNumber.replace(
        /[0-9]/g,
        "",
      );

      if (mainCarrierCode !== interlineCarrierCode) {
        const interlineLogo = "/static/airline-logos/" + interlineCarrierCode + ".png";
        return [flight.airlineLogo, interlineLogo];
      }
      return [flight.airlineLogo];
    } else {
      return [flight.airlineLogo];
    }
  }
  private getCO2 (legs: Leg[]): string {
    return legs
      .filter((path) => path?.co2)
      .map((path) => Math.ceil(path?.co2?.value))
      .reduce((sum, current) => sum + current, 0)
      .toFixed();
  }

  getWeightType (type: string): string {
    const isFrench = window.location.href?.indexOf("/fr/") >= 0;
    let weightTypeDisplay = `/${this.measurementUnit.WEIGHT.code}`;
    if (
      type === RateWeightTypeEnum.SHIPMENT ||
      type === RateWeightTypeEnum.PIECES
    ) {
      weightTypeDisplay = isFrench
        ? `/${WeightTypeFrench[type]}`
        : `/${WeightTypeDisplay[type]}`;
    }
    return weightTypeDisplay;
  }

  getFormattedRateName (selectedRate): string {
    return SearchResultUtil.getFormattedRateName(selectedRate);
  }

  get isUserAirline () {
    return this.sessionService.getCurrentUser()?.type === UserTypeEnum.AIRLINE;
  }

  get isInterlineEnabled (): boolean {
    return !!this.currentUser?.activatedFeatures?.find((af)=>af.featureName==="INTERLINE");
  }
}
