<div #wrapper class="container">
  <div class="search-field kt-margin-b-20-tablet-and-mobile kt-input-icon">
    <div class="kt-form__group kt-form__group--inline d-flex flex-column">
      <input
        #input
        type="text"
        class="form-control"
        [placeholder]="label"
        [(ngModel)]="display"
        (blur)="blur(input.value)"
        [disabled]="viewOnly ? true : null"
        (keyup)="keyup($event, dropdown, wrapper)"
        (focus)="open(dropdown, wrapper, showAllOnFocus)"
        [class.text-center]="textCenter"
        [class.text-uppercase]="uppercase"
        [class.float-label]="floatLabel"
      />
    </div>
    <span class="kt-input-icon__icon">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.2649 13.9301L11.1429 9.84006C11.9734 8.67147 12.4041 7.19536 12.1888 5.62698C11.8196 2.98228 9.66632 0.891118 6.99006 0.645099C3.36019 0.276071 0.284031 3.35131 0.65317 7.01084C0.899263 9.65555 2.99105 11.839 5.63655 12.208C7.20539 12.4233 8.68195 11.9927 9.85089 11.1624L13.9422 15.2525C14.1268 15.437 14.4036 15.437 14.5882 15.2525L15.2342 14.6067C15.4187 14.4222 15.4187 14.1146 15.2649 13.9301ZM2.46809 6.42685C2.46809 4.24343 4.25227 2.4598 6.43634 2.4598C8.62042 2.4598 10.4046 4.24343 10.4046 6.42685C10.4046 8.61027 8.62042 10.3939 6.43634 10.3939C4.25227 10.3939 2.46809 8.64103 2.46809 6.42685Z"
          fill="#53A8E7"
        />
      </svg>
    </span>
  </div>
  <ng-template #dropdown>
    <div
      class="search-dropdown"
      [ngClass]="customClass"
      [style.min-width.px]="minWidth"
      [style.max-width.px]="maxWidth"
      style="overflow: hidden !important"
      [class.d-none]="!filteredOptions?.length"
    >
      <ng-container *ngIf="!showNoResults" style="overflow: hidden !important">
        <cdk-virtual-scroll-viewport
          class="search-dropdown-inner"
          itemSize="25"
          [style.height.px]="calculateDropdownHeightPx()"
          style="overflow-x: hidden !important"
        >
          <div
            *cdkVirtualFor="let option of filteredOptions; let i = index"
            class="multiselect-dropdown-item"
          >
            <div
              class="select-item"
              caiTooltip
              class="item"
              [class.d-none]="shouldHide(i)"
              [tooltip]="option.label"
              placement="bottom-start"
              [class.active]="selected === option"
              (mousedown)="select(option); $event.preventDefault()"
            >
              <span [innerHtml]="getHighlightedText(option.label)"></span>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </ng-container>
      <div *ngIf="showNoResults && !allowManualInput" class="no-results">
        <div i18n="@@global.no-results-found">NO RESULTS FOUND</div>
        <div *ngIf="noResultsText">{{ noResultsText }}</div>
      </div>
    </div>
  </ng-template>
</div>
