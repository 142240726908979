import { Component, Input } from "@angular/core";

@Component({
  "selector": "cai-success-message",
  "templateUrl": "./success-message.component.html",
  "styleUrls": ["./success-message.component.scss"],
})
export class CaiSuccessMessageComponent {
  @Input() isShow: boolean;
  @Input() title: string;
  @Input() message: string;
  @Input() otherMessage: any;
  @Input() isAdvancedBookings: boolean;
}
