<ul class="advanced-load-type-switch">
  <li
    [attr.data-before]="loadTypeEnum.TOTAL"
    [class.active]="isSelected(loadTypeEnum.TOTAL)"
    (click)="select(loadTypeEnum.TOTAL)"
  >
    {{ loadTypeEnum.TOTAL }}
  </li>
  <li
    [attr.data-before]="loadTypeEnum.DIMENSIONS"
    [class.active]="isSelected(loadTypeEnum.DIMENSIONS)"
    [class.toggleable]="enableToggle"
    (click)="select(loadTypeEnum.DIMENSIONS)"
  >
    {{ loadTypeEnum.DIMENSIONS }}
    <span
      *ngIf="enableToggle"
      class="toggle-icon"
      (click)="toggle()"
      [class.collapsed]="collapsed"
      ><ng-container [ngTemplateOutlet]="chevronIcon"></ng-container
    ></span>
  </li>
  <li
    [attr.data-before]="loadTypeEnum.BUP"
    [class.active]="isSelected(loadTypeEnum.BUP)"
    [class.toggleable]="enableToggle"
    (click)="select(loadTypeEnum.BUP)"
  >
    {{ loadTypeEnum.BUP }}
    <span
      *ngIf="enableToggle"
      class="toggle-icon"
      (click)="toggle()"
      [class.collapsed]="collapsed"
      ><ng-container [ngTemplateOutlet]="chevronIcon"></ng-container
    ></span>
  </li>
</ul>

<ng-template #chevronIcon>
  <svg
    width="22"
    height="15"
    viewBox="0 0 22 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.75591 4.3804C7.41367 4.14706 6.94708 4.23534 6.71374 4.57757C6.48039 4.9198 6.56867 5.3864 6.9109 5.61974L10.5776 8.11974C10.8324 8.29352 11.1677 8.29352 11.4226 8.11974L15.0892 5.61974C15.4315 5.3864 15.5198 4.9198 15.2864 4.57757C15.0531 4.23534 14.5865 4.14706 14.2442 4.3804L11.0001 6.59233L7.75591 4.3804ZM5.92258 6.8804C5.58034 6.64706 5.11374 6.73534 4.8804 7.07757C4.64706 7.4198 4.73534 7.8864 5.07757 8.11974L10.4649 11.7929C10.7877 12.013 11.2124 12.013 11.5352 11.7929L16.9226 8.11974C17.2648 7.8864 17.3531 7.4198 17.1197 7.07757C16.8864 6.73534 16.4198 6.64706 16.0776 6.8804L11.0001 10.3423L5.92258 6.8804Z"
      fill="white"
    />
  </svg>
</ng-template>
