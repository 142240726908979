<div class="modal-header">
  <h3>
    {{ editUserForm.controls["firstName"]?.value }}
    {{ editUserForm.controls["lastName"]?.value }}
  </h3>
  <button class="close" (click)="modalRef.dismiss()"></button>
</div>
<div class="d-flex">
  <div
    ngbNav
    #editUserNav="ngbNav"
    [(activeId)]="activeTab"
    class="left-nav"
    orientation="vertical"
    role="tablist"
  >
    <ng-container ngbNavItem="general" *ngIf="!isWalletApp">
      <button
        role="tab"
        [attr.aria-selected]="activeTab === 'general'"
        (click)="activeTab = 'general'"
        ngbNav
        i18n="@@global.general"
      >
        General
      </button>
      <ng-template ngbNavContent>
        <form
          class="office-settings-modal-form container office-settings-form"
          [formGroup]="editUserForm"
          (ngSubmit)="updateUserInformation()"
        >
          <div class="row">
            <mat-form-field appearance="outline" class="col-sm">
              <mat-label i18n="@@global.first-name">First Name</mat-label>
              <input
                type="text"
                class="input-field"
                aria-label="First Name"
                matInput
                placeholder="First Name"
                formControlName="firstName"
              />
              <mat-error *ngIf="generalFormHasError('firstName', 'required')">
                <strong i18n="@@global.first-name-required-field">
                  First name is required
                </strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-sm">
              <mat-label i18n="@@global.last-name">Last Name</mat-label>
              <input
                type="text"
                class="input-field"
                aria-label="Last Name"
                matInput
                placeholder="Last Name"
                formControlName="lastName"
              />
              <mat-error *ngIf="generalFormHasError('lastName', 'required')">
                <strong i18n="@@global.last-name-required-field">
                  Last name is required
                </strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-sm">
              <mat-label i18n="@@global.number">Number</mat-label>
              <input
                type="text"
                class="input-field"
                aria-label="Number"
                matInput
                placeholder="Number"
                formControlName="number"
              />
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field appearance="outline" class="col-sm">
              <mat-label i18n="@@global.email">Email</mat-label>
              <input
                type="text"
                class="input-field"
                aria-label="Email"
                matInput
                placeholder="Email"
                formControlName="email"
              />
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-sm">
              <mat-label i18n="@@global.country">Country</mat-label>
              <input
                type="text"
                class="input-field"
                aria-label="Country"
                matInput
                placeholder="Country"
                formControlName="country"
              />
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-sm">
              <mat-label i18n="@@office-setting.airport">Airport</mat-label>
              <mat-select
                formControlName="originAirport"
                panelClass="office-settings-panel"
              >
                <mat-option
                  *ngFor="let airport of airportsOfCountry"
                  [value]="airport.airportCode"
                  class="office-settings-select-option"
                >
                  {{ airport.airportName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="d-flex justify-content-center w-100" style="gap: 10px">
            <button
              class="btn btn-secondary"
              type="button"
              (click)="modalRef.dismiss()"
              i18n="@@global.cancel"
            >
              Cancel
            </button>
            <button
              class="btn btn-primary"
              type="submit"
              i18n="@@global.confirm"
              [disabled]="editUserForm.invalid"
            >
              Confirm
            </button>
          </div>
        </form>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem="roles-and-permissions">
      <button
        role="tab"
        [attr.aria-selected]="activeTab === 'roles-and-permissions'"
        (click)="activeTab = 'roles-and-permissions'"
        ngbNav
        i18n="@@office-setting.roles-and-permissions"
      >
        Roles and Permissions
      </button>
      <ng-template ngbNavContent>
        <form
          class="edit-user-roles-form office-settings-form"
          [formGroup]="editUserRolesForm"
          (ngSubmit)="updateUserRoles()"
        >
          <div class="subheader">
            <mat-form-field appearance="outline">
              <mat-label i18n="@@office-setting.roles">Roles</mat-label>
              <mat-select
                formControlName="roles"
                multiple
                panelClass="office-settings-panel"
              >
                <mat-option
                  *ngFor="let role of relevantRoles"
                  [value]="role"
                  class="office-settings-select-option"
                >
                  {{ role }}
                </mat-option>
              </mat-select>

              <img
                *ngIf="displayProTag"
                class="protag-img"
                src="/assets/media/icons/pro-tag.svg"
                alt=""
              />

              <mat-error *ngIf="rolesFormHasError('roles', 'minItems')">
                <strong
                  i18n="@@office-setting.at-least-one-role-required-field"
                >
                  At least one role is required
                </strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="permission-groups">
            <div
              *ngFor="let permissionGroup of permissionGroups"
              class="permission-group"
              [class.two-columns]="permissionGroup.name === 'Bookings'"
            >
              <h4>{{ permissionGroup.name }}</h4>
              <div class="permissions">
                <div *ngFor="let permission of permissionGroup.permissions">
                  <mat-checkbox
                    [checked]="permissionDict[permission]"
                    disabled
                  ></mat-checkbox>
                  <mat-label i18n="@@office-setting.roles">{{
                    permission
                  }}</mat-label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-content-center w-100"
            style="gap: 10px; padding: 20px 26px"
          >
            <button
              class="btn btn-secondary"
              type="button"
              (click)="modalRef.dismiss()"
              i18n="@@global.cancel"
            >
              Cancel
            </button>
            <button
              class="btn btn-primary"
              type="submit"
              i18n="@@global.confirm"
              [disabled]="editUserRolesForm.invalid"
            >
              Confirm
            </button>
          </div>
        </form>
      </ng-template>
    </ng-container>
  </div>
  <div
    [ngbNavOutlet]="editUserNav"
    class="main-container mh-100 flex-grow-1"
  ></div>
</div>
