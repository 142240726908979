import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import { CommodityResultTypeEnum } from "../../enum/commodity-result-type.enum";
import { ICommoditySearchResult } from "../../model/commodity-result.model";
import { ICommodity } from "../../model/commodity.model";
import { ErrorUtil } from "../../utils/error.util";
import { API_CORE } from "../../constant/api-core.const";

@Injectable({
  "providedIn": "root",
})
export class CommodityService {
  async searchCommodities (
    query: string,
    type?: CommodityResultTypeEnum
  ): Promise<ICommoditySearchResult[]> {
    const extraOptions: any = {
      "queryStringParameters": {
        "input": query ? query : "",
      },
    };
    if (type) {
      extraOptions.queryStringParameters.type = type;
    }
    return API.get(
      API_CORE.name,
      API_CORE.URLs.Commodity.Search,
      extraOptions
    ).catch(ErrorUtil.handleError);
  }

  async getCommoditiesByLevel (
    level: number,
    requirement: boolean
  ): Promise<ICommodity[]> {
    const queryStringParams = {
      level,
      requirement,
    };
    return this.getCommodities(queryStringParams);
  }

  async getCommodityParent (
    commodity: ICommodity,
    commodities: ICommodity[]
  ): Promise<ICommodity> {
    const findParentFromList = (list) => list.find((parentComm) => (parentComm.childrens || []).filter((child) => child.id === commodity.id).length);

    let commodityParent = commodity.parent;
    if (commodity.parent) {
      const detailedParent = commodities.find(
        (parentComm) => parentComm.id === commodity.parent.id
      );
      if (detailedParent) {
        commodityParent = detailedParent;
      }
    }
    if (!commodityParent) {
      commodityParent = findParentFromList(commodities);
    }
    if (!commodityParent && commodity.level > 1) {
      const parents = await this.getCommoditiesByLevel(
        commodity.level - 1,
        false
      );
      commodityParent = findParentFromList(parents);
    }
    if (commodityParent) {
      return {
        ...commodity,
        "parent":
          commodity.level > 1
            ? await this.getCommodityParent(commodityParent, commodities)
            : null,
      };
    }
    return {
      ...commodity,
      "parent": null,
    };
  }

  async getCommoditiesByParentId (parentId: number): Promise<ICommodity[]> {
    const queryStringParams = {
      "parent": parentId,
    };
    return this.getCommodities(queryStringParams);
  }

  private async getCommodities (queryStringParams: any): Promise<ICommodity[]> {
    return API.get(API_CORE.name, API_CORE.URLs.Commodity.Commodities, {
      "queryStringParameters": queryStringParams,
    }).catch(ErrorUtil.handleError);
  }
}
