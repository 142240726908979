<div class="temp-full">
  <div class="temp-full__body">
    <div class="question">
      <span>{{ question }}</span>
      <div class="prompt" *ngIf="goodsType?.name === 'Perishable'">
        <svg
          class="align-self-start"
          width="16"
          height="16"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.47596 1.5C5.07096 1.5 1.5 5.07096 1.5 9.47596C1.5 13.881 5.07096 17.4519 9.47596 17.4519C13.881 17.4519 17.4519 13.881 17.4519 9.47596C17.4519 5.07096 13.881 1.5 9.47596 1.5ZM0 9.47596C0 4.24253 4.24253 0 9.47596 0C14.7094 0 18.9519 4.24253 18.9519 9.47596C18.9519 14.7094 14.7094 18.9519 9.47596 18.9519C4.24253 18.9519 0 14.7094 0 9.47596ZM9.47595 4.69775C9.97301 4.69775 10.376 5.10069 10.376 5.59775V10.4455C10.376 10.9426 9.97301 11.3455 9.47595 11.3455C8.9789 11.3455 8.57595 10.9426 8.57595 10.4455V5.59775C8.57595 5.10069 8.9789 4.69775 9.47595 4.69775ZM9.65403 14.9965C10.1786 14.9965 10.6038 14.5712 10.6038 14.0467C10.6038 13.5221 10.1786 13.0969 9.65403 13.0969C9.12949 13.0969 8.70427 13.5221 8.70427 14.0467C8.70427 14.5712 9.12949 14.9965 9.65403 14.9965Z"
            fill="currentColor"
          />
        </svg>
        <span i18n="@@global.select-right-temperature">
          Please select the right temperature requirements if applicable
        </span>
      </div>
    </div>

    <div class="options">
      <div *ngFor="let option of options">
        <ng-container
          *ngTemplateOutlet="
            checkboxTpl;
            context: {
              option: option
            }
          "
        ></ng-container>
      </div>
    </div>
  </div>
</div>

<div class="not-required" *ngIf="selectedAnswer === notRequired">
  <span>
    <img src="assets/media/icons/info.svg" alt="info icon" />
  </span>
  <span i18n="@@global.temperature-control-not-required"
    >Temperature control not required</span
  >
</div>

<ng-template #checkboxTpl let-option="option">
  <div
    class="custom-checkbox"
    [id]="option.value"
    [class.selected]="selectedAnswer === option"
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      (click)="selectAnswer(option)"
    >
      <ng-container *ngIf="selectedAnswer === option">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.43597 5.18404C3 6.03969 3 7.15979 3 9.4V14.6C3 16.8402 3 17.9603 3.43597 18.816C3.81947 19.5686 4.43139 20.1805 5.18404 20.564C6.03969 21 7.15979 21 9.4 21H14.6C16.8402 21 17.9603 21 18.816 20.564C19.5686 20.1805 20.1805 19.5686 20.564 18.816C21 17.9603 21 16.8402 21 14.6V9.4C21 7.92364 21 6.93376 20.8752 6.18545L22.5303 4.53033C22.8232 4.23744 22.8232 3.76256 22.5303 3.46967C22.2374 3.17678 21.7626 3.17678 21.4697 3.46967L20.2588 4.68051C19.8868 4.15784 19.3927 3.72984 18.816 3.43597C17.9603 3 16.8402 3 14.6 3H9.4C7.15979 3 6.03969 3 5.18404 3.43597C4.43139 3.81947 3.81947 4.43139 3.43597 5.18404ZM20.2588 4.68051L12 12.9393L9.53033 10.4697C9.23744 10.1768 8.76256 10.1768 8.46967 10.4697C8.17678 10.7626 8.17678 11.2374 8.46967 11.5303L11.4697 14.5303C11.6103 14.671 11.8011 14.75 12 14.75C12.1989 14.75 12.3897 14.671 12.5303 14.5303L20.8752 6.18545C20.8106 5.79829 20.7127 5.47579 20.564 5.18404C20.4744 5.00813 20.3723 4.83992 20.2588 4.68051Z"
          fill="#4d578d"
        ></path>
      </ng-container>
      <ng-container *ngIf="selectedAnswer !== option">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.367 2.25L9.4 2.25H14.6L14.633 2.25C15.7251 2.24999 16.5906 2.24999 17.2883 2.30699C18.0017 2.36527 18.6053 2.48688 19.1565 2.76772C20.0502 3.22312 20.7769 3.94978 21.2323 4.84355C21.5131 5.39472 21.6347 5.99835 21.693 6.71173C21.75 7.40935 21.75 8.27484 21.75 9.36698V9.4V14.6V14.633C21.75 15.7252 21.75 16.5906 21.693 17.2883C21.6347 18.0017 21.5131 18.6053 21.2323 19.1565C20.7769 20.0502 20.0502 20.7769 19.1565 21.2323C18.6053 21.5131 18.0017 21.6347 17.2883 21.693C16.5906 21.75 15.7252 21.75 14.633 21.75H14.6H9.4H9.36698C8.27484 21.75 7.40935 21.75 6.71173 21.693C5.99834 21.6347 5.39472 21.5131 4.84355 21.2323C3.94978 20.7769 3.22312 20.0502 2.76772 19.1565C2.48688 18.6053 2.36527 18.0017 2.30699 17.2883C2.24999 16.5906 2.24999 15.7251 2.25 14.633L2.25 14.6V9.4L2.25 9.367C2.24999 8.27485 2.24999 7.40936 2.30699 6.71173C2.36527 5.99834 2.48688 5.39472 2.76772 4.84355C3.22312 3.94978 3.94978 3.22312 4.84355 2.76772C5.39472 2.48688 5.99835 2.36527 6.71173 2.30699C7.40936 2.24999 8.27486 2.24999 9.367 2.25ZM6.83388 3.80201C6.21325 3.85271 5.829 3.94909 5.52453 4.10423C4.913 4.41582 4.41582 4.913 4.10423 5.52453C3.94909 5.829 3.85271 6.21325 3.80201 6.83388C3.75058 7.46326 3.75 8.26752 3.75 9.4V14.6C3.75 15.7325 3.75058 16.5367 3.80201 17.1661C3.85271 17.7867 3.94909 18.171 4.10423 18.4755C4.41582 19.087 4.913 19.5842 5.52453 19.8958C5.829 20.0509 6.21325 20.1473 6.83388 20.198C7.46326 20.2494 8.26752 20.25 9.4 20.25H14.6C15.7325 20.25 16.5367 20.2494 17.1661 20.198C17.7867 20.1473 18.171 20.0509 18.4755 19.8958C19.087 19.5842 19.5842 19.087 19.8958 18.4755C20.0509 18.171 20.1473 17.7867 20.198 17.1661C20.2494 16.5367 20.25 15.7325 20.25 14.6V9.4C20.25 8.26752 20.2494 7.46326 20.198 6.83388C20.1473 6.21325 20.0509 5.829 19.8958 5.52453C19.5842 4.913 19.087 4.41582 18.4755 4.10423C18.171 3.94909 17.7867 3.85271 17.1661 3.80201C16.5367 3.75058 15.7325 3.75 14.6 3.75H9.4C8.26752 3.75 7.46326 3.75058 6.83388 3.80201Z"
          fill="#dadada"
        />
      </ng-container>
    </svg>
    <div>
      <ng-container
        *ngFor="let label of getLabelArray(option.label); let i = index"
      >
        <span *ngIf="i > 0">to</span>
        <span class="label">{{ label }}</span>
      </ng-container>
    </div>
    <span class="description" *ngIf="selectedAnswer === option">{{
      option.description
    }}</span>
  </div>
</ng-template>
