// Angular
import { Pipe, PipeTransform } from "@angular/core";

import moment from "moment";
import { getStringDate } from "../../../../helpers/date-helpers";

@Pipe({
  "name": "dayDiffer",
})
export class DayDiffer implements PipeTransform {
  transform (
    firstDate: string | Date,
    lastDate: string | Date,
    firstDelay?: string,
    lastDelay?: string,
    plusDay?: number
  ): number {
    let newFirstDate = firstDate,
     newLastDate = lastDate;
    if (firstDelay) {
      newFirstDate = moment(newFirstDate).add(firstDelay, "ms").toISOString();
    }
    if (lastDelay) {
      newLastDate = moment(newLastDate).add(lastDelay, "ms").toISOString();
    }
    const firstDayStr = getStringDate(newFirstDate),
     lastDayStr = getStringDate(newLastDate);

    let result = moment(lastDayStr).diff(moment(firstDayStr), "days");
    if (plusDay) {
      result = result + plusDay;
    }
    return result;
  }
}
