<!--begin::Form-->
<form class="form personal-settings-form" [formGroup]="preferenceForm" (ngSubmit)="onSubmit()">
  <!--begin::Body-->
  <div class="row">
    <div class="col-lg-12">
      <div>
        <h2 i18n="@@profile.language">Language</h2>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="form-group col-8">
              <label for="currency" required i18n="@@global.language"
                >Language</label
              >
              <mat-select
                id="language"
                placeholder="Select Language"
                formControlName="language"
                class="form-control form-control-solid"
                panelClass="language-selector-panel"
                [disableOptionCentering]="true"
              >
                <mat-select-trigger>
                  <kt-language-option
                    [verbose]="true"
                    [language]="getLanguage(language?.value)"
                  ></kt-language-option>
                </mat-select-trigger>
                <ng-container *ngFor="let language of languages">
                  <mat-option [value]="language.locale">
                    <kt-language-option
                      [verbose]="true"
                      [language]="language"
                    ></kt-language-option>
                  </mat-option>
                </ng-container>
              </mat-select>
            </div>
          </div>
          <div class="card-text d-flex flex-row">
            <img src="/assets/media/icons/info.svg" alt="info icon" />
            <div i18n="@@settings.text-language-change">
              This will be applied to your account only. You will receive all
              notifications in your selected language
            </div>
          </div>
        </div>
      </div>

      <div>
        <h2 i18n="@@profile.account-settings">Account Settings</h2>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="form-group col-8">
              <label for="currency" required i18n="@@global.currency"
                >Currency</label
              >
              <input
                id="currency"
                formControlName="currency"
                type="text"
                matInput
                placeholder="Choose your preferred currency"
                class="form-control form-control-solid"
                [matAutocomplete]="currencyAutoComplete"
              />

              <mat-autocomplete #currencyAutoComplete="matAutocomplete">
                <mat-option
                  *ngFor="
                    let currency of filteredCurrencies | async | slice: 0 : 100
                  "
                  [value]="currency.currencyCode"
                >
                  <div>
                    {{ currency.currencyCode }}
                  </div>
                </mat-option>
              </mat-autocomplete>

              <mat-error
                *ngIf="
                  isControlHasError(preferenceForm, 'currency', 'mustBeInList')
                "
                i18n="@@registration.complete.text-invalid-currency"
              >
                Please select a valid currency
              </mat-error>
            </div>
          </div>
          <div class="card-text d-flex flex-row">
            <img src="/assets/media/icons/info.svg" alt="info icon" />
            <div i18n="@@settings.text-currency-change">
              It will be used for default currency
            </div>
          </div>

          <div class="row mt-4">
            <div class="form-group col-8">
              <label
                for="measurement-system"
                required
                i18n="@@global.measurement-system"
                >Measurement system</label
              >
              <mat-select
                id="measurement-system"
                placeholder="Select Type"
                formControlName="measurementSystem"
                class="form-control form-control-solid"
              >
                <mat-option
                  value="{{ measurementSystemEnum.METRIC }}"
                  i18n="@@global.metric"
                >
                  Metric (kg, m, ..)
                </mat-option>
                <mat-option
                  value="{{ measurementSystemEnum.IMPERIAL }}"
                  i18n="@@global.imperial"
                >
                  Imperial (kg, ft, ..)
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="card-text d-flex flex-row">
            <img src="/assets/media/icons/info.svg" alt="info icon" />
            <div i18n="@@settings.text-measurement-change">
              It will be your default measurement, which you can change it from
              shipment details & other pages.
            </div>
          </div>

          <ng-container *ngIf="isForwarder">
            <div class="row mt-4">
              <div class="form-group col-8">
                <label
                  for="default-measurement-system"
                  required
                  i18n="@@settings.default-departure-date"
                  >Default Departure Date</label
                >
                <kt-settings-select
                  formControlName="defaultSearchDay"
                  [options]="searchDays"
                  [width]="172"
                >
                </kt-settings-select>
              </div>
            </div>
            <div class="card-text d-flex flex-row">
              <img src="/assets/media/icons/info.svg" alt="info icon" />
              <div i18n="@@settings.text-measurement-change">
                It will be your default depature date of your shipment. Based on
                this date your search results will be displayed.
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <button
        type="submit"
        [disabled]="!preferenceForm.dirty || !preferenceForm.valid"
        [ngClass]="{
          'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
            loading
        }"
        class="btn btn-save"
        i18n="@@global.save-changes"
      >
        Save Changes
      </button>
    </div>
  </div>
  <!--end::Body-->
</form>
<!--end::Form-->
