enum WidgetQuotesRequestEnum {
  ALL_NON_EXPIRED_QUOTES = "ALL_NON_EXPIRED_QUOTES",
  ACCEPTED_QUOTES = "ACCEPTED_QUOTES",
  OPTIONS = "OPTIONS",
  PENDING_QUOTES = "PENDING_QUOTES",
  BOOKING_REQUESTED_QUOTES = "BOOKING_REQUESTED_QUOTES",
  EXPIRED_QUOTES = "EXPIRED_QUOTES",
}

enum WidgetShipperQuotesRequestEnum {
  ALL_QUOTES = "ALL_QUOTES",
  READY_TO_BOOK_QUOTES = "READY_TO_BOOK_QUOTES",
  PENDING_QUOTES = "PENDING_QUOTES",
  ARCHIVED_QUOTES = "ARCHIVED_QUOTES",
}

enum WidgetQuotesEnum {
  ALL_BOOKING = "ALL_BOOKING",
  ALL_NON_EXPIRED_NON_CONFIRMED = "ALL_NON_EXPIRED_NON_CONFIRMED",
  PENDING_QUOTES = "PENDING_QUOTES",
  EXPIRED_QUOTES = "EXPIRED_QUOTES",
  BOOKING_REQUESTED = "BOOKING_REQUESTED",
  BOOKING_CONFIRMED = "BOOKING_CONFIRMED",
  IN_TRANSIT = "IN_TRANSIT",
  AT_DESTINATION = "AT_DESTINATION",
  NEED_RATING = "NEED_RATING",
  DELIVERED = "DELIVERED",
  PENDING_DELIVERY = "PENDING_DELIVERY",
}

export { WidgetQuotesRequestEnum, WidgetShipperQuotesRequestEnum, WidgetQuotesEnum };
