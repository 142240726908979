import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  Output,
  Component,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  Self,
  Optional,
  TemplateRef,
  ViewContainerRef,
  NgZone,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { filter, takeUntil, takeWhile } from "rxjs/operators";
import { fromEvent } from "rxjs";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import Popper from "popper.js";
import { cloneDeep } from "lodash";

@Component({
  "selector": "cai-autocomplete",
  "templateUrl": "./autocomplete.component.html",
  "styleUrls": ["./autocomplete.component.scss"],
  "changeDetection": ChangeDetectionStrategy.OnPush,
})
export class CaiAutocomplete
  implements OnInit, OnChanges, ControlValueAccessor
{
  @ViewChild("input") input: ElementRef;
  @Input() label: string;
  @Input() width: number;
  @Input() excludeValues: string[] = [];
  @Input() options: any[] = [];
  @Input() viewOnly: boolean;
  @Input() placeholder: string;
  @Input() disableState: boolean;
  @Input() hideLabel: boolean;
  @Input() isInvalid: string;
  @Input() isShipperForm: boolean;
  @Input() isGroupSelected = false;
  @Input() selectableGroup = false;
  @Input() dropdownMenuClass = "select-menu";
  @Output() onFocus = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onSelectItem = new EventEmitter<any>();
  @Output() onSelectGroup = new EventEmitter<any>();

  selected: any;
  text = "";
  filteredOptions: any[];
  groupedFilteredOptions: any[];

  _value: string;
  get value () {
    return this._value;
  }
  set value ($event: string) {
    this._value = $event;
    if (this.isGroupSelected) {
      const selectedGroup = this.options.find((option) => option.groupValue === this.value);
      this.text = selectedGroup?.group ?? "";
    } else if (this.value && this.options?.length) {
      this.selected = this.options.find((item) => item.value === this.value);
      this.text = this.selected
        ? this.selected?.display || this.selected.label
        : "";
      if (typeof this.onChange === "function") {
        this.onChange(this.value);
      }
    } else {
      this.selected = null;
      this.text = "";
    }
    this.cdr.detectChanges();
  }

  private touched: boolean;
  private view: EmbeddedViewRef<any>;
  private popperRef: Popper;

  onChange = (value: string) => {};
  onTouched = () => {};

  constructor (
    @Self()
    @Optional()
    public control: NgControl,
    private vcr: ViewContainerRef,
    private zone: NgZone,
    private cdr: ChangeDetectorRef
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit (): void {
    this.filteredOptions = cloneDeep(this.options);
    if (this.isShipperForm) {
      this.groupedFilteredOptions = this.groupFilteredOptions();
    }
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.hasOwnProperty("options")) {
      if (this.value) {
        this.selected = this.options?.find((item) => item.value === this.value);
        this.text = this.selected
          ? this.selected?.display || this.selected.label
          : "";

        this.cdr.detectChanges();
      }
      if (this.isShipperForm && this.filteredOptions) {
        this.filteredOptions = cloneDeep(this.options);
        this.groupedFilteredOptions = this.groupFilteredOptions();
      }
    }
  }

  public get invalid (): boolean {
    return this.control ? this.control.invalid : false;
  }

  public get showError (): boolean {
    if (!this.control) {
      return false;
    }
    const { dirty, touched } = this.control;
    return this.invalid ? dirty || touched : false;
  }

  public get errorMessage (): string {
    const error = Object.values(this.control.errors)[0].message || null;
    if (error) {
      return error.replace("{field}", this.label);
    }
    return null;
  }

  writeValue (value: string) {
    this.value = value;
  }

  registerOnChange (onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched (onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched () {
    if (!this.touched) {
      if (typeof this.onTouched === "function") {
        this.onTouched();
      }
      this.touched = true;
    }
  }

  setDisabledState (disabled: boolean) {
    this.disableState = disabled;
  }

  isOpen (): boolean {
    return !!this.popperRef;
  }

  onKeyUp ($event) {
    const key = $event.keyCode || $event.charCode;
    if (key === 9) {
      return;
    }
    this.markAsTouched();
  }

  blur (inputText: string) {
    if (this.isOpen()) {
      this.select(this.getFirstMatch(inputText));
    }
    this.markAsTouched();
    this.cdr.detectChanges();
  }

  private getFirstMatch (query: string): any {
    if (!query || this.filteredOptions.length === 0) {
      return null;
    }

    return this.filteredOptions[0];
  }

  handleFocus (event, dropdown, input) {
    this.onFocus.emit();
    if (this.isShipperForm) {
      this.open(event?.target?.value, dropdown, input);
    }
  }

  open (keyword: string, dropdownTpl: TemplateRef<any>, input: HTMLElement) {
    if (!!this.popperRef) {
      this.close();
    }
    this.search(keyword);
    if (this.filteredOptions.length) {
      this.view = this.vcr.createEmbeddedView(dropdownTpl);
      const dropdown = this.view.rootNodes[0];

      document.body.appendChild(dropdown);
      dropdown.style["min-width"] = `${input.offsetWidth}px`;
      dropdown.style.width = `${
        this.width ? this.width : input.offsetWidth
      }px`;
      dropdown.style["z-index"] = "9999";

      this.zone.runOutsideAngular(() => {
        this.popperRef = new Popper(input, dropdown, {
          "placement": "bottom-start",
          "removeOnDestroy": true,
        });
      });
      if (!this.isShipperForm) {
        this.handleClickOutside();
      }
    }
  }

  select (option: { value: any; display: any; label: any }) {
    this.value = option?.value ?? null;
    this.selectItem(option);
    this.cdr.detectChanges();
  }

  search (keyword: string) {
    if (!keyword && this.isShipperForm) {
      this.filteredOptions = cloneDeep(this.options);
      this.groupedFilteredOptions = this.groupFilteredOptions();
      return this.filteredOptions;
    }

    const exactMatches = this.options.filter(
      (item) =>
        item.label.toLowerCase() === keyword.toLowerCase() &&
        !this.excludeValues?.includes(item?.value)
    ),
     isDuplicateMatch = this.excludeValues?.includes(this.text);

    if (exactMatches?.length > 0) {
      this.filteredOptions = exactMatches;
      this.groupedFilteredOptions = this.groupFilteredOptions();
      this.cdr.detectChanges();
      return this.filteredOptions;
    }

    this.filteredOptions = this.options.filter((item) => {
      item.value = item.value.toString();
      const groupName: string = item.group;
      return (
        (item.value.indexOf(keyword?.toUpperCase()) > -1 ||
          item.label.indexOf(keyword?.toUpperCase()) > -1 ||
          item.uppercaseMatch?.toUpperCase() === keyword?.toUpperCase() ||
          item.anycaseMatch?.toLowerCase().startsWith(keyword?.toLowerCase()) ||
          (item.keywords &&
            this.containsAnyOfKeywords(keyword, item.keywords)) ||
          (groupName &&
            groupName.toUpperCase().indexOf(keyword?.toUpperCase()) > -1)) &&
        !this.excludeValues?.filter((v) => v).includes(item.value)
      );
    });
    if (isDuplicateMatch) {
      this.filteredOptions = [];
    }

    this.filteredOptions.sort((itemA, itemB) => {
      const indexItemA = itemA.label.indexOf(keyword?.toUpperCase()),
       indexItemB = itemB.label.indexOf(keyword?.toUpperCase());
      return indexItemB - indexItemA;
    });
    this.groupedFilteredOptions = this.groupFilteredOptions();
    this.cdr.detectChanges();
    return this.filteredOptions;
  }

  private groupFilteredOptions (): any[] {
    const groupedFilteredOptions = [];

    this.filteredOptions?.forEach((item) => {
      const groupName = item.group ?? "",
       groupWithSameName = groupedFilteredOptions.find(
        (group) => group.name === groupName
      );
      if (groupWithSameName === undefined) {
        const newGroup = {
          "name": groupName,
          "iconUrl": item.groupIconUrl,
          "value": item.groupValue,
          "options": [item],
        };
        groupedFilteredOptions.push(newGroup);
      } else {
        groupWithSameName.options.push(item);
      }
    });

    return groupedFilteredOptions;
  }

  private containsAnyOfKeywords (query: string, keywords: string[]) {
    return keywords.some((keyword) => query.includes(keyword));
  }

  private handleClickOutside () {
    fromEvent(document, "click")
      .pipe(
        takeWhile(() => this.isOpen()),
        filter(({ target }) => {
          const origin = this.popperRef.reference as HTMLElement;
          return origin.contains(target as HTMLElement) === false;
        }),
        takeUntil(this.onClose)
      )
      .subscribe(() => {
        this.close();
        this.cdr.detectChanges();
      });
  }

  private close () {
    this.onClose.emit();
    if (this.view) {
      this.view.destroy();
      this.view = null;
    }
    if (this.popperRef) {
      this.popperRef = null;
    }
  }

  calculateDropdownHeightPx (): number {
    const groupLabelCount = this.groupedFilteredOptions
      // group labels with no names aren't displayed
      .filter((group) => group.name?.length > 0).length,
     itemCount = this.filteredOptions.length;
    return (groupLabelCount + itemCount) * 25;
  }

  private selectItem (option: { value: any; display: any; label: any }): void {
    const label = option ? option?.display || option.label : "";
    this.selected = option;
    this.text = label;
    if (typeof this.onChange === "function") {
      this.onChange(this.value);
    }
    this.close();
    this.onSelectItem.emit(option);
  }

  selectGroup (group: any): void {
    if (!this.selectableGroup) {
      return;
    }
    this.text = group.name;
    if (typeof this.onChange === "function") {
      this.onChange(group.value);
    }
    this.close();
    this.onSelectGroup.emit(group);
  }
}
