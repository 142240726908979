// Angular
import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";
@Pipe({
  "name": "caiTimeDiffer",
})
export class CaiTimeDifferPipe implements PipeTransform {
  transform (startDate, endDate, dayDiff: number): number {
    const totalDayDiffInMinutes = 24 * 60 * dayDiff,
     diffInMinutes = moment(endDate).diff(moment(startDate), "minutes");
    return (diffInMinutes / totalDayDiffInMinutes) * 100;
  }
}
