import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

@Component({
  "selector": "icon-copy",
  "templateUrl": "./icon-copy.component.html",
  "styleUrls": ["./icon-copy.component.scss"],
  "changeDetection": ChangeDetectionStrategy.OnPush,
})
export class IconCopyComponent implements OnChanges {
  @Input() isHovered: boolean;
  @Input() copiedField: string;
  @Input() activeColor = "#4F8BB6";
  @Input() copyText: string = $localize`:@@global.copy:Copy`;
  @Input() field: string;

  hidden: boolean;

  constructor (private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges (changes: SimpleChanges) {
    if (changes.copiedField) {
      this.hidden = true;
      this.cdr.detectChanges();
      setTimeout(() => {
        this.hidden = false;
        this.cdr.detectChanges();
      });
    }
  }

  get isCopied (): boolean {
    return this.field && this.field === this.copiedField;
  }

  get copyTooltip () {
    if (this.isCopied) {
      return $localize`:@@global.copied:Copied`;
    }
    return this.copyText;
  }
}
