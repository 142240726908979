<!--begin::Form-->
<form class="form personal-settings-form" [formGroup]="aboutForm" (ngSubmit)="updateUserAbout()">
  <section>
    <h2 i18n="@@global.about-you" class="mb-4">About You</h2>
    <div class="row">
      <div class="form-group col-6">
        <label
          for="first-name"
          required
          i18n="@@global.first-name"
          class="text-capitalize"
          >First name</label
        >
        <input
          id="first-name"
          type="text"
          formControlName="firstName"
          class="form-control form-control-solid"
        />
      </div>
      <div class="form-group col-6">
        <label
          for="last-name"
          required
          i18n="@@global.last-name"
          class="text-capitalize"
          >Last name</label
        >
        <input
          id="last-name"
          type="text"
          formControlName="lastName"
          class="form-control form-control-solid"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-6">
        <label for="phone" required i18n="@@about-personal.phone">Phone</label>
        <input
          id="phone"
          class="form-control form-control-solid personal-phone-input"
          formControlName="phoneNumber"
          type="tel"
          numbersAndHyphenOnly
          [value]="
              aboutForm.get('phoneNumber').value | phoneFormatter: phoneMask
            "
          (change)="onPhoneChanged()"
        />
        <mat-error
          *ngIf="
              isControlHasError(aboutForm, 'phoneNumber', 'phoneInvalid')
            "
          i18n="@@registration.complete.text-invalid-phone"
          style="color: #e161a4"
        >
          Please enter a valid phone number
        </mat-error>
      </div>
      <div class="form-group col-6">
        <label for="email" i18n="@@about-personal.email">Email</label>
        <input
          id="email"
          class="form-control form-control-solid"
          formControlName="email"
          type="email"
        />
      </div>
    </div>
    <button
      type="submit"
      [ngClass]="{
      'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
        loading
    }"
      [disabled]="!aboutForm.dirty || !aboutForm.valid"
      class="btn btn-save"
      i18n="@@global.save-changes"
    >
      Save changes
    </button>
  </section>
  <section class="mb-5">
    <h2 class="mb-4" i18n="@@personal-settings.assigned-location">Assigned Location</h2>
    <div class="row">
      <div class="form-group col-6">
        <label
          for="country"
          i18n="@@global.country"
          class="text-capitalize"
        >
          Country
        </label>
        <input
          id="country"
          type="text"
          class="form-control form-control-solid"
          [value]="user?.country?.countryName"
          disabled
        />
      </div>
      <div class="form-group col-6">
        <label
          for="airport"
          i18n="@@global.airport"
          class="text-capitalize"
        >
          Airport
        </label>
        <input
          id="airport"
          type="text"
          class="form-control form-control-solid"
          [value]="user?.originAirport?.airportCode"
          disabled
        />
      </div>
    </div>
  </section>
</form>
