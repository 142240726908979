import { ICountry } from "@cai-services";
import { State } from "./state.model";
import { Currency } from "./currency.model";

export class Country implements ICountry {
  countryCode: string;
  countryName: string;
  states?: State[];
  localCurrency?: Currency;
  defaultCurrency?: Currency;

  constructor (countryCode?: string, countryName?: string,localCurrency?: Currency, defaultCurrency?: Currency ) {
    this.countryCode = countryCode;
    this.countryName = countryName;
    this.localCurrency = localCurrency;
    this.defaultCurrency = defaultCurrency;
  }
}
