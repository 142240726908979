<img
  style="width: 28px"
  [class.ml-2]="!verbose"
  src="/assets/media/flags/{{ language?.flag }}.png"
  alt="flag-{{ language?.flag }}"
/>
<span *ngIf="!verbose" class="ml-3">{{ language?.locale }}</span>
<span *ngIf="verbose" class="ml-3"
  >{{ language?.country }} - {{ language?.language }}</span
>
