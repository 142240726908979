import moment from 'moment';
import momentTz from 'moment-timezone';
import {
  DATE_FORMAT_WITHOUT_TIMEZONE,
  DATE_FORMAT_WITH_TIMEZONE,
  DEFAULT_DATE_FORMAT,
} from '../core/_constants/constants';

const getFormatDate = (originalDate: string | Date): Date =>
    moment(originalDate, DEFAULT_DATE_FORMAT).toDate(),
  getFormatOffsetDateTime = (originalDate: string | Date): string =>
    moment(originalDate).format(DATE_FORMAT_WITH_TIMEZONE),
  getStringDate = (originDate: string | Date, format?: string): string => {
    if (originDate instanceof Date) {
      originDate = moment(originDate).format();
    }
    const stringDate = originDate.split('T')[0];
    return format
      ? moment(stringDate, 'YYYY-MM-DD').format(format)
      : stringDate;
  },
  formatStringDateTime = (originDate: string, format: string): string => {
    if (!originDate) {
      return '';
    }
    originDate = originDate?.split('+')[0];
    if (
      originDate.indexOf('T') > -1 &&
      originDate.indexOf('T') < originDate.lastIndexOf('-')
    ) {
      originDate = originDate?.substring(0, originDate.lastIndexOf('-'));
    }
    return moment(originDate).format(format);
  },
  prepareDateTime = (originDate: string): string => {
    originDate = originDate?.split('+')[0];
    if (
      originDate.indexOf('T') > -1 &&
      originDate.indexOf('T') < originDate.lastIndexOf('-')
    ) {
      originDate = originDate?.substring(0, originDate.lastIndexOf('-'));
    }
    return originDate;
  },
  getFormatSimpleDate = (date: string | Date): string =>
    moment(date).format('DD MMM yy'),
  getDifferenceInDays = (
    firstDay: string | Date,
    lastDay: string | Date,
  ): number => {
    firstDay = getFormatDate(firstDay);
    lastDay = getFormatDate(lastDay);
    return moment(lastDay).diff(moment(firstDay), 'days');
  },
  getLastUpdatedDate = (
    date: string | Date,
    isFrench = false,
  ): [string, number] => {
    let result;
    const lastUpdatedTime = (moment.now() - moment(date).valueOf()) / 1000;
    if (lastUpdatedTime < 60) {
      result = isFrench
        ? `il y a ${Math.floor(lastUpdatedTime)} sec`
        : Math.floor(lastUpdatedTime) + ' sec ago';
    } else if (lastUpdatedTime < 3600) {
      result = isFrench
        ? `il y a ${Math.floor(lastUpdatedTime / 60)} min`
        : Math.floor(lastUpdatedTime / 60) + ' min ago';
    } else if (lastUpdatedTime < 86400) {
      result = isFrench
        ? `il y a ${Math.floor(lastUpdatedTime / 3600)} hr`
        : Math.floor(lastUpdatedTime / 3600) + ' hr ago';
    } else {
      // sent more than one day ago
      result = moment.utc(date).format('DD MMM yy');
    }
    return [result, lastUpdatedTime];
  },
  getTimeLeft = (date: string | Date): string => {
    const timeLeft =
      (moment(date).valueOf() - moment.now()) / (1000 * 3600 * 24);
    if (timeLeft > 0) {
      const days = Math.floor(timeLeft),
        hours = Math.floor((timeLeft - days) * 24);
      if (days > 0) {
        return days + 'd ' + hours + 'h';
      } else {
        if (hours > 0) {
          return hours + 'h';
        } else {
          return $localize`:@@data-helper.expired:Expired`;
        }
      }
    } else {
      return $localize`:@@data-helper.expired:Expired`;
    }
  },
  getDuration = (
    departureTime: string | Date,
    arrivalTime: string | Date,
    toBeRounded?: boolean,
  ): string => {
    let durationStr = '';
    if (arrivalTime && departureTime) {
      const departureTimeLocal = moment(departureTime),
        arrivalTimeLocal = moment(arrivalTime),
        duration = moment.duration(arrivalTimeLocal.diff(departureTimeLocal)),
        seconds = duration.asSeconds(),
        h = Math.round(duration.asHours()),
        m = Math.round((seconds % 3600) / 60);

      if (toBeRounded) {
        durationStr += Math.round(duration.asHours()) + 'h';
      } else {
        const hDisplay = h > 0 ? h + ' h' : null;
        if (hDisplay) {
          durationStr += hDisplay + ' ';
        }

        const mDisplay = m > 0 ? m + ' m' : null;
        if (mDisplay) {
          durationStr += mDisplay + ' ';
        }
      }
    }

    return durationStr.trim();
  },
  getTimezone = (date: string | Date, timezone: string): string => {
    // Return if input date is null or undefined
    if (!date) {
      return;
    }

    const localDate = moment(date).format(DATE_FORMAT_WITHOUT_TIMEZONE),
      offset = momentTz(localDate).tz(timezone).format('Z');

    // Format with ISO pattern
    return localDate + offset;
  },
  getDateWithTimezone = (date: string | Date, timezone: string): string => {
    if (!date) {
      return;
    }
    const localDate = moment(date).format(DATE_FORMAT_WITHOUT_TIMEZONE);
    return momentTz(localDate).tz(timezone).format(DATE_FORMAT_WITH_TIMEZONE);
  },
  formatSearchReqDate = (date: Date): string =>
    [
      date?.getFullYear(),
      padTo2Digits(date?.getMonth() + 1),
      padTo2Digits(date?.getDate()),
    ].join('-'),
  padTo2Digits = (num: number): string => num?.toString().padStart(2, '0');

export {
  formatSearchReqDate,
  formatStringDateTime,
  getDateWithTimezone,
  getDifferenceInDays,
  getDuration,
  getFormatDate,
  getFormatOffsetDateTime,
  getFormatSimpleDate,
  getLastUpdatedDate,
  getStringDate,
  getTimeLeft,
  getTimezone,
  prepareDateTime,
};
