class Customer {
  static readonly Wallet = "/customers/{walletId}";
  static readonly Events = "/customers/{walletId}/events";
  static readonly Balances = "/customers/{walletId}/balances";
  static readonly Beneficiaries = "/customers/{walletId}/beneficiaries";
  static readonly BalanceConversions = "/customers/{walletId}/balance-conversions";
  static readonly FundingAccounts = "/customers/{walletId}/funding-accounts/{currency}";
  static readonly PaylaterBalances = "/customers/{walletId}/paylater-balances";
  static readonly Currencies = "/customers/{walletId}/currencies";
  static readonly TazapayCurrencies = "/tazapay/currencies";
  static readonly StatementEvents = "/customers/{walletId}/statement-events";
  static readonly DefaultBalance = "/customers/{walletId}/default-balance";
  static readonly CustomerStatus = "/customers/{walletId}/status";
  static readonly PayLaterLimit = "/customers/{walletId}/pay-later-limit";
  static readonly TopUp = "/customers/{walletId}/top-up";
  static readonly PrePaidPayment = "/customers/{walletId}/pre-paid";
  static readonly LaterPayment = "/customers/{walletId}/pay-later";
  static readonly CancelTransactionsByRef = "/customers/{walletId}/cancel?reference={transactionId}";
  static readonly SettleTransactionsByRef = "/customers/{walletId}/settle";
  static readonly UserDataByRef = "/customers/{walletId}/user-data";
  static readonly ReconcileTransactionByRef = "/customers/{walletId}/reconcile";
  static readonly Transactions = "/customers/{walletId}/transactions";
  static readonly Metadata = "/customers/{walletId}/transactions/metadata";
  static readonly GuestTransactions = "/transactions";
  static readonly PayoutFrequency = "/customers/{walletId}/payout-frequency";
  static readonly GetPaid = "/customers/{walletId}/get-paid";
  static readonly PaymentApproval = "/customers/{walletId}/payment-approval";
  static readonly TransactionsCsv = "/customers/{walletId}/transactions-csv";
  static readonly TransactionsRecipient = "/customers/{walletId}/recipients";
}

class Tazapay {
  static readonly PaymentMethods = "/tazapay/payment-methods";
  static readonly Currencies = "/tazapay/currencies";
  static readonly Payout = "/tazapay/payout";
}

class Currency {
  static readonly Convert = "/currencies/convert";
}

class CurrencyCloud {
  static readonly PushNotification = "/currency-cloud/push";
}

class Transaction {
  static readonly SettleTransaction = "/transactions/{transactionId}/settle";
  static readonly TransactionDetail = "/transactions/{transactionId}";
  static readonly Categories = "/transactions/categories";
  static readonly GetAttachments = "/transactions/{transactionId}/attachments";
  static readonly PostAttachments = "/transactions/attachments";
  static readonly Prefill = "/transactions/prefill";
  static readonly PrefillById = "/transactions/prefill/{prefillId}";
  static readonly GetPaid = "/transactions/{transactionId}";
  static readonly ApprovePaymentRequest = "/transactions/{id}/approve";
  static readonly ApprovePayTo = "/transactions/{transactionId}/approve";
  static readonly RejectPayTo = "/transactions/{transactionId}/reject";
}

class File {
  static readonly PresignedUrl = "/files/presigned-url";
}

export const API_WALLET = {
  "name": "api-wallet",
  "URLs": {
    Customer,
    Currency,
    CurrencyCloud,
    Transaction,
    File,
    Tazapay,
  },
};
