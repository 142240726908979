import { ITypeOfProduct } from "@cai-services";
import { AircraftType } from "./aircraft-type.model";

export class TypeOfProduct implements ITypeOfProduct {
  typeOfProductId: number;
  productCode: string;
  productDescription: string;
  aircraftType?: AircraftType;

  constructor (productCode?: string, productDescription?: string) {
    this.productCode = productCode;
    this.productDescription = productDescription;
  }
}
