<div
  class="icon-wrapper"
  caiPopover
  [hidden]="hidden"
  [target]="tooltip"
  [allowHoverOnTarget]="true"
  placement="bottom-end"
>
  <span
    class="copy-icon"
    [style.background-color]="isHovered || isCopied ? activeColor : '#4F8BB6'"
    [class.hover]="isHovered"
    [class.copied]="isCopied"
  ></span>
</div>
<ng-template #tooltip>
  <div class="tooltip-text">
    {{ copyTooltip }}
  </div>
</ng-template>
