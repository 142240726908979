import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import moment from "moment";
import _ from "lodash";

export class AllotmentValidators {
  static format: any =
    (): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const text = control.value || "",
        isValid = /[a-zA-Z0-9]+/.test(text);
      return isValid || !text.length
        ? null
        : {
            "format": {
              "message": "Allotment ID must contain at least one character",
            },
          };
    };

  static departuresSequence: any =
    (fieldKeys: string[]): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      if (control) {
        for (let i = 0; i < fieldKeys.length; i++) {
          const field = control?.get(fieldKeys[i]);
          if (!field) {
            continue;
          }
          if (field?.hasError("sequence")) {
            field?.setErrors({
              ..._.omit(field?.errors, ["sequence"]),
            });
          }
          if (fieldKeys[i - 1] && fieldKeys[i]) {
            const currentDate = moment(field?.value, "YYYY-MM-DD"),
              prevDate = moment(
                control?.get(fieldKeys[i - 1])?.value,
                "YYYY-MM-DD",
              );
            if (currentDate.isBefore(prevDate, "day")) {
              const errors = {
                "sequence": {
                  "message": `{field} cannot precede {${fieldKeys[i - 1]}}`,
                },
              };
              field.setErrors(errors);
            }
          }
          if (field?.errors && Object.keys(field?.errors).length === 0) {
            field?.setErrors(null);
          }
        }
      }
      return null;
    };
}
