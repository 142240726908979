export enum RequirementCodeEnum {
  CONTAIN_LITHIUM_BATTERY = "contain_lithium_battery",
  LITHIUM_BATTERY_DETAIL = "lithium_battery_detail",
  COOLING_PACKAGING = "cooling_packaging",
  TEMPERATURE_FULL = "temperature_full",
  TEMPERATURE_LIVING = "temperature_living",
  TEMPERATURE_TRACKING_DEVICE = "temperature_tracking_device",
  TRACKING_DEVICE_BATTERY = "tracking_device_battery",
  TRACKING_DEVICE_NUMBER = "tracking_device_number",
  NEED_ACTIVE_CONTAINER = "need_active_container",
  PHARMA_DANGEROUS_GOOD = "pharma_dangerous_good",
  DANGEROUS_GOOD_TABLE = "dangerous_good_table",
  CARGO_AIRCRAFT_ONLY = "cargo_aircraft_only",
  DIPLOMATIC = "diplomatic",
  EXPRESS = "express",
  SCREENED = "screened",
  KNOWN_SHIPPER = "known_shipper",
}
