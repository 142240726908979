import {
  LoadTypeEnum,
  PackingTypeEnum,
  WeightTypeEnum,
  IQuoteItem,
  ULDTypeEnum,
} from "@cai-services";
import { QuoteRequest } from "./quote-request.model";
import { TypeOfProduct } from "./type-of-product.model";

export class QuoteItem implements IQuoteItem {
  quoteItemId: number;
  weight: number;
  typeOfProduct: TypeOfProduct;
  dimensionWidth: number;
  dimensionLength: number;
  volume?: number;
  numOfItems: number;
  dimensionHeight: number;
  uldType?: ULDTypeEnum;
  weightType: WeightTypeEnum;
  packing: PackingTypeEnum;
  quoteRequest: QuoteRequest;
  loadType: LoadTypeEnum;

  constructor (
    numOfItems?: number,
    weight?: number,
    dimensionLength?: number,
    dimensionWidth?: number,
    dimensionHeight?: number,
    packing?: PackingTypeEnum,
    weightType?: WeightTypeEnum,
    typeOfProduct?: TypeOfProduct,
    quoteItemId?: number,
    loadType?: LoadTypeEnum
  ) {
    this.numOfItems = numOfItems;
    this.weight = weight;
    this.dimensionLength = dimensionLength;
    this.dimensionWidth = dimensionWidth;
    this.dimensionHeight = dimensionHeight;
    this.packing = packing;
    this.quoteItemId = quoteItemId;
    this.weightType = weightType;
    this.typeOfProduct = typeOfProduct;
    this.loadType = loadType;
  }
}
