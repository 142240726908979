<!-- splash screen -->
<div #splashScreen class="kt-splash-screen">
  <span *ngIf="loaderType === 'spinner-message'">{{ loaderMessage }}</span>
  <div class="section--white">
    <div class="loader--roller">
      <div [class.blue-theme-loader-point]="isWalletApp"></div>
      <div [class.blue-theme-loader-point]="isWalletApp"></div>
      <div [class.blue-theme-loader-point]="isWalletApp"></div>
      <div [class.blue-theme-loader-point]="isWalletApp"></div>
      <div [class.blue-theme-loader-point]="isWalletApp"></div>
      <div [class.blue-theme-loader-point]="isWalletApp"></div>
      <div [class.blue-theme-loader-point]="isWalletApp"></div>
    </div>
  </div>
</div>
