enum AircraftCategory {
  FREIGHTERS_AND_WIDEBODIES = "freighters_and_widebodies",
  FREIGHTERS_ONLY = "freighters_only",
  ALL_AIRCRAFTS = "all_aircrafts",
}

enum AircraftTypes {
  FREIGHTER = "freighter",
  WIDEBODY = "widebody",
  NARROWBODY = "narrowbody",
  SURFACE = "surface",
  UNKNOWN = "unknown",
}

export { AircraftCategory, AircraftTypes };
