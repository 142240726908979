import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { UserService } from "@cai-services";
import Swal from "sweetalert2";
import { User } from "../../../../../core/_models/user.model";
import { SessionService } from "../../../../../core/_services/session.service";

@Component({
  "selector": "mart-integrations",
  "templateUrl": "./integrations.component.html",
  "styleUrls": ["./integrations.component.scss"],
})
export class IntegrationsComponent implements OnInit {
  @Input() user: User;

  magaya = false;
  ccn = false;
  azf = false;
  integrationsForm: FormGroup;
  loading: boolean;
  selectedTab: Tabs[] = [Tabs.MAGAYA, Tabs.CCN, Tabs.AZF];
  isActive: { [key: string]: boolean } = {
    [Tabs.MAGAYA]: this.magaya,
    [Tabs.CCN]: this.ccn,
    [Tabs.AZF]: this.azf,
  };
  tabProvider: string[];

  constructor (
    private readonly formBuilder: FormBuilder,
    private readonly ref: ChangeDetectorRef,
    private readonly userService: UserService,
    private readonly sessionService: SessionService,
  ) {
    this.integrationsForm = this.formBuilder.group({
      "Magaya": this.magaya,
      "CCN": this.ccn,
      "AZF": this.azf,
    });
  }
  ngOnInit (): void {
    if (this.user.providerId) {
      this.tabProvider = this.user.providerId.split(";");
      this.magaya = this.tabProvider.includes("Magaya");
      this.ccn = this.tabProvider.includes("CCN");
      this.azf = this.tabProvider.includes("AZF");
    }

    if (this.magaya) {
      this.isActive[Tabs.MAGAYA] = true;
    }
    if (this.ccn || this.azf) {
      this.isActive[Tabs.CCN] = true;
    }
    if (this.azf) {
      this.isActive[Tabs.AZF] = true;
    }
  }

  clickSwitch (tab: Tabs): void {
    let valeur: string;

    if (tab === Tabs.MAGAYA) {
      this.magaya = !this.magaya;
      this.isActive[Tabs.MAGAYA] = this.magaya;
      valeur = "Magaya";
    } else if (tab === Tabs.CCN) {
      this.ccn = !this.ccn;
      this.isActive[Tabs.CCN] = this.ccn;
      valeur = "CCN";
    } else if (tab === Tabs.AZF) {
      this.azf = !this.azf;
      this.isActive[Tabs.AZF] = this.azf;
      valeur = "AZF";
    }

    if (valeur) {
      if (!this.tabProvider) {
        this.tabProvider = [];
      }
      if (this.tabProvider.includes(valeur)) {
        this.tabProvider = this.tabProvider.filter((item) => item !== valeur);
      } else {
        this.tabProvider.push(valeur);
      }
    }
  }

  onSubmit (): void {
    this.loading = true;
    const updateUser = { ...this.user };
    updateUser.providerId = this.tabProvider.join(";");
    console.log(updateUser);
    this.userService
      .updateUser(updateUser)
      .then((user) => {
        this.user.providerId = user.providerId;
        this.sessionService.setCurrentUser(user);
        this.integrationsForm.markAsPristine();
        Swal.fire(
          $localize`:@@global.succeddfully-updated:Successfully updated!`,
          "",
          "success",
        );
        this.loading = false;
        this.ref.detectChanges();
      })
      .catch((err) => {
        Swal.fire("Something went wrong!", err.response.message, "error");
        this.loading = false;
        this.ref.detectChanges();
      });
  }
}

enum Tabs {
  MAGAYA = "Magaya",
  CCN = "CCN",
  AZF = "AZF",
}
