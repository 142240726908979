import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import { IGeneralCondition } from "../../model/general-condition.model";
import { ErrorUtil } from "../../utils/error.util";
import { API_CORE } from "../../constant/api-core.const";
import { IPresignedURL } from "../../model/presigned-url.model";
import { IPresignedGeneralConditionAttachment } from "../../model/presigned-general-condition-attachment.model";
import { IGeneralConditionAttachment } from "../../model/general-condition-attachment.model";

@Injectable({
  "providedIn": "root",
})
export class GeneralConditionService {
  async getGeneralCondition (
    airlineBrandCode?: string,
    originCountry?: string,
    destinationCountry?: string,
    originCode?: string,
    destinationCode?: string,
    chargeableWeight?: number,
    product?: string
  ): Promise<IGeneralCondition[]> {
    const extraOptions: any = {
      "queryStringParameters": {},
    };
    if (airlineBrandCode) {
      extraOptions.queryStringParameters.airline = airlineBrandCode;
    }
    if (originCountry) {
      extraOptions.queryStringParameters.originCountry = originCountry;
    }
    if (destinationCountry) {
      extraOptions.queryStringParameters.destinationCountry =
        destinationCountry;
    }
    if (originCode) {
      extraOptions.queryStringParameters.origin = originCode;
    }
    if (destinationCode) {
      extraOptions.queryStringParameters.destination = destinationCode;
    }
    if (chargeableWeight) {
      extraOptions.queryStringParameters.chargeableWeight = chargeableWeight;
    }
    if (product) {
      extraOptions.queryStringParameters.product = product;
    }
    return API.get(
      API_CORE.name,
      API_CORE.URLs.GeneralCondition.GeneralConditions,
      extraOptions
    ).catch(ErrorUtil.handleError);
  }

  async createOrUpdateGeneralCondition (
    generalCondition: IGeneralCondition
  ): Promise<IGeneralCondition> {
    return API.post(
      API_CORE.name,
      API_CORE.URLs.GeneralCondition.GeneralConditions,
      {
        "body": generalCondition,
      }
    ).catch(ErrorUtil.handleError);
  }
  async generateUploadUrlForAttachment (
    generalConditionId: number,
    attachment: IGeneralConditionAttachment
  ): Promise<IPresignedGeneralConditionAttachment> {
    const url = API_CORE.URLs.GeneralCondition.UploadAttachment.replace(
      "{generalConditionId}",
      generalConditionId.toString()
    );
    return API.post(API_CORE.name, url, {
      "body": attachment,
    }).catch(ErrorUtil.handleError);
  }
  async generateDownloadUrlForAttachment (
    generalConditionId: number,
    generalConditionAttachmentId: number,
    token?: string
  ): Promise<IPresignedURL> {
    const url = API_CORE.URLs.GeneralCondition.DownloadAttachment.replace(
      "{generalConditionId}",
      generalConditionId.toString()
    )
      .concat("/")
      .concat(generalConditionAttachmentId.toString());
    let extraOptions = {};
    if (token) {
      extraOptions = {
        "queryStringParameters": { token },
      };
    }
    return API.get(API_CORE.name, url, extraOptions);
  }
  async deleteAttachment (
    generalConditionId: number,
    generalConditionAttachmentId: number
  ): Promise<any> {
    const url = API_CORE.URLs.GeneralCondition.DeleteAttachment.replace(
      "{generalConditionId}",
      generalConditionId.toString()
    )
      .concat("/")
      .concat(generalConditionAttachmentId.toString());
    return API.del(API_CORE.name, url, {});
  }
}
