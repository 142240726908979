//Make sure that the enum key matches the description spelling except the 'noticication' text, with all dashed replaced by underscores and all caps for keys

export enum AppNotificationTypeEnum {
  AIRLINE_BOOKING_CONFIRMATION = "notification-airline-booking-confirmation",
  QUOTE_ACCEPT = "notification-quote-accept",
  QUOTE_UPDATE = "notification-quote-update",
  QUOTE_REJECT = "notification-quote-reject",
  QUOTE_IN_TRANSIT = "notification-quote-in-transit",
  QUOTE_AT_DESTINATION = "notification-quote-at-destination",
  QUOTE_DELIVERED = "notification-quote-delivered",
  QUOTE_REQUEST = "notification-quote-request",
  QUOTE_MESSAGE = "notification-quote-message",
  BOOKING_REQUEST = "notification-booking-request",
  BOOKING_UPDATE = "notification-booking-update",
  BOOKING_REJECT = "notification-booking-reject",
  BOOKING_MODIFY_REQUEST = "notification-booking-modify-request",
  BOOKING_MODIFY_ACCEPT = "notification-booking-modify-accept",
  BOOKING_MODIFY_REJECT = "notification-booking-modify-reject",
  BOOKING_CANCEL = "notification-booking-cancel",
  BOOKING_CANCEL_REQUEST = "notification-booking-cancel-request",
  QUALITY_RATING = "notification-quality-rating",
  USER_UPDATE = "notification-user-update",
  QUOTE_PING_UPDATE = "notification-quote-ping-update",
  PROMOS = "notification-promos",
  QUOTE_CHAT_MESSAGE = "notification-quote-chat-message",
  BOOKING_CHAT_MESSAGE = "notification-booking-chat-message",
  BOOKING_CONFIRM = "notification-booking-confirm",
  RATE_UPDATE = "notification-rate-update",
  DEPARTURE_UPDATE = "notification-departure-update",
  ARRIVAL_UPDATE = "notification-arrival-update",
  AIRCRAFT_UPDATE = "notification-aircraft-update",
  FLIGHT_NUMBER_UPDATE = "notification-flight-number-update",
  ORIGIN_UPDATE = "notification-origin-update",
  DESTINATION_UPDATE = "notification-destination-update",
  QUOTE_VALIDITY_UPDATE = "notification-quote-validity-update",
  WEIGHT_VOLUME_PIECES_UPDATE = "notification-weight-volume-pieces-update",
  CHARGEABLE_WEIGHT_UPDATE = "notification-chargeable-weight-update",
  ULD_UPDATE = "notification-uld-update",
  ALLOTMENT_UPDATE = "notification-allotment-update",
  DIMENSIONS_UPDATE = "notification-dimensions-update",
  CANCEL_REJECT = "notification-cancel-reject",
  BOOKING_REQUESTED_STILL_PENDING = "notification-booking-requested-still-pending",
  RATE_AND_CAPACITY_DATA = "notification-rate-and-capacity-data",
  FWB_ACCEPTED = "notification-fwb-accepted",
  FWB_REJECTED = "notification-fwb-rejected",
  FHL_REJECTED = "notification-fhl-rejected",
  FHL_ACCEPTED = "notification-fhl-accepted",
  SEARCH_POPUP = "notification-search-popup",
  OPTION_PLACED = "notification-option-placed",
  OPTION_CANCEL = "notification-option-cancel",
}
