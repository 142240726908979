<svg
  width="28"
  height="34"
  viewBox="0 0 28 34"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M27.2184 13.8595C27.2184 -4.61976 0 -4.6199 0 13.8595C0 22.7639 7.80334 29.742 11.5924 32.6054C12.7998 33.5178 14.4193 33.5178 15.6267 32.6054C19.4156 29.7419 27.2184 22.7639 27.2184 13.8595ZM13.6094 18.7101C16.4279 18.7101 18.7128 16.4255 18.7128 13.6073C18.7128 10.7892 16.4279 8.50455 13.6094 8.50455C10.7908 8.50455 8.50589 10.7892 8.50589 13.6073C8.50589 16.4255 10.7908 18.7101 13.6094 18.7101Z"
    fill="url(#paint0_linear_609:4565)"
  />
  <defs>
    <linearGradient
      id="paint0_linear_609:4565"
      x1="13.6092"
      y1="0"
      x2="13.6092"
      y2="33.2897"
      gradientUnits="userSpaceOnUse"
    >
      <stop [attr.stop-color]="startClr" />
      <stop offset="1" [attr.stop-color]="endClr" />
    </linearGradient>
  </defs>
</svg>
