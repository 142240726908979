
import { MeasurementSystemEnum, UserTypeEnum } from "@cai-services";
import { Airport } from "./region-airport.model";
import { User } from "./user.model";

/**
 *
 */
export class AdminUser extends User {
    companyName: string; // from LightUser
    companyId: number; // from LightUser
    sortKey: string; // for on screen sorting
    userPassword: string;

  constructor (
    userName?: string,
    userEmail?: string,
    userType?: UserTypeEnum,
    defaultCurrency?: string,
    originAirport?: Airport,
    measurementSystem?: MeasurementSystemEnum
  ) {
    super(userName, userEmail, userType, defaultCurrency, originAirport, measurementSystem);
  }
}
