import { Component, Inject, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  Event,
  NavigationStart,
  Router,
} from "@angular/router";
import { APP_PROPS } from "../../cai-common.module";
import { ApplicationMenuConfig, ApplicationProperties } from "../../core";
import { MenuAsideService } from "../../core/_base/layout/services/menu-aside.service";
import { MenuConfigService } from "../../core/_base/layout/services/menu-config.service";
import { MenuHorizontalService } from "../../core/_base/layout/services/menu-horizontal.service";
import { SplashScreenService } from "../../core/_base/layout/services/splash-screen.service";
import { AuthNoticeService } from "../../core/_misc/auth-notice/auth-notice.service";
import { SessionService } from "../../core/_services/session.service";
import { WalletUtil } from "../../utils/wallet.util";

@Component({
  "selector": "kt-auth",
  "templateUrl": "./auth.component.html",
  "styleUrls": ["./auth.component.scss"],
})
export class AuthComponent implements OnInit {
  today: number = Date.now();
  headerLogo: string;
  isLoginPage: boolean;
  isEmbedMode: boolean;

  constructor (
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    public readonly authNoticeService: AuthNoticeService,
    private readonly splashScreenService: SplashScreenService,
    private readonly sessionService: SessionService,
    private readonly router: Router,
    private readonly menuConfigService: MenuConfigService,
    private readonly menuHorizontalService: MenuHorizontalService,
    private readonly menuAsideService: MenuAsideService,
    private readonly route: ActivatedRoute
  ) {
    this.headerLogo = this.appProperties.logo;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (event.url.includes("/auth/login")) {
          this.isLoginPage = true;
        }
      }
    });
  }

  ngOnInit (): void {
    if (this.router.url.includes("/auth/login")) {
      this.isLoginPage = true;
    }
    this.route.queryParams.subscribe(async (params) => {
      this.isEmbedMode = WalletUtil.isEmbedMode(this.appProperties, params);
    });
    this.splashScreenService.hide();
    this.navigateLoggedUser();
  }

  navigateLoggedUser () {
    const userType = this.sessionService.getCurrentUser()?.type,
     userMenu = this.appProperties.menu.find(
      (config) => userType === config.userType
    );
    if (userMenu) {
      this.router.navigateByUrl(userMenu.defaultPage).then(() => {
        if (!this.isEmbedMode) {
          this.initMenu(userMenu);
        }
      });
    }
  }

  private initMenu (menuConfig: ApplicationMenuConfig): void {
    if (
      menuConfig.defaultPage.indexOf(
        "/" + menuConfig.userType.toLowerCase() + "/"
      ) > -1
    ) {
      this.menuConfigService.loadConfigs(menuConfig.config.menuItems);
    }
    this.menuAsideService.loadMenu();
    this.menuHorizontalService.loadMenu();
  }
}
