import { CaiSelectOption } from "@cai-framework";
import { IDgdDictionnary } from "@cai-services";
import {
  DangerousGoodTableConfig,
  DangerousGoodUOM,
} from "../constant/requirements/dangerous-goods-table.const";
import { DangerousGood } from "../core/_models/dangerous-good.model";
import { PackagingInstructionEnum } from "../enum/packaging-instructions.enum";

export class DangerousGoodUtil {
  static isPartiallyFilled (dangerousGood: DangerousGood): boolean {
    return !!dangerousGood?.unid;
  }

  static splitString (text: string): string[] {
    if (text) {
      return text.split(",").map((d) => d.trim());
    }
    return [];
  }

  static selectFirstItemOrNone (options: CaiSelectOption[]): string {
    if (options[0]) {
      return options[0].value;
    }
    return null;
  }

  static isDangerousGoodValid (
    dangerousGood: DangerousGood,
    isCAO: boolean
  ): boolean {
    return this.validateDangerousGood(dangerousGood, isCAO).length === 0;
  }

  static validateDangerousGood (
    dangerousGood: DangerousGood,
    isCAO: boolean
  ): string[] {
    const dangerousGoodErrors = [];
    for (const column of DangerousGoodTableConfig.columns) {
      const value = dangerousGood[column.name];
      if (column.validators && value !== "NONE") {
        for (const key of Object.keys(column.validators)) {
          const error = column.validators[key](value),
           exists = dangerousGoodErrors.find(
            (err) => err.field === column.name
          ),
           isCAOFieldsEnabled =
            ["packagingInstruction", "totalNet"].includes(column.name) &&
            this.isCAOFieldsEnabled(dangerousGood, isCAO, true),
           isNonCAOFieldsEnabled =
            ["caoPackagingInstruction", "caoTotalNet"].includes(column.name) &&
            this.isNonCAOFieldsEnabled(dangerousGood, isCAO, true);
          if (
            error &&
            !exists &&
            !isCAOFieldsEnabled &&
            !isNonCAOFieldsEnabled
          ) {
            dangerousGoodErrors.push({
              "field": column.name,
              "message": error,
            });
          }
        }
      }
    }
    return dangerousGoodErrors.map((err) => err.message);
  }

  static isCAOFieldsEnabled (
    dangerousGood: DangerousGood,
    isCAO: boolean,
    isMandatoryTouched: boolean
  ): boolean {
    return (
      !dangerousGood.isNewRow &&
      isCAO &&
      (!isMandatoryTouched ||
        (isMandatoryTouched &&
          (!this.isEmpty(dangerousGood.caoPackagingInstruction) ||
            !this.isEmpty(dangerousGood.caoTotalNet))))
    );
  }

  static isNonCAOFieldsEnabled (
    dangerousGood: DangerousGood,
    isCAO: boolean,
    isMandatoryTouched: boolean
  ): boolean {
    return (
      !dangerousGood.isNewRow &&
      (!isCAO ||
        (isCAO &&
          (!isMandatoryTouched ||
            (isMandatoryTouched &&
              this.isEmpty(dangerousGood.caoPackagingInstruction) &&
              this.isEmpty(dangerousGood.caoTotalNet)))))
    );
  }

  static isEmpty (value: any): boolean {
    return [null, undefined].includes(value);
  }

  static getOptionsFromString (text: string): CaiSelectOption[] {
    return DangerousGoodUtil.splitString(text)
      .filter((str) => str)
      .map((option) => ({
        "label": option,
        "value": option,
      }));
  }

  static getPackagingGroupOptions (
    searchResult: IDgdDictionnary
  ): CaiSelectOption[] {
    const packagingTypes = searchResult.packagingTypes || [];
    return packagingTypes
      .map((type) => {
        const formatted = type.packagingType.replace("TYPE", "").trim();
        return {
          "label": formatted,
          "value": formatted,
        };
      })
      .filter((type) => type.value);
  }

  static getPackagingInstructionOptions (
    selectedPackagingGroup: string,
    searchResult: IDgdDictionnary
  ): CaiSelectOption[] {
    if (searchResult) {
      const selectedPackagingType = searchResult.packagingTypes.find((type) => {
        const formatted = type.packagingType.replace("TYPE", "").trim();
        return formatted === selectedPackagingGroup;
      });
      if (selectedPackagingType) {
        const packagingTypeOptions = [
          {
            "label": selectedPackagingType.limitedPackagingInstruction,
            "value": selectedPackagingType.limitedPackagingInstruction,
            "description": PackagingInstructionEnum.LIMITED,
          },
        ];
        if (
          packagingTypeOptions.find(
            (opt) =>
              opt.value !== selectedPackagingType.regulatedPackagingInstruction
          )
        ) {
          packagingTypeOptions.push({
            "label": selectedPackagingType.regulatedPackagingInstruction,
            "value": selectedPackagingType.regulatedPackagingInstruction,
            "description": PackagingInstructionEnum.REGULATED,
          });
        }
        return packagingTypeOptions.filter((option) => option.value);
      }
    }
    return [];
  }

  static getNonCAOValuesByPackaging (
    dangerousGood: DangerousGood
  ): DangerousGood {
    const selectedPackagingType = dangerousGood.searchResult?.packagingTypes
      .filter((type) => type.packagingType)
      .find((type) => {
        const formatted = type.packagingType.replace("TYPE", "").trim();
        return formatted === dangerousGood.packagingGroup;
      }),
     selectedPackagingInstruction =
      dangerousGood.packagingInstructionOptions.find((option) => option.value === dangerousGood.packagingInstruction),

     nonCAODangerousGood = {
      ...dangerousGood,
    };

    if (selectedPackagingInstruction && selectedPackagingType) {
      const prefix = selectedPackagingInstruction.description,
       uom = selectedPackagingType[prefix + "Uom"];
      nonCAODangerousGood.uom = uom;
      nonCAODangerousGood.uomOptions = [
        {
          "label": uom,
          "value": uom,
        },
      ];
      nonCAODangerousGood.shc = selectedPackagingType.shc;
    } else if (selectedPackagingType && !selectedPackagingInstruction) {
      if (!dangerousGood.uom) {
        nonCAODangerousGood.uom = DangerousGoodUOM[0].value;
      }
      nonCAODangerousGood.uomOptions = DangerousGoodUOM;
      const shcForNone = dangerousGood.searchResult.packagingTypes.find(
        (type) => type.packagingType === "NONE"
      ),
       shcForNonePI = dangerousGood.searchResult.packagingTypes.find(
        (type) =>
          !type.limitedPackagingInstruction &&
          !type.regulatedPackagingInstruction
      );
      if (shcForNone) {
        nonCAODangerousGood.shc = shcForNone.shc;
      } else if (shcForNonePI) {
        nonCAODangerousGood.shc = shcForNonePI.shc;
      } else {
        nonCAODangerousGood.shc = "NONE";
      }
    } else {
      const shcForEmpty = dangerousGood.searchResult?.packagingTypes.find(
        (type) => !type.packagingType
      );
      nonCAODangerousGood.shc =
        shcForEmpty && shcForEmpty.shc ? shcForEmpty.shc : null;
    }
    return nonCAODangerousGood;
  }
}
