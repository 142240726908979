<mat-form-field
  class="ml-2 mr-2 language-form-field"
  [class.login-page-selector]="isLoginPage"
  [class.login-page-selector-wallet]="isWalletApp && isLoginPage"
  [class.selector-wallet]="isWalletApp"
  style="width: 180px"
>
  <mat-select
    [value]="languageSelected?.locale || 'en'"
    (selectionChange)="onSelectionChange($event)"
    [class.wallet-blue-arrow]="isWalletApp"
  >
    <mat-select-trigger>
      <kt-language-option [language]="languageSelected"></kt-language-option>
    </mat-select-trigger>
    <ng-container *ngFor="let language of languages">
      <mat-option [value]="language?.locale">
        <kt-language-option [language]="language"></kt-language-option>
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
