import { IWalletOffice } from '../../wallet/model/office.model';
import {
  WalletPaymentTerms,
  WalletStatusTypeEnum,
  WalletTopupPaymentTypeEnum,
} from '../enum/wallet-status.enum';
import { WalletTransactionPayment } from './wallet-transactions.model';

export interface DefaultWalletBalance {
  amount: number;
  currency: string;
}
export interface WalletGlobalBalance {
  amount: number;
  currency: string;
  on_hold: number;
}

export class AccountWalletDetails {
  customer_id: string;
  office_id: string;
  account_name: string;
  provider: string;
  status: WalletStatusTypeEnum;
  default_currency: string;
  default_pay_later_currency: string;
  default_pay_later_limit: number;
  paylater_status: string;
  balances: DefaultWalletBalance[];
}

export class WalletTopUp {
  amount = '';
  currency: string;
  paymentMethod: WalletTopupPaymentTypeEnum;
  funding_account?: WalletBankAccountDetails;
}

export class WalletPayLaterBalance {
  limit: number;
  currency: string;
  future_movement: number;
}
export class WalletConvertedPayLaterBalance {
  origin_amount: number;
  converted_amount: number;
  from: string;
  to: string;
}

export class ConvertAmountRequest {
  from_currency: string;
  to_currency: string;
  amount: number;
  sale: boolean;
}

export class WalletBankAccountDetails {
  account_number: string;
  account_number_type: string;
  account_holder_name: string;
  bank_name: string;
  bank_address: string;
  bank_country: string;
  currency: string;
  payment_type: string;
  routing_code: string;
  routing_code_type: string;
}

export class WalletPaymentApprovalWorkflow {
  payment_approval_threshold: number;
  payment_approval_status: string;
}

export class WalletUserData {
  reference: string;
  awb: string;
  invoice_number: string;
  receipt_number: string;
  pay_term: WalletPaymentTerms;
  fee_type: string;
  office: IWalletOffice;
  buyer_office: IWalletOffice;
}

export class WalletMetadataUser {
  type: string;
  data: WalletUserData;
}

export class GetEventResponse {
  events: WalletTransaction[];
  last_evaluated_key: string;
}

export class GetPayoutEventResponse {
  events: PayoutHistoricTransaction[];
}

export class WalletTransaction {
  reference_id: string;
  transaction_id: string;
  transaction_type: string;
  transaction_status: string;
  balance_movement: number;
  started_at: Date;
  due_date: string;
  currency: string;
  operation: string;
  user_data: WalletUserData;
  metadata_user: WalletMetadataUser;
  tran_type: string;
  isShow = true;
  is_conversion_transaction: boolean;
  conversion_data: ConversionData;
  additional_info: string;
  destination_pay_from: string;
  destination_pay_to: string;
  email_pay_to: string;
  email_pay_from?: string;
  transaction_category: string;
  payment_information?: {
    data: WalletTransactionPayment;
    type: string;
  };
  token?: string;
}

export class PayoutHistoricTransaction {
  customerId: string;
  currency: string;
  transaction_type: string;
  transaction_status: string;
  balance_movement: number;
  transaction_date: string;
  due_date: string;
  transaction_id: string;
  reference_id: string;
  metadata_user: WalletMetadataUser;
  user_data: WalletUserData;
  operation: string;
  last_updated_date: string;
  payout_frequency: string;
  maintain_balance: string;
  minimum_payout_amount: string;
  customer_name: string;
  beneficiary_id: string;
  officeId: number;
  countryCode: string;
  companyCode: string;
  fee?: number;
}

export class WalletCurrency {
  code: string;
  name: string;
}
export class TazapayWalletCurrency {
  currency: string;
  country: string;
}

export class TazapayCurrency {
  currency: string;
  country: string;
}

export class ConversionData {
  converted_amount: string;
  converted_exchange_rate: string;
  converted_currency: string;
}

export const TransactionTypes = {
  TOP_UP: 'CargoWALLET Top Up',
  PREPAID_BOOKING: 'Booking Prepayment',
  PREPAID_PREPAYMENT: 'Booking Prepayment',
  PAYMENT_ISSUE: 'Payment Issued',
  LATER_BOOKING: 'PayLater',
  LATER_BOOKING_TERM_30: 'PayLater 30 days',
  LATER_BOOKING_TERM_60: 'PayLater 60 days',
  LATER_CARGOAI_FEE: 'Booking Adjustment',
  LATER_CARGOAI_FEE_Booking_TERM_30: 'CargoWALLET PayLater 30 days Fee',
  LATER_CARGOAI_FEE_Booking_TERM_60: 'CargoWALLET PayLater 60 days Fee',
  LATER_CARGOAI_FEE_Export: 'CargoWALLET Export Fee',
  PREPAID_RECONCILIATION: 'Booking Adjustment',
  LATER_RECONCILIATION: 'Booking Adjustment',
  PREPAID_PAY_FROM: 'Payment Received',
  Prepaid_PayTo: 'PREPAID_PAY_TO',
  TAZAPAY_PAY_FROM: 'Payment Received',
  CONVERSION_FEE: 'Conversion Fee',
  PREPAID_CARGOAI_FEE: 'Prepaid Fee',
  CREDIT_CARD_FEE: 'Credit Card Fee',
  APM_FEE: 'Local Payment Method Fee',
  PAYMENT_RECEIVED: 'Payment Received',
  PAYMENT_FEE: 'Payment Fee',
  TOP_UP_FEE: 'Top Up Fee',
  TAZAPAY_PAYOUT_TRANSACTION_FEE: 'Payout Fee',
  TAZAPAY_PAYOUT: 'Payout',
  GET_PAID: 'Payment Requested',
  OVERDUE: 'Payment To Be Issued',
  PREPAID_RECONCILIATION_FEE: 'Booking Adjustment Fee',
};
