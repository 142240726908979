<svg
  width="73"
  height="79"
  viewBox="0 0 73 79"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g filter="url(#filter0_d_224:1823)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.846 4.04099C35.5457 4.10275 35.2621 4.26028 34.695 4.57532L7.64609 19.6006L7.64608 19.6006C7.489 19.6878 7.48955 19.9125 7.64662 19.9998L35.4719 35.4563C35.7555 35.6138 35.8973 35.6926 36.0474 35.7235C36.1803 35.7508 36.3174 35.7508 36.4503 35.7235C36.6005 35.6926 36.7422 35.6138 37.0258 35.4563L64.8511 19.9998C65.0082 19.9125 65.0087 19.6878 64.8516 19.6006L37.8028 4.57532C37.2356 4.26028 36.952 4.10275 36.6517 4.04099C36.3859 3.98634 36.1118 3.98634 35.846 4.04099ZM66.4977 22.398C66.4977 22.1937 66.2794 22.0662 66.1009 22.1653L38.3219 37.5961C38.0224 37.7625 37.8726 37.8457 37.7635 37.9641C37.667 38.0688 37.594 38.1929 37.5494 38.328C37.4989 38.4809 37.4989 38.6522 37.4989 38.9948V69.8809C37.4989 70.0373 37.6661 70.1361 37.8028 70.0602L64.8516 55.0349L64.8517 55.0349C65.4508 54.7021 65.7503 54.5357 65.9684 54.2991C66.1614 54.0897 66.3074 53.8415 66.3967 53.5711C66.4977 53.2655 66.4977 52.9229 66.4977 52.2375V22.398ZM34.695 70.0602C34.8317 70.1361 34.9989 70.0373 34.9989 69.8809V38.9948C34.9989 38.6522 34.9989 38.4809 34.9484 38.328C34.9037 38.1929 34.8307 38.0688 34.7342 37.9641C34.6251 37.8457 34.4754 37.7625 34.1758 37.5961L6.39686 22.1653C6.21834 22.0662 6 22.1937 6 22.398V52.2375C6 52.9229 6 53.2655 6.10098 53.5711C6.19031 53.8415 6.33635 54.0897 6.52932 54.2991C6.74743 54.5357 7.04697 54.7021 7.64604 55.0349L7.64609 55.0349L34.695 70.0602Z"
      fill="url(#paint0_linear_224:1823)"
    />
  </g>
  <defs>
    <filter
      id="filter0_d_224:1823"
      x="0"
      y="0"
      width="72.4976"
      height="78.0862"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="2" />
      <feGaussianBlur stdDeviation="3" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_224:1823"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_224:1823"
        result="shape"
      />
    </filter>
    <linearGradient
      id="paint0_linear_224:1823"
      x1="36.2489"
      y1="4"
      x2="36.2489"
      y2="70.0863"
      gradientUnits="userSpaceOnUse"
    >
      <stop [attr.stop-color]="startClr" />
      <stop offset="1" [attr.stop-color]="endClr" />
    </linearGradient>
  </defs>
</svg>
