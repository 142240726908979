import { AppUser } from "../../_models/app-user.model";
import { AuthActions, AuthActionTypes } from "../_actions/auth.actions";

export interface AuthState {
  loggedIn: boolean;
  authToken: string;
  user: AppUser;
  isUserLoaded: boolean;
}

export const initialAuthState: AuthState = {
  "loggedIn": false,
  "authToken": undefined,
  "user": undefined,
  "isUserLoaded": false,
};

export function authReducer (
  state = initialAuthState,
  action: AuthActions
): AuthState {
  switch (action.type) {
    case AuthActionTypes.Login: {
      const _token: string = action.payload.authToken;
      return {
        "loggedIn": true,
        "authToken": _token,
        "user": undefined,
        "isUserLoaded": false,
      };
    }

    case AuthActionTypes.Register: {
      const _token: string = action.payload.authToken;
      return {
        "loggedIn": true,
        "authToken": _token,
        "user": undefined,
        "isUserLoaded": false,
      };
    }

    case AuthActionTypes.Logout:
      return initialAuthState;

    case AuthActionTypes.UserLoaded: {
      const _user: AppUser = action.payload.user;
      return {
        ...state,
        "user": _user,
        "isUserLoaded": true,
      };
    }

    default:
      return state;
  }
}
