import { User } from "./user.model";

/**
 *
 */
export class DashboardAccess {
  startDate: Date;
  endDate: Date;
  forwarderId: number;
  airlineCompanyId: number;
  userType: DashboardAccess.UserTypeEnum;
  user: User;

  constructor (
    startDate?: Date,
    endDate?: Date,
    forwarderId?: number,
    airlineCompanyId?: number,
    userType?: DashboardAccess.UserTypeEnum
  ) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.forwarderId = forwarderId;
    this.airlineCompanyId = airlineCompanyId;
    this.userType = userType;
  }
}

export namespace DashboardAccess {
  export type UserTypeEnum = "FORWARDER | AIRLINE";
  export const UserTypeEnum = {
    "FORWARDER": "FORWARDER" as UserTypeEnum,
    "AIRLINE": "AIRLINE" as UserTypeEnum,
    "ADMIN": "ADMIN" as UserTypeEnum,
  };
}
