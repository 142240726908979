import { Injectable } from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { filter, pairwise, startWith } from "rxjs/operators";

const SHIPMENT_DETAILS_URL = "/shipment-details/";

@Injectable()
export class RouterService {
  private prevUrl?: any = undefined;
  private currentUrl: string;

  constructor (private router: Router) {
    this.currentUrl = this.router.url;

    router.events
      .pipe(
        filter((e: any) => e instanceof NavigationEnd),
        startWith(new NavigationEnd(0, this.currentUrl, this.currentUrl)),
        pairwise(),
      )
      .subscribe((pairedURL) => {
        const previousUrlEvent = pairedURL[0].urlAfterRedirects,
         currentUrlEvent = pairedURL[1].urlAfterRedirects,
         storedCurUrl = sessionStorage.getItem("currentUrl"),
         storedPrevUrl = sessionStorage.getItem("prevUrl");
        this.prevUrl = previousUrlEvent;
        if (!this.prevUrl && currentUrlEvent !== storedCurUrl) {
          this.prevUrl = storedCurUrl;
        }
        if (currentUrlEvent === storedCurUrl) {
          this.prevUrl = storedPrevUrl;
        }
        if (
          previousUrlEvent &&
          previousUrlEvent?.includes(SHIPMENT_DETAILS_URL)
        ) {
          sessionStorage.setItem("browserRefreshed", "false");
        }
        sessionStorage.setItem("prevUrl", this.prevUrl);
        sessionStorage.setItem("currentUrl", currentUrlEvent);
      });

    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (!router.navigated) {
          sessionStorage.setItem("browserRefreshed", "true");
        }
      }
    });
  }

  public getPrevUrl () {
    return this.prevUrl;
  }
  public getCurrUrl () {
    return this.router.url;
  }
  public checkStorage () {
    this.prevUrl = sessionStorage.getItem("prevUrl");
  }
}
