import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";

import { API_SOLUTIONS } from "../../../solutions/constant/api-solutions.const";
import { IServiceConfigField } from "../../model/service-config.model";

@Injectable({
  "providedIn": "root",
})
export class ServiceConfigService {
  async getConfigs (
    type?: string,
    keyId?: string
  ): Promise<IServiceConfigField[]> {
    return API.get(
      API_SOLUTIONS.name,
      `/config?type=${type}&keyId=${keyId}`,
      {}
    );
  }

  async saveConfig (
    type?: string,
    configFields?: IServiceConfigField
  ): Promise<IServiceConfigField> {
    return API.post(API_SOLUTIONS.name, `/config?type=${type}`, {
      "body": configFields,
    });
  }

  async deleteConfig (
    configFields: IServiceConfigField,
    type?: string
  ): Promise<IServiceConfigField> {
    return API.del(API_SOLUTIONS.name, `/config?type=${type}`, {
      "body": configFields,
    });
  }
}
