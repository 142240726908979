import { Component, Input, OnInit } from "@angular/core";

@Component({
  "selector": "kt-request-button",
  "templateUrl": "./request-button.component.html",
  "styleUrls": ["./request-button.component.scss"],
})
export class RequestButtonComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() isLoading: boolean;
  @Input() invalid: boolean;

  constructor () {}

  ngOnInit (): void {}
}
