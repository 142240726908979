<div class="modal-body">
  <div class="header">
    <span>{{ heading }}</span>
    <svg
      (click)="closeModal()"
      *ngIf="showClose"
      width="24"
      height="26"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.48329 5.76877C5.78352 5.4834 6.25824 5.49544 6.54361 5.79567L12 11.5361L17.4564 5.79567C17.7418 5.49544 18.2165 5.4834 18.5167 5.76877C18.8169 6.05414 18.829 6.52886 18.5436 6.82909L13.0348 12.6247L18.5436 18.4203C18.829 18.7206 18.8169 19.1953 18.5167 19.4806C18.2165 19.766 17.7418 19.754 17.4564 19.4537L12 13.7133L6.54361 19.4537C6.25824 19.754 5.78352 19.766 5.48329 19.4806C5.18306 19.1953 5.17102 18.7206 5.45639 18.4203L10.9652 12.6247L5.45639 6.82909C5.17102 6.52886 5.18306 6.05414 5.48329 5.76877Z"
        fill="#679EC6"
      />
    </svg>
  </div>
  <ng-container *ngIf="type === 'SELECT'">
    <div class="title" i18n="@@office-setting.select-branches-to-upgrade">
      Select the branch(es) that you would like to upgrade to Pro plan:
    </div>
    <div class="offices">
      <div *ngFor="let office of upgradableOffices">
        <kt-checkbox
          [label]="officeLabel(office)"
          (onToggle)="onToggle(office)"
        >
        </kt-checkbox>
      </div>
    </div>
    <div
      class="more-branches"
      i18n="@@office-setting.dont-see-branches-add-more-later"
    >
      Don't see all the branches? You can contact us to add more branches later.
    </div>
  </ng-container>
  <ng-container *ngIf="type === 'UPGRADE'">
    <div class="upgrade">
      <div class="amount-detail row">
        <div class="col-8">
          {{ getBranches() }}
        </div>
        <div class="col-4">
          ${{ branchesTotal | caiNumberFormatter: false : 2 : true }}
        </div>
      </div>
      <div class="amount-detail calculation row">
        <div class="col-8" i18n="@@office-setting.first-branch-149-12-months">
          First branch $149 x 12 months
        </div>
        <div class="col-4">$1788.00</div>
      </div>
      <div
        class="amount-detail calculation row"
        *ngIf="selectedOffices?.length > 1"
      >
        <div class="col-8">
          <span i18n="@@office-setting.additional-branch"
            >Additional branch $99</span
          >
          x {{ selectedOffices?.length - 1 }} x 12
          <span i18n="@@office-setting.months">months</span>
        </div>
        <div class="col-4">
          ${{ additionalOfficesTotal | caiNumberFormatter: false : 2 : true }}
        </div>
      </div>
      <div class="row addon">
        <div class="col-9">
          <kt-checkbox
            label="CargoINTEL market analytics"
            [(ngModel)]="isMarketAnalysis"
          ></kt-checkbox>
        </div>
        <div class="col-3" *ngIf="isMarketAnalysis">
          ${{ addonAmount | caiNumberFormatter: false : 2 : true }}
        </div>
      </div>
      <div class="calculation addon-price">
        $99
        <span *ngIf="!isMarketAnalysis" i18n="@@office-setting.per-branch"
          >per branch</span
        >
        <span *ngIf="isMarketAnalysis">x {{ selectedOffices?.length }}</span> x
        12 <span i18n="@@office-setting.months">months</span>
      </div>
      <div class="row addon">
        <div class="col-9">
          <kt-checkbox
            label="Quotation for Shipper"
            [(ngModel)]="isQuotation"
          ></kt-checkbox>
        </div>
        <div class="col-3" *ngIf="isQuotation">
          ${{ addonAmount | caiNumberFormatter: false : 2 : true }}
        </div>
      </div>
      <div class="calculation addon-price">
        $99
        <span *ngIf="!isQuotation" i18n="@@office-setting.per-branch"
          >per branch</span
        >
        <span *ngIf="isQuotation">x {{ selectedOffices?.length }}</span> x 12
        <span i18n="@@office-setting.months">months</span>
      </div>
      <hr />
      <div class="amount-detail subtotal row">
        <div class="col-8" i18n="@@office-setting.subtotal">Subtotal</div>
        <div class="col-4">
          ${{ subTotal | caiNumberFormatter: false : 2 : true }}
        </div>
      </div>
      <div class="amount-detail gst row" *ngIf="gst > 0">
        <div class="col-8" i18n="@@office-setting.gst-9">GST (9%)</div>
        <div class="col-4">
          {{ gst | caiNumberFormatter: false : 2 : true }}
        </div>
      </div>
      <div class="amount-detail discount row" *ngIf="discountAmount > 0">
        <div class="col-8">
          <span i18n="@@office-setting.discount">Discount </span> ({{
            getDiscountValue()
          }}
          <span i18n="@@office-setting.off">off</span>)
        </div>
        <div class="col-4">
          -${{ discountAmount | caiNumberFormatter: false : 2 : true }}
        </div>
      </div>
      <div id="discount-control" class="form-field-group">
        <mat-form-field
          appearance="outline"
          [class.error-message]="!!errorMessage"
        >
          <mat-label i18n="@@office-setting.referral-code"
            >Referral code</mat-label
          >
          <input
            matInput
            [ngModel]="discountCode"
            (ngModelChange)="changeCode($event)"
            type="text"
            autocomplete="off"
            name="discountCode"
            [ngClass]="{ 'mat-form-field-invalid': !!errorMessage }"
          />
          <mat-hint>
            <span
              *ngIf="discount?.value && !discountCode && !errorMessage"
              i18n="@@office-setting.code-applied-successfully"
              >Code applied successfully</span
            >
            <span *ngIf="!!errorMessage">{{ errorMessage }}</span>
          </mat-hint>
        </mat-form-field>
        <button
          mat-flat-button
          color="primary"
          class=""
          (click)="applyDiscountCode()"
          [disabled]="!discountCode || !!errorMessage"
        >
          <span i18n="@@office-setting.apply">APPLY</span>
        </button>
      </div>
      <hr />
      <div class="amount-detail row">
        <div class="col-8" i18n="@@office-setting.total-due-today">
          Total due today
        </div>
        <div class="col-4 total">
          ${{ total | caiNumberFormatter: false : 2 : true }}
        </div>
      </div>
      <div class="pay-securely">
        We will bring you to
        <img src="/assets/media/logos/cargowallet.png" alt="CargoWALLET" />
        where you can pay securely with credit card or other payment methods
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isInfo">
    <div
      [ngClass]="centerMessage ? 'message' : 'message__left'"
      i18n="@@office-setting.your-upgrade-complete-start-exploring-pro"
      [innerHTML]="infoMessage"
    ></div>
    <ng-container *ngIf="type === 'INVOICE'">
      <div
        class="message__left"
        i18n="@@office-setting.continue-payment-via-link"
      >
        You can continue the payment via the link in the invoice
      </div>
    </ng-container>
  </ng-container>
  <div
    class="button"
    [class.disabled]="disabled"
    (click)="onPrimaryButtonClick()"
  >
    {{ primaryButtonLabel }}
  </div>
  <div
    class="button second"
    *ngIf="secondaryButtonLabel.length > 0"
    (click)="onSecondaryButtonClick()"
  >
    {{ secondaryButtonLabel }}
  </div>
</div>
