import { Gha } from "./gha.model";

export class GhaAttachment {
  id: number;
  fileName: string;
  fileType: string;
  fileSize: number;
  gha: Gha;

  constructor (
    fileName: string,
    fileType: string,
    fileSize: number,
    gha?: Gha,
    id?: number
  ) {
    this.fileName = fileName;
    this.fileType = fileType;
    this.fileSize = fileSize;
    this.gha = gha;
    this.id = id;
  }
}
