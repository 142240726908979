export const LoadTypeMeasurementUnits = {
  "METRIC": {
    "WEIGHT": {
      "code": "kg",
      "name": "kilogram",
      "display": "kg",
    },
    "VOLUME": {
      "code": "m3",
      "name": "cubic meter",
      "display": "m\u00B3",
    },
    "MEASURE": {
      "code": "cm",
      "name": "centimeter",
      "display": "cm",
    },
  },
  "IMPERIAL": {
    "WEIGHT": {
      "code": "lb",
      "name": "pound",
      "display": "lb",
    },
    "VOLUME": {
      "code": "ft3",
      "name": "cubic feet",
      "display": "ft\u00B3",
    },
    "MEASURE": {
      "code": "in",
      "name": "inch",
      "display": "in",
    },
  },
};
