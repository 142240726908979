<div class="cargo-aircraft-only">
  <div class="cargo-aircraft-only__body">
    <div class="question">
      {{ question }}
    </div>
    <div class="switch">
      <ng-container *ngTemplateOutlet="switchTpl"></ng-container>
    </div>
  </div>
</div>

<ng-template #switchTpl>
  <svg
    *ngIf="selectedAnswer.value === answers.YES"
    width="59"
    height="23"
    viewBox="0 0 59 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    (click)="selectAnswer(options[0])"
  >
    <rect x="0.5" y="0.5" width="58" height="22" rx="11" stroke="#4D578D" />
    <path
      d="M36.4583 6.939L33.5073 12.581V16H32.3243V12.581L29.3603 6.939H30.6733L32.9093 11.528L35.1453 6.939H36.4583ZM38.8891 7.901V10.93H42.1911V11.905H38.8891V15.025H42.5811V16H37.7061V6.926H42.5811V7.901H38.8891ZM47.2441 16.091C46.6461 16.091 46.1088 15.987 45.6321 15.779C45.1641 15.5623 44.7958 15.2677 44.5271 14.895C44.2585 14.5137 44.1198 14.076 44.1111 13.582H45.3721C45.4155 14.0067 45.5888 14.3663 45.8921 14.661C46.2041 14.947 46.6548 15.09 47.2441 15.09C47.8075 15.09 48.2495 14.9513 48.5701 14.674C48.8995 14.388 49.0641 14.024 49.0641 13.582C49.0641 13.2353 48.9688 12.9537 48.7781 12.737C48.5875 12.5203 48.3491 12.3557 48.0631 12.243C47.7771 12.1303 47.3915 12.009 46.9061 11.879C46.3081 11.723 45.8271 11.567 45.4631 11.411C45.1078 11.255 44.8001 11.0123 44.5401 10.683C44.2888 10.345 44.1631 9.89433 44.1631 9.331C44.1631 8.837 44.2888 8.39933 44.5401 8.018C44.7915 7.63667 45.1425 7.342 45.5931 7.134C46.0525 6.926 46.5768 6.822 47.1661 6.822C48.0155 6.822 48.7088 7.03433 49.2461 7.459C49.7921 7.88367 50.0998 8.447 50.1691 9.149H48.8691C48.8258 8.80233 48.6438 8.499 48.3231 8.239C48.0025 7.97033 47.5778 7.836 47.0491 7.836C46.5551 7.836 46.1521 7.966 45.8401 8.226C45.5281 8.47733 45.3721 8.83267 45.3721 9.292C45.3721 9.62133 45.4631 9.89 45.6451 10.098C45.8358 10.306 46.0655 10.4663 46.3341 10.579C46.6115 10.683 46.9971 10.8043 47.4911 10.943C48.0891 11.1077 48.5701 11.2723 48.9341 11.437C49.2981 11.593 49.6101 11.84 49.8701 12.178C50.1301 12.5073 50.2601 12.958 50.2601 13.53C50.2601 13.972 50.1431 14.388 49.9091 14.778C49.6751 15.168 49.3285 15.4843 48.8691 15.727C48.4098 15.9697 47.8681 16.091 47.2441 16.091Z"
      fill="#4D578D"
    />
    <circle cx="12.5" cy="11.5" r="8.5" fill="#4D578D" />
  </svg>
  <svg
    *ngIf="selectedAnswer.value === answers.NO"
    width="59"
    height="23"
    viewBox="0 0 59 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    (click)="selectAnswer(options[1])"
  >
    <rect x="0.5" y="0.5" width="58" height="22" rx="11" stroke="#979797" />
    <path
      d="M17.7646 16H16.5816L11.8236 8.785V16H10.6406V6.926H11.8236L16.5816 14.128V6.926H17.7646V16ZM23.8893 16.091C23.0486 16.091 22.2816 15.896 21.5883 15.506C20.8949 15.1073 20.3446 14.557 19.9373 13.855C19.5386 13.1443 19.3393 12.347 19.3393 11.463C19.3393 10.579 19.5386 9.786 19.9373 9.084C20.3446 8.37333 20.8949 7.823 21.5883 7.433C22.2816 7.03433 23.0486 6.835 23.8893 6.835C24.7386 6.835 25.5099 7.03433 26.2033 7.433C26.8966 7.823 27.4426 8.369 27.8413 9.071C28.2399 9.773 28.4393 10.5703 28.4393 11.463C28.4393 12.3557 28.2399 13.153 27.8413 13.855C27.4426 14.557 26.8966 15.1073 26.2033 15.506C25.5099 15.896 24.7386 16.091 23.8893 16.091ZM23.8893 15.064C24.5219 15.064 25.0896 14.9167 25.5923 14.622C26.1036 14.3273 26.5023 13.907 26.7883 13.361C27.0829 12.815 27.2303 12.1823 27.2303 11.463C27.2303 10.735 27.0829 10.1023 26.7883 9.565C26.5023 9.019 26.1079 8.59867 25.6053 8.304C25.1026 8.00933 24.5306 7.862 23.8893 7.862C23.2479 7.862 22.6759 8.00933 22.1733 8.304C21.6706 8.59867 21.2719 9.019 20.9773 9.565C20.6913 10.1023 20.5483 10.735 20.5483 11.463C20.5483 12.1823 20.6913 12.815 20.9773 13.361C21.2719 13.907 21.6706 14.3273 22.1733 14.622C22.6846 14.9167 23.2566 15.064 23.8893 15.064Z"
      fill="#979797"
    />
    <circle cx="46.5" cy="11.5" r="8.5" fill="#979797" />
  </svg>
</ng-template>
