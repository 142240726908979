import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import { ICountry } from "../../model";
import { ErrorUtil } from "../../utils/error.util";
import { API_CORE } from "../../constant/api-core.const";

@Injectable({
  "providedIn": "root",
})
export class CountryService {
  async getCountries (): Promise<ICountry[]> {
    return API.get(API_CORE.name, API_CORE.URLs.Country.Countries, {}).catch(
      ErrorUtil.handleError
    );
  }
}
