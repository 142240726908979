import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { WeightTypeEnum } from "@cai-services";

@Component({
  "selector": "cai-load-type-weight-switch",
  "templateUrl": "./load-type-weight-switch.component.html",
  "styleUrls": ["./load-type-weight-switch.component.scss"],
})
export class CaiLoadTypeWeightSwitchComponent {
  @Input() value = WeightTypeEnum.PER_ITEM;
  @Input() disabled: boolean;
  @Output() valueChange = new EventEmitter<WeightTypeEnum>();

  weightTypeEnum = WeightTypeEnum;

  constructor (private readonly cdr: ChangeDetectorRef) {}

  toggleWeightType (): void {
    if (this.disabled) {
      return;
    }
    switch (this.value) {
      case WeightTypeEnum.PER_ITEM:
        this.value = WeightTypeEnum.TOTAL;
        break;
      case WeightTypeEnum.TOTAL:
        this.value = WeightTypeEnum.PER_ITEM;
        break;
    }
    this.valueChange.emit(this.value);
    this.cdr.detectChanges();
  }
}
