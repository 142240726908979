import { ElementRef } from "@angular/core";

export class EventsUtil {
  static handleInputFromPaste (
    $event: any,
    validationFn: (payload) => boolean
  ): void {
    const clipboardData = $event.clipboardData,
     pastedText: string = clipboardData.getData("text");

    if (pastedText && pastedText.length) {
      if (!validationFn(pastedText)) {
        $event.preventDefault();
      }
    }
  }

  static handleInputFromKeyPress (
    $event: any,
    value: string,
    inputElement: ElementRef,
    validationFn: (payload) => boolean
  ): void {
    const key = $event.key,
     selectionStart = inputElement.nativeElement.selectionStart,
     selectionEnd = inputElement.nativeElement.selectionEnd,
     text = value != null ? value.toString() : "",

     newValue =
      text.substring(0, selectionStart) + key + text.substring(selectionEnd);
    if (!validationFn(newValue)) {
      $event.preventDefault();
    }
  }

  static validateInputByRegExp (regeExp: RegExp, value: string): boolean {
    const _inputRegex = new RegExp(regeExp);
    if (_inputRegex.test(value)) {
      return true;
    }
    return false;
  }

  static validateInputFromKeyup (
    $event: any,
    value: any,
    validationFn: (payload) => boolean
  ): boolean {
    const key = $event.keyCode || $event.charCode;
    if (key === 8 || key === 46) {
      return !value || validationFn(value.toString());
    }
    return true;
  }
}
