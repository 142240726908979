<svg
  width="102"
  height="102"
  viewBox="0 0 102 102"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_224:1864)">
    <g filter="url(#filter0_d_224:1864)">
      <path
        d="M55.8969 83.6504L61.2126 80.5022L61.1204 50.043L75.7386 41.3856C77.9446 40.0791 78.6706 37.2439 77.3639 35.0382C76.0573 32.8325 73.2217 32.1066 71.0157 33.413L56.3976 42.0705L29.727 27.3521L24.4113 30.5003L44.4373 49.1539L29.8191 57.8113L21.8967 53.5287L17.9099 55.8898L26.8649 64.9457L30.5042 77.1498L34.4909 74.7887L34.542 65.7838L49.1601 57.1264L55.8969 83.6504Z"
        fill="url(#paint0_linear_224:1864)"
      />
    </g>
  </g>
  <defs>
    <filter
      id="filter0_d_224:1864"
      x="11.9097"
      y="23.3521"
      width="72.104"
      height="68.2983"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="2" />
      <feGaussianBlur stdDeviation="3" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_224:1864"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_224:1864"
        result="shape"
      />
    </filter>
    <linearGradient
      id="paint0_linear_224:1864"
      x1="77.3639"
      y1="35.0382"
      x2="24.2105"
      y2="66.5257"
      gradientUnits="userSpaceOnUse"
    >
      <stop [attr.stop-color]="startClr" />
      <stop offset="1" [attr.stop-color]="endClr" />
    </linearGradient>
    <clipPath id="clip0_224:1864">
      <rect
        width="74.1312"
        height="74.1358"
        fill="white"
        transform="matrix(0.509674 0.860368 -0.860425 0.509578 63.7881 0)"
      />
    </clipPath>
  </defs>
</svg>
