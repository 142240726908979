import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  TemplateRef,
  ViewContainerRef,
  NgZone,
  EmbeddedViewRef,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { filter, fromEvent, Subscription, takeWhile } from "rxjs";
import {
  AutofillDimensionsService,
  ShipmentInputs,
} from "@cai-services";
import Swal from "sweetalert2";
import Popper from "popper.js";
import { CaiSuccessMessageComponent } from "../../../success-message/success-message.component";
import { CaiLoadTypeAutofillText } from "../../modals";
import { SessionService } from "../../../../core/_services/session.service";

@Component({
  "selector": "cai-load-type-dimension-autofill",
  "templateUrl": "./load-type-dimension-autofill.component.html",
  "styleUrls": ["./load-type-dimension-autofill.component.scss"],
})
export class CaiLoadTypeAutofillDimensions {
  @ViewChild("excelInput") excelInput: ElementRef;
  @ViewChild("imageInput") imageInput: ElementRef;
  @Output() autofillDimensionsChange = new EventEmitter<ShipmentInputs>();
  constructor (
    private readonly modalService: NgbModal,
    private readonly sessionService: SessionService,
    private readonly cdr: ChangeDetectorRef,
    private readonly autofillDimensionsService: AutofillDimensionsService,
    private readonly vcr: ViewContainerRef,
    private readonly zone: NgZone
  ) {}

  popperRef: Popper;
  view: EmbeddedViewRef<any>;
  sub: Subscription;
  enableTextEdit: boolean;
  inputText: string;
  isSuccess = false;

  open (button: HTMLElement, dropdownTpl: TemplateRef<any>): void {
    if (!!this.popperRef) {
      this.close();
    }
    this.view = this.vcr.createEmbeddedView(dropdownTpl);
    const dropdown = this.view.rootNodes[0];

    document.body.appendChild(dropdown);
    dropdown.style["z-index"] = "1111";

    this.zone.runOutsideAngular(() => {
      this.popperRef = new Popper(button, dropdown, {
        "placement": "bottom-end",
        "removeOnDestroy": true,
        "modifiers": {
          "preventOverflow": { "escapeWithReference": true },
        },
      });
    });

    this.inputText = localStorage.getItem("autofillText");
    this.enableTextEdit = this.sessionService.getItemSessionStorage(
      "browserRefreshed"
    )
      ? false
      : this.inputText
      ? true
      : false;
    this.cdr.detectChanges();
    this.handleClickOutside();
  }

  onAutofillOptionClick (type: string) {
    switch (type) {
      case "SIMPLE_TEXT":
        const modalRef = this.modalService.open(CaiLoadTypeAutofillText, {
          "centered": true,
          "size": "lg",
        });
        modalRef.componentInstance.inputText = this.enableTextEdit
          ? this.inputText
          : null;
        this.sub = modalRef.componentInstance?.updateDimensions?.subscribe(
          (newDimensions) => {
            newDimensions.dimensions = newDimensions.dimensions.filter(
              (item) =>
                item.pieces ||
                item.distanceUnit ||
                item.length ||
                item.width ||
                item.height ||
                item.weightUnit ||
                item.weight ||
                item.volume
            );
            this.autofillDimensionsChange.emit(newDimensions);
            this.showSuccessOrError(newDimensions);
          }
        );
        break;
      case "EXCEL":
        this.excelInput.nativeElement.click();
        break;
      case "IMAGE":
        this.imageInput.nativeElement.click();
    }
  }

  handleFileChosen (e: any, type: string) {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = async () => {
      const newDimensions =
        await this.autofillDimensionsService.extractShipmentInputs(
          `${e.target.files[0].name}:${reader.result}`,
          type
        );
      newDimensions.dimensions = newDimensions.dimensions.filter(
        (item) =>
          item.pieces ||
          item.distanceUnit ||
          item.length ||
          item.width ||
          item.height ||
          item.weightUnit ||
          item.weight ||
          item.volume
      );
      this.autofillDimensionsChange.emit(newDimensions);
      this.showSuccessOrError(newDimensions);
    };
  }

  showSuccessOrError (dimensions: ShipmentInputs) {
    const success = this.isDimensionsComplete(dimensions);
    if (success) {
      const modalref = this.modalService.open(CaiSuccessMessageComponent, {
        "centered": true,
        "windowClass": "success-checkmark",
      });
      modalref.componentInstance.isShow = true;
      setTimeout(() => {
        modalref.dismiss();
      }, 2000);
    } else {
      Swal.mixin({
        "customClass": {
          "confirmButton": "badge rounded-pill",
          "container": "autofill-error-container",
        },
      }).fire({
        "html":
          "<div> " +
          "<div>" +
          "<span class=\"error-title\">" +
          "Packing list text format is incorrect" +
          "</span>" +
          "<div><img class=\"error-icon\" src=\"/assets/media/icons/shipmentDetails/disruption-icon.png\"/></div>" +
          "<span class=\"error-text\">" +
          "To continue with the autofill dimensions<br/>please change the values and try again" +
          "</span>" +
          "</div>" +
          "</div>",
        "confirmButtonText": $localize`:@@global.ok: OK`,
        "showCancelButton": false,
        "showCloseButton": true,
      });
    }
  }

  isDimensionsComplete (dimensions: ShipmentInputs): boolean {
    if (dimensions?.dimensions?.length > 0) {
      return true;
    }
    return false;
  }

  close () {
    if (this.view) {
      this.view.destroy();
      this.view = null;
    }
  }

  handleClickOutside () {
    fromEvent(document, "click")
      .pipe(
        takeWhile(() => !!this.popperRef),
        filter(({ target }) => {
          const origin = this.popperRef.reference as HTMLElement;
          return origin.contains(target as HTMLElement) === false;
        })
      )
      .subscribe(() => {
        this.close();
        this.cdr.detectChanges();
      });
  }

  onDownloadExcelTemplateClick () {
    const excelFilePath =
      "/static/autofill-template/autofill-dimensions-excel-template.xlsx";
    window.open(excelFilePath);
  }
}
