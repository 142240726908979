import { Component, Inject, Input, OnInit } from "@angular/core";
import {
  IAirport,
  IOffice,
  IOfficeAccess,
  IPermissionSet,
  OfficeService,
  PermissionNameEnum,
} from "@cai-services";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import { datadogRum } from "@datadog/browser-rum";
import {
  ApplicationProperties,
  GlobalService,
  PermissionGroup,
  SessionService,
  User,
  UserFeaturesEnum,
} from "../../../../core";
import { APP_PROPS } from "../../../../cai-common.module";
import { minItems } from "../../../../validators";
import { HomepageCommonUtil } from "../../../../utils";
import { environment } from "../../../../environments";

@Component({
  "selector": "cai-office-edit-user-modal",
  "templateUrl": "./office-edit-user-modal.component.html",
  "styleUrls": ["./office-edit-user-modal.component.scss"],
})
export class OfficeEditUserModal implements OnInit {
  @Input()
  activeOffice: IOffice;
  @Input()
  modalRef: NgbModalRef;
  @Input()
  userToEdit: User;
  @Input()
  rolesOfCompany: IPermissionSet[];
  @Input()
  relevantRoles: string[];
  hiddenRolesOfUser: string[];
  editUserForm: FormGroup;
  editUserRolesForm: FormGroup;
  airportsOfCountry: IAirport[];
  activeTab: "general" | "roles-and-permissions";
  displayProTag = false;

  features = environment.features;

  @Input()
  permissionGroups: PermissionGroup[];
  permissionDict: { [permissionName: string]: boolean } = {};

  constructor (
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private readonly fb: FormBuilder,
    private readonly officeService: OfficeService,
    private readonly globalService: GlobalService,
    private readonly sessionService: SessionService,
  ) {
    this.activeTab = this.isWalletApp ? "roles-and-permissions" : "general";
  }

  ngOnInit () {
    this.addDatadogFlagEvaluations();
    this.initializeHiddenRoles();
    this.initializeEditUserForm();
    this.initializeEditUserRolesForm();
    this.initializeAirportsOfCountry();
  }

  addDatadogFlagEvaluations () {
    if (environment.datadog) {
      datadogRum.addFeatureFlagEvaluation(
        UserFeaturesEnum.ROLE_PERMISSION_MANAGEMENT,
        this.isRolePermissionManagementEnabled,
      );

      datadogRum.addFeatureFlagEvaluation(
        UserFeaturesEnum.CARGOMART_PRO,
        this.isCargoMartProEnabled,
      );
    }
  }

  private initializeHiddenRoles () {
    /**
     * this is important because the 'roles' form field does not contain
     * irrelevant roles, but we need to include these roles in the final request
     * to prevent deleting them from the user
     */
    this.hiddenRolesOfUser =
      this.getActiveOfficeAccessOfUser(this.userToEdit)
        ?.roles?.filter((role) => !this.relevantRoles.includes(role.name))
        ?.map((role) => role.name) ?? [];
  }

  private initializeEditUserForm () {
    this.editUserForm = this.fb.group({
      "firstName": [this.userToEdit.firstName, [Validators.required]],
      "lastName": [this.userToEdit.lastName, [Validators.required]],
      "email": [{ "value": this.userToEdit.userEmail, "disabled": true }],
      "country": [
        { "value": this.userToEdit.country?.countryName, "disabled": true },
      ],
      "number": [this.userToEdit.contact?.phoneNumber],
      "originAirport": [this.userToEdit.originAirport?.airportCode],
    });
  }

  private async initializeEditUserRolesForm (): Promise<void> {
    const userRoles =
      this.getActiveOfficeAccessOfUser(this.userToEdit)
        ?.roles?.filter((role) => this.relevantRoles.includes(role.name))
        ?.map((role) => role.name) ?? [];
    this.updatePermissionDict(userRoles);

    this.editUserRolesForm = this.fb.group({
      "roles": [userRoles, minItems(1)],
    });

    this.editUserRolesForm.controls.roles.valueChanges.subscribe(
      (updatedRoles: string[]) => {
        this.updatePermissionDict(updatedRoles);
      },
    );

    this.editUserRolesForm.markAsPristine();

    if (this.isCargoMartProEnabled && !this.isRolePermissionManagementEnabled) {
      this.editUserRolesForm.get("roles").disable();
      this.displayProTag = true;
    }
  }

  private getActiveOfficeAccessOfUser (user: User): IOfficeAccess {
    return user.officeAccesses?.find(
      (officeAccess) => officeAccess.office.id === this.activeOffice.id,
    );
  }

  private updatePermissionDict (roles: string[]) {
    this.permissionDict = {};

    const permissionsOfSelectedRoles = this.rolesOfCompany
      .filter((role) => roles.includes(role.name))
      .map((role) => role.permissions)
      .reduce(
        (roleAPermissions, roleBPermissions) => [
          ...roleAPermissions,
          ...roleBPermissions,
        ],
        [],
      );

    permissionsOfSelectedRoles.forEach(
      (permission) =>
        (this.permissionDict[PermissionNameEnum[permission.name]] = true),
    );
  }

  private async initializeAirportsOfCountry (): Promise<void> {
    this.airportsOfCountry = (await this.globalService.getAirports()).filter(
      (airport) =>
        airport.countryCode === this.activeOffice.country.countryCode,
    );
    if (
      this.userToEdit.originAirport &&
      !this.airportsOfCountry.some(
        (airport) =>
          airport.airportCode === this.userToEdit.originAirport?.airportCode,
      )
    ) {
      this.airportsOfCountry.push(this.userToEdit.originAirport);
    }
  }

  generalFormHasError (controlName: string, validationType: string): boolean {
    const control = this.editUserForm?.controls[controlName];
    if (!control) {
      return false;
    }

    return control.hasError(validationType);
  }

  rolesFormHasError (controlName: string, validationType: string): boolean {
    const control = this.editUserRolesForm?.controls[controlName];
    if (!control) {
      return false;
    }

    return control.hasError(validationType);
  }

  async updateUserInformation (): Promise<void> {
    if (!this.editUserForm.valid) {
      return;
    }

    if (!this.hasChangesToUserInformation()) {
      this.modalRef.dismiss();
      return;
    }

    try {
      await this.officeService.updateUserFromOffice(
        this.activeOffice.id,
        this.userToEdit.userId,
        {
          "firstName": this.editUserForm.controls.firstName.value,
          "lastName": this.editUserForm.controls.lastName.value,
          "contactInfo": {
            "countryCode": this.userToEdit.contact?.countryCode,
            "cityCode": this.userToEdit.contact?.cityCode,
            "phoneNumber": this.editUserForm.controls.number.value,
          },
          "airportCode": this.editUserForm.controls.originAirport.value,
        },
      );
      this.onSaveSuccess();
    } catch {
      this.onSaveError();
    }
  }

  private hasChangesToUserInformation (): boolean {
    return (
      this.editUserForm.controls.firstName.value !==
        this.userToEdit.firstName ||
      this.editUserForm.controls.lastName.value !==
        this.userToEdit.lastName ||
      (this.editUserForm.controls.number.value ?? "") !==
        (this.userToEdit.contact?.phoneNumber ?? "") ||
      (this.editUserForm.controls.originAirport.value ?? "") !==
        (this.userToEdit.originAirport?.airportCode ?? "")
    );
  }

  private onSaveSuccess () {
    Swal.fire({
      "text": $localize`:@@office-management.success.update-office-member:Success! The information has been saved.`,
      "icon": "success",
      "showCancelButton": false,
      "allowOutsideClick": false,
    });
    this.modalRef.close(true);
  }

  private onSaveError () {
    Swal.fire(
      "",
      $localize`:@@office-management.error.update-office-member:Sorry, The user could not be updated at this time. Please try again in 30 minutes, or reach out to us at support@cargoai.co`,
      "error",
    );
  }

  async updateUserRoles (): Promise<void> {
    if (!this.editUserRolesForm.valid) {
      return;
    }

    if (!this.hasChangesToUserRoles()) {
      this.modalRef.dismiss();
      return;
    }

    try {
      await this.officeService.updateUserFromOffice(
        this.activeOffice.id,
        this.userToEdit.userId,
        {
          "roles": [
            ...this.editUserRolesForm.controls.roles.value,
            ...this.hiddenRolesOfUser,
          ],
        },
      );
      this.onSaveSuccess();
    } catch {
      this.onSaveError();
    }
  }

  private hasChangesToUserRoles (): boolean {
    const userToEditRoles =
      this.getActiveOfficeAccessOfUser(this.userToEdit)?.roles?.map(
        (role) => role.name,
      ) ?? [];

    return (
      this.editUserRolesForm.controls.roles.value.join(",") !==
      userToEditRoles.join(",")
    );
  }

  get isWalletApp (): boolean {
    return this.appProperties.name === "cargowallet";
  }

  get isRolePermissionManagementEnabled (): boolean {
    const officeId = this.sessionService.getSelectedOfficeId(),
     officeAccess = this.sessionService.getCurrentUser()?.officeAccesses?.find((oa) => oa.office.id == officeId),
     office = officeAccess ? officeAccess.office : null;
    return HomepageCommonUtil.isFeatureEnabled(
      office?.activatedFeatures,
      UserFeaturesEnum.ROLE_PERMISSION_MANAGEMENT,
    );
  }

  get isCargoMartProEnabled (): boolean {
    return (
      HomepageCommonUtil.isFeatureEnabled(
        this.sessionService.getCurrentUser()?.activatedFeatures,
        UserFeaturesEnum.CARGOMART_PRO,
      ) || this.features?.cargomartPro
    );
  }
}
