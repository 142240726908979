enum WeightTypeDisplay {
  CHARGEABLE_WEIGHT = "chargeable weight",
  GROSS_WEIGHT = "gross weight",
  SHIPMENT = "shipment",
  PIECES = "pc(s)",
}

enum WeightTypeFrench {
  CHARGEABLE_WEIGHT = "poids taxable",
  GROSS_WEIGHT = "poids brut",
  SHIPMENT = "Expédition",
  PIECES = "pc(s)",
}

export { WeightTypeDisplay, WeightTypeFrench };
