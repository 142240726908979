import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  "selector": "[caiAwbFormat]",
})
export class CaiAwbFormatDirective {
  private previousValue = "";

  constructor (private readonly el: ElementRef) {}

  @HostListener("input", ["$event"]) onInput (event: any) {
    const input = this.el.nativeElement;
    let value = input.value.replace(/-/g, ""),
     cursorPosition = input.selectionStart;

    // Format the input value
    if (value.length > 3) {
      value = `${value.substring(0, 3)}-${value.substring(3)}`;
    }

    const wasDeleting = this.previousValue.length > value.length;

    input.value = value;

    // Adjust cursor position
    if (!wasDeleting && cursorPosition > 3) {
      if (value[cursorPosition - 1] === "-") {
        cursorPosition += 1;
      }
    } else if (wasDeleting && cursorPosition === 4) {
      cursorPosition -= 1;
    }

    input.setSelectionRange(cursorPosition, cursorPosition);

    this.previousValue = value;
  }
}
