// Angular
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import { Subject } from 'rxjs';
// Auth
import { Auth } from '@aws-amplify/auth';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import { LoginFormValidators } from '../../../../../../cai-common/src/lib/validators/loginForm.validator';
import { Forwarder } from '../../../core/_models/forwarder.model';
import { OTPStatus } from '../../layout/new-otp-container/new-otp-container.component';
import { environment } from '../../../environments';
import { AuthNoticeService } from '../../../core/_misc/auth-notice/auth-notice.service';
import { REGEXP_PASSWORD } from '../../../core/_constants/constants';
import { BrandIconUtil } from '../../../utils/brand.util';
import { APP_PROPS } from '../../../cai-common.module';
import { ApplicationProperties } from '../../../core/_base/layout/models/app-properties.model';

@Component({
  selector: 'kt-forgot-password',
  templateUrl: './new-forgot-password.component.html',
  styleUrls: ['./new-forgot-password.component.scss'],
})
export class NewForgotPasswordComponent implements OnInit, OnDestroy {
  //declare variables
  email: string;
  forwarder: Forwarder;
  // Public params
  forgotPasswordForm: FormGroup;
  newPasswordForm: FormGroup;
  loading = false;
  errors: any = [];
  requireOTP = false;
  errorMessage: string;
  otpCode: string;
  requireNewPassword = false;
  password: string;
  OTPStatus: OTPStatus;
  features = environment.features;
  showSuccessMessage: boolean;
  timer = 3;
  interval: NodeJS.Timeout;
  isShowNewPassword: boolean;
  isShowConfirmNewPassword: boolean;
  showResetPasswordSuccessMessage: boolean;
  redirectUrl: '/auth/login';
  registerUrl: string;
  private readonly unsubscribe = new Subject<void>();

  /**
   * Component constructor
   *
   * @param authService
   * @param authNoticeService
   * @param translate
   * @param router
   * @param fb
   * @param cdr
   */
  constructor(
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    public authNoticeService: AuthNoticeService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.registerUrl = appProperties.registerUrl;
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  public static matchValues(
    matchTo: string, // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null =>
      !!control.parent &&
      !!control.parent.value &&
      control.value === control.parent.controls[matchTo].value
        ? null
        : {
            isMatching: {
              message: $localize`:@@resetPassword.matchPassword:Your passwords do not match`,
            },
          };
  }

  ngOnInit() {
    this.initRegistrationForm();
    this.route.queryParams.subscribe((params) => {
      if (params.otp) {
        this.otpCode = params.otp;
        this.email = params.user_name;
        this.requireNewPassword = true;
        this.cdr.detectChanges();
      }
    });
    if (environment.datadog) {
      datadogRum.addFeatureFlagEvaluation('i18n', this.features.i18n);
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  /**
   * Form initalization
   * Default params, validators
   */
  initRegistrationForm() {
    this.forgotPasswordForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          LoginFormValidators.required('Email'),
          LoginFormValidators.email(),
          LoginFormValidators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ]),
      ],
    });

    this.newPasswordForm = this.fb.group({
      password: [
        { value: '', disabled: false },
        [
          LoginFormValidators.required,
          LoginFormValidators.maxLength(100),
          LoginFormValidators.pattern(REGEXP_PASSWORD, true),
        ],
      ],
      passwordConfirmation: [
        { value: '', disabled: false },
        [
          LoginFormValidators.required,
          NewForgotPasswordComponent.matchValues('password'),
          LoginFormValidators.maxLength(32),
          LoginFormValidators.pattern(REGEXP_PASSWORD, true),
        ],
      ],
    });

    this.newPasswordForm.controls.password.valueChanges.subscribe(() =>
      this.newPasswordForm.controls.passwordConfirmation.updateValueAndValidity(),
    );
  }

  private handleSuccess(resetPasswordSuccessMessage?: boolean): void {
    if (resetPasswordSuccessMessage) {
      this.showResetPasswordSuccessMessage = true;
    } else {
      this.showSuccessMessage = true;
    }
    if (this.timer === 3) {
      this.interval = setInterval(() => {
        if (this.timer > 1) {
          this.timer -= 1;
        }
        this.cdr.detectChanges();
      }, 1000);
    } else if (this.timer <= 1) {
      clearInterval(this.interval);
    }
    setTimeout(() => {
      if (resetPasswordSuccessMessage) {
        this.showResetPasswordSuccessMessage = false;
        this.router.navigateByUrl(this.redirectUrl);
      }
    }, 3300);
  }

  /**
   * Form Submit
   */
  submit() {
    const controls = this.forgotPasswordForm.controls;
    /** check form */
    if (this.forgotPasswordForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched(),
      );
      return;
    }
    this.loading = true;
    Auth.forgotPassword(controls.email.value, {
      origin: window.location.origin,
    })
      .then(() => {
        this.handleSuccess();
        this.loading = false;
        this.cdr.detectChanges();
      })
      .catch((err) => {
        this.loading = false;
        Swal.fire(
          'If the user is registered, you will get an email.',
          'If you do not have an account, please sign up.',
          'info',
        );
        this.cdr.detectChanges();
      });
  }

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(
    controlName: string,
    validationType: string,
    isField?: boolean,
  ): boolean {
    const control = this.forgotPasswordForm.controls[controlName];
    if (!control) {
      return false;
    }

    if (
      control.status === 'INVALID' &&
      isField &&
      (control.dirty || control.touched)
    ) {
      return true;
    }
    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }

  isControlHasErrorNew(
    controlName: string,
    validationType: string,
    isField?: boolean,
  ): boolean {
    const control = this.newPasswordForm.controls[controlName];
    if (!control) {
      return false;
    }
    if (
      control.status === 'INVALID' &&
      isField &&
      (control.dirty || control.touched)
    ) {
      return true;
    }
    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }

  submitOTP(): void {
    const controls = this.newPasswordForm.controls;
    if (this.newPasswordForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched(),
      );
      Swal.fire('Error', 'Please enter the same password!', 'error');
      return;
    }
    this.loading = true;
    Auth.forgotPasswordSubmit(
      this.email,
      this.otpCode,
      controls.passwordConfirmation.value,
    )
      .then(() => {
        this.handleSuccess(true);
      })
      .catch((err) => {
        this.loading = false;
        alert(
          `There is an error, please try again or contact us!\n
           ${err.name}: ${err.message}\n
           \n
           ${err.stack}`,
        );
        this.cdr.detectChanges();
      });
  }

  get forgotPasswordSuccessMessage(): string {
    return $localize`:@@forgot-password.successMessage:Instructions to reset password have been sent to your registered email address.`;
  }

  get resetPasswordSuccessMessage(): string {
    return $localize`:@@forgot-password.resetPasswordSuccessMessage:You will be redirected to Login in`;
  }

  get forgotPasswordSuccessTitle(): string {
    return $localize`:@@forgot-password.title:SUCCESS!`;
  }

  openSite(): void {
    window.open('https://www.cargoai.co/', '_blank', 'noopener');
  }

  isDisabled(): boolean {
    const controls = this.forgotPasswordForm?.controls;
    if (controls?.email.status === 'INVALID' || controls?.email.value === '') {
      return true;
    }
    return false;
  }

  isDisabledResetPassword(): boolean {
    const controls = this?.newPasswordForm?.controls;
    if (
      controls?.password.status === 'INVALID' ||
      controls?.password.value === '' ||
      controls?.passwordConfirmation.status === 'INVALID' ||
      controls?.passwordConfirmation.value === ''
    ) {
      return true;
    }
    return false;
  }

  toggleEye1(): void {
    this.isShowNewPassword = !this.isShowNewPassword;
  }
  toggleEye2(): void {
    this.isShowConfirmNewPassword = !this.isShowConfirmNewPassword;
  }

  get otherBrand(): boolean {
    return !BrandIconUtil.isCargoAiDomain();
  }

  get backgroundImage(): string {
    return BrandIconUtil.fetchBackgroundIcon(
      this.appProperties.logo,
      '/assets/media/logos/',
    );
  }
}
