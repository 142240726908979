<div class="active_container">
  <div class="space"></div>
  <div
    class="active_container__body"
    [class.right-aligned]="isRightAligned"
    [style.padding-top.px]="paddingTop"
  >
    <div class="question">
      <ng-container *ngFor="let label of questionArray; let i = index">
        <span *ngIf="i !== 1" class="label" [class.underline]="i !== 1">{{
          label
        }}</span>
        <span *ngIf="i === 1" class="label"
          >‘<span class="underline">{{ label }}</span
          >’</span
        >
      </ng-container>
    </div>
    <div class="switch">
      <ng-container *ngTemplateOutlet="switchTpl; context: {}"></ng-container>
    </div>
  </div>
</div>

<ng-template #switchTpl>
  <div class="custom-switch">
    <span
      *ngIf="selectedAnswer === options[0]"
      (click)="selectAnswer(options[1])"
    >
      <svg
        width="39"
        height="18"
        viewBox="0 0 39 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="39" height="18" rx="9" fill="#979797" />
        <circle cx="10" cy="9" r="7" fill="white" />
      </svg>
    </span>
    <span
      *ngIf="selectedAnswer === options[1]"
      (click)="selectAnswer(options[0])"
    >
      <svg
        width="39"
        height="18"
        viewBox="0 0 39 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="39" height="18" rx="9" fill="#4D578D" />
        <circle cx="29" cy="9" r="7" fill="white" />
      </svg>
    </span>
  </div>
</ng-template>
