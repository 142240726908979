import { AppNotificationTypeEnum } from "../../enum/app-notification-type.enum";
import { IAppNotification } from "../../model/app-notification.model";

const mockedNotificationParameters = {
  "competitorCount": 0,
  "current": {
    "airlineBrand": {
      "airlineCompanyName": "Singapore Airlines",
      "airlineCompanyCode": "SQ",
    },
    "fromValidity": "2022-01-14T08:48:30Z",
    "minWeight": 266.8,
    "maxWeight": 500.25,
    "awb": "61835776565",
    "quoteId": 22342,
    "quoteStatus": "IN_TRANSIT",
    "rejectedReasons": "",
    "destinationAirport": { "airportCode": "AMS" },
    "quoteCurrency": "SGD",
    "originAirport": { "airportCode": "SIN" },
    "suggestedPrice": 11.3,
    "actualWeight": 25,
    "chargeableWeight": 333.5,
    "trips": [
      {
        "flightNo": "SQ324",
        "startDepartureDate": "2022-01-20T23:55:00+08:00",
        "endArrivalDate": "2022-01-21T06:45:00+01:00",
        "flights": [
          {
            "flightNo": "SQ324",
            "destRegionAirport": { "airportCode": "AMS" },
            "departureDate": "2022-01-20T23:55:00+08:00",
            "originRegionAirport": { "airportCode": "SIN" },
            "arrivalDate": "2022-01-21T06:45:00+01:00",
          },
        ],
      },
    ],
    "actualVolume": 2,
    "quoteRequest": {
      "bookingType": "SEARCH_BOOK",
      "forwarder": {
        "firstName": "Arthur",
        "lastName": "Kwan2",
        "userEmail": "teamcargoai+Schenker_SIN@gmail.com",
        "company": {
          "companyName": "Forwarder",
          "logoType": "jpeg",
          "logoHash": "dd4b9346-85ce-409f-a8dd-f7f78448add1",
        },
      },
      "measurementSystem": "METRIC",
      "originalQuotePrice": 11.3,
      "quoteItems": [{ "typeOfProduct": { "productCode": "GCR" }, "numOfItems": 3 }],
    },
    "validity": "2022-01-16T08:48:30Z",
    "quoteDate": "2022-01-14T08:48:22Z",
    "rateDetail": { "total": 3768.55, "currency": "SGD" },
    "contacts": [
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "teamcargoai+SQ_SIN6@gmail.com",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1208@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Test",
          "lastName": "Airline",
          "userEmail": "tester+0629a@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Terry",
          "lastName": "Airliner",
          "userEmail": "tester+ai111921@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "96faa56c-6282-4d76-bbb9-b8400932edc2",
          },
        },
      },
      {
        "contact": {
          "firstName": "Test",
          "lastName": "Airline",
          "userEmail": "tester+0113airline@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "8bdb056f-855e-4341-96e7-aceea387d543",
          },
        },
      },
      {
        "contact": {
          "firstName": "Test",
          "lastName": "Airline",
          "userEmail": "tester+1119airline@wedoqa.co",
          "company": {
            "companyName": "wedoqa1117",
            "logoType": "",
            "logoHash": "a9662807-7319-4ae4-8758-72db537b90e7",
          },
        },
      },
      {
        "contact": {
          "firstName": "Substitute",
          "lastName": "Airline",
          "userEmail": "tester+automationairline39b@alasdoo.com",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Test",
          "lastName": "Airline",
          "userEmail": "tester+0426a@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co12081111@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co12081111@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+1012serw@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co12081111@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Test",
          "lastName": "Airline",
          "userEmail": "tester+automationairline39a@alasdoo.com",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Pen",
          "lastName": "Airliner",
          "userEmail": "tester+ai1117@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "96faa56c-6282-4d76-bbb9-b8400932edc2",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+1021test@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "8bdb056f-855e-4341-96e7-aceea387d543",
          },
        },
      },
      {
        "contact": {
          "firstName": "Test",
          "lastName": "Airline",
          "userEmail": "tester+1004testairline@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+0901testairline@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1208211@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1209a@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co12082111@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "L",
          "lastName": "T",
          "userEmail": "tester+ai0929@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "R",
          "lastName": "R",
          "userEmail": "tester+ai0629@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Test ",
          "lastName": "Airline",
          "userEmail": "tester+0726airline@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Paul",
          "lastName": "Airliner",
          "userEmail": "tester+ai1172@wedoqa.co",
          "company": {
            "companyName": "wedoqa1117",
            "logoType": "",
            "logoHash": "a9662807-7319-4ae4-8758-72db537b90e7",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co12081111@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+0901testairline1@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1130@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1208@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1018a2@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co09232@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+0927@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+0930kl@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+col20821@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co12081111@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Prudence",
          "lastName": "Airliner",
          "userEmail": "tester+ai1208@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "96faa56c-6282-4d76-bbb9-b8400932edc2",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+test0901airline@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co12081111@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "P",
          "lastName": "O",
          "userEmail": "tester+0622aidev@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1102@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "96faa56c-6282-4d76-bbb9-b8400932edc2",
          },
        },
      },
      {
        "contact": {
          "firstName": "Test",
          "lastName": "Airline",
          "userEmail": "tester+automationairline_f@alasdoo.com",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Temp",
          "lastName": "Airline",
          "userEmail": "tester+automationairline23temp@alasdoo.com",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+custom33467@alasdoo.com",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1209b@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Cho",
          "lastName": "Wai",
          "userEmail": "cho+air_test_16_08_21@cargoai.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co09235@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co0923@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1209@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co120821111@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1208@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+0901testair@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co120821@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+frw1116c@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "96faa56c-6282-4d76-bbb9-b8400932edc2",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+1012jkk1@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
    ],
  },
  "senderName": "Arthur Kwan2",
  "recipientType": "FORWARDER",
  "reminder": false,
  "SenderType": "AIRLINE",
  "viewLink": "https://app.dev.cargoai.co/shipment-details/22342",
  "actionDate": "2022-01-14T13:44:30Z",
  "actionLink": "https://app.dev.cargoai.co/shipment-details/22342",
  "former": {
    "airlineBrand": {
      "airlineCompanyName": "Singapore Airlines",
      "airlineCompanyCode": "SQ",
    },
    "fromValidity": "2022-01-14T08:48:30Z",
    "minWeight": 266.8,
    "maxWeight": 500.25,
    "awb": "61835776565",
    "quoteId": 22342,
    "quoteStatus": "PENDING_DELIVERY",
    "rejectedReasons": "",
    "destinationAirport": { "airportCode": "AMS" },
    "quoteCurrency": "SGD",
    "originAirport": { "airportCode": "SIN" },
    "suggestedPrice": 11.3,
    "actualWeight": 25,
    "chargeableWeight": 333.5,
    "trips": [
      {
        "flightNo": "SQ324",
        "startDepartureDate": "2022-01-20T23:55:00+08:00",
        "endArrivalDate": "2022-01-21T06:45:00+01:00",
        "flights": [
          {
            "flightNo": "SQ324",
            "destRegionAirport": { "airportCode": "AMS" },
            "departureDate": "2022-01-20T23:55:00+08:00",
            "originRegionAirport": { "airportCode": "SIN" },
            "arrivalDate": "2022-01-21T06:45:00+01:00",
          },
        ],
      },
    ],
    "actualVolume": 2,
    "quoteRequest": {
      "bookingType": "SEARCH_BOOK",
      "forwarder": {
        "firstName": "Arthur",
        "lastName": "Kwan2",
        "userEmail": "teamcargoai+Schenker_SIN@gmail.com",
        "company": {
          "companyName": "Forwarder",
          "logoType": "jpeg",
          "logoHash": "dd4b9346-85ce-409f-a8dd-f7f78448add1",
        },
      },
      "measurementSystem": "METRIC",
      "originalQuotePrice": 11.3,
      "quoteItems": [{ "typeOfProduct": { "productCode": "GCR" }, "numOfItems": 3 }],
    },
    "validity": "2022-01-16T08:48:30Z",
    "quoteDate": "2022-01-14T08:48:22Z",
    "rateDetail": { "total": 3768.55, "currency": "SGD" },
    "contacts": [
      {
        "contact": {
          "firstName": "Test",
          "lastName": "Airline",
          "userEmail": "tester+0113airline@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "8bdb056f-855e-4341-96e7-aceea387d543",
          },
        },
      },
      {
        "contact": {
          "firstName": "Test",
          "lastName": "Airline",
          "userEmail": "tester+0629a@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1130@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1208211@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1208@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Substitute",
          "lastName": "Airline",
          "userEmail": "tester+automationairline39b@alasdoo.com",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+1012serw@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+custom33467@alasdoo.com",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Test",
          "lastName": "Airline",
          "userEmail": "tester+automationairline39a@alasdoo.com",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Terry",
          "lastName": "Airliner",
          "userEmail": "tester+ai111921@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "96faa56c-6282-4d76-bbb9-b8400932edc2",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1208@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co12081111@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Temp",
          "lastName": "Airline",
          "userEmail": "tester+automationairline23temp@alasdoo.com",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1208@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "P",
          "lastName": "O",
          "userEmail": "tester+0622aidev@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Test",
          "lastName": "Airline",
          "userEmail": "tester+1004testairline@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1018a2@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co12081111@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Pen",
          "lastName": "Airliner",
          "userEmail": "tester+ai1117@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "96faa56c-6282-4d76-bbb9-b8400932edc2",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+0930kl@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1102@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "96faa56c-6282-4d76-bbb9-b8400932edc2",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+0927@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co12081111@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co12082111@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+0901testairline@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co09232@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Cho",
          "lastName": "Wai",
          "userEmail": "cho+air_test_16_08_21@cargoai.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+1012jkk1@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+0901testairline1@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+test0901airline@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+0901testair@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1209@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co09235@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Test ",
          "lastName": "Airline",
          "userEmail": "tester+0726airline@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1209b@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Test",
          "lastName": "Airline",
          "userEmail": "tester+1119airline@wedoqa.co",
          "company": {
            "companyName": "wedoqa1117",
            "logoType": "",
            "logoHash": "a9662807-7319-4ae4-8758-72db537b90e7",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co120821111@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co0923@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "L",
          "lastName": "T",
          "userEmail": "tester+ai0929@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+1021test@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "8bdb056f-855e-4341-96e7-aceea387d543",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co12081111@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+col20821@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Prudence",
          "lastName": "Airliner",
          "userEmail": "tester+ai1208@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "96faa56c-6282-4d76-bbb9-b8400932edc2",
          },
        },
      },
      {
        "contact": {
          "firstName": "Test",
          "lastName": "Airline",
          "userEmail": "tester+automationairline_f@alasdoo.com",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Paul",
          "lastName": "Airliner",
          "userEmail": "tester+ai1172@wedoqa.co",
          "company": {
            "companyName": "wedoqa1117",
            "logoType": "",
            "logoHash": "a9662807-7319-4ae4-8758-72db537b90e7",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co1209a@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "Test",
          "lastName": "Airline",
          "userEmail": "tester+0426a@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "teamcargoai+SQ_SIN6@gmail.com",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co120821@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co12081111@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+co12081111@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "R",
          "lastName": "R",
          "userEmail": "tester+ai0629@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "283c8bf2-44fb-11eb-88a2-06f57e4303ea",
          },
        },
      },
      {
        "contact": {
          "firstName": "N/C",
          "lastName": "N/C",
          "userEmail": "tester+frw1116c@wedoqa.co",
          "company": {
            "companyName": "Singapore Airlines",
            "logoType": "",
            "logoHash": "96faa56c-6282-4d76-bbb9-b8400932edc2",
          },
        },
      },
    ],
  },
};

export const mockedNotification: IAppNotification = {
  "description": null,
  "link": "test",
  "parameters": JSON.stringify(mockedNotificationParameters),
  "read": false,
  "seen": true,
  "timestamp": 1642167873,
  "title": "Booking 22342 in transit",
  "type": AppNotificationTypeEnum.QUOTE_IN_TRANSIT,
  "user": "1",
  "push": true,
};
