import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import {
  CargoMartPlansType,
  CompanyService,
  CustomerService,
  IOfficeAccess,
  IPermissionSet,
  OfficeService,
  PermissionNameEnum,
  PermissionSetName,
  WalletPaymentApprovalWorkflow,
  UserTypeEnum,
  PlanUpgradeService,
  WalletService,
} from '@cai-services';
import { PaginatePipeArgs } from 'ngx-pagination';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import { MatRadioChange } from '@angular/material/radio';
import Swal from 'sweetalert2';
import {
  ApplicationProperties,
  GlobalService,
  Office,
  PermissionGroup,
  SessionService,
  User,
  UserFeaturesEnum,
} from '../../../core';
import { HomepageCommonUtil, OfficeUtil } from '../../../utils';
import { APP_PROPS } from '../../../cai-common.module';
import { OfficeAddUserModal } from '../modals/office-add-user-modal/office-add-user-modal.component';
import { OfficeRemoveUserModal } from '../modals/office-remove-user-modal/office-remove-user-modal.component';
import { OfficeEditUserModal } from '../modals/office-edit-user-modal/office-edit-user-modal.component';
import { formatStringDateTime } from '../../../helpers';
import { CargoMartPlanSelectorModal } from '../modals/cargomart-plan-selector-modal/cargomart-plan-selector-modal.component';
import { ProGenericModal } from '../modals/pro-generic-modal/pro-generic-modal.component';
import { environment } from '../../../environments';
import { isNumberValidator } from '../../../validators';

@Component({
  selector: 'cai-office-management',
  templateUrl: './office-management.component.html',
  styleUrls: ['./office-management.component.scss'],
})
export class OfficeManagementComponent implements OnInit, OnChanges {
  @Input() activeOffice: Office;
  @Input() companyOffices: Office[];
  @Input() currentUser: User;
  @Input() openViewPlans: boolean;
  @Input() openUpgradePlans: boolean;
  @Output() displayedViewPlans: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() onUpdateActiveOffice = new EventEmitter<Office>();
  @ViewChild('officeNameInput') officeNameInput: ElementRef;
  getOfficeName = OfficeUtil.getOfficeName;

  features = environment.features;

  officeNameToUpdate: string;
  OfficePrivacyToupdate: boolean;
  activeOfficeUsers: User[];
  activeOfficePaymentApproval: WalletPaymentApprovalWorkflow;
  rolesOfCompany: IPermissionSet[] = [];
  relevantRoles: string[] = [];
  permissionGroups: PermissionGroup[];
  relevantPermissions: string[] = [];
  isAddOnsAvailable: boolean;
  iAmAdmin = false;
  isNewSelection = false;
  intialWalletPublic: boolean;
  walletPrivacyForm: FormGroup;
  officeInformationForm: FormGroup;
  officePaymentApprovalForm: FormGroup;
  officeEmailModifierForm: FormGroup;
  isEdited = false;
  initialOfficeName: string;
  initialOfficeEmail: string;
  hoveredUser?: User;

  PREVIOUS_LABEL = $localize`:@@global.previous:Previous`;
  NEXT_LABEL = $localize`:@@global.next:Next`;

  isLoading = true;
  isPageLoading = false;
  isEditingOfficeInformation = false;
  isEditingPaymentApproval = false;
  maxOfficeNameLength = 55;

  pagination: PaginatePipeArgs = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  sortState: Sort = {
    active: 'firstName',
    direction: 'asc',
  };
  addOnsMapper = {
    [UserFeaturesEnum.ANALYTICS_FORWARDER]: 'Market analysis',
    [UserFeaturesEnum.QUOTATION]: 'Quotation for Shipper',
  };

  search: string = null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  isWalletPublic: any;

  constructor(
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private readonly officeService: OfficeService,
    private readonly companyService: CompanyService,
    private readonly globalService: GlobalService,
    private readonly customerService: CustomerService,
    private readonly walletService: WalletService,
    private readonly cdr: ChangeDetectorRef,
    private readonly modalService: NgbModal,
    private readonly sessionService: SessionService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly planUpgradeService: PlanUpgradeService,
  ) {
    this.initializePermissionGroups();
  }

  private initializePermissionGroups() {
    if (this.isCargoWallet) {
      this.permissionGroups = [
        {
          name: 'Payment',
          permissions: [PermissionNameEnum.PAY_TO],
        },
        {
          name: 'Request payment',
          permissions: [
            PermissionNameEnum.GET_PAID,
            // TODO: should be payment link
            PermissionNameEnum.TOP_UP,
          ],
        },
        {
          name: 'Payout',
          permissions: [PermissionNameEnum.PAYOUT],
        },
      ];
    } else {
      this.permissionGroups = [
        {
          name: 'Bookings',
          permissions: [
            PermissionNameEnum.READ_BOOKING,
            PermissionNameEnum.READ_QUOTE,
            PermissionNameEnum.CREATE_BOOKING,
            PermissionNameEnum.CREATE_QUOTE,
            PermissionNameEnum.MODIFY_BOOKING,
            PermissionNameEnum.MODIFY_QUOTE,
          ],
        },
        {
          name: 'Rates',
          permissions: [
            PermissionNameEnum.ACCESS_CONTRACT_RATE,
            PermissionNameEnum.ACCESS_MARKET_RATE,
          ],
        },
      ];
    }

    this.relevantPermissions = this.permissionGroups
      .map((permissionGroup) => permissionGroup.permissions)
      .reduce(
        (groupAPermissions, groupBPermissions) => [
          ...groupAPermissions,
          ...groupBPermissions,
        ],
        [],
      );
  }

  async ngOnInit(): Promise<void> {
    this.addDatadogFlagEvaluations();
    this.initializeOfficeInformationForm();
    this.initializeOfficePaymentApprovalForm();
    this.initializeData();
    this.route.queryParamMap.subscribe((paramMap) => {
      if (paramMap.get('upgradeSuccess') != null) {
        this.showUpgradeResult(paramMap.get('upgradeSuccess'));
      }
    });
    if (this.openViewPlans) {
      this.onViewPlansClick();
      this.updateViewPlansFlag();
    } else if (this.openUpgradePlans) {
      const upgradableOffices = this.companyOffices.filter(
        (office) => office.plan !== CargoMartPlansType.PRO,
      );
      if (upgradableOffices.length === 1) {
        this.openProSummaryModal(upgradableOffices);
      } else {
        await this.openBranchSelection(upgradableOffices);
      }
      this.updateViewPlansFlag();
    }
  }

  async initializeData(): Promise<void> {
    await this.initializePermissionSets();
    await this.fetchUsers();
    await this.fetchPaymentApproval();
  }

  addDatadogFlagEvaluations() {
    if (environment.datadog) {
      datadogRum.addFeatureFlagEvaluation(
        UserFeaturesEnum.SHARE_QUOTES_ACROSS_OFFICE,
        this.isShareQuotesEnabled,
      );

      datadogRum.addFeatureFlagEvaluation(
        UserFeaturesEnum.CARGOMART_PRO,
        this.isCargoMartProEnabled,
      );
    }
  }

  private async initializePermissionSets(): Promise<void> {
    this.rolesOfCompany = await this.globalService.getPermissionSets();
    const relevantRoleNames = this.isCargoWallet
      ? ['OFFICE_ADMIN', 'PAYER', 'PAYMENT_APPROVER', 'VENDOR_APPROVER']
      : ['OFFICE_ADMIN', 'OPERATION', 'STANDARD', 'SALES'];
    this.relevantRoles = this.rolesOfCompany
      .filter((role) => relevantRoleNames.includes(role.name))
      .map((role) => role.name);
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.hasOwnProperty('activeOffice') && this.activeOffice) {
      this.isEdited = false;
      this.isNewSelection = false;
      this.officeNameToUpdate =
        this.activeOffice.name ??
        OfficeUtil.getLongOfficeName(this.activeOffice);
      this.initializeOfficeEmailModifierForm();
      this.initializeOfficeInformationForm();
      this.fetchUsers();
      this.initializeWalletPrivacyForm();
      this.fetchPaymentApproval();
      this.isAddOnsAvailable = !!this.currentUser?.officeAccesses?.find(
        (office) => office?.office?.id === this.activeOffice.id,
      )?.office?.activatedFeatures?.length;
      this.iAmAdmin = !!this.currentUser?.officeAccesses?.find(
        (oa) =>
          oa.office.id === this.activeOffice.id &&
          oa.roles.find((r) => r.name === PermissionSetName.OFFICE_ADMIN),
      );
      this.cdr.detectChanges();
    }
  }

  updateViewPlansFlag() {
    this.openViewPlans = !this.openViewPlans;
    this.displayedViewPlans.emit(this.openViewPlans);
  }

  private async fetchUsers(): Promise<void> {
    if (this.activeOffice === undefined) {
      return;
    }

    this.isLoading = true;
    const page = this.pagination.currentPage as number,
      sort = `${this.sortState.active},${this.sortState.direction}`;
    try {
      const pagedResults = await this.officeService.getOfficeMembers(
        this.activeOffice.id,
        page - 1,
        this.pagination.itemsPerPage as number,
        sort,
        this.search,
      );

      this.activeOfficeUsers = pagedResults.content;
      this.pagination.totalItems = pagedResults.totalElements;
    } catch (e) {
      console.error(e);
    }
    this.isLoading = false;
    this.cdr.detectChanges();
  }

  private async fetchPaymentApproval(): Promise<void> {
    if (this.activeOffice === undefined || !this.isWalletActive) {
      this.activeOfficePaymentApproval = undefined;
      this.initializeOfficePaymentApprovalForm();
      return;
    }

    try {
      this.activeOfficePaymentApproval =
        await this.customerService.getWalletPaymentApprovalFlow(
          this.activeOffice.walletId,
        );
    } catch (e) {
      this.activeOfficePaymentApproval = undefined;
      console.error(e);
    }

    this.initializeOfficePaymentApprovalForm();
    this.cdr.detectChanges();
  }

  sortData(sort: Sort) {
    this.sortState = sort;
    this.fetchUsers();
  }

  private initializeOfficeInformationForm() {
    this.officeInformationForm = new FormGroup({
      iata: new FormControl({ value: this.activeOffice?.iata, disabled: true }),
      cass: new FormControl({ value: this.activeOffice?.cass, disabled: true }),
      country: new FormControl({
        value: this.activeOffice?.country?.countryCode,
        disabled: true,
      }),
      airport: new FormControl({
        value: this.activeOffice?.airports[0]?.airportCode,
        disabled: true,
      }),
      company: new FormControl({
        value: this.activeOffice?.company?.companyName,
        disabled: true,
      }),
      shareQuotes: new FormControl({
        value: this.activeOffice?.shareQuotes,
        disabled: true,
      }),
      plan: new FormControl({
        value: this.activeOffice?.plan,
        disabled: true,
      }),
      walletPrivacy: new FormControl(this.activeOffice?.isWalletPublic),
      planExpiryDate: new FormControl({
        value: formatStringDateTime(
          moment(this.activeOffice?.planExpiryDate)?.toISOString(),
          'MMM DD, YYYY',
        ),
        disabled: true,
      }),
      addOns: new FormControl({
        value: this.currentUser?.officeAccesses
          ?.find((office) => office?.office?.id === this.activeOffice?.id)
          ?.office?.activatedFeatures?.map(
            (af) => this.addOnsMapper?.[af?.featureName],
          )
          .filter((name) => !!name)
          ?.join(','),
        disabled: true,
      }),
    });
  }
  private initializeOfficeEmailModifierForm(): void {
    this.initialOfficeName = this.activeOffice.name;
    this.initialOfficeEmail = this.activeOffice.financeEmail;

    this.officeEmailModifierForm = new FormGroup({
      OfficeName: new FormControl(this.initialOfficeName, Validators.required),
      OfficeEmail: new FormControl(this.initialOfficeEmail, [
        Validators.required,
        Validators.email,
      ]),
    });

    this.officeEmailModifierForm.valueChanges.subscribe(() => {
      this.isEdited =
        this.officeEmailModifierForm.get('OfficeName')?.value !==
          this.initialOfficeName ||
        this.officeEmailModifierForm.get('OfficeEmail')?.value !==
          this.initialOfficeEmail;
    });
  }

  private initializeOfficePaymentApprovalForm(): void {
    this.officePaymentApprovalForm = new FormGroup(
      {
        approvalThreshold: new FormControl({
          value: this.activeOfficePaymentApproval?.payment_approval_threshold,
          disabled: this.paymentApprovalDisabled,
        }),
        isActive: new FormControl(
          this.activeOfficePaymentApproval?.payment_approval_status ===
            'ACTIVE',
        ),
      },
      [this.officePaymentApprovalFormValidator],
    );
  }
  initializeWalletPrivacyForm(): void {
    this.intialWalletPublic = this.activeOffice.isWalletPublic;
    this.walletPrivacyForm = new FormGroup({
      walletPrivacy: new FormControl(this.activeOffice.isWalletPublic),
    });
    this.walletPrivacyForm
      .get('walletPrivacy')
      ?.valueChanges.subscribe((value) => {
        this.isNewSelection = value !== this.intialWalletPublic;
      });
  }

  private officePaymentApprovalFormValidator: ValidatorFn = (
    form: AbstractControl,
  ): ValidationErrors | null => {
    const approvalThresholdControl = form.get('approvalThreshold'),
      isActiveControl = form.get('isActive');

    if (isActiveControl.value) {
      const requiredError = Validators.required(approvalThresholdControl),
        isNumberError = isNumberValidator()(approvalThresholdControl);
      if (requiredError) {
        approvalThresholdControl.setErrors({ required: true });
      }
      if (isNumberError) {
        approvalThresholdControl.setErrors({ isNumber: false });
      }
    } else if (approvalThresholdControl.errors) {
      approvalThresholdControl.setErrors(null);
    }

    return null;
  };

  get isPlanFree(): boolean {
    return (
      this.officeInformationForm?.get('plan')?.value === CargoMartPlansType.FREE
    );
  }

  get isPlanPro(): boolean {
    return (
      this.officeInformationForm?.get('plan')?.value === CargoMartPlansType.PRO
    );
  }

  getRoles(user: User): string {
    const activeOfficeAccess = this.getActiveOfficeAccessOfUser(user);
    if (activeOfficeAccess === undefined) {
      return '';
    }

    return activeOfficeAccess.roles
      .filter((role) => this.relevantRoles.includes(role.name))
      .map((role) => role.name)
      .join(', ');
  }

  get getPermissionsOfHoveredUser(): string[] {
    if (this.hoveredUser === undefined) {
      return [];
    }

    const activeOfficeAccess = this.getActiveOfficeAccessOfUser(
        this.hoveredUser,
      ),
      permissionNames = new Set<string>();
    activeOfficeAccess.roles
      .map((role) => role.permissions)
      .reduce(
        (roleAPermissions, roleBPermissions) => [
          ...roleAPermissions,
          ...roleBPermissions,
        ],
        [],
      )
      .filter((permission) =>
        this.relevantPermissions.includes(PermissionNameEnum[permission.name]),
      )
      .forEach((permission) =>
        permissionNames.add(PermissionNameEnum[permission.name]),
      );

    return Array.from(permissionNames);
  }

  private getActiveOfficeAccessOfUser(user: User): IOfficeAccess {
    return user.officeAccesses?.find(
      (officeAccess) => officeAccess.office.id === this.activeOffice.id,
    );
  }

  startEditingOfficeInformation() {
    this.isEditingOfficeInformation = true;
    if (this.isShareQuotesEnabled) {
      this.officeInformationForm.get('shareQuotes').enable();
    }
    if (!this.officeNameToUpdate) {
      this.officeNameToUpdate =
        this.activeOffice.company.companyName +
        ' ' +
        this.activeOffice.country.countryCode;
    }
  }

  async saveOfficeChanges(): Promise<void> {
    try {
      const officeToUpdate: Office = {
        ...this.activeOffice,
        name: this.officeNameInput.nativeElement.innerText,
        shareQuotes: this.officeInformationForm.get('shareQuotes').value,
      };
      await this.companyService.updateOfficeById(
        this.activeOffice.id,
        officeToUpdate,
      );
      this.onUpdateActiveOffice.emit(officeToUpdate);
      this.officeNameToUpdate = officeToUpdate.name;
    } catch {}

    this.stopEditingOfficeInformation();
  }

  async savePaymentApprovalChanges(): Promise<void> {
    try {
      const updatedPaymentApprovalWorkflow = {
        payment_approval_threshold:
          +this.officePaymentApprovalForm.get('approvalThreshold').value,
        payment_approval_status: this.officePaymentApprovalForm.get('isActive')
          .value
          ? 'ACTIVE'
          : 'INACTIVE',
      };
      await this.customerService.patchPaymentApprovalWorkflow(
        this.activeOffice.walletId,
        updatedPaymentApprovalWorkflow,
      );
      this.activeOfficePaymentApproval = updatedPaymentApprovalWorkflow;
    } catch {}

    this.stopEditingPaymentApproval();
    this.cdr.detectChanges();
  }

  stopEditingOfficeInformation() {
    this.initializeOfficeInformationForm();
    this.isEditingOfficeInformation = false;
    this.officeNameInput.nativeElement.innerText = this.officeNameToUpdate;
  }

  stopEditingPaymentApproval() {
    this.initializeOfficePaymentApprovalForm();
    this.isEditingPaymentApproval = false;
  }

  async onInviteUserButtonClick(): Promise<void> {
    const addUserModal = this.modalService.open(OfficeAddUserModal, {
      centered: true,
      size: 'md',
    });
    addUserModal.componentInstance.activeOffice = this.activeOffice;
    addUserModal.componentInstance.rolesOfCompany = this.rolesOfCompany;
    addUserModal.componentInstance.relevantRoles = this.relevantRoles;
    addUserModal.componentInstance.modalRef = addUserModal;

    try {
      await addUserModal.result;
      this.fetchUsers();
    } catch {}
  }

  async onEditUserButtonClick(user: User): Promise<void> {
    const editUserModal = this.modalService.open(OfficeEditUserModal, {
      centered: true,
      size: 'lg',
    });
    editUserModal.componentInstance.activeOffice = this.activeOffice;
    editUserModal.componentInstance.rolesOfCompany = this.rolesOfCompany;
    editUserModal.componentInstance.relevantRoles = this.relevantRoles;
    editUserModal.componentInstance.permissionGroups = this.permissionGroups;
    editUserModal.componentInstance.userToEdit = user;
    editUserModal.componentInstance.modalRef = editUserModal;

    try {
      await editUserModal.result;
      this.fetchUsers();
    } catch {}
  }

  async onRemoveUserButtonClick(user: User): Promise<void> {
    const removeUserModal = this.modalService.open(OfficeRemoveUserModal, {
      centered: true,
      size: 'md',
    });
    removeUserModal.componentInstance.activeOffice = this.activeOffice;
    removeUserModal.componentInstance.userToRemove = user;
    removeUserModal.componentInstance.modalRef = removeUserModal;
    removeUserModal.componentInstance.isWalletApp = this.isCargoWallet;

    try {
      await removeUserModal.result;
      this.fetchUsers();
    } catch {}
  }

  trackById(index: number, user: User) {
    return user.userId;
  }

  onPageChanged(page: number) {
    this.pagination.currentPage = page;
    this.fetchUsers();
  }

  onSearch(query: string) {
    this.search = query;
    this.fetchUsers();
  }

  isAdmin(user: User) {
    return this.getRoles(user)?.includes('OFFICE_ADMIN');
  }

  get WalletPrivacy(): string {
    return this.activeOffice.isWalletPublic ? 'Public' : 'Private';
  }

  get companyName(): string {
    return this.activeOffice.name;
  }
  get companyFinanceEmail(): string {
    return this.activeOffice.financeEmail;
  }
  get isCargoWallet(): boolean {
    return this.appProperties.name === 'cargowallet';
  }

  async openBranchSelection(upgradableOffices: Office[]): Promise<void> {
    const branchesSelectorModal = this.modalService.open(ProGenericModal, {
      centered: true,
      windowClass: 'pro-w-416',
    });
    branchesSelectorModal.componentInstance.type = 'SELECT';
    branchesSelectorModal.componentInstance.upgradableOffices =
      upgradableOffices;
    const selectedOffices = await branchesSelectorModal.result;
    this.planUpgradeService.captureUpgradeEvent('SB', selectedOffices?.length);
    if (selectedOffices) {
      this.openProSummaryModal(selectedOffices);
    }
  }

  async onViewPlansClick() {
    const planModal = this.modalService.open(CargoMartPlanSelectorModal, {
      centered: true,
      windowClass: 'plan-modal',
    });
    try {
      const res = await planModal.result,
        upgradableOffices = this.companyOffices.filter(
          (office) => office.plan !== CargoMartPlansType.PRO,
        );
      this.planUpgradeService.captureUpgradeEvent(
        'SI',
        upgradableOffices?.length,
      );
      if (res === 'upgrade') {
        if (upgradableOffices.length === 1) {
          this.openProSummaryModal(upgradableOffices);
        } else {
          await this.openBranchSelection(upgradableOffices);
        }
      }
    } catch {}
  }

  async openProSummaryModal(offices?: Office[]) {
    const upgradeToProModal = this.modalService.open(ProGenericModal, {
      centered: true,
      windowClass: 'upgrade-pro-modal',
    });
    upgradeToProModal.componentInstance.type = 'UPGRADE';
    upgradeToProModal.componentInstance.selectedOffices = offices;
    upgradeToProModal.componentInstance.originOfficeId = this.activeOffice?.id;
    upgradeToProModal.componentInstance.loading.subscribe((loading) => {
      this.isPageLoading = loading;
      this.cdr.detectChanges();
    });
    try {
      const res = await upgradeToProModal.result;
      if (res?.startsWith('invoice')) {
        const infoModal = this.modalService.open(ProGenericModal, {
          centered: true,
          windowClass: 'pro-w-416',
        });
        infoModal.componentInstance.type = res?.endsWith('sent')
          ? 'INVOICE'
          : 'ERROR';
      }
    } catch {
    } finally {
      this.isPageLoading = false;
      this.cdr.detectChanges();
    }
  }

  async showUpgradeResult(result) {
    const resultModal = this.modalService.open(ProGenericModal, {
      centered: true,
      windowClass: 'pro-w-416',
    });
    resultModal.componentInstance.type =
      result === 'true' ? 'SUCCESS' : 'FAILED';
    try {
      const res = await resultModal.result;
      await this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {},
      });
      if (res === 'view') {
        this.onViewPlansClick();
      }
    } catch {}
  }

  showUpgradeMessage() {
    if (
      this.sessionService.getSelectedOffice()?.plan ===
        CargoMartPlansType.PRO ||
      this.sessionService.getCurrentUser()?.type === UserTypeEnum.AIRLINE
    ) {
      return;
    }
    const branchesSelectorModal = this.modalService.open(ProGenericModal, {
      centered: true,
    });
    branchesSelectorModal.componentInstance.type = 'SUGGEST-UPGRADE';
  }

  get isShareQuotesEnabled(): boolean {
    if (!this.isCargoMartProEnabled) {
      return true;
    }
    const officeId = this.activeOffice?.id,
      officeAccess = this.sessionService
        .getCurrentUser()
        ?.officeAccesses?.find((oa) => oa.office.id == officeId),
      office = officeAccess ? officeAccess.office : null;
    return HomepageCommonUtil.isFeatureEnabled(
      office?.activatedFeatures,
      UserFeaturesEnum.SHARE_QUOTES_ACROSS_OFFICE,
    );
  }

  get isCargoMart(): boolean {
    return this.appProperties.name === 'cargomart';
  }

  get isCargoMartProEnabled(): boolean {
    return (
      HomepageCommonUtil.isFeatureEnabled(
        this.sessionService.getCurrentUser()?.activatedFeatures,
        UserFeaturesEnum.CARGOMART_PRO,
      ) || this.features?.cargomartPro
    );
  }

  get showPaymentApprovalThreshold(): boolean {
    return this.officePaymentApprovalForm?.get('isActive').value;
  }

  approvalControlHasError(controlName: string, errorCode: string): boolean {
    const control = this.officePaymentApprovalForm?.controls[controlName];
    if (!control) {
      return false;
    }

    return (
      control.hasError(errorCode) ||
      (control.errors && Object.keys(control.errors).includes(errorCode))
    );
  }

  limitOfficeName(element) {
    if (element.innerText.length > this.maxOfficeNameLength) {
      const selection = window.getSelection();
      let currentPosition = selection.getRangeAt(0).startOffset;
      element.innerText = element.innerText.slice(0, this.maxOfficeNameLength);
      currentPosition = Math.min(currentPosition, this.maxOfficeNameLength);
      selection.collapse(element.firstChild, currentPosition);
    }
  }

  async onRadioChange(event: MatRadioChange): Promise<void> {
    this.isNewSelection = true;
    this.isWalletPublic = this.walletPrivacyForm.get('walletPrivacy')?.value;
  }

  async onSaveOfficeDetails(): Promise<void> {
    if (!this.isEdited) {
      console.error('No changes made');
      return;
    }

    const updatedOffice: Office = {
      ...this.activeOffice,
      name: this.officeEmailModifierForm.get('OfficeName')?.value,
      financeEmail: this.officeEmailModifierForm.get('OfficeEmail')?.value,
    };

    try {
      await this.companyService.updateOfficeById(
        this.activeOffice.id,
        updatedOffice,
      );
      this.onUpdateActiveOffice.emit(updatedOffice);

      Swal.fire({
        title: 'Successfully updated!',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      console.error('Failed to update office:', error);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to update office details.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
    this.isEdited = false;
  }

  async onSave(): Promise<void> {
    if (this.isWalletPublic === undefined) {
      console.error('No selection made');
      return;
    }

    const officeToUpdate: Office = {
      ...this.activeOffice,
      isWalletPublic: this.isWalletPublic,
    };

    try {
      await this.companyService.getCompanyOffices();
      await this.walletService.updateOffice(
        this.activeOffice.id,
        this.isWalletPublic,
      );
      this.onUpdateActiveOffice.emit(officeToUpdate);
      this.OfficePrivacyToupdate = officeToUpdate.isWalletPublic;
      this.sessionService.initCompanyOffices();

      Swal.fire({
        title: 'Successfully updated!',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      console.error('Failed to update office:', error);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to update office.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  }
  get paymentApprovalDisabled(): boolean {
    return !this.iAmAdmin || !this.isWalletActive;
  }

  get isWalletActive(): boolean {
    return (
      this.activeOffice?.walletId &&
      this.activeOffice?.walletStatus === 'ACTIVE'
    );
  }

  get showPaymentApproval(): boolean {
    return this.isCargoWallet && this.isForwarderOrStandardUser;
  }

  get isForwarderOrStandardUser(): boolean {
    return (
      this.currentUser.type === UserTypeEnum.FORWARDER ||
      this.currentUser.type === UserTypeEnum.STANDARD
    );
  }
}
