import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  "name": "strReplace",
})
export class StringReplacePipe implements PipeTransform {
  transform (value: string, from: string, to: string): string {
    return value.replace(from, to);
  }
}
