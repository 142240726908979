import { STABLE_LANGUAGE } from "../../../lib/core/_constants/constants";
import { environment } from "../../environments";

export class Language {
  locale: string;
  flag: string;
  country: string;
  language: string;
}

const localeList = [
  "fr",
  "en",
  "de",
  "es",
  "ja",
  "ko",
  "pt",
  "it",
  "ru",
  "tr",
  "vi",
  "zh-Hans",
  "ar",
  "pl",
  "th-TH",
],

 countryList = [
  "France",
  "United States",
  "Germany",
  "Spain",
  "Japan",
  "Korea",
  "Portugal",
  "Italia",
  "Russia",
  "Turkey",
  "Vietnam",
  "China",
  "",
  "Poland",
  "Thailand",
],

 languageList = [
  "Français",
  "English",
  "Deutsch",
  "Español",
  "日本",
  "한국인",
  "Português",
  "Italian",
  "русский",
  "турецкий",
  "Tiếng Việt",
  "中文",
  "",
  "Polska",
  "ประเทศไทย",
],

 flagList = [
  "fr",
  "us",
  "de",
  "es",
  "ja",
  "ko",
  "pt",
  "it",
  "ru",
  "tr",
  "vi",
  "zh-Hans",
  "ar",
  "pl",
  "th-TH",
];

export const languages = (): Language[] => {
  const newLanguages = [];

  localeList.forEach((_, index) => {
    if (
      !environment.features.newLanguages &&
      !STABLE_LANGUAGE.includes(localeList[index])
    ) {
      return;
    } else {
      newLanguages.push({
        "locale": localeList[index],
        "country": countryList[index],
        "language": languageList[index],
        "flag": flagList[index],
      });
    }
  });
  return newLanguages;
};
