import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild, OnChanges
} from "@angular/core";
import Swal from "sweetalert2";
import { UserTypeEnum } from "@cai-services";
import { User } from "../../../_models/user.model";
import { RegisterService } from "../../../_services/register.service";
import { freeEmailRegex } from "../../../../validators/email-provider.validator";

@Component({
  "selector": "ng-input-select",
  "templateUrl": "./input-select.component.html",
  "styleUrls": ["./input-select.component.scss"],
})
export class InputSelectComponent implements OnInit, OnChanges {
  @Input() elements: any[] = [];
  @Input() contactByFlight: any[] = [];
  @Input() label = "";
  @Input() validationPattern: RegExp;
  @Input() metadata: any;
  @Input() text: string;
  @Input() disableAdd: boolean;

  @Output() elementsChange = new EventEmitter<any>();
  @Output()
  valueChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  chosenElement: any;
  @Input()
  chosenValue: User[];
  @Input() isSelectAll: boolean;

  displayMenu = false;
  @ViewChild("toggleButton", { "static": true }) toggleButton: ElementRef;
  @ViewChild("menu", { "static": true }) menu: ElementRef;
  constructor (
    private ref: ChangeDetectorRef,
    private readonly registerService: RegisterService,
    private readonly renderer: Renderer2,
    private readonly eleRef: ElementRef
  ) {
    this.renderer.listen("document", "click", (e: Event) => {
      if (!this.eleRef.nativeElement.contains(e.target)) {
        this.displayMenu = false;
        this.ref.detectChanges();
      }
    });
  }

  ngOnInit (): void {
    this.onInitElements();
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.elements && changes.elements.currentValue) {
      this.onInitElements();
    }
  }

  onInitElements (): void {
    if (this.elements) {
      if (this.elements.length === 1) {
        this.elements[0].checked = true;
        this.chosenValue = this.elements[0].value;
        this.isSelectAll = false;
      } else if (this.isSelectAll) {
        this.elements = this.elements.map((e) => {
          e.checked = true;
          return e;
        });
      }
      this.chosenValue = this.formatChosenValue();
      this.emitValueChanges();
    }
  }

  formatChosenValue (): User[] {
    const allChosenEl = this.elements.filter((e) => e.checked);
    return allChosenEl.map((e) => e.value);
  }

  chooseElement (index: number) {
    this.elements[index].checked = !this.elements[index].checked;
    const allChosenEl = this.elements.filter((e) => e.checked);
    this.isSelectAll = allChosenEl.length === this.elements.length;
    this.chosenValue = this.formatChosenValue();
    this.emitValueChanges();
  }

  onSelectAll (event: Event): void {
    const target = event.target as HTMLInputElement;
    this.isSelectAll = target.checked;
    this.elements = this.elements.map((e) => ({
      ...e,
      "checked": target.checked,
    }));
    this.chosenValue = target.checked ? this.formatChosenValue() : [];
    this.emitValueChanges();
  }

  emitValueChanges () {
    const value = {
      "isSelectAll": this.isSelectAll,
      "value": this.chosenValue,
    };
    this.valueChanged.emit(value);
  }

  validateValue (input) {
    return this.validationPattern.test(input.toLowerCase());
  }

  toggleDropdownMenu () {
    this.displayMenu = !this.displayMenu;
  }

  onAddClicked () {
    const swalWithBootstrapButtons = Swal.mixin({
      "customClass": {
        "confirmButton": "btn btn-success",
        "cancelButton": "btn btn-danger",
      },
      "buttonsStyling": false,
    });

    swalWithBootstrapButtons
      .fire({
        "title": "Please " + this.label,
        "text": this.text,
        "input": "text",
        "confirmButtonText": "Confirm",
        "showCancelButton": true,
        "showLoaderOnConfirm": true,
        "reverseButtons": true,
        "allowOutsideClick": () => !Swal.isLoading(),
        "preConfirm": (input) => {
          if (!input.length) {
            return new Promise((resolve) => {
              Swal.showValidationMessage("Email is required!");
              resolve(false);
            });
          }
          if (this.validationPattern && !this.validateValue(input)) {
            return new Promise((resolve) => {
              Swal.showValidationMessage("The email is invalid!");
              resolve(false);
            });
          }
          const personalEmailMessage = $localize`:@@global.email-not-supported:Personal email addresses are not supported. Please provide a corporate email address`;
          if (input.match(freeEmailRegex)) {
            return new Promise((resolve) => {
              Swal.showValidationMessage(personalEmailMessage);
              resolve(false);
            });
          }
          const emailUsedMessage = $localize`:@@global.error-email-used:This email is already used by another user.`;
          if (this.isEntered(input)) {
            return new Promise((resolve) => {
              Swal.showValidationMessage(emailUsedMessage);
              resolve(false);
            });
          }
          if (this.metadata && this.metadata.airlineCompanyId) {
            return new Promise((resolve) => {
              this.registerService.checkExistingEmail(input, UserTypeEnum.AIRLINE).subscribe({
                "next": (alreadyExisting) => {
                  if (alreadyExisting) {
                    Swal.showValidationMessage(emailUsedMessage);
                    resolve(false);
                  } else {
                    resolve(input);
                  }
                },
                "error": (err) => {
                  if (err?.includes("400")) {
                    Swal.hideLoading();
                    Swal.showValidationMessage("The email is invalid!");
                  }
                },
              });
            });
          }
        },
      })
      .then((result) => {
        if (result.value) {
          const newElement = {
            "heading": result.value,
            "value": new User(
              "TEST-EMAIL",
              result.value.toString(),
              UserTypeEnum.AIRLINE
            ),
            "selected": true,
          };
          this.elements.push(newElement);
          this.elementsChange.emit(this.elements);
          this.chooseElement(this.elements.length - 1);
          this.ref.detectChanges();
        }
      });
  }

  isEntered (input: string): boolean {
    const availableValues = [].concat
      .apply([], this.contactByFlight)
      .map((el) => el.value.userEmail);
    return availableValues.includes(input);
  }

  formatInput (chosenValue: any[]): string {
    return chosenValue.map((el) => el.userEmail).join(",");
  }
}
