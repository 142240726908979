<svg
  width="33"
  height="31"
  viewBox="0 0 33 31"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15.5 0C6.93958 0 0 6.93959 0 15.5C0 24.0604 6.93959 31 15.5 31H17.0725C25.6329 31 32.5725 24.0604 32.5725 15.5C32.5725 6.93959 25.6329 0 17.0725 0H15.5ZM17.536 9.29989C17.536 8.60953 16.9764 8.04989 16.286 8.04989C15.5957 8.04989 15.036 8.60953 15.036 9.29989V15.4999C15.036 15.9279 15.255 16.3261 15.6164 16.5554L20.5022 19.6554C21.0851 20.0252 21.8575 19.8525 22.2274 19.2696C22.5972 18.6866 22.4245 17.9143 21.8416 17.5444L17.536 14.8126V9.29989Z"
    fill="url(#paint0_linear_609:4571)"
  />
  <defs>
    <linearGradient
      id="paint0_linear_609:4571"
      x1="16.2862"
      y1="0"
      x2="16.2862"
      y2="31"
      gradientUnits="userSpaceOnUse"
    >
      <stop [attr.stop-color]="startClr" />
      <stop offset="1" [attr.stop-color]="endClr" />
    </linearGradient>
  </defs>
</svg>
