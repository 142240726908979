<div
  class="flight-paths-container"
  [ngStyle]="{
    width: isQuoteCartPage ? '100%' : 100 * dayDiff + '%',
  }"
>
  <div *ngIf="flightPaths.length" class="multi-paths-container">
    <div
      [ngStyle]="{
        width: getBeforePath(beforePath) + '%',
        'padding-right': beforePath < 2 ? '10px' : '',
        'margin-left':
          beforePath < 10 ? (flightPaths[0] ? '22px' : '10px') : '',
      }"
      *ngIf="parseDate(flightPaths[0].departureTime) >= parseDate(firstDay)"
    >
      <div
        class="flight-path-info flight-depart position-relative"
        [ngStyle]="{
          'background-color': isColumnActive ? 'transparent' : '',
        }"
      >
        <div
          *ngIf="lastDay && isArrivingLastDay && !isQuoteCartPage"
          class="d-flex align-items-center co2-and-option left position-absolute"
        >
          <div class="flight-co2">
            <div class="leaf"></div>
            {{ CO2 }}
          </div>
          <div
            *ngIf="firstDayOfFlight > minOptionDate"
            class="option d-flex align-items-center"
          >
            <span class="text-gradient" i18n="@@search-results.option"
              >Option</span
            >
          </div>
          <div
            *ngIf="flightDetails?.value?.features?.isInterline"
            class="interline d-flex align-items-center"
          >
            <div class="ml-1">Interline</div>
          </div>
        </div>

        <span class="flight-airport">{{
          flightPaths[0].departureAirport
        }}</span>
        <span
          class="flight-date"
          [ngStyle]="{
            opacity:
              flightPaths[0].flightNumber === 'TBC' && !isQuoteCard
                ? '0'
                : '100',
          }"
          >{{ flightPaths[0].departureTime | time }}</span
        >
      </div>
    </div>

    <ng-container *ngFor="let path of flightPaths; let i = index">
      <div
        class="path"
        *ngIf="
          path.mainPath &&
          parseDate(path.departureTime) <= parseDate(lastDay) &&
          parseDate(path.arrivalTime) >= parseDate(firstDay)
        "
        [ngStyle]="{
          width: path.mainPath + '%',
          'border-top-left-radius': i === 0 ? '8px' : '',
          'border-bottom-left-radius': i === 0 ? '8px' : '',
          'border-top-right-radius': i === flightPaths.length - 1 ? '8px' : '',
          'border-bottom-right-radius':
            i === flightPaths.length - 1 ? '8px' : '',
          'border-right': i === flightPaths.length - 1 ? '' : 'none',
          'border-left': i === 0 ? '' : 'none',
        }"
        [matTooltip]="getMainTooltip(path)"
        caiPopover
        [targetId]="'flight-overview'"
        [target]="disableTooltip && !popover ? flightOverview : null"
        [placement]="'right'"
        [allowHoverOnTarget]="true"
        (isOpen)="updatePopups()"
        (mouseover)="updatePopups()"
      >
        <div
          class="legs"
          [class.legs-light]="isLegsLight(path)"
          *ngIf="path.bodyType | aircraftColor as aircraftColorBg"
          [ngStyle]="{
            'background-color': aircraftColorBg,
          }"
        >
          &nbsp;
        </div>
      </div>

      <ng-container *ngIf="i < flightPaths.length - 1">
        <div
          *ngIf="
            path.transitTime &&
            parseDate(path.arrivalTime) <= parseDate(lastDay) &&
            parseDate(flightPaths[i + 1].departureTime) >= parseDate(firstDay)
          "
          class="flight-transit-path"
          [ngStyle]="{
            width: path.transitTime + '%',
          }"
          [class.full-width]="
            parseDate(flightPaths[flightPaths.length - 1].arrivalTime) >
            parseDate(lastDay)
          "
          [matTooltip]="getTransitTooltip(path, i)"
          caiPopover
          [targetId]="'flight-overview'"
          [target]="disableTooltip && !popover ? flightOverview : null"
          [placement]="'right'"
          [allowHoverOnTarget]="true"
          (isOpen)="updatePopups()"
          (mouseover)="updatePopups()"
        >
          <span
            class="flight-transit-info"
            [ngStyle]="{
              display: path.transitTime < 10 ? 'none' : '',
            }"
            >{{ path.arrivalAirport }}</span
          >
        </div>
      </ng-container>
    </ng-container>

    <div
      class="flight-path-info flight-arrival last-path position-relative"
      *ngIf="
        parseDate(flightPaths[flightPaths.length - 1].arrivalTime) <=
          parseDate(lastDay) && flightPaths[flightPaths.length - 1] as lastPath
      "
      [ngStyle]="{
        'background-color': isColumnActive ? 'transparent' : '',
      }"
      style="width: auto"
    >
      <div
        *ngIf="lastDay && !isArrivingLastDay && !isQuoteCartPage"
        class="d-flex align-items-center co2-and-option position-absolute"
      >
        <div class="flight-co2">
          <div class="leaf"></div>
          {{ CO2 }}
        </div>
        <div
          *ngIf="firstDayOfFlight > minOptionDate"
          class="option d-flex align-items-center"
        >
          <span class="text-gradient" i18n="@@search-results.option"
            >Option</span
          >
        </div>
        <div
          *ngIf="flightDetails?.value?.features?.isInterline"
          class="interline d-flex align-items-center"
        >
          <div class="ml-1">Interline</div>
        </div>
      </div>

      <span class="flight-airport">
        {{ lastPath.arrivalAirport }}
      </span>

      <span
        class="flight-date"
        [ngStyle]="{
          opacity:
            flightPaths[0].flightNumber === 'TBC' && !isQuoteCard ? '0' : '100',
        }"
      >
        <span>{{ lastPath.arrivalTime | time }} </span>
      </span>
    </div>
    <div class="flight-paths-summary" *ngIf="popover">
      <div class="flight-no">
        <img
          src="/assets/media/icons/small-tilted-plane.svg"
          alt=""
          style="width: 13px; height: 10px"
        />
        <span> {{ flightDetails?.value?.flightNumber }}</span>
      </div>
      <div class="flight-duration">
        <img
          src="/assets/media/icons/search-results/clock.svg"
          alt=""
          style="width: 13px; height: 10px"
        />
        <span>
          {{
            getFlightDuration(
              flightDetails?.value?.departureTime,
              flightDetails?.value?.arrivalTime
            )
          }}</span
        >
      </div>
      <div class="co2">
        <img
          src="/assets/media/icons/search-results/leaf.svg"
          alt=""
          style="width: 11px; height: 13px"
        />
      </div>
    </div>
  </div>
</div>

<ng-template #flightOverview>
  <div id="flight-overview">
    <kt-flight-detail-popup
      [flightDetails]="flightDetails"
      [aircraftList]="aircraftList"
      [searchTemplate]="searchTemplate"
      [measurementUnit]="measurementUnit"
      [isEnableQuoteRequest]="isEnableQuoteRequest"
      (onBookClicked)="onBookClicked.emit($event)"
      (onRequestBookClicked)="onRequestBookClicked.emit($event)"
      (onSelectRate)="onSelectRate.emit($event)"
    >
    </kt-flight-detail-popup>
  </div>
</ng-template>
