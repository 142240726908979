import { Injectable } from "@angular/core";
import {
  AircraftService,
  AirlineService,
  AirportService,
  CityService,
  CountryService,
  CurrencyService,
  SpecialHandlingCodeService,
  TypeOfProductService,
  PermissionSetService,
  IPermissionSet,
} from "@cai-services";
import { Aircraft } from "../_models/aircraft.model";
import { AirlineBrand } from "../_models/airline.model";
import { AirportLight } from "../_models/airport-light.model";
import { City } from "../_models/city.model";
import { Country } from "../_models/country.model";
import { Currency } from "../_models/currency.model";
import { SpecialHandlingCode } from "../_models/special-handling-code.model";
import { TypeOfProduct } from "../_models/type-of-product.model";
import { SessionService } from "./session.service";
@Injectable({
  "providedIn": "root",
})
export class GlobalService {
  constructor (
    private readonly sessionService: SessionService,
    private readonly airportService: AirportService,
    private readonly airlineService: AirlineService,
    private readonly aircraftService: AircraftService,
    private readonly cityService: CityService,
    private readonly countryService: CountryService,
    private readonly specialHandlingCodeService: SpecialHandlingCodeService,
    private readonly typeOfProductService: TypeOfProductService,
    private readonly currencyService: CurrencyService,
    private readonly permissionSetService: PermissionSetService
  ) {}

  public getAirports (): Promise<AirportLight[]> {
    const airports = this.sessionService.getAirports();
    if (airports) {
      return this.convertToPromise(airports);
    } else {
      const asyncAirports = this.airportService.getAirportsLight() as Promise<
        AirportLight[]
      >;
      asyncAirports.then((airportResult) =>
        this.sessionService.setAirports(airportResult)
      );
      return asyncAirports;
    }
  }

  public async getAirlineCompanies (): Promise<AirlineBrand[]> {
    let airlineCompanies = this.sessionService.getAirlineCompanies();
    if (airlineCompanies) {
      return this.convertToPromise(airlineCompanies);
    } else {
      const asyncAirlineCompanies =
        this.airlineService.getAirlines() as Promise<AirlineBrand[]>;
      airlineCompanies = await asyncAirlineCompanies;
      this.sessionService.setAirlineCompanies(airlineCompanies);
      return asyncAirlineCompanies;
    }
  }

  public async getAircrafts (token?: string): Promise<Aircraft[]> {
    let aircrafts = this.sessionService.getAircrafts();
    if (aircrafts) {
      return this.convertToPromise(aircrafts);
    } else {
      const asyncAircrafts = this.aircraftService.getAircrafts(
        token
      ) as Promise<Aircraft[]>;
      aircrafts = await asyncAircrafts;
      this.sessionService.setAircrafts(aircrafts);
      return asyncAircrafts;
    }
  }

  public async getCities (): Promise<City[]> {
    let cities = this.sessionService.getCities();
    if (cities) {
      return this.convertToPromise(cities);
    } else {
      const asyncCities = this.cityService.getCities() as Promise<City[]>;
      cities = await asyncCities;
      this.sessionService.setCities(cities);
      return asyncCities;
    }
  }

  public async getCountries (): Promise<Country[]> {
    let countries = this.sessionService.getCountries();
    if (countries) {
      return this.convertToPromise(countries);
    } else {
      const asyncCountries = this.countryService.getCountries() as Promise<
        Country[]
      >;
      countries = await asyncCountries;
      this.sessionService.setCountries(countries);
      return asyncCountries;
    }
  }

  public async getSpecialHandlingCodes (): Promise<SpecialHandlingCode[]> {
    let specialHandlingCodes = this.sessionService.getSpecialHandlingCodes();
    if (specialHandlingCodes) {
      return this.convertToPromise(specialHandlingCodes);
    } else {
      const asyncSpecialHandlingCodes =
        this.specialHandlingCodeService.getSpecialHandlingCodes() as Promise<
          SpecialHandlingCode[]
        >;
      specialHandlingCodes = await asyncSpecialHandlingCodes;
      this.sessionService.setSpecialHandlingCodes(specialHandlingCodes);
      return asyncSpecialHandlingCodes;
    }
  }

  public async getTypeOfProducts (): Promise<TypeOfProduct[]> {
    let typeOfProducts = this.sessionService.getTypeOfProducts();
    if (typeOfProducts) {
      return this.convertToPromise(typeOfProducts);
    } else {
      const asyncTypeOfProducts =
        this.typeOfProductService.getTypeOfProducts() as Promise<
          TypeOfProduct[]
        >;
      typeOfProducts = await asyncTypeOfProducts;
      this.sessionService.setTypeOfProducts(typeOfProducts);
      return asyncTypeOfProducts;
    }
  }

  public async getAllCurrencies (): Promise<Currency[]> {
    let currencies = this.sessionService.getCurrencies();
    if (currencies) {
      return this.convertToPromise(currencies);
    } else {
      const asyncCurrencies = this.currencyService.getCurrencies() as Promise<
        Currency[]
      >;
      currencies = await asyncCurrencies;
      this.sessionService.setCurrencies(currencies);
      return asyncCurrencies;
    }
  }

  public async getPermissionSets (): Promise<IPermissionSet[]> {
    const cachedPermissionSets = this.sessionService.getPermissionSets();
    if (cachedPermissionSets) {
      return cachedPermissionSets;
    }

    const permissionSets = await this.permissionSetService.getPermissionSets();
    this.sessionService.setPermissionSets(permissionSets);
    return permissionSets;
  }

  private convertToPromise (item: any): Promise<any> {
    return new Promise((resolve) => {
      resolve(item);
    });
  }
}
