import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IRequirementAnswer } from "@cai-services";
import { PharmaDangerousGoodsConfig } from "../../../constant/requirements/pharma-dangerous-goods.const";

@Component({
  "selector": "kt-requirement-pharma-dangerous-goods",
  "templateUrl": "./requirement-pharma-dangerous-goods.component.html",
  "styleUrls": ["./requirement-pharma-dangerous-goods.component.scss"],
})
export class RequirementPharmaDangerousGoodsComponent implements OnInit {
  @Output() onFocus = new EventEmitter();

  formGroup: FormGroup = new FormGroup({});
  code = "";
  defaultAnswer = {} as IRequirementAnswer;
  selectedAnswer: any = {};
  question: string = PharmaDangerousGoodsConfig.question;
  options: any[] = PharmaDangerousGoodsConfig.options;

  constructor (private readonly cdr: ChangeDetectorRef) {}

  ngOnInit (): void {
    this.updateAnswer(this.formGroup.get(this.code).value);
    this.formGroup.get(this.code).valueChanges.subscribe((value) => {
      this.updateAnswer(value);
    });
  }

  selectAnswer (option) {
    if (this.selectedAnswer !== option) {
      this.selectedAnswer = option;
      this.formGroup.get(this.code).setValue(option.value);
      this.onFocus.emit();
    }
  }

  updateAnswer (value) {
    const selected = this.options.find((opt) => opt.value === value);
    if (selected) {
      this.selectedAnswer = selected;
      this.cdr.detectChanges();
    }
  }
}
