import { RequirementConfig } from "../../core/_models/requirement-config.model";

export enum CoolingPackagingAnswer {
  NA = "NA",
  ICE_WATER = "icewater",
  DRY_ICE = "dryice",
}

export const CoolingPackagingConfig: RequirementConfig = {
  "summaryLabel": "Cooling Package :",
  "question": "Does your shipment require cooling package ?",
  "options": [
    {
      "label": "N/A",
      "value": CoolingPackagingAnswer.NA,
    },
    {
      "label": "Ice Water",
      "value": CoolingPackagingAnswer.ICE_WATER,
    },
    {
      "label": "Dry Ice",
      "value": CoolingPackagingAnswer.DRY_ICE,
    },
  ],
};
