import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import Auth from "@aws-amplify/auth";
import { hasLowercaseValidator } from "../../../../../validators/has-lowercase.validator";
import { hasNumberValidator } from "../../../../../validators/has-number.validator";
import { hasUppercaseValidator } from "../../../../../validators/has-uppercase.validator";
import { MustMatch } from "../../../../../validators/must-match.validator";
import { User } from "../../../../../core/_models/user.model";

@Component({
  "selector": "kt-security-personal-settings",
  "templateUrl": "./security-personal.component.html",
})
export class SecurityPersonalSettingsComponent implements OnInit {
  @Input() user: User;
  updatePasswordForm: FormGroup;
  loading: boolean;

  constructor (private readonly formBuilder: FormBuilder) {}

  ngOnInit () {
    this.initUpdatePassForm();
  }

  initUpdatePassForm () {
    this.updatePasswordForm = this.formBuilder.group(
      {
        "oldPassword": ["", Validators.compose([Validators.required])],
        "newPassword": [
          "",
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            hasUppercaseValidator(),
            hasLowercaseValidator(),
            hasNumberValidator(),
          ]),
        ],
        "confirmNewPassword": ["", Validators.compose([Validators.required])],
      },
      {
        "validator": MustMatch("newPassword", "confirmNewPassword"),
      },
    );
  }

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError (controlName: string, validationType: string): boolean {
    const control = this.updatePasswordForm.controls[controlName];
    if (!control) {
      return false;
    }

    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }

  onSubmit () {
    const oldPassword = this.updatePasswordForm.get("oldPassword").value,
     newPassword = this.updatePasswordForm.get("newPassword").value;
    if (this.updatePasswordForm.touched && this.updatePasswordForm.valid) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          this.updatePasswordForm.markAsPristine();
          return Auth.changePassword(user, oldPassword, newPassword);
        })
        .then((data) => {
          if (data === "SUCCESS") {
            Swal.fire({
              "icon": "success",
              "title": $localize`:@@global.succeddfully-updated:Successfully updated!`,
            });
          }
        })
        .catch((err) => {
          if (err.message.includes("Incorrect username or password")) {
            return Swal.fire({
              "icon": "error",
              "title": $localize`:@@securityPersonal.incorrect-oldPassword:Incorrect old password`,
            });
          }
          return Swal.fire({
            "icon": "error",
            "title": err.message,
          });
        });
    }
  }
}
