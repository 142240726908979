import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  CommodityResultTypeEnum,
  CommodityService,
  IDgdDictionnary,
  RequirementCodeEnum,
} from "@cai-services";
import { CaiInput, CaiInputTypesEnum } from "@cai-framework";
import {
  DangerousGoodTableConfig,
  DangerousGoodUOM,
} from "../../../constant/requirements/dangerous-goods-table.const";
import { DangerousGood } from "../../../core/_models/dangerous-good.model";
import { DangerousGoodUtil } from "../../../utils/dangerous-goods.util";
import { CargoAircraftOnlyAnswer } from "../../../constant/requirements/cargo-aircraft-only.const";
import { PharmaDangerousGoodsAnswer } from "../../../constant/requirements/pharma-dangerous-goods.const";
import { RequirementColumn } from "../../../core/_models/requirement-column.model";

const PARENT_COMPONENT = RequirementCodeEnum.PHARMA_DANGEROUS_GOOD;

@Component({
  "selector": "kt-requirement-dangerous-good-table",
  "templateUrl": "./requirement-dangerous-good-table.component.html",
  "styleUrls": ["./requirement-dangerous-good-table.component.scss"],
})
export class RequirementDangerousGoodTableComponent implements OnInit {
  @ViewChildren("colInput") colInputs: QueryList<CaiInput>;
  @Input() formGroup: FormGroup = new FormGroup({});
  @Input() isShipmentDetailsPage: boolean;
  @Output() onFocus = new EventEmitter();
  @Output() onDgdResultsChange = new EventEmitter();
  @Output() onCAOChange = new EventEmitter();
  @Output() updateShc = new EventEmitter<{
    isDelete: boolean;
    dgs: DangerousGood[];
  }>();

  code: string = RequirementCodeEnum.DANGEROUS_GOOD_TABLE;
  tableColumns: RequirementColumn[] = DangerousGoodTableConfig.columns;
  dangerousGoods: DangerousGood[] = [];
  dangerousGoodResults: IDgdDictionnary[] = [];
  visible = true;
  isCAO: boolean;
  inputTypesEnum = CaiInputTypesEnum;
  currentIndex: number;
  searchedQueries: string[] = [];

  constructor (
    private readonly cdr: ChangeDetectorRef,
    private readonly commodityService: CommodityService,
  ) {}

  ngOnInit (): void {
    const field = this.formGroup.get(this.code);
    if (field) {
      this.updateTableData(field.value);
      const validRows = (field.value || []).filter((item) =>
        DangerousGoodUtil.isDangerousGoodValid(item, this.isCAO),
      );
      field.setErrors(validRows.length ? null : { "required": {} });
    }

    const caoField = this.formGroup.get(
      RequirementCodeEnum.CARGO_AIRCRAFT_ONLY,
    );
    if (caoField) {
      caoField.valueChanges.subscribe((value) => {
        this.isCAO = value === CargoAircraftOnlyAnswer.YES;
        this.onCAOChange.emit(this.isCAO);
        this.checkErrors();
        this.updateDangerousGoods();
        this.cdr.detectChanges();
      });
    }

    const parentField = this.formGroup.get(PARENT_COMPONENT);
    if (parentField) {
      const initialValue = this.formGroup.get(PARENT_COMPONENT).value;
      this.updateTableDataBasedOnParent(initialValue);

      parentField.valueChanges.subscribe((value) => {
        this.updateTableDataBasedOnParent(value);
      });
    }
  }

  async searchDangerousGoods (dangerousGood: DangerousGood, query: string) {
    if (query) {
      this.dangerousGoodResults = [...this.dangerousGoodResults];
      if (!this.searchedQueries.find((q) => q === query)) {
        this.searchedQueries.push(query);
        const res = await this.commodityService.searchCommodities(
          query,
          CommodityResultTypeEnum.DANGEROUS_GOOD,
        ),
         filtered = res.filter(
          (item) => item.type === CommodityResultTypeEnum.DANGEROUS_GOOD,
        ),
         filteredRes: IDgdDictionnary[] = filtered
          .flatMap((item) => item.object)
          .filter((item) => !this.dangerousGoodResults.find(
              (option) => option.id === item.id,
            ));
        if (filteredRes.length) {
          this.dangerousGoodResults =
            this.dangerousGoodResults.concat(filteredRes);
          this.onDgdResultsChange.emit(this.dangerousGoodResults);
        }
      }
    } else if (!dangerousGood.searchResult) {
      this.resetValues(dangerousGood);
    }
    this.cdr.detectChanges();
  }

  selectDangerousGoodFromResults (
    dangerousGood: DangerousGood,
    searchResult: IDgdDictionnary,
  ): void {
    if (dangerousGood.unid?.length) {
      dangerousGood.isNewRow = false;
    }
    if (
      dangerousGood.searchResult &&
      dangerousGood.searchResult.id === `UN${dangerousGood.unid}`
    ) {
      return;
    }
    if (searchResult) {
      dangerousGood.searchResult = searchResult;
      dangerousGood.unid = searchResult.id.replace("UN", "");
      const goodClassOptions = DangerousGoodUtil.getOptionsFromString(
        searchResult.goodClass,
      );
      dangerousGood.goodClass =
        DangerousGoodUtil.selectFirstItemOrNone(goodClassOptions);
      dangerousGood.goodClassOptions = goodClassOptions;

      const packagingGroupOptions =
        DangerousGoodUtil.getPackagingGroupOptions(searchResult);
      dangerousGood.packagingGroup = DangerousGoodUtil.selectFirstItemOrNone(
        packagingGroupOptions,
      );
      dangerousGood.packagingGroupOptions = packagingGroupOptions;
      dangerousGood.packagingInstruction = null;
      dangerousGood.packagingInstructionOptions = [];
      dangerousGood.totalNet = null;
      dangerousGood.uom = null;
      dangerousGood.uomOptions = [];
      dangerousGood.caoPackagingInstruction = null;
      dangerousGood.caoTotalNet = null;
      dangerousGood.caoUomOptions = DangerousGoodUOM;
      dangerousGood.caoUom = DangerousGoodUOM[0].value;
      dangerousGood.shc = null;
      dangerousGood.shcOptions = [];
      this.changePackagingGroup(dangerousGood);
      this.checkAddNewRow();
    } else if (!dangerousGood.isNewRow) {
      this.resetValues(dangerousGood);
    }
    if (dangerousGood.unid && this.isShipmentDetailsPage) {
      this.updateShc.emit({ "isDelete": false, "dgs": [dangerousGood] });
    }
    this.updateDangerousGoods();
    this.cdr.detectChanges();
  }

  changePackagingGroup (dangerousGood: DangerousGood): void {
    const packagingInstructionOptions =
      DangerousGoodUtil.getPackagingInstructionOptions(
        dangerousGood.packagingGroup,
        dangerousGood.searchResult,
      ),
     packagingInstructionValue = DangerousGoodUtil.selectFirstItemOrNone(
      packagingInstructionOptions,
    );
    if (dangerousGood.unid === "2915") {
      dangerousGood.packagingInstruction = null;
      dangerousGood.packagingInstructionOptions = [];
      dangerousGood.caoPackagingInstruction = null;
    } else if (
      !dangerousGood.packagingInstructionOptions.length &&
      !dangerousGood.packagingInstruction
    ) {
      if (packagingInstructionValue !== "NONE") {
        dangerousGood.packagingInstruction = packagingInstructionValue;
        dangerousGood.packagingInstructionOptions = packagingInstructionOptions;
        if (this.isCAO) {
          dangerousGood.caoPackagingInstruction = packagingInstructionValue;
        }
      } else {
        dangerousGood.packagingInstruction = null;
        dangerousGood.packagingInstructionOptions = [];
        dangerousGood.caoPackagingInstruction = null;
      }
    }

    if (
      !dangerousGood.packagingInstructionOptions.find(
        (opt) => opt.value === dangerousGood.packagingInstruction,
      )
    ) {
      dangerousGood.isPackagingInstructionEditable = true;
    }

    this.changePackagingInstruction(dangerousGood);
  }

  changePackagingInstruction (dangerousGood: DangerousGood): void {
    const nonCAOValues =
      DangerousGoodUtil.getNonCAOValuesByPackaging(dangerousGood);
    dangerousGood.uom = nonCAOValues.uom;
    dangerousGood.uomOptions = nonCAOValues.uomOptions;
    if (!dangerousGood.shcOptions.length) {
      dangerousGood.shc = nonCAOValues.shc;
      dangerousGood.shcOptions = DangerousGoodUtil.getOptionsFromString(
        nonCAOValues.shc,
      );
    }
    this.updateDangerousGoods();
  }

  isFieldReadOnly (
    dangerousGood: DangerousGood,
    optionsKey: string,
    minOptions = 1,
  ): boolean {
    return (
      !dangerousGood.isNewRow &&
      dangerousGood[optionsKey] &&
      dangerousGood[optionsKey].length < minOptions
    );
  }

  resetValues (dangerousGood: DangerousGood): void {
    dangerousGood.unid = null;
    dangerousGood.searchResult = null;
    dangerousGood.isNewRow = true;
    dangerousGood.goodClass = null;
    dangerousGood.goodClassOptions = [];
    dangerousGood.packagingGroup = null;
    dangerousGood.packagingGroupOptions = [];
    dangerousGood.packagingInstruction = null;
    dangerousGood.packagingInstructionOptions = [];
    dangerousGood.totalNet = null;
    dangerousGood.uom = null;
    dangerousGood.uomOptions = [];
    dangerousGood.caoPackagingInstruction = null;
    dangerousGood.caoTotalNet = null;
    dangerousGood.caoUom = null;
    dangerousGood.caoUomOptions = [];
    dangerousGood.shc = null;
    dangerousGood.shcOptions = [];
  }

  checkAddNewRow (): void {
    const newItem = new DangerousGood();
    if (this.dangerousGoods.length) {
      const invalidRows = this.dangerousGoods.filter(
        (item) => !DangerousGoodUtil.isPartiallyFilled(item),
      );
      if (!invalidRows.length) {
        this.dangerousGoods.push(newItem);
        this.cdr.detectChanges();
      }
    } else {
      this.dangerousGoods.push(newItem);
      this.cdr.detectChanges();
    }
  }

  focusRow (dangerousGood: DangerousGood, index: number) {
    this.currentIndex = index;
    this.dangerousGoods
      .filter((d) => d.isFocused)
      .forEach((d) => {
        d.isFocused = false;
      });
    dangerousGood.isFocused = true;
    dangerousGood.isTouched = true;
    this.checkErrors();
    this.onFocus.emit();
    this.cdr.detectChanges();
  }

  unfocusRow (dangerousGood: DangerousGood) {
    this.currentIndex = null;
    dangerousGood.isFocused = false;
    this.checkErrors();
    this.updateDangerousGoods();
    this.cdr.detectChanges();
  }

  touchRow (dangerousGood: DangerousGood, isMandatory = false) {
    dangerousGood.isFocused = true;
    dangerousGood.isTouched = true;
    if (isMandatory) {
      dangerousGood.isMandatoryTouched = true;
    }
    this.onFocus.emit();
  }

  deleteRow (rowIndex: number): void {
    if (this.dangerousGoods.length === 1) {
      this.dangerousGoods = [];
      this.checkAddNewRow();
    } else {
      const deletedRow = this.dangerousGoods.splice(rowIndex, 1);
      if (this.isShipmentDetailsPage) {
        this.updateShc.emit({ "isDelete": true, "dgs": deletedRow });
      }
      this.cdr.detectChanges();
    }
    this.currentIndex = null;
    this.updateDangerousGoods();
    this.checkErrors();
    this.dangerousGoods.forEach((row) => {
      if (!row.isNewRow) {
        row.isTouched = true;
      }
    });
    this.cdr.detectChanges();
  }

  showDelete (dangerousGood: DangerousGood, index: number): boolean {
    const newRow =
      dangerousGood.isNewRow && index < this.dangerousGoods.length - 1,
     existingRow =
      !dangerousGood.isNewRow &&
      !dangerousGood.isFocused &&
      dangerousGood.isTouched &&
      !this.isInvalidRow(dangerousGood, index);
    return (
      DangerousGoodUtil.isPartiallyFilled(dangerousGood) ||
      newRow ||
      existingRow
    );
  }

  isInvalidRow (dangerousGood: DangerousGood, index: number): boolean {
    if (this.isShipmentDetailsPage) {
      return (
        !dangerousGood.isFocused &&
        dangerousGood.isTouched &&
        dangerousGood.errors &&
        !!dangerousGood.errors.length
      );
    }
    return (
      !dangerousGood.isFocused &&
      dangerousGood.isTouched &&
      dangerousGood.errors &&
      !!dangerousGood.errors.length &&
      this.currentIndex !== index
    );
  }

  updateTableData (dangerousGoods: DangerousGood[]): void {
    const caoField = this.formGroup.get(
      RequirementCodeEnum.CARGO_AIRCRAFT_ONLY,
    );
    if (caoField) {
      this.isCAO = caoField.value === CargoAircraftOnlyAnswer.YES;
    }
    this.dangerousGoods = (dangerousGoods || [])
      .map((dangerousGood) => {
        if (dangerousGood.searchResult) {
          return dangerousGood;
        } else {
          const matchedResult = this.dangerousGoodResults.find(
            (result) => result.id === `${dangerousGood.unid}`,
          );
          let packagingInstructionOptions = [];
          if (matchedResult) {
            packagingInstructionOptions =
              DangerousGoodUtil.getPackagingInstructionOptions(
                dangerousGood.packagingGroup,
                matchedResult,
              );

            let uomOptions = DangerousGoodUOM;
            const selectedPackagingType = matchedResult.packagingTypes.find(
              (type) => {
                const formatted = type.packagingType.replace("TYPE", "").trim();
                return formatted === dangerousGood.packagingGroup;
              },
            ),
             selectedPackagingInstruction =
              packagingInstructionOptions.find((option) => option.value === dangerousGood.packagingInstruction);

            if (selectedPackagingInstruction && selectedPackagingType) {
              const prefix = selectedPackagingInstruction.description,
               uom = selectedPackagingType[prefix + "Uom"];
              uomOptions = [
                {
                  "label": uom,
                  "value": uom,
                },
              ];
            }

            if (
              !packagingInstructionOptions.length &&
              !dangerousGood.packagingInstructionOptions?.length &&
              matchedResult.packagingTypes.length
            ) {
              const formattedType =
                matchedResult.packagingTypes[0].packagingType
                  .replace("TYPE", "")
                  .trim();
              packagingInstructionOptions =
                DangerousGoodUtil.getPackagingInstructionOptions(
                  formattedType,
                  matchedResult,
                );
            }

            let shcOptions = [];
            if (selectedPackagingType?.shc) {
              shcOptions = DangerousGoodUtil.getOptionsFromString(
                selectedPackagingType.shc,
              );
            }
            if (
              !shcOptions.length &&
              !dangerousGood.shcOptions?.length &&
              matchedResult.packagingTypes.length
            ) {
              if (matchedResult.packagingTypes[0]) {
                shcOptions = DangerousGoodUtil.getOptionsFromString(
                  matchedResult.packagingTypes[0].shc,
                );
              }
            }

            return {
              ...dangerousGood,
              "shc": dangerousGood.shc
                ? dangerousGood.shc.toUpperCase()
                : dangerousGood.shc,
              shcOptions,
              "searchResult": matchedResult,
              "goodClassOptions": DangerousGoodUtil.getOptionsFromString(
                matchedResult.goodClass,
              ),
              "packagingGroupOptions":
                DangerousGoodUtil.getPackagingGroupOptions(matchedResult),
              "caoUomOptions": DangerousGoodUOM,
              packagingInstructionOptions,
              uomOptions,
            };
          }
        }
        return null;
      })
      .filter((dg) => dg);
    this.checkAddNewRow();
    this.checkErrors();
    this.dangerousGoods.forEach((dimension) => {
      if (!dimension.isNewRow) {
        dimension.isTouched = true;
      }
    });
  }

  updateTableDataBasedOnParent (value): void {
    const visible = value === PharmaDangerousGoodsAnswer.YES,
     field = this.formGroup.get(this.code);
    if (visible) {
      this.visible = true;
      if (field.value) {
        this.updateTableData(field.value);
      } else {
        this.updateTableData([]);
      }
      this.updateDangerousGoods();
    } else {
      this.visible = false;
      this.updateTableData([]);
      field.setErrors(null);
    }
  }

  updateDangerousGoods (): void {
    const validRows = this.dangerousGoods
      .filter((item) =>
        DangerousGoodUtil.isDangerousGoodValid(item, this.isCAO),
      )
      .map((item) => ({
          ...item,
          "shc": item.shc ? item.shc.toUpperCase() : item.shc,
        })),
     field = this.formGroup.get(this.code);
    field.setValue(this.dangerousGoods.length ? this.dangerousGoods : null);
    field.setErrors(validRows.length ? null : { "required": {} });
  }

  formatSHC (dangerousGood: DangerousGood) {
    if (dangerousGood.shc) {
      dangerousGood.shc = dangerousGood.shc
        .split(",")
        .filter((shc) => shc)
        .map((shc) => shc.toUpperCase().trim())
        .join(",");
    }
    this.unfocusRow(dangerousGood);
  }

  enableCAOFields (dangerousGood: DangerousGood, inputElement: any): void {
    if (
      this.isNonCAOFieldsEnabled(dangerousGood) &&
      !this.isCAOFieldsEnabled(dangerousGood)
    ) {
      dangerousGood.packagingInstruction = null;
      dangerousGood.totalNet = null;
      dangerousGood.caoPackagingInstruction = "";
      dangerousGood.caoTotalNet = null;
      dangerousGood.caoUom = DangerousGoodUOM[0].value;
      dangerousGood.errors = [];
      inputElement.input?.nativeElement.focus();
      this.cdr.detectChanges();
    }
  }

  enableNonCAOFields (dangerousGood: DangerousGood, inputElement: any): void {
    if (
      !this.isNonCAOFieldsEnabled(dangerousGood) &&
      this.isCAOFieldsEnabled(dangerousGood)
    ) {
      dangerousGood.packagingInstruction = null;
      dangerousGood.totalNet = null;
      dangerousGood.caoPackagingInstruction = null;
      dangerousGood.caoTotalNet = null;
      dangerousGood.caoUom = null;
      dangerousGood.errors = [];
      inputElement.input?.nativeElement.focus();
      this.cdr.detectChanges();
    }
  }

  isCAOFieldsEnabled (dangerousGood: DangerousGood): boolean {
    return DangerousGoodUtil.isCAOFieldsEnabled(
      dangerousGood,
      this.isCAO,
      dangerousGood.isMandatoryTouched,
    );
  }

  isNonCAOFieldsEnabled (dangerousGood: DangerousGood): boolean {
    return DangerousGoodUtil.isNonCAOFieldsEnabled(
      dangerousGood,
      this.isCAO,
      dangerousGood.isMandatoryTouched,
    );
  }

  validate (): void {
    this.currentIndex = null;
    this.dangerousGoods.forEach((dangerousGood) => {
      dangerousGood.isTouched = true;
      dangerousGood.isMandatoryTouched = true;
      dangerousGood.isFocused = false;
    });
    this.checkErrors();
    this.cdr.detectChanges();
  }

  checkErrors (): void {
    this.dangerousGoods.forEach((dangerousGood, index) => {
      dangerousGood.errors = [];
      for (const column of this.tableColumns) {
        const error = this.getFieldError(column.name, dangerousGood),
         existingFieldError = dangerousGood.errors.find(
          (err) => err.field === column.name,
        ),
         existingMessage = dangerousGood.errors.find(
          (err) => err.message === error,
        ),
         isCAOFieldsEnabled =
          ["packagingInstruction", "totalNet"].includes(column.name) &&
          this.isCAOFieldsEnabled(dangerousGood),
         isNonCAOFieldsEnabled =
          ["caoPackagingInstruction", "caoTotalNet"].includes(column.name) &&
          this.isNonCAOFieldsEnabled(dangerousGood);
        if (
          error &&
          !existingFieldError &&
          !isCAOFieldsEnabled &&
          !isNonCAOFieldsEnabled &&
          (!dangerousGood.isNewRow ||
            (dangerousGood.isNewRow && column.name === "unid" && index === 0))
        ) {
          dangerousGood.errors.push({
            "field": column.name,
            "message": !existingMessage ? error : null,
          });
        }
      }
    });
  }

  isInvalidField (
    name: string,
    dangerousGood: DangerousGood,
    index: number,
  ): boolean {
    if (this.isShipmentDetailsPage) {
      return (
        !dangerousGood.isFocused &&
        dangerousGood.isTouched &&
        dangerousGood.errors &&
        dangerousGood.errors.find((error) => error.field === name)
      );
    }
    return (
      !dangerousGood.isFocused &&
      dangerousGood.isTouched &&
      dangerousGood.errors &&
      dangerousGood.errors.find((error) => error.field === name) &&
      this.currentIndex !== index
    );
  }

  getFieldError (name: string, dangerousGood: DangerousGood): string {
    let fieldError = null;
    const column = this.tableColumns.find((c) => c.name === name),
     value = dangerousGood[name];
    if (value !== "NONE" && column && column.validators) {
      for (const key of Object.keys(column.validators)) {
        const error = column.validators[key](value);
        if (error && !fieldError) {
          fieldError = error;
        }
      }
    }
    return fieldError;
  }
}
