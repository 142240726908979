import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { UserStatusEnum, UserTypeEnum } from '@cai-services';
import { datadogRum } from '@datadog/browser-rum';
import { APP_PROPS } from '../../cai-common.module';
import { environment } from '../../environments';
import { ApplicationProperties } from '../_base/layout/models/app-properties.model';
import { User } from '../_models/user.model';
import { RegisterService } from '../_services/register.service';
import { SessionService } from '../_services/session.service';
import { HomepageCommonUtil } from '../../utils';
import { UserFeaturesEnum } from '../_enums';

const forwarderRateRoutes = [
  '/compare-rates',
  '/rate-sheet',
  '/contract-rate',
  '/market-rate',
  '/promo-rate',
];

@Injectable()
export class AuthenticatedGuard {
  protected nonActivatedUrl: string;
  private readonly dashboardUrls: string[] = [
    '/airline/dashboard/market-analysis',
    '/airline/dashboard/load-board',
    '/forwarder/dashboard/market-analysis',
    '/forwarder/dashboard/airline-contacts',
  ];
  private readonly shipperQuoteUrl: string = '/forwarder/quote/shipper-quote';
  features = environment.features;

  constructor(
    @Inject(APP_PROPS)
    protected appProperties: ApplicationProperties,
    protected router: Router,
    protected sessionService: SessionService,
    protected registerService: RegisterService,
  ) {}

  isAnalyticalDashboardActivated(url: string): boolean {
    const user = this.sessionService.getCurrentUser();

    if (user?.type === UserTypeEnum.FORWARDER) {
      const officeId = this.sessionService.getSelectedOfficeId(),
        office = this.sessionService
          .getCurrentUser()
          ?.officeAccesses?.find((oa) => oa.office?.id == officeId)?.office;
      if (url === '/forwarder/dashboard/airline-contacts') {
        return HomepageCommonUtil.isFeatureEnabled(
          office?.activatedFeatures,
          UserFeaturesEnum.ANALYTICS_AIRLINE_CONTACT,
        );
      }

      return HomepageCommonUtil.isFeatureEnabled(
        office?.activatedFeatures,
        UserFeaturesEnum.ANALYTICS_FORWARDER,
      );
    }

    if (user?.type === UserTypeEnum.AIRLINE) {
      return HomepageCommonUtil.isFeatureEnabled(
        user?.activatedFeatures,
        UserFeaturesEnum.ANALYTICS_AIRLINE,
      );
    }
    return false;
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    if (
      state.url === '/pay-to' &&
      this.sessionService.getItemSessionStorage('isGuestUser')
    ) {
      return true;
    }
    const user = this.sessionService.getCurrentUser();

    if (state.url.toUpperCase().includes('/SEARCH/')) {
      if (
        user?.type === UserTypeEnum.AIRLINE &&
        !user?.activatedFeatures?.find((af) => af.featureName === 'INTERLINE')
      ) {
        this.router.navigateByUrl('/invalid-link');
        return false;
      }
    }

    if (
      state.url.toUpperCase().includes('/COMPARE-RATES') ||
      state.url.toUpperCase().includes('/RATE-SHEET')
    ) {
      if (user?.type === UserTypeEnum.FORWARDER) {
        if (!this.isCargoMartProEnabled) {
          return true;
        } else {
          const officeId = this.sessionService.getSelectedOfficeId(),
            office = this.sessionService
              .getCurrentUser()
              ?.officeAccesses?.find((oa) => oa.office?.id == officeId)?.office,
            hasAccess =
              HomepageCommonUtil.isFeatureEnabled(
                office?.activatedFeatures,
                UserFeaturesEnum.RATE_MANAGEMENT_UPLOAD,
              ) &&
              HomepageCommonUtil.isFeatureEnabled(
                office?.activatedFeatures,
                UserFeaturesEnum.RATE_MANAGEMENT_ACCESS,
              );
          if (!hasAccess) {
            this.router.navigateByUrl('/invalid-link');
            return false;
          }
          return true;
        }
      }
    }

    if (forwarderRateRoutes.some((route) => state.url?.includes(route))) {
      const { userEmail, type, company } =
        this.sessionService?.getCurrentUser();
      if (
        type === UserTypeEnum.FORWARDER &&
        company?.providers?.length &&
        (userEmail?.startsWith('tests+') || company?.providers[0] !== 'DHL')
      ) {
        this.router.navigateByUrl('/invalid-link');
        return false;
      }
    }

    //CARGOMART PLAN check for Dashboards
    if (
      this.dashboardUrls?.includes(state.url) &&
      !this.isAnalyticalDashboardActivated(state.url)
    ) {
      this.router.navigateByUrl('/invalid-link');
      return false;
    }

    //CARGOMART PLAN check for Quotation

    if (this.shipperQuoteUrl === state.url) {
      const officeId = this.sessionService.getSelectedOfficeId(),
        office = this.sessionService
          .getCurrentUser()
          ?.officeAccesses?.find((oa) => oa.office?.id == officeId)?.office;
      if (
        !HomepageCommonUtil.isFeatureEnabled(
          office?.activatedFeatures,
          UserFeaturesEnum.QUOTATION,
        )
      ) {
        this.router.navigateByUrl('/invalid-link');
        return false;
      }
    }

    const err = await this.tryPasswordlessLogin(route);
    if (err) {
      this.router.navigateByUrl('/invalid-link');
      return false;
    }
    try {
      const currentUser = await Auth.currentAuthenticatedUser();

      await this.onAuthenticated(currentUser);
      const currentGroups =
        currentUser.signInUserSession.accessToken.payload['cognito:groups'];

      let user = this.sessionService.getCurrentUser();
      if (!user) {
        user = await this.registerService.getUser();
        this.sessionService.setCurrentUser(user);
      }
      const userType = user?.type.toLowerCase();
      this.nonActivatedUrl = `/${userType}/profile/${userType}-profile`;

      if (!this.isUserActivated(user, state)) {
        this.redirectOnNotActivated(user);
        return false;
      }

      if (!this.isUserAuthorizedToViewPage(currentGroups)) {
        this.redirectOnUnauthorized(currentGroups, state.url);
        return false;
      }

      return true;
    } catch (e) {
      const prefillRegisterEmail = state.root.queryParams?.prefillRegisterEmail;
      let url = state.url;
      if (!!prefillRegisterEmail) {
        url = state.url.replace(/prefillRegisterEmail=[^&]*/, '');
      }
      this.navigateToLogin(url, prefillRegisterEmail);
      return false;
    }
  }

  isUserActivated(user: User, state: RouterStateSnapshot): boolean {
    if (
      !this.isCargoAdmin &&
      (user.userStatus === UserStatusEnum.ACTIVATED ||
        state.url.indexOf(this.nonActivatedUrl) > -1)
    ) {
      return true;
    }

    if (this.isCargoAdmin) {
      if (user.userStatus === UserStatusEnum.PENDING_MANUAL_ACTIVATION) {
        return false;
      }
      return true;
    }

    return false;
  }

  // returns authentication error if any, null otherwise
  private async tryPasswordlessLogin(
    route: ActivatedRouteSnapshot,
  ): Promise<any> {
    const urlEncodedBase64EncodedToken = route.queryParams.auth;

    // don't try when login params don't exist
    if (!urlEncodedBase64EncodedToken) {
      return null;
    }

    await this.tryLoggingOutIfSessionExists();

    /*
      Expects auth token to be (in order):
      1. converted to JSON string
      2. converted to base64
      3. URL encoded
     */
    const auth: AuthToken = JSON.parse(
      atob(decodeURIComponent(urlEncodedBase64EncodedToken)),
    );
    try {
      const cognitoUser: CognitoUser = await Auth.signIn(auth.email);
      await Auth.sendCustomChallengeAnswer(cognitoUser, auth.secret);
      await Auth.currentSession();
      return null;
    } catch (e: any) {
      return e;
    }
  }

  private async tryLoggingOutIfSessionExists(): Promise<void> {
    try {
      await Auth.currentSession();
      await Auth.signOut();
    } catch {}
  }

  private async onAuthenticated(currentUser: any): Promise<void> {
    this.initDatadog(currentUser);
    if (this.isCargoWallet) {
      await this.sessionService.initSelectedOffice();
    }
  }

  private initDatadog(currentUser: any): void {
    if (!environment.datadog) {
      return;
    }
    datadogRum.setUser({
      id: currentUser.attributes['custom:userId'],
      name: currentUser.attributes.nickname,
      email: currentUser.attributes.email,
    });
  }

  private redirectOnNotActivated(user: User): void {
    if (user.userStatus === UserStatusEnum.PENDING_COMPANY_INFORMATION) {
      this.router.navigateByUrl(`/register/${user.type.toLowerCase()}`);
    } else {
      if (this.isCargoAdmin) {
        this.nonActivatedUrl = '/auth/login';
      }

      this.router.navigateByUrl(this.nonActivatedUrl);
    }
  }

  protected isUserAuthorizedToViewPage(loggedInUserGroups: any[]): boolean {
    return true;
  }

  private redirectOnUnauthorized(
    groupsOfLoggedInUser: any[],
    currentUrl: string,
  ): void {
    const userMenu = this.appProperties.menu.find(
      (config) =>
        !!groupsOfLoggedInUser.find((group) =>
          group.includes(config.userType.toLowerCase()),
        ),
    );
    if (userMenu) {
      this.router.navigateByUrl(userMenu.defaultPage);
      return;
    }
    this.navigateToLogin(currentUrl);
    return;
  }

  private navigateToLogin(returnUrl?: string, prefillRegisterEmail?: string) {
    const queryParams = returnUrl ? { return: returnUrl } : ({} as any);
    queryParams.prefillRegisterEmail = prefillRegisterEmail
      ? prefillRegisterEmail
      : null;
    if (this.sessionService.getItemSessionStorage('isGuestUser')) {
      return this.router.navigate(['/pay-to']);
    }
    this.router.navigate(['/auth/login'], { queryParams });
  }

  get isCargoAdmin(): boolean {
    return this.appProperties.name === 'cargoadmin';
  }

  get isCargoWallet(): boolean {
    return this.appProperties.name === 'cargowallet';
  }

  get isCargoMartProEnabled(): boolean {
    return (
      HomepageCommonUtil.isFeatureEnabled(
        this.sessionService.getCurrentUser()?.activatedFeatures,
        UserFeaturesEnum.CARGOMART_PRO,
      ) || this.features?.cargomartPro
    );
  }
}

interface AuthToken {
  email: string;
  secret: string;
}
