const SCROLL_BREAKPOINT = 406,
 MIN_CARD_HEIGHT = 70,
 DEFAULT_OFFSET = 3,
 DEFAULT_GOODS_TYPE = "General Cargo",
 DANGEROUS_GOODS = "Dangerous Goods",
 KNOWN_SHIPPER_COUNTRIES = ["US", "CA"];

export {
  SCROLL_BREAKPOINT,
  MIN_CARD_HEIGHT,
  DEFAULT_OFFSET,
  DEFAULT_GOODS_TYPE,
  DANGEROUS_GOODS,
  KNOWN_SHIPPER_COUNTRIES,
};
