import { TitleCasePipe } from "@angular/common";
import { MeasurementUnit } from "../../../core/_models/measurement-system.model";
import { minMaxUnitValues } from "../components/load-type/constants/measurements.const";
import { DimensionsConfig } from "../constants/dimension.const";

export class CaiDimensionUtil {
  constructor (public readonly titleCasePipe: TitleCasePipe) {}

  static isPartiallyFilled (dimension): boolean {
    return (
      dimension?.dimensionWidth > 0 ||
      dimension?.dimensionLength > 0 ||
      dimension?.dimensionHeight > 0 ||
      dimension?.weight > 0
    );
  }

  static isDimensionValid (
    dimension,
    weightUnit: MeasurementUnit,
    dimensionUnit: MeasurementUnit
  ): boolean {
    return (
      this.validateDimension(dimension, weightUnit, dimensionUnit).length === 0
    );
  }

  static validateDimension (
    dimension,
    weightUnit: MeasurementUnit,
    dimensionUnit: MeasurementUnit
  ): string[] {
    const dimesionErrors = [];
    let minValue,
     maxValue;
    DimensionsConfig.forEach((config) => {
      const value = dimension[config.field];
      if (dimensionUnit?.code === "in") {
        maxValue = minMaxUnitValues.maxINValue;
        minValue = minMaxUnitValues.minINValue;
      } else {
        maxValue = minMaxUnitValues.maxCMValue;
        minValue = minMaxUnitValues.minCMValue;
      }
      if (config.field === "weight" && weightUnit?.code === "lb") {
        maxValue = minMaxUnitValues.maxLBValue;
        minValue = minMaxUnitValues.minLBValue;
      } else if (config.field === "weight") {
        maxValue = minMaxUnitValues.maxKGValue;
        minValue = minMaxUnitValues.minKGValue;
      }
      for (const key of Object.keys(config.validators)) {
        const error = config.validators[key](
          value,
          config.field === "weight" ? weightUnit?.code : dimensionUnit?.code,
          maxValue,
          minValue
        ),
         exists = dimesionErrors.find((err) => err.field === config.field);
        if (error && !exists) {
          dimesionErrors.push({
            "field": config.field,
            "message": error,
          });
        }
      }
    });
    return dimesionErrors.map((err) => err.message);
  }
}
