import {
  ChangeDetectorRef,
  Component,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { MeasurementSystemEnum } from "@cai-services";
import Popper from "popper.js";
import { filter, fromEvent, takeUntil, takeWhile } from "rxjs";
import { MeasurementUnit } from "../../../../core/_models/measurement-system.model";
import { MeasurementUtil } from "../../../../core/_base/crud/utils/measurement.util";

@Component({
  "selector": "cai-load-type-unit-selector",
  "templateUrl": "./load-type-unit-selector.component.html",
  "styleUrls": ["./load-type-unit-selector.component.scss"],
})
export class CaiLoadTypeUnitSelectorComponent {
  @Input() width: number;
  @Input() marginTop: number;
  @Input() fontSize = 16;
  @Input() options: MeasurementUnit[] = [];
  @Input() isParentMetric: boolean;
  @Input() isParentImperial: boolean;
  @Input() selected: MeasurementUnit;
  @Input() isAdvancedBookings: boolean;
  @Input() measurementSystem: MeasurementSystemEnum;
  @Output() measurementSystemChange = new EventEmitter<MeasurementSystemEnum>();
  @Output() selectedChange = new EventEmitter<MeasurementUnit>();
  @Output() onClose = new EventEmitter();

  popperRef: Popper;
  view: EmbeddedViewRef<any>;

  constructor (
    private readonly vcr: ViewContainerRef,
    private readonly zone: NgZone,
    private readonly cdr: ChangeDetectorRef
  ) {}

  isOpen (): boolean {
    return !!this.popperRef;
  }

  open (dropdownTpl: TemplateRef<any>, wrapper: HTMLElement) {
    if (!!this.popperRef) {
      this.close();
    }

    if (this.options?.length) {
      this.view = this.vcr.createEmbeddedView(dropdownTpl);
      const dropdown = this.view.rootNodes[0];

      document.body.appendChild(dropdown);
      dropdown.style["z-index"] = "1";
      dropdown.style.display = "none";

      if (this.marginTop) {
        dropdown.style["margin-top"] = `${this.marginTop}px`;
      }

      setTimeout(() => {
        dropdown.style.display = "flex";
        this.popperRef = new Popper(wrapper, dropdown, {
          "placement": "bottom-end",
        });
        this.cdr.detectChanges();
        this.handleClickOutside();
      });
    }
  }

  select (option: MeasurementUnit) {
    if (this.selected !== option) {
      this.measurementSystem = MeasurementUtil.toggleMeasurementSystem(
        this.measurementSystem
      );
      this.measurementSystemChange.emit(this.measurementSystem);
      this.selected = option;
      this.selectedChange.emit(option);
      this.close();
      this.cdr.detectChanges();
    }
  }

  handleClickOutside () {
    fromEvent(document, "click")
      .pipe(
        takeWhile(() => this.isOpen()),
        filter(({ target }) => {
          const origin = this.popperRef.reference as HTMLElement;
          return origin.contains(target as HTMLElement) === false;
        }),
        takeUntil(this.onClose)
      )
      .subscribe(() => {
        this.close();
        this.cdr.detectChanges();
      });
  }

  close () {
    this.onClose.emit();
    if (this.view) {
      this.view.destroy();
      this.view = null;
    }
  }

  get isMetric (): boolean {
    if (this.isParentMetric !== undefined) {
      return this.isParentMetric;
    }
    return this.measurementSystem === MeasurementSystemEnum.METRIC;
  }

  get isImperial (): boolean {
    if (this.isParentImperial !== undefined) {
      return this.isParentImperial;
    }
    return this.measurementSystem === MeasurementSystemEnum.IMPERIAL;
  }
}
