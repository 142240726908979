import {
  QuoteActionEnum,
  QuoteStatusEnum,
  IQuote,
  IQuoteWithErrors,
  IQuoteError,
  ITracking,
  AwbTypeEnum,
  QuoteOrigin,
  WalletPaymentTerms,
  WalletPaymentTypesEnum,
} from "@cai-services";
import { Action } from "./action";
import { AirlineBrand } from "./airline.model";
import { CoLoaderOffice } from "./coloader-office.model";
import { QuoteGhaAttachment } from "./quote-gha-attachment.model";
import { QuoteAttachment } from "./quote-attachment.model";
import { ContactQuote } from "./quote-contact.model.model";
import { QuoteRateDetail } from "./quote-rate-detail.model";
import { QuoteRequest } from "./quote-request.model";
import { Airport } from "./region-airport.model";
import { Trip } from "./trip.model";
import { PartnerExportFacility } from "./partner-export.model";

export class Quote implements IQuote {
  responsiveness: number;
  quoteDate: Date;
  isViewForwarder: boolean;
  isViewAirline: boolean;
  quoteCurrency: string;
  priority: number;
  suggestedCapacity: number;
  suggestedPrice: number;
  acceptedPrice: number;
  acceptedDate: Date;
  lastUpdatedDate: Date;
  isRate: boolean;
  minWeight: number;
  maxWeight: number;
  maxDensity: number;
  validity: Date;
  fromValidity: Date;
  lastViewed: Date;
  lastNotified: Date;
  rejectedReasons: string;
  cancelledReasons: string;
  contacts: ContactQuote[];
  trips: Trip[];
  skyPalletID: string;
  searchUUID: string;
  flightUUID: string;
  provider: boolean;
  rateId: string;
  actions: Action[];
  possibleActions: QuoteActionEnum[];
  chargeableWeight: number;
  actualWeight: number;
  actualVolume: number;
  actualPieces: number;
  trackings: ITracking[];
  isTrackTraceSubscribe: boolean;
  airlineConditions: string;
  salesConditions: string;
  source: string;
  allotmentCode: string;
  internalRateUpdatedDate: Date;
  isAwbDisabled: boolean;
  isAwbCommentDisabled?: boolean;
  isAllotmentDisabled?: boolean;
  isFFRsent: boolean;
  awbDisabled?: boolean;
  trackTraceSubscribe: boolean;
  timeleft: string;
  timeQuote: string;
  quoteRequest: QuoteRequest;
  airlineBrand: AirlineBrand;
  originAirport: Airport;
  destinationAirport: Airport;
  rateDetail: QuoteRateDetail;
  maxAttachmentCount: number;
  maxAttachmentTotalSize: number;
  attachments: QuoteAttachment[];
  ghaAttachments: QuoteGhaAttachment[];
  quoteId: number;
  quoteStatus: QuoteStatusEnum;
  awb: string;
  awbType: AwbTypeEnum;
  awbComments: string;
  apiContacts: string[];
  paymentType: WalletPaymentTypesEnum;
  payTerm: WalletPaymentTerms;
  paymentReferenceId: string;
  latestAcceptanceTime: Date;
  handlingInfoLink: string;
  ghaName: string;
  ghaAddress: string;
  hle?: string;
  timeOfAvailability: Date;
  sortOption?: string;
  co2Efficiency?: number;
  origin: QuoteOrigin;
  coLoaderOffice: CoLoaderOffice;
  isCoLoaderShipment: boolean;
  militaryOrDualUseGoods?: boolean;
  partnerExportFacilities?: PartnerExportFacility[];
  checkoutUrl?: string;
  optionCancellationDate?: Date;
  oldId: number;

  constructor (quoteDate?: Date, quoteCurrency?: string) {
    this.quoteCurrency = quoteCurrency;
    this.quoteDate = quoteDate;
    this.lastUpdatedDate = quoteDate;
    this.isViewAirline = false;
    this.isViewForwarder = false;
    this.quoteStatus = QuoteStatusEnum.PENDING_AIRLINE;
    this.isRate = false;
    this.provider = false;
    this.maxAttachmentCount = 3;
    this.maxAttachmentTotalSize = 9000000;
    this.partnerExportFacilities = [];
  }
}

export class QuoteWithErrors implements IQuoteWithErrors {
  errors: IQuoteError[];
  quote: Quote;
}

export class QuoteValidity {
  minWeight: number;
  maxWeight: number;
  maxDensity: number;
  validity: Date;
  fromValidity: Date;
  quoteDate?: Date;
  possibleActions: QuoteActionEnum[];
}
