import { Injectable } from "@angular/core";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

@Injectable({
  "providedIn": "root",
})
export class GraphqlUtil {
  static getAuthMode (isPublicPage: boolean): GRAPHQL_AUTH_MODE {
    return isPublicPage
      ? GRAPHQL_AUTH_MODE.API_KEY
      : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS;
  }
}
