export const copyAdvancedBookingDetailTemplate =
  "<b>---" +
  $localize`:@@email.booking-summary:Booking Summary` +
  ": {airlineName}" +
  "---</b><br/>" +
  $localize`:@@email.airline:Airline` +
  ": {airline}<br/>" +
  $localize`:@@email.origin:Origin` +
  ": {origin}<br/>" +
  $localize`:@@email.destination:Destination` +
  ": {destination}<br/>" +
  $localize`:@@email.flight-no:Flight Number` +
  ": {flightNo}<br/>" +
  $localize`:@@email.product-type:Product Type` +
  ": {productType}<br/>" +
  $localize`:@@email.weight:weight` +
  "({weightUnit})" +
  ": {weight}<br/>" +
  $localize`:@@email.volume:Volume(m3)` +
  ": {volume}<br/>" +
  $localize`:@@email.pieces:Pieces(pc(s))` +
  ": {pieces}<br/>" +
  $localize`:@@email.awb:AWB` +
  ": {awb}<br/>" +
  $localize`:@@email.allotment:Allotment` +
  ": {allotment}<br/>";

export const dimensionsTemplate =
  "<b>-" +
  $localize`:@@email.dimension-details:dimension details` +
  "-</b><br/>" +
  $localize`:@@dimension.pieces:pieces` +
  ": {pieces}<br/>" +
  $localize`:@@email.dimensions:Dimensions` +
  "({dimensionUnit})" +
  ": Length:{length}|Width:{width}|Height:{height}<br/>" +
  $localize`:@@email.weight:Weight` +
  "({weightUnit})" +
  ": {weight}|Per Piece<br/>" +
  "{packingType}<br/>" +
  "<br/>";

export const bupsTemplate =
  "<b>-" +
  $localize`:@@email.bup-details:bup details` +
  "-</b><br/>" +
  $localize`:@@bup.uld-type:ULD Type` +
  ": {uldType}<br/>" +
  $localize`:@@bup.quantity:Quantity` +
  ": {quantity}<br/>" +
  $localize`:@@email.weight:Weight` +
  "({weightUnit})" +
  ": {weight}|Per Piece<br/>" +
  $localize`:@@bup.volume:Volume` +
  ": {volume}{volumeUnit}<br/>" +
  "<br/>";

export const bookingDetailsTemplate =
  "<p>" +
  "{advancedBookingText}<br/>" +
  "{dimensionText}</p>" +
  "{bupText}</p>";
