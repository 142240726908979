export enum FileExtension {
  DOC = "doc",
  DOCX = "docx",
  EML = "eml",
  PDF = "pdf",
  PNG = "png",
  MSG = "msg",
  ZIP = "zip",
  TXT = "txt",
  XLS = "xls",
  CSV = "csv",
  XLSX = "xlsx",
}
