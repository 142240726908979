<!--begin::Form-->
<form class="form personal-settings-form" (ngSubmit)="onSubmit()">
  <div class="row wrapper">
    <div class="col-lg-12">
      <div class="mb-2">
        <h2 i18n="@@profile.notificaion-preferences">
          Notification Preferences
        </h2>
      </div>

      <!-- <div class="message">
        We will never distribute your email address to third parties. Read about email communication in our privacy policy.
      </div> -->
      <figure>
        <figcaption></figcaption>
        <table aria-describedby="notification-settings">
          <tbody>
            <tr>
              <td class="not-selectable">Select All</td>
              <td>
                <cai-checkbox
                  [isActive]="allSend.isSendEmail"
                  [styles]="{ 'justify-content': 'center' }"
                  [disabled]="!isFetched"
                  (change)="updateSetting(null, 'isSendEmail', $event)"
                >
                </cai-checkbox>
              </td>
              <td>
                <cai-checkbox
                  [isActive]="allSend.isSendToast"
                  [styles]="{ 'justify-content': 'center' }"
                  [disabled]="!isFetched"
                  (change)="updateSetting(null, 'isSendToast', $event)"
                >
                </cai-checkbox>
              </td>
            </tr>
            <ng-container
              *ngFor="let notification of notificationSettings; let i = index"
            >
              <tr class="not-selectable">
                <th scope="col" [attr.colspan]="i > 0 ? 3 : 1">
                  {{ notification.category }}
                </th>
                <th scope="col" *ngIf="i == 0" i18n="@@notification.Email">
                  Email
                </th>
                <th scope="col" *ngIf="i == 0" i18n="@@notification.In App">
                  In App
                </th>
              </tr>
              <ng-container *ngFor="let setting of notification.settings">
                <tr>
                  <td class="not-selectable">{{ setting.label }}</td>
                  <td>
                    <cai-checkbox
                      caiPopover
                      [target]="popOver"
                      [enablePopover]="
                        !isFetched || setting.isEmailNotApplicable
                      "
                      [isActive]="setting.isSendEmail"
                      [styles]="{ 'justify-content': 'center' }"
                      [disabled]="!isFetched || setting.isEmailNotApplicable"
                      [showDisableColor]="
                        !isFetched || setting.isEmailNotApplicable
                      "
                      (change)="updateSetting(setting, 'isSendEmail', $event)"
                    >
                    </cai-checkbox>
                  </td>
                  <td>
                    <cai-checkbox
                      caiPopover
                      [target]="popOver"
                      [enablePopover]="
                        !isFetched || setting.isPushNotApplicable
                      "
                      [isActive]="setting.isSendToast"
                      [styles]="{ 'justify-content': 'center' }"
                      [disabled]="!isFetched || setting.isPushNotApplicable"
                      [showDisableColor]="
                        !isFetched || setting.isPushNotApplicable
                      "
                      (change)="updateSetting(setting, 'isSendToast', $event)"
                    >
                    </cai-checkbox>
                  </td>
                  <td class="protag" *ngIf="showProTag(setting.name)">
                    <img
                      src="/assets/media/icons/pro-tag.svg"
                      alt="Pro upgrade tag"
                    />
                  </td>
                  <ng-template #popOver>
                    <kt-popover
                      [message]="popMessage(setting)"
                      [arrowColor]="'#679EC6'"
                      [bgColor]="'#679EC6'"
                      [textColor]="'white'"
                    ></kt-popover>
                  </ng-template>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </figure>

      <button
        type="submit"
        [disabled]="loading || !isSettingsUpdated"
        [ngClass]="{
          'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
            loading,
        }"
        class="btn btn-save mt-1"
        i18n="@@global.update-preferences"
      >
        Update Preferences
      </button>
    </div>
  </div>
</form>
<!--end::Form-->
