import moment from "moment";

const FORWARDER_GROUP = "forwarders",
 AIRLINE_GROUP = "airlines",
 STANDARD_GROUP = "standards",
 ADMIN_GROUP = "admins",
 SUPERADMIN_USER_GROUP = "superadmins",

 DEFAULT_ITEMS_PER_PAGE = 20,
 DEFAULT_DATE_FORMAT = "YYYY-MM-DD",
 DATE_MEDIUM_FORMAT = "DD MMM YY",
 DEFAULT_DATETIME_FORMAT = "YYYY-MM-DD HH:mm",
 DATE_FORMAT_WITHOUT_TIMEZONE = "YYYY-MM-DDTHH:mm:ss",
 DATE_FORMAT_WITH_TIMEZONE = "yyyy-MM-DDTHH:mm:ssZ",
 UFN_DATE = new Date(
  moment
    .utc(new Date("2050-01-01T00:00:00.000Z"))
    .format(DATE_FORMAT_WITH_TIMEZONE),
),

// Aircraft maximum dimensions
 NARROW_AIRCRAFT_MAX_LENGTH = 250,
 NARROW_AIRCRAFT_MAX_WIDTH = 100,
 NARROW_AIRCRAFT_MAX_HEIGHT = 100,
 NARROW_AIRCRAFT_MAX_WEIGHT = 150,
 NARROW_AIRCRAFT_MAX_WEIGHT_AOG = 300,
 WIDEBODY_AIRCRAFT_MAX_LENGTH = 315,
 WIDEBODY_AIRCRAFT_MAX_WIDTH = 244,
 WIDEBODY_AIRCRAFT_MAX_HEIGHT = 160,
 WIDEBODY_AIRCRAFT_MAX_WEIGHT = 4500,

 FORWARDER_HELP_CENTER_URL =
  "https://help.cargoai.co/en/collections/2592413-forwarders",
 AIRLINE_HELP_CENTER_URL =
  "https://help.cargoai.co/en/collections/2592414-airlines",
 FAQ_URL = "https://help.cargoai.co/en/collections/2592519-faq",
 CONTACT_US_URL = "https://www.cargoai.co/contact-us/",
 FORWARDER_BASICS_TOUR_URL =
  "https://app.cargoai.co/forwarder/search/forwarder-search?showProductTour=9128",
 FORWARDER_HOME_TOUR_URL =
  "https://app.cargoai.co/forwarder/search/forwarder-search?showProductTour=9146",
 FORWARDER_QUOTES_TOUR_URL =
  "https://app.cargoai.co/forwarder/quote/quote-status?showProductTour=9201",
 FORWARDER_SHIPMENTS_TOUR_URL =
  "https://app.cargoai.co/forwarder/book/booking-history?showProductTour=9129",
 FORWARDER_RATES_TOUR_URL =
  "https://app.cargoai.co/forwarder/compare-rates?showProductTour=9017",
 FORWARDER_ADVANCED_BOOKING_TOUR_URL =
  "https://app.cargoai.co/forwarder/search/forwarder-search?showProductTour=21503",
 FORWARDER_WALLET_TOP_UP_TOUR_URL =
  "https://app.wallet.cargoai.co/wallet-management/top-up?showProductTour=35167&officeId={officeId}",

 SESSION_STORAGE_RATE_COUNTRY = "rateCountry",
 SESSION_STORAGE_RATE_CATEGORY = "rateCategory",
 SESSION_STORAGE_RATE_IATACASS = "rateIatacass",

 REGEXP_PASSWORD =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\^$*.[\]{}()?\-“!@#%&/,><’:;|_~+`]{8,99}$/,

 EMAIL_REGEX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
 AIRLINE_CODE_REGEX = /^[a-zA-Z]{2,3}$/,
 AIRPORT_CODE_REGEX = /^[a-zA-Z]{3}$/,
 ALPHANUMERIC_REGEX = /^[a-zA-Z0-9]+$/,
 SHORTDATE_REGEX =
  /^(0?[1-9]|[12]\d|3[01])\/(0?[1-9]|1[0-2])\/\d{2}(?:\d{2})?$/,
 PIECES_REGEX = /^[1-9]\d*$/,
 MAX_FOUR_DECIMALS_REGEX = /^(0\.00009|[1-9]\d*(\.\d{1,4})?)$/,
 AWB_PREFIX_REGEX = /^[1-9]\d{2}$/,
 AWB_NUMBER_REGEX = /^[1-9]\d{7}$/,

 ALLOWED_FILE_EXTENSIONS = [
  ".txt",
  ".zip",
  ".eml",
  ".png",
  ".msg",
  ".docx",
  ".doc",
  ".pdf",
  ".csv",
  ".jpg",
  ".xls",
  ".xlsx",
],

 CHARGE_CODES = [
  "CC",
  "CZ",
  "CG",
  "PP",
  "PX",
  "PZ",
  "PG",
  "CP",
  "CX",
  "CM",
  "NC",
  "NT",
  "NZ",
  "NG",
  "NP",
  "NX",
  "CA",
  "CB",
  "CE",
  "CH",
  "PC",
  "PD",
  "PE",
  "PH ",
  "PF",
],

 VALUATION_OPTIONS = ["P", "C"],

 RATE_CLASS_CODE_OPTIONS = [
  "B",
  "R",
  "S",
  "P",
  "M",
  "N",
  "Q",
  "K",
  "C",
  "X",
  "E",
  "U",
  "Y",
],

 WEIGHT_UOM = ["K", "L"],

 VOLUME_UOM = ["CC", "CF", "CI", "MC"],
 HEIGHT_UOM = ["CMT", "INH"],

 MONTH_MMM = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
],

 DANGEROUS_GOODS_SHC = [
  "RPB",
  "RNG",
  "RCM",
  "RXD",
  "RFW",
  "RXC",
  "RFG",
  "RSC",
  "RCL",
  "RPG",
  "RMD",
  "ROX",
  "RFS",
  "REX",
  "RFL",
  "RXG",
  "RGX",
  "CDF",
  "PEL",
  "RXS",
  "POM",
],

 SUPPORTED_LANGUAGES = [
  "fr",
  "de",
  "es",
  "ja",
  "ko",
  "pt",
  "it",
  "ru",
  "tr",
  "vi",
  "zh-Hans",
  "ar",
  "pl",
  "th-TH",
],

 STABLE_LANGUAGE = [
  "fr",
  "en",
  "de",
  "es",
  "ja",
  "ko",
  "pt",
  "zh-Hans",
  "it",
  "pl",
],

 SUPPORTED_CREDIT_CARD_CURRENCIES = ["SGD", "USD", "EUR", "GBP"],
 NON_PROD_ENVIRONMENTS = ["local", "dev"];

export {
  FORWARDER_GROUP,
  AIRLINE_GROUP,
  ADMIN_GROUP,
  SUPERADMIN_USER_GROUP,
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_DATE_FORMAT,
  DATE_MEDIUM_FORMAT,
  DEFAULT_DATETIME_FORMAT,
  DATE_FORMAT_WITH_TIMEZONE,
  DATE_FORMAT_WITHOUT_TIMEZONE,
  UFN_DATE,
  MONTH_MMM,
  NARROW_AIRCRAFT_MAX_LENGTH,
  NARROW_AIRCRAFT_MAX_WIDTH,
  NARROW_AIRCRAFT_MAX_HEIGHT,
  NARROW_AIRCRAFT_MAX_WEIGHT,
  NARROW_AIRCRAFT_MAX_WEIGHT_AOG,
  NON_PROD_ENVIRONMENTS,
  WIDEBODY_AIRCRAFT_MAX_LENGTH,
  WIDEBODY_AIRCRAFT_MAX_WIDTH,
  WIDEBODY_AIRCRAFT_MAX_HEIGHT,
  WIDEBODY_AIRCRAFT_MAX_WEIGHT,
  FORWARDER_HELP_CENTER_URL,
  AIRLINE_HELP_CENTER_URL,
  FAQ_URL,
  CONTACT_US_URL,
  FORWARDER_BASICS_TOUR_URL,
  FORWARDER_HOME_TOUR_URL,
  FORWARDER_QUOTES_TOUR_URL,
  FORWARDER_SHIPMENTS_TOUR_URL,
  FORWARDER_RATES_TOUR_URL,
  FORWARDER_ADVANCED_BOOKING_TOUR_URL,
  FORWARDER_WALLET_TOP_UP_TOUR_URL,
  SESSION_STORAGE_RATE_CATEGORY,
  SESSION_STORAGE_RATE_COUNTRY,
  SESSION_STORAGE_RATE_IATACASS,
  REGEXP_PASSWORD,
  EMAIL_REGEX,
  AIRLINE_CODE_REGEX,
  AIRPORT_CODE_REGEX,
  ALPHANUMERIC_REGEX,
  SHORTDATE_REGEX,
  PIECES_REGEX,
  MAX_FOUR_DECIMALS_REGEX,
  AWB_PREFIX_REGEX,
  AWB_NUMBER_REGEX,
  ALLOWED_FILE_EXTENSIONS,
  CHARGE_CODES,
  VALUATION_OPTIONS,
  RATE_CLASS_CODE_OPTIONS,
  WEIGHT_UOM,
  VOLUME_UOM,
  HEIGHT_UOM,
  DANGEROUS_GOODS_SHC,
  SUPPORTED_LANGUAGES,
  STANDARD_GROUP,
  STABLE_LANGUAGE,
  SUPPORTED_CREDIT_CARD_CURRENCIES,
};
