<label
  class="advanced-select"
  [class.invalid]="showError"
  [class.focused]="isFocused"
  [attr.for]="undefined"
  caiTooltip
  [tooltip]="input.value"
  [detectOverflow]="true"
  [target]="input"
>
  <input
    #input
    [placeholder]="label"
    [(ngModel)]="display"
    [class.text-center]="textCenter"
    [class.text-uppercase]="uppercase"
    [class.float-label]="floatLabel"
    (focus)="open(dropdown, input)"
    (click)="open(dropdown, input)"
    readonly
  />
  <span class="label">{{ label }}</span>
  <ng-template #dropdown>
    <ul class="dropdown dropdown--multiselect">
      <div *ngIf="!!options.length" class="d-flex flex-column">
        <div
          class="dropdown--multiselect-search"
          (click)="$event.stopPropagation()"
        >
          <input
            [ngModel]="searchKey"
            (ngModelChange)="handleKeywordChange($event)"
            placeholder="Search..."
          />
          <span class="search-icon" (click)="$event.stopPropagation()">
            <span><em class="la la-search"></em></span>
          </span>
        </div>
        <ng-container
          *ngIf="
            (searchKey ? filteredOptions : options).length;
            else showDefaultMsg
          "
        >
          <cdk-virtual-scroll-viewport
            class="dropdown--inner"
            [style.height.px]="height"
            [style.min-width.px]="minWidth"
            [style.max-width.px]="maxWidth"
            itemSize="34"
          >
            <div
              *cdkVirtualFor="
                let option of searchKey ? this.filteredOptions : options;
                let i = index
              "
            >
              <div
                caiTooltip
                class="item"
                [tooltip]="option.label"
                placement="bottom-start"
                [class.selected]="value.includes(option.value)"
                (mousedown)="toggleSelection(option); $event.stopPropagation()"
              >
                <cai-checkbox
                  [isActive]="value.includes(option.value)"
                ></cai-checkbox>
                <span class="pl-2">{{ option.label }}</span>
              </div>
            </div>
          </cdk-virtual-scroll-viewport> </ng-container
        ><ng-template #showDefaultMsg>
          <div class="default-msg" (click)="$event.stopPropagation()">
            <span>{{ defaultMsg }}</span>
          </div>
        </ng-template>
      </div>
    </ul>
  </ng-template>
</label>
