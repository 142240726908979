export const CommoditySuggestions = [
  {
    "label": "Apple",
    "description": "you can go as detailed as ‘dried apple’",
  },
  {
    "label": "Fruits",
    "description": "any big category of the main group",
  },
  {
    "label": "Perishables",
    "description": "all main commodity types: Dangerous goods, Live Animals etc",
  },
  {
    "label": "PER",
    "description": "SHC, SCR, UN, ID",
  },
];
