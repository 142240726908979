export const updateNotification = `mutation setNotification(
  $user: String!, $timestamp: AWSTimestamp!, $read: Boolean, $seen: Boolean
) {
  setNotification(user: $user, timestamp: $timestamp, read: $read, seen: $seen) {
    title
    description
    type
    link
    user
    timestamp
    read
    seen
  }
}`;
