import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  LoadTypeEnum,
  MeasurementSystemEnum,
  WeightTypeEnum,
} from "@cai-services";
import { MeasurementUtil } from "../../../../core/_base/crud/utils/measurement.util";
import { BUP } from "../../../../core/_models/bup.model";
import {
  MeasurementUnit,
  MeasurementUnits,
} from "../../../../core/_models/measurement-system.model";
import { BUPConfig, ULDTypes } from "../../constants/bup.const";
import { BUPFieldsEnum } from "../../enum/bup.enum";
import { LoadTypeInputTypesEnum } from "../../enum/input-types.enum";
import { MeasurementTypeEnum } from "../../enum/measurement-type.enum";
import { CaiBUPUtil } from "../../utils/bup.util";
import { CaiLoadTypeMeasurementUtil } from "../../utils/measurement.util";

const DETAILS_ROW_HEIGHT = 46,
 ERROR_ROW_HEIGHT = 26;

@Component({
  "selector": "cai-load-type-bup",
  "templateUrl": "./load-type-bup.component.html",
  "styleUrls": ["./load-type-bup.component.scss"],
})
export class CaiLoadTypeBupComponent implements OnInit, OnChanges {
  @ViewChild("tbody") tbody: ElementRef;
  @Input() bups: BUP[] = [];
  @Input() measurementSystem: MeasurementSystemEnum =
    MeasurementSystemEnum.METRIC;
  @Input() origMeasurementSystem: MeasurementSystemEnum =
    MeasurementSystemEnum.METRIC;
  @Input() weightUnit: MeasurementUnit;
  @Input() volumeUnit: MeasurementUnit;
  @Input() enableUnitSwitch: boolean;
  @Input() minRowBeforeScroll: number;
  @Input() mode: LoadTypeEnum;
  @Input() chargeableUnitChangeCount: number;
  @Input() defaultMeasurementSystem: MeasurementSystemEnum;
  @Output() weightUnitChange = new EventEmitter<MeasurementUnit>();
  @Output() measurementSystemChange = new EventEmitter<MeasurementSystemEnum>();
  @Output() bupsChange = new EventEmitter<BUP[]>();
  @Output() onUpdateTotals = new EventEmitter();

  uldTypeOptions = [];
  measurementUnit = MeasurementUnits.METRIC;
  measurementSystemEnum = MeasurementSystemEnum;
  measurementTypeEnum = MeasurementTypeEnum;
  loadTypeInputTypesEnum = LoadTypeInputTypesEnum;
  BUPFieldsEnum = BUPFieldsEnum;
  currentIndex: number = null;
  placeholder = $localize`:@@load-type-bup.placeholder:Select`;

  constructor (private readonly cdr: ChangeDetectorRef) {}

  ngOnInit (): void {
    this.buildUldTypeOptions();
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.hasOwnProperty("bups")) {
      this.checkAddNewRow();
      this.checkErrors();
      this.checkUpdateTotals();
      this.markAsTouched();
    }
    if (changes.hasOwnProperty("measurementSystem")) {
      this.measurementUnit = MeasurementUnits[this.measurementSystem];
    }
    if (
      changes.hasOwnProperty("chargeableUnitChangeCount") &&
      changes.chargeableUnitChangeCount.currentValue &&
      this.mode === LoadTypeEnum.BUP
    ) {
      this.changeWeightUnit(this.weightUnit);
    }
    if (changes.hasOwnProperty("weightUnit")) {
      this.checkErrors();
    }
  }

  buildUldTypeOptions (): void {
    this.uldTypeOptions = ULDTypes.map((type) => ({
        "label": `${type.name} - ${type.description}`,
        "value": type.name,
      }));
  }

  changeULDType ($event, bup: BUP): void {
    const uldType = ULDTypes.find((type) => type.name === $event);
    if (uldType) {
      bup.uldType = uldType.name;
      bup.quantity = 1;
      bup.weight = MeasurementUtil.convertWeight(
        uldType.weight,
        MeasurementSystemEnum.METRIC,
        this.weightUnit.code === "kg"
          ? MeasurementSystemEnum.METRIC
          : MeasurementSystemEnum.IMPERIAL
      );
      bup.weightType = WeightTypeEnum.PER_ITEM;
      bup.volume = MeasurementUtil.convertVolume(
        uldType.volume,
        MeasurementSystemEnum.METRIC,
        this.origMeasurementSystem
      );
      bup.weightUnit = this.weightUnit?.display;
      bup.volumeUnit =
        MeasurementUnits[this.origMeasurementSystem].VOLUME.display;
      bup.isNewRow = false;
    }
    this.changeValue();
  }

  changeWeightUnit (unit: MeasurementUnit) {
    this.weightUnitChange.emit(unit);
    this.bups.forEach((bup) => {
      if (bup.weightUnit) {
        bup.weightUnit = unit.display;
      }
      if (bup.weight) {
        bup.weight = MeasurementUtil.convertWeight(
          bup.weight,
          MeasurementUtil.toggleMeasurementSystem(
            this.weightUnit.code === "kg"
              ? MeasurementSystemEnum.METRIC
              : MeasurementSystemEnum.IMPERIAL
          ),
          this.weightUnit.code === "kg"
            ? MeasurementSystemEnum.METRIC
            : MeasurementSystemEnum.IMPERIAL
        );
      }
    });
    this.updateBUPs();
    this.checkErrors();
  }

  matchMeasurementSystem (name: MeasurementSystemEnum): boolean {
    return this.measurementSystem === name;
  }

  matchMeasurementUnit (name: MeasurementSystemEnum): boolean {
    if (
      name === MeasurementSystemEnum.METRIC &&
      this.weightUnit?.code === "kg"
    ) {
      return true;
    }
    if (
      name === MeasurementSystemEnum.IMPERIAL &&
      this.weightUnit?.code === "lb"
    ) {
      return true;
    }
    return false;
  }

  getMeasurementUnits (type: MeasurementTypeEnum) {
    return CaiLoadTypeMeasurementUtil.getMeasurementUnits(type);
  }

  checkAddNewRow (): void {
    const newBUP = {
      "uldType": null,
      "quantity": null,
      "weight": null,
      "weightType": WeightTypeEnum.PER_ITEM,
      "weightUnit": null,
      "volume": null,
      "volumeUnit": null,
      "isNewRow": true,
      "isTouched": false,
      "isFocused": false,
      "errors": [],
    } as BUP;
    let shouldAdd = false;
    if (this.bups.length) {
      const invalidRows = this.bups.filter(
        (item) => !CaiBUPUtil.isPartiallyFilled(item)
      );
      if (!invalidRows.length) {
        shouldAdd = true;
      }
    } else {
      shouldAdd = true;
    }
    if (shouldAdd) {
      this.bups.push(newBUP);
      this.cdr.detectChanges();
      this.tbody.nativeElement.scrollTop =
        this.tbody.nativeElement.scrollHeight;
    }
  }

  validate (): void {
    this.currentIndex = null;
    this.bups.forEach((dimension) => {
      dimension.isTouched = true;
      dimension.isFocused = false;
    });
    this.checkErrors();
    this.cdr.detectChanges();
  }

  changeValue () {
    this.checkUpdateTotals();
    this.checkAddNewRow();
    this.updateBUPs();
    this.checkErrors();
  }

  updateBUPs (): void {
    this.bupsChange.emit(this.bups);
    this.checkUpdateTotals();
  }

  calculateVolume (bup: BUP): void {
    const volume = 0;

    bup.volume = volume;
  }

  showDelete (bup: BUP, index: number): boolean {
    return (
      CaiBUPUtil.isPartiallyFilled(bup) ||
      (!bup.isNewRow &&
        !bup.isFocused &&
        bup.isTouched &&
        !this.isInvalidRow(bup, index))
    );
  }

  touchRow (bup: BUP) {
    bup.isFocused = true;
    bup.isNewRow = false;
    bup.isTouched = true;
  }

  focusRow (bup: BUP, index: number) {
    this.currentIndex = index;
    this.bups
      .filter((d) => d.isFocused)
      .forEach((d) => {
        d.isFocused = false;
      });
    bup.isFocused = true;
    if (!bup.isNewRow) {
      bup.isTouched = true;
    }
    this.checkErrors();
    this.cdr.detectChanges();
  }

  unfocusRow (bup: BUP) {
    this.currentIndex = null;
    bup.isFocused = false;
    this.checkErrors();
    this.cdr.detectChanges();
  }

  deleteRow (rowIndex: number): void {
    if (this.bups.length === 1) {
      this.bups = [];
      this.checkAddNewRow();
    } else {
      this.bups.splice(rowIndex, 1);
      this.cdr.detectChanges();
    }
    this.currentIndex = null;
    this.updateBUPs();
    this.checkUpdateTotals();
    this.checkErrors();
    this.markAsTouched();
  }

  checkUpdateTotals (): void {
    this.onUpdateTotals.emit();
  }

  markAsTouched () {
    this.bups.forEach((bup) => {
      if (!bup.isNewRow) {
        bup.isTouched = true;
      }
    });
  }

  checkErrors (): void {
    this.bups.forEach((bup, index) => {
      bup.errors = [];
      for (const key of Object.keys(BUPFieldsEnum)) {
        const error = this.getFieldError(BUPFieldsEnum[key], bup),
         exists = bup.errors.find(
          (err) => err.field === BUPFieldsEnum[key]
        );
        if (
          error &&
          !exists &&
          (!bup.isNewRow ||
            (bup.isNewRow &&
              BUPFieldsEnum[key] === BUPFieldsEnum.ULD_TYPE &&
              index === 0))
        ) {
          bup.errors.push({
            "field": BUPFieldsEnum[key],
            "message": error,
          });
        }
      }
    });
  }

  getFieldError (field: BUPFieldsEnum, bup: BUP): string {
    let fieldError = null,
     maxValue,
     minValue;
    const config = BUPConfig.find((c) => c.field === field);
    if (config && config.validators) {
      const value = bup[field];
      if (field === "weight" && this.weightUnit?.code === "lb") {
        maxValue = MeasurementUtil.convertWeight(
          1000000,
          this.defaultMeasurementSystem,
          MeasurementSystemEnum.IMPERIAL
        );
        minValue = 0;
      } else if (field === "weight") {
        maxValue = 1000000;
        minValue = 0;
      }
      for (const key of Object.keys(config.validators)) {
        const error = config.validators[key](
          value,
          this.weightUnit?.code,
          maxValue,
          minValue
        );
        if (error && !fieldError) {
          fieldError = error;
        }
      }
    }
    return fieldError;
  }

  isInvalidField (field: BUPFieldsEnum, bup: BUP, index: number): boolean {
    return (
      !bup.isFocused &&
      bup.isTouched &&
      bup.errors &&
      bup.errors.find((error) => error.field === field) &&
      this.currentIndex !== index
    );
  }

  isInvalidRow (bup: BUP, index: number): boolean {
    return (
      !bup.isFocused &&
      bup.isTouched &&
      bup.errors &&
      !!bup.errors.length &&
      this.currentIndex !== index
    );
  }

  get maxHeight (): number {
    if (this.bups.length > this.minRowBeforeScroll) {
      return this.bups
        .slice(0, this.minRowBeforeScroll)
        .map((dimension, index) => (
            DETAILS_ROW_HEIGHT +
            (this.isInvalidRow(dimension, index) ? ERROR_ROW_HEIGHT : 0)
          ))
        .reduce((a, b) => a + b, 0);
    }
    return null;
  }
}
