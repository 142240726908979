import { DimensionFieldsEnum } from "../enum/dimensions.enum";

export const DimensionsConfig = [
  {
    "field": DimensionFieldsEnum.PIECES,
    "validators": {
      "required": (value: number) =>
        [null, undefined].includes(value) || value.toString().length < 1
          ? "Pieces is required"
          : null,
      "min": (value: number) => (+value <= 0 ? "Pieces must be above 0" : null),
      "max": (value: number) =>
        +value >= 1000 ? "Pieces must be below 1,000" : null,
    },
  },
  {
    "field": DimensionFieldsEnum.LENGTH,
    "validators": {
      "required": (value: number) =>
        [null, undefined].includes(value) || value.toString().length < 1
          ? "Length is required"
          : null,
      "min": (
        value: number,
        dimensionUnit?: string,
        maxValue?: number,
        minValue?: number
      ) =>
        +value <= minValue
          ? `Length must be above ${minValue} ${dimensionUnit}`
          : null,
      "max": (value: number, dimensionUnit?: string, maxValue?: number) =>
        +value >= maxValue
          ? `Length must be below ${maxValue.toLocaleString()} ${dimensionUnit}`
          : null,
    },
  },
  {
    "field": DimensionFieldsEnum.WIDTH,
    "validators": {
      "required": (value: number) =>
        [null, undefined].includes(value) || value.toString().length < 1
          ? "Width is required"
          : null,
      "min": (
        value: number,
        dimensionUnit?: string,
        maxValue?: number,
        minValue?: number
      ) =>
        +value <= minValue
          ? `Width must be above ${minValue} ${dimensionUnit}`
          : null,
      "max": (value: number, dimensionUnit?: string, maxValue?: number) =>
        +value >= maxValue
          ? `Width must be below ${maxValue.toLocaleString()} ${dimensionUnit}`
          : null,
    },
  },
  {
    "field": DimensionFieldsEnum.HEIGHT,
    "validators": {
      "required": (value: number) =>
        [null, undefined].includes(value) || value.toString().length < 1
          ? "Height is required"
          : null,
      "min": (
        value: number,
        dimensionUnit?: string,
        maxValue?: number,
        minValue?: number
      ) =>
        +value <= minValue
          ? `Height must be above ${minValue} ${dimensionUnit}`
          : null,
      "max": (value: number, dimensionUnit?: string, maxValue?: number) =>
        +value >= maxValue
          ? `Height must be below ${maxValue.toLocaleString()} ${dimensionUnit}`
          : null,
    },
  },
  {
    "field": DimensionFieldsEnum.WEIGHT,
    "validators": {
      "required": (value: number) =>
        [null, undefined].includes(value) || value.toString().length < 1
          ? "Weight is required"
          : null,
      "min": (
        value: number,
        weightUnit?: string,
        maxValue?: number,
        minValue?: number
      ) =>
        +value <= minValue
          ? `Weight must be above ${minValue} ${weightUnit}`
          : null,
      "max": (value: number, weightUnit?: string, maxValue?: number) =>
        +value >= maxValue
          ? `Weight must be below ${maxValue.toLocaleString()} ${weightUnit}`
          : null,
    },
  },
];
