<div class="wrapper">
  <div class="dropdown">
    <input
      #input
      class="select-trigger"
      [class.invalid]="invalid"
      [placeholder]="placeholder || ''"
      [(ngModel)]="text"
      (click)="open(dropdown, input)"
      (ngModelChange)="valueChange.emit($event)"
      (blur)="blur()"
      readonly
    />
    <span
      *ngIf="options?.length"
      (click)="open(dropdown, input); $event.stopPropagation()"
      class="caret"
    >
      <svg
        width="10"
        height="8"
        viewBox="0 0 10 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.19895 7C6.33317 7 9.95887 2.01102 9.30687 1.44502C8.65488 0.879015 1.80534 0.824923 1.09103 1.44502C0.376729 2.0659 4.06564 7 5.19895 7Z"
          [attr.fill]="'#4D578D'"
          [attr.stroke]="'#4D578D'"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
    <ng-template #dropdown>
      <div class="load-type-select">
        <cdk-virtual-scroll-viewport
          class="load-type-select-inner"
          [itemSize]="25"
          [style.height.px]="options.length * 25"
        >
          <div
            *cdkVirtualFor="let option of options"
            class="load-type-select-item"
            [class.active]="[option?.label, option?.value].includes(value)"
            (mousedown)="select(option)"
          >
            <span class="d-flex w-100" [title]="option.label">{{
              option.label
            }}</span>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </ng-template>
  </div>
</div>
