import {
  IAirlineBrand,
  IAirport,
  ICountry,
  IGeneralCondition,
} from "@cai-services";
import { DisclaimerRateTypeEnum, TermTypeEnum } from "@cai-services";
import { Country } from "./country.model";
import { GeneralConditionAttachment } from "./general-condition-attachment.model";

export class GeneralCondition implements IGeneralCondition {
  id: number;
  airlineBrand: IAirlineBrand;
  origin: IAirport;
  destination: IAirport;
  product: string;
  rateType: DisclaimerRateTypeEnum;
  termType: TermTypeEnum;
  terms: string;
  otherCharges: string;
  otherInfo: string;
  generalConditionAttachment: GeneralConditionAttachment[];
  minChargeableWeight?: number;
  maxChargeableWeight?: number;
  destinationCountry: Country;
  originCountry: Country;

  constructor (airlineBrand?: IAirlineBrand, originCountry?: ICountry) {
    this.airlineBrand = airlineBrand;
    this.originCountry = originCountry;
    this.termType = TermTypeEnum.AIRLINE_DISCLAIMER;
    this.terms = "";
  }
}
