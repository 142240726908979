import { getCurrencySymbol } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren, OnDestroy, OnChanges,
} from "@angular/core";
import { CaiPopoverDirective } from "@cai-framework";
import {
  LoadTypeEnum,
  MeasurementSystemEnum,
  PermissionNameEnum,
  UserService,
  UserTypeEnum,
  WalletStatusTypeEnum,
} from "@cai-services";
import moment from "moment";
import { Modifiers } from "popper.js";
import { datadogRum } from "@datadog/browser-rum";
import {
  AircraftTypes,
  MeasurementUtil,
  NARROW_AIRCRAFT_MAX_HEIGHT,
  NARROW_AIRCRAFT_MAX_LENGTH,
  NARROW_AIRCRAFT_MAX_WEIGHT,
  NARROW_AIRCRAFT_MAX_WIDTH,
  SearchItemTemplate,
  User,
  UserFeaturesEnum,
  WIDEBODY_AIRCRAFT_MAX_HEIGHT,
  WIDEBODY_AIRCRAFT_MAX_LENGTH,
  WIDEBODY_AIRCRAFT_MAX_WEIGHT,
  WIDEBODY_AIRCRAFT_MAX_WIDTH,
} from "../../core";
import { AircraftColorPipe } from "../../core/_base/layout/pipes/aircraft-color.pipe";
import { DayDiffer } from "../../core/_base/layout/pipes/day-diff.pipe";
import { TimeDiffer } from "../../core/_base/layout/pipes/time-diff.pipe";
import { TimeFromStartDifferPipe } from "../../core/_base/layout/pipes/time-start-diff.pipe";
import { RateNameEnum } from "../../core/_enums/rate-name.enum";
import { Aircraft } from "../../core/_models/aircraft.model";
import { AirlineBrand } from "../../core/_models/airline.model";
import {
  CustomFlightDetails,
  FlightInput,
} from "../../core/_models/flight-input.model";
import { FlightRate } from "../../core/_models/flight-rate.model";
import { MeasurementUnits } from "../../core/_models/measurement-system.model";
import { SearchTemplate } from "../../core/_models/search-template.model";
import { AppFileService } from "../../core/_services/app-file.service";
import { CargoGateService } from "../../core/_services/cargogate.service";
import { SessionService } from "../../core/_services/session.service";
import { environment } from "../../environments";
import {
  formatStringDateTime,
  getDuration,
  getStringDate,
} from "../../helpers/date-helpers";
import { CaiNumberFormatterPipe } from "../../pipes/number-formatter.pipe";
import { HomepageCommonUtil } from "../../utils";
import { SearchResultUtil } from "../../utils/search-result.util";

const DEFAULT_FLIGHTS_NUMBER = 3,
 DEFAULT_FLIGHTS_ADD = 5;
let DEFAULT_ROW_HEIGHT = 71;

@Component({
  "selector": "kt-smart-view-table-row",
  "templateUrl": "./smart-view-table-row.component.html",
  "styleUrls": ["./smart-view-table-row.component.scss"],
  "providers": [
    TimeDiffer,
    TimeFromStartDifferPipe,
    AircraftColorPipe,
    CaiNumberFormatterPipe,
  ],
})
export class SmartViewTableRowComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {
  @ViewChildren(CaiPopoverDirective) popups: QueryList<CaiPopoverDirective>;
  @ViewChild("main", { "read": ElementRef }) main: ElementRef;
  @Input() searchUUID: string;
  @Input() airlineCompany: AirlineBrand;
  @Input() weight: number;
  @Input() chargeableWeight: number;
  @Input() measurementUnit = MeasurementUnits.METRIC;
  @Input() allFlights: CustomFlightDetails[] = [];
  @Input() airlinesWithRateInsight: string[] = [];
  @Input() sliderFirstDep: Date;
  @Input() sliderLastArr: Date;
  @Input() selectedFlightDetails: FlightInput[] = [];
  @Input() isSelectAll: boolean;
  @Input() flightDays: number;
  @Input() isShipperQuote: boolean;
  @Input() isBatchLoading: boolean;
  @Input() searchTemplate: SearchTemplate;
  @Input() aircraftList: Aircraft[] = [];
  @Input() isEnableQuoteRequest: boolean;
  @Input() activeColumn: number;
  @Input() expandFlightState: any;
  @Input() popover: boolean;
  @Input() index: number;
  @Input() requestedFlight: CustomFlightDetails;
  @Input() isShipmentDetailsPage: boolean;
  @Input() isNewSearch: boolean;
  @Input() flightIndex: number;
  @Input() airlineCount: number;
  @Input() qualityScore: number;
  @Input() avgQualityScore: number;
  @Input() isExpressOnly: boolean;
  @Output() onSelectFlight = new EventEmitter<{
    checked: boolean;
    sortKey: string;
  }>();
  @Output() expandFlightStateUpdated = new EventEmitter();
  @Output() flightSelected = new EventEmitter<CustomFlightDetails>();
  @Output() onBookClicked = new EventEmitter<{
    payload: CustomFlightDetails;
  }>();
  @Output() onMoveFlightDaysView = new EventEmitter();
  @Output() onRequestBookClicked = new EventEmitter<{
    payload: CustomFlightDetails;
  }>();
  @Output() onRateChange = new EventEmitter<{
    sortKey: string;
    selectedRate: any;
  }>();
  @Output() onSelectAllChange = new EventEmitter();
  rateType = RateNameEnum;
  isHover: boolean;
  selectedFlight: CustomFlightDetails;
  panelOpenState = false;
  filteredFlights: CustomFlightDetails[];
  lastDay: Date;
  firstDay: Date;
  lastIndex = 3;
  colWidth: number = 77 / 5;
  currency: string;
  totalDays = [];

  popupModifiers: Modifiers = {
    "offset": {
      "enabled": true,
      "offset": "0, 10",
    },
  };
  expanded: boolean;
  autoAwbDisabledByCargoGate = false;
  canCreateBooking = true;
  bookDisabledReason = "";
  allowedCraftTypes: AircraftTypes[];
  currentUser: User;
  constructor (
    public fileService: AppFileService,
    private readonly sessionService: SessionService,
    private readonly cargoGateService: CargoGateService,
    private readonly userService: UserService,
    private readonly ref: ChangeDetectorRef,
    private readonly dayDifferPipe: DayDiffer,
  ) {
    this.autoAwbDisabledByCargoGate =
      this.cargoGateService.isFeatureDisabledByCargoGate("autoAwb");
  }

  ngOnDestroy () {
    this.ref.detach();
  }

  async ngOnInit () {
    this.currentUser = this.sessionService.getCurrentUser();
    let selectedOffice = this.sessionService.getSelectedOffice();
    if (!selectedOffice) {
      await this.sessionService.initSelectedOffice();
      selectedOffice = this.sessionService.getSelectedOffice();
    }
    this.canCreateBooking =
      this.userService.hasPermission(
        this.currentUser,
        selectedOffice?.id,
        PermissionNameEnum.CREATE_BOOKING,
      ) || this.isInterlineEnabled;
    if (!this.canCreateBooking) {
      this.bookDisabledReason = $localize`:@@global.booking-unauthorized:Booking permissions \nare not authorized \nfor your role.`;
    }

    if (environment.datadog) {
      datadogRum.addFeatureFlagEvaluation(
        UserFeaturesEnum.CARGO_COPILOT_RATE_INSIGHTS,
        this.isCargoCoPilotActivated,
      );
    }
  }

  ngAfterViewInit () {
    this.totalDays = [...Array(this.flightDays).keys()];
    if (this.sliderLastArr) {
      this.lastDay = new Date(this.sliderLastArr);
    }
    this.computeFlights();
    if (this.requestedFlight && this.flightIndex >= 0) {
      this.selectedFlight = {} as CustomFlightDetails;
      this.selectedFlight[this.flightIndex] = this.requestedFlight;
      this.ref.detectChanges();
    }
    this.updateFlightDisabledStatuses();
  }

  ngOnChanges (simpleChanges: SimpleChanges) {
    if (simpleChanges.sliderLastArr?.currentValue) {
      if (this.sliderLastArr) {
        this.lastDay = new Date(this.sliderLastArr);
      }
      if (this.sliderFirstDep) {
        this.firstDay = new Date(this.sliderFirstDep);
      }
    }
    if (simpleChanges.allFlights?.currentValue) {
      if (simpleChanges.allFlights.firstChange) {
        const defaultCurrency = this.currentUser?.defaultCurrency;
        this.currency = defaultCurrency;
      }
      this.computeFlights();
      this.expandFlightContainer();
    }
    if (simpleChanges.isSelectAll?.currentValue !== undefined) {
      this.updateFlightDisabledStatuses();
    }
  }

  get maxFlightReached (): boolean {
    return this.selectedFlightDetails?.length >= 3;
  }

  parseDate (date: string | Date): Date {
    if (date) {
      return new Date(getStringDate(date, "MMM DD, YYYY"));
    }
    return null;
  }

  formatDate (datetime: string, format: string) {
    return formatStringDateTime(datetime, format);
  }

  computeFlights (): void {
    this.allowedCraftTypes = SearchResultUtil.getAllowedAircraftTypes(
      this.searchTemplate,
    );
    this.allFlights.forEach((f, index) => {
      if (f.rates) {
        f.rates.forEach((rate) => {
          rate.formattedName = SearchResultUtil.getRateName(rate);
        });
        f.selectedRate = f.rates[0];
      }
      if (this.isSelectAll && index === 0) {
        f.checked = true;
      }
      if (this.isRequestBooking(f) && this.isTransborderSearch) {
        f.available = false;
        if (this.hasWallet && this.hasNoIataCass) {
          f.availableReason = $localize`:@@global.activate-wallet-booking-outside-origin:This flight is not yet bookable with CargoWallet`;
        } else if (!this.hasWallet) {
          f.availableReason = $localize`:@@global.activate-wallet-booking-outside-origin:Activate your CargoWALLET to make bookings outside your country of origin`;
        }
      }
      if (this.isRequestBooking(f) && this.hasNoIataCass) {
        f.available = false;
        f.availableReason = $localize`:@@global.activate-wallet-booking-outside-origin:This flight is not yet bookable with CargoWallet`;
      }
      if (
        this.isRequestBooking(f) &&
        !f.legs.every((leg) =>
          this.allowedCraftTypes.includes(
            leg.bodyType?.toLowerCase() as AircraftTypes,
          ),
        )
      ) {
        f.available = false;
        f.availableReason = $localize`:@@global.not-loadable:Shipment is not loadable`;
      }
    });
    if (this.isSelectAll) {
      this.onSelectAllChange.emit();
    }
    if (this.airlineCount > 1) {
      this.filteredFlights = this.allFlights.slice(
        0,
        this.expandFlightState?.[this.index] || DEFAULT_FLIGHTS_NUMBER,
      );
    } else {
      this.filteredFlights = this.allFlights;
      this.expanded = true;
    }
    this.ref.detectChanges();
  }

  private updateFlightDisabledStatuses () {
    if (this.isSelectAll) {
      this.allFlights.forEach((flight) => {
        if (!flight.checked && !this.isShipperQuote) {
          flight.disabled = this.isSelectAll;
        }
      });
    } else {
      const hasCheckedFlight = this.allFlights.some((flight) => flight.checked);
      this.allFlights.forEach((flight) => {
        if (!flight.checked && !this.isShipperQuote) {
          flight.disabled = hasCheckedFlight;
        }
      });
    }
  }

  getCurrency (flightDetail): string {
    return getCurrencySymbol(
      flightDetail?.selectedRate?.currency || this.currency,
      "wide",
    );
  }

  handleSelectFlight (flightDetail, index) {
    if (this.selectedFlight?.[index]) {
      this.selectedFlight = {} as CustomFlightDetails;
    } else {
      this.selectedFlight = {} as CustomFlightDetails;
      this.selectedFlight[index] = flightDetail;
    }
    this.flightSelected.emit(this.selectedFlight[index]);
  }

  trackByFn (index: number): number {
    return index;
  }

  expandFlightContainer (): void {
    if (this.expandFlightState?.[this?.index]) {
      this.filteredFlights = this.allFlights.slice(
        0,
        this.expandFlightState?.[this?.index],
      );
      this.lastIndex = this.expandFlightState?.[this?.index];
      this.expanded = true;
      this.ref.detectChanges();
    }
  }

  onShowMore (): void {
    this.filteredFlights = this.allFlights.slice(
      0,
      this.lastIndex + DEFAULT_FLIGHTS_ADD,
    );
    this.lastIndex = this.lastIndex + DEFAULT_FLIGHTS_ADD;

    this.expanded = true;

    this.expandFlightStateUpdated.emit({
      "key": this.index,
      "value": this.lastIndex,
    });
    this.ref.detectChanges();
  }

  onShowLess (): void {
    this.filteredFlights = this.allFlights.slice(0, DEFAULT_FLIGHTS_NUMBER);
    this.lastIndex = DEFAULT_FLIGHTS_NUMBER;

    this.expanded = false;

    this.expandFlightStateUpdated.emit({
      "key": this.index,
      "value": this.lastIndex,
    });
  }

  onFlightRowSelected (checked: boolean, sortKey: string) {
    this.onSelectFlight.emit({ checked, sortKey });
    this.allFlights.forEach((flight) => {
      if (flight.sortingFlightKey !== sortKey && !this.isShipperQuote) {
        flight.disabled = checked;
      }
    });
  }

  onSelectRate (rate: FlightRate, flight: CustomFlightDetails) {
    this.selectedFlight = null;
    this.onRateChange.emit({
      "sortKey": flight.sortingFlightKey,
      "selectedRate": rate,
    });
  }

  onBookClickedEvent (flightDetail: CustomFlightDetails): void {
    this.onBookClicked.emit({ "payload": flightDetail });
  }

  onRequestBookingClickedEvent (flightDetail: CustomFlightDetails): void {
    this.onRequestBookClicked.emit({ "payload": flightDetail });
  }

  getBeforeDeparture (flight: CustomFlightDetails): number[] {
    if (this.shouldShowFlight(flight)) {
      let nbDaysBeforeDepart = this.dayDifferPipe.transform(
        this.sliderFirstDep,
        flight.departureTime,
      );
      const isFlightPartition =
        this.parseDate(flight.departureTime) < this.parseDate(this.firstDay) &&
        this.parseDate(flight.arrivalTime) <= this.parseDate(this.lastDay);
      if (isFlightPartition && nbDaysBeforeDepart < 0) {
        nbDaysBeforeDepart = 0;
      }

      return this.totalDays.slice(0, nbDaysBeforeDepart);
    }
    return this.totalDays;
  }

  getMainPath (flight: CustomFlightDetails): number[] {
    if (this.shouldShowFlight(flight)) {
      let nbDaysBeforeDepart = this.dayDifferPipe.transform(
        this.sliderFirstDep,
        flight.departureTime,
      ),
       nbDaysMainPath = this.dayDifferPipe.transform(
        flight.departureTime,
        flight.arrivalTime,
        undefined,
        flight.elapsedTime,
        1,
      );
      const isFlightPartition =
        this.parseDate(flight.departureTime) < this.parseDate(this.firstDay) &&
        this.parseDate(flight.arrivalTime) <= this.parseDate(this.lastDay);
      if (isFlightPartition && nbDaysBeforeDepart < 0) {
        nbDaysBeforeDepart = 0;
        nbDaysMainPath =
          this.dayDifferPipe.transform(
            this.sliderFirstDep,
            flight.arrivalTime,
          ) + 1;
      }

      return this.totalDays.slice(
        nbDaysBeforeDepart,
        nbDaysBeforeDepart + nbDaysMainPath,
      );
    }
    return [];
  }

  getAfterArrivalPath (flight: CustomFlightDetails): number[] {
    if (this.shouldShowFlight(flight)) {
      const nbDaysAfterArrival = this.dayDifferPipe.transform(
        this.parseDate(flight.arrivalTime),
        this.parseDate(this.sliderLastArr),
      );
      return this.totalDays.slice(
        this.totalDays.length - nbDaysAfterArrival,
        this.totalDays.length,
      );
    }
    return [];
  }

  get isTransborderSearch (): boolean {
    return (
      this.searchTemplate?.office?.country?.countryCode !=
      this.searchTemplate?.originCountry
    );
  }

  get hasWallet (): boolean {
    return (
      this.searchTemplate?.office?.walletStatus == WalletStatusTypeEnum.ACTIVE
    );
  }

  get hasNoIataCass (): boolean {
    return (
      !this.searchTemplate?.office?.iata || !this.searchTemplate?.office?.cass
    );
  }

  get hasDirectFlight (): boolean {
    return this.allFlights.some((flight) => flight.legs.length === 1);
  }

  get hasAutoAwb (): boolean {
    return this.allFlights.some(
      (flight) => flight.features?.awbOptional === true,
    );
  }

  get isColoaderAvailable (): boolean {
    return this.allFlights.some((flight) =>
      flight.rates?.some((rate) => rate.coloaderOfficeId > 0),
    );
  }

  get isWalletPaymentWithAirline (): boolean {
    return this.allFlights.some((flight) =>
      ["EXCLUSIVE", "ONLY_WALLET"].includes(flight.features?.walletConfig),
    );
  }

  get paymentOptionDescription (): string {
    if (this.isColoaderAvailable) {
      return $localize`:@@smart-view-table-row.cargomart-payment-via-partner:CargoMart payment available for booking via partner`;
    }
    return $localize`:@@smart-view-table-row.cargomart-payment-direct-airline:CargoMart payment available for direct booking with the airline (no partner)`;
  }

  isInstantBooking (flightDetail: CustomFlightDetails): boolean {
    return (
      this.shouldShowRate(flightDetail) &&
      flightDetail.features?.instantBookable === true &&
      flightDetail.features?.bookable === true &&
      flightDetail.available === true
    );
  }

  isRequestBooking (flightDetail: CustomFlightDetails): boolean {
    return (
      this.shouldShowRate(flightDetail) &&
      !flightDetail.features?.instantBookable &&
      flightDetail.features?.bookable === true &&
      flightDetail.available === true
    );
  }

  shouldShowRate (flightDetail: CustomFlightDetails): boolean {
    return flightDetail.selectedRate != null;
  }

  shouldShowSpinner (flightDetail: CustomFlightDetails): boolean {
    if (flightDetail.selectedRate) {
      return false;
    }
    return flightDetail.loading && this.isBatchLoading;
  }

  shouldShowNotAvailableRate (flightDetail: CustomFlightDetails): boolean {
    return !this.isBatchLoading && flightDetail.available !== true;
  }

  getFormattedRateName (selectedRate): string {
    return SearchResultUtil.getFormattedRateName(selectedRate);
  }

  getRateRemarks(selectedRate: FlightRate): string | null {
    if (!selectedRate || typeof selectedRate !== 'object') {
      return null;
    }
    return selectedRate.airlineRateRemarks ? selectedRate.airlineRateRemarks : null;
  }

  getHoverIcon (flightDetail): string {
    const isAvailable = !flightDetail.loading && flightDetail.available,
     isInstantBooking =
      isAvailable &&
      flightDetail.features?.bookable &&
      flightDetail.features?.instantBookable;
    if (isInstantBooking && flightDetail.source?.includes("AI")) {
      return "/assets/media/icons/search-results/checkmark.svg";
    }
    return "/assets/media/icons/search-results/crossmark.svg";
  }

  getPopupDetails (flightDetail: CustomFlightDetails) {
    let selectedRate = flightDetail.selectedRate;
    if (!selectedRate && flightDetail.rates && flightDetail.rates.length) {
      selectedRate = flightDetail.rates[0];
    }
    const isAvailable = !flightDetail.loading && flightDetail.available,
     isInstantBooking =
      isAvailable &&
      flightDetail.features?.bookable &&
      flightDetail.features?.instantBookable,
     isRequestBooking =
      isAvailable &&
      flightDetail.features?.bookable &&
      !flightDetail.features?.instantBookable,
     reason = !isAvailable ? flightDetail.availableReason : null;
    return {
      "airlineLogo": this.fileService.getAirlineLogoPath(
        flightDetail.airlineCode,
      ),
      selectedRate,
      "currency":
        selectedRate && selectedRate.currency
          ? getCurrencySymbol(selectedRate.currency, "wide")
          : null,
      reason,
      "isNotAvailable": !isAvailable,
      "isInstantBooking": isInstantBooking && !!selectedRate,
      "isRequestBooking": isRequestBooking && !!selectedRate,
      "isEquote": !selectedRate,
      "isLoading": flightDetail.loading && this.isBatchLoading,
      "flightHrs": !selectedRate
        ? getDuration(
            flightDetail.departureTime,
            flightDetail.arrivalTime,
            true,
          )
        : null,
      "departureDate": moment(flightDetail.departureTime, "YYYYMMDD").toDate(),
      "value": { ...flightDetail },
    };
  }

  splitStringAndGetValueByIndex (total: number, index: number): string {
    const result = total?.toFixed(2)?.split(".");
    if (result && result.length && result[index] && +result[index] > 0) {
      return result[index];
    }
    return null;
  }

  private shouldShowFlight (flight) {
    const flightDateMin = moment(this.sliderFirstDep).startOf("day").toDate(),
     flightDateMax = moment(this.sliderLastArr).endOf("day").toDate(),
     departureTime = moment(
      flight.departureTime?.split("T")[0],
      "YYYY-MM-DD",
    ).toDate(),

     arrivalTime = moment(
      flight.arrivalTime?.split("T")[0],
      "YYYY-MM-DD",
    ).toDate();

    return (
      (flightDateMin <= departureTime && departureTime <= flightDateMax) ||
      (departureTime < flightDateMin &&
        arrivalTime <= flightDateMax &&
        arrivalTime > flightDateMin)
    );
  }

  getQuotableReason (flightDetail: CustomFlightDetails): string {
    if (!this.isQuotable(flightDetail)) {
      if (flightDetail?.rates?.length) {
        const rateName = flightDetail?.rates[0]?.formattedName;
        if (rateName) {
          if (flightDetail.airlineCode === "TK") {
            return $localize`:@@search-page.and-does-not-accept-spot-requests-under-500kg:This airline offers live rate and doesn't accept spot requests under 500kg`;
          }
          return `${$localize`:@@search-page.this-airline-offers:This airline offers`} ${rateName} ${$localize`:@@search-page.and-does-not-accept-spot-requests:and doesn’t accept Spot Requests`}`;
        }
      }
      if (
        !flightDetail.legs.every((leg) =>
          this.allowedCraftTypes.includes(
            leg.bodyType?.toLowerCase() as AircraftTypes,
          ),
        )
      ) {
        return $localize`:@@global.not-loadable:Shipment is not loadable`;
      }
      return $localize`:@@search-page.airline-does-not-accept-spot-requests:This airline doesn’t accept Spot Requests`;
    }
    return "";
  }

  isQuotable (flightDetail: CustomFlightDetails): boolean {
    return SearchResultUtil.isQuotable(flightDetail, this.allowedCraftTypes);
  }

  get rowHeight (): string {
    if (this.filteredFlights) {
      if (this.filteredFlights.length === 1) {
        DEFAULT_ROW_HEIGHT = 90;
      } else {
        DEFAULT_ROW_HEIGHT = 71;
      }
    }
    if (this.expanded) {
      return `${DEFAULT_ROW_HEIGHT * this.filteredFlights.length}px`;
    }
    return "auto";
  }

  isExpress (): boolean {
    return !!this.searchTemplate?.requirements?.find((req) =>
      req.answer?.specialHandlingCodes?.some((shc) => shc.shcCode === "XPS"),
    );
  }

  isBup (): boolean {
    return !!this.searchTemplate?.searchItemTemplates?.find(
      (item) => item.itemType === LoadTypeEnum.BUP,
    );
  }

  get isCargoCoPilotActivated (): boolean {
    const searchOfficeId = this.sessionService.getSearchOfficeId(),
     officeAccess = this.currentUser?.officeAccesses?.find(
      (oa) => oa.office.id == searchOfficeId,
    ),
     office = officeAccess ? officeAccess.office : null;
    return HomepageCommonUtil.isFeatureEnabled(
      office?.activatedFeatures,
      UserFeaturesEnum.CARGO_COPILOT_RATE_INSIGHTS,
    );
  }

  showCargoMate (): boolean {
    if (this.currentUser?.type === UserTypeEnum.AIRLINE) {
      return false;
    }

    if (this.currentUser?.company?.providers?.length > 0) {
      const providerFlights = this.allFlights.filter((f) => {
        let provider = f?.source;
        if (provider?.includes("AI-")) {
          provider = provider.replace("AI-", "");
        }
        if (
          !!environment?.alternateProvider &&
          !!environment?.alternateProvider[provider]
        ) {
          provider = environment?.alternateProvider[provider];
        }
        return !!this.currentUser?.company?.providers.find(
          (p) => p === provider,
        );
      });
      if (!providerFlights.length) {
        return false;
      }
    }

    if (
      !(
        (this.searchTemplate?.typeOfProduct?.productCode === "GCR" ||
          !this.isExpress()) &&
        !this.isBup() &&
        (this.isCargoCoPilotActivated ||
          !this.currentUser?.userEmail?.toLowerCase().endsWith("cargoai.co") ||
          ["customersuccess@cargoai.co", "matt+forwarder@cargoai.co"].includes(
            this.currentUser?.userEmail?.toLowerCase(),
          ))
      )
    ) {
      return false;
    }
    return !!this.airlinesWithRateInsight.find(
      (ac) => ac == this.airlineCompany.airlineCompanyCode,
    );
  }

  get isUserAirline () {
    return this.currentUser?.type === UserTypeEnum.AIRLINE;
  }

  get isInterlineEnabled (): boolean {
    return !!this.currentUser?.activatedFeatures?.find(
      (af) => af.featureName === "INTERLINE",
    );
  }
}
