import { Injectable } from "@angular/core";
import { environment } from "../../environments";
import { SessionService } from "./session.service";

@Injectable({
  "providedIn": "root",
})
export class UsetifulService {
  constructor (private sessionService: SessionService) {}

  initUsetiful () {
    const isUsetifulInitialized =
      document.getElementById("usetifulScript") !== null;
    if (!environment.usetiful || isUsetifulInitialized) {
      return;
    }

    let simpleEnv = "prod";
    if (environment.name != "prod") {
      simpleEnv = "dev";
    }

    const user = this.sessionService.getCurrentUser();
    if (user?.userId) {
      const scriptTag = document.createElement("script");
      scriptTag.id = "usetifulTags";
      scriptTag.defer = true;
      scriptTag.innerHTML = `
      window.usetifulTags = { 
        segment: "${simpleEnv}",
        userId: "${user.userId}",
      };
    `;
      document.body.appendChild(scriptTag);
    }

    const script = document.createElement("script");
    script.defer = true;
    script.innerHTML = `(function (w, d, s) {
      var a = d.getElementsByTagName('head')[0];
      var r = d.createElement('script');
      r.async = 1;
      r.src = s;
      r.setAttribute('id', 'usetifulScript');
      r.dataset.token = "fa1ecaba259f7ed50eb36a071f22aa2d";
      a.appendChild(r);
      })(window, document, "https://www.usetiful.com/dist/usetiful.js");`;
    document.body.appendChild(script);
  }

  removeUsetiful () {
    const usetifulScript = document.getElementById("usetifulScript"),
     usetifulTags = document.getElementById("usetifulTags");
    if (usetifulScript !== null) {
      usetifulScript.remove();
    }
    if (usetifulTags !== null) {
      usetifulTags.remove();
    }
  }
}
