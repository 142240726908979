import moment from "moment";

const DATE_FORMAT = "yyyy-MM-DD";

export class HomepageCommonUtil {
  static getTimeDifference (date: any, displayPlural = true): string {
    const diff = moment.duration(moment(new Date()).diff(moment(date))),
     seconds = diff.asSeconds(),

     getDiff = (value: number, unit: string) => {
      const _value = Math.floor(value);
      if (_value > 0) {
        let result = _value + ` ${unit}`;
        if (_value > 1 && displayPlural) {
          result += "s";
        }
        return result;
      }
      return null;
    },

     yearDiff = getDiff(seconds / (3600 * 24 * 30 * 12), "year");
    if (yearDiff) {
      return yearDiff;
    }

    const monthDiff = getDiff(seconds / (3600 * 24 * 30), "month");
    if (monthDiff) {
      return monthDiff;
    }

    const weeksDiff = getDiff(seconds / (3600 * 24 * 7), "week");
    if (weeksDiff) {
      return weeksDiff;
    }

    const dayDiff = getDiff(seconds / (3600 * 24), "day");
    if (dayDiff) {
      return dayDiff;
    }

    const hourDiff = getDiff(seconds / 3600, "hour");
    if (hourDiff) {
      return hourDiff;
    }

    const minDiff = getDiff((seconds % 3600) / 60, "minute");
    if (minDiff) {
      return minDiff;
    }

    const secDiff = getDiff((seconds % 3600) % 60, "second");
    if (secDiff) {
      return secDiff;
    }

    return "";
  }

  static getTimeGroup (searchDate): string {
    const today = moment(new Date()).startOf("day").format(DATE_FORMAT),
     yesterday = moment(new Date())
      .add(-1, "day")
      .startOf("day")
      .format(DATE_FORMAT),
     formatted = moment(new Date(searchDate))
      .startOf("day")
      .format(DATE_FORMAT),
     timeDiff = this.getTimeDifference(searchDate, true),
     agoLabel = $localize`:@@global.ago:ago`;

    if (today === formatted) {
      return $localize`:@@global.totday:Today`;
    }
    if (yesterday === formatted) {
      return $localize`:@@global.yesterday:Yesterday`;
    }
    return `${timeDiff} ${agoLabel}`;
  }

  static isFeatureEnabled (activatedFeatures, key: string): boolean {
    if (!activatedFeatures?.length) {
      return false;
    }
    return activatedFeatures?.some((activatedFeature) => (
        activatedFeature?.featureName?.toLowerCase() === key?.toLowerCase()
      ));
  }
}
