import {
  ChangeDetectorRef,
  Component,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  TemplateRef,
  ViewContainerRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import Popper from 'popper.js';
import { fromEvent, takeWhile, filter, takeUntil } from 'rxjs';
import moment from 'moment';

const NO_RESULT_FOUND = 'No result found';
@Component({
  selector: 'kt-advanced-daterange-picker',
  templateUrl: './advanced-daterange-picker.component.html',
  styleUrl: './advanced-daterange-picker.component.scss',
})
export class AdvancedDaterangePickerComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() options: any[] = [];
  @Input() class: string;
  @Input() placeholder: string;
  @Input() selectAllText: string;
  @Input() allowNoneSelected = false;
  @Input() hideValue: boolean;
  @Input() enableSearch = true;
  @Input() isFilter: boolean;
  @Input() defaultMsg = NO_RESULT_FOUND;
  @Output() selectedDateChange = new EventEmitter<{
    fromDate: any;
    endDate: any;
  }>();
  @Input() selectedOptions: any[] = [];
  @Output() selectedOptionsChange = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Input() optionKey = 'value';
  @Input() description = 'description';
  @Input() returnStrings?: boolean;
  private view: EmbeddedViewRef<any>;
  private popperRef: Popper;
  searchKey = '';
  isCustom: boolean;
  minimumDate: any = '2022-01-01';
  maximumDate: string = moment().format('YYYY-MM-DD');
  startDate: any = '2022-01-01';
  endDate: string = moment().format('YYYY-MM-DD');
  selectedOption: string;
  constructor(
    private readonly vcr: ViewContainerRef,
    private readonly zone: NgZone,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.selectedOption = this.options[0]?.value;
  }
  ngOnDestroy(): void {
    this.close();
  }

  isOpen(): boolean {
    return !!this.popperRef;
  }

  open(dropdownTpl: TemplateRef<any>, input: HTMLElement) {
    this.onFocus.emit();
    if (this.isOpen()) {
      this.close();
    } else if (this.options.length || this.defaultMsg) {
      this.view = this.vcr.createEmbeddedView(dropdownTpl);
      const dropdown = this.view.rootNodes[0];

      document.body.appendChild(dropdown);
      dropdown.style['z-index'] = '1';
      dropdown.style['min-width'] = `${input.offsetWidth}px`;

      if (!this.options.length && this.defaultMsg) {
        dropdown.style.width = `${input.offsetWidth}px`;
      }

      this.zone.runOutsideAngular(() => {
        this.popperRef = new Popper(input, dropdown, {
          placement: 'bottom-start',
          removeOnDestroy: true,
          modifiers: {
            preventOverflow: { escapeWithReference: true },
          },
        });
      });
      this.handleClickOutside();
    }
  }

  private handleClickOutside() {
    fromEvent(document, 'click')
      .pipe(
        takeWhile(() => this.isOpen()),
        filter(({ target }) => {
          const origin = this.popperRef.reference as HTMLElement,
            dropdown = this.view.rootNodes[0] as HTMLElement,
            isOriginClicked = origin.contains(target as HTMLElement),
            isDropdownClicked = dropdown.contains(target as HTMLElement),
            isDateRangePickerElement = this.isDateRangePickerElement(
              target as HTMLElement,
            );

          return (
            !isOriginClicked && !isDropdownClicked && !isDateRangePickerElement
          );
        }),
        takeUntil(this.onClose),
      )
      .subscribe(() => {
        this.close();
        this.cdr.detectChanges();
      });
  }
  private isDateRangePickerElement(target: HTMLElement): boolean {
    return (
      !!target.closest('.mat-datepicker-content') ||
      !!target.closest('.mat-calendar') ||
      !!target.closest('.mat-calendar-body') ||
      !!target.closest('.mat-calendar-table') ||
      !!target.closest('.mat-calendar-controls')
    );
  }
  close() {
    if (this.popperRef) {
      this.popperRef?.destroy();
      this.popperRef = null;
      this.view.destroy();
    }
    this.onClose.emit();
  }

  setRadioDateRange(value: string) {
    const today = moment();
    let startDate: moment.Moment, endDate: moment.Moment;

    switch (value) {
      case 'ALL':
        startDate = moment('2022-01-01');
        endDate = today;
        break;
      case 'THIS_WEEK':
        startDate = moment().startOf('week');
        endDate = today;
        break;
      case 'THIS_MONTH':
        startDate = moment().startOf('month');
        endDate = today;
        break;
      case 'THIS_YEAR':
        startDate = moment().startOf('year');
        endDate = today;
        break;
      default:
        return;
    }
    this.isCustom = false;
    this.startDate = startDate.format('YYYY-MM-DD');
    this.endDate = endDate.format('YYYY-MM-DD');
    const selectedOption = this.options.find(
      (option) => option.value === value,
    );
    this.selectedOption = selectedOption.value;
    this.selectedOptions = [selectedOption];
    this.selectedOptionsChange.emit(this.selectedOptions);
    this.selectedDateChange.emit({
      fromDate: this.startDate,
      endDate: this.endDate,
    });
    this.close();
  }
  applyDateRange() {
    const startDateISOString = moment(this.startDate).format('YYYY-MM-DD');

    if (!this.endDate) {
      this.endDate = moment().format('YYYY-MM-DD');
    }

    const endDateISOString = moment(this.endDate).format('YYYY-MM-DD');

    this.selectedDateChange.emit({
      fromDate: startDateISOString,
      endDate: endDateISOString,
    });
    this.selectedOption = '';
    this.isCustom = true;
    this.close();
    this.cdr.detectChanges();
  }
  get invalid(): boolean {
    if (this.allowNoneSelected) {
      return false;
    }
    return this.options?.length && !this.selectedOptions?.length;
  }
  get inputText(): string {
    if (this.options?.length) {
      if (this.isCustom) {
        return 'Custom';
      } else {
        if (
          this.selectedOptions.length > 1 &&
          this.selectedOptions.length === this.options.length
        ) {
          return this.selectAllText;
        } else {
          const matchedOptions = this.options.filter((opt) =>
            this.isSelected(opt),
          );
          if (matchedOptions.length) {
            return this.description === 'city'
              ? matchedOptions[0]?.[this.description]?.cityName
              : matchedOptions[0]?.[this.description];
          }
        }
      }
    }
    return this.selectAllText;
  }

  getSelectedRadioLabel(): string {
    const selectedRadio = this.options.find(
      (option) => option[this.optionKey] === this.inputText,
    );

    return selectedRadio ? selectedRadio[this.description] : this.placeholder;
  }

  isSelected(option): boolean {
    return !!this.selectedOptions.find(
      (selected) =>
        (selected?.[this.optionKey] || selected).toString() ===
        option[this.optionKey].toString(),
    );
  }
}
