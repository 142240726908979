import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { OfficeService } from "@cai-services";
import { CoLoaderOffice } from "../_models";

@Injectable({
  "providedIn": "root",
})
export class AppOfficeService {
  constructor (
    private httpClient: HttpClient,
    private readonly officeService: OfficeService
  ) {}

  uploadCompanyLogo (uploadUrl: string, imageBase: File): Observable<any> {
    return this.httpClient.put<any>(uploadUrl, imageBase, {
      "headers": {
        "content-type": "image/*",
      },
    });
  }

  async getColoaderOfficeDetails (
    coLoaderOfficeId: number
  ): Promise<CoLoaderOffice> {
    return this.officeService.getCoLoaderOffice(coLoaderOfficeId);
  }

  public getWalletsPaginated (
    page: number,
    size: number,
    sort: string,
    walletStatuses?: string,
    countries?: string,
    search?: string,
  ): Observable<any> {
    return from(
      this.officeService.getWalletsPaginated(
        page,
        size,
        sort,
        countries,
        walletStatuses,
        search
      ),
    );
  }

  public getWalletsMetadata (): Observable<any> {
    return from(
      this.officeService.getWalletsMetadata(),
    );
  }
}
