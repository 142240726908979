import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AutofillDimensionsService, ShipmentInputs } from "@cai-services";
import { Subject } from "rxjs";
import { SessionService } from "../../../../core/_services/session.service";

@Component({
  "selector": "cai-load-type-autofill-text-modal",
  "templateUrl": "./load-type-autofill-text-modal.component.html",
  "styleUrls": ["./load-type-autofill-text-modal.component.scss"],
})
export class CaiLoadTypeAutofillText {
  constructor (
    private readonly activeModal: NgbActiveModal,
    private readonly sessionService: SessionService,
    private readonly autofillDimensionsService: AutofillDimensionsService
  ) {}
  inputText: string;
  isSuccess = false;
  private updateDimensions = new Subject<ShipmentInputs>();

  async onTextAutofillClick (): Promise<void> {
    const text = this.inputText.replace("–", "-");
    localStorage.setItem("autofillText", text);
    this.sessionService.setItemSessionStorage("browserRefreshed", false);
    this.updateDimensions.next(
      await this.autofillDimensionsService.extractShipmentInputs(
        text,
        "SIMPLE_TEXT"
      )
    );
    this.closeModal();
  }

  public closeModal (): void {
    this.activeModal.dismiss();
  }
}
