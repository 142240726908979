export class UserMeasurementSystem {
  WEIGHT: MeasurementUnit;
  VOLUME: MeasurementUnit;
  MEASURE: MeasurementUnit;
}

export class MeasurementUnit {
  code: string;
  name: string;
  display: string;
}

export const ConversionRate = {
  "CF_TO_CM": 35.315,
  "CM_TO_IN": 0.393701,
  "IN3_TO_FT3": 1728,
  "CM3_TO_M3": 1000000,
  "KG_TO_LB": 2.20462,
  "VOLUME_TO_WEIGHT": {
    "METRIC": 0.006,
    "IMPERIAL": 0.2117757972,
  },
};

export const MeasurementUnits = {
  "METRIC": {
    "WEIGHT": {
      "code": "kg",
      "name": "kilo",
      "display": "kg",
    },
    "VOLUME": {
      "code": "m3",
      "name": "cubic meter",
      "display": "m\u00B3",
    },
    "MEASURE": {
      "code": "cm",
      "name": "centimeter",
      "display": "cm",
    },
  } as UserMeasurementSystem,
  "IMPERIAL": {
    "WEIGHT": {
      "code": "kg",
      "name": "kilo",
      "display": "kg",
    },
    "VOLUME": {
      "code": "ft3",
      "name": "cubic feet",
      "display": "ft\u00B3",
    },
    "MEASURE": {
      "code": "in",
      "name": "inch",
      "display": "in",
    },
  } as UserMeasurementSystem,
};
