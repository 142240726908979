import { Component, Inject, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IOffice, IPermissionSet, OfficeService } from "@cai-services";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { datadogRum } from "@datadog/browser-rum";
import { ApplicationProperties, UserFeaturesEnum } from "../../../../core";
import { APP_PROPS } from "../../../../cai-common.module";
import { minItems } from "../../../../validators";
import { SessionService } from "../../../../core";
import { HomepageCommonUtil } from "../../../../utils";
import { environment } from "../../../../environments";

@Component({
  "selector": "cai-office-add-user-modal",
  "templateUrl": "./office-add-user-modal.component.html",
  "styleUrls": ["./office-add-user-modal.component.scss"],
})
export class OfficeAddUserModal implements OnInit {
  @Input()
  activeOffice: IOffice;

  @Input()
  modalRef: NgbModalRef;
  @Input()
  rolesOfCompany: IPermissionSet[];
  @Input()
  relevantRoles: string[];
  hiddenRoles: string[] = [];

  addUserToOfficeForm: FormGroup;
  displayProTag = false;

  features = environment.features;

  constructor (
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private fb: FormBuilder,
    private officeService: OfficeService,
    private readonly sessionService: SessionService,
  ) {
    /**
     * this is important because the 'roles' form field may not contain
     * irrelevant roles that should be added by default (e.g. OPERATIONS is
     * a default role, but in Wallet app we hide it)
     */
    this.hiddenRoles = this.isWalletApp ? ["OPERATION"] : ["PAYER"];
  }

  ngOnInit () {
    this.addDatadogFlagEvaluations();
    this.initializeAddUserForm();
  }

  addDatadogFlagEvaluations () {
    if (environment.datadog) {
      datadogRum.addFeatureFlagEvaluation(
        UserFeaturesEnum.ROLE_PERMISSION_MANAGEMENT,
        this.isRolePermissionManagementEnabled,
      );

      datadogRum.addFeatureFlagEvaluation(
        UserFeaturesEnum.CARGOMART_PRO,
        this.isCargoMartProEnabled,
      );
    }
  }

  initializeAddUserForm () {
    this.addUserToOfficeForm = this.fb.group({
      "email": ["", [Validators.required, Validators.email]],
      "roles": [[], minItems(1)],
    });

    if (this.isCargoMartProEnabled && !this.isRolePermissionManagementEnabled) {
      this.relevantRoles = this.relevantRoles.filter(
        (role) => role == "STANDARD",
      );
      this.addUserToOfficeForm.patchValue({ "roles": ["STANDARD"] });
      this.addUserToOfficeForm.get("roles").disable();
      this.displayProTag = true;
    }
  }

  isControlHasError (controlName: string, validationType: string): boolean {
    const control = this.addUserToOfficeForm.controls[controlName];
    if (!control) {
      return false;
    }

    return control.hasError(validationType);
  }

  async onSubmitForm (): Promise<void> {
    if (!this.addUserToOfficeForm.valid) {
      return;
    }

    try {
      const email = this.addUserToOfficeForm.controls.email.value,
       roles = [
        ...this.addUserToOfficeForm.controls.roles.value,
        ...this.hiddenRoles,
      ],
       { isUserAdded } = await this.officeService.addOfficeMember(
        this.activeOffice.id,
        email,
        roles,
      );
      Swal.fire({
        "text": isUserAdded
          ? $localize`:@@office-management.success.added-office-member:Success! The user has been added to the office.`
          : $localize`:@@office-management.success.invited-office-member:An invite has been sent to ` +
            email,
        "icon": "success",
        "showCancelButton": false,
        "allowOutsideClick": false,
      });
      this.modalRef.close(true);
    } catch (e) {
      if (e.response === undefined || e.response.data === undefined) {
        return;
      }
      const errorResponse = e.response.data;

      Object.keys(errorResponse.fieldErrors).forEach((key) => {
        this.addUserToOfficeForm.get(key).setErrors({
          [errorResponse.fieldErrors[key][0].code]: true,
        });
      });
    }
  }

  get isWalletApp (): boolean {
    return this.appProperties.name === "cargowallet";
  }

  get isRolePermissionManagementEnabled (): boolean {
    const officeId = this.sessionService.getSelectedOfficeId(),
     officeAccess = this.sessionService.getCurrentUser()?.officeAccesses?.find((oa) => oa.office.id == officeId),
     office = officeAccess ? officeAccess.office : null;
    return HomepageCommonUtil.isFeatureEnabled(
      office?.activatedFeatures,
      UserFeaturesEnum.ROLE_PERMISSION_MANAGEMENT,
    );
  }

  get isCargoMartProEnabled (): boolean {
    return (
      HomepageCommonUtil.isFeatureEnabled(
        this.sessionService.getCurrentUser()?.activatedFeatures,
        UserFeaturesEnum.CARGOMART_PRO,
      ) || this.features?.cargomartPro
    );
  }
}
