<section class="mt-4 px-3">
  <div class="pl-2">
    <kt-back-button [previousUrl]="prevURL" *ngIf="false"></kt-back-button>
    <h3 class="mb-0 ml-2 align-title" i18n="@@wallet-topup-title">
      CargoWALLET Top Up
    </h3>
  </div>
  <div
    class="container-fluid pt-2"
    *ngIf="!isProcessCompleted && !isPaymentPage"
  >
    <kt-wallet-topup-currency
      [walletDetails]="walletDetails"
      [walletTopUp]="topupData"
      [currencies]="currencyList"
      (currencyChanged)="onChangeCurrency()"
    ></kt-wallet-topup-currency>
    <kt-wallet-topup-payment-method
      [walletTopUp]="topupData"
      [walletDetails]="walletDetails"
      [bankDetails]="bankDetails"
      [isCreditCardEnabled]="isCreditCardEnabled"
      [isLocalEnabled]="isLocalEnabled"
      [isInternationEnabled]="isInternationEnabled"
      [isOtherEnabled]="isOtherEnabled"
      (paymentTypeChanged)="onChangePaymentType($event)"
    >
    </kt-wallet-topup-payment-method>
    <kt-wallet-topup-amount
      [topupData]="topupData"
      [minAmount]="minAmount"
      [maxAmount]="maxAmount"
      [paymentFees]="paymentFees"
      [isLocalEnabled]="isLocalEnabled"
      [isInternationEnabled]="isInternationEnabled"
      [walletDetails]="walletDetails"
    >
    </kt-wallet-topup-amount>
    <div class="mb-3"></div>
    <div class="mb-3"></div>
    <button
      class="btn wallet-btn ml-5 my-3"
      (click)="topUpBalance()"
      [disabled]="isTopupDisabled"
      [ngClass]="{ disabled: isTopupDisabled || isInvalidAmount }"
      type="button"
      i18n="@@wallet-topup"
    >
      TOP UP MY WALLET
    </button>
  </div>
  <div class="container-fluid mt-5 pt-5" *ngIf="isProcessCompleted">
    <kt-topup-thankyou
      [walletName]="topupData.currency"
      [transactionId]="transactionId"
      [referenceId]="referenceId"
      [status]="paymentStatus"
      [shouldDownload]="
        topupData.paymentMethod !== 'CREDIT_CARD' &&
        topupData.paymentMethod !== 'OTHER'
      "
    ></kt-topup-thankyou>
  </div>
</section>
<div *ngIf="walletLoader" class="kt-splash-screen">
  <div class="section--white">
    <div class="loader--roller">
      <div class="blue-theme-loader-point"></div>
      <div class="blue-theme-loader-point"></div>
      <div class="blue-theme-loader-point"></div>
      <div class="blue-theme-loader-point"></div>
      <div class="blue-theme-loader-point"></div>
      <div class="blue-theme-loader-point"></div>
      <div class="blue-theme-loader-point"></div>
    </div>
  </div>
</div>
