class Config {
  static readonly Config = "/config";
}

export const API_SOLUTIONS = {
  "name": "api-solutions",
  "URLs": {
    Config,
  },
};
