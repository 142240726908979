// Angular
import { Injectable } from "@angular/core";
// RxJS
import _ from "lodash";
import { Subject } from "rxjs";
import { UserTypeEnum } from "@cai-services";
import { SessionService } from "../../../_services/session.service";
import { UserFeaturesEnum } from "../../../_enums";
import { HomepageCommonUtil } from "../../../../utils/homepage.util";
import { User } from "../../../_models";

@Injectable()
export class MenuConfigService {
  // Public properties
  onConfigUpdated$: Subject<any>;
  // Private properties
  private menuConfig: any;
  currentUser: User;

  /**
   * Service Constructor
   */
  constructor (private readonly sessionService: SessionService) {
    // register on config changed event and set default config
    this.onConfigUpdated$ = new Subject();
    this.sessionService.watchSessionStorage().subscribe((res) => {
      if (res.key === "currentUser") {
        this.currentUser = res.data;
      }
    });
  }

  /**
   * Returns the menuConfig
   */
  getMenus () {
    const clonedMenuConfig = _.cloneDeep(this.menuConfig);
    if (!this.isAllotmentActivatedForLoggedInUser && !!clonedMenuConfig) {
      clonedMenuConfig.header.items = clonedMenuConfig?.header?.items?.filter(
        (menu) => menu?.title?.toUpperCase() !== "ALLOTMENT",
      );
    }

    const user = this.sessionService.getCurrentUser();
    if (
      user?.type == UserTypeEnum.AIRLINE &&
      !!clonedMenuConfig
    ) {
      if (!user?.activatedFeatures?.find((af)=>af.featureName==="INTERLINE")) {
        clonedMenuConfig.header.items = clonedMenuConfig?.header?.items?.filter(
          (menu) =>
            menu?.title?.toUpperCase() !==
            $localize`:@@global.search:Search`.toUpperCase(),
        );
      }
    }

    return clonedMenuConfig;
  }

  /**
   * Load config
   *
   * @param config: any
   */
  loadConfigs (config: any) {
    this.menuConfig = config;
    this.onConfigUpdated$.next(this.menuConfig);
  }

  private get isAllotmentActivatedForLoggedInUser (): boolean {
    const officeId = this.sessionService.getSelectedOfficeId(),

     updatedUser =
      this.currentUser || this.sessionService.getCurrentUser(),
     officeAccess = updatedUser?.officeAccesses?.find(
      (oa) => oa.office.id == officeId,
    ),
     office = officeAccess ? officeAccess.office : null;
    return HomepageCommonUtil.isFeatureEnabled(
      office?.activatedFeatures,
      UserFeaturesEnum.ALLOTMENT,
    );
  }
}
