import { Component, Input, OnInit } from "@angular/core";
import { DayDiffer } from "../../core/_base/layout/pipes/day-diff.pipe";
import { TimeDiffer } from "../../core/_base/layout/pipes/time-diff.pipe";
import { TimeFromStartDifferPipe } from "../../core/_base/layout/pipes/time-start-diff.pipe";
import { AppFileService } from "../../core/_services/app-file.service";
import { GlobalService } from "../../core/_services/global.service";
import { getDifferenceInDays, getDuration } from "../../helpers/date-helpers";
import { mapFlightSearchToTrip } from "../../helpers/search_helpers";
import { CaiTimeFormatPipe } from "../../pipes/time-format.pipe";

@Component({
  "selector": "kt-flight-preview",
  "templateUrl": "./flight-preview.component.html",
  "styleUrls": ["./flight-preview.component.scss"],
  "providers": [
    CaiTimeFormatPipe,
    TimeDiffer,
    TimeFromStartDifferPipe,
    DayDiffer,
  ],
})
export class FlightPreviewComponent implements OnInit {
  @Input() flightDetails: any;
  flightDays: number;
  totalDays = [];
  sliderFirstDep: Date;
  dayDiff: number;

  constructor (
    public fileService: AppFileService,
    public timeDifferPipe: TimeDiffer,
    public timeFromStartDiffer: TimeFromStartDifferPipe,
    public readonly globalService: GlobalService
  ) {}

  async ngOnInit () {
    this.totalDays = [...Array(1).keys()];
    this.dayDiff = getDifferenceInDays(
      this.flightDetails.departureTime,
      this.flightDetails.arrivalTime
    );
    const airCrafts = await this.globalService.getAircrafts(),

     flights = [mapFlightSearchToTrip(this.flightDetails, airCrafts)][0]
      .flights;
    this.flightDetails.legs.forEach((leg, index) => {
      this.flightDetails.legs[index].aircraft = flights[index]?.aircraft;
    });
  }

  get Math () {
    return Math;
  }

  getFlightDuration () {
    return getDuration(
      this.flightDetails?.departureTime,
      this.flightDetails?.arrivalTime
    );
  }
}
