import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { QuoteStatusEnum } from "@cai-services";

@Component({
  "selector": "kt-advanced-shipment-status",
  "templateUrl": "./advanced-shipment-status.component.html",
  "styleUrls": ["./advanced-shipment-status.component.scss"],
})
export class AdvancedShipmentStatusComponent implements OnChanges {
  @Input() status: QuoteStatusEnum;

  bgColor: string;
  fontColor: string;
  borderColor: string;
  text: string;

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.hasOwnProperty("status")) {
      switch (this.status) {
        case QuoteStatusEnum.PENDING_AIRLINE_INFORMATION:
          this.text = "Booking Requested";
          this.borderColor = "#2DCE89";
          this.fontColor = "#2DCE89";
          this.bgColor = "#ffffff";
          break;
        case QuoteStatusEnum.PENDING_DELIVERY:
          this.text = "Booking Confirmed";
          this.borderColor = "#2DCE89";
          this.fontColor = "#ffffff";
          this.bgColor = "#2DCE89";
          break;
        case QuoteStatusEnum.REQUEST_FAILED:
          this.text = "Request Failed";
          this.borderColor = "#EF75B4";
          this.fontColor = "#ffffff";
          this.bgColor = "#EF75B4";
          break;
        case QuoteStatusEnum.BOOKING_REJECTED:
          this.text = "Booking Rejected";
          this.borderColor = "#FF708B";
          this.fontColor = "#ffffff";
          this.bgColor = "#FF708B";
          break;
        case QuoteStatusEnum.CANCELLED:
          this.text = "Cancelled";
          this.borderColor = "#FF708B";
          this.fontColor = "#ffffff";
          this.bgColor = "#FF708B";
          break;
        case QuoteStatusEnum.FAILED:
          this.text = "Booking Failed";
          this.borderColor = "#F8A243";
          this.fontColor = "#ffffff";
          this.bgColor = "#F8A243";
          break;
        case QuoteStatusEnum.CANCELLATION_REQUESTED:
          this.text = "Cancellation Requested";
          this.borderColor = "#F8A243";
          this.fontColor = "#ffffff";
          this.bgColor = "#F8A243";
          break;
        case QuoteStatusEnum.BOOKING_FAILED:
          this.text = "Booking Failed";
          this.bgColor = "#F8A243";
          this.borderColor = "#F8A243";
          this.fontColor = "#ffffff";
          break;
        case QuoteStatusEnum.BOOKING_CONFIRMED:
          this.text = "Booking Confirmed";
          this.bgColor = "#2DCE89";
          this.borderColor = "#2DCE89";
          this.fontColor = "#ffffff";
          break;
      }
    }
  }
}
