import { IAirlineBrand, ICompany } from "@cai-services";
import { Route } from "./route.model";
import { NegoRoute } from "./nego-route.model";
import { EmailToConfirm } from "./email-to-confirm.model";

export class AirlineBrand implements IAirlineBrand {
  airlineCompanyId: number;
  airlineCompanyName: string;
  airlineCompanyCode: string;
  awbPrefix: string;
  preventRateExport: boolean;
  allowRateProdCode: boolean;
  mainWebsiteUrl: string;
  company: ICompany;
  mainAirlineLogoPath?: string;
  routes: Route[];
  negoRoutes: NegoRoute[];
  emailToConfirms: EmailToConfirm[];

  constructor (airlineCompanyName?: string, airlineCompanyCode?: string) {
    this.airlineCompanyName = airlineCompanyName;
    this.airlineCompanyCode = airlineCompanyCode;
    this.preventRateExport = false;
    this.allowRateProdCode = false;
  }
}
