import { Component, Input } from "@angular/core";

@Component({
  "selector": "kt-svg-auto-awb",
  "templateUrl": "./svg-auto-awb.component.html",
})
export class SvgAutoAwbComponent {
  @Input() size = 30;
  get autoAwbTooltip (): string {
    return $localize`:@@global.auto-awb-tooltip:Airline can provide an AWB automatically`;
  }
}
