import { RequirementConfig } from "../../core/_models/requirement-config.model";

export enum TemperatureLivingAnswer {
  NOT_REQUIRED = "temp_not_required",
  // NA = 'NA',
  CONTROLLED = "controlled",
  AMBIANT = "ambiant",
}

export const TemperatureLivingConfig: RequirementConfig = {
  "summaryLabel": "Temperature requirements :",
  "question": "TEMPERATURE REQUIREMENTS",
  "options": [
    {
      "label": "Not Required",
      "value": TemperatureLivingAnswer.NOT_REQUIRED,
      "description": "",
    },
    // {
    //   label: 'N/A',
    //   value: TemperatureLivingAnswer.NA,
    //   description: '',
    // },
    {
      "label": "2°C to 25°C",
      "value": TemperatureLivingAnswer.CONTROLLED,
      "description": "(36°F to 77°F)",
    },
    {
      "label": "15°C to 25°C ",
      "value": TemperatureLivingAnswer.AMBIANT,
      "description": "(59°F to 77°F)",
    },
  ],
};
