import { ModuleWithProviders, NgModule, InjectionToken } from "@angular/core";

export const ENV = new InjectionToken<any>(
  "Provide Angular environment as injection token"
);

@NgModule()
export class CaiServicesModule {
  static forRoot (env: any = { "production": false }): ModuleWithProviders<any> {
    return {
      "ngModule": CaiServicesModule,
      "providers": [
        {
          "provide": ENV,
          "useValue": env,
        },
      ],
    };
  }
}
