import * as qs from 'querystring';
import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { Store } from '@ngrx/store';
import { datadogRum } from '@datadog/browser-rum';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SsoConfig, SsoService, UserTypeEnum } from '@cai-services';
import { APP_PROPS } from '../../../cai-common.module';
import { ApplicationProperties } from '../../../core/_base/layout/models/app-properties.model';
import { MenuAsideService } from '../../../core/_base/layout/services/menu-aside.service';
import { MenuConfigService } from '../../../core/_base/layout/services/menu-config.service';
import { MenuHorizontalService } from '../../../core/_base/layout/services/menu-horizontal.service';
import {
  ADMIN_GROUP,
  AIRLINE_GROUP,
  EMAIL_REGEX,
  FORWARDER_GROUP,
  REGEXP_PASSWORD,
  STANDARD_GROUP,
  SUPERADMIN_USER_GROUP,
} from '../../../core/_constants/constants';
import { IntercomEventEnum } from '../../../core/_enums/intercom-event.enum';
import { AuthNoticeService } from '../../../core/_misc/auth-notice/auth-notice.service';
import { Forwarder } from '../../../core/_models/forwarder.model';
import { IntercomService } from '../../../core/_services/intercom.service';
import { RegisterService } from '../../../core/_services/register.service';
import { SessionService } from '../../../core/_services/session.service';
import { ExpirableStorage } from '../../../core/_storages/expirable.storage';
import { environment } from '../../../environments';
import { BrandIconUtil } from '../../../utils/brand.util';
import { LoginFormValidators } from '../../../validators/loginForm.validator';
import { OTPStatus } from '../../layout/new-otp-container/new-otp-container.component';
import { NewPasswordComponent } from '../new-password/new-password.component';
import { WalletUtil } from '../../../utils/wallet.util';
import { AppState, Logout, UsetifulService } from '../../../core';
import { BasicPopupComponent } from '../../popup/basic-popup.component';

const unknownMartUserError = $localize`:@@login.unknown-mart-user:There is no account associated with this email. Please try again or`,
  unknownUserError = $localize`:@@login.unknown-user:There is no account associated with this email. Please try again.`,
  wrongPasswordMessage = $localize`:@@login.wrong-password:Wrong email or password`,
  rememberMe = $localize`:@@login.remember-me:REMEMBER ME`,
  linkMessage = $localize`:@@login.link-message:sign up`,
  expiredMessage = $localize`:@@otp.expired:OTP has expired, you can click on ‘resend OTP’ to get a new one`,
  title = $localize`:@@otp-container.title:SUCCESS!`;

@Component({
  selector: 'kt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('250ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class LoginComponent implements OnInit, OnDestroy {
  forwarder: Forwarder;
  errorMessage: string;
  isLogin: boolean;
  loginForm: FormGroup;
  loading = false;
  loadingMessage = false;
  errors: any = [];
  cognitoUser: any;
  incorrectTime = 0;
  banDeadline: Date;
  rememberLogin: boolean;
  isEmbedMode: boolean;
  browserKey: string;
  requireOTP = false;
  OTPStatus = undefined;
  return = '';
  isShowPassword: boolean;
  unknownUserMessage: string;
  wrongPasswordMessage: string;
  rememberMe = rememberMe;
  linkMessage = linkMessage;
  expiredOTPMessage = expiredMessage;
  title = title;
  loginDisabled = true;
  registerUrl: string;
  ssoConfigs: SsoConfig[];
  prefillRegisterEmail: string;
  features = environment.features;

  constructor(
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private readonly router: Router,
    private readonly authNoticeService: AuthNoticeService,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    public readonly sessionService: SessionService,
    public readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly registerService: RegisterService,
    private readonly menuConfigService: MenuConfigService,
    private readonly menuHorizontalService: MenuHorizontalService,
    private readonly menuAsideService: MenuAsideService,
    private readonly intercomService: IntercomService,
    private readonly usetifulService: UsetifulService,
    private readonly modalService: NgbModal,
    private readonly ssoService: SsoService,
    private readonly store: Store<AppState>,
  ) {
    this.registerUrl = appProperties.registerUrl;
    this.route.queryParams.subscribe((params) => {
      this.return = params.return ? params.return : '';
      this.prefillRegisterEmail = params.prefillRegisterEmail
        ? params.prefillRegisterEmail
        : '';
      const queryParams = qs.parse(
        this.return.substring(this.return.indexOf('?') + 1),
      );
      this.isEmbedMode = WalletUtil.isEmbedMode(
        this.appProperties,
        queryParams,
      );
    });
  }

  async ngOnInit(): Promise<void> {
    const sessionExists = await this.tryLoggingInIfSessionExists();
    if (sessionExists) {
      return;
    }

    if (this.sessionService.isRememberMeSet()) {
      this.rememberLogin = this.sessionService.getRememberMeLogin();
    } else {
      this.rememberLogin = true;
    }
    this.initLoginForm();
    this.loginForm.get('email').valueChanges.subscribe(() => {
      this.setLoginDisabled();
      if (this.unknownUserMessage) {
        this.unknownUserMessage = '';
      }
    });
    this.loginForm.get('password').valueChanges.subscribe(() => {
      this.setLoginDisabled();
      if (this.wrongPasswordMessage) {
        this.wrongPasswordMessage = '';
      }
    });
    this.browserKey = this.sessionService.getBrowserKey();
    if (environment.datadog) {
      datadogRum.addFeatureFlagEvaluation('i18n', this.features.i18n);
      datadogRum.addFeatureFlagEvaluation(
        'guestUserLogin',
        this.features.guestUserLogin,
      );
    }
    if (this.isCargoAdmin || this.isCargoWallet) {
      this.ssoConfigs = await this.ssoService.fetchSsoConfigs();
    }
  }

  async tryLoggingInIfSessionExists(): Promise<boolean> {
    try {
      await Auth.currentSession();
      await this.handleLoginSuccess();
      return true;
    } catch (e) {
      console.log(e);
      Auth.signOut().then(() => {
        this.store.dispatch(new Logout());
        this.sessionService.clearLocalStorage();
        this.usetifulService.removeUsetiful();
        const returnUrl = this.route.snapshot.queryParamMap.get('return'),
          officeId = this.route.snapshot.queryParamMap.get('officeId');
        this.prefillRegisterEmail = this.route.snapshot.queryParamMap.get(
          'prefillRegisterEmail',
        );
        const queryParams = {} as any;

        if (returnUrl) {
          queryParams.return = encodeURIComponent(returnUrl);
        }

        if (officeId) {
          queryParams.officeId = officeId;
        }

        if (this.prefillRegisterEmail) {
          queryParams.prefillRegisterEmail = this.prefillRegisterEmail;
        }

        const queryParamsString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join('&'),
          url = queryParamsString
            ? `/auth/login?${queryParamsString}`
            : '/auth/login';

        this.router.navigateByUrl(url);
      });
      return false;
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.loading = false;
  }

  /**
   * Form initalization
   * Default params, validators
   */
  initLoginForm() {
    this.loginForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          LoginFormValidators.required('Email'),
          LoginFormValidators.pattern(EMAIL_REGEX, false, 'email address'),
          LoginFormValidators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ]),
      ],
      password: [
        '',
        Validators.compose([
          LoginFormValidators.required('Password'),
          LoginFormValidators.pattern(REGEXP_PASSWORD),
          LoginFormValidators.maxLength(100),
        ]),
      ],
    });
    this.cdr.detectChanges();
  }

  async signInSwitch(challengeName: string, authState): Promise<void> {
    switch (challengeName) {
      case 'CUSTOM_CHALLENGE':
        this.requireOTP = true;
        this.cdr.detectChanges();
        break;
      case 'NEW_PASSWORD_REQUIRED':
        const dialogRef = this.dialog.open(NewPasswordComponent, {
          data: { user: authState },
        });
        dialogRef.afterClosed().subscribe(async (user) => {
          if (user?.challengeName === 'CUSTOM_CHALLENGE') {
            this.requireOTP = true;
            this.cdr.detectChanges();
          } else if (!user) {
            this.loading = false;
            this.authNoticeService.setNotice(
              "Can't complete new password",
              'danger',
            );
          } else {
            await this.handleLoginSuccess();
          }
        });
        break;
      default:
        await this.handleLoginSuccess();
        break;
    }
  }

  get SsoSrc(): string {
    if (this.isCargoAdmin) {
      return '/assets/media/login/microsoft.png';
    } else if (this.isCargoWallet) {
      return '/assets/media/login/google.svg';
    }
  }

  get ssoProvider(): string {
    if (this.isCargoAdmin) {
      return $localize`:@@login.microsoft:Microsoft`;
    } else if (this.isCargoWallet) {
      return $localize`:@@login.google:Google`;
    }
  }

  getSsoProviderConfig(domainName: string): SsoConfig {
    if (domainName === 'onlysso.co') {
      return this.ssoConfigs?.find((config) => config.domain === domainName);
    } else {
      return this.ssoConfigs?.find(
        (config) =>
          config.domain === domainName &&
          config.providerName ===
            (this.isCargoAdmin ? 'MicrosoftCargoAi' : 'GoogleCargoAi'),
      );
    }
  }

  /**
   * Form Submit
   */
  submitMsftLogin() {
    this.loading = true;
    const domainName = this.extractDomainFromEmail(
        this.loginForm.get('email').value,
      ),
      currentProvider = this.getSsoProviderConfig(domainName),
      providerName = currentProvider?.providerName;

    if (providerName) {
      Auth.federatedSignIn({ customProvider: providerName });
    }

    this.cdr.detectChanges();

    return null;
  }

  async submit() {
    this.sessionService.removeItemSessionStorage('isGuestUser');
    this.OTPStatus = OTPStatus.isSending;
    const controls = this.loginForm?.controls;
    /** check form */
    if (this.loginForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched(),
      );
      return;
    }
    this.loading = true;
    this.sessionService.setRememberMeLogin(!!this.rememberLogin);
    if (!this.rememberLogin) {
      Auth.configure({
        storage: localStorage,
      });
      await Auth.signOut();
    }
    Auth.configure({
      storage: this.rememberLogin ? localStorage : ExpirableStorage,
    });

    Auth.signIn(
      controls?.email.value, // Required, the username
      controls?.password.value,
      {
        deviceId: '' + this.browserKey,
        origin: window.location.origin,
      },
    )
      .then(async (authState) => {
        const currentGroup =
          authState.signInUserSession.accessToken.payload['cognito:groups'];
        if (!this.isValidUserGroup(currentGroup)) {
          this.handleError({
            message: 'user not exist',
          });
          return;
        }
        this.OTPStatus = OTPStatus.sent;
        this.loading = false;
        const { challengeName } = authState;
        this.cognitoUser = authState;
        await this.signInSwitch(challengeName, authState);
      })
      .catch((err) => {
        this.handleError(err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  handleError(err: any) {
    this.loading = false;
    if (err.message.includes('exist')) {
      this.unknownUserMessage = this.isCargoMart
        ? unknownMartUserError
        : unknownUserError;
      this.wrongPasswordMessage = '';
    } else if (err.message.includes('username')) {
      this.wrongPasswordMessage = wrongPasswordMessage;
      this.unknownUserMessage = '';
    } else {
      alert(
        `There is an error, please try again or contact us!\n
         ${err.name}: ${err.message}\n
         \n
         ${err.stack}`,
      );
    }

    this.cdr.detectChanges();
  }

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(
    controlName: string,
    validationType: string,
    isField = false,
  ): boolean {
    const control = this.loginForm?.controls[controlName];
    if (!control) {
      return false;
    }
    if (
      control.status === 'INVALID' &&
      isField &&
      (control.dirty || control.touched)
    ) {
      return true;
    }
    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }

  async handleLoginSuccess(): Promise<void> {
    const currentUser = await Auth.currentAuthenticatedUser();
    if (!currentUser) {
      console.log('currentUser not found', currentUser);
      return;
    }
    const currentGroup =
        currentUser.signInUserSession.accessToken.payload['cognito:groups'],
      filteredGroup = currentGroup.filter((group) =>
        [
          FORWARDER_GROUP,
          AIRLINE_GROUP,
          ADMIN_GROUP,
          SUPERADMIN_USER_GROUP,
          STANDARD_GROUP,
        ].includes(group),
      );
    if (!filteredGroup) {
      alert('Please finish registration first in order to log in');
      this.router.navigateByUrl('/auth/login');
      return;
    }
    if (!this.isValidUserGroup(filteredGroup)) {
      console.log('Missing valid group');
      // if user doesn't belong to this group, log out and redirect to auth/login
      await Auth.signOut()
        .then(() => {
          this.router.navigateByUrl('/auth/login');
        })
        .catch(() => {});
      return;
    }
    const userEmail = currentUser.attributes.email;
    if (!userEmail) {
      console.log("Can't find email");
      return;
    }
    this.initUser(userEmail, filteredGroup[0]);
  }

  isValidUserGroup(group: string[]): boolean {
    if (
      !this.isCargoAdmin &&
      (group.includes(FORWARDER_GROUP) ||
        group.includes(AIRLINE_GROUP) ||
        group.includes(STANDARD_GROUP))
    ) {
      return true;
    }
    if (
      this.isCargoAdmin &&
      (group.includes(ADMIN_GROUP) || group.includes(SUPERADMIN_USER_GROUP))
    ) {
      return true;
    }
    return false;
  }

  initUser(userEmail: string, group: string, retries = 3): void {
    this.registerService
      .getUser()
      .then((res) => {
        if (res.userStatus === 'PENDING_MANUAL_ACTIVATION') {
          const options: NgbModalOptions = {
              windowClass: 'basic-modal',
              backdrop: 'static',
              keyboard: false,
              centered: true,
            },
            dialogRef = this.modalService.open(BasicPopupComponent, options);
          dialogRef.componentInstance.header = $localize`:@@login.account-creation in process:Account creation in process`;
          dialogRef.componentInstance.body =
            '<div><div>Thank you for signing up!</div><div>Your account is pending manual activation and</div><div>will be available within 24 hours.</div></div>';
          dialogRef.componentInstance.primaryButtonText = $localize`:@@global.close:close`;
          dialogRef.componentInstance.hideCancel = true;
          dialogRef.result.then(
            () => {
              this.modalService.dismissAll();
              Auth.signOut();
            },
            () => {
              this.modalService.dismissAll();
              Auth.signOut();
            },
          );

          return;
        }
        this.sessionService.setIsLogin(true);
        this.sessionService.setCurrentUser(res);
        this.usetifulService.initUsetiful();
        const nextUrl = this.getLoginSuccessUrl(group);
        this.router.navigateByUrl(nextUrl).then(() => this.initMenu());
      })
      .catch((e) => {
        // Handling the 504 with a retry to give time for the DB to start
        if (retries !== 0) {
          return this.initUser(userEmail, group, --retries);
        } else {
          this.loading = false;
          console.error(e);
          alert(
            `There is an error, please try again or contact us!\n
             ${e.name}: ${e.message}\n
             \n
             ${e.stack}`,
          );
          this.router.navigateByUrl('/auth/login');
        }
      });
  }

  getLoginSuccessUrl(currentGroup): string {
    if (this.return !== '') {
      return this.return;
    }
    const userMenu = this.appProperties.menu.find((config) =>
      currentGroup.includes(config.userType.toLowerCase()),
    );
    if (userMenu) {
      const user = this.sessionService.getCurrentUser();
      if (
        user?.type === UserTypeEnum.AIRLINE &&
        !!user?.activatedFeatures?.find(
          (af) => af.featureName === 'INTERLINE',
        ) &&
        !this.isCargoWallet
      ) {
        return userMenu.secondDefaultPage;
      }
      return userMenu.defaultPage;
    }
  }

  updateIntercom(userEmail: string): void {
    this.intercomService.trackEvent(IntercomEventEnum.SIGN_IN, userEmail);
  }

  async initMenu(): Promise<void> {
    const controls = this.loginForm?.controls,
      user = this.sessionService.getCurrentUser(),
      userMenu = this.appProperties.menu.find(
        (config) =>
          user?.type === config.userType &&
          config.authenticationMethods.includes(user?.authenticationMethod),
      );
    if (userMenu && !this.isEmbedMode) {
      this.menuConfigService.loadConfigs(userMenu.config.menuItems);
    }
    this.menuAsideService.loadMenu();
    this.menuHorizontalService.loadMenu();
    this.updateIntercom(controls?.email.value);
  }

  checkOTP(challengeAnswer: string): void {
    this.OTPStatus = OTPStatus.isChecking;
    this.cdr.detectChanges();

    if (challengeAnswer.includes('expired')) {
      this.OTPStatus = OTPStatus.expired;
      return;
    }
    // prettier-ignore
    Auth.sendCustomChallengeAnswer(
      this.cognitoUser, // Return object from Auth.signIn()
      challengeAnswer, // Confirmation code
      {
        "deviceId": "" + this.browserKey,
      }
    )
      .then(async (result) => {
        // wrong otp
        if (result.challengeName === "CUSTOM_CHALLENGE") {
          this.OTPStatus = OTPStatus.incorrect;
          this.cdr.detectChanges();
          return;
        }
        this.OTPStatus = OTPStatus.correct;
        this.cdr.detectChanges();
        setTimeout(async ()=>await this.handleLoginSuccess(),3300);
      })
      .catch((e) => {
        if(e.message.includes("Invalid session for the user")){
          this.OTPStatus=OTPStatus.expired;
        }
        else{
        this.OTPStatus = OTPStatus.incorrect;
        }
        this.cdr.detectChanges();
      });
  }

  resendOTP(): void {
    this.submit();
  }

  toggleRememberLogin(): void {
    this.rememberLogin = !this.rememberLogin;
  }

  get OTPSuccessMessage(): string {
    return $localize`:@@login.text-success-otp-message:You will be redirected to the home page in `;
  }

  toggleEye(): void {
    this.isShowPassword = !this.isShowPassword;
  }

  setLoginDisabled(): void {
    const controls = this.loginForm?.controls;
    if (
      controls?.email.status === 'INVALID' ||
      controls?.password.status === 'INVALID' ||
      controls?.email.value === '' ||
      controls?.password.value === ''
    ) {
      this.loginDisabled = true;
    } else {
      this.loginDisabled = false;
    }
    this.cdr.detectChanges();
  }

  get backgroundImage(): string {
    return BrandIconUtil.fetchBackgroundIcon(
      this.appProperties.logo,
      '/assets/media/logos/',
    );
  }

  get otherBrand(): boolean {
    return !BrandIconUtil.isCargoAiDomain();
  }

  get isCargoAdmin(): boolean {
    return this.appProperties.name === 'cargoadmin';
  }

  get isCargoMart(): boolean {
    return this.appProperties.name === 'cargomart';
  }

  get isCargoWallet(): boolean {
    return this.appProperties.name === 'cargowallet';
  }

  openSite(): void {
    window.open('https://www.cargoai.co/', '_blank', 'noopener');
  }

  hideOTP(): void {
    this.requireOTP = false;
    this.cdr.detectChanges();
  }

  get hideOtherLoginFields(): boolean {
    if (!this.isCargoAdmin) {
      return false;
    }
    if (
      this.loginForm.get('email').value &&
      !this.isControlHasError('email', '', true)
    ) {
      return false;
    }
    return true;
  }

  extractDomainFromEmail(email) {
    const regex = /@([A-Za-z0-9.-]+\.[A-Za-z]{2,})/, // Updated regex
      matchResult = email.match(regex);

    if (matchResult && matchResult.length > 1) {
      return matchResult[1];
    }

    return null;
  }

  get showOnlySso(): boolean {
    const email = this.loginForm.get('email').value,
      domainName = this.extractDomainFromEmail(email),
      currentProvider = this.getSsoProviderConfig(domainName);
    if (currentProvider?.strict) {
      return true;
    }
    return false;
  }

  get showSso(): boolean {
    const email = this.loginForm.get('email').value,
      domainName = this.extractDomainFromEmail(email),
      currentProvider = this.getSsoProviderConfig(domainName);
    if (currentProvider) {
      return true;
    }
    return false;
  }
  get isWalletApp(): boolean {
    return this.appProperties.name === 'cargowallet';
  }

  loginAsGuest() {
    const queryParams = { isGuestUser: 'true' };
    this.router.navigate(['/register'], { queryParams });
  }
}
