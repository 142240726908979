import { FlightChargeTypeEnum } from "../../enum/flight-charge-type.enum";
import { IFlightRateCharge } from "../../model/flight-rate-charge.model";

export const mockedFlightCharges: IFlightRateCharge[] = [
  {
    "code": "fsc",
    "label": "Fuel surcharge",
    "type": FlightChargeTypeEnum.CHARGEABLE_WEIGHT,
    "rate": 0,
  },
  {
    "code": "ssc",
    "label": "Security surcharge",
    "type": FlightChargeTypeEnum.CHARGEABLE_WEIGHT,
    "rate": 0,
  },
];
