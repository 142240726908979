export enum QuoteActionEnum {
  EDIT_RATE = "EDIT_RATE",
  EDIT_FLIGHT = "EDIT_FLIGHT",
  REJECT_QUOTE = "REJECT_QUOTE",
  ACCEPT_QUOTE = "ACCEPT_QUOTE",
  BOOK_QUOTE = "BOOK_QUOTE",
  REJECT_BOOKING = "REJECT_BOOKING",
  CONFIRM_BOOKING = "CONFIRM_BOOKING",
  CANCEL_BOOKING = "CANCEL_BOOKING",
  RATE_SHIPMENT = "RATE_SHIPMENT",
  CONFIRM_SHIPPING = "CONFIRM_SHIPPING",
  CONFIRM_DESTINATION = "CONFIRM_DESTINATION",
  CONFIRM_DELIVERY = "CONFIRM_DELIVERY",
  UPDATE_QUOTE = "UPDATE_QUOTE",
  UPDATE_BOOKING = "UPDATE_BOOKING",
  UPDATE_SHIPMENT = "UPDATE_SHIPMENT",
  EDIT_CARGO = "EDIT_CARGO",
  REQUEST_QUOTE = "REQUEST_QUOTE",
  REQUEST_BOOKING = "REQUEST_BOOKING",
  PLACE_OPTION = "PLACE_OPTION",
  CONFIRM_OPTION = "CONFIRM_OPTION",
  UPDATE_OPTION = "UPDATE_OPTION",
  CANCEL_OPTION = "CANCEL_OPTION"
}
