import { Injectable } from "@angular/core";
import { environment } from "../../environments";

@Injectable({
  "providedIn": "root",
})
export class CargoGateService {
  constructor () {}

  public isFeatureDisabledByCargoGate (featureName: string): boolean {
    const cargoGateConfig = this.getCargoGateConfig();
    if (
      cargoGateConfig === undefined ||
      cargoGateConfig.disabledFeatures === undefined
    ) {
      return false;
    }

    return cargoGateConfig.disabledFeatures[featureName] === true;
  }

  public getCargoGateConfig (): CargoGateConfig | undefined {
    const cargoGateSubdomain = this.getCargoGateSubdomain();
    if (
      cargoGateSubdomain === undefined ||
      environment.cargoGateConfig === undefined
    ) {
      return undefined;
    }

    const cargoGateConfigs = environment.cargoGateConfig as CargoGateConfig[];
    return cargoGateConfigs.find(
      (config) => config.subdomain === cargoGateSubdomain
    );
  }

  public getCargoGateSubdomain (): string | undefined {
    const hostName = window.location.hostname,

     caiUrlSuffix = ".cargoai.co";
    if (!hostName.includes(caiUrlSuffix)) {
      // either localhost or not a CAI url
      return undefined;
    }

    const hostNameSplit = hostName.split("."),
     subdomain = hostNameSplit[0];

    if (subdomain === "app" || subdomain === "admin") {
      // CargoMART and CargoADMIN are not CargoGATE subdomains
      return undefined;
    }

    return subdomain;
  }
}

interface CargoGateConfig {
  subdomain: string;
  disabledFeatures: { [feature: string]: boolean };
}
