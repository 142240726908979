import { RequirementConfig } from "../../core/_models/requirement-config.model";

export enum KnownShipperAnswer {
  NO = "uks_no",
  YES = "default_yes",
}

const DEFAULT_OPTION = {
  "label": "Yes",
  "value": KnownShipperAnswer.YES,
};

export const KnownShipperConfig: RequirementConfig = {
  "summaryLabel": "",
  "question": "",
  "options": [
    DEFAULT_OPTION,
    {
      "label": "NO",
      "value": KnownShipperAnswer.NO,
    },
  ],
  "defaultOption": DEFAULT_OPTION,
  "activeOption": DEFAULT_OPTION,
};
