<svg
  width="73"
  height="70"
  viewBox="0 0 73 70"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g filter="url(#filter0_d_224:1854)">
    <path
      d="M16.9053 52.1286C15.7782 51.744 14.8446 51.2372 14.0926 50.7148C14.1708 47.484 14.7021 44.0675 15.7221 40.8151C18.4438 32.1135 24.2071 25.5199 31.9459 22.2421C45.9125 16.3331 55.8503 11.0633 61.0654 3.99992C56.4774 6.18782 50.028 7.68518 43.191 9.24449C30.194 12.2282 15.4488 15.6119 9.51924 25.1797C6.73066 29.6805 6.10993 35.2524 7.67458 41.7406C8.38854 44.7022 11.4111 48.8738 14.0903 50.7653C14.0084 54.4112 14.5052 57.8156 15.6214 60.4532C15.893 61.0963 16.5885 61.4333 17.2479 61.2737C17.3218 61.2558 17.3941 61.2318 17.4674 61.1996C18.1818 60.8963 18.5172 60.0686 18.2147 59.357C17.4282 57.5077 16.9011 51.9559 16.9053 52.1286Z"
      fill="url(#paint0_linear_224:1854)"
    />
    <path
      d="M33.0434 24.8372C24.1163 28.614 20.1579 36.0712 18.4084 41.6586C17.7839 43.6611 17.3882 45.6279 17.1575 47.5105C28.1668 52.9702 37.5371 53.6208 45.0163 49.4501C59.3275 41.4651 62.8736 18.254 63.7123 5.09917C58.2188 12.9627 47.7901 18.6003 33.0434 24.8372Z"
      fill="url(#paint1_linear_224:1854)"
    />
  </g>
  <defs>
    <filter
      id="filter0_d_224:1854"
      x="0.833496"
      y="0"
      width="68.8789"
      height="69.313"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="2" />
      <feGaussianBlur stdDeviation="3" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_224:1854"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_224:1854"
        result="shape"
      />
    </filter>
    <linearGradient
      id="paint0_linear_224:1854"
      x1="31.5327"
      y1="11.1483"
      x2="42.2034"
      y2="55.2333"
      gradientUnits="userSpaceOnUse"
    >
      <stop [attr.stop-color]="startClr" />
      <stop offset="1" [attr.stop-color]="endClr" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_224:1854"
      x1="36.8745"
      y1="11.5952"
      x2="46.1619"
      y2="49.9652"
      gradientUnits="userSpaceOnUse"
    >
      <stop [attr.stop-color]="startClr" />
      <stop offset="1" [attr.stop-color]="endClr" />
    </linearGradient>
  </defs>
</svg>
