import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IRequirementAnswer } from "@cai-services";
import { TemperatureLivingConfig } from "../../../constant/requirements/temperature-living.const";

@Component({
  "selector": "kt-requirement-temperature-living",
  "templateUrl": "./requirement-temperature-living.component.html",
  "styleUrls": ["./requirement-temperature-living.component.scss"],
})
export class RequirementTemperatureLivingComponent implements OnInit {
  @Output() onFocus = new EventEmitter();

  formGroup: FormGroup = new FormGroup({});
  code = "";
  defaultAnswer = {} as IRequirementAnswer;
  selectedAnswer: any = {};
  question: string = TemperatureLivingConfig.question;
  options: any[] = TemperatureLivingConfig.options;
  notRequired = TemperatureLivingConfig.options[0];

  constructor (private readonly cdr: ChangeDetectorRef) {}

  ngOnInit (): void {
    this.updateAnswer(this.formGroup.get(this.code).value);
    this.formGroup.get(this.code).valueChanges.subscribe((value) => {
      this.updateAnswer(value);
    });
  }

  selectAnswer (option) {
    if (this.selectedAnswer !== option) {
      this.selectedAnswer = option;
      this.formGroup.get(this.code).setValue(option.value);
      this.onFocus.emit();
    }
  }

  getLabelArray (value: string): string[] {
    if (value) {
      const labelArr = value.split(" to ");
      return labelArr.map((d) => d.trim());
    }
    return [];
  }

  private updateAnswer (value) {
    const selected = this.options.find((opt) => opt.value === value);
    if (selected) {
      this.selectedAnswer = selected;
      this.cdr.detectChanges();
    }
  }
}
