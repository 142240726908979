import {
  Component,
  ElementRef,
  Input,
  Optional,
  Self,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";

@Component({
  "template": "",
})
export class AdvancedInputBaseComponent implements ControlValueAccessor {
  @ViewChild("input") input: ElementRef;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() value: string;
  @Input() viewOnly: boolean;
  @Input() floatLabel: boolean;
  @Input() maxLength: number;
  @Input() noUpdateOnBlur: boolean;
  @Input() textCenter: boolean;
  @Input() isDisabled: boolean;
  @Input() textTransform: "uppercase" | "lowercase";

  onChange = (value: any) => {};
  onTouched = () => {};

  constructor (
    @Self()
    @Optional()
    private readonly control: NgControl,
    private readonly el: ElementRef,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  registerOnChange (onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched (onTouched: any) {
    this.onTouched = onTouched;
  }

  writeValue (value: any) {
    value = value?.value || value;
    if (
      this.label === "AWB" &&
      value &&
      !value?.includes("-") &&
      value?.length > 4
    ) {
      value = `${value.slice(0, 3)}-${value.slice(3, value.length)}`;
    }
    this.value = this.convertCase(value);
    this.onChange(value);
  }

  keyup ($event) {
    const key = $event.keyCode || $event.charCode;
    if (key === 9) {
      return;
    }
    this.markAsTouched();
    this.onChange(this.value);
  }

  blur () {
    this.markAsTouched();
    if (!this.noUpdateOnBlur) {
      this.onChange(this.value);
    }
  }

  markAsTouched () {
    this.onTouched();
  }

  convertCase (text: string): string {
    if (text && this.textTransform) {
      return this.textTransform === "uppercase"
        ? text.toUpperCase()
        : text.toLowerCase();
    }
    return text;
  }

  get boundingClientRect () {
    return this.el.nativeElement.getBoundingClientRect();
  }

  get isFocused (): boolean {
    return this.input?.nativeElement === document.activeElement;
  }

  get invalid (): boolean {
    return this.control?.touched ? this.control.invalid : false;
  }

  get showError (): boolean {
    if (!this.control) {
      return false;
    }
    const { dirty, touched } = this.control;
    return this.invalid ? dirty || touched : false;
  }

  get errorMessage (): string {
    if (this.control?.errors) {
      const error = Object.values(this.control.errors)[0].message || null;
      if (error) {
        return error.replace("{field}", this.label);
      }
    }
    return null;
  }
}
