import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({ "providedIn": "root" })
export class AppWalletService {
  private defaultCurrency$ = new Subject<string>();

  updateDefaultCurrency (currency: string) {
    this.defaultCurrency$.next(currency);
  }

  returnUpdateDefaultCurrencyObs () {
    return this.defaultCurrency$.asObservable();
  }
}
