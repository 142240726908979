import { Injectable } from "@angular/core";
import { FileExtension } from "../_enums/file-extension-enum";
import { QuoteAttachment } from "../_models/quote-attachment.model";

@Injectable({
  "providedIn": "root",
})
export class AttachmentService {
  public getIconPath (fileName: string): string {
    switch (this.getExtention(fileName)) {
      case FileExtension.DOCX:
        return "/assets/media/icons/doc-icon.png";
      case FileExtension.XLSX:
        return "/assets/media/icons/xls-icon.png";
      default:
        return (
          "/assets/media/icons/" + this.getExtention(fileName) + "-icon.png"
        );
    }
  }

  public sortAttachments (attachments: QuoteAttachment[]): QuoteAttachment[] {
    const extenstionsInOrder = [
      FileExtension.ZIP,
      FileExtension.EML,
      FileExtension.PDF,
      FileExtension.PNG,
      FileExtension.MSG,
      FileExtension.DOC,
      FileExtension.DOCX,
    ];
    let sortedAttachments = [];
    extenstionsInOrder.forEach((ext) => {
      const filteredAttachments = attachments.filter(
        (attachment) =>
          this.getExtention(attachment.fileName).toLowerCase() === ext
      );
      sortedAttachments = [...sortedAttachments, ...filteredAttachments];
    });
    return sortedAttachments;
  }

  getExtention (fileName: string): string {
    return fileName.split(".").pop();
  }
}
