<div class="temp-tracking-number" [class.d-none]="!visible">
  <div class="temp-tracking-number__body">
    <div class="question">
      {{ question }}
    </div>
    <div class="qty">
      <form [formGroup]="formGroup">
        <input
          #input
          class="custom-input"
          type="text"
          placeholder="Qty"
          [formControlName]="code"
          [(ngModel)]="value"
          (focus)="onFocus.emit()"
          (keypress)="onKeyPress($event)"
          (keydown)="onKeyDown($event)"
          (keyup)="onKeyUp($event)"
          (paste)="onPaste($event)"
        />
      </form>
    </div>
  </div>
</div>
