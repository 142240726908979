<div header class="flight-search-result__header" *ngIf="isPopover">
  <span i18n="@@global.flight-alert" class="pt-1 d-block label">{{
    header || defaultHeader
  }}</span>
</div>
<span class="icon-close" (click)="onClose()" *ngIf="isPopover"
  ><svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z"
      fill="#679EC6"
    />
  </svg>
</span>

<div class="pt-2" *ngIf="isAllotment && allotmentDetails">
  <kt-booking-details-item
    #bookingDetailsItem
    [advancedBookingDetails]="allotmentDetails"
    [parentCommodities]="parentCommodities"
    [airlines]="airlines"
    [disableUnitSelector]="disableUnitSelector"
    [regionAirports]="regionAirports"
    [hideFavorite]="true"
    [readOnly]="true"
    [noUpdateOnBlur]="noUpdateOnBlur"
    [isAllotmentModification]="isAllotmentModification"
    [popup]="true"
  ></kt-booking-details-item>
</div>

<div class="flight-message">
  <span>
    <span class="info">{{ airlineName }}</span>
    <span i18n="@@global.route-options">
      route options for the next 7 departure days after&nbsp;</span
    >
    <span>{{ sliderFirstDep || departureDate | date: "E MMM d, YYYY" }} :</span>
  </span>
</div>
<div
  class="day-label-container"
  [class.d-flex]="isShipmentDetailsPage"
  [class.align-items-center]="isShipmentDetailsPage"
>
  <ng-container *ngIf="searchResults?.length">
    <span
      *ngIf="prevConditions"
      class="slider-button slider-button__left"
      [class.new-search-slider-button]="isShipmentDetailsPage"
      (click)="handleChevronClick('prev')"
    >
      <svg
        width="11"
        height="13"
        viewBox="0 0 11 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.381935 4.87586C0.438493 4.81791 0.652078 4.57012 0.851007 4.36631C2.01736 3.08443 5.05884 0.987353 6.65029 0.347882C6.89199 0.245115 7.50301 0.0277019 7.82938 0.0140156C8.14209 0.0143163 8.44012 0.0866033 8.72444 0.232877C9.07885 0.437218 9.363 0.75849 9.51851 1.13764C9.6186 1.40074 9.77371 2.18789 9.7737 2.20189C9.92874 3.06304 10.0131 4.46212 10.0116 6.00812C10.0102 7.48112 9.9232 8.82303 9.79474 9.69691C9.78012 9.7109 9.62331 10.6887 9.4525 11.0236C9.1392 11.6353 8.52802 12.0147 7.87435 12.0141L7.81784 12.014C7.39214 11.9986 6.49723 11.6177 6.49724 11.6037C4.99275 10.9603 2.02535 8.96044 0.833255 7.63229C0.833255 7.63229 0.497489 7.29097 0.351567 7.07783C0.123902 6.77161 0.0112609 6.3925 0.0116253 6.0135C0.0120319 5.5905 0.140028 5.19663 0.381935 4.87586Z"
          fill="#5D97C2"
        />
      </svg>
    </span>
    <div
      class="day-label"
      [class.new-search-popup-label]="isShipmentDetailsPage && !isAllotment"
      [class.search-popup-label]="isAllotment"
    >
      <ng-container *ngFor="let day of selectedFlightDays; let i = index">
        <span>{{ day | date: "E, MMM d" }}</span>
      </ng-container>
    </div>

    <span
      *ngIf="originalFlightDateMax < maxArrivalDate"
      [attr.data-after-content]="'NEXT ' + getFlightDaysToView + ' DAYS'"
      class="slider-button slider-button__right"
      [class.new-search-slider-button-next]="isShipmentDetailsPage"
      [class.new-search-slider-button]="isShipmentDetailsPage"
      (click)="handleChevronClick('next')"
    >
      <svg
        width="10"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.63078 7.131C9.57428 7.189 9.36094 7.437 9.1622 7.641C7.99708 8.924 4.95762 11.024 3.36678 11.665C3.12518 11.768 2.51437 11.986 2.18802 12C1.8753 12 1.5772 11.928 1.29274 11.782C0.938139 11.578 0.653678 11.257 0.497808 10.878C0.397467 10.615 0.241598 9.828 0.241598 9.814C0.0857282 8.953 0 7.554 0 6.008C0 4.535 0.0857282 3.193 0.213346 2.319C0.227959 2.305 0.383829 1.327 0.554311 0.992C0.867024 0.38 1.47784 0 2.13151 0H2.18802C2.61374 0.015 3.50901 0.395 3.50901 0.409C5.01413 1.051 7.98344 3.048 9.17681 4.375C9.17681 4.375 9.51291 4.716 9.65904 4.929C9.88699 5.235 10 5.614 10 5.993C10 6.416 9.87238 6.81 9.63078 7.131Z"
          fill="#5D97C2"
        />
      </svg>
    </span>
  </ng-container>
  <cai-datepicker
    [ngModel]="departureDate"
    [minDate]="minDate"
    (dateChanged)="handleDepartureDateChange($event)"
    i18n-label="@@global.departure"
    label="Departure"
    *ngIf="isShipmentDetailsPage && !isAllotment"
  >
  </cai-datepicker>
</div>

<div *ngIf="!searchResults?.length" class="no-options">
  <span class="highlight">NO</span> options available for the next 7 days
</div>
<kt-smart-view-table
  *ngIf="searchResults?.length"
  [isBatchLoading]="false"
  [allFlights]="searchResults"
  [sliderFirstDep]="sliderFirstDep"
  [flightDays]="selectedFlightDays.length"
  [sliderLastArr]="sliderLastArr"
  [popover]="true"
  [isNewSearch]="true"
  [measurementUnit]="measurementUnit"
  [selectedFlight]="selectedFlight"
  [isShipmentDetailsPage]="isShipmentDetailsPage"
  [flightIndex]="flightIndex"
  (flightSelected)="onFlightSelect($event)"
  (onRateChange)="onRateChange.emit($event)"
  (onMoveFlightDaysView)="moveFlightDaysView.emit($event)"
></kt-smart-view-table>
<span
  class="error-msg"
  i18n="@@advanced-search-result.select-flight-alert"
  *ngIf="showErrorMessage"
  >*Flight must be selected to proceed</span
>
<div footer class="flight-search__footer pb-2" *ngIf="isPopover">
  <span i18n="@@global.cancel" class="cancel-button" (click)="onClose()"
    >cancel</span
  >
  <button
    class="save-button"
    i18n="@@global.save"
    [class.active]="selectedFlight || isFormGroupDirty"
    (click)="handleSubmit()"
  >
    SAVE
  </button>
</div>
