// Angular

import moment from "moment";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  "name": "timeDuration",
})
export class TimeDurationPipe implements PipeTransform {
  transform (startDate: any, endDate: any) {
    const duration = moment.duration(moment(endDate).diff(moment(startDate))),

     days = Math.floor(duration.asDays());
    duration.subtract(moment.duration(days, "days"));

    const hours = duration.hours();
    duration.subtract(moment.duration(hours, "hours"));

    const minutes = duration.minutes();
    duration.subtract(moment.duration(minutes, "minutes"));
    if (days === 0 && hours === 0 && minutes === 0) {
      return "-";
    }
    return `${days !== 0 ? days + "d" : ""} ${hours !== 0 ? hours + "h" : ""} ${
      minutes !== 0 ? minutes + "min" : ""
    }`;
  }
}
