import { AdminUser } from "./admin-user.model";

export class Feature {
  featureName: string;
  free: boolean;
  trial: boolean;
  pro: boolean;
  activatedUsers: AdminUser[];
  activatedCount: number;

  constructor (featureName?: string) {
    this.featureName = featureName;
    this.activatedUsers = [];
    this.activatedCount = 0;
    this.free = false;
    this.trial = false;
    this.pro = false;
  }
}
