import { Pipe, PipeTransform } from "@angular/core";
import {
  ICommodity,
  LoadTypeEnum,
  MeasurementSystemEnum,
  WeightTypeEnum,
} from "@cai-services";
import { CaiNumberFormatterPipe } from "../../../../pipes/number-formatter.pipe";
import { MeasurementUnits } from "../../../_models/measurement-system.model";
import { QuoteItem } from "../../../_models/quote-item.model";
import { MeasurementUtil } from "../../crud/utils/measurement.util";

@Pipe({
  "name": "shipmentDetail",
})
export class ShipmentDetail implements PipeTransform {
  constructor (private nf: CaiNumberFormatterPipe) {}

  transform (
    quoteItems: any,
    commodities: ICommodity[],
    measurementSystem = MeasurementSystemEnum.METRIC,
    args?: any,
    isHideCommodities?: boolean,
    useAlternativeFormat?: boolean,
  ): string {
    if (!quoteItems || !quoteItems.length) {
      return "";
    }
    if (args === "1") {
      return this.getOneShipmentDetails(
        commodities,
        quoteItems[0],
        measurementSystem,
        useAlternativeFormat,
      );
    } else {
      return this.getListShipmentDetails(
        commodities,
        quoteItems,
        measurementSystem,
        isHideCommodities,
        useAlternativeFormat,
      );
    }
  }

  private getListShipmentDetails (
    commodities: ICommodity[],
    quoteItems: QuoteItem[],
    measurementSystem: MeasurementSystemEnum,
    isHideCommodities: boolean,
    useAlternativeFormat: boolean,
  ): string {
    // Total volume & weight
    const totalVolume = quoteItems.reduce((acc, curr) => {
      let volume = 0;
      if (curr.loadType === LoadTypeEnum.BUP) {
        volume = curr.volume;
      } else {
        volume = MeasurementUtil.calculateVolume(
          curr.dimensionWidth,
          curr.dimensionLength,
          curr.dimensionHeight,
          curr.numOfItems,
          measurementSystem,
        );
      }
      acc += volume;
      return acc;
    }, 0),
     totalWeight = quoteItems.reduce((acc, curr) => {
      if (curr.weightType === WeightTypeEnum.PER_ITEM) {
        acc += curr.weight * curr.numOfItems;
      } else {
        acc += curr.weight;
      }
      return acc;
    }, 0),
     typeOfProduct = this.getProduct(commodities, quoteItems[0]),

     totalPieces = quoteItems.reduce((acc, curr) => {
      acc += curr.numOfItems;
      return acc;
    }, 0),

     nbPiece = `${totalPieces} ${totalPieces > 1 ? "pcs" : "pc"}`;

    if (useAlternativeFormat) {
      return `${this.nf.transform(totalWeight)}${
        MeasurementUnits[measurementSystem].WEIGHT.display
      } | ${this.nf.transform(totalVolume)}${
        MeasurementUnits[measurementSystem].VOLUME.display
      } | ${nbPiece} ${!isHideCommodities ? `(${typeOfProduct})` : ""}`;
    } else {
      return `${this.nf.transform(totalWeight)} ${
        MeasurementUnits[measurementSystem].WEIGHT.display
      }, ${this.nf.transform(totalVolume)} ${
        MeasurementUnits[measurementSystem].VOLUME.display
      }, ${nbPiece} ${!isHideCommodities ? `(${typeOfProduct})` : ""}`;
    }
  }

  private getOneShipmentDetails (
    commodities: ICommodity[],
    quoteItem: QuoteItem,
    measurementSystem: MeasurementSystemEnum,
    useAlternativeFormat: boolean,
  ): string {
    const nbPiece = `${quoteItem.numOfItems} ${
      quoteItem.numOfItems > 1 ? "pcs" : "pc"
    }`;
    let totalVolume = 0;
    if (quoteItem.loadType === LoadTypeEnum.BUP) {
      totalVolume = quoteItem.volume;
    } else {
      totalVolume = MeasurementUtil.calculateVolume(
        quoteItem.dimensionWidth,
        quoteItem.dimensionLength,
        quoteItem.dimensionHeight,
        quoteItem.numOfItems,
        measurementSystem,
      );
    }
    const totalWeight =
      quoteItem.weightType === WeightTypeEnum.PER_ITEM
        ? quoteItem.weight * quoteItem.numOfItems
        : quoteItem.weight,
     typeOfProduct = this.getProduct(commodities, quoteItem);

    if (useAlternativeFormat) {
      return `${this.nf.transform(totalWeight)}${
        MeasurementUnits[measurementSystem].WEIGHT.display
      } | ${this.nf.transform(totalVolume)}${
        MeasurementUnits[measurementSystem].VOLUME.display
      } | ${nbPiece} (${typeOfProduct})`;
    } else {
      return `${this.nf.transform(totalWeight)} ${
        MeasurementUnits[measurementSystem].WEIGHT.display
      }, ${this.nf.transform(totalVolume)} ${
        MeasurementUnits[measurementSystem].VOLUME.display
      }, ${nbPiece} (${typeOfProduct})`;
    }
  }

  private getProduct (commodities: ICommodity[], quoteItem: QuoteItem): string {
    if (this.getCommodity(commodities)) {
      return this.getCommodity(commodities)?.name;
    } else if (
      quoteItem.typeOfProduct !== null &&
      quoteItem.typeOfProduct?.productCode
    ) {
      return quoteItem?.typeOfProduct?.productCode;
    } else {
      return "-";
    }
  }

  private getCommodity (commodities: ICommodity[]): ICommodity {
    if (commodities && commodities.length > 0) {
      return commodities.find((commodity) => commodity.level === 1);
    }
    return null;
  }
}
