<div class="wrapper" [class.shipper-form-autocomplete]="isShipperForm">
  <div class="dropdown">
    <div class="icon">
      <ng-content select="[icon]"></ng-content>
    </div>
    <input
      #input
      class="select-trigger"
      autoComplete="off"
      [class.invalid]="showError || isInvalid"
      [class.viewonly]="viewOnly"
      [class.show-placeholder]="hideLabel"
      [class.shipperForm]="isShipperForm"
      [placeholder]="placeholder"
      [(ngModel)]="text"
      (keyup)="onKeyUp($event)"
      (blur)="blur(input.value)"
      (focus)="handleFocus($event, dropdown, input)"
      [disabled]="disableState"
      (ngModelChange)="open($event, dropdown, input)"
    />
    <label [class.shipper-label]="isShipperForm" *ngIf="!viewOnly && label">{{
      label
    }}</label>
    <ng-template #dropdown>
      <div class="cai-autocomplete select-menu" [ngClass]="dropdownMenuClass">
        <cdk-virtual-scroll-viewport
          class="select-menu-inner"
          [itemSize]="25"
          [style.height.px]="calculateDropdownHeightPx()"
        >
          <div *cdkVirtualFor="let group of groupedFilteredOptions">
            <div
              *ngIf="group.name && group.name.length > 0"
              class="group select-item"
              [class.selectable]="selectableGroup"
              (mousedown)="selectGroup(group)"
            >
              <img
                class="select-item-icon"
                alt="group icon"
                *ngIf="group.iconUrl"
                [src]="group.iconUrl"
                loading="lazy"
              />
              <span>{{ group.name }}</span>
            </div>
            <div
              *ngFor="let option of group.options"
              class="select-item selectable"
              [class.indented]="group.name && group.name.length > 0"
              (mousedown)="select(option)"
            >
              <img
                class="select-item-icon"
                alt="option icon"
                *ngIf="option.iconUrl"
                [src]="option.iconUrl"
                loading="lazy"
              />
              <span>{{ option.label }}</span>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </ng-template>
    <span icon class="dropdown-icon" *ngIf="isShipperForm && !disableState">
      <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.00787 9C7.36059 9 11.6848 2.34803 10.9072 1.59336C10.1296 0.838686 1.96048 0.766564 1.10857 1.59336C0.256657 2.4212 4.65623 9 6.00787 9Z"
          fill="#679EC6"
          stroke="#679EC6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  </div>
  <span
    *ngIf="
      !viewOnly && showError && errorMessage && !hideLabel && !isShipperForm
    "
    class="error-msg"
  >
    {{ errorMessage }}
  </span>
</div>
