<div class="kt-portlet">
  <div class="kt-portlet__head kt-portlet__head__custom">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">Copy Banner</h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>
  <div
    class="kt-form kt-form--label-align-right kt-form--group-seperator-dashed"
  >
    <div class="kt-portlet__body">
      <p>Create a new banner from the selected banner.</p>
      <mat-form-field appearance="fill">
        <mat-label>New Banner ID</mat-label>
        <input matInput [(ngModel)]="data.newBannerId" />
      </mat-form-field>
    </div>
    <div
      class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right"
    >
      <div class="kt-form__actions kt-form__actions--solid">
        <div class="row">
          <div class="col-lg-12">
            <button
              type="button"
              mat-raised-button
              color="primary"
              [disabled]="data.newBannerId.length === 0"
              [mat-dialog-close]="data.newBannerId"
            >
              Create Banner</button
            >&nbsp;
            <button type="button" mat-raised-button (click)="onNoClick()">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
