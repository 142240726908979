// Angular
import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  "name": "timeFromStartDiffer",
})
export class TimeFromStartDifferPipe implements PipeTransform {
  transform (date: string, dayDiff: number): number {
    const totalDayDiffInMinutes = 24 * 60 * dayDiff,
     [hours, minutes] = date.split("T")[1]?.split(":"),
     totalMinutes = Number(hours) * 60 + Number(minutes);

    return (totalMinutes / totalDayDiffInMinutes) * 100;
  }
}
