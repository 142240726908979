import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class AwbValidators {
  static format: any =
    (full = false): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const text = control.value || "";

      if (/[a-zA-Z]/.test(text)) {
        return {
          "format": { "message": "Enter a valid AWB number" },
        };
      }

      if (text) {
        if (text.indexOf("-") > -1) {
          const prefix = text.split("-")[0],
            number = text.split("-")[1];
          if (prefix.length !== 3 && !full) {
            return {
              "format": { "message": "AWB prefix must be 3 digits" },
            };
          }
          if (number.length !== 8 && number?.length && !full) {
            return {
              "format": { "message": "AWB must be 8 digits" },
            };
          }
        }
        if (text.replace(/[^0-9]/g, "").length === 4 && !full) {
          return {
            "format": { "message": "AWB must be 8 digits" },
          };
        }
        if (
          (!full &&
            text.replace(/[^0-9]/g, "").length !== 11 &&
            text.replace(/[^0-9]/g, "").length !== 3) ||
          (full && text.replace(/[^0-9]/g, "").length !== 11)
        ) {
          return {
            "format": { "message": "AWB number must be 11 digits" },
          };
        }
      }
      return null;
    };

  static valid: any =
    (): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const text = control?.value
          ? control.value.substring(control.value.length - 8)
          : "",
        awbNumber = text.substring(0, ("" + text).length - 1),
        verificationDigit = text.substring(
          ("" + text).length - 1,
          ("" + text).length,
        );
      if (
        Number(awbNumber) % 7 !== Number(verificationDigit) &&
        awbNumber?.length === 7
      ) {
        return {
          "format": { "message": "AWB is invalid (verification digit incorrect)" },
        };
      }
      return null;
    };
}
