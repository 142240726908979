export const subscribeNotifications = `subscription updateNotifications(
  $user: String!
) {
  Notification(user: $user) {
    title
    description
    type
    link
    user
    timestamp
    read
    seen
    parameters
    push
  }
}
`;
