<div
  class="office-selector"
  ngbDropdown
  #officeDropdown="ngbDropdown"
  autoClose="outside"
  placement="bottom-start"
  (mouseenter)="appearance === 'expanded' && officeDropdown.open()"
  (mouseleave)="appearance === 'expanded' && officeDropdown.close()"
>
  <div *ngIf="appearance === 'expanded'" class="office-selector__value">
    <div *ngIf="showIcon" class="svg-office">
      <kt-svg-office></kt-svg-office>
    </div>
    <div class="pr-4">{{ getValue(selectedOfficeView?.countryName) }}</div>
    <div>
      {{
        showIataCass
          ? getValue(selectedOfficeView?.iata) +
            "-" +
            getValue(selectedOfficeView?.cass)
          : getValue(selectedOfficeView?.countryCode)
      }}
    </div>
  </div>
  <button *ngIf="appearance === 'icon'" ngbDropdownToggle>
    <kt-svg-office></kt-svg-office>
  </button>
  <div
    ngbDropdownMenu
    class="office-selector__popup p-0 dropdown-menu-anim"
    style="z-index: 999"
  >
    <div class="popup-wrapper">
      <div class="popup-header">
        <div class="pr-3">
          <kt-svg-office></kt-svg-office>
        </div>
        <div
          *ngIf="type === 'search'"
          i18n="@@global.text-select-one-office-to-see-rates"
        >
          Select one of the offices below to see contract rates for selected
          route:
        </div>
        <div
          *ngIf="type === 'searchOption'"
          i18n="@@global.text-select-one-office-to-see-rates"
        >
          You can switch to your other CargoWALLET offices below :
        </div>
        <div
          *ngIf="type === 'rates'"
          i18n="@@global.text-select-one-office-to-see-rate-sheet"
        >
          Select one of the offices below to see associated rate sheet:
        </div>
      </div>
      <div class="popup-body">
        <div
          class="option"
          *ngFor="let item of companyOfficesView"
          (click)="onSelectOffice(item?.value)"
          [class.selected]="item?.value === selectedOfficeView?.value"
        >
          <div class="icon-wrapper">
            <div
              [class.visible]="item?.value !== selectedOfficeView?.value"
              class="icon-svg"
              [ngStyle]="{
                mask: 'url(assets/media/icons/search-page/map-marker.svg)',
                '-webkit-mask':
                  'url(assets/media/icons/search-page/map-marker.svg)',
              }"
            ></div>
            <div
              class="icon-img"
              [class.visible]="item?.value === selectedOfficeView?.value"
            >
              <img
                src="/assets/media/icons/search-page/map-marker-solid.svg"
                alt=""
              />
            </div>
          </div>
          <div class="value">
            <div class="country-name">
              {{ getValue(item.airportCode) }}
            </div>
            <div class="country-code">
              {{ getValue(item.countryCode) }}
            </div>
            <div class="iata">
              {{ getValue(item.iata) }}
            </div>
            <span>-</span>
            <div class="cass">
              {{ getValue(item.cass) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
