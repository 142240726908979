import { Component, Input } from "@angular/core";

@Component({
  "selector": "kt-svg-flight",
  "templateUrl": "./svg-flight.component.html",
})
export class SvgFlightComponent {
  @Input() startColor = "";
  @Input() endColor = "";

  get startClr (): string {
    return this.startColor ? this.startColor : "#2DCE89";
  }
  get endClr (): string {
    return this.endColor ? this.endColor : "#49B2FF";
  }
}
