
import { OfficeAdditionalInfo } from "./office-additional-info.model";

export class Provider {
  name: string;
  additionalInfos: OfficeAdditionalInfo[];
  additionalInfosSize: number;
  reviewCount: number;

  constructor (name?: string) {
    this.name = name;
    this.additionalInfos = [];
    this.additionalInfosSize = 0;
    this.reviewCount = 0;
  }
}
