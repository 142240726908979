<div class="dangerous-good-table" [class.d-none]="!visible">
  <table aria-describedby="dangerous-good-table">
    <thead>
      <tr>
        <ng-container *ngFor="let column of tableColumns">
          <th scope="col" *ngIf="!column.isCAO || (column.isCAO && isCAO)">
            <div
              class="col"
              *ngIf="
                !isCAO ||
                (column.name !== 'packagingInstruction' &&
                  column.name !== 'totalNet' &&
                  column.name !== 'uom')
              "
              [style.width]="column.width"
              [ngClass]="column.customClass"
              [class.cao]="column.isCAO"
              [class.cao-label]="column.isCAOLabelHolder"
              [matTooltip]="column.tooltip"
              [matTooltipPosition]="'below'"
            >
              <span class="label" [class.required]="column.showRequired">{{
                column.title
              }}</span>
            </div>
          </th>
        </ng-container>
        <th scope="col" class="action"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container
        *ngFor="let dangerousGood of dangerousGoods; let index = index"
      >
        <tr [attr.data-before]="(index + 1).toString() + '.'">
          <ng-container *ngFor="let column of tableColumns">
            <ng-container [ngSwitch]="column.name">
              <td *ngSwitchCase="'unid'">
                <div
                  class="col ml-0"
                  [style.width]="column.width"
                  [class.un-disabled]="dangerousGood.isNewRow"
                >
                  <kt-unid-selector
                    [selected]="dangerousGood.searchResult"
                    [disabled]="!dangerousGood.searchResult"
                    [options]="dangerousGoodResults"
                    [blurOnSelect]="true"
                    [(value)]="dangerousGood[column.name]"
                    [height]="isShipmentDetailsPage ? '35vh' : ''"
                    [invalid]="
                      isInvalidField(column.name, dangerousGood, index)
                    "
                    (valueChange)="searchDangerousGoods(dangerousGood, $event)"
                    (onSelect)="
                      selectDangerousGoodFromResults(dangerousGood, $event)
                    "
                    (onFocus)="focusRow(dangerousGood, index)"
                    (onLostFocus)="unfocusRow(dangerousGood)"
                    (onKeyDownEvent)="touchRow(dangerousGood)"
                  ></kt-unid-selector>
                </div>
              </td>
              <td *ngSwitchCase="'goodClass'">
                <div
                  class="col"
                  [style.width]="column.width"
                  [class.disabled]="dangerousGood.isNewRow"
                >
                  <cai-input-select
                    [editable]="true"
                    [invalid]="
                      isInvalidField(column.name, dangerousGood, index)
                    "
                    [disabled]="dangerousGood.isNewRow"
                    [options]="dangerousGood.goodClassOptions"
                    [enableManualAdd]="true"
                    [type]="inputTypesEnum.ALPHANUMERIC"
                    [(value)]="dangerousGood[column.name]"
                    (onFocus)="focusRow(dangerousGood, index)"
                    (onLostFocus)="unfocusRow(dangerousGood)"
                    (onKeyDownEvent)="touchRow(dangerousGood)"
                  ></cai-input-select>
                </div>
              </td>
              <td *ngSwitchCase="'packagingGroup'">
                <div
                  class="col"
                  [style.width]="column.width"
                  [class.disabled]="dangerousGood.isNewRow"
                >
                  <cai-input-select
                    [editable]="true"
                    [invalid]="
                      isInvalidField(column.name, dangerousGood, index)
                    "
                    [disabled]="dangerousGood.isNewRow"
                    [options]="dangerousGood.packagingGroupOptions"
                    [(value)]="dangerousGood[column.name]"
                    [type]="inputTypesEnum.ALPHANUMERIC"
                    [enableManualAdd]="
                      dangerousGood.packagingGroupOptions.length > 0
                    "
                    (valueChange)="changePackagingGroup(dangerousGood)"
                    (onFocus)="focusRow(dangerousGood, index)"
                    (onLostFocus)="unfocusRow(dangerousGood)"
                    (onKeyDownEvent)="touchRow(dangerousGood)"
                  ></cai-input-select>
                </div>
              </td>
              <td *ngSwitchCase="'packagingInstruction'">
                <div
                  class="col"
                  *ngIf="!isCAO"
                  [style.width]="column.width"
                  [class.disabled]="!isNonCAOFieldsEnabled(dangerousGood)"
                >
                  <cai-input-select
                    #packagingInstructionInput
                    [mandatoryDisabled]="!isNonCAOFieldsEnabled(dangerousGood)"
                    [options]="dangerousGood.packagingInstructionOptions"
                    [(editable)]="dangerousGood.isPackagingInstructionEditable"
                    [enableManualAdd]="true"
                    [type]="inputTypesEnum.ALPHANUMERIC"
                    [invalid]="
                      isInvalidField(column.name, dangerousGood, index)
                    "
                    [(value)]="dangerousGood[column.name]"
                    (valueChange)="changePackagingInstruction(dangerousGood)"
                    (onFocus)="focusRow(dangerousGood, index)"
                    (onLostFocus)="unfocusRow(dangerousGood)"
                    (onKeyDownEvent)="touchRow(dangerousGood, true)"
                    (click)="
                      enableNonCAOFields(
                        dangerousGood,
                        packagingInstructionInput
                      )
                    "
                  ></cai-input-select>
                </div>
              </td>
              <ng-container *ngSwitchCase="'totalNet'">
                <td [attr.colspan]="dangerousGood[column.name] === -1 ? 2 : 1">
                  <div
                    class="col mr-0 pl-2"
                    *ngIf="!isCAO"
                    [class.disabled]="!isNonCAOFieldsEnabled(dangerousGood)"
                    [ngClass]="
                      dangerousGood[column.name] === -1
                        ? ['rounded', 'mr-1']
                        : [' rounded-right-0', 'mr-0']
                    "
                    [style.width]="
                      dangerousGood[column.name] === -1 ? '149px' : '95px'
                    "
                  >
                    <cai-input
                      #totalNetInput
                      [mandatoryDisabled]="
                        !isNonCAOFieldsEnabled(dangerousGood)
                      "
                      [type]="inputTypesEnum.DECIMAL"
                      [decimalPlace]="null"
                      [name]="column.name"
                      [fontSize]="16"
                      [invalid]="
                        isInvalidField(column.name, dangerousGood, index)
                      "
                      [(value)]="dangerousGood[column.name]"
                      (onFocus)="focusRow(dangerousGood, index)"
                      (onLostFocus)="unfocusRow(dangerousGood)"
                      (onKeyDownEvent)="touchRow(dangerousGood, true)"
                      (valueChange)="updateDangerousGoods()"
                      (click)="enableNonCAOFields(dangerousGood, totalNetInput)"
                    ></cai-input>
                  </div></td
              ></ng-container>
              <ng-container *ngSwitchCase="'uom'">
                <td>
                  <div
                    *ngIf="!isCAO"
                    class="col ml-0 rounded-left-0"
                    [style.width]="column.width"
                    [class.disabled]="!isNonCAOFieldsEnabled(dangerousGood)"
                  >
                    <cai-input-select
                      *ngIf="!dangerousGood.isNewRow"
                      #uomInput
                      [mandatoryDisabled]="
                        !isNonCAOFieldsEnabled(dangerousGood)
                      "
                      [readonly]="
                        isFieldReadOnly(dangerousGood, 'uomOptions', 2)
                      "
                      [options]="dangerousGood.uomOptions"
                      [alignment]="
                        dangerousGood.uomOptions?.length > 1 ? 'left' : 'center'
                      "
                      [(value)]="dangerousGood[column.name]"
                      (onFocus)="focusRow(dangerousGood, index)"
                      (onLostFocus)="unfocusRow(dangerousGood)"
                      (onKeyDownEvent)="touchRow(dangerousGood)"
                      (click)="enableNonCAOFields(dangerousGood, uomInput)"
                    ></cai-input-select>
                  </div>
                </td>
              </ng-container>
              <ng-container *ngIf="column.isCAO && isCAO">
                <td *ngSwitchCase="'caoPackagingInstruction'">
                  <div
                    class="col px-2"
                    [style.width]="column.width"
                    [class.disabled]="!isCAOFieldsEnabled(dangerousGood)"
                  >
                    <cai-input
                      #caoPackagingInstructionInput
                      [mandatoryDisabled]="!isCAOFieldsEnabled(dangerousGood)"
                      [type]="inputTypesEnum.ALPHANUMERIC"
                      [name]="column.name"
                      [fontSize]="16"
                      [invalid]="
                        isInvalidField(column.name, dangerousGood, index)
                      "
                      [(value)]="dangerousGood[column.name]"
                      (onFocus)="focusRow(dangerousGood, index)"
                      (onLostFocus)="unfocusRow(dangerousGood)"
                      (onKeyDownEvent)="touchRow(dangerousGood, true)"
                      (valueChange)="updateDangerousGoods()"
                      (click)="
                        enableCAOFields(
                          dangerousGood,
                          caoPackagingInstructionInput
                        )
                      "
                    ></cai-input>
                  </div>
                </td>
                <ng-container *ngSwitchCase="'caoTotalNet'">
                  <td
                    [attr.colspan]="dangerousGood[column.name] === -1 ? 2 : 1"
                  >
                    <div
                      class="col pl-2"
                      [class.disabled]="!isCAOFieldsEnabled(dangerousGood)"
                      [ngClass]="
                        dangerousGood[column.name] === -1
                          ? ['rounded', 'mr-1']
                          : [' rounded-right-0', 'mr-0']
                      "
                      [style.width]="
                        dangerousGood[column.name] === -1 ? '149px' : '95px'
                      "
                    >
                      <cai-input
                        #caoTotalNetInput
                        [mandatoryDisabled]="!isCAOFieldsEnabled(dangerousGood)"
                        [type]="inputTypesEnum.DECIMAL"
                        [decimalPlace]="null"
                        [name]="column.name"
                        [fontSize]="16"
                        [invalid]="
                          isInvalidField(column.name, dangerousGood, index)
                        "
                        [(value)]="dangerousGood[column.name]"
                        (onFocus)="focusRow(dangerousGood, index)"
                        (onLostFocus)="unfocusRow(dangerousGood)"
                        (onKeyDownEvent)="touchRow(dangerousGood, true)"
                        (valueChange)="updateDangerousGoods()"
                        (click)="
                          enableCAOFields(dangerousGood, caoTotalNetInput)
                        "
                      ></cai-input>
                    </div></td
                ></ng-container>
                <ng-container *ngSwitchCase="'caoUom'">
                  <td>
                    <div
                      class="col ml-0 rounded-left-0"
                      [style.width]="column.width"
                      [class.disabled]="!isCAOFieldsEnabled(dangerousGood)"
                    >
                      <cai-input-select
                        #caoUomInput
                        [mandatoryDisabled]="!isCAOFieldsEnabled(dangerousGood)"
                        [options]="dangerousGood.caoUomOptions"
                        [(value)]="dangerousGood[column.name]"
                        (onFocus)="focusRow(dangerousGood, index)"
                        (onLostFocus)="unfocusRow(dangerousGood)"
                        (onKeyDownEvent)="touchRow(dangerousGood)"
                        (click)="enableCAOFields(dangerousGood, caoUomInput)"
                      ></cai-input-select>
                    </div>
                  </td>
                </ng-container>
              </ng-container>
              <td *ngSwitchCase="'shc'">
                <div
                  class="col mr-0"
                  [style.width]="column.width"
                  [class.disabled]="dangerousGood.isNewRow"
                  [class.readonly]="
                    !dangerousGood.isNewRow || dangerousGood[column.name]
                  "
                >
                  <cai-input-select
                    [invalid]="
                      isInvalidField(column.name, dangerousGood, index)
                    "
                    [textTransform]="'uppercase'"
                    [disabled]="dangerousGood.isNewRow"
                    [options]="dangerousGood.shcOptions"
                    [enableManualAdd]="true"
                    [multiselect]="true"
                    [type]="inputTypesEnum.ALPHABET"
                    [(value)]="dangerousGood[column.name]"
                    (onFocus)="focusRow(dangerousGood, index)"
                    (onLostFocus)="formatSHC(dangerousGood)"
                    (onKeyDownEvent)="touchRow(dangerousGood)"
                  ></cai-input-select>
                </div>
              </td>
            </ng-container>
          </ng-container>
          <td class="action">
            <span
              class="btn-delete"
              *ngIf="showDelete(dangerousGood, index)"
              (click)="deleteRow(index)"
            ></span>
          </td>
        </tr>
        <tr
          *ngIf="isInvalidRow(dangerousGood, index)"
          colspan="4"
          class="error-row"
        >
          <div class="error-container">
            <ng-container *ngFor="let error of dangerousGood.errors">
              <span
                *ngIf="error.message"
                [title]="error.message"
                class="error-item"
              >
                {{ error.message }}
              </span>
            </ng-container>
          </div>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
