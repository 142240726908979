<div class="section-currency section-topup p-3 bg-white mt-4">
  <h5 class="section-wallet-heading" i18n="@@topup-amount">STEP 3 : AMOUNT</h5>
  <div
    class="d-flex align-items-center mt-3 mb-4 pr-0 pr-sm-5 topup-amount-wrapper position-relative"
  >
    <div
      class="currency-flag currency-flag-img-size"
      [ngClass]="
        'currency-flag-' + getFlagName(topupData?.currency)?.toLowerCase()
      "
    ></div>
    <input
      type="text"
      placeholder="0"
      id="topupAmount"
      (keydown)="onKeyPressAvoidDot($event)"
      (blur)="onKeyPressAvoidDot($event, true)"
      (change)="onKeyPressAvoidDot($event, true)"
      [(ngModel)]="topupData.amount"
      [ngClass]="{ invalid: isInvalidAmount }"
      class="form-control custom-control py-0"
    />
    <div class="top-up-fees-wrapper ml-1 ml-sm-3">
      <div class="top-up-wrapper">
        <label class="topup-input-wrapper readonly" for="topupfees">
          <div class="icon"></div>
          <input
            autocomplete="off"
            [style.min-width]="taxFieldMinWidth"
            class="readonly ng-untouched ng-pristine"
            id="topupfees"
            placeholder="TOP UP FEES"
            value="{{ getCalcPaymentFee ? getCalcPaymentFee : 0 }}"
            readonly="true"
            disabled="true"
          /><span class="unit">{{ topupData?.currency }}</span
          ><label
            class="input-label always-float"
            i18n-label="@@global.top-up-fees-label"
            >TOP UP FEES</label
          ></label
        >
      </div>
    </div>
  </div>
  <p
    i18n="@@topup-amount"
    class="amount-error"
    *ngIf="replacedValue < minAmount && topupData?.amount !== ''"
  >
    Amount to be more than {{ minAmount | number: "1.0-2" }}
    {{ topupData.currency }}
  </p>
  <p
    i18n="@@topup-amount"
    class="amount-error"
    *ngIf="replacedValue > maxAmount && topupData?.amount !== ''"
  >
    Amount to be less than {{ maxAmount | number: "1.0-2" }}
    {{ topupData.currency }}
  </p>
  <div>
    <div
      class="tooltip-content__icon custom-position-wallet position-relative pl-5"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.53247 1.5C5.07023 1.5 1.5 4.98091 1.5 9.21774C1.5 13.4546 5.07023 16.9355 9.53247 16.9355C13.9947 16.9355 17.5649 13.4546 17.5649 9.21774C17.5649 4.98091 13.9947 1.5 9.53247 1.5ZM0 9.21774C0 4.10136 4.29387 0 9.53247 0C14.7711 0 19.0649 4.10136 19.0649 9.21774C19.0649 14.3341 14.7711 18.4355 9.53247 18.4355C4.29387 18.4355 0 14.3341 0 9.21774ZM9.53265 4.55435C10.0297 4.55435 10.4326 4.95729 10.4326 5.45435V10.1586C10.4326 10.6557 10.0297 11.0586 9.53265 11.0586C9.03559 11.0586 8.63265 10.6557 8.63265 10.1586V5.45435C8.63265 4.95729 9.03559 4.55435 9.53265 4.55435ZM9.71185 14.575C10.2398 14.575 10.6678 14.1624 10.6678 13.6533C10.6678 13.1443 10.2398 12.7317 9.71185 12.7317C9.18392 12.7317 8.75594 13.1443 8.75594 13.6533C8.75594 14.1624 9.18392 14.575 9.71185 14.575Z"
          fill="#FF2E3B"
        />
      </svg>
      <p i18n="@@topup-amount">
        If you wish to reflect the full amount in your prepayment wallet
        balance, please ensure to bear all bank charges of the payment by
        selecting charges to "OUR" (bank charges to be borne by remitter) when
        initiating the payment. Wallet balance will be updated with the received
        amount.
      </p>
    </div>
    <div
      class="tooltip-content__icon custom-position-wallet position-relative pl-5"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.53247 1.5C5.07023 1.5 1.5 4.98091 1.5 9.21774C1.5 13.4546 5.07023 16.9355 9.53247 16.9355C13.9947 16.9355 17.5649 13.4546 17.5649 9.21774C17.5649 4.98091 13.9947 1.5 9.53247 1.5ZM0 9.21774C0 4.10136 4.29387 0 9.53247 0C14.7711 0 19.0649 4.10136 19.0649 9.21774C19.0649 14.3341 14.7711 18.4355 9.53247 18.4355C4.29387 18.4355 0 14.3341 0 9.21774ZM9.53265 4.55435C10.0297 4.55435 10.4326 4.95729 10.4326 5.45435V10.1586C10.4326 10.6557 10.0297 11.0586 9.53265 11.0586C9.03559 11.0586 8.63265 10.6557 8.63265 10.1586V5.45435C8.63265 4.95729 9.03559 4.55435 9.53265 4.55435ZM9.71185 14.575C10.2398 14.575 10.6678 14.1624 10.6678 13.6533C10.6678 13.1443 10.2398 12.7317 9.71185 12.7317C9.18392 12.7317 8.75594 13.1443 8.75594 13.6533C8.75594 14.1624 9.18392 14.575 9.71185 14.575Z"
          fill="#FF2E3B"
        />
      </svg>
      <p i18n="@@topup-withdraw-msg">
        In case you no longer wish to use CargoWALLET, you can request a refund
        of your unused prepayment funds by contacting our customer service team
        at
        <a href="mailto:support+wallet@cargoai.co"
          >support+wallet&#64;cargoai.co</a
        >.
      </p>
    </div>
    <div
      *ngIf="isTazapayBankPayment"
      class="tooltip-content__icon custom-position-wallet position-relative pl-5"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.53247 1.5C5.07023 1.5 1.5 4.98091 1.5 9.21774C1.5 13.4546 5.07023 16.9355 9.53247 16.9355C13.9947 16.9355 17.5649 13.4546 17.5649 9.21774C17.5649 4.98091 13.9947 1.5 9.53247 1.5ZM0 9.21774C0 4.10136 4.29387 0 9.53247 0C14.7711 0 19.0649 4.10136 19.0649 9.21774C19.0649 14.3341 14.7711 18.4355 9.53247 18.4355C4.29387 18.4355 0 14.3341 0 9.21774ZM9.53265 4.55435C10.0297 4.55435 10.4326 4.95729 10.4326 5.45435V10.1586C10.4326 10.6557 10.0297 11.0586 9.53265 11.0586C9.03559 11.0586 8.63265 10.6557 8.63265 10.1586V5.45435C8.63265 4.95729 9.03559 4.55435 9.53265 4.55435ZM9.71185 14.575C10.2398 14.575 10.6678 14.1624 10.6678 13.6533C10.6678 13.1443 10.2398 12.7317 9.71185 12.7317C9.18392 12.7317 8.75594 13.1443 8.75594 13.6533C8.75594 14.1624 9.18392 14.575 9.71185 14.575Z"
          fill="#FF2E3B"
        />
      </svg>
      <p i18n="@@topup-reference-number-remark">
        Remember to save your reference number and mention it when making the
        bank transfer. This allows us and our payment partners to ensure the
        transaction is completed smoothly and linked to the correct CargoWALLET
        Account.
      </p>
    </div>
  </div>
</div>
