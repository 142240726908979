import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { CaiInputTypesEnum } from "../../enum/input-types.enum";

const INPUT_REGEX_NUMERIC = /[0-9]/g,
 INPUT_REGEX_DECIMAL = /[0-9.]/g,
 FORMAT_REGEX_NUMERIC = /^\d{0,10}?$/,
 FORMAT_REGEX_DECIMAL = /^\d{0,10}(\.\d{0,4})?$/;

@Component({
  "selector": "cai-input",
  "templateUrl": "./input.component.html",
  "styleUrls": ["./input.component.scss"],
})
export class CaiInput {
  @ViewChild("input") input: ElementRef;
  @Input() type: CaiInputTypesEnum;
  @Input() readonly: boolean;
  @Input() disabled: boolean;
  @Input() mandatoryDisabled: boolean;
  @Input() placeholder: string;
  @Input() inputColor: string;
  @Input() name: string;
  @Input() fontSize = 14;
  @Input() invalid: boolean;
  @Input() decimalPlace = 2;
  @Input() value: any;
  @Output() valueChange = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Output() onLostFocus = new EventEmitter();
  @Output() onKeyDownEvent = new EventEmitter();

  lastValue: any;

  onKeyPress ($event) {
    const key = $event.key,
     selectionStart = this.input.nativeElement.selectionStart,
     selectionEnd = this.input.nativeElement.selectionEnd,
     text = this.value != null ? this.value.toString() : "",

     value =
      text.substring(0, selectionStart) + key + text.substring(selectionEnd);
    if (!this.validateInput(value)) {
      $event.preventDefault();
    }
  }

  onKeyDown ($event) {
    const key = $event.keyCode || $event.charCode;
    if (key === 8 || key === 46) {
      this.lastValue = this.value != null ? this.value : null;
    } else {
      const char = $event.key,
       inputRegex =
        this.type === CaiInputTypesEnum.DECIMAL
          ? INPUT_REGEX_DECIMAL
          : INPUT_REGEX_NUMERIC,
       _inputRegex = new RegExp(inputRegex);
      if (_inputRegex.test(char)) {
        this.onKeyDownEvent.emit();
      }
    }
  }

  onKeyUp ($event) {
    const key = $event.keyCode || $event.charCode;
    if (key === 8 || key === 46) {
      const value = this.value;
      if (value && value.toString() && !this.validateInput(value.toString())) {
        this.value = this.lastValue;
      }
    }
  }

  onPaste ($event) {
    const clipboardData = $event.clipboardData,
     pastedText: string = clipboardData.getData("text");

    if (pastedText && pastedText.length) {
      if (!this.validateInput(pastedText)) {
        $event.preventDefault();
      }
    }
  }

  blur () {
    let value = this.value ? this.value.toString().trim() : "";
    if (value && this.type === CaiInputTypesEnum.DECIMAL) {
      if (this.decimalPlace !== null) {
        value = Number(value).toFixed(this.decimalPlace);
      }
      if (isNaN(value)) {
        value = "";
      }
    }
    this.value = value;
    this.valueChange.emit(value);
    this.onLostFocus.emit();
  }

  validateInput (value: string): boolean {
    let inputRegex,
     formatRegex;
    if (this.type === CaiInputTypesEnum.DECIMAL) {
      inputRegex = INPUT_REGEX_DECIMAL;
      formatRegex = FORMAT_REGEX_DECIMAL;
    } else if (this.type === CaiInputTypesEnum.NUMERIC) {
      inputRegex = INPUT_REGEX_NUMERIC;
      formatRegex = FORMAT_REGEX_NUMERIC;
    }
    if (inputRegex) {
      for (let i = 0; i < value.length; i++) {
        const char = value.charAt(i),
         _inputRegex = new RegExp(inputRegex);
        if (!_inputRegex.test(char)) {
          return false;
        }
      }
    }
    const _formatRegex = new RegExp(formatRegex);
    return _formatRegex.test(value);
  }
}
