import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserService, UserTypeEnum } from '@cai-services';
import Auth from '@aws-amplify/auth';
import { User } from '../../../../core/_models/user.model';
import { AppState } from '../../../../core/reducers';
import { SessionService } from '../../../../core/_services/session.service';
import { IntercomService } from '../../../../core/_services/intercom.service';
import { RegisterService } from '../../../../core/_services/register.service';
import { environment } from '../../../../environments';
import { Logout } from '../../../../core/_misc/_actions/auth.actions';
import { APP_PROPS } from '../../../../cai-common.module';
import { ApplicationProperties } from '../../../../core/_base/layout/models/app-properties.model';
import { UsetifulService } from '../../../../core';

@Component({
  selector: 'kt-user-profile2',
  templateUrl: './user-profile2.component.html',
  styleUrls: ['./user-profile2.component.scss'],
})
export class UserProfile2Component implements OnInit {
  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;
  @Input() handleResponsive? = false;
  user: User;
  token: string;
  hmac: string;
  intercomSetting: any;
  userSettingItems = [];
  shortcutLinks = [
    {
      key: 'about',
      iconUrl: '/assets/media/icons/account-purple.svg',
    },
    {
      key: 'preferences',
      iconUrl: '/assets/media/icons/settings-purple.svg',
    },
    {
      key: 'notifications',
      iconUrl: '/assets/media/icons/bell-purple.svg',
    },
    {
      key: 'integrations',
      iconUrl: '/assets/media/icons/puzzle-purple.svg',
    },
  ];

  constructor(
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private readonly activatedRoute: ActivatedRoute,
    private readonly userService: UserService,
    private readonly route: Router,
    private readonly store: Store<AppState>,
    public readonly sessionService: SessionService,
    private readonly registerService: RegisterService,
    private readonly ref: ChangeDetectorRef,
    private readonly intercomService: IntercomService,
    private readonly usetifulService: UsetifulService,
  ) {}

  private get entityName(): string {
    return this.user ? this.user.type.toLowerCase() : '';
  }

  public get personalLink(): string {
    return `/${this.entityName}/profile/personal-settings`;
  }

  async ngOnInit(): Promise<void> {
    this.user = await this.registerService.getUser();
    if (!this.user) {
      return;
    }
    this.sessionService.setCurrentUser(this.user);
    this.sessionService.watchSessionStorage().subscribe((res) => {
      if (res.key === 'currentUser') {
        this.user = res.data;
      }

      this.ref.detectChanges();
    });
    if ((window as any).dataLayer) {
      (window as any).dataLayer.push({ user_id: this.user.userId });
    }
    this.ref.detectChanges();
    this.prepareIntercomData();

    if (this.user.type === UserTypeEnum.FORWARDER) {
      const basePath = '/forwarder';
      this.userSettingItems = [
        { link: `${basePath}/profile/forwarder-profile`, label: 'Profile' },
        {
          link: `${basePath}/profile/personal-settings`,
          label: $localize`:@@global.personal-settings:Personal Settings`,
        },
      ];
    } else if (this.user.type === UserTypeEnum.AIRLINE) {
      this.userSettingItems = [{ link: '', label: '' }];
    }
  }

  prepareIntercomData() {
    const customerType = this.user?.type;
    if (environment.production) {
      if (this.activatedRoute.snapshot.paramMap.get('token')) {
        this.token = this.activatedRoute.snapshot.paramMap.get('token');
      }
      this.userService.getHMAC(this.token).then((res) => {
        this.hmac = res.hmac;
      });
    }

    this.intercomSetting = {
      app_id: environment.intercomAppId,
      email: this.user?.userEmail,
      subscription_status: 'ACTIVE',
    };
    if (this.user?.userId) {
      this.intercomSetting.cargoai_user_id = this.user?.userId;
    }
    if (this.user?.userName) {
      this.intercomSetting.name = this.user?.userName;
    }
    if (customerType) {
      this.intercomSetting.Customer_type = customerType;
    }
    if (this.hmac) {
      this.intercomSetting.user_hash = this.hmac;
    }

    this.intercomService.update(this.intercomSetting);
  }

  getInitial(): string {
    if (this.user) {
      return `${this.user?.firstName?.[0]?.toUpperCase() || ''}${
        this.user?.lastName?.[0]?.toUpperCase() || ''
      }`;
    }
    return '';
  }

  /**
   * Log out
   */
  logout() {
    Auth.signOut()
      .then(() => {
        this.store.dispatch(new Logout());
        this.sessionService.clearLocalStorage();
        this.usetifulService.removeUsetiful();
        this.route.navigate(['/auth/login']);
      })
      .catch((err) => alert(err.message || "Can't logout"));
  }

  get isCargoWallet(): boolean {
    return this.appProperties.name === 'cargowallet';
  }
  get isCargoMart(): boolean {
    return this.appProperties.name === 'cargoMart';
  }
  handleLogoError(): void {
    this.user.logoPath = undefined;
    this.ref.detectChanges();
  }
}
