import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import { IGha } from "../../model/gha.model";
import { ErrorUtil } from "../../utils/error.util";
import { API_CORE } from "../../constant/api-core.const";
import { IPresignedURL } from "../../model/presigned-url.model";
import { IPresignedGhaAttachment } from "../../model/presigned-gha-attachment.model";
import { IGhaAttachment } from "../../model/gha-attachment.model";

@Injectable({
  "providedIn": "root",
})
export class GhaService {
  async getGha (
    airlineBrandCode?: string,
    stationCode?: string,
  ): Promise<IGha[]> {
    const extraOptions: any = {
      "queryStringParameters": {},
    };
    if (airlineBrandCode) {
      extraOptions.queryStringParameters.airline = airlineBrandCode;
    }
    if (stationCode) {
      extraOptions.queryStringParameters.station = stationCode;
    }
    return API.get(
      API_CORE.name,
      API_CORE.URLs.Gha.Ghas,
      extraOptions
    ).catch(ErrorUtil.handleError);
  }

  async createOrUpdateGha (
    gha: IGha
  ): Promise<IGha> {
    return API.post(
      API_CORE.name,
      API_CORE.URLs.Gha.Ghas,
      {
        "body": gha,
      }
    ).catch(ErrorUtil.handleError);
  }
  async generateUploadUrlForAttachment (
    ghaId: number,
    attachment: IGhaAttachment
  ): Promise<IPresignedGhaAttachment> {
    const url = API_CORE.URLs.Gha.UploadAttachment.replace(
      "{ghaId}",
      ghaId.toString()
    );
    return API.post(API_CORE.name, url, {
      "body": attachment,
    });
  }
  async generateDownloadUrlForAttachment (
    ghaAttachmentId: number,
    token?: string
  ): Promise<IPresignedURL> {
    const url = API_CORE.URLs.Gha.DownloadAttachment
      .concat("/")
      .concat(ghaAttachmentId.toString());
    let extraOptions = {};
    if (token) {
      extraOptions = {
        "queryStringParameters": { token },
      };
    }
    return API.get(API_CORE.name, url, extraOptions);
  }
  async deleteAttachment (
    ghaId: number,
    ghaAttachmentId: number
  ): Promise<any> {
    const url = API_CORE.URLs.Gha.DeleteAttachment.replace(
      "{ghaId}",
      ghaId.toString()
    )
      .concat("/")
      .concat(ghaAttachmentId.toString());
    return API.del(API_CORE.name, url, {});
  }
}
