var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import * as cookie from 'cookie';
import { hasDocumentCookie, parseCookies, readCookie } from './utils';
var Cookies = /** @class */function () {
  function Cookies(cookies, options) {
    var _this = this;
    this.changeListeners = [];
    this.HAS_DOCUMENT_COOKIE = false;
    this.cookies = parseCookies(cookies, options);
    new Promise(function () {
      _this.HAS_DOCUMENT_COOKIE = hasDocumentCookie();
    }).catch(function () {});
  }
  Cookies.prototype._updateBrowserValues = function (parseOptions) {
    if (!this.HAS_DOCUMENT_COOKIE) {
      return;
    }
    this.cookies = cookie.parse(document.cookie, parseOptions);
  };
  Cookies.prototype._emitChange = function (params) {
    for (var i = 0; i < this.changeListeners.length; ++i) {
      this.changeListeners[i](params);
    }
  };
  Cookies.prototype.get = function (name, options, parseOptions) {
    if (options === void 0) {
      options = {};
    }
    this._updateBrowserValues(parseOptions);
    return readCookie(this.cookies[name], options);
  };
  Cookies.prototype.getAll = function (options, parseOptions) {
    if (options === void 0) {
      options = {};
    }
    this._updateBrowserValues(parseOptions);
    var result = {};
    for (var name_1 in this.cookies) {
      result[name_1] = readCookie(this.cookies[name_1], options);
    }
    return result;
  };
  Cookies.prototype.set = function (name, value, options) {
    var _a;
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    this.cookies = __assign(__assign({}, this.cookies), (_a = {}, _a[name] = value, _a));
    if (this.HAS_DOCUMENT_COOKIE) {
      document.cookie = cookie.serialize(name, value, options);
    }
    this._emitChange({
      name: name,
      value: value,
      options: options
    });
  };
  Cookies.prototype.remove = function (name, options) {
    var finalOptions = options = __assign(__assign({}, options), {
      expires: new Date(1970, 1, 1, 0, 0, 1),
      maxAge: 0
    });
    this.cookies = __assign({}, this.cookies);
    delete this.cookies[name];
    if (this.HAS_DOCUMENT_COOKIE) {
      document.cookie = cookie.serialize(name, '', finalOptions);
    }
    this._emitChange({
      name: name,
      value: undefined,
      options: options
    });
  };
  Cookies.prototype.addChangeListener = function (callback) {
    this.changeListeners.push(callback);
  };
  Cookies.prototype.removeChangeListener = function (callback) {
    var idx = this.changeListeners.indexOf(callback);
    if (idx >= 0) {
      this.changeListeners.splice(idx, 1);
    }
  };
  return Cookies;
}();
export default Cookies;