<div class="dimensions-container">
  <figure>
    <figcaption></figcaption>
    <table class="dimensions-table" aria-describedby="dimensions">
      <thead>
        <tr>
          <th scope="col">
            <div class="col" i18n="@@global.pieces">Pieces</div>
          </th>
          <th scope="col">
            <div class="col">
              <span i18n="@@global.dimensions">Dimensions</span>
              <span
                class="unit-switch"
                [class.disabled]="!enableUnitSwitch"
                [class.metric]="
                  matchMeasurementSystem(measurementSystemEnum.METRIC, true)
                "
                [class.imperial]="
                  matchMeasurementSystem(measurementSystemEnum.IMPERIAL, true)
                "
              >
                <cai-load-type-unit-selector
                  *ngIf="enableUnitSwitch; else defaultDimensionUnit"
                  [(selected)]="dimensionUnit"
                  (selectedChange)="
                    changeUnit($event, measurementTypeEnum.MEASURE)
                  "
                  [isParentMetric]="
                    matchMeasurementSystem(measurementSystemEnum.METRIC, true)
                  "
                  [isParentImperial]="
                    matchMeasurementSystem(measurementSystemEnum.IMPERIAL, true)
                  "
                  [(measurementSystem)]="measurementSystem"
                  (measurementSystemChange)="
                    measurementSystemChange.emit($event)
                  "
                  [options]="getMeasurementUnits(measurementTypeEnum.MEASURE)"
                  [marginTop]="2"
                  [fontSize]="14"
                  [width]="41"
                ></cai-load-type-unit-selector>
                <ng-template #defaultDimensionUnit>
                  <span>{{ measurementUnit.MEASURE.display }}</span>
                </ng-template>
              </span>
            </div>
          </th>
          <th scope="col">
            <div class="col">
              <span i18n="@@global.weight">Weight</span>
              <span
                class="unit-switch"
                [class.disabled]="!enableUnitSwitch"
                [class.metric]="
                  matchMeasurementSystem(measurementSystemEnum.METRIC, false)
                "
                [class.imperial]="
                  matchMeasurementSystem(measurementSystemEnum.IMPERIAL, false)
                "
              >
                <cai-load-type-unit-selector
                  *ngIf="enableUnitSwitch; else defaultWeightUnit"
                  [(selected)]="weightUnit"
                  (selectedChange)="
                    changeUnit($event, measurementTypeEnum.WEIGHT)
                  "
                  [isParentMetric]="
                    matchMeasurementSystem(measurementSystemEnum.METRIC, false)
                  "
                  [isParentImperial]="
                    matchMeasurementSystem(
                      measurementSystemEnum.IMPERIAL,
                      false
                    )
                  "
                  [(measurementSystem)]="measurementSystem"
                  (measurementSystemChange)="
                    measurementSystemChange.emit($event)
                  "
                  [options]="getMeasurementUnits(measurementTypeEnum.WEIGHT)"
                  [marginTop]="4"
                  [fontSize]="14"
                  [width]="41"
                ></cai-load-type-unit-selector>
                <ng-template #defaultWeightUnit>
                  <span>{{ measurementUnit.WEIGHT.display }}</span></ng-template
                >
              </span>
            </div>
          </th>
          <th scope="col">
            <div class="col overflow-visible">
              <cai-load-type-dimension-autofill
                (autofillDimensionsChange)="autofillDimensionsChange($event)"
              ></cai-load-type-dimension-autofill>
            </div>
          </th>
        </tr>
      </thead>
      <tbody #tbody [style.max-height.px]="maxHeight">
        <ng-container *ngFor="let dimension of dimensions; let index = index">
          <tr>
            <td>
              <div
                class="col"
                [attr.data-before]="(index + 1).toString() + '.'"
              >
                <cai-load-type-input
                  placeholder=" "
                  (onFocus)="focusRow(dimension, index)"
                  (onLostFocus)="unfocusRow(dimension)"
                  (onKeyDownEvent)="touchRow(dimension)"
                  [type]="loadTypeInputTypesEnum.NUMERIC"
                  [(value)]="dimension.numOfItems"
                  [invalid]="
                    isInvalidField(dimensionFieldsEnum.PIECES, dimension, index)
                  "
                  (valueChange)="changeValue()"
                  [noUpdateOnBlur]="noUpdateOnBlur"
                ></cai-load-type-input>
              </div>
            </td>
            <td>
              <div class="col">
                <cai-load-type-input
                  i18n-placeholder="@@global.length"
                  placeholder="Length"
                  (onFocus)="focusRow(dimension, index)"
                  (onLostFocus)="unfocusRow(dimension)"
                  (onKeyDownEvent)="touchRow(dimension)"
                  [decimalPlace]="null"
                  [type]="loadTypeInputTypesEnum.DECIMAL"
                  [(value)]="dimension.dimensionLength"
                  [invalid]="
                    isInvalidField(dimensionFieldsEnum.LENGTH, dimension, index)
                  "
                  (valueChange)="changeValue()"
                  [noUpdateOnBlur]="noUpdateOnBlur"
                ></cai-load-type-input>
                <div class="cell-space"></div>
                <cai-load-type-input
                  i18n-placeholder="@@global.width"
                  placeholder="Width"
                  (onFocus)="focusRow(dimension, index)"
                  (onLostFocus)="unfocusRow(dimension)"
                  (onKeyDownEvent)="touchRow(dimension)"
                  [decimalPlace]="null"
                  [type]="loadTypeInputTypesEnum.DECIMAL"
                  [(value)]="dimension.dimensionWidth"
                  [invalid]="
                    isInvalidField(dimensionFieldsEnum.WIDTH, dimension, index)
                  "
                  (valueChange)="changeValue()"
                  [noUpdateOnBlur]="noUpdateOnBlur"
                ></cai-load-type-input>
                <div class="cell-space"></div>
                <cai-load-type-input
                  i18n-placeholder="@@global.height"
                  placeholder="Height"
                  (onFocus)="focusRow(dimension, index)"
                  (onLostFocus)="unfocusRow(dimension)"
                  (onKeyDownEvent)="touchRow(dimension)"
                  [decimalPlace]="null"
                  [type]="loadTypeInputTypesEnum.DECIMAL"
                  [(value)]="dimension.dimensionHeight"
                  [invalid]="
                    isInvalidField(dimensionFieldsEnum.HEIGHT, dimension, index)
                  "
                  (valueChange)="changeValue()"
                  [noUpdateOnBlur]="noUpdateOnBlur"
                ></cai-load-type-input>
              </div>
            </td>
            <td>
              <div class="col">
                <cai-load-type-input
                  i18n-placeholder="@@global.weight"
                  placeholder="Weight"
                  (onFocus)="focusRow(dimension, index)"
                  (onLostFocus)="unfocusRow(dimension)"
                  (onKeyDownEvent)="touchRow(dimension)"
                  [decimalPlace]="null"
                  [type]="loadTypeInputTypesEnum.DECIMAL"
                  [(value)]="dimension.weight"
                  [invalid]="
                    isInvalidField(dimensionFieldsEnum.WEIGHT, dimension, index)
                  "
                  (valueChange)="changeValue()"
                  [noUpdateOnBlur]="noUpdateOnBlur"
                ></cai-load-type-input>
                <cai-load-type-weight-switch
                  [(value)]="dimension.weightType"
                  (valueChange)="changeValue()"
                ></cai-load-type-weight-switch>
              </div>
            </td>
            <td class="w-100">
              <div class="col">
                <div>
                  <cai-checkbox
                    id="stackable"
                    [size]="18"
                    [fontSize]="12"
                    [label]="stackableLabel"
                    [isActive]="isStackable(dimension)"
                    (change)="
                      toggleStackable($event, dimension); onFocus.emit()
                    "
                  ></cai-checkbox>
                </div>
                <div>
                  <cai-checkbox
                    id="turnable"
                    [size]="18"
                    [fontSize]="12"
                    [label]="turnableLabel"
                    [isActive]="isTurnable(dimension)"
                    (change)="toggleTurnable($event, dimension); onFocus.emit()"
                  ></cai-checkbox>
                </div>
                <div style="width: 20px">
                  <span
                    class="btn-delete"
                    *ngIf="showDelete(dimension, index)"
                    (click)="deleteRow(index)"
                  ></span>
                </div>
              </div>
            </td>
          </tr>
          <tr
            *ngIf="isInvalidRow(dimension, index)"
            colspan="4"
            class="error-row"
          >
            <div class="error-container">
              <span
                *ngFor="let error of dimension.errors"
                [title]="error?.message"
                class="error-item"
              >
                {{ error?.message }}
              </span>
            </div>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </figure>
</div>
