<div class="tooltip-wrapper">
  <div
    class="tooltip-content"
    [ngClass]="ngClass"
    [style.border-color]="arrowColor"
    [style.background]="bgColor"
    [style.color]="textColor"
    [style.width.px]="width"
    [style.max-width]="maxWidth ? maxWidth : width + 'px'"
  >
    <div class="tooltip-content__icon" *ngIf="showIcon">
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.5 1.125C5.77005 1.125 1.125 5.77005 1.125 11.5C1.125 17.23 5.77005 21.875 11.5 21.875C17.23 21.875 21.875 17.23 21.875 11.5C21.875 5.77005 17.23 1.125 11.5 1.125ZM0.125 11.5C0.125 5.21776 5.21776 0.125 11.5 0.125C17.7822 0.125 22.875 5.21776 22.875 11.5C22.875 17.7822 17.7822 22.875 11.5 22.875C5.21776 22.875 0.125 17.7822 0.125 11.5ZM11.5 6.16667C11.7761 6.16667 12 6.39053 12 6.66667V12.7083C12 12.9845 11.7761 13.2083 11.5 13.2083C11.2239 13.2083 11 12.9845 11 12.7083V6.66667C11 6.39053 11.2239 6.16667 11.5 6.16667ZM11.5 17C11.7761 17 12 16.7761 12 16.5C12 16.2239 11.7761 16 11.5 16C11.2239 16 11 16.2239 11 16.5C11 16.7761 11.2239 17 11.5 17Z"
          fill="#4D578D"
        />
      </svg>
    </div>
    <div class="tooltip-content__message">
      <div #body>
        <ng-content select="[body]"></ng-content>
      </div>
      <ng-container *ngIf="body.childNodes.length === 0">
        <div class="header" *ngIf="header">
          {{ header }}
        </div>
        <div class="subheader" *ngIf="subheader">
          {{ subheader }}
        </div>
        <div class="message">
          {{ message }}
        </div>
        <div
          *ngIf="messageHtml"
          class="message"
          [innerHTML]="messageHtml"
        ></div>
        <div
          class="secondary-message"
          *ngIf="secondaryMessage"
          [class.normal-text]="highlightMessage"
        >
          {{ secondaryMessage }}
        </div>
        <div class="highlight-message" *ngIf="highlightMessage">
          {{ highlightMessage }}
        </div>
        <div class="footer" *ngIf="footer">
          {{ footer }}
        </div>
      </ng-container>
    </div>
  </div>
</div>
