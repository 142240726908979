import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import { ICurrency } from "../../model/currency.model";
import { ErrorUtil } from "../../utils/error.util";
import { API_CORE } from "../../constant/api-core.const";

@Injectable({
  "providedIn": "root",
})
export class CurrencyService {
  async getCurrencies (): Promise<ICurrency[]> {
    return API.get(API_CORE.name, API_CORE.URLs.Currency.Currencies, {}).catch(
      ErrorUtil.handleError
    );
  }
}
