import { RequirementConfig } from "../../core/_models/requirement-config.model";

export enum TemperatureTrackingBatteryAnswer {
  LITHIUM_ION = "lithium_ion",
  LITHIUM_METAL = "lithium_metal",
  BOTH = "both",
}

export const TemperatureTrackingBatteryConfig: RequirementConfig = {
  "summaryLabel": "Battery powering devices :",
  "question": "What battery are powering the devices ?",
  "options": [
    {
      "label": "Lithium Ion",
      "value": TemperatureTrackingBatteryAnswer.LITHIUM_ION,
    },
    {
      "label": "Lithium Metal",
      "value": TemperatureTrackingBatteryAnswer.LITHIUM_METAL,
    },
    {
      "label": "Both",
      "value": TemperatureTrackingBatteryAnswer.BOTH,
    },
  ],
};
