import { RequirementConfig } from "../../core/_models/requirement-config.model";

export enum TemperatureFullAnswer {
  NOT_REQUIRED = "temp_not_required",
  FROZEN = "frozen",
  COLD = "cold",
  CONTROLLED = "controlled",
  AMBIANT = "ambiant",
}

export const TemperatureFullConfig: RequirementConfig = {
  "summaryLabel": "Temperature requirements :",
  "question": "TEMPERATURE REQUIREMENTS",
  "options": [
    {
      "label": "Not Required",
      "value": TemperatureFullAnswer.NOT_REQUIRED,
      "description": "",
    },
    {
      "label": "-10°C to -20°C",
      "value": TemperatureFullAnswer.FROZEN,
      "description": "(14°F to 4°F)",
    },
    {
      "label": "2°C to 8°C",
      "value": TemperatureFullAnswer.COLD,
      "description": "(36°F to 46°F)",
    },
    {
      "label": "2°C to 25°C",
      "value": TemperatureFullAnswer.CONTROLLED,
      "description": "(36°F to 77°F)",
    },
    {
      "label": "15°C to 25°C ",
      "value": TemperatureFullAnswer.AMBIANT,
      "description": "(59°F to 77°F)",
    },
  ],
};
