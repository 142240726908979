import { UserTypeEnum } from "@cai-services";
import {
  FORWARDER_ADVANCED_BOOKING_TOUR_URL,
  FORWARDER_BASICS_TOUR_URL,
  FORWARDER_HOME_TOUR_URL,
  FORWARDER_QUOTES_TOUR_URL,
  FORWARDER_RATES_TOUR_URL,
  FORWARDER_SHIPMENTS_TOUR_URL,
  FORWARDER_WALLET_TOP_UP_TOUR_URL,
} from "../../../../core/_constants/constants";

export const productTourList = [
  {
    "name": $localize`:@@help.cargoais-basics:CargoAi's Basics`,
    "duration": "30 sec",
    "link": FORWARDER_BASICS_TOUR_URL,
    "watched": false,
    "userType": UserTypeEnum.FORWARDER,
  },
  {
    "name": $localize`:@@help.discover-the-home-page:Discover the Home Page`,
    "duration": "1 min",
    "link": FORWARDER_HOME_TOUR_URL,
    "watched": false,
    "userType": UserTypeEnum.FORWARDER,
  },
  // {
  //   name: $localize`:@@help.learn-more-about-the-search-results:Learn more about the Search Results`,
  //   duration: '1 min',
  //   link: '',
  //   watched: false,
  //   userType: UserTypeEnum.FORWARDER,
  // },
  {
    "name": $localize`:@@help.manage-your-quotes:Manage your quotes`,
    "duration": "45 sec",
    "link": FORWARDER_QUOTES_TOUR_URL,
    "watched": false,
    "userType": UserTypeEnum.FORWARDER,
  },
  {
    "name": $localize`:@@help.track-your-shipments:Track your shipments`,
    "duration": "45 sec",
    "link": FORWARDER_SHIPMENTS_TOUR_URL,
    "watched": false,
    "userType": UserTypeEnum.FORWARDER,
  },
  {
    "name": $localize`:@@help.compare-and-update-rates:Compare and update rates`,
    "duration": "90 sec",
    "link": FORWARDER_RATES_TOUR_URL,
    "watched": false,
    "userType": UserTypeEnum.FORWARDER,
  },
  {
    "name": $localize`:@@help..advanced-booking:Advanced booking`,
    "duration": "1 min",
    "link": FORWARDER_ADVANCED_BOOKING_TOUR_URL,
    "watched": false,
    "userType": UserTypeEnum.FORWARDER,
  },
  {
    "name": $localize`:@@help..wallet-top-up:Wallet Top-Up`,
    "duration": "1 min",
    "link": FORWARDER_WALLET_TOP_UP_TOUR_URL,
    "watched": false,
    "userType": UserTypeEnum.FORWARDER,
  },
];
