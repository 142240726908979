import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IRequirementAnswer, RequirementCodeEnum } from "@cai-services";
import { TemperatureTrackingDeviceAnswer } from "../../../constant/requirements/temperature-tracking-device.const";
import { TemperatureTrackingNumberConfig } from "../../../constant/requirements/temperature-tracking-number.const";

const PARENT_COMPONENT = RequirementCodeEnum.TEMPERATURE_TRACKING_DEVICE,
 FORMAT_REGEX = /^\d{0,10}(\.\d{0,4})?$/,
 INPUT_REGEX = /[0-9]/g;

@Component({
  "selector": "kt-requirement-temperature-tracking-number",
  "templateUrl": "./requirement-temperature-tracking-number.component.html",
  "styleUrls": ["./requirement-temperature-tracking-number.component.scss"],
})
export class RequirementTemperatureTrackingNumberComponent implements OnInit {
  @ViewChild("input") input: ElementRef;
  @Input() formGroup: FormGroup = new FormGroup({});
  @Output() onFocus = new EventEmitter();

  code: string = RequirementCodeEnum.TRACKING_DEVICE_NUMBER;
  defaultAnswer = {} as IRequirementAnswer;
  selectedAnswer: any = {};
  question: string = TemperatureTrackingNumberConfig.question;
  visible = false;
  value: number;

  private lastValue: number;

  constructor (private readonly cdr: ChangeDetectorRef) {}

  ngOnInit (): void {
    this.value = this.formGroup.get(this.code).value;
    this.formGroup.get(this.code).valueChanges.subscribe((value) => {
      this.value = value;
    });

    const initialValue = this.formGroup.get(PARENT_COMPONENT).value;
    this.updateAnswerBasedOnParent(initialValue);
    this.formGroup.get(PARENT_COMPONENT).valueChanges.subscribe((value) => {
      this.updateAnswerBasedOnParent(value);
    });
    this.cdr.detectChanges();
  }

  onKeyPress ($event) {
    const key = $event.key,
     selectionStart = this.input.nativeElement.selectionStart,
     selectionEnd = this.input.nativeElement.selectionEnd,
     text = this.value != null ? this.value.toString() : "",

     value =
      text.substring(0, selectionStart) + key + text.substring(selectionEnd);
    if (!this.validateInput(value)) {
      $event.preventDefault();
    }
  }

  onKeyDown ($event) {
    const key = $event.keyCode || $event.charCode;
    if (key === 8 || key === 46) {
      this.lastValue = this.value != null ? this.value : null;
    }
  }

  onKeyUp ($event) {
    const key = $event.keyCode || $event.charCode;
    if (key === 8 || key === 46) {
      const value = this.value;
      if (value && value.toString() && !this.validateInput(value.toString())) {
        this.value = this.lastValue;
      }
    }
  }

  onPaste ($event) {
    const clipboardData = $event.clipboardData,
     pastedText: string = clipboardData.getData("text");

    if (pastedText && pastedText.length) {
      if (!this.validateInput(pastedText)) {
        $event.preventDefault();
      }
    }
  }

  private updateAnswerBasedOnParent (value) {
    const visible = value === TemperatureTrackingDeviceAnswer.YES,
     field = this.formGroup.get(this.code);
    if (visible) {
      this.visible = true;
    } else {
      this.visible = false;
      field.setValue(null);
    }
  }

  private validateInput (value: string): boolean {
    if (value.startsWith("0")) {
      return false;
    }
    for (let i = 0; i < value.length; i++) {
      const char = value.charAt(i),
       inputRegex = new RegExp(INPUT_REGEX);
      if (!inputRegex.test(char)) {
        return false;
      }
    }
    const formatRegex = new RegExp(FORMAT_REGEX);
    return formatRegex.test(value);
  }
}
