import { WalletStatusTypeEnum } from "@cai-services";
import { Office } from "../core/_models/office.model";

const getDefaultOffice = (
  companyOffices: Office[],
  currentOffice?: Office,
  isCargoWallet?: boolean
): Office => {
  let defaultOffice: Office;
  if (currentOffice) {
    defaultOffice = currentOffice;
  }
  if (
    defaultOffice &&
    !companyOffices.find((office) => office.id === defaultOffice.id)
  ) {
    defaultOffice = null;
  }
  if (!defaultOffice && companyOffices?.length > 0) {
    if (isCargoWallet) {
      defaultOffice = companyOffices.find(
        (office) => office.walletStatus === WalletStatusTypeEnum.ACTIVE
      );
    }
  }
  if (!defaultOffice) {
    defaultOffice = companyOffices?.[0];
  }
  if (
    defaultOffice &&
    (defaultOffice.country || defaultOffice?.airports?.length)
  ) {
    return defaultOffice;
  }
  return defaultOffice;
};

export { getDefaultOffice };
