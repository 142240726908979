<div
  class="wrapper"
  *ngIf="isShow"
  [class.advanced-bookings-spinner]="isAdvancedBookings"
>
  <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
    <path
      class="checkmark__check"
      fill="none"
      d="M14.1 27.2l7.1 7.2 16.7-16.8"
    />
  </svg>
  <p class="success-message__innerContainer align-items-center">
    <span class="success-message__title">{{ title }}</span>
    <span class="success-message__message d-flex">{{ message }} </span>
    <span class="success-message__otherMessage" *ngIf="otherMessage">{{
      otherMessage
    }}</span>
  </p>
</div>
