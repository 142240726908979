import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import { ITypeOfProduct } from "../../model/type-of-product.model";
import { ErrorUtil } from "../../utils/error.util";
import { API_CORE } from "../../constant/api-core.const";

@Injectable({
  "providedIn": "root",
})
export class TypeOfProductService {
  async getTypeOfProducts (): Promise<ITypeOfProduct[]> {
    return API.get(
      API_CORE.name,
      API_CORE.URLs.TypeOfProduct.TypeOfProducts,
      {}
    ).catch(ErrorUtil.handleError);
  }
}
