// Angular
import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({
  "name": "time",
})
export class TimeFormatPipe implements PipeTransform {
  transform (date: string | Date, args?: string): string {
    if (date instanceof Date) {
      date = moment(date).format();
    }
    const [day, time] = date?.split("T") ?? [],
     [hours, minutes] = time?.split(":") ?? [];

    if (!hours || !minutes) {
      return "";
    }
    if (args) {
      return moment(day).format(args);
    }
    return `${hours}:${minutes}`;
  }
}
