import { Component, Input } from "@angular/core";

@Component({
  "selector": "kt-svg-location",
  "templateUrl": "./svg-location.component.html",
})
export class SvgLocationComponent {
  @Input() startColor = "";
  @Input() endColor = "";

  get startClr (): string {
    return this.startColor ? this.startColor : "#2DCE89";
  }
  get endClr (): string {
    return this.endColor ? this.endColor : "#53A8E7";
  }
}
