<div class="expandable-wrapper">
  <div class="expandable-main" [style.height]="rowHeight">
    <ng-container>
      <div class="d-flex">
        <div class="expandable-header" *ngIf="!popover">
          <div
            *ngIf="showCargoMate()"
            style="cursor: pointer"
            caiPopover
            [enablePopover]="true"
            [target]="cargomateTooltip"
            [allowHoverOnTarget]="true"
            [placement]="'right'"
            id="cargomate-info"
          >
            <img
              class="cargomate-info"
              src="/assets/media/icons/info-up.svg"
              alt="Info icon"
            />
            <ng-template #cargomateTooltip>
              <cai-cargomate-popup
                [searchUUID]="searchUUID"
                [airlineCode]="airlineCompany?.airlineCompanyCode"
                [allFlights]="allFlights"
                [searchTemplate]="searchTemplate"
              >
              </cai-cargomate-popup>
            </ng-template>
          </div>
          <img
            alt="Airline Logo"
            [src]="
              this.fileService.getAirlineLogoPath(
                airlineCompany?.airlineCompanyCode
              )
            "
            onerror="this.onerror=null;this.src='/assets/media/airlinesLogo/default.png';"
          />
          <span>{{ airlineCompany?.airlineCompanyName }}</span>
          <div
            class="badges-wrapper d-flex justify-content-center align-items-center flex-wrap ml-1 mr-1"
          >
            <ng-container *ngIf="hasDirectFlight">
              <span class="direct-badge text-uppercase" i18n="@@global.direct">
                Direct
              </span>
            </ng-container>
            <ng-container *ngIf="hasAutoAwb && !autoAwbDisabledByCargoGate">
              <kt-svg-auto-awb [size]="40"></kt-svg-auto-awb>
            </ng-container>
            <div>
              <ng-container
                class="flex-wrap"
                *ngIf="qualityScore || avgQualityScore"
              >
                <span
                  [target]="tooltip"
                  [allowHoverOnTarget]="true"
                  [zIndex]="1200"
                  [placement]="'right-start'"
                  caiPopover
                  [enablePopover]="true"
                >
                  <kt-svg-quality-score
                    [qualityScore]="qualityScore"
                    [avgQualityScore]="avgQualityScore"
                  >
                  </kt-svg-quality-score>
                </span>
              </ng-container>
            </div>

            <ng-template #tooltip>
              <kt-popover
                [arrowColor]="'#679EC6'"
                [bgColor]="'#679EC6'"
                [textColor]="'white'"
                [placement]="'right-start'"
              >
                <span body i18n="@@smart-view-table-row.quality-score-tooltip"
                  >Quality Scores are calculated with at least 5 shipments over
                  a period of 90 days. Find out
                  <a
                    href="https://help.cargoai.co/en/articles/7439184-cargoquality-your-guide-to-selecting-the-best-airlines-on-cargomart"
                    target="_blank"
                    rel="noopener"
                    >more:
                  </a></span
                >
              </kt-popover>
            </ng-template>
            <div>
              <ng-container
                *ngIf="isColoaderAvailable || isWalletPaymentWithAirline"
              >
                <span
                  [target]="tooltipPayment"
                  [allowHoverOnTarget]="true"
                  [zIndex]="1200"
                  [placement]="'right-start'"
                  caiPopover
                  [enablePopover]="true"
                  class="payment-wrapper"
                >
                  <img
                    class="wallet-icon"
                    src="/assets/media/icons/search-results/wallet-payment.svg"
                  />
                  <div
                    *ngIf="isColoaderAvailable"
                    i18n="@@smart-view-table-row.partner"
                    class="text-uppercase partner-badge"
                  >
                    Partner
                  </div>
                </span>
              </ng-container>
            </div>
            <ng-template #tooltipPayment>
              <kt-popover
                [arrowColor]="'#679EC6'"
                [bgColor]="'#679EC6'"
                [textColor]="'white'"
                [placement]="'right-start'"
              >
                <span body>{{ paymentOptionDescription }}</span>
              </kt-popover>
            </ng-template>
          </div>
        </div>

        <mat-expansion-panel
          [(expanded)]="panelOpenState"
          [disabled]="true"
          hideToggle
          [class.full-width]="popover"
        >
          <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
            <table
              role="presentation"
              class="table table-striped table-bordered table-hover table-checkable expandable-row-table"
              [class.popover-table]="popover"
            >
              <ng-container *ngIf="!allFlights?.length">
                <tr
                  class="no-result-content"
                  i18n="@@global.no-flight-available"
                >
                  No Flight Available
                </tr>
              </ng-container>
              <tr
                *ngFor="
                  let flightDetail of filteredFlights;
                  trackBy: trackByFn;
                  let i = index
                "
                [attr.data-source]="flightDetail.source"
              >
                <ng-container
                  *ngIf="getBeforeDeparture(flightDetail) as beforeDepart"
                >
                  <ng-container
                    *ngFor="let item of beforeDepart; let i = index"
                  >
                    <td
                      [ngStyle]="{
                        width: colWidth + '%',
                      }"
                      class="smart-view-table__row-height before-depart"
                      [class.active-column]="item === activeColumn"
                    >
                      <div
                        *ngIf="
                          i === beforeDepart.length - 1 &&
                          (parseDate(sliderLastArr) <
                            parseDate(flightDetail.departureTime) ||
                            parseDate(sliderFirstDep) >
                              parseDate(flightDetail.departureTime))
                        "
                      >
                        <ng-container
                          *ngTemplateOutlet="
                            parseDate(sliderLastArr) <
                            parseDate(flightDetail.departureTime)
                              ? nextPageIndicator
                              : previousPageIndicator;
                            context: {
                              item: flightDetail.departureTime,
                            }
                          "
                        ></ng-container>
                      </div>
                    </td>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="getMainPath(flightDetail) as mainPath">
                  <ng-container *ngFor="let item of mainPath; let i = index">
                    <td
                      class="merged-flights main-path"
                      [ngStyle]="{
                        width: colWidth + '%',
                        'border-left':
                          mainPath.length === 2 && i === 1 ? 'none' : '',
                        'border-right':
                          mainPath.length === 2 && i === 0 ? 'none' : '',
                      }"
                      [class.active-column]="item === activeColumn"
                    >
                      <ng-container *ngIf="i === 0">
                        <ng-container
                          *ngIf="getPopupDetails(flightDetail) as popupDetails"
                        >
                          <kt-flight-paths
                            [aircraftList]="aircraftList"
                            [isColumnActive]="mainPath.includes(activeColumn)"
                            [flightPaths]="flightDetail.legs"
                            [dayDiff]="mainPath.length"
                            [lastDay]="lastDay"
                            [firstDay]="firstDay"
                            [disableTooltip]="true"
                            [flightDetails]="popupDetails"
                            [aircraftList]="aircraftList"
                            [searchTemplate]="searchTemplate"
                            [popover]="popover"
                            [measurementUnit]="measurementUnit"
                            [isEnableQuoteRequest]="isEnableQuoteRequest"
                            (onBookClicked)="onBookClicked.emit($event)"
                            (onRequestBookClicked)="
                              onRequestBookClicked.emit($event)
                            "
                            (onSelectRate)="
                              onSelectRate($event.rate, $event.flight)
                            "
                          >
                          </kt-flight-paths>
                        </ng-container>
                      </ng-container>
                    </td>
                  </ng-container>
                </ng-container>

                <ng-container
                  *ngIf="getAfterArrivalPath(flightDetail) as afterArrival"
                >
                  <ng-container
                    *ngFor="let item of afterArrival; let i = index"
                  >
                    <td
                      [ngStyle]="{
                        width: colWidth + '%',
                      }"
                      [class.active-column]="item === activeColumn"
                      class="after-arrival"
                    ></td>
                  </ng-container>
                </ng-container>

                <td
                  class="col-minimized actions-column"
                  style="overflow: visible"
                  [class.popover-actions-column]="popover && !isNewSearch"
                  [class.new-search-actions-column]="isNewSearch"
                >
                  <div
                    class="actions-container"
                    [class.select-button-container]="popover"
                  >
                    <div
                      class="rates-container"
                      *ngIf="!popover || isNewSearch"
                    >
                      <div
                        *ngIf="
                          shouldShowRate(flightDetail) && !isInterlineEnabled
                        "
                        class="rate-selected"
                        [class.instant-booking]="isInstantBooking(flightDetail)"
                        ngbDropdown
                      >
                        <span
                          class="rate-name d-flex align-items-center"
                          [class.dropdown]="
                            flightDetail.rates?.length > 1 && !isExpressOnly
                          "
                          ngbDropdownToggle
                          [matTooltip]="
                            getFormattedRateName(flightDetail.selectedRate)
                          "
                        >
                          <span class="rate-name__value">
                            {{
                              getFormattedRateName(flightDetail.selectedRate)
                            }}
                          </span>
                          <span>
                            <svg
                              width="10"
                              height="6"
                              viewBox="0 0 10 6"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              *ngIf="
                                flightDetail.rates?.length > 1 && !isExpressOnly
                              "
                            >
                              <path
                                d="M1 1L5 5L9 1"
                                stroke="#679EC6"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        </span>
                        <div ngbDropdownMenu class="dropdown-menu">
                          <ng-container *ngFor="let rate of flightDetail.rates">
                            <span
                              ngbDropdownItem
                              class="rate-option dropdown-item"
                              *ngIf="flightDetail.selectedRate !== rate"
                              (click)="onSelectRate(rate, flightDetail)"
                              [matTooltip]="getRateRemarks(rate)"
                              >{{ getFormattedRateName(rate) }}</span
                            >
                          </ng-container>
                        </div>

                        <div class="rate-details" *ngIf="!isInterlineEnabled">
                          <span class="rate-value">
                            {{
                              flightDetail.selectedRate?.allInRate
                                | caiNumberFormatter
                            }}</span
                          >
                          <span class="rate-unit">
                            /{{ measurementUnit?.WEIGHT?.code }}
                          </span>
                          <span class="rate-currency">
                            {{ getCurrency(flightDetail) }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="buttons-container" *ngIf="!isShipperQuote">
                      <button
                        *ngIf="
                          (isInstantBooking(flightDetail) ||
                            isRequestBooking(flightDetail)) &&
                          popover &&
                          !isNewSearch
                        "
                        class="select-button"
                        [class.selected-button]="selectedFlight?.[i]"
                        (click)="handleSelectFlight(flightDetail, i)"
                        (mouseenter)="isHover = true"
                        (mouseleave)="isHover = false"
                      >
                        {{
                          selectedFlight?.[i] && isHover
                            ? "UNSELECT"
                            : selectedFlight?.[i]
                              ? "SELECTED"
                              : "SELECT"
                        }}
                      </button>

                      <button
                        *ngIf="
                          ((isInstantBooking(flightDetail) && !popover) ||
                            (isNewSearch &&
                              (isInstantBooking(flightDetail) ||
                                isRequestBooking(flightDetail)))) &&
                          !isShipperQuote
                        "
                        class="booking-btn btn-book"
                        [class.enabled]="canCreateBooking"
                        [class.selected]="isNewSearch && selectedFlight?.[i]"
                        caiPopover
                        [enablePopover]="canCreateBooking"
                        [zIndex]="93"
                        [target]="rateDetail"
                        [modifiers]="popupModifiers"
                        [placement]="'left'"
                        [disabled]="!canCreateBooking"
                        [matTooltip]="bookDisabledReason"
                        [matTooltipDisabled]="bookDisabledReason.length === 0"
                        matTooltipClass="multiline"
                        (click)="
                          isNewSearch
                            ? handleSelectFlight(flightDetail, i)
                            : onBookClickedEvent(flightDetail)
                        "
                      >
                        <div
                          i18n="@@global.book"
                          class="text-uppercase"
                          *ngIf="!isNewSearch"
                        >
                          Book
                        </div>
                        <div
                          i18n="@@global.select"
                          class="text-uppercase"
                          *ngIf="isNewSearch"
                        >
                          {{
                            selectedFlight?.[i] && isHover
                              ? "UNSELECT"
                              : selectedFlight?.[i]
                                ? "SELECTED"
                                : "SELECT"
                          }}
                        </div>
                        <div i18n="@@global.now" *ngIf="!isNewSearch">NOW</div>
                        <div
                          i18n="@@global.minimum-rate"
                          class="d-none minimum-rate"
                          *ngIf="
                            flightDetail.selectedRate?.minimumRate &&
                            !isInterlineEnabled
                          "
                        >
                          Minimum Rate
                        </div>
                        <div
                          class="d-none rate-total"
                          *ngIf="!isInterlineEnabled"
                        >
                          <span>{{
                            splitStringAndGetValueByIndex(
                              flightDetail.selectedRate?.total,
                              0
                            ) | number: "1.0"
                          }}</span>
                          <ng-container
                            *ngIf="
                              splitStringAndGetValueByIndex(
                                flightDetail.selectedRate?.total,
                                1
                              ) as decimalValue
                            "
                          >
                            <span>.</span>
                            <span>{{ decimalValue }} </span>
                          </ng-container>
                          <span class="total-currency">{{
                            flightDetail?.selectedRate?.currency
                          }}</span>
                        </div>
                      </button>
                      <button
                        *ngIf="
                          isRequestBooking(flightDetail) &&
                          !isTransborderSearch &&
                          !popover &&
                          !isNewSearch &&
                          !isShipperQuote
                        "
                        class="booking-btn btn-request-booking"
                        caiPopover
                        [zIndex]="93"
                        [target]="rateDetail"
                        [modifiers]="popupModifiers"
                        [placement]="'left'"
                        [disabled]="!canCreateBooking || isUserAirline"
                        [matTooltip]="bookDisabledReason"
                        [matTooltipDisabled]="bookDisabledReason.length === 0"
                        matTooltipClass="multiline"
                        (click)="onRequestBookingClickedEvent(flightDetail)"
                      >
                        <div i18n="@@global.request" *ngIf="!isNewSearch">
                          REQUEST
                        </div>
                        <div
                          i18n="@@global.booking"
                          class="text-uppercase"
                          *ngIf="!isNewSearch"
                        >
                          Booking
                        </div>
                        <div
                          i18n="@@global.minimum-rate"
                          class="d-none minimum-rate"
                          *ngIf="flightDetail.selectedRate?.minimumRate"
                        >
                          Minimum Rate
                        </div>
                        <div class="d-none rate-total">
                          <span>{{
                            splitStringAndGetValueByIndex(
                              flightDetail.selectedRate?.total,
                              0
                            ) | number: "1.0"
                          }}</span>
                          <ng-container
                            *ngIf="
                              splitStringAndGetValueByIndex(
                                flightDetail.selectedRate?.total,
                                1
                              ) as decimalValue
                            "
                          >
                            <span>.</span>
                            <span>{{ decimalValue }}</span>
                          </ng-container>
                          <span class="total-currency">{{
                            flightDetail?.selectedRate?.currency
                          }}</span>
                        </div>
                      </button>
                      <ng-template #rateDetail>
                        <div class="rate-detail-popup">
                          <ul>
                            <li>
                              <span
                                ><img alt="" [src]="getHoverIcon(flightDetail)"
                              /></span>
                              <span>Live Rate</span>
                            </li>
                            <li>
                              <span
                                ><img alt="" [src]="getHoverIcon(flightDetail)"
                              /></span>
                              <span>Live Capacity</span>
                            </li>
                            <li>
                              <span
                                ><img alt="" [src]="getHoverIcon(flightDetail)"
                              /></span>
                              <span>Live Schedule</span>
                            </li>
                          </ul>
                        </div>
                      </ng-template>
                      <div
                        class="not-available"
                        *ngIf="shouldShowNotAvailableRate(flightDetail)"
                      >
                        <span i18n="@@global.booking" class="text-uppercase"
                          >Booking</span
                        >
                        <span
                          class="description text-uppercase"
                          i18n="@@global.not-available"
                          >Not Available</span
                        >
                        <span
                          *ngIf="flightDetail.availableReason"
                          class="reason"
                          [ngbPopover]="popReason"
                          [placement]="'bottom'"
                          triggers="mouseenter:mouseleave"
                          [popoverTitle]=""
                          container="body"
                        >
                          <img
                            src="/assets/media/icons/search-results/question.svg"
                            alt=""
                          />
                        </span>
                        <ng-template #popReason>
                          <div
                            class="reason__value"
                            *ngIf="
                              flightDetail.availableReason;
                              else unknownReason
                            "
                          >
                            {{ flightDetail.availableReason }}
                          </div>
                          <ng-template #unknownReason>
                            <div
                              class="reason__unknown"
                              i18n="@@global.reason-is-unknown"
                            >
                              Reason is unknown
                            </div>
                          </ng-template>
                        </ng-template>
                      </div>
                      <ng-container *ngIf="shouldShowSpinner(flightDetail)">
                        <mat-spinner
                          style="margin: auto"
                          [diameter]="20"
                        ></mat-spinner>
                      </ng-container>
                    </div>
                    <div
                      class="chk-select-container"
                      style="align-items: center"
                    >
                      <cai-checkbox
                        *ngIf="isEnableQuoteRequest"
                        class="chk-select"
                        [isActive]="flightDetail.checked && !isUserAirline"
                        [showDisableColor]="
                          isShipperQuote
                            ? !(flightDetail.checked && !isUserAirline) &&
                              (maxFlightReached ||
                                isUserAirline ||
                                !flightDetail?.rates?.length)
                            : !isQuotable(flightDetail) || isUserAirline
                        "
                        [disabled]="
                          isShipperQuote
                            ? !(flightDetail.checked && !isUserAirline) &&
                              (maxFlightReached ||
                                isUserAirline ||
                                !flightDetail?.rates?.length)
                            : flightDetail.disabled ||
                              !isQuotable(flightDetail) ||
                              isUserAirline
                        "
                        [disablePopover]="
                          isQuotable(flightDetail) ||
                          isUserAirline ||
                          isShipperQuote
                        "
                        [ngbPopover]="quotableReason"
                        [popoverClass]="'smart-quotable-reason'"
                        triggers="mouseenter:mouseleave"
                        placement="bottom-right"
                        popoverTitle=""
                        container="body"
                        (change)="
                          onFlightRowSelected(
                            $event,
                            flightDetail.sortingFlightKey
                          )
                        "
                      >
                      </cai-checkbox>
                      <span
                        *ngIf="isEnableQuoteRequest"
                        class="request-quote-label"
                        i18n="@@smart-view-table-row.request-quote"
                        [innerText]="
                          isShipperQuote ? 'Select Flight' : 'Request Quote'
                        "
                      ></span>
                      <ng-template #quotableReason>
                        {{ getQuotableReason(flightDetail) }}
                      </ng-template>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </div>
    </ng-container>
  </div>
  <hr />
  <div
    *ngIf="allFlights?.length > 3 && airlineCount > 1"
    class="toogle-panel-container"
  >
    <ng-container *ngIf="filteredFlights?.length < allFlights?.length">
      <em
        *ngIf="filteredFlights?.length > 3"
        class="la la-angle-up"
        (click)="onShowLess()"
      ></em>
      <span (click)="onShowMore()">
        {{ allFlights?.length - filteredFlights?.length }}
        <span i18n="@@global.more">more</span>
        {{
          allFlights?.length - filteredFlights?.length > 1
            ? "flights"
            : "flight"
        }}
      </span>
      <em (click)="onShowMore()" class="la la-angle-down"></em>
    </ng-container>
    <ng-container *ngIf="filteredFlights?.length === allFlights?.length">
      <em class="la la-angle-up" (click)="onShowLess()"></em>
      <span (click)="onShowLess()" i18n="@@global.show-less">Show less</span>
    </ng-container>
  </div>
</div>

<ng-template #nextPageIndicator let-item="item">
  <div
    class="route-available-indicator d-flex"
    (click)="onMoveFlightDaysView.emit({ type: 'next', item: this.item })"
  >
    <span i18n="@@smart-view-table-row.row-available-from"
      >ROUTE AVAILABLE FROM {{ formatDate(item, "MMM D") }}</span
    >
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style="margin-left: 3px"
    >
      <path
        d="M9.63078 7.131C9.57428 7.189 9.36094 7.437 9.1622 7.641C7.99708 8.924 4.95762 11.024 3.36678 11.665C3.12518 11.768 2.51437 11.986 2.18802 12C1.8753 12 1.5772 11.928 1.29274 11.782C0.938139 11.578 0.653678 11.257 0.497808 10.878C0.397467 10.615 0.241598 9.828 0.241598 9.814C0.0857282 8.953 0 7.554 0 6.008C0 4.535 0.0857282 3.193 0.213346 2.319C0.227959 2.305 0.383829 1.327 0.554311 0.992C0.867024 0.38 1.47784 0 2.13151 0H2.18802C2.61374 0.015 3.50901 0.395 3.50901 0.409C5.01413 1.051 7.98344 3.048 9.17681 4.375C9.17681 4.375 9.51291 4.716 9.65904 4.929C9.88699 5.235 10 5.614 10 5.993C10 6.416 9.87238 6.81 9.63078 7.131Z"
        fill="#5D97C2"
      />
    </svg>
  </div>
</ng-template>

<ng-template #previousPageIndicator let-item="item">
  <div
    class="route-available-indicator d-flex"
    (click)="onMoveFlightDaysView.emit({ type: 'prev', item: this.item })"
  >
    <svg
      width="11"
      height="13"
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style="margin-right: 3px"
    >
      <path
        d="M0.381935 4.87586C0.438493 4.81791 0.652078 4.57012 0.851007 4.36631C2.01736 3.08443 5.05884 0.987353 6.65029 0.347882C6.89199 0.245115 7.50301 0.0277019 7.82938 0.0140156C8.14209 0.0143163 8.44012 0.0866033 8.72444 0.232877C9.07885 0.437218 9.363 0.75849 9.51851 1.13764C9.6186 1.40074 9.77371 2.18789 9.7737 2.20189C9.92874 3.06304 10.0131 4.46212 10.0116 6.00812C10.0102 7.48112 9.9232 8.82303 9.79474 9.69691C9.78012 9.7109 9.62331 10.6887 9.4525 11.0236C9.1392 11.6353 8.52802 12.0147 7.87435 12.0141L7.81784 12.014C7.39214 11.9986 6.49723 11.6177 6.49724 11.6037C4.99275 10.9603 2.02535 8.96044 0.833255 7.63229C0.833255 7.63229 0.497489 7.29097 0.351567 7.07783C0.123902 6.77161 0.0112609 6.3925 0.0116253 6.0135C0.0120319 5.5905 0.140028 5.19663 0.381935 4.87586Z"
        fill="#5D97C2"
      />
    </svg>
    <span i18n="@@smart-view-table-row.row-available-from"
      >ROUTE AVAILABLE FROM {{ formatDate(item, "MMM D") }}</span
    >
  </div>
</ng-template>
