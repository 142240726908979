import { NgModule } from "@angular/core";
import { CaiAwbFormatDirective } from "./awb-format.directive";
import { CurrencyDirective } from "./currency.directive";

const CAI_DIRECTIVES = [CaiAwbFormatDirective, CurrencyDirective];

@NgModule({
  "declarations": [...CAI_DIRECTIVES],
  "exports": [...CAI_DIRECTIVES],
  "providers": [],
  "imports": [],
})
export class CommonDirectivesModule {}
