<div class="kt-portlet">
  <div class="kt-portlet__head kt-portlet__head__custom">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title" i18n="@@global.new-airline">
        New Airline
      </h3>
    </div>
  </div>
  <form class="kt-form">
    <div class="kt-portlet__body">
      <div class="kt-portlet__body-progress">
        <mat-spinner [diameter]="10"></mat-spinner>
      </div>

      <div class="row">
        <div class="col-xl-6">
          <div class="form-group">
            <label i18n="@@global.airline-name">Airline Name</label>
            <input
              type="text"
              class="form-control"
              name="airlineName"
              i18n-placeholder="@@global.airline-name"
              placeholder="Airline Name"
              [ngModel]="airline.userName"
            />
            <span
              class="form-text text-muted"
              i18n="@@auth.text-please-enter-new-airline-name"
              >Please enter new airline name.</span
            >
          </div>
        </div>

        <div class="col-xl-6">
          <div class="form-group">
            <label i18n="@@global.airline-email">Airline Email</label>
            <input
              type="text"
              class="form-control"
              name="airlineEmail"
              i18n-placeholder="@@global.airline-email"
              placeholder="Airline Email"
              [ngModel]="airline.userEmail"
            />
            <span
              class="form-text text-muted"
              i18n="@@auth.text-please-enter-new-airline-email"
              >Please enter new airline Email</span
            >
          </div>
        </div>
      </div>

      <div class="row text-right">
        <div class="col-lg-12">
          <button
            type="button"
            mat-raised-button
            cdkFocusInitial
            matTooltip="Cancel"
          >
            <span i18n="@@global.cancel">Cancel</span></button
          >&nbsp;
          <button
            type="button"
            mat-raised-button
            color="primary"
            matTooltip="Save"
          >
            <span i18n="@@global.save">Save</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
