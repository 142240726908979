class Aircraft {
  static readonly Aircrafts = "/aircrafts";
  static readonly AircraftByCode = "/aircrafts/{aircraftCode}";
}

class Airline {
  static readonly Airlines = "/airlines";
  static readonly AirlineById = "/airlines/{airlineId}";
  static readonly AirlineConfig = "/airlines/{airlineId}/config";
  static readonly ResponseRate = "/airlines/response-rate";
}

class Airport {
  static readonly Airports = "/airports";
  static readonly AirportByCode = "/airports/{airportCode}";
}

class Analytics {
  static readonly AirlinesRevenue = "/analytics/airlines/revenue";
  static readonly ForwardersRevenue = "/analytics/forwarders/revenue";
  static readonly ForwardersMain = "/analytics/forwarder-main";
  static readonly TopDestinations = "/analytics/top-destinations";
  static readonly BookingTrend = "/analytics/booking-trend";
  static readonly SearchBehaviour = "/analytics/search-behaviour";
  static readonly TopTradeLanes = "/analytics/top-trade-lanes";
  static readonly Co2EfficiencyScore = "/analytics/co2-efficiency-score";
  static readonly IndustryAverageCo2EfficiencyScore =
    "/analytics/industry-average-co2-efficiency-score";
  static readonly GreenestOptionUsage = "/analytics/greenest-option-usage";
  static readonly Co2Statistics = "/analytics/co2-statistics";
  static readonly Co2PerKg = "/analytics/co2-per-kg";
  static readonly CapacityAndRate = "/analytics/capacity-and-rate";
  static readonly QualityScore = "/analytics/quality-score";
}

class Awb {
  static readonly Awbs = "/awbs";
  static readonly AwbStatus = "/awbs/{awb}/status";
}

class Allotment {
  static readonly Allotments = "/allotments";
  static readonly Filenames = "/allotments/filenames";
  static readonly Summary = "/allotments/summary-email";
}

class Plan {
  static readonly Upgrade = "/upgrade/{type}";
  static readonly Discount = "/upgrade/discount";
  static readonly Capture = "/upgrade/capture/{type}/{branches}";
}

class City {
  static readonly Cities = "/cities";
  static readonly CityById = "/cities/{cityId}";
}

class Commodity {
  static readonly Commodities = "/commodities";
  static readonly Search = "/commodities/search";
  static readonly DangerousGoodById = "/commodities/dangerous-goods/{commodityId}";
}

class Company {
  static readonly Companies = "/companies";
  static readonly OfficeStatus = "/companies/office-status";
  static readonly OfficesById = "/companies/{companyId}/office";
  static readonly CurrentCompany = "/companies/mine";
  static readonly CompaniesById = "/companies/mine/{companyId}";
  static readonly Offices = "/companies/mine/offices";
  static readonly OfficesByOfficeId = "/companies/mine/offices/{officeId}";
  static readonly OfficesByIataCass = "/companies/mine/offices/{iatacass}";
}

class Country {
  static readonly Countries = "/countries";
  static readonly CountryByCode = "/country/{countryCode}";
}

class Currency {
  static readonly Currencies = "/currencies";
}

class File {
  static readonly Files = "/files";
  static readonly Upload = "/files/upload";
  static readonly PresignedUrl = "/files/presigned-url";
}

class Flight {
  static readonly Flights = "/flights";
  static readonly Alternatives = "/flights/alternative";
  static readonly FlightById = "/flights/{flightId}";
  static readonly FlightQuotesById = "/flights/{flightId}/quotes";
  static readonly FlightQuotesExcelById = "/flights/{flightId}/quotes-excel";
  static readonly FlightNumbers = "/flights/numbers";
  static readonly CarbonEmission = "/flights/calculate-co2";
  static readonly MaxCapacity = "/flights/capacity";
}

class GeneralCondition {
  static readonly GeneralConditions = "/general-conditions";
  static readonly UploadAttachment =
    GeneralCondition.GeneralConditions +
    "/{generalConditionId}/upload-attachment";
  static readonly DeleteAttachment =
    GeneralCondition.GeneralConditions +
    "/{generalConditionId}/delete-attachment";
  static readonly DownloadAttachment =
    GeneralCondition.GeneralConditions +
    "/{generalConditionId}/download-attachment";
}

class Gha {
  static readonly Ghas = "/ghas";
  static readonly UploadAttachment = Gha.Ghas + "/{ghaId}/upload-attachment";
  static readonly DeleteAttachment = Gha.Ghas + "/{ghaId}/delete-attachment";
  static readonly DownloadAttachment = Gha.Ghas + "/download-attachment";
}

class MarketRate {
  static readonly MarketRates = "/market-rates";
  static readonly List = "/market-rates/list";
  static readonly Metadata = "/market-rates/metadata";
  static readonly Conditions = "/market-rates/conditions";
  static readonly ExportCSV = "/market-rates/export-csv";
}

class ContractRate {
  static readonly ContractRates = "/contract-rates";
  static readonly List = "/contract-rates/list";
  static readonly Metadata = "/contract-rates/metadata";
  static readonly ExportCSV = "/contract-rates/export-csv";
}

class Office {
  static readonly Office = "/offices/{officeId}";
  static readonly Terms = "/offices/{officeId}/terms";
  static readonly Disclaimer = "/offices/{officeId}/disclaimer";
  static readonly Members = "/offices/{officeId}/members";
  static readonly Charges = "/offices/{officeId}/charges";
  static readonly AirportCharges = "/offices/{officeId}/charges/{airportCode}";
  static readonly AirportCharge =
    "/offices/{officeId}/charges/{airportCode}?name={name}";
  static readonly Member = "/offices/{officeId}/members/{userId}";
  static readonly Margin = "/offices/{officeId}/margin";
  static readonly Invitations = "/offices/invitations";
  static readonly Invitation = "/offices/invitations/{invitationId}";
  static readonly AcceptInvitation =
    "/offices/invitations/{invitationId}/accept";
  static readonly TermsAndConditions = "/offices/{officeId}/terms-and-conditions";
  static readonly TermsAndCondition = "/offices/{officeId}/terms-and-conditions/{name}";
  static readonly Customers = "/offices/{officeId}/customers";
  static readonly OfficesWithWalletPaginated =
    "/user-management/wallets/paginated";
  static readonly OfficesWithWalletMetadata =
    "/user-management/wallets/metadata";
}

class Quote {
  static readonly Quotes = "/quotes";
  static readonly QuotesLight = "/quotes/light";
  static readonly Metadata = "/quotes/metadata";
  static readonly ExportPdf = "/quotes/export-pdf";
  static readonly QuoteById = "/quotes/{quoteId}";
  static readonly Messages = "/quotes/{quoteId}/messages";
  static readonly Attachment = "/quotes/attachment";
  static readonly QuoteAttachment = "/quotes/{quoteId}/attachment";
  static readonly TrackById = "/quotes/{quoteId}/track";
  static readonly QuoteAttachmentById = "/quotes/{quoteId}/attachment";
  static readonly AttachmentByAttachmentId = "/quotes/attachment/{quoteAttachmentId}";
  static readonly GhaAttachmentByAttachmentId = "/quotes/gha-attachment/{quoteGhaAttachmentId}";
  static readonly QuoteAttachmentByAttachmentId = "/quotes/{quoteId}/attachment/{quoteAttachmentId}";
  static readonly ShipperDetails = "/quotes/{quoteId}/shipper";
  static readonly Fwb = "/quotes/{quoteId}/fwb";
  static readonly ResendEmail = "/quotes/{quoteId}/resend-email";
  static readonly subscribedEmails = "/quotes/{quoteId}/subscribers";
  static readonly Fhl = "/quotes/{quoteId}/fhl";
  static readonly eawbExportPdf = "/quotes/eawb-export-pdf";
}

class QuoteRequest {
  static readonly QuoteRequests = "/quote-requests";
  static readonly QuoteRequestsAirline = "/quote-requests/airline";
  static readonly QuoteRequestById = "/quote-requests/{quoteRequestId}";
  static readonly Metadata = "/quote-requests/metadata";
  static readonly Attachment = "/quote-requests/attachment";
}

class AdvancedBookings {
  static readonly AdvancedBookings = "/advanced-bookings";
  static readonly AdvancedBookingsById = "/advanced-bookings/{id}";
  static readonly Suggestions = "/advanced-bookings/suggestions";
  static readonly AutoFills = "/advanced-bookings/auto-fills";
  static readonly RecentInput = "/advanced-bookings/recent-inputs/";
}

class Rate {
  static readonly Rates = "/rates";
  static readonly ExportCSV = "/rates/export-csv";
  static readonly Metadata = "/rates/metadata";
  static readonly Insights = "/rates/insights";
  static readonly Coverage = "/rates/coverage";
  static readonly ProdCodes = "/rates/airline-prod-codes";
}

class Rss {
  static readonly News = "/news";
}

class SellingRate {
  static readonly SellingRates = "/selling-rates";
  static readonly List = "/selling-rates/list";
  static readonly Metadata = "/selling-rates/metadata";
  static readonly Conditions = "/selling-rates/conditions";
  static readonly ExportCSV = "/selling-rates/export-csv";
}

class SkyPallet {
  static readonly SkyPalletById = "/skypallet/{skypalletId}";
}

class ShipperQuote {
  static readonly ShipperQuoteRequests = "/shipper-quote-requests";
  static readonly ShipperQuoteOfRequest =
    "/shipper-quote-requests/{quoteRequestId}/shipper-quotes/{quoteId}";
  static readonly Metadata = "/shipper-quote-requests/metadata";
  static readonly DownloadPdf =
    "/shipper-quote-requests/{quoteRequestId}/export-pdf";
}

class Solutions {
  static readonly ExtractShipmentInputs = "/solutions/ai/shipment";
  static readonly GetShipmentInputsByUuid = "/solutions/ai/shipment/{uuid}";
  static readonly AwbManagement = "/solutions/awb-management/stocks";
}

class SpecialHandlingCode {
  static readonly SpecialHandlingCodes = "/special-handling-codes";
  static readonly SpecialHandlingCodeByCode = "/special-handling-codes/{code}";
}

class Surcharge {
  static readonly Surcharges = "/surcharges";
}

class TypeOfProduct {
  static readonly TypeOfProducts = "/type-of-products";
}

class User {
  static readonly Users = "/users";
  static readonly AirlinerUsers = "/users/airlines";
  static readonly AirlinerUserById = "/users/airlines/{userId}";
  static readonly ForwarderUsers = "/users/forwarders";
  static readonly ForwarderUserById = "/users/forwarders/{userId}";
  static readonly StandardsUserById = "/users/standards/{userId}";
  static readonly CurrentUser = "/users/me";
  static readonly officeCampaign = "/users/me/office/{officeId}/ads/{campaign}";
  static readonly RegisterUser = "/users/{type}";
  static readonly HMAC = "/users/me/hmac";
  static readonly EmailStatus = "/users/email-status";
  static readonly ConfirmEmail = "/users/confirm-email";
  static readonly ValidateEmail = "/users/me/validate-email";
  static readonly RecentSearches = "/users/me/recent-searches";
  static readonly SearchTemplates = "/users/me/search-templates";
  static readonly SearchTemplateByTemplateId = "/users/me/search-templates/{templateId}";
  static readonly Statistics = "/users/me/statistics";
  static readonly PrefillInformation = "/users/me/prefill-informations";
  static readonly NotificationSettings = "/users/me/notification-settings";
  static readonly UserAdsInfo = "/users/me/ads-info";
  static readonly SupersetToken = "/users/me/superset-token";
  static readonly AgentCode = "/users/ecs";
}

class Wallet {
  static readonly PostWallet = "/wallets/{officeId}";
  static readonly DefaultBalance = "/wallets/{officeId}/default-balance";
  static readonly AccountWalletDetails = "/wallets/{officeId}/account";
  static readonly UpdateWalletStatus = "/wallets/{officeId}/status";
  static readonly TransactionsList = "/wallets/{officeId}/transactions";
  static readonly FundingAccounts = "/wallets/{officeId}/funding-accounts/{currency}";
  static readonly TopUp = "/wallets/{officeId}/top-up";
  static readonly WalletPrivacy = "/wallets/{officeId}/privacy/{bool}";
  static readonly GetBalances = "/wallets/{officeId}/balances?currency={currency}";
  static readonly GetPaylaterBalances = "/wallets/{officeId}/paylater-balances";
  static readonly GetConvertPaylaterBalances = "/wallets/convert";
  static readonly GetCurrencyList = "/wallets/{officeId}/currencies";
  static readonly DownloadInvoiceOrReceipt = "/wallets/invoice-receipt/{transactionId}/{documentType}";
  static readonly GlobalBalance = "/wallets/{officeId}/balance-conversions";
  static readonly Beneficiaries = "/wallets/{officeId}/beneficiaries";
  static readonly GlobalBeneficiaries = "/wallets/beneficiaries";
}

class Sso {
  static readonly ssoConfig = "/sso/configs";
}

class PermissionSet {
  static readonly PermissionSets = "/user-management/permission-sets";
}

export const API_CORE = {
  "name": "api-core",
  "URLs": {
    Aircraft,
    Airline,
    Airport,
    Analytics,
    Awb,
    Allotment,
    Plan,
    City,
    Commodity,
    Company,
    Country,
    Currency,
    File,
    Flight,
    GeneralCondition,
    Gha,
    MarketRate,
    ContractRate,
    Office,
    Quote,
    QuoteRequest,
    Rate,
    Rss,
    SellingRate,
    SkyPallet,
    ShipperQuote,
    Solutions,
    SpecialHandlingCode,
    Surcharge,
    TypeOfProduct,
    User,
    AdvancedBookings,
    Wallet,
    Sso,
    PermissionSet,
  },
};
