import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import {
  AppNotificationTypeEnum,
  CargoMartPlansType,
  UserTypeEnum,
} from '@cai-services';
import { NotificationSetting } from '../../../../../core/_models/notification-setting.model';
import { RegisterService } from '../../../../../core/_services/register.service';
import { User } from '../../../../../core/_models/user.model';
import {
  defaultNotificationSettingsForwarder,
  defaultNotificationSettingsAirline,
} from './data';
import { SessionService } from '../../../../../core/_services/session.service';

@Component({
  selector: 'kt-notifications-personal-settings',
  templateUrl: './notifications-personal.component.html',
  styleUrls: ['./notifications-personal.component.scss'],
})
export class NotificationsPersonalSettingsComponent implements OnInit {
  @Input() user: User;
  loading = false;
  notificationSettings = [];
  isSettingsUpdated = false;
  isFetched = false;
  allSend = new NotificationSetting();
  shouldDisableShipmentStatNotifs: boolean;
  shipmentStatNotifs = [
    AppNotificationTypeEnum.QUOTE_IN_TRANSIT,
    AppNotificationTypeEnum.QUOTE_AT_DESTINATION,
    AppNotificationTypeEnum.QUOTE_DELIVERED,
  ];

  constructor(
    private ref: ChangeDetectorRef,
    private sessionService: SessionService,
    private registerService: RegisterService,
  ) {}

  async ngOnInit() {
    this.notificationSettings =
      this?.user?.type === UserTypeEnum.FORWARDER
        ? defaultNotificationSettingsForwarder
        : defaultNotificationSettingsAirline;
    await this.disableShipmentStatusNotisIfFree();
    this.fetchUserSetting();
  }

  onSubmit(): void {
    this.loading = true;
    const settings = this.formatSettings();
    this.registerService
      .updateNSByForwarder(settings)
      .then(() => {
        this.loading = false;
        this.isSettingsUpdated = false;
        this.ref.detectChanges();
        Swal.fire(
          $localize`:@@global.success:Success!`,
          $localize`:@@global.noti-preference-updated:Notification preferences have been updated successfully!`,
          'success',
        );
      })
      .catch(() => {
        console.error('User NotificationSettings cannnot be updated');
        Swal.fire(
          '',
          $localize`:@@global.error.generic-error:Something went wrong!`,
          'error',
        );
      });
  }

  fetchUserSetting(): void {
    if (this.user) {
      this.registerService
        .getNSByForwarder()
        .then((res) => {
          this.notificationSettings.forEach((notifictaion) => {
            notifictaion.settings.forEach((setting) => {
              const currentNSetting = res.find(
                (nSetting) => nSetting.notificationType.name === setting.name,
              );
              if (
                this.shouldDisableShipmentStatNotifs &&
                this.shipmentStatNotifs.includes(setting.name)
              ) {
                setting.isSendEmail = false;
                setting.isSendToast = false;
                setting.isPushNotApplicable = true;
                setting.isEmailNotApplicable = true;
                return;
              }
              if (currentNSetting) {
                setting.isSendEmail = currentNSetting?.isSendEmail;
                setting.isSendToast = currentNSetting?.isSendToast;
                setting.notificationType = currentNSetting?.notificationType;
              } else {
                setting.notificationType = {
                  name: setting.name,
                  email: setting.isSendEmail,
                  push: setting.isSendToast,
                };
              }
            });
          });
          this.checkAllSend();
          this.isFetched = true;
          this.ref.detectChanges();
        })
        .catch(() => {
          console.error('User NotificationSettings cannnot be fetched');
        });
    }
  }

  formatSettings(): NotificationSetting[] {
    return this.notificationSettings
      .map((ns) => ns.settings)
      .reduce((pre, cur) => pre.concat(cur))
      .map((setting) => ({
        notificationType: setting.notificationType,
        isSendEmail: setting.isSendEmail,
        isSendToast: setting.isSendToast,
        user: this.user,
      }));
  }

  updateSetting(setting: NotificationSetting, type: string, value: boolean) {
    if (setting == null) {
      this.allSend[type] = value;
      this.notificationSettings.forEach((notifictaion) => {
        notifictaion.settings.forEach((setting) => {
          if (
            (type != 'isSendToast' || !setting.isPushNotApplicable) &&
            !setting.isEmailNotApplicable
          ) {
            setting[type] = value;
          }
        });
      });
    } else {
      setting[type] = value;
      this.checkAllSend();
    }
    this.isSettingsUpdated = true;
  }

  checkAllSend() {
    let isSendEmailAllOn = true,
      isSendToastAllOn = true;
    this.notificationSettings.forEach((notifictaion) => {
      notifictaion.settings.forEach((setting) => {
        isSendEmailAllOn = isSendEmailAllOn && setting.isSendEmail;
        isSendToastAllOn =
          isSendToastAllOn &&
          (setting.isSendToast || setting.isPushNotApplicable);
      });
    });
    this.allSend.isSendEmail = isSendEmailAllOn;
    this.allSend.isSendToast = isSendToastAllOn;
  }

  async disableShipmentStatusNotisIfFree(): Promise<void> {
    if (this.user?.type === UserTypeEnum.FORWARDER) {
      const offices = await this.sessionService.getOffices();
      this.shouldDisableShipmentStatNotifs = !offices.some(
        (office) => office.plan !== CargoMartPlansType.FREE,
      );
    }
  }

  popMessage(setting) {
    if (
      this.shouldDisableShipmentStatNotifs &&
      this.shipmentStatNotifs.includes(setting.name)
    ) {
      return $localize`:@@notifications-personal.upgrade-to-enable:Upgrade to Pro to enable`;
    }
    if (setting.isEmailNotApplicable || setting.isPushNotApplicable) {
      return $localize`:@@notifications-personal.not-supported:Not supported`;
    }
  }

  showProTag(name: AppNotificationTypeEnum): boolean {
    return (
      this.shouldDisableShipmentStatNotifs &&
      this.shipmentStatNotifs.includes(name)
    );
  }
}
