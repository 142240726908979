import {
  ChangeDetectorRef,
  Output,
  Component,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  TemplateRef,
  ViewContainerRef,
  NgZone,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  SimpleChanges, OnChanges,
} from "@angular/core";
import { fromEvent } from "rxjs";
import { filter, takeUntil, takeWhile } from "rxjs/operators";
import Popper from "popper.js";
import { CaiSelectOption } from "../../model/select.model";
import { CaiInputTypesEnum } from "../../enum/input-types.enum";
import { EventsUtil } from "../../utils/events.util";

const REGEX_ALPHABET = /[a-zA-Z,]/g,
 REGEX_ALPHANUMERIC = /[a-zA-Z0-9.]/g,
 INPUT_REGEX_NUMERIC = /[0-9]/g,
 INPUT_REGEX_DECIMAL = /[0-9.]/g,
 FORMAT_REGEX_NUMERIC = /^\d{0,10}?$/,
 FORMAT_REGEX_DECIMAL = /^\d{0,10}(\.\d{0,4})?$/;

@Component({
  "selector": "cai-input-select",
  "templateUrl": "./input-select.component.html",
  "styleUrls": ["./input-select.component.scss"],
  "changeDetection": ChangeDetectionStrategy.OnPush,
})
export class CaiInputSelect implements OnChanges {
  @ViewChild("input") input: ElementRef;
  @ViewChild("wrapper") wrapper: ElementRef;
  @Input() type: CaiInputTypesEnum;
  @Input() readonly: boolean;
  @Input() disabled: boolean;
  @Input() mandatoryDisabled: boolean;
  @Input() enableManualAdd: boolean;
  @Input() editable: boolean;
  @Input() multiselect: boolean;
  @Input() invalid: boolean;
  @Input() placeholder: string;
  @Input() maxlength: number;
  @Input() options: CaiSelectOption[] = [];
  @Input() value: any;
  @Input() decimalPlace = 2;
  @Input() textTransform: "uppercase" | "lowercase";
  @Input() alignment: "left" | "right" | "center" = "left";
  @Output() editableChange = new EventEmitter();
  @Output() valueChange = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onLostFocus = new EventEmitter();
  @Output() onKeyDownEvent = new EventEmitter();

  selected: any;
  popperRef: Popper;
  view: EmbeddedViewRef<any>;
  lastValue: any;

  constructor (
    private readonly vcr: ViewContainerRef,
    private readonly zone: NgZone,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.hasOwnProperty("value")) {
      this.findMatch();
    }
  }

  get isDisabled (): boolean {
    return this.disabled;
  }

  get isEditable (): boolean {
    return this.editable && !this.selected;
  }

  isOpen (): boolean {
    return !!this.popperRef;
  }

  open (dropdownTpl: TemplateRef<any>, input: HTMLElement) {
    this.onFocus.emit();
    if (!!this.popperRef) {
      this.close();
    }

    if (this.options?.length) {
      this.view = this.vcr.createEmbeddedView(dropdownTpl);
      const dropdown = this.view.rootNodes[0];

      document.body.appendChild(dropdown);
      dropdown.style["z-index"] = "1";
      dropdown.style["min-width"] = `${input.offsetWidth}px`;

      this.zone.runOutsideAngular(() => {
        this.popperRef = new Popper(input, dropdown, {
          "placement": "bottom-end",
          "removeOnDestroy": true,
          "modifiers": {
            "preventOverflow": { "escapeWithReference": true },
          },
        });
      });

      this.cdr.detectChanges();
      this.handleClickOutside();
    }
  }

  select (option) {
    if (this.selected !== option || this.value !== option.value) {
      const value = option ? option.value : null;
      this.selected = option;
      this.value = value;
      this.close();
      this.valueChange.emit(value);
      this.cdr.detectChanges();
      this.onLostFocus.emit();
    }
  }

  check (option) {
    if (!this.value) {
      const value = option ? option.value : null;
      this.selected = option;
      this.value = value;
    } else {
      const filtered = this.value
        ?.split(",")
        ?.filter(
          (str) =>
            str &&
            str !== option.value &&
            !!this.options.find((opt) => opt.value === str)
        );
      if (this.isChecked(option)) {
        this.value = filtered.join(",");
      } else {
        this.value = filtered?.concat([option.value]).join(",");
      }
    }
    this.valueChange.emit(this.value);
    this.cdr.detectChanges();
    this.onLostFocus.emit();
  }

  isChecked (option): boolean {
    return this.value?.indexOf(option.value) > -1;
  }

  enableEdit () {
    this.selected = null;
    this.value = null;
    this.editable = true;
    this.editableChange.emit(true);
    this.close();
    this.valueChange.emit(null);
    setTimeout(() => {
      this.input.nativeElement.focus();
    });
  }

  handleClickOutside () {
    fromEvent(document, "click")
      .pipe(
        takeWhile(() => this.isOpen()),
        filter(({ target }) => {
          const origin = this.popperRef.reference as HTMLElement;
          return (
            origin.contains(target as HTMLElement) === false &&
            this.wrapper.nativeElement.contains(target as HTMLElement) === false
          );
        }),
        takeUntil(this.onClose)
      )
      .subscribe(() => {
        this.close();
        this.cdr.detectChanges();
      });
  }

  close () {
    this.onClose.emit();
    if (this.view) {
      this.view.destroy();
      this.view = null;
    }
  }

  onKeyPress ($event) {
    EventsUtil.handleInputFromKeyPress($event, this.value, this.input, () =>
      this.validateInput(this.value)
    );
  }

  onKeyDown ($event) {
    const key = $event.keyCode || $event.charCode;
    if (key === 8 || key === 46) {
      this.lastValue = this.value != null ? this.value : null;
    } else {
      let inputRegex = null;
      switch (this.type) {
        case CaiInputTypesEnum.DECIMAL:
          inputRegex = INPUT_REGEX_DECIMAL;
          break;
        case CaiInputTypesEnum.NUMERIC:
          inputRegex = INPUT_REGEX_NUMERIC;
          break;
        case CaiInputTypesEnum.ALPHABET:
          inputRegex = REGEX_ALPHABET;
          break;
        case CaiInputTypesEnum.ALPHANUMERIC:
          inputRegex = REGEX_ALPHANUMERIC;
          break;
      }
      if (inputRegex) {
        if (EventsUtil.validateInputByRegExp(inputRegex, $event.key)) {
          this.onKeyDownEvent.emit();
        } else {
          $event.preventDefault();
        }
      }
    }
  }

  onKeyUp ($event) {
    if (
      !EventsUtil.validateInputFromKeyup($event, this.value, () =>
        this.validateInput(this.value)
      )
    ) {
      this.value = this.lastValue;
    }
  }

  onPaste ($event) {
    EventsUtil.handleInputFromPaste($event, this.validateInput);
  }

  blur () {
    let value = this.value ? this.value.toString().trim() : "";
    if (value && this.type === CaiInputTypesEnum.DECIMAL) {
      if (this.decimalPlace !== null) {
        value = Number(value).toFixed(this.decimalPlace);
      }
      if (isNaN(value)) {
        value = "";
      }
    }
    this.findMatch();
    if (this.value !== value) {
      this.value = value;
      this.valueChange.emit(value);
    }
    this.onLostFocus.emit();
  }

  findMatch () {
    const matched = (this.options || []).find(
      (option) => option.value === this.value
    );
    if (matched && this.input?.nativeElement !== document.activeElement) {
      this.selected = matched;
    }
  }

  validateInput (value: string): boolean {
    if (!value) {
      return true;
    }
    let inputRegex,
     formatRegex;
    switch (this.type) {
      case CaiInputTypesEnum.DECIMAL:
        inputRegex = INPUT_REGEX_DECIMAL;
        formatRegex = FORMAT_REGEX_DECIMAL;
        break;
      case CaiInputTypesEnum.NUMERIC:
        inputRegex = INPUT_REGEX_NUMERIC;
        formatRegex = FORMAT_REGEX_NUMERIC;
        break;
      case CaiInputTypesEnum.ALPHABET:
        inputRegex = REGEX_ALPHABET;
        formatRegex = REGEX_ALPHABET;
        break;
      case CaiInputTypesEnum.ALPHANUMERIC:
        inputRegex = REGEX_ALPHANUMERIC;
        formatRegex = REGEX_ALPHANUMERIC;
        break;
    }
    if (inputRegex) {
      for (let i = 0; i < value.length; i++) {
        const char = value.charAt(i),
         _inputRegex = new RegExp(inputRegex);
        if (!_inputRegex.test(char)) {
          return false;
        }
      }
    }
    const _formatRegex = new RegExp(formatRegex);
    return _formatRegex.test(value);
  }
}
