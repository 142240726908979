import {
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  "selector": "kt-advanced-errors",
  "templateUrl": "./advanced-errors.component.html",
  "styleUrls": ["./advanced-errors.component.scss"],
})
export class AdvancedErrorsComponent {
  @ViewChildren("mainErrorDiv") errorDivs: QueryList<ElementRef>;
  @Input() fieldComponents: any[];
  @Input() formGroup: FormGroup;
  @Input() errors: any[];
}
