// Angular
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { CargoMartPlansType, UserTypeEnum } from '@cai-services';
import { BrandIconUtil, CONTACT_US_LINKS } from '../../../../utils/brand.util';
import {
  AIRLINE_HELP_CENTER_URL,
  FAQ_URL,
  FORWARDER_HELP_CENTER_URL,
  FORWARDER_RATES_TOUR_URL,
} from '../../../../core/_constants/constants';
import { HelpType } from '../../../../core/_enums/help-enum';
import { ApplicationProperties } from '../../../../core/_base/layout/models/app-properties.model';
import { APP_PROPS } from '../../../../cai-common.module';
import { SessionService } from '../../../../core/_services/session.service';
import { Office } from '../../../../core/_models/office.model';
import { productTourList } from './data';
@Component({
  selector: 'kt-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit, OnDestroy {
  @Input() userType: UserTypeEnum;
  selectedOffice: Office;
  productTours: any;
  officeChangedStatusSub: any;

  constructor(
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private readonly ref: ChangeDetectorRef,
    private readonly sessionService: SessionService,
  ) {}

  ngOnInit(): void {
    this.selectedOffice = this.sessionService.getSelectedOffice();
    this.officeChangedStatusSub =
      this.sessionService.officeChangedStatusObs.subscribe(() => {
        this.selectedOffice = this.sessionService.getSelectedOffice();
        this.productTours = productTourList?.filter(
          (tour) =>
            this.userType === tour?.userType &&
            (this.selectedOffice?.plan !== CargoMartPlansType.FREE ||
              tour?.link !== FORWARDER_RATES_TOUR_URL),
        );
      });
    this.productTours = productTourList?.filter(
      (tour) =>
        this.userType === tour?.userType &&
        (this.selectedOffice?.plan !== CargoMartPlansType.FREE ||
          tour?.link !== FORWARDER_RATES_TOUR_URL),
    );
  }

  ngOnDestroy(): void {
    if (this.officeChangedStatusSub) {
      this.officeChangedStatusSub.unsubscribe();
    }
  }

  public openNewTab(event: string, tourIndex?: number) {
    switch (event) {
      case HelpType.HELP_CENTER:
        {
          if (this.userType === UserTypeEnum.FORWARDER) {
            window.open(FORWARDER_HELP_CENTER_URL, '_blank');
          } else {
            window.open(AIRLINE_HELP_CENTER_URL, '_blank');
          }
        }
        break;
      case HelpType.FAQ:
        window.open(FAQ_URL, '_blank');
        break;
      case HelpType.CONTACT_US:
        const contactUsUrl = BrandIconUtil.isCargoAiDomain()
          ? CONTACT_US_LINKS.cargoai
          : CONTACT_US_LINKS[BrandIconUtil.fetchDomainTitle()?.toLowerCase()];
        window.open(contactUsUrl, '_blank');
        break;
      case HelpType.PRODUCT_TOUR:
        this.productTours[tourIndex].watched = true;
        localStorage.setItem(
          'productTourList',
          JSON.stringify(this.productTours),
        );
        this.ref.detectChanges();
        const office = this.sessionService.getSelectedOffice();
        const productTourLink = this.productTours[tourIndex]?.link?.replace(
          '{officeId}',
          office?.id,
        );
        window.open(productTourLink, '_blank');
        break;
      default:
        console.warn(`${event} should be handled`);
    }
  }

  get isCargoWallet(): boolean {
    return this.appProperties.name === 'cargowallet';
  }
}
