import { Office } from "./office.model";
import { Provider } from "./provider.model";

export class OfficeAdditionalInfo {
  office: Office;
  provider: Provider;
  customerId: string;
  isColoaderOnly: boolean;
  dateUpdated: Date;
}
