import { CaiSelectOption } from "@cai-framework";
import { IDangerousGood, IDgdDictionnary } from "@cai-services";

export class DangerousGood implements IDangerousGood {
  id: number;
  unid: string;
  goodClass: string;
  packagingGroup: string;
  packagingInstruction: string;
  totalNet: number;
  uom: string;
  shc: string;
  caoPackagingInstruction: string;
  caoTotalNet: number;
  caoUom: string;
  searchResult: IDgdDictionnary;
  goodClassOptions: CaiSelectOption[] = [];
  packagingGroupOptions: CaiSelectOption[] = [];
  packagingInstructionOptions: CaiSelectOption[] = [];
  shcOptions: CaiSelectOption[] = [];
  isPackagingInstructionEditable: boolean;
  uomOptions: CaiSelectOption[] = [];
  caoUomOptions: CaiSelectOption[] = [];
  isNewRow = true;
  isTouched = false;
  isMandatoryTouched = false;
  isFocused = false;
  errors: any[] = [];
}
