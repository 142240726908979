import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges, OnChanges,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  MeasurementSystemEnum,
  UserService,
  UserTypeEnum,
} from "@cai-services";
import Swal from "sweetalert2";
import _ from "lodash";
import { Router } from "@angular/router";
import { datadogRum } from "@datadog/browser-rum";
import { AutoCompleteValidators } from "../../../../../validators/must-in-list.validator";
import { Currency } from "../../../../../core/_models/currency.model";
import { environment } from "../../../../../environments";
import { GlobalService } from "../../../../../core/_services/global.service";
import {
  Language,
  languages,
} from "../../../../../../lib/components/language-selector/language";
import { SessionService } from "../../../../../core/_services/session.service";
import { User } from "../../../../../core/_models/user.model";

@Component({
  "selector": "kt-preferences-personal-settings",
  "templateUrl": "./preferences-personal.component.html",
  "styleUrls": ["preferences-personal.component.scss"],
})
export class PreferencesPersonalSettingsComponent implements OnInit, OnChanges {
  @Input() user: User;

  preferenceForm: FormGroup;
  currencies: Currency[];
  filteredCurrencies: Observable<Currency[]>;
  loading: boolean;
  measurementSystemEnum = MeasurementSystemEnum;
  languages = languages();
  searchDays = [];
  language: FormControl;
  features = environment.features;

  constructor (
    private readonly ref: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly globalService: GlobalService,
    private readonly userService: UserService,
    private readonly sessionService: SessionService,
    private readonly router: Router,
  ) {
    this.language = new FormControl(Validators.required);
    this.searchDays = [...Array(11).keys()].map((day) => ({
      "label": day > 0 ? `+ ${day} Day (s)` : "Current Date",
      "value": day,
    }));
    this.preferenceForm = this.formBuilder.group({
      "currency": new FormControl(null, { "validators": Validators.required }),
      "measurementSystem": new FormControl(Validators.required),
      "language": this.language,
      "defaultSearchDay": 0,
    });

    this.preferenceForm.get("currency").valueChanges.subscribe((value) => {
      this.preferenceForm
        .get("currency")
        .setValue(value?.toUpperCase(), { "emitEvent": false });
    });
  }

  async ngOnInit () {
    this.currencies = await this.globalService.getAllCurrencies();
    this.currencies = _.uniqBy(this.currencies, "currencyCode");
    this.preferenceForm
      .get("currency")
      .addValidators(
        AutoCompleteValidators.mustBeInList(this.currencies, "currencyCode"),
      );

    this.filteredCurrencies = this.preferenceForm
      .get("currency")
      .valueChanges.pipe(
        map((value) =>
          typeof value === "string" ? value : value.currencyCode,
        ),
        map((name) =>
          name ? this.filterCurrency(name) : this.currencies.slice(),
        ),
      );
    if (environment.datadog) {
      datadogRum.addFeatureFlagEvaluation(
        "newLanguages",
        this.features.newLanguages,
      );
    }
  }

  get isForwarder (): boolean {
    return this.user?.type === UserTypeEnum.FORWARDER;
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.user) {
      this.fillValues();
    }
  }

  fillValues () {
    this.preferenceForm.get("currency").setValue(this.user?.defaultCurrency);
    this.preferenceForm
      .get("measurementSystem")
      .setValue(this.user?.measurementSystem);
    this.preferenceForm.get("language").setValue(this.user?.language);
    this.preferenceForm
      .get("defaultSearchDay")
      .setValue(this.user?.defaultSearchDay);
  }

  filterCurrency (name: string): Currency[] {
    return this.currencies.filter(
      (option) =>
        option.currencyCode.toLowerCase().indexOf(name.toLowerCase()) === 0,
    );
  }

  getLanguage (locale: string): Language {
    return languages().find((l) => l.locale === locale);
  }

  translateApp (user: User, prevSelectedLanguage: string): void {
    const checkUrlLanguage =
      window.location.href?.indexOf(`/${user.language}/`) >= 0;
    if (prevSelectedLanguage !== user.language && !checkUrlLanguage) {
      if (this.router.url.indexOf(`/${prevSelectedLanguage}/`) >= 0) {
        const newUrl = this.router.url.replace(
          `/${prevSelectedLanguage}/`,
          `/${user.language}/`,
        );
        this.router.navigateByUrl(newUrl);
        return;
      }
      const new_route =
        (user.language !== "en" ? "/" + user.language : "") + this.router.url;
      window.location.replace(new_route);
    }
  }

  onSubmit (): void {
    this.loading = true;
    const updateUser = { ...this.user };
    updateUser.defaultCurrency = this.preferenceForm.get("currency").value
      ? this.preferenceForm.get("currency").value
      : "";
    updateUser.language = this.preferenceForm.get("language").value;
    updateUser.measurementSystem =
      this.preferenceForm.get("measurementSystem").value;
    updateUser.defaultSearchDay =
      this.preferenceForm.get("defaultSearchDay").value;
    this.userService
      .updateUser(updateUser)
      .then((user) => {
        const prevSelectedLanguage = this.user.language;
        this.user.defaultCurrency = user.defaultCurrency;
        this.user.measurementSystem = user.measurementSystem;
        this.user.language = user.language;
        this.sessionService.setCurrentUser(user);
        this.preferenceForm.markAsPristine();
        Swal.fire(
          $localize`:@@global.succeddfully-updated:Successfully updated!`,
          "",
          "success",
        ).then(() => {
          this.translateApp(user, prevSelectedLanguage);
        });

        this.loading = false;
        this.ref.detectChanges();
      })
      .catch((err) => {
        Swal.fire("Something went wrong!", err.response.message, "error");
        this.loading = false;
        this.ref.detectChanges();
      });
  }

  isControlHasError (
    form: FormGroup,
    controlName: string,
    validationType: string,
  ): boolean {
    const control = form.controls[controlName];
    if (!control) {
      return false;
    }
    return control.hasError(validationType);
  }
}
