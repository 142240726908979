import { FormControl, ValidationErrors } from "@angular/forms";

export const minItems = (numOfItems: number) => (
  control: FormControl,
): ValidationErrors | null => {
  const hasAtLeastNumOfItems =
    control &&
    control.value &&
    control.value.length &&
    control.value.length >= numOfItems;
  return hasAtLeastNumOfItems ? null : { "minItems": true };
};
