import { Component, Input, EventEmitter, Output } from "@angular/core";

@Component({
  "selector": "cai-checkbox",
  "templateUrl": "./checkbox.component.html",
  "styleUrls": ["./checkbox.component.scss"],
})
export class CaiCheckbox {
  @Input() isActive: boolean;
  @Input() id: string;
  @Input() label: string;
  @Input() fontSize = 16;
  @Input() size = 24;
  @Input() disabled: boolean;
  @Input() colorTick = "#679EC6";
  @Input() colorUntick = "#C4C4C4";
  @Input() showDisableColor: boolean;
  @Input() styles?;
  @Output() change = new EventEmitter<boolean>();

  onToggleCheckbox () {
    if (!this.disabled) {
      this.change.emit(!this.isActive);
    }
  }

  get showDisabled (): boolean {
    return this.disabled && this.showDisableColor;
  }
}
