export class QueryResultsModel {
  // fields
  items: any[];
  totalCount: number;
  errorMessage: string;

  constructor (
    _items: any[] = [],
    _totalCount = 0,
    _errorMessage = ""
  ) {
    this.items = _items;
    this.totalCount = _totalCount;
  }
}
