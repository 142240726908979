import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  SimpleChanges,
  ViewChildren,
  QueryList,
  ViewChild,
  Inject,
  OnChanges,
} from '@angular/core';
import {
  CountdownComponent,
  CountdownConfig,
  CountdownEvent,
} from 'ngx-countdown';
import { IntercomService } from '../../../core/_services/intercom.service';
import { BrandIconUtil } from '../../../utils/brand.util';
import { APP_PROPS } from '../../../cai-common.module';
import { ApplicationProperties } from '../../../core/_base/layout/models/app-properties.model';

@Component({
  selector: 'kt-new-otp-container',
  templateUrl: './new-otp-container.component.html',
  styleUrls: ['./new-otp-container.component.scss'],
})
export class NewOTPContainerComponent implements OnInit, OnChanges {
  @Input() isShowOTP: boolean;
  @Input('otpStatus') OTPStatus: OTPStatus;
  @Input() errorMessage: string;
  @Input() successMessage: string;
  @Input() title: string;
  @Input() expiredOTPMessage: string;
  @Output() onReceiveOTP = new EventEmitter<string>();
  @Output() onRequestNewOTP = new EventEmitter();
  @Output() goBack = new EventEmitter();

  @ViewChild('ngOtpInput') ngOtpInputRef: any;

  isCheckingOTP = false;
  isOnCountdown = false;
  timer = 3;
  interval: NodeJS.Timeout;

  otpCDConfig: CountdownConfig = {
    leftTime: 180,
    demand: true,
    format: 'mm:ss',
    prettyText: (text: string) => {
      const min = text.split(':')[0],
        sec = text.split(':')[1];
      return String(parseFloat(min) * 60 + parseInt(sec));
    },
  };

  @ViewChildren('otpCountdown')
  countdowns: QueryList<CountdownComponent>;
  otpCountDown: CountdownComponent;

  constructor(
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private readonly cdr: ChangeDetectorRef,
    private readonly intercomService: IntercomService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.OTPStatus) {
      const { currentValue } = changes.OTPStatus;

      if (
        currentValue === OTPStatus.incorrect ||
        currentValue === OTPStatus.expired
      ) {
        const elements = document.querySelectorAll('.otp-input');
        if (elements) {
          const element = elements[5] as HTMLElement;
          element?.blur();
        }
        this.isCheckingOTP = false;
        if (currentValue === OTPStatus.expired) {
          this.otpCountDown?.stop();
          this.isOnCountdown = false;
        }
      } else if (currentValue === OTPStatus.sent) {
        this.startCountdown();
      } else if (currentValue === OTPStatus.correct) {
        if (this.timer === 3) {
          this.interval = setInterval(() => {
            if (this.timer > 1) {
              this.timer -= 1;
            }
            this.cdr.detectChanges();
          }, 1000);
        } else if (this.timer <= 1) {
          clearInterval(this.interval);
        }
      }
    }
  }

  ngOnInit(): void {
    if (!this.errorMessage) {
      this.errorMessage = $localize`:@@otp.incorrect-otp:Incorrect OTP`;
    }
    if (!this.successMessage) {
      this.successMessage = $localize`:@@otp.correct-otp:Correct OTP!`;
    }
  }

  onOTPComplete(otpCode: string): void {
    if (this.isCheckingOTP || otpCode?.length < 6) {
      return;
    }
    if (!this.isOnCountdown) {
      this.onReceiveOTP.emit('expired');
    } else {
      this.onReceiveOTP.emit(otpCode);
    }
    this.isCheckingOTP = true;
    this.OTPStatus = OTPStatus.isChecking;

    this.cdr.detectChanges();
  }

  handleRequestNewOTP(): void {
    this?.ngOtpInputRef?.setValue('');
    this.onRequestNewOTP.emit();
  }

  startCountdown(): void {
    this.isOnCountdown = true;
    if (this.otpCountDown) {
      this.otpCountDown.restart();
      this.otpCountDown?.begin();
    } else {
      this.countdowns.changes.subscribe(
        (comps: QueryList<CountdownComponent>) => {
          this.otpCountDown = comps.first;
          this.otpCountDown?.begin();
        },
      );
    }
  }

  handleCountdownEvent(event: CountdownEvent): void {
    if (event.action === 'done') {
      this.isOnCountdown = false;
    }
  }
  handleNavigation(): void {
    this.goBack.emit();
  }

  handleContactUs() {
    this.intercomService.openIntercom();
  }

  openSite(): void {
    window.open('https://www.cargoai.co/', '_blank', 'noopener');
  }

  handleClick(event) {
    event.target.setSelectionRange(0, 0);
  }

  get otherBrand(): boolean {
    return !BrandIconUtil.isCargoAiDomain();
  }

  get backgroundImage(): string {
    return BrandIconUtil.fetchBackgroundIcon(
      this.appProperties.logo,
      '/assets/media/logos/',
    );
  }
}

export enum OTPStatus {
  isChecking = 'isChecking',
  incorrect = 'incorrect',
  correct = 'correct',
  isSending = 'isSending',
  sent = 'sent',
  expired = 'expired',
}
