import { SUPPORTED_LANGUAGES } from "../core";

export class AppUtil {
  static modifyUrl (url: string): string {
    const language = url.split("/")[3];

    if (SUPPORTED_LANGUAGES.includes(language)) {
      const urlParts = url.split("/");

      urlParts.splice(3, 1);

      let modifiedUrl = urlParts.join("/");

      modifiedUrl += "?language=" + language;

      return modifiedUrl;
    } else {
      return url;
    }
  }

  static getAppConfigPath (url: string): string {
    let appConfigPath = `${document.location.origin}/assets/environment/app-config.json`;
    if (url.includes("pr-")) {
      const prPrefix = "pr-" + url?.split("pr-")?.[1]?.split("/")?.[0];
      appConfigPath = `${document.location.origin}/${prPrefix}/assets/environment/app-config.json`;
    }
    return appConfigPath;
  }
}
