import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import { IPlanUpgrade } from "../../model/plan-upgrade.model";
import { API_CORE } from "../../constant/api-core.const";

@Injectable({
  "providedIn": "root",
})
export class PlanUpgradeService {
  async createUpgradePrefill (
    body: IPlanUpgrade,
    type: string,
  ): Promise<string> {
    return API.post(
      API_CORE.name,
      API_CORE.URLs.Plan.Upgrade.replace("{type}", type),
      { body },
    );
  }
  async applyDiscountCode (discountCode: string): Promise<any> {
    return API.get(API_CORE.name, API_CORE.URLs.Plan.Discount, {
      "queryStringParameters": { "code": discountCode },
    });
  }
  async captureUpgradeEvent (type: string, branches: number): Promise<void> {
    return API.post(
      API_CORE.name,
      API_CORE.URLs.Plan.Capture.replace(
        "{type}/{branches}",
        `${type}/${branches}`,
      ),
      {},
    );
  }
}
