import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IPlanUpgrade, PlanUpgradeService } from '@cai-services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Office, User } from '../../../../core/_models';
import { SessionService } from '../../../../core/_services';
import { CaiNumberFormatterPipe } from '../../../../pipes/number-formatter.pipe';

@Component({
  selector: 'cai-pro-generic-modal',
  templateUrl: './pro-generic-modal.component.html',
  styleUrls: ['./pro-generic-modal.component.scss'],
})
export class ProGenericModal implements OnInit {
  @Output() loading = new EventEmitter<boolean>();
  type: string;
  originOfficeId: number;
  upgradableOffices: Office[];
  selectedOffices: Office[] = [];
  branchesTotal: number;
  additionalOfficesTotal: number;
  isMarketAnalysis: boolean = false;
  isQuotation: boolean = false;
  addonAmount: number;
  discountCode: string;
  discount: { type: 'PERCENT' | 'ABSOLUTE'; value: number };
  appliedCode: string;
  errorMessage: string;
  isInfo: boolean;
  infoMessage: string;
  user: User;
  centerMessage: boolean;
  buttonsDisabled = false;

  constructor(
    private readonly router: Router,
    private readonly activeModal: NgbActiveModal,
    private readonly planUpgradeService: PlanUpgradeService,
    private readonly sessionService: SessionService,
    private readonly caiNumberFormatterPipe: CaiNumberFormatterPipe,
  ) {}

  ngOnInit(): void {
    this.user = this.sessionService.getCurrentUser();
    this.isInfo = [
      'SUCCESS',
      'FAILED',
      'INVOICE',
      'ERROR',
      'SUGGEST-UPGRADE',
    ].includes(this.type);
    if (this.isInfo) {
      this.infoMessage = this.getInfoMessage();
      this.centerMessage = this.type !== 'INVOICE' && this.type !== 'ERROR';
    } else if (this.type === 'UPGRADE') {
      this.additionalOfficesTotal =
        99 * (this.selectedOffices?.length - 1) * 12;
      this.branchesTotal = this.additionalOfficesTotal + 1788.0;
      this.addonAmount = this.selectedOffices.length * 99 * 12;
    }
  }

  getInfoMessage(): string {
    switch (this.type) {
      case 'SUCCESS':
        return $localize`:@@office-setting.your-upgrade-complete-start-exploring-pro:Your upgrade is completed. Start exploring the Pro features today!`;
      case 'FAILED':
        return $localize`:@@office-setting.if-still-like-to-upgrade-try-again-contact:If you would still like to upgrade to Pro, you can try again or contact us directly`;
      case 'INVOICE':
        return (
          $localize`:@@office-setting.sent-invoice-to-email:We've sent an invoice to your email` +
          ' ' +
          this.user?.userEmail
        );
      case 'ERROR':
        return $localize`:@@office-setting.error-generating-invoice:We're sorry, but we encountered an issue generating your invoice. Please try again later or contact our support team for immediate assistance.`;
      case 'SUGGEST-UPGRADE':
        return $localize`:@@office-setting.suggest-office-upgrade:Unlock this feature by upgrading to CargoMART Pro.<br>Elevate your office’s capabilities today!`;
    }
  }

  get subTotal(): number {
    return (
      this.branchesTotal +
      (this.isMarketAnalysis ? this.addonAmount : 0) +
      (this.isQuotation ? this.addonAmount : 0)
    );
  }

  get gst(): number {
    if (this.user?.country?.countryCode === 'SG') {
      return this.subTotal * 0.09;
    }
    return 0.0;
  }

  get discountAmount(): number {
    return this.discount?.type === 'ABSOLUTE'
      ? this.discount?.value
      : this.discount?.type === 'PERCENT'
        ? (this.subTotal * this.discount?.value) / 100
        : 0;
  }

  get total(): number {
    return (
      this.subTotal + this.gst - (this.discountAmount ? this.discountAmount : 0)
    );
  }

  get heading(): string {
    switch (this.type) {
      case 'SELECT':
        return $localize`:@@office-setting.select-branches:SELECT BRANCHES`;
      case 'UPGRADE':
        return $localize`:@@office-setting.upgrade-to-pro-plan:UPGRADE TO PRO PLAN`;
      case 'SUCCESS':
        return $localize`:@@office-setting.welcome-to-pro:WELCOME TO PRO`;
      case 'FAILED':
        return $localize`:@@office-setting.payment-unsuccessful:PAYMENT UNSUCCESSFUL`;
      case 'INVOICE':
        return $localize`:@@office-setting.invoice-sent:INVOICE SENT`;
      case 'ERROR':
        return $localize`:@@office-setting.error:ERROR`;
      case 'SUGGEST-UPGRADE':
        return $localize`:@@office-setting.suggest-upgrade:ACCESS PRO PLAN FEATURES`;
      default:
        return '';
    }
  }

  getBranches(): string {
    return (
      this.selectedOffices?.length +
      ' branch' +
      (this.selectedOffices?.length > 1 ? 'es' : '')
    );
  }

  officeLabel(office: Office): string {
    return (
      office?.country?.countryCode +
      '-' +
      (office?.airports[0]?.airportCode ?? '')
    );
  }

  get showClose(): boolean {
    return this.type !== 'UPGRADE';
  }

  closeModal(offices?: Office[]) {
    if (offices) {
      this.activeModal.close(offices);
    } else {
      this.activeModal.close();
    }
  }

  onToggle(office: Office) {
    if (this.selectedOffices?.includes(office)) {
      this.selectedOffices = this.selectedOffices?.filter((o) => o !== office);
    } else {
      this.selectedOffices.push(office);
    }
  }

  async applyDiscountCode() {
    if (this.discountCode) {
      try {
        this.discount = await this.planUpgradeService.applyDiscountCode(
          this.discountCode,
        );
        if (this.discount?.value) {
          this.discount.value = parseFloat(
            this.displayDecimalConditionally(this.discount.value),
          );
        }
      } catch {
        this.errorMessage = $localize`:@@office-setting.referral-code-invalid:This referral code is invalid`;
      }
      if (
        !this.discount?.type ||
        !this.discount?.value ||
        (this.discount?.type === 'ABSOLUTE' &&
          this.discount?.value > this.subTotal)
      ) {
        this.discount = null;
        this.errorMessage = $localize`:@@office-setting.referral-code-invalid:This referral code is invalid`;
      } else {
        this.appliedCode = this.discountCode;
        this.discountCode = null;
      }
    }
  }

  getDiscountValue() {
    if (this.discount?.type === 'ABSOLUTE') {
      return '$' + this.discount?.value;
    } else if (this.discount?.type === 'PERCENT') {
      return this.discount?.value + '%';
    }
  }

  displayDecimalConditionally(value: number): string {
    value = (value * 100 - ((value * 100) % 1)) / 100;
    const decimal = value - parseInt(value.toString());
    if (decimal < 0.01) {
      return parseInt(value.toString()).toString();
    }
    return this.caiNumberFormatterPipe.transform(value, false, 2, true);
  }

  changeCode(code: string) {
    this.discountCode = code;
    if (this.discountCode !== '' && !!this.discountAmount) {
      this.errorMessage = $localize`:@@office-setting.referral-code-already-applied:A referral code is already applied`;
    } else {
      this.errorMessage = null;
    }
  }

  get disabled(): boolean {
    if (this.type === 'SELECT' && this.selectedOffices.length === 0) {
      return true;
    }
    return false;
  }

  get primaryButtonLabel(): string {
    switch (this.type) {
      case 'SELECT':
        return $localize`:@@office-setting.next:NEXT`;
      case 'UPGRADE':
        return $localize`:@@office-setting.next-payment:NEXT:PAYMENT`;
      case 'SUCCESS':
        return $localize`:@@office-setting.ok:OK`;
      case 'FAILED':
        return $localize`:@@office-setting.view-plans:VIEW PLANS`;
      case 'INVOICE':
        return $localize`:@@office-setting.back-to-home:BACK TO HOME`;
      case 'ERROR':
        return $localize`:@@office-setting.ok:OK`;
      case 'SUGGEST-UPGRADE':
        return $localize`:@@office-setting.upgrade-now:UPGRADE NOW`;
      default:
        return '';
    }
  }

  async onPrimaryButtonClick() {
    if (!this.buttonsDisabled) {
      this.buttonsDisabled = true;
      switch (this.type) {
        case 'SELECT':
          if (this.selectedOffices.length > 0) {
            this.closeModal(this.selectedOffices);
          }
          break;
        case 'UPGRADE': {
          this.loading.emit(true);
          const officeIds = this.selectedOffices.map((office) => office.id);
          const url = await this.planUpgradeService.createUpgradePrefill(
            {
              amount: this.total,
              cargoIntel: !!this.isMarketAnalysis,
              quotation: !!this.isQuotation,
              officeIds,
              userId: this.user?.userId,
              originOfficeId: officeIds.includes(this.originOfficeId)
                ? this.originOfficeId
                : officeIds[0],
              gst: this.gst,
              discountType: this.discount?.type,
              discountValue: this.discount?.value,
              discountCode: this.appliedCode,
            } as IPlanUpgrade,
            'pay',
          );
          window.open(url, '_self');
          break;
        }
        case 'SUCCESS':
          this.activeModal.close();
          break;
        case 'FAILED':
          this.activeModal.close('view');
          break;
        case 'INVOICE':
          this.router.navigateByUrl('/forwarder/search/forwarder-search');
          break;
        case 'ERROR':
          this.activeModal.close();
          break;
        case 'SUGGEST-UPGRADE':
          {
            const currentUrl = this.router.url;
            if (currentUrl.includes('settings/office-settings#viewplans')) {
              window.location.reload();
            } else if (currentUrl.includes('settings/office-settings')) {
              this.router
                .navigateByUrl('settings/office-settings#viewplans')
                .then(() => {
                  window.location.reload();
                });
            } else {
              this.router.navigateByUrl('settings/office-settings#viewplans');
            }
          }
          break;
      }
    }
  }

  get secondaryButtonLabel(): string {
    if (this.type === 'UPGRADE') {
      return $localize`:@@office-setting.get-invoice-and-pay-later:GET INVOICE AND PAY LATER`;
    }
    if (this.type === 'FAILED') {
      return $localize`:@@office-setting.contact-us:CONTACT US`;
    }
    if (this.type === 'SUGGEST-UPGRADE') {
      return $localize`:@@office-setting.later:LATER`;
    }
    return '';
  }

  async onSecondaryButtonClick() {
    if (!this.buttonsDisabled) {
      this.buttonsDisabled = true;
      if (this.type === 'UPGRADE') {
        try {
          this.loading.emit(true);
          const officeIds = this.selectedOffices.map((office) => office.id);
          await this.planUpgradeService.createUpgradePrefill(
            {
              amount: this.total,
              cargoIntel: !!this.isMarketAnalysis,
              quotation: !!this.isQuotation,
              officeIds,
              userId: this.user?.userId,
              originOfficeId: officeIds.includes(this.originOfficeId)
                ? this.originOfficeId
                : officeIds[0],
              gst: this.gst,
              discountType: this.discount?.type,
              discountValue: this.discount?.value,
              discountCode: this.appliedCode,
            } as IPlanUpgrade,
            'invoice',
          );
          this.activeModal.close('invoice-sent');
        } catch {
          this.activeModal.close('invoice-failed');
        }
      }
      if (this.type === 'FAILED') {
        window.open('https://www.cargoai.co/contact-us/#enterprise', '_blank');
      }
      if (this.type === 'SUGGEST-UPGRADE') {
        this.activeModal.close();
      }
    }
  }
}
