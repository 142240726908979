import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { LoadTypeEnum } from "@cai-services";

@Component({
  "selector": "kt-advanced-load-type-switch",
  "templateUrl": "./advanced-load-type-switch.component.html",
  "styleUrls": ["./advanced-load-type-switch.component.scss"],
})
export class AdvancedLoadTypeSwitchComponent implements OnInit {
  @Input() selected: LoadTypeEnum = LoadTypeEnum.TOTAL;
  @Input() collapsed: boolean;
  @Input() isValidDimensions: boolean;
  @Input() isValidBUPs: boolean;
  @Output() selectedChange = new EventEmitter<LoadTypeEnum>();
  @Output() collapsedChange = new EventEmitter<boolean>();

  loadTypeEnum = LoadTypeEnum;

  constructor (private readonly cdr: ChangeDetectorRef) {}

  ngOnInit (): void {}

  isSelected (selected: LoadTypeEnum): boolean {
    return this.selected === selected;
  }

  select (selected: LoadTypeEnum) {
    if (this.selected !== selected) {
      this.selected = selected;
      this.selectedChange.emit(selected);
      this.cdr.detectChanges();
    }
  }

  get enableToggle (): boolean {
    if (this.selected === LoadTypeEnum.DIMENSIONS) {
      return this.isValidDimensions;
    } else if (this.selected === LoadTypeEnum.BUP) {
      return this.isValidBUPs;
    }
    return false;
  }

  toggle () {
    this.collapsed = !this.collapsed;
    this.collapsedChange.emit(this.collapsed);
    this.cdr.detectChanges();
  }
}
