import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Optional,
  Self,
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { MeasurementSystemEnum } from "@cai-services";
import { CaiLoadTypeQuantityInputBaseComponent } from "../load-type/components/load-type-quantity-input/load-type-quantity-input-base.component";

@Component({
  "selector": "kt-advanced-qty-input",
  "templateUrl": "./advanced-qty-input.component.html",
  "styleUrls": ["./advanced-qty-input.component.scss"],
})
export class AdvancedQtyInputComponent extends CaiLoadTypeQuantityInputBaseComponent {
  measurementSystemEnum = MeasurementSystemEnum;
  @Input() labelCenter = true;

  constructor (
    @Self()
    @Optional()
    protected readonly control: NgControl,
    protected readonly cdr: ChangeDetectorRef,
    private readonly el: ElementRef,
  ) {
    super(control, cdr);
  }

  onKeyUp ($event) {
    const key = $event.keyCode || $event.charCode;
    if (key === 9) {
      return;
    }
    const value = this.value;
    if (
      [8, 49].includes(key) &&
      value &&
      !this.validateInput(value.toString())
    ) {
      this.value = this.lastValue;
    }
    if (
      ![null, undefined].includes(value) ||
      (value && this.validateInput(value.toString()))
    ) {
      this.onChange(this.value);
    }
  }

  matchMeasurementSystem (name: MeasurementSystemEnum): boolean {
    if (name === MeasurementSystemEnum.METRIC && this.unit === "kg") {
      return true;
    }
    if (name === MeasurementSystemEnum.IMPERIAL && this.unit === "lb") {
      return true;
    }
    return false;
  }

  get isFocused (): boolean {
    return this.input?.nativeElement === document.activeElement;
  }

  get boundingClientRect () {
    return this.el.nativeElement.getBoundingClientRect();
  }
}
