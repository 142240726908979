import { Injectable } from '@angular/core';
import { API } from '@aws-amplify/api';
import { ContactTypeEnum, PermissionNameEnum } from '../../enum';
import { SearchTemplateDeleteTypeEnum } from '../../enum/search-template-delete-type.enum';
import { UserTypeEnum } from '../../enum/user-type.enum';
import { IAirlineUser } from '../../model/airline-user.model';
import { IForwarder } from '../../model/forwarder.model';
import { INotificationSetting } from '../../model/notification-setting.model';
import { IPrefillInformation } from '../../model/prefill-information.model';
import { ISearchTemplate } from '../../model/search-template.model';
import { ISendConfirmResponse } from '../../model/send-confirm-response.model';
import { IUserStatistic } from '../../model/user-statistic.model';
import { IUser } from '../../model/user.model';
import { IUserAdsInfo, UserAdsEnum } from '../../model/user-ads-info.model';
import { ErrorUtil } from '../../utils/error.util';
import { API_CORE } from '../../constant/api-core.const';
import { IAgentDetail } from '../../model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  async getSearchHistory(): Promise<ISearchTemplate[]> {
    return API.get(API_CORE.name, API_CORE.URLs.User.RecentSearches, {}).catch(
      ErrorUtil.handleError,
    );
  }

  async getSearchTemplates(): Promise<ISearchTemplate[]> {
    return API.get(API_CORE.name, API_CORE.URLs.User.SearchTemplates, {}).catch(
      ErrorUtil.handleError,
    );
  }

  async getSearchTemplate(templateId: number): Promise<ISearchTemplate> {
    const url = API_CORE.URLs.User.SearchTemplateByTemplateId.replace(
      '{templateId}',
      templateId.toString(),
    );

    return API.get(API_CORE.name, url, {}).catch(ErrorUtil.handleError);
  }

  async deleteSearchTemplate(
    templateId: number,
    type: SearchTemplateDeleteTypeEnum,
  ): Promise<void> {
    const url = API_CORE.URLs.User.SearchTemplateByTemplateId.replace(
        '{templateId}',
        templateId.toString(),
      ),
      extraOptions = {
        queryStringParameters: { type },
      };
    return API.del(API_CORE.name, url, extraOptions).catch(
      ErrorUtil.handleError,
    );
  }

  async createSearchTemplate(
    searchTemplate: ISearchTemplate,
  ): Promise<ISearchTemplate> {
    return API.post(API_CORE.name, API_CORE.URLs.User.SearchTemplates, {
      body: searchTemplate,
    }).catch(ErrorUtil.handleError);
  }

  async updateSearchTemplate(
    searchTemplate: ISearchTemplate,
  ): Promise<ISearchTemplate> {
    const url = API_CORE.URLs.User.SearchTemplateByTemplateId.replace(
      '{templateId}',
      searchTemplate.searchTemplateId.toString(),
    );
    return API.put(API_CORE.name, url, {
      body: searchTemplate,
    }).catch(ErrorUtil.handleError);
  }

  async saveUserAdsInfo(adsInfo: IUserAdsInfo[]) {
    return API.post(API_CORE.name, API_CORE.URLs.User.UserAdsInfo, {
      body: adsInfo,
    }).catch(ErrorUtil.handleError);
  }

  async getAgentDetails(code: string, name: string): Promise<IAgentDetail> {
    const requestURL = API_CORE.URLs.User.AgentCode,
      queryStringParameters = { name, code };
    return API.get(API_CORE.name, requestURL, {
      queryStringParameters,
    }).catch(ErrorUtil.handleError);
  }

  async getUserAdsInfo(): Promise<IUserAdsInfo[]> {
    return API.get(API_CORE.name, API_CORE.URLs.User.UserAdsInfo, {}).catch(
      ErrorUtil.handleError,
    );
  }

  async retrieveAllStatistics(): Promise<IUserStatistic> {
    return API.get(API_CORE.name, API_CORE.URLs.User.Statistics, {}).catch(
      ErrorUtil.handleError,
    );
  }

  async getHMAC(token?: string): Promise<any> {
    let extraOptions = {};
    if (token) {
      extraOptions = {
        queryStringParameters: { token },
      };
    }
    return API.get(API_CORE.name, API_CORE.URLs.User.HMAC, extraOptions).catch(
      ErrorUtil.handleError,
    );
  }

  async getPrefillInformation(token?: string): Promise<IPrefillInformation> {
    let extraOptions = {};
    if (token) {
      extraOptions = {
        queryStringParameters: { token },
      };
    }
    return API.get(
      API_CORE.name,
      API_CORE.URLs.User.PrefillInformation,
      extraOptions,
    ).catch(ErrorUtil.handleError);
  }

  async validateEmail(token: string): Promise<void> {
    const extraOptions = {
      queryStringParameters: { token },
    };
    return API.put(
      API_CORE.name,
      API_CORE.URLs.User.ValidateEmail,
      extraOptions,
    ).catch(ErrorUtil.handleError);
  }

  async checkExistingEmail(
    email: string,
    userType: UserTypeEnum,
  ): Promise<boolean> {
    const extraOptions = {
      response: true,
      queryStringParameters: { email, userType },
    };
    return API.get(API_CORE.name, API_CORE.URLs.User.EmailStatus, extraOptions)
      .then((response) => response.status === 200)
      .catch(ErrorUtil.handleError);
  }

  async resendEmail(token: string): Promise<ISendConfirmResponse> {
    return API.put(API_CORE.name, API_CORE.URLs.User.ConfirmEmail, {
      body: {
        expiredToken: token,
      },
    }).catch(ErrorUtil.handleError);
  }

  async getAirlineByCompanyAndOrigin(
    airlineCompanyCode: string,
    cityCode: string,
    role: ContactTypeEnum,
  ): Promise<IAirlineUser[]> {
    return API.get(API_CORE.name, API_CORE.URLs.User.Users, {
      queryStringParameters: {
        type: UserTypeEnum.AIRLINE,
        airlineCode: airlineCompanyCode,
        airport: cityCode,
        role,
      },
    }).catch(ErrorUtil.handleError);
  }

  async registerUser(user: IUser, type: string): Promise<IUser> {
    const url = API_CORE.URLs.User.RegisterUser.replace(
      '{type}',
      type.toString(),
    );
    return API.post(API_CORE.name, url, {
      body: user,
    }).catch(ErrorUtil.handleError);
  }

  async deleteUserOfficeAd(officeId: string, campaign: UserAdsEnum) {
    const url = API_CORE.URLs.User.officeCampaign
      .replace('{officeId}', officeId)
      .replace('{campaign}', campaign);
    return API.del(API_CORE.name, url, {}).catch(ErrorUtil.handleError);
  }

  async updateUser(user: IUser): Promise<IUser> {
    return API.put(API_CORE.name, API_CORE.URLs.User.CurrentUser, {
      body: user,
    }).catch(ErrorUtil.handleError);
  }

  async getCurrentUser(token?: string): Promise<IUser> {
    let extraOptions = {};
    if (token) {
      extraOptions = {
        queryStringParameters: { token },
      };
    }
    return API.get(
      API_CORE.name,
      API_CORE.URLs.User.CurrentUser,
      extraOptions,
    ).catch(ErrorUtil.handleError);
  }

  async getForwarders(): Promise<IForwarder[]> {
    return API.get(API_CORE.name, API_CORE.URLs.User.ForwarderUsers, {}).catch(
      ErrorUtil.handleError,
    );
  }

  async getNSByForwarder(): Promise<INotificationSetting[]> {
    return API.get(
      API_CORE.name,
      API_CORE.URLs.User.NotificationSettings,
      {},
    ).catch(ErrorUtil.handleError);
  }

  async updateNSByForwarder(
    notificationSetting: INotificationSetting[],
  ): Promise<INotificationSetting[]> {
    return API.post(API_CORE.name, API_CORE.URLs.User.NotificationSettings, {
      body: notificationSetting,
    }).catch(ErrorUtil.handleError);
  }

  async updateForwarder(
    forwarder: IForwarder,
    token?: string,
  ): Promise<IForwarder> {
    const url = API_CORE.URLs.User.ForwarderUserById.replace(
        '{userId}',
        forwarder.userId.toString(),
      ),
      extraOptions = {
        body: forwarder,
        queryStringParameters: {} as any,
      };
    if (token) {
      extraOptions.queryStringParameters.token = token;
    }
    return API.put(API_CORE.name, url, extraOptions).catch(
      ErrorUtil.handleError,
    );
  }

  async updateStandards(standard: IUser, token?: string): Promise<IForwarder> {
    const url = API_CORE.URLs.User.StandardsUserById.replace(
        '{userId}',
        standard.userId.toString(),
      ),
      extraOptions = {
        body: standard,
        queryStringParameters: {} as any,
      };
    if (token) {
      extraOptions.queryStringParameters.token = token;
    }
    return API.put(API_CORE.name, url, extraOptions).catch(
      ErrorUtil.handleError,
    );
  }

  async updateAirline(
    airline: IAirlineUser,
    airlineCompanyCode: string,
    token?: string,
  ): Promise<IAirlineUser> {
    const url = API_CORE.URLs.User.AirlinerUserById.replace(
        '{userId}',
        airline.userId.toString(),
      ),
      extraOptions: any = {
        body: airline,
        queryStringParameters: {} as any,
      };
    if (airlineCompanyCode) {
      extraOptions.queryStringParameters.airline = airlineCompanyCode;
    }
    if (token) {
      extraOptions.queryStringParameters.token = token;
    }
    return API.put(API_CORE.name, url, extraOptions).catch(
      ErrorUtil.handleError,
    );
  }

  hasPermission(
    currentUser: IUser,
    officeId: number,
    permissionName: PermissionNameEnum,
  ): boolean {
    if (
      currentUser.officeAccesses === undefined ||
      currentUser.officeAccesses.length === 0
    ) {
      return false;
    }

    const accessForOffice = currentUser.officeAccesses.find(
      (officeAccess) => officeAccess.office?.id === officeId,
    );
    if (accessForOffice === undefined) {
      return false;
    }

    const permissionList = accessForOffice.roles
      .map((role) => role.permissions)
      .reduce(
        (roleAPermissions, roleBPermissions) => [
          ...roleAPermissions,
          ...roleBPermissions,
        ],
        [],
      );

    return permissionList.some(
      (permission) => PermissionNameEnum[permission.name] === permissionName,
    );
  }

  async getSupersetToken(
    dashboardId: string,
    originCountry?: string,
    dashboardName?: string,
  ): Promise<string> {
    const extraOptions: any = {
      queryStringParameters: { dashboardId },
    };
    if (originCountry) {
      extraOptions.queryStringParameters.originCountry = originCountry;
    }
    if (dashboardName) {
      extraOptions.queryStringParameters.dashboardName = dashboardName;
    }
    const res = await API.get(
      API_CORE.name,
      API_CORE.URLs.User.SupersetToken,
      extraOptions,
    );
    return res;
  }
}
