// Angular
import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";
@Pipe({
  "name": "timeDiffer",
})
export class TimeDiffer implements PipeTransform {
  transform (startDate, endDate, dayDiff: number): number {
    const totalDayDiffInMinutes = 24 * 60 * dayDiff,
     diffInMinutes = moment(endDate).diff(moment(startDate), "minutes");
    return (diffInMinutes / totalDayDiffInMinutes) * 100;
  }

  public getTimeUntilEnd (time: string, dayDiff: number): number {
    const [hours, minutes] = time.split("T")?.[1].split(":"),
     totalDayDiffInMinutes = 24 * 60,
     totalMinutes = Number(hours) * 60 + Number(minutes);
    return ((1 - totalMinutes / totalDayDiffInMinutes) * 100) / dayDiff;
  }
}
