import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({
  "name": "dateNoTimezone",
})
export class DateNoTimezonePipe implements PipeTransform {
  // NOTE: assumes date is in ISO format
  transform (date: string, args?: string): string {
    const dateNoTimezone = date.substring(0, 16);
    return moment(dateNoTimezone).format(args);
  }
}
