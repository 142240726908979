<div class="kt-login__icon1">
  <kt-svg-location
    class="icons iconShadow"
    [startColor]="isWalletApp ? '#F3F9FD' : ''"
    [endColor]="isWalletApp ? '#49B2FF' : ''"
    [class.activeIcon1]="currentSlider === 1"
    [class.fadeIn]="currentSlider === 3"
  ></kt-svg-location>
  <kt-svg-clock
    [startColor]="isWalletApp ? '#F3F9FD' : ''"
    [endColor]="isWalletApp ? '#49B2FF' : ''"
    class="icons iconShadow"
    [class.activeIcon2]="currentSlider === 2"
  ></kt-svg-clock>
  <kt-svg-leaf
    [startColor]="isWalletApp ? '#F3F9FD' : ''"
    [endColor]="isWalletApp ? '#49B2FF' : ''"
    class="icons iconShadow"
    [class.activeIcon3]="currentSlider === 3"
  ></kt-svg-leaf>
</div>

<div class="kt-login__icon2">
  <kt-svg-big-package
    [startColor]="isWalletApp ? '#53A8E7' : ''"
    [endColor]="isWalletApp ? '#F3F9FD' : ''"
    class="icons"
    [class.activeIcon1]="currentSlider === 1"
    [class.fadeIn]="currentSlider === 3"
  ></kt-svg-big-package>
  <kt-svg-big-leaf
    [startColor]="isWalletApp ? '#53A8E7' : ''"
    [endColor]="isWalletApp ? '#F3F9FD' : ''"
    class="icons"
    [class.activeIcon2]="currentSlider === 2"
  ></kt-svg-big-leaf>
  <kt-svg-big-flight
    [startColor]="isWalletApp ? '#53A8E7' : ''"
    [endColor]="isWalletApp ? '#F3F9FD' : ''"
    class="icons"
    [class.activeIcon3]="currentSlider === 3"
  ></kt-svg-big-flight>
</div>

<div class="kt-login__icon3">
  <kt-svg-search
    class="icons iconShadow"
    [startColor]="isWalletApp ? '#53A8E7' : ''"
    [endColor]="isWalletApp ? '#F3F9FD' : ''"
    [class.activeIcon1]="currentSlider === 1"
    [class.fadeIn]="currentSlider === 3"
  ></kt-svg-search>
  <kt-svg-flight
    [startColor]="isWalletApp ? '#53A8E7' : ''"
    [endColor]="isWalletApp ? '#F3F9FD' : ''"
    class="icons iconShadow"
    [class.activeIcon2]="currentSlider === 2"
  ></kt-svg-flight>
  <kt-svg-package
    [startColor]="isWalletApp ? '#53A8E7' : ''"
    [endColor]="isWalletApp ? '#F3F9FD' : ''"
    class="icons iconShadow"
    [class.activeIcon3]="currentSlider === 3"
  ></kt-svg-package>
</div>
