import {
  ChangeDetectorRef,
  Component,
  Input,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { LoadTypeEnum, MeasurementSystemEnum } from "@cai-services";
import { MeasurementUnit } from "../../core/_models/measurement-system.model";
import { AdvancedQtyInputComponent } from "../advanced-qty-input/advanced-qty-input.component";
import { CaiLoadTypeBaseComponent } from "../load-type/load-type-base.component";
import { LoadTypeUnit } from "../load-type/model/load-type-unit.model";

@Component({
  "selector": "kt-advanced-load-type",
  "templateUrl": "./advanced-load-type.component.html",
  "styleUrls": ["./advanced-load-type.component.scss"],
})
export class AdvancedLoadTypeComponent extends CaiLoadTypeBaseComponent {
  @ViewChildren("qtyField") qtyFields: QueryList<AdvancedQtyInputComponent>;
  @Input() viewOnly: boolean;
  @Input() noUpdateOnBlur: boolean;
  @Input() disableUnitSelector: boolean;

  constructor (
    protected readonly fb: FormBuilder,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(fb, cdr);
  }

  get isDisabled (): boolean {
    return this.loadTypeMode !== LoadTypeEnum.TOTAL;
  }

  selectedUnitChange (unitState: LoadTypeUnit, $event: MeasurementUnit): void {
    if (unitState) {
      unitState.weightUnitDetail = $event;
      this.convertTotalWeight(unitState, $event);
    }
  }

  measurementSystemChange (
    unitState: LoadTypeUnit,
    $event: MeasurementSystemEnum,
  ) {
    if (unitState) {
      unitState.measurementSystem = $event;
    }
  }
}
