// Angular
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DeleteEntityDialogComponent } from "../../../../components/delete-entity-dialog/delete-entity-dialog.component";
import { ActionNotificationComponent } from "../../../../components/action-notification/action-notification.component";
import { CopyBannerDialogComponent } from "../../../../components/copy-banner-dialog/copy-banner-dialog.component";
export enum MessageType {
  Create,
  Read,
  Update,
  Delete,
}

@Injectable({ "providedIn": "root" })
export class LayoutUtilsService {
  /**
   * Service constructor
   *
   * @param snackBar: MatSnackBar
   * @param dialog: MatDialog
   */
  constructor (private snackBar: MatSnackBar, private dialog: MatDialog) {}

  /**
   * Showing (Mat-Snackbar) Notification
   *
   * @param message: string
   * @param type: MessageType
   * @param duration: number
   * @param showCloseButton: boolean
   * @param showUndoButton: boolean
   * @param undoButtonDuration: number
   * @param verticalPosition: 'top' | 'bottom' = 'top'
   */
  showActionNotification (
    _message: string,
    _type: MessageType = MessageType.Create,
    _duration = 10000,
    _showCloseButton = true,
    _showUndoButton = true,
    _undoButtonDuration = 3000,
    _verticalPosition: "top" | "bottom" = "bottom"
  ) {
    const _data = {
      "message": _message,
      "snackBar": this.snackBar,
      "showCloseButton": _showCloseButton,
      "showUndoButton": _showUndoButton,
      "undoButtonDuration": _undoButtonDuration,
      "verticalPosition": _verticalPosition,
      "type": _type,
      "action": "Undo",
    };
    return this.snackBar.openFromComponent(ActionNotificationComponent, {
      "duration": _duration,
      "data": _data,
      "verticalPosition": _verticalPosition,
      "panelClass": "custom-snack-bar",
    });
  }

  /**
   * Showing Confirmation (Mat-Dialog) before Entity Removing
   *
   * @param title: stirng
   * @param description: stirng
   * @param waitDesciption: string
   */
  deleteElement (
    title = "",
    description = "",
    waitDesciption = ""
  ) {
    return this.dialog.open(DeleteEntityDialogComponent, {
      "data": { title, description, waitDesciption },
      "width": "440px",
    });
  }

  confirmElement (
    title = "",
    description = "",
    waitDesciption = "",
    confirmLabel?: string
  ) {
    return this.dialog.open(DeleteEntityDialogComponent, {
      "data": { title, description, waitDesciption, confirmLabel },
      "width": "440px",
    });
  }

  openCopyBannerDialog () {
    return this.dialog.open(CopyBannerDialogComponent, {
      "data": { "newBannerId": "" },
      "width": "480px",
    });
  }
}
