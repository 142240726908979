import { Component, Input } from "@angular/core";

@Component({
  "selector": "kt-popover",
  "templateUrl": "./popover.component.html",
  "styleUrls": ["./popover.component.scss"],
})
export class PopoverComponent {
  @Input() arrowColor: string;
  @Input() bgColor: string;
  @Input() textColor: string;
  @Input() header: string;
  @Input() subheader: string;
  @Input() message: string;
  @Input() messageHtml: string;
  @Input() secondaryMessage: string;
  @Input() footer: string;
  @Input() showIcon: boolean;
  @Input() width: number;
  @Input() maxWidth?: string = "";
  @Input() additionalClass: string;
  @Input() placement = "bottom-end";
  @Input() highlightMessage: string;

  get ngClass (): string[] {
    return [this.placement, this.additionalClass].filter((_class) => _class);
  }
}
