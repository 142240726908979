export enum WalletStatusTypeEnum {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  INACTIVE = "INACTIVE",
  BLOCKED = "BLOCKED",
  NONE = "NONE",
}

export enum CargoMartPlansType {
  FREE = "FREE",
  TRIAL = "TRIAL",
  PRO = "PRO",
}

export enum TransactionStatusEnum {
  WAITING_FUND = "WAITING_FUND",
  ON_HOLD = "ON_HOLD",
  ON_HOLD_BENEFICIARY = "ON_HOLD_BENEFICIARY",
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
  WAITING_FOR_PAYER = "WAITING_FOR_PAYER",
  OVER_DUE = "OVER_DUE",
  SETTLED = "SETTLED",
  AWAITING_APPROVAL = "AWAITING_APPROVAL",
}
export enum WalletTopupPaymentTypeEnum {
  LOCAL_BANK = "LOCAL_BANK",
  INTERNATIONAL_BANK = "INTERNATIONAL_BANK",
  CREDIT_CARD = "CREDIT_CARD",
  OTHER = "OTHER",
}

export enum WalletPaymentTypesEnum {
  IATA_CASS = "IATA_CASS",
  WALLET_PREPAID = "WALLET_PREPAID",
  WALLET_LATER = "WALLET_LATER",
  NONE = "NONE",
}
export enum WalletPaymentTerms {
  TERM_30 = "TERM_30",
  TERM_60 = "TERM_60",

  NONE = "NONE",
}

export enum WalletFeesTypes {
  PayLaterFee = "LATER_CARGOAI_FEE",
  ExportFee = "export_fee",
  BookingFee = "booking_fee",
  ConversionFee = "conversion_fee",
  TazapayTransactionFee = "TAZAPAY_TRANSACTION_FEE",
  LaterPayTo = "LATER_PAY_TO",
  PrepaidPayToCargoFee = "PREPAID_PAY_TO_CARGOAI_FEE",
  LaterPaytoCargoFee = "LATER_PAY_TO_CARGOAI_FEE",
  TopUpFee = "TOP_UP_FEE",
  PayOutFee = "TAZAPAY_PAYOUT_TRANSACTION_FEE",
  PrepaidPayTo = "PREPAID_PAY_TO",
  PaymentFee = "PAYMENT_FEE",
  PrepaidFee = "LATER_CARGOAI_FEE",
  PrepaidReconciliationFee = "PREPAID_RECONCILIATION_FEE",
}
