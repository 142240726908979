import { InjectionToken, ModuleWithProviders, NgModule } from "@angular/core";
import { ApplicationProperties } from "./core";

export const APP_PROPS = new InjectionToken<any>(
  "Provide application properties as injection token"
);

@NgModule({})
export class CaiCommonModule {
  static forRoot (props: ApplicationProperties): ModuleWithProviders<any> {
    return {
      "ngModule": CaiCommonModule,
      "providers": [
        {
          "provide": APP_PROPS,
          "useValue": props,
        },
      ],
    };
  }
}
