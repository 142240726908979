import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import { ErrorUtil } from "../../utils/error.util";
import { API_CORE } from "../../constant/api-core.const";

@Injectable({
  "providedIn": "root",
})
export class SsoService {
  async fetchSsoConfigs (): Promise<any> {
    return API.get(API_CORE.name, API_CORE.URLs.Sso.ssoConfig, {}).catch(
      ErrorUtil.handleError
    );
  }
}
