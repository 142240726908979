import { Inject, Injectable, OnDestroy } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import {
  CompanyService,
  IPermissionSet,
  IRateInsight,
  WalletStatusTypeEnum,
} from '@cai-services';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { APP_PROPS } from '../../cai-common.module';
import { getDefaultOffice } from '../../helpers/office-helpers';
import { ApplicationProperties } from '../_base/layout/models/app-properties.model';
import {
  AIRLINE_GROUP,
  DEFAULT_DATE_FORMAT,
  FORWARDER_GROUP,
} from '../_constants/constants';
import { Aircraft } from '../_models/aircraft.model';
import { AirlineBrand } from '../_models/airline.model';
import { AirportLight } from '../_models/airport-light.model';
import { City } from '../_models/city.model';
import { Company } from '../_models/company.model';
import { Country } from '../_models/country.model';
import { Currency } from '../_models/currency.model';
import { FlightInput } from '../_models/flight-input.model';
import { Office } from '../_models/office.model';
import { Quote } from '../_models/quote.model';
import { SearchTemplate } from '../_models/search-template.model';
import { SpecialHandlingCode } from '../_models/special-handling-code.model';
import { TypeOfProduct } from '../_models/type-of-product.model';
import { User } from '../_models/user.model';
import { AcceptQuoteService } from './bookingConfirmation/accept-quote.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService implements OnDestroy {
  private originName: string;
  private token: any;
  private destiName: string;
  private storageSub = new Subject<{ key: string; data: any }>();
  private sessionStorageSub = new Subject<{ key: string; data: any }>();
  private officeChangedStatus = new BehaviorSubject<boolean>(false);
  private searchOfficeChangedStatus = new BehaviorSubject<boolean>(false);
  private walletCurrency = new BehaviorSubject<{
    flag: boolean;
    currency: any;
  }>({ flag: false, currency: '' });
  officeChangedStatusObs = this.officeChangedStatus.asObservable();
  searchOfficeChangedStatusObs = this.searchOfficeChangedStatus.asObservable();
  walletCurrencyObs = this.walletCurrency.asObservable();
  private interval: NodeJS.Timeout;

  public sameRouteNotificationQuoteSelected = new Subject<number>();

  constructor(
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private acceptQuoteService: AcceptQuoteService,
    private readonly companyService: CompanyService,
    private readonly route: ActivatedRoute,
  ) {
    sessionStorage.serialNumber = 0;
    if (localStorage.getItem('browser_key') == null) {
      const newBrowserKey = uuid();
      localStorage.setItem('browser_key', newBrowserKey);
    }
    this.prepareBookingScheduler();

    this.watchSessionStorage().subscribe((sessionStorageItem) => {
      // sessionStorage 'currentUser' is always updated after /me endpoint is called
      if (sessionStorageItem.key === 'currentUser') {
        if (!this.getCurrentUser()) {
          this.token = this.route.snapshot.queryParamMap.get('token');
          if (this.token) {
            this.initSelectedOffice(this.token);
          } else {
            this.initSelectedOffice();
          }
        }
      }
    });
    this.interval = setInterval(() => {
      const currentUser = this.getCurrentUser();
      if (!currentUser) {
        return;
      }
      this.token = this.route.snapshot.queryParamMap.get('token');
      if (this.token) {
        this.initOffices(this.token);
      } else {
        this.initOffices();
      }
    }, 1600000);
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  watchSessionStorage(): Observable<any> {
    return this.sessionStorageSub.asObservable();
  }

  private setItem(key: string, data: any) {
    localStorage.setItem(key, data);
    this.storageSub.next({ key, data });
  }

  private getItem(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  private setExpirableItem(key: string, value: any) {
    const now = new Date(),
      expiryMillis = 1800000, // expired in 30 mins
      item = {
        value,
        expiry: now.getTime() + expiryMillis,
      };
    localStorage.setItem(key, JSON.stringify(item));
  }

  private setExpirableSessionItem(key: string, value: any) {
    const now = new Date(),
      expiryMillis = 1800000, // expired in 30 mins
      item = {
        value,
        expiry: now.getTime() + expiryMillis,
      };
    sessionStorage.setItem(key, JSON.stringify(item));
  }

  private getExpirableSessionItem(key: string) {
    const itemStr = sessionStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr),
      now = new Date();
    if (now.getTime() > item.expiry) {
      sessionStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

  private getExpirableItem(key: string) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr),
      now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

  getItemSessionStorage(key: string) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  setItemSessionStorage(key: string, data: any) {
    return sessionStorage.setItem(key, JSON.stringify(data));
  }

  removeItemSessionStorage(key: string) {
    return sessionStorage.removeItem(key);
  }

  private getSessionItem(key: string) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  private setSessionItem(key: string, data: any) {
    this.sessionStorageSub.next({ key, data });
    return sessionStorage.setItem(key, JSON.stringify(data));
  }

  getOriginName() {
    return JSON.parse(localStorage.getItem('originName'));
  }

  setOriginName(origin) {
    this.originName = origin;
    sessionStorage.originName = JSON.stringify(this.originName);
    localStorage.setItem('originName', JSON.stringify(this.originName));
  }

  getDestinationName() {
    return JSON.parse(localStorage.getItem('destinationName'));
  }

  setDestinationName(destination) {
    this.destiName = destination;
    sessionStorage.destiName = JSON.stringify(this.destiName);
    localStorage.setItem('destinationName', JSON.stringify(this.destiName));
  }

  getCountries() {
    return this.getExpirableItem('countries');
  }

  setCountries(countries: Country[]) {
    this.setExpirableItem('countries', countries);
  }

  getCities() {
    return this.getExpirableItem('cities');
  }

  setCities(cities: City[]) {
    this.setExpirableItem('cities', cities);
  }

  getCurrencies() {
    return this.getExpirableItem('currencies');
  }

  setCurrencies(currencies: Currency[]) {
    this.setExpirableItem('currencies', currencies);
  }

  getSpecialHandlingCodes() {
    return this.getExpirableItem('specialHandlingCodes');
  }

  setSpecialHandlingCodes(specialHandlingCodes: SpecialHandlingCode[]) {
    this.setExpirableItem('specialHandlingCodes', specialHandlingCodes);
  }

  getShipmentDate() {
    return JSON.parse(localStorage.getItem('shipmentDate'));
  }

  setShipmentDate(shipment: Date) {
    const selectedDate = moment(shipment).format(DEFAULT_DATE_FORMAT);
    sessionStorage.shipmentDate = shipment;
    localStorage.setItem('shipmentDate', JSON.stringify(selectedDate));
  }

  getAirports(): AirportLight[] {
    return this.getExpirableItem('airports');
  }

  setAirports(airports: AirportLight[]) {
    this.setExpirableItem('airports', airports);
  }

  getSelectedLibraryTab(): string {
    return JSON.parse(localStorage.getItem('selectedLibraryTab'));
  }

  setSelectedLibraryTab(tab: string) {
    localStorage.setItem('selectedLibraryTab', JSON.stringify(tab));
  }

  getSelectedLoadTypeMode(): string {
    return JSON.parse(localStorage.getItem('selectedLoadTypeMode'));
  }

  setSelectedLoadTypeMode(tab: string) {
    localStorage.setItem('selectedLoadTypeMode', JSON.stringify(tab));
  }

  getSelectedAllotmentTab(): string {
    return JSON.parse(localStorage.getItem('selectedAllotmentTab'));
  }

  setSelectedAllotmentTab(tab: string) {
    localStorage.setItem('selectedAllotmentTab', JSON.stringify(tab));
  }

  async initOffices(token?: string): Promise<void> {
    try {
      const companyOffices = (await this.companyService.getCompanyOffices(
        token,
      )) as Office[];
      this.setExpirableSessionItem('offices', companyOffices);
    } catch (err) {
      console.error('Failed to initialise offices:', err);
    }
  }

  async initSelectedOffice(
    token?: string,
    refreshOffices?: boolean,
    silentMode?: boolean,
  ): Promise<void> {
    this.route.queryParams.subscribe(async (params) => {
      let companyOffices = [];
      const officeIdQueryParam = params?.officeId,
        selectedOffice = this.getSelectedOffice();
      if (selectedOffice?.id !== officeIdQueryParam) {
        try {
          companyOffices = (await this.companyService.getCompanyOffices(
            token,
          )) as Office[];
        } catch (err) {
          console.error('Failed to fetch offices:', err);
        }
        const previouslySelectedCargoMartOffice = companyOffices.find(
          (office) => +office?.id === +officeIdQueryParam,
        );
        if (previouslySelectedCargoMartOffice) {
          this.setSelectedOffice(previouslySelectedCargoMartOffice, silentMode);
        }
      }
      this.setExpirableSessionItem('offices', companyOffices);
    });
    let companyOffices = [];
    const selectedOffice = this.getSelectedOffice();
    if (selectedOffice && !refreshOffices) {
      return;
    }
    try {
      companyOffices = (await this.companyService.getCompanyOffices(
        token,
      )) as Office[];
    } catch (err) {
      console.error('Failed to init fetch offices:', err);
    }
    this.setExpirableSessionItem('offices', companyOffices);

    const selectedOfficeId = this.getSelectedOfficeId(),
      previouslySelectedOffice = companyOffices.find(
        (office) => office.id === selectedOfficeId,
      );
    if (
      selectedOfficeId === undefined ||
      previouslySelectedOffice === undefined
    ) {
      const defaultOffice = getDefaultOffice(
        companyOffices,
        null,
        this.isCargoWallet,
      );
      this.setSelectedOffice(defaultOffice, silentMode);
    } else {
      this.setSelectedOffice(previouslySelectedOffice, silentMode);
    }
  }

  getSelectedOffice(): Office {
    const prevSelectedOffice = JSON.parse(
        localStorage.getItem('selectedOffice'),
      ),
      office: Office = this.getExpirableItem('selectedOffice');
    if (office?.walletStatus == 'NONE') {
      office.walletStatus = WalletStatusTypeEnum.INACTIVE;
      this.setSelectedOffice(office);
    }
    if (!office) {
      const officesList = this.getExpirableSessionItem('offices'),
        matchedOffice = officesList?.find(
          (off) => off?.id === prevSelectedOffice?.value?.id,
        );
      if (matchedOffice) {
        this.setSelectedOffice(matchedOffice, true);
        return matchedOffice;
      } else {
        const defaultOffice = getDefaultOffice(
          this.getExpirableSessionItem('offices'),
          null,
          this.isCargoWallet,
        );

        this.setSelectedOffice(defaultOffice, false);
        return defaultOffice;
      }
    }
    return office;
  }

  async initCompanyOffices(): Promise<Office[]> {
    let companyOffices = [];
    try {
      if (this.token) {
        companyOffices = (await this.companyService.getCompanyOffices(
          this.token,
        )) as Office[];
      } else {
        companyOffices =
          (await this.companyService.getCompanyOffices()) as Office[];
      }
    } catch (err) {
      console.error('Failed to fetch office for init:', err);
    }
    this.setExpirableSessionItem('offices', companyOffices);
    return companyOffices;
  }

  async getOffices(): Promise<Office[]> {
    let offices: Office[] = this.getExpirableSessionItem('offices');

    if (!offices) {
      offices = await this.initCompanyOffices();
    }

    return offices || [];
  }

  async getSelectedOfficeAsync(): Promise<Office> {
    let allOffice = [];
    try {
      allOffice = (await this.companyService.getCompanyOffices()) as Office[];
    } catch (err) {
      console.error('Failed to get offices:', err);
    }
    const cOffice = this.getSelectedOffice(),
      updatedCOffice = allOffice.find((r) => r.id === cOffice?.id);
    if (updatedCOffice) {
      this.setSelectedOffice(updatedCOffice);
    }
    return this.getSelectedOffice();
  }

  getSelectedOfficeId(): number {
    const officeId = localStorage.getItem('selectedOfficeId');
    if (officeId) {
      return JSON.parse(officeId);
    }
    return null;
  }

  setSelectedOffice(selectedOffice: Office, silentMode?: boolean) {
    const currentSelectedOffice = this.getExpirableItem('selectedOffice');
    if (
      currentSelectedOffice?.id === selectedOffice?.id &&
      currentSelectedOffice?.walletId === selectedOffice?.walletId
    ) {
      return;
    }
    this.setExpirableItem('selectedOffice', selectedOffice);
    this.setSelectedOfficeId(selectedOffice?.id);
    if (!silentMode) {
      this.updateOfficeChangeStatus();
    }
  }

  setSearchOffice(searchOffice: Office) {
    this.setExpirableItem('searchOffice', searchOffice);
    this.setSearchOfficeId(searchOffice?.id);
    this.updateSearchOfficeChangeStatus();
  }

  getSearchOffice() {
    const searchOffice = this.getExpirableItem('searchOffice');
    if (!searchOffice) {
      return this.getSelectedOffice();
    }
    return searchOffice;
  }

  setSearchOfficeId(searchOfficeId: number) {
    if (searchOfficeId) {
      localStorage.setItem('searchOfficeId', JSON.stringify(searchOfficeId));
    }
  }

  getSearchOfficeId(): number {
    const officeId = localStorage.getItem('searchOfficeId');
    if (officeId) {
      return JSON.parse(officeId);
    }
    return null;
  }

  setSelectedOfficeId(selectedOfficeId: number) {
    if (selectedOfficeId) {
      localStorage.setItem(
        'selectedOfficeId',
        JSON.stringify(selectedOfficeId),
      );
    }
  }

  setAirlineCompanies(airlineCompanies: AirlineBrand[]) {
    this.setExpirableItem('airlineCompanies', airlineCompanies);
  }

  getAirlineCompanies(): AirlineBrand[] {
    return this.getExpirableItem('airlineCompanies');
  }

  setTypeOfProducts(typeOfProducts: TypeOfProduct[]) {
    this.setExpirableItem('typeOfProducts', typeOfProducts);
  }

  getTypeOfProducts(): TypeOfProduct[] {
    return this.getExpirableItem('typeOfProducts');
  }

  setCurrentAirlineCompany(airlineCompany: AirlineBrand) {
    sessionStorage.setItem(
      'currentAirlineCompany',
      JSON.stringify(airlineCompany),
    );
  }

  getCurrentAirlineCompany(): AirlineBrand {
    return JSON.parse(sessionStorage.getItem('currentAirlineCompany'));
  }

  getSearchTemplate(): SearchTemplate {
    const searchTemplateString = localStorage.getItem('searchTemplate');

    if (searchTemplateString) {
      try {
        return JSON.parse(searchTemplateString) as SearchTemplate;
      } catch (error) {
        console.error('Error parsing JSON:', error);
        return null;
      }
    }

    return null;
  }

  setSearchTemplate(item: SearchTemplate) {
    localStorage.setItem('searchTemplate', JSON.stringify(item));
  }

  getAverageCo2OfMostRecentSearchResult(): number {
    return JSON.parse(
      localStorage.getItem('averageCo2OfMostRecentSearchResult'),
    );
  }

  setAverageCo2OfMostRecentSearchResult(item: number) {
    localStorage.setItem(
      'averageCo2OfMostRecentSearchResult',
      JSON.stringify(item),
    );
  }

  getIsLogin(): boolean {
    return sessionStorage.isLogin === 'true';
  }

  setIsLogin(isLogin: boolean): void {
    sessionStorage.isLogin = isLogin;
  }

  getCurrentUser(): User {
    return (
      this.getSessionItem('currentUser') ??
      JSON.parse(localStorage.getItem('currentUser'))
    );
  }

  async getCurrentUserGroup(): Promise<string> {
    try {
      const currentUser = await Auth.currentAuthenticatedUser(),
        currentGroups =
          currentUser.signInUserSession.accessToken.payload['cognito:groups'];
      if (currentGroups.includes(FORWARDER_GROUP)) {
        return FORWARDER_GROUP;
      } else {
        return AIRLINE_GROUP;
      }
    } catch {
      console.warn('No logged user');
    }
  }

  public setCurrentUser(user: User): void {
    this.setSessionItem('currentUser', user);
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  public setCurrentUserCompany(company: Company): void {
    const user = this.getCurrentUser();
    user.company = company;
    this.setSessionItem('currentUser', user);
  }

  getAircrafts(): Aircraft[] {
    return this.getExpirableItem('aircrafts');
  }

  setAircrafts(aircrafts: Aircraft[]): void {
    this.setExpirableItem('aircrafts', aircrafts);
  }

  getBrowserKey(): string {
    if (localStorage.getItem('browser_key') == null) {
      const newBrowserKey = uuid();
      localStorage.setItem('browser_key', newBrowserKey);
    }
    return localStorage.getItem('browser_key');
  }

  setChosenEmailsByAirlineCompany(value: any): void {
    const userId = this.getCurrentUser().userId,
      listSaved = this.getChosenEmailsByAirlineCompany() || {};
    if (!Object.keys(listSaved).length) {
      localStorage.setItem(`emailsByAirline_${userId}`, JSON.stringify(value));
    } else {
      const mergedListAirlines = { ...listSaved, ...value };
      localStorage.setItem(
        `emailsByAirline_${userId}`,
        JSON.stringify(mergedListAirlines),
      );
    }
  }

  getChosenEmailsByAirlineCompany(): any {
    const userId = this.getCurrentUser().userId;
    return JSON.parse(localStorage.getItem(`emailsByAirline_${userId}`));
  }

  setUUIDSearch(value: string): void {
    localStorage.setItem('searchUUID', value);
  }

  getUUIDSearch(): string {
    return localStorage.getItem('searchUUID');
  }

  setRateInsight(key: string, value: IRateInsight) {
    this.setExpirableItem(key, value);
  }

  getRateInsight(key: string): IRateInsight {
    return this.getExpirableItem(key);
  }

  setDirectBookFlight(flight: FlightInput, searchUUID: string): void {
    localStorage.setItem(
      'bookFlight',
      JSON.stringify({ input: flight, searchUUID }),
    );
  }

  getDirectBookFlight(): { input: FlightInput; searchUUID: string } {
    return JSON.parse(localStorage.getItem('bookFlight'));
  }

  setRequestBookFlight(flight: FlightInput, searchUUID: string): void {
    localStorage.setItem(
      'requestBookFlight',
      JSON.stringify({ input: flight, searchUUID }),
    );
  }

  getRequestBookFlight(): { input: FlightInput; searchUUID: string } {
    return JSON.parse(localStorage.getItem('requestBookFlight'));
  }

  setNotQuotableAirlines(airlines: string[]): void {
    localStorage.setItem('notQuotableAirlines', JSON.stringify(airlines));
  }

  getNotQuotableAirlines(): string[] {
    return JSON.parse(localStorage.getItem('notQuotableAirlines'));
  }

  addUnfinishedBookings(quote: Quote) {
    let quotes: Quote[] = JSON.parse(
      localStorage.getItem('unfinishedBookings'),
    );
    if (!quotes) {
      quotes = [];
    }
    const filteredQuotes = quotes.filter((b) => b.quoteId !== quote.quoteId);
    filteredQuotes.push(quote);
    localStorage.setItem('unfinishedBookings', JSON.stringify(quotes));
  }

  getUnfinishedBookings() {
    let quotes: Quote[] = JSON.parse(
      localStorage.getItem('unfinishedBookings'),
    );
    if (!quotes) {
      quotes = [];
    }
    return quotes;
  }

  getLastCo2ChartGeneratedInfo(): any {
    const lastCo2ChartGeneratedInfo = localStorage.getItem(
      'lastCo2ChartGeneratedInfo',
    );
    if (lastCo2ChartGeneratedInfo) {
      return JSON.parse(lastCo2ChartGeneratedInfo);
    }
    return null;
  }

  getBannerDisplayInfo(): any {
    const bannerDisplayInfo = localStorage.getItem('bannerDisplayInfo');
    if (bannerDisplayInfo) {
      return JSON.parse(bannerDisplayInfo);
    }
    return null;
  }

  setBannerDisplayInfo(info: any) {
    localStorage.setItem('bannerDisplayInfo', JSON.stringify(info));
  }

  removeBannerDisplayInfo(): void {
    localStorage.removeItem('bannerDisplayInfo');
  }

  prepareBookingScheduler() {
    const bookingScheulerInterval = 1000 * 60 * 10;
    setInterval(() => {
      this.runBookingScheduler();
    }, bookingScheulerInterval);
  }

  runBookingScheduler() {
    const quotes: Quote[] = JSON.parse(
        localStorage.getItem('unfinishedBookings'),
      ),
      filteredQuotes = JSON.parse(JSON.stringify(quotes));
    if (quotes) {
      quotes.forEach((quote) => {
        this.acceptQuoteService.updateConfirmPage(quote).subscribe((_) => {
          filteredQuotes.filter((b) => b.quoteId !== quote.quoteId);
          localStorage.setItem(
            'unfinishedBookings',
            JSON.stringify(filteredQuotes),
          );
        });
      });
    }
  }

  getPermissionSets() {
    return this.getExpirableItem('permissionSets');
  }

  setPermissionSets(permissionSets: IPermissionSet[]) {
    this.setExpirableItem('permissionSets', permissionSets);
  }

  clearLocalStorage(): void {
    [
      'currentAdmin',
      'flightDetails',
      'countries',
      'shipmentDate',
      'airports',
      'searchTemplate',
      'currentForwarder',
      'currentAirline',
      'airlineCompanies',
      'aircrafts',
      'averageCo2OfMostRecentSearchResult',
      'selectedOffice',
      'selectedOfficeId',
      'searchOfficeId',
      'currentUser',
      'permissionSets',
      'searchUUID',
    ].forEach((key) => localStorage.removeItem(key));
    const appName = this.appProperties.name;
    sessionStorage.clear();
    sessionStorage.setItem('appName', appName);
  }

  setRememberMeLogin(rememberLogin: boolean) {
    this.setItem('rememberMeLogin', rememberLogin);
  }

  getRememberMeLogin(): boolean {
    console.log('RememberMe is set to: ', !!this.getItem('rememberMeLogin'));
    return !!this.getItem('rememberMeLogin');
  }

  isRememberMeSet(): boolean {
    return this.getItem('rememberMeLogin') !== null;
  }

  getQuoteLastViewDate(quoteId: number): Date {
    return new Date(JSON.parse(localStorage.getItem(`quote_${quoteId}`)));
  }

  updateQuoteLastViewDate(quoteId: number) {
    localStorage.setItem(`quote_${quoteId}`, JSON.stringify(new Date()));
  }
  getSelectedLanguage() {
    let selectedLanguage = localStorage.getItem('selectedLanguage');
    if (
      !selectedLanguage ||
      selectedLanguage === 'null' ||
      selectedLanguage === 'undefined'
    ) {
      selectedLanguage = 'en';
    }
    return selectedLanguage;
  }

  setSelectedLanguage(value: string): void {
    localStorage.setItem('selectedLanguage', value);
  }
  updateOfficeChangeStatus(): void {
    this.officeChangedStatus.next(!this.officeChangedStatus.getValue());
  }
  updateSearchOfficeChangeStatus(): void {
    this.searchOfficeChangedStatus.next(
      !this.searchOfficeChangedStatus.getValue(),
    );
  }
  updatewalletCurrency(flag: boolean, currency: string): void {
    this.walletCurrency.next({ flag, currency });
  }

  get isCargoWallet(): boolean {
    return this.appProperties.name === 'cargowallet';
  }

  get isCargoMart(): boolean {
    return this.appProperties.name === 'cargomart';
  }
}
