import { NgModule } from "@angular/core";
import { CaiAircraftColorPipe } from "./aircraft-color.pipe";
import { CaiNumberFormatterPipe } from "./number-formatter.pipe";
import { SafePipe } from "./safe.pipe";
import { CaiTimeDifferPipe } from "./time-diff.pipe";
import { CaiTimeFormatPipe } from "./time-format.pipe";

const CAI_PIPES = [
  CaiTimeFormatPipe,
  CaiTimeDifferPipe,
  CaiAircraftColorPipe,
  CaiNumberFormatterPipe,
  SafePipe,
];

@NgModule({
  "declarations": [...CAI_PIPES],
  "exports": [...CAI_PIPES],
  "providers": [],
  "imports": [],
})
export class CommonPipesModule {}
