import {
  BookingTypeEnum,
  IAllotment,
  ICommodity,
  IDangerousGood,
  IForwarderOffice,
  IQuoteRequestAirline,
  IRequirementQuote,
  ISpecialHandlingCode,
  ITypeOfProduct,
  MeasurementSystemEnum,
  QuoteRequestStatusEnum,
} from "@cai-services";
import { QuoteItem } from "./quote-item.model";
import { Quote } from "./quote.model";
import { AirlineUser } from "./airline-user.model";
import { QuoteRequest } from "./quote-request.model";

export class QuoteRequestAirline implements IQuoteRequestAirline {
  quoteRequestId: number;
  quality: number;
  delayTime: number;
  qualityReasons: string;
  quoteRequestStatus: QuoteRequestStatusEnum;
  transCurrencyAmount: number;
  originalQuotePrice: number;
  originalQuoteCapacity: number;
  bookingType: BookingTypeEnum;
  partnerEmail: string;
  maxDepartureDays: number;
  forwarderOffice: IForwarderOffice;
  isShipmentScreened: boolean;
  loadBoardConsent: boolean;
  specialHandlingCodes: ISpecialHandlingCode[];
  iata: number;
  cass: string;
  typeOfProduct: ITypeOfProduct;
  commodities: ICommodity[];
  requirements: IRequirementQuote[];
  dangerousGoods: IDangerousGood[];
  measurementSystem: MeasurementSystemEnum;
  quotes: Quote[];
  quoteItems: QuoteItem[];
  airline: AirlineUser;
  airlineCreator: AirlineUser;
  allotment?: IAllotment;
  filename?: string;
  rejectedReasons?: string;

  constructor (
    quoteRequest?: QuoteRequest) {
      this.quoteRequestId = quoteRequest.quoteRequestId;
      this.quality = quoteRequest.quality;
      this.delayTime = quoteRequest.delayTime;
      this.qualityReasons = quoteRequest.qualityReasons;
      this.quoteRequestStatus = quoteRequest.quoteRequestStatus;
      this.transCurrencyAmount = quoteRequest.transCurrencyAmount;
      this.originalQuotePrice = quoteRequest.originalQuotePrice;
      this.originalQuoteCapacity = quoteRequest.originalQuoteCapacity;
      this.bookingType = quoteRequest.bookingType;
      this.partnerEmail = quoteRequest.partnerEmail;
      this.maxDepartureDays = quoteRequest.maxDepartureDays;
      this.forwarderOffice = quoteRequest.forwarderOffice;
      this.isShipmentScreened = quoteRequest.isShipmentScreened;
      this.loadBoardConsent = quoteRequest.loadBoardConsent;
      this.specialHandlingCodes = quoteRequest.specialHandlingCodes;
      this.iata = quoteRequest.iata;
      this.cass = quoteRequest.cass;
      this.typeOfProduct = quoteRequest.typeOfProduct;
      this.commodities = quoteRequest.commodities;
      this.requirements = quoteRequest.requirements;
      this.dangerousGoods = quoteRequest.dangerousGoods;
      this.measurementSystem = quoteRequest.measurementSystem;
      this.quotes = quoteRequest.quotes;
      this.quoteItems = quoteRequest.quoteItems;
      this.allotment = quoteRequest.allotment;
      this.filename = quoteRequest.filename;

      this.quotes[0].quoteRequest.forwarder = null;
      this.quotes[0].quoteRequest.forwarderCreator = null;
  }
}
