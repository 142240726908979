<div class="modal-body office-settings-danger-modal">
  <button class="close" (click)="modalRef.dismiss()">&times;</button>
  <form class="office-settings-modal-form">
    <h2 class="header modal-title">
      <span i18n="@@office-setting.remove-user">Remove User</span>
    </h2>
    <div class="body">
      <p>
        <span i18n="@@global.sure-remove">Are you sure you want to remove</span>
        {{ userToRemove.firstName }} {{ userToRemove.lastName }}
        <span i18n="@@global.from">from</span>
        {{ activeOffice.name ?? getLongOfficeName(activeOffice) }}?
      </p>
      <p i18n="@@office-remove-user-modal.transfer-quotes" *ngIf="!isWalletApp">
        All Quotes/Bookings of this user will be transferred to you
      </p>
    </div>
    <div class="d-flex justify-content-center w-100" style="gap: 10px">
      <button
        class="btn btn-secondary"
        type="button"
        (click)="modalRef.dismiss()"
        i18n="@@global.cancel"
      >
        Cancel
      </button>
      <button
        class="btn btn-danger"
        type="button"
        (click)="removeUserFromOffice()"
        i18n="@@office-remove-user-modal.remove"
      >
        Remove
      </button>
    </div>
  </form>
</div>
