<div
  ngbDropdown
  placement="bottom-right"
  *ngIf="user"
  class="h-100 d-flex align-items-center mr-2 ml-3"
>
  <div
    ngbDropdownToggle
    class="kt-header__topbar-wrapper"
    *ngIf="!handleResponsive"
    style="cursor: pointer"
  >
    <ng-template [ngTemplateOutlet]="profilePicture"></ng-template>
  </div>
  <div
    ngbDropdownMenu
    class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
  >
    <div class="d-flex flex-column dropdown-menu-body" style="gap: 16px">
      <!--begin: Head -->
      <div class="d-flex align-items-center" style="gap: 12px">
        <ng-template [ngTemplateOutlet]="profilePicture"></ng-template>
        <div class="d-flex flex-column">
          <h2>{{ user.userName }}</h2>
          <span
            class="kt-user-card__email"
            [cdkCopyToClipboard]="user.userEmail"
            >{{ user.userEmail }}</span
          >
          <div
            class="d-flex shortcut-links"
            style="gap: 10px"
            *ngIf="!isCargoWallet"
          >
            <a
              *ngFor="let link of shortcutLinks"
              [routerLink]="personalLink"
              [queryParams]="{ tab: link.key }"
            >
              <i [style.mask-image]="'url(' + link.iconUrl + ')'"></i>
            </a>
          </div>
        </div>
      </div>
      <!--end: Head -->
      <div class="divider"></div>
      <!--begin: Navigation -->
      <div class="dropdown-links d-flex flex-column">
        <a [routerLink]="personalLink" i18n="@@topbar.personal-settings">
          Personal settings
        </a>
        <a
          routerLink="/settings/office-settings"
          i18n="@@topbar.company-office-settings"
        >
          Company & office settings
        </a>
      </div>
      <button class="btn btn-sign-out" (click)="logout()">
        <img
          src="/assets/media/icons/sign-out.svg"
          alt="icon.svg"
          width="14"
          height="14"
        />
        <span i18n="@@global.sign-out">Sign Out</span>
      </button>
    </div>
  </div>
</div>

<ng-template #profilePicture>
  <div class="kt-profile-icon" [class.img-container]="!user.logoPath">
    <span *ngIf="!user.logoPath">{{ getInitial() }}</span>
    <img
      *ngIf="user.logoPath"
      [src]="user.logoPath"
      alt="profile picture"
      (error)="handleLogoError()"
    />
  </div>
</ng-template>
