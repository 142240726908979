import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IRequirementAnswer, RequirementCodeEnum } from "@cai-services";
import { LithiumBatteryAnswer } from "../../../constant/requirements/lithium-battery.const";
import {
  TemperatureTrackingDeviceAnswer,
  TemperatureTrackingDeviceConfig,
} from "../../../constant/requirements/temperature-tracking-device.const";

@Component({
  "selector": "kt-requirement-temperature-tracking-device",
  "templateUrl": "./requirement-temperature-tracking-device.component.html",
  "styleUrls": ["./requirement-temperature-tracking-device.component.scss"],
})
export class RequirementTemperatureTrackingDeviceComponent implements OnInit {
  @Output() onFocus = new EventEmitter();

  formGroup: FormGroup = new FormGroup({});
  mainFormGroup: FormGroup = new FormGroup({});
  code = "";
  defaultAnswer = {} as IRequirementAnswer;
  selectedAnswer: any = {};
  question: string = TemperatureTrackingDeviceConfig.question;
  options: any[] = TemperatureTrackingDeviceConfig.options;
  linkLithiumBattery: boolean;

  constructor (private readonly cdr: ChangeDetectorRef) {}

  ngOnInit (): void {
    this.updateAnswer(this.formGroup.get(this.code).value);
    this.formGroup.get(this.code).valueChanges.subscribe((value) => {
      this.updateAnswer(value);
    });
  }

  selectAnswer (option) {
    if (this.selectedAnswer !== option) {
      this.selectedAnswer = option;
      this.formGroup.get(this.code).setValue(option.value);
      const lithiumBatteryReq = this.mainFormGroup.get(
        RequirementCodeEnum.CONTAIN_LITHIUM_BATTERY
      );
      if (
        lithiumBatteryReq &&
        ((lithiumBatteryReq.value === LithiumBatteryAnswer.NO &&
          option.value === TemperatureTrackingDeviceAnswer.YES) ||
          this.linkLithiumBattery)
      ) {
        this.linkLithiumBattery = true;
        lithiumBatteryReq.setValue(option.value);
      }
      this.onFocus.emit();
    }
  }

  private updateAnswer (value) {
    const selected = this.options.find((opt) => opt.value === value);
    if (selected) {
      this.selectedAnswer = selected;
      this.cdr.detectChanges();
    }
  }
}
