<div class="otp-container" *ngIf="isShowOTP">
  <div
    class="otp-container__innerContainer d-flex flex-column align-items-center"
  >
    <div
      class="kt-login__logo"
      [class.other-brand]="otherBrand"
      (click)="openSite()"
      [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')' }"
    ></div>
    <h3
      class="title"
      i18n="@@otp.otp-verification"
      *ngIf="OTPStatus !== 'correct'"
    >
      OTP Verification
    </h3>
    <p
      class="text-info"
      i18n="@@otp.please-enter-verification-code"
      *ngIf="OTPStatus !== 'correct'"
    >
      Please enter the verification code sent to your email.
    </p>
    <ng-otp-input
      *ngIf="OTPStatus !== 'correct'"
      [class.error]="OTPStatus === 'incorrect' || OTPStatus === 'expired'"
      [config]="{ length: 6, allowNumbersOnly: true }"
      (onInputChange)="onOTPComplete($event)"
      (click)="handleClick($event)"
      #ngOtpInput
    ></ng-otp-input>
    <div class="otp-help" *ngIf="OTPStatus !== 'correct'">
      <p class="text-danger" ngB>
        <span
          *ngIf="OTPStatus === 'incorrect'"
          [innerHTML]="errorMessage"
        ></span>
      </p>
      <p class="text-danger" ngB>
        <span
          *ngIf="OTPStatus === 'expired'"
          [innerHTML]="expiredOTPMessage"
        ></span>
      </p>
    </div>
    <div class="btn-container" *ngIf="OTPStatus !== 'correct'">
      <div class="otp-container__actions d-flex align-items-center">
        <button
          class="otp-container__actionButton backButton"
          i18n="@@otp.back"
          routerLink="/auth/login"
          (click)="handleNavigation()"
        >
          BACK
        </button>
        <div
          class="otp-container__timerContainer d-flex align-items-center"
          [style.display]="'block'"
          [style.color]="isOnCountdown ? '#2ccac7' : '#F27D7D'"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.53033 1.46967C5.82322 1.76256 5.82322 2.23744 5.53033 2.53033L2.53033 5.53033C2.23744 5.82322 1.76256 5.82322 1.46967 5.53033C1.17678 5.23744 1.17678 4.76256 1.46967 4.46967L4.46967 1.46967C4.76256 1.17678 5.23744 1.17678 5.53033 1.46967ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75C16.5563 3.75 20.25 7.44365 20.25 12C20.25 16.5563 16.5563 20.25 12 20.25C7.44365 20.25 3.75 16.5563 3.75 12ZM12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25ZM12.75 8C12.75 7.58579 12.4142 7.25 12 7.25C11.5858 7.25 11.25 7.58579 11.25 8V12C11.25 12.2508 11.3753 12.4849 11.584 12.624L14.584 14.624C14.9286 14.8538 15.3943 14.7607 15.624 14.416C15.8538 14.0714 15.7607 13.6057 15.416 13.376L12.75 11.5986V8ZM18.4697 2.53033C18.1768 2.23744 18.1768 1.76256 18.4697 1.46967C18.7626 1.17678 19.2374 1.17678 19.5303 1.46967L22.5303 4.46967C22.8232 4.76256 22.8232 5.23744 22.5303 5.53033C22.2374 5.82322 21.7626 5.82322 21.4697 5.53033L18.4697 2.53033Z"
              fill="#E161A4"
              [attr.fill]="isOnCountdown ? ' #2ccac7' : '#F27D7D'"
            />
          </svg>
          <countdown
            class="otp-container__timer"
            #otpCountdown
            [config]="otpCDConfig"
            (event)="handleCountdownEvent($event)"
          ></countdown>
          <span i18n="@@otp.sec">sec</span>
        </div>
        <span class="otp-container__buttonWrapper">
          <button
            class="otp-container__actionButton resendButton d-flex justify-content-end"
            i18n="@@otp.resend-otp"
            *ngIf="!isOnCountdown"
            (click)="handleRequestNewOTP()"
            [disabled]="OTPStatus === 'isSending'"
          >
            RESEND OTP
          </button>
        </span>
      </div>
    </div>
    <div
      class="otp-container__help d-flex flex-column"
      *ngIf="OTPStatus !== 'correct'"
    >
      <span i18n="@@otp.receive-otp">Didn’t receive the OTP ?</span>
      <span i18n="@@otp.receive-helperTitle" class="otp-container__helper"
        >Here are a few things that could help :</span
      >
      <div
        class="otp-container__helperMessages d-flex flex-column justify-content-center align-items-start"
      >
        <span
          class="otp-container__helperMessage"
          i18n="@@otp.check-spam-email"
        >
          <img src="/assets/media/icons/check-icon.svg" alt="" loading="lazy" />
          Check your spam mail
        </span>
        <span class="otp-container__helperMessage">
          <img src="/assets/media/icons/check-icon.svg" alt="" loading="lazy" />
          <span i18n="@@otp.click-here">
            or click here to
            <span
              [class.otp-container__linkMessage]="!isOnCountdown"
              (click)="!isOnCountdown && handleRequestNewOTP()"
            >
              Resend OTP</span
            ></span
          >
        </span>
        <div
          class="otp-container__helperMessage d-flex"
          style="width: 100%; position: relative"
        >
          <img
            src="/assets/media/icons/check-icon.svg"
            alt=""
            loading="lazy"
            class="otp-container__lastIcon"
          />
          <div
            class="otp-container__messageWrapper d-flex"
            i18n="@@otp.contact-us"
          >
            or
            <span
              class="otp-container__linkMessage"
              (click)="handleContactUs()"
              style="white-space: nowrap; margin-left: 4px; margin-right: 4px"
            >
              contact us</span
            >
            <span class="d-flex" style="text-align: left">
              if you still have an issue
            </span>
          </div>
        </div>
      </div>
    </div>
    <cai-success-message
      [isShow]="OTPStatus === 'correct'"
      [title]="title"
      [message]="successMessage"
      [otherMessage]="timer"
    ></cai-success-message>
  </div>
</div>
