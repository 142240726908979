<div class="flight-preview-container">
  <div class="route-airline">
    <div
      class="align-self-center ml-4 mr-2 d-flex flex-column align-items-center"
    >
      <img
        alt="Airline Logo"
        class="airline-logo"
        [src]="fileService.getAirlineLogoPath(flightDetails?.airlineCode)"
      />
      <div
        *ngIf="flightDetails?.legs?.length === 1"
        class="direct-badge text-uppercase"
        i18n="@@global.direct"
      >
        Direct
      </div>
    </div>
    <div class="align-self-center mr-2 airline">
      {{ flightDetails?.flightNumber }}
    </div>
  </div>
  <div class="d-flex flex-column align-self-center info-flight">
    <div>{{ flightDetails?.departureTime | caiTime : "DD MMM YY" }}</div>
    <div>{{ flightDetails?.departureTime | caiTime }}</div>
  </div>
  <span class="airport">{{ flightDetails?.departureAirport }}</span>
  <div class="flight-preview-bar-container">
    <ng-container *ngFor="let path of flightDetails?.legs; let i = index">
      <div
        *ngIf="
          path?.departureTime
            | caiTimeDiffer : path.arrivalTime : dayDiff as mainPath
        "
        class="path"
        [class.oneBar]="flightDetails?.legs?.length === 1"
        [ngStyle]="{
          width: Math.min(mainPath + 50, 100) + '%',

          height: '23px',
          'border-top-left-radius': i === 0 ? '8px' : '',
          'border-bottom-left-radius': i === 0 ? '8px' : '',
          'border-top-right-radius':
            i === flightDetails?.legs?.length - 1 ? '8px' : '',
          'border-bottom-right-radius':
            i === flightDetails?.legs?.length - 1 ? '8px' : '',
          'border-right': i === flightDetails?.legs?.length - 1 ? '' : 'none',
          'border-left': i === 0 ? '' : 'none'
        }"
      >
        <div
          class="legs"
          [class.legs-light]="
            path.aircraft?.aircraftType?.aircraftTypeName === 'Narrowbody'
          "
          *ngIf="
            path.aircraft?.aircraftType?.aircraftTypeName
              | caiAircraftColor as aircraftColorBg
          "
          [ngStyle]="{
            'background-color': aircraftColorBg
          }"
        ></div>
      </div>

      <ng-container *ngIf="i < flightDetails?.legs?.length - 1">
        <div
          *ngIf="
            path.arrivalTime
              | caiTimeDiffer
                : flightDetails?.legs?.[i + 1]?.departureTime
                : dayDiff as afterPath
          "
          class="flight-transit-path"
          [ngStyle]="{
            width: Math.max(afterPath + 20, 5) + '%'
          }"
          [matTooltip]="
            path.destRegionAirport?.airportCode +
            ' ' +
            (path.arrivalTime | caiTime).concat(
              ' - ',
              flightDetails?.legs?.[i + 1]?.departureTime | caiTime
            )
          "
        >
          <div class="flight-leg">{{ path.arrivalAirport }}</div>
        </div>
      </ng-container>
    </ng-container>
    <div class="duration">{{ getFlightDuration() }}</div>
  </div>
  <span class="airport">{{ flightDetails?.arrivalAirport }}</span>
  <div
    class="d-flex flex-column align-self-center info-flight"
    style="padding-right: 80px"
  >
    <div>{{ flightDetails?.arrivalTime | caiTime : "DD MMM YY" }}</div>
    <div>{{ flightDetails?.arrivalTime | caiTime }}</div>
  </div>
  <div class="co2-wrapper">
    <div *ngIf="flightDetails?.legs?.[0]?.co2" class="flights-co2">
      <img
        src="/assets/media/icons/shipmentDetails/leaf-white.svg"
        alt="leaf-icon"
      />
      <span class="co2-value"> {{ flightDetails?.legs | co2 }}</span>
    </div>
  </div>
</div>
