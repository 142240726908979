// Angular
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
// Object-Path
import * as objectPath from "object-path";
import { LayoutConfigService } from "../../../core/_base/layout/services/layout-config.service";
import { SplashScreenService } from "../../../core/_base/layout/services/splash-screen.service";
import { BrandIconUtil } from "../../../utils/brand.util";
import { APP_PROPS } from "../../../cai-common.module";
import { ApplicationProperties } from "../../../core";

@Component({
  "selector": "kt-splash-screen",
  "templateUrl": "./splash-screen.component.html",
  "styleUrls": ["./splash-screen.component.scss"],
})
export class SplashScreenComponent implements OnInit {
  // Public proprties
  loaderLogo: string;
  loaderType: string;
  loaderMessage: string;

  @ViewChild("splashScreen", { "static": true }) splashScreen: ElementRef;

  /**
   * Component constructor
   *
   * @param el: ElementRef
   * @param layoutConfigService: LayoutConfigService
   * @param splashScreenService: SplachScreenService
   */
  constructor (
    private el: ElementRef,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit () {
    // init splash screen, see loader option in layout.config.ts
    const loaderConfig = this.layoutConfigService.getConfig("loader");
    this.loaderLogo = objectPath.get(loaderConfig, "logo");
    this.loaderType = objectPath.get(loaderConfig, "type");
    this.loaderMessage = objectPath.get(loaderConfig, "message");

    this.splashScreenService.init(this.splashScreen);
  }

  get backgroundImage (): string {
    return BrandIconUtil.fetchBackgroundIcon(
      this.loaderLogo,
      "/assets/media/logos/"
    );
  }
  get isWalletApp (): boolean {
    return this.appProperties.name === "cargowallet";
  }
}
