import { CdkScrollableModule, ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { CaiAutocomplete } from "./components/autocomplete/autocomplete.component";
import { CaiCheckbox } from "./components/checkbox/checkbox.component";
import { CaiDatepicker } from "./components/datepicker/datepicker.component";
import { CaiInputSelect } from "./components/input-select/input-select.component";
import { CaiInput } from "./components/input/input.component";
import { CaiPanel } from "./components/panel/panel.component";
import { CaiSelectGroup } from "./components/select-group/select-group.component";
import { CaiSelect } from "./components/select/select.component";
import { CaiPopoverDirective } from "./directives/popover.directive";
import { CaiTooltipDirective } from "./directives/tooltip.directive";

const FWK_COMPONENTS = [
  CaiDatepicker,
  CaiPanel,
  CaiSelect,
  CaiSelectGroup,
  CaiAutocomplete,
  CaiInput,
  CaiInputSelect,
  CaiCheckbox,
],

 FWK_DIRECTIVES = [CaiPopoverDirective, CaiTooltipDirective];

@NgModule({
  "imports": [
    CommonModule,
    FormsModule,
    ScrollingModule,
    ReactiveFormsModule,
    CdkScrollableModule,
    MatDatepickerModule,
  ],
  "declarations": [...FWK_COMPONENTS, ...FWK_DIRECTIVES],
  "providers": [DatePipe],
  "exports": [...FWK_COMPONENTS, ...FWK_DIRECTIVES],
})
export class CaiFrameworkModule {}
