import { Injectable } from "@angular/core";

@Injectable({
  "providedIn": "root",
})
export class ErrorUtil {
  static async handleError (httpError): Promise<never> {
    const errorMessage = ErrorUtil.constructErrorMessage(httpError);
    console.error(errorMessage);
    return Promise.reject(errorMessage);
  }

  static async warnError (httpError): Promise<void> {
    const errorMessage = ErrorUtil.constructErrorMessage(httpError);
    console.warn(errorMessage);
    return Promise.resolve();
  }

  private static constructErrorMessage (httpError: any): string {
    let errMessage = "";
    const errResponse = httpError?.response;
    if (errResponse) {
      const errDetails = [];
      if (errResponse.status) {
        errDetails.push(`${errResponse.status}`);
      }
      if (errResponse.data?.fieldErrors) {
        return errResponse.data?.fieldErrors;
      }
      if (errResponse.data?.error) {
        errDetails.push(`${errResponse.data?.error}`);
      }
      if (errResponse.data?.error) {
        errDetails.push(`${errResponse.config?.url}`);
      }
      errMessage = errDetails.join(" ");
      if (errMessage) {
        errMessage = `Error: ${errMessage}`;
      }
    }

    if (!errMessage && httpError) {
      errMessage = httpError;
    }
    if (!errMessage) {
      errMessage = "An unknown error has occurred";
    }
    return errMessage;
  }
}
