import { Injectable } from '@angular/core';
import { API } from '@aws-amplify/api';
import { TransactionStatusEnum } from '../../../core/enum/wallet-status.enum';
import {
  PaginatedTransactionDetails,
  TransactionMetadata,
} from '../../../core/model/wallet-transactions.model';
import {
  AccountWalletDetails,
  DefaultWalletBalance,
  GetEventResponse,
  WalletBankAccountDetails,
  WalletCurrency,
  WalletGlobalBalance,
  WalletPayLaterBalance,
  WalletPaymentApprovalWorkflow,
  WalletTopUp,
  WalletTransaction,
} from '../../../core/model/wallet.model';
import { IPostGetPaidTransaction } from '../../../core/model/post-get-paid-transaction.model';
import { API_WALLET } from '../../constant/api-wallet.const';
import { TransactionCategoryEnum } from '../../enum';
import {
  IPayout,
  IPostGetPaid,
  IPutGetPaid,
  TazapayCurrencies,
  TazapayPaymentMethod,
} from '../../model';
import { PostTopUp } from '../../model/post-top-up.model';
import { IWalletTransaction } from '../../model/transaction.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  async getDefaultWalletBalance(
    walletId: string,
  ): Promise<DefaultWalletBalance> {
    const requestURL = API_WALLET.URLs.Customer.Balances.replace(
      '{walletId}',
      walletId,
    );
    return API.get(API_WALLET.name, requestURL, {}).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async getAccountWalletDetails(
    walletId: string,
  ): Promise<AccountWalletDetails> {
    const requestURL = API_WALLET.URLs.Customer.Wallet.replace(
      '{walletId}',
      walletId,
    );
    return API.get(API_WALLET.name, requestURL, {}).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async updatePayoutFrequency(
    walletId: string,
    payoutFrequency: string,
    userEmail: string,
    maintainBalance?: number,
    minimumPayoutAmount?: number,
  ): Promise<AccountWalletDetails> {
    const requestURL = API_WALLET.URLs.Customer.PayoutFrequency.replace(
        '{walletId}',
        walletId,
      ),
      extraOptions = {
        queryStringParameters: {
          maintainBalance,
          userEmail,
          minimumPayoutAmount,
          payoutFrequency,
        },
      };
    return API.put(API_WALLET.name, requestURL, extraOptions).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async getPayoutFrequency(walletId: string): Promise<IPayout> {
    const requestURL = API_WALLET.URLs.Customer.PayoutFrequency.replace(
      '{walletId}',
      walletId,
    );

    return API.get(API_WALLET.name, requestURL, {}).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async updateWalletStatus(
    walletId: string,
    body: any,
  ): Promise<AccountWalletDetails> {
    const requestURL = API_WALLET.URLs.Customer.CustomerStatus.replace(
      '{walletId}',
      walletId,
    );
    return API.patch(API_WALLET.name, requestURL, { body }).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async getTransactionsList(
    walletId: string,
    referenceId?: string,
  ): Promise<GetEventResponse> {
    const requestURL = API_WALLET.URLs.Customer.Events.replace(
      '{walletId}',
      walletId,
    );
    let extraOptions = {};
    if (referenceId) {
      extraOptions = {
        queryStringParameters: { referenceId },
      };
    }
    return API.get(API_WALLET.name, requestURL, extraOptions).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async patchPayout(
    reference: string,
    approve: boolean,
    isDeclined?: boolean,
  ): Promise<WalletTransaction> {
    const requestURL = API_WALLET.URLs.Tazapay.Payout,
      action = isDeclined
        ? approve
          ? 'SETTLE'
          : 'REJECT'
        : approve
          ? 'APPROVE'
          : 'REJECT',
      extraOptions = {
        queryStringParameters: { reference, action },
      };
    return API.put(API_WALLET.name, requestURL, extraOptions).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async getSwifBankDetails(
    walletId: string,
    currency: string,
  ): Promise<Array<WalletBankAccountDetails>> {
    const requestURL = API_WALLET.URLs.Customer.FundingAccounts.replace(
      '{walletId}',
      walletId,
    ).replace('{currency}', currency);
    return API.get(API_WALLET.name, requestURL, {}).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async addTopupBalance(
    walletId: string,
    body: PostTopUp,
  ): Promise<WalletTransaction> {
    const requestURL = API_WALLET.URLs.Customer.TopUp.replace(
      '{walletId}',
      walletId,
    );
    return API.post(API_WALLET.name, requestURL, { body }).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async getWalletBalancesByCurrencies(
    walletId: string,
    currency: string,
  ): Promise<WalletTopUp> {
    const requestURL = API_WALLET.URLs.Customer.Balances.replace(
        '{walletId}',
        walletId,
      ),
      body = { currency };
    return API.get(API_WALLET.name, requestURL, body).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async getWalletPayLaterBalances(
    walletId: string,
  ): Promise<WalletPayLaterBalance> {
    const requestURL = API_WALLET.URLs.Customer.PaylaterBalances.replace(
      '{walletId}',
      walletId,
    );
    return API.get(API_WALLET.name, requestURL, {}).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async getWalletCurrencyList(
    walletId: string,
  ): Promise<Array<WalletCurrency>> {
    const requestURL = API_WALLET.URLs.Customer.Currencies.replace(
      '{walletId}',
      walletId,
    );
    return API.get(API_WALLET.name, requestURL, {}).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async patchDefaultBalance(
    walletId: string,
    customerId: string,
    currency: string,
  ): Promise<null> {
    const requestURL = API_WALLET.URLs.Customer.DefaultBalance.replace(
        '{walletId}',
        walletId,
      ),
      body = { customer_id: customerId, default_currency: currency };
    return API.patch(API_WALLET.name, requestURL, { body }).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async patchWalletPaymentApprovalFlow(
    walletId: string,
    updatedPaymentApproval: WalletPaymentApprovalWorkflow,
  ): Promise<void> {
    const requestURL = API_WALLET.URLs.Customer.PaymentApproval.replace(
      '{walletId}',
      walletId,
    );
    return API.patch(API_WALLET.name, requestURL, {
      body: updatedPaymentApproval,
    }).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async getGlobalPrepaidBalance(
    walletId: string,
    currency: string,
  ): Promise<WalletGlobalBalance> {
    if (!walletId) {
      return Promise.reject('wallet id not present');
    }
    const requestURL = API_WALLET.URLs.Customer.BalanceConversions.replace(
        '{walletId}',
        walletId,
      ),
      queryStringParameters = {
        currency,
      };
    return API.get(API_WALLET.name, requestURL, {
      queryStringParameters,
    }).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async getBeneficiaries(walletId: string): Promise<any[]> {
    const requestURL = API_WALLET.URLs.Customer.Beneficiaries.replace(
      '{walletId}',
      walletId,
    );
    return API.get(API_WALLET.name, requestURL, {}).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async createTransaction(
    walletId: string,
    body: IWalletTransaction,
  ): Promise<WalletTransaction[]> {
    let requestURL;
    if (walletId) {
      requestURL = API_WALLET.URLs.Customer.Transactions.replace(
        '{walletId}',
        walletId,
      );
    } else {
      requestURL = API_WALLET.URLs.Customer.GuestTransactions;
    }
    return API.post(API_WALLET.name, requestURL, { body }).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async getTransactions(
    walletId: string,
    sort: string,
    page: number,
    categories?: TransactionCategoryEnum[],
    statuses?: TransactionStatusEnum[],
    operations?: string[],
    dateRange?: any,
    filter?: string,
    searchKey?: string,
  ): Promise<PaginatedTransactionDetails> {
    let requestURL;
    if (walletId) {
      requestURL = API_WALLET.URLs.Customer.Transactions.replace(
        '{walletId}',
        walletId,
      );
    } else {
      requestURL = API_WALLET.URLs.Customer.GuestTransactions;
    }

    const queryStringParameters = {
      sort,
    } as any;
    if (page) {
      queryStringParameters.page = page;
    }
    if (categories) {
      queryStringParameters.category = categories;
    }
    if (statuses) {
      queryStringParameters.status = statuses;
    }
    if (operations) {
      queryStringParameters.operation = operations;
    }
    if (dateRange?.fromDate) {
      queryStringParameters.fromDate = dateRange?.fromDate;
    }
    if (dateRange?.toDate) {
      queryStringParameters.toDate = dateRange?.toDate;
    }
    if (filter) {
      queryStringParameters.filter = filter;
    }
    if (searchKey) {
      queryStringParameters.searchKey = searchKey;
    }
    return API.get(API_WALLET.name, requestURL, {
      queryStringParameters,
    }).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async getMetadata(walletId: string): Promise<TransactionMetadata> {
    let requestURL;
    requestURL = API_WALLET.URLs.Customer.Metadata.replace(
      '{walletId}',
      walletId,
    );
    return API.get(API_WALLET.name, requestURL, {}).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async createGetPaidTransaction(
    walletId: string,
    body: IPostGetPaid,
  ): Promise<IPostGetPaidTransaction> {
    let requestURL;

    requestURL = API_WALLET.URLs.Customer.GetPaid.replace(
      '{walletId}',
      walletId,
    );

    return API.post(API_WALLET.name, requestURL, { body }).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async updateGetPaidTransaction(
    walletId: string,
    body: IPutGetPaid,
  ): Promise<any[]> {
    let requestURL;

    requestURL = API_WALLET.URLs.Customer.GetPaid.replace(
      '{walletId}',
      walletId,
    );

    return API.put(API_WALLET.name, requestURL, { body }).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async patchPaymentApprovalWorkflow(
    walletId: string,
    body: WalletPaymentApprovalWorkflow,
  ): Promise<void> {
    const requestURL = API_WALLET.URLs.Customer.PaymentApproval.replace(
      '{walletId}',
      walletId,
    );

    return API.patch(API_WALLET.name, requestURL, { body }).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async getPaymentTypes(
    currency?: string,
    country?: string,
    type?: string,
  ): Promise<TazapayPaymentMethod[]> {
    const queryStringParameters: any = {
      type,
    };
    if (currency) {
      queryStringParameters.currency = currency;
    }
    if (country) {
      queryStringParameters.country = country;
    }
    return API.get(API_WALLET.name, API_WALLET.URLs.Tazapay.PaymentMethods, {
      queryStringParameters,
    }).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }
  async getTazapayCurrencies(complete?: boolean): Promise<TazapayCurrencies[]> {
    const queryStringParameters: any = {
      complete,
    };
    return API.get(API_WALLET.name, API_WALLET.URLs.Tazapay.Currencies, {
      queryStringParameters,
    }).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async getWalletPaymentApprovalFlow(
    walletId: string,
  ): Promise<WalletPaymentApprovalWorkflow> {
    const requestURL = API_WALLET.URLs.Customer.PaymentApproval.replace(
      '{walletId}',
      walletId,
    );
    return API.get(API_WALLET.name, requestURL, {}).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async cancelTransactions(
    walletId: string,
    transactionId: string,
  ): Promise<string> {
    const requestURL = API_WALLET.URLs.Customer.CancelTransactionsByRef.replace(
      '{walletId}',
      walletId,
    ).replace('{transactionId}', transactionId);
    return API.put(API_WALLET.name, requestURL, {}).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async getTransactionsCsv(walletId: string): Promise<string> {
    const requestURL = API_WALLET.URLs.Customer.TransactionsCsv.replace(
      '{walletId}',
      walletId,
    );
    return API.get(API_WALLET.name, requestURL, {}).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }

  async getTransactionsRecipient(
    walletId: string,
    categories?: TransactionCategoryEnum[],
    statuses?: TransactionStatusEnum[],
    operations?: string[],
    filter?: string,
  ): Promise<string[]> {
    const requestURL = API_WALLET.URLs.Customer.TransactionsRecipient.replace(
        '{walletId}',
        walletId,
      ),
      queryStringParameters = {} as any;
    if (categories) {
      queryStringParameters.category = categories;
    }
    if (statuses) {
      queryStringParameters.status = statuses;
    }
    if (operations) {
      queryStringParameters.operation = operations;
    }
    if (filter) {
      queryStringParameters.filter = filter;
    }
    return API.get(API_WALLET.name, requestURL, {
      queryStringParameters,
    }).catch((err) => {
      console.error(err.message);
      return Promise.reject(err);
    });
  }
}
