import {
  IAirlineBrand,
  IAirport,
  IGha,
} from "@cai-services";
import { GhaAttachment } from "./gha-attachment.model";

export class Gha implements IGha {
  id: number;
  airlineBrand: IAirlineBrand;
  station: IAirport;
  effectiveDate: string;
  name: string;
  hle: string;
  address: string;
  ghaAttachments: GhaAttachment[];

  constructor (airlineBrand?: IAirlineBrand, station?: IAirport) {
    this.airlineBrand = airlineBrand;
    this.station = station;
    const date: Date = new Date();
    this.effectiveDate = date.toISOString().slice(0, 10);
    this.name = "";
    this.hle = "";
    this.address = "";
    this.ghaAttachments = [];
  }
}
