export const EmailDetailsTemplate =
  $localize`:@@email.hello:Hello` +
  ",\n\n" +
  $localize`:@@email.find-below-details:Please find below the available route(s) options for the shipment` +
  ": {origin} > {destination} | {commodityCode} | {chargeableWeight} \n" +
  "----" +
  $localize`:@@email.route-summary:Route Summary` +
  ": {airlineName}----\n" +
  "{duration} - {noOfStops} stop(s) - CO2 {co2}\n" +
  "{routeDetails}" +
  "{rateDetails}";

export const EmailRouteDetailTemplate =
  "{origin} > {destination} | {flightNo} | {departureDateTime} - {arrivalDateTime}\n";

export const EmailRateDetailTemplate =
  "-" +
  $localize`:@@email.rate-details:rate details` +
  "-\n" +
  $localize`:@@email.all-in-rate:all in rate` +
  ": {allInRate} | " +
  $localize`:@@email.total-cost:total all in cost` +
  ": {allInCost}\n" +
  "(" +
  $localize`:@@email.net-rate:net rate` +
  ": {totalNetRate}({netRate})" +
  "; " +
  $localize`:@@email.surcharge:surcharge` +
  ": {totalSurcharge}({surcharge})" +
  ")";
