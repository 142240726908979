<div class="lithium-battery" [class.d-none]="!visible">
  <div class="lithium-battery__body">
    <div class="description" *ngIf="descriptionArray.length">
      <span class="pr-1">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.02826 1.75C4.5711 1.75 1.77002 4.54905 1.77002 8C1.77002 11.4509 4.5711 14.25 8.02826 14.25C11.4854 14.25 14.2865 11.4509 14.2865 8C14.2865 4.54905 11.4854 1.75 8.02826 1.75ZM0.27002 8C0.27002 3.71896 3.74434 0.25 8.02826 0.25C12.3122 0.25 15.7865 3.71896 15.7865 8C15.7865 12.281 12.3122 15.75 8.02826 15.75C3.74434 15.75 0.27002 12.281 0.27002 8ZM8.02826 3.98889C8.52532 3.98889 8.92826 4.39183 8.92826 4.88889V8.77778C8.92826 9.27483 8.52532 9.67778 8.02826 9.67778C7.53121 9.67778 7.12826 9.27483 7.12826 8.77778V4.88889C7.12826 4.39183 7.53121 3.98889 8.02826 3.98889ZM8.17129 12.4286C8.59258 12.4286 8.93409 12.0875 8.93409 11.6667C8.93409 11.2459 8.59258 10.9048 8.17129 10.9048C7.75001 10.9048 7.40849 11.2459 7.40849 11.6667C7.40849 12.0875 7.75001 12.4286 8.17129 12.4286Z"
            fill="#4D578D"
          />
        </svg>
      </span>
      <span *ngFor="let desc of descriptionArray; let i = index">
        {{ desc }}
        <span class="separator" *ngIf="i < descriptionArray.length - 1">|</span>
      </span>
    </div>
    <div class="options">
      <div class="mx-1 d-inline-block" *ngFor="let option of options">
        <ng-container
          *ngTemplateOutlet="
            radioButtonTpl;
            context: {
              option: option
            }
          "
        ></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #radioButtonTpl let-option="option">
  <div
    class="custom-radiobutton"
    [id]="option.value"
    [class.selected]="selectedAnswer === option"
  >
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      (click)="selectAnswer(option)"
    >
      <ng-container *ngIf="selectedAnswer === option">
        <path
          d="M17.9965 9C17.9965 13.6912 14.1667 17.5 9.43525 17.5C4.70381 17.5 0.874023 13.6912 0.874023 9C0.874023 4.30877 4.70381 0.5 9.43525 0.5C14.1667 0.5 17.9965 4.30877 17.9965 9Z"
          stroke="#4D578D"
        />
        <ellipse cx="9.43538" cy="9" rx="5.03401" ry="5" fill="#4D578D" />
      </ng-container>
      <ng-container *ngIf="selectedAnswer !== option">
        <path
          d="M17.9965 9C17.9965 13.6912 14.1667 17.5 9.43525 17.5C4.70381 17.5 0.874023 13.6912 0.874023 9C0.874023 4.30877 4.70381 0.5 9.43525 0.5C14.1667 0.5 17.9965 4.30877 17.9965 9Z"
          stroke="#9B9B9F"
        />
      </ng-container>
    </svg>
    <span class="label">{{ option.label }}</span>
  </div>
</ng-template>
