export const minMaxUnitValues = {
  "maxLBValue": 2204620,
  "minLBValue": 0,
  "maxKGValue": 1000000,
  "minKGValue": 0,
  "maxINValue": 393.7,
  "minINValue": 0,
  "maxCMValue": 1000,
  "minCMValue": 0,
};
