import { Component, OnInit } from "@angular/core";
import { AirlineUser } from "../../../core/_models/airline-user.model";

@Component({
  "selector": "kt-auth-add-airline",
  "templateUrl": "./auth-add-airline.component.html",
  "styleUrls": ["./auth-add-airline.component.scss"],
})
export class AuthAddAirlineComponent implements OnInit {
  airline: AirlineUser;

  constructor () {}

  ngOnInit () {}

  // 	/**
  //  * Save data
  //  */
  // onSubmit() {
  // 	this.hasFormErrors = false;
  // 	this.loadingAfterSubmit = false;
  // 	/** check form */
  // 	if (!this.isTitleValid()) {
  // 		this.hasFormErrors = true;
  // 		return;
  // 	}

  // 	const editedRole = this.prepareRole();
  // 	if (editedRole.id > 0) {
  // 		this.updateRole(editedRole);
  // 	} else {
  // 		this.createRole(editedRole);
  // 	}
  // }

  // /**
  //  * Update role
  //  *
  //  * @param _role: Role
  //  */
  // updateRole(_role: Role) {
  // 	this.loadingAfterSubmit = true;
  // 	this.viewLoading = true;
  // 	/* Server loading imitation. Remove this on real code */
  // 	const updateRole: Update<Role> = {
  // 		id: this.role.id,
  // 		changes: _role
  // 	  };
  // 	this.store.dispatch(new RoleUpdated({
  // 		partialrole: updateRole,
  // 		role: _role
  // 	}));
  // 	of(undefined).pipe(delay(1000)).subscribe(() => { // Remove this line
  // 		this.viewLoading = false;
  // 		this.dialogRef.close({
  // 			_role,
  // 			isEdit: true
  // 		});
  // 	}); // Remove this line
  // }

  // /**
  //  * Create role
  //  *
  //  * @param _role: Role
  //  */
  // createRole(_role: Role) {
  // 	this.loadingAfterSubmit = true;
  // 	this.viewLoading = true;
  // 	this.store.dispatch(new RoleOnServerCreated({ role: _role }));
  // 	this.componentSubscriptions = this.store.pipe(
  // 		delay(1000), // Remove this line
  // 		select(selectLastCreatedRoleId)
  // 	).subscribe(res => {
  // 		if (!res) {
  // 			return;
  // 		}

  // 		this.viewLoading = false;
  // 		this.dialogRef.close({
  // 			_role,
  // 			isEdit: false
  // 		});
  // 	});
  // }
}
