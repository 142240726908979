import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { CompanyService } from "@cai-services";
import Swal from "sweetalert2";
import { AppFileService } from "../../../../core/_services/app-file.service";
import { AppOfficeService } from "../../../../core/_services/app-office.service";
import { BrandIconUtil } from "../../../../utils/brand.util";
import { Company } from "../../../../core/_models/company.model";
import { SessionService } from "../../../../core/_services/session.service";

@Component({
  "selector": "kt-company-settings",
  "templateUrl": "./company-settings.component.html",
})
export class CompanySettingsComponent implements OnInit {
  accountForm: FormGroup;
  currentCompany: Company;
  logoPath: string;
  loading: boolean;
  imageFile: File;
  title = $localize`:@@company-setting.title:Company Account Settings`;

  constructor (
    private readonly formBuilder: FormBuilder,
    private readonly officeService: AppOfficeService,
    private readonly companyService: CompanyService,
    private readonly fileService: AppFileService,
    private readonly sessionService: SessionService,
    private readonly ref: ChangeDetectorRef,
    public titleService: Title,
  ) {}

  ngOnInit () {
    this.createForm();
    this.setupData();
    this.titleService.setTitle(
      `Company Account Settings - ${BrandIconUtil.fetchDomainTitle()}`,
    );
  }

  createForm () {
    this.accountForm = this.formBuilder.group({
      "companyName": [""],
      "companyCode": [""],
      "companyLogo": [""],
    });
  }

  async setupData () {
    this.currentCompany = (await this.companyService.getCompany()) as Company;
    if (this.currentCompany) {
      this.accountForm
        .get("companyName")
        .setValue(this.currentCompany.companyName);
      this.accountForm
        .get("companyCode")
        .setValue(this.currentCompany.companyCode);
      this.fileService
        .getCompanyLogo(
          this.currentCompany.logoHash,
          this.currentCompany.logoType,
        )
        .then((result: string) => {
          this.logoPath = result;
          this.ref.detectChanges();
        });
    }
  }

  get isSubmitBtnDisable () {
    return (
      !this.imageFile || (this.accountForm.touched && !this.accountForm.valid)
    );
  }

  onSubmit () {
    this.loading = true;
    const updateCompany = { ...this.currentCompany };
    updateCompany.companyName = this.accountForm.get("companyName").value;
    updateCompany.companyCode = this.accountForm.get("companyCode").value;
    updateCompany.updateLogo = !!this.imageFile;
    if (this.imageFile) {
      updateCompany.logoType = this.imageFile.type.split("/")[1];
    }

    this.companyService
      .updateCompanyById(updateCompany.id, updateCompany)
      .then((result) => {
        this.currentCompany = result as Company;
        if (result.uploadURL) {
          this.officeService
            .uploadCompanyLogo(result.uploadURL, this.imageFile)
            .subscribe(
              () => {
                this.handleSuccessUpdate();
              },
              (err) => {
                console.error(err);
                this.loading = false;
                this.ref.detectChanges();
              },
            );
        } else {
          this.handleSuccessUpdate();
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const errorResponse = error.response.data;
          Swal.fire("Something went wrong!", errorResponse.message, "error");
          this.loading = false;
          if (errorResponse.fieldErrors) {
            Object.keys(errorResponse.fieldErrors).forEach((key) => {
              this.accountForm.get(key).setErrors({
                [errorResponse.fieldErrors[key][0].code]:
                  errorResponse.fieldErrors[key][0].message,
              });
            });
            this.ref.detectChanges();
          }
        }
      });
  }

  handleSuccessUpdate (): void {
    Swal.fire("Successfully updated!", "", "success");
    this.loading = false;
    this.sessionService.setCurrentUserCompany(this.currentCompany);
    this.ref.detectChanges();
  }

  async onProfilePictureChanged (imageFile: File): Promise<void> {
    this.imageFile = imageFile;
  }
}
