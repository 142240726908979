// Angular
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ADMIN_GROUP, SUPERADMIN_USER_GROUP } from "../_constants/constants";
import { RegisterService } from "../_services/register.service";
import { SessionService } from "../_services/session.service";
// Auth reducers and selectors
import { APP_PROPS } from "../../cai-common.module";
import { ApplicationProperties } from "../_base/layout/models/app-properties.model";
import { AuthenticatedGuard } from "./base.guard";

@Injectable()
export class AdminGuard extends AuthenticatedGuard {
  constructor (
    @Inject(APP_PROPS)
    appProperties: ApplicationProperties,
    router: Router,
    sessionService: SessionService,
    registerService: RegisterService
  ) {
    super(appProperties, router, sessionService, registerService);
  }

  protected override isUserAuthorizedToViewPage (
    loggedInUserGroups: any[]
  ): boolean {
    return (
      loggedInUserGroups &&
      (loggedInUserGroups.includes(SUPERADMIN_USER_GROUP) ||
        loggedInUserGroups.includes(ADMIN_GROUP))
    );
  }
}
