import {
  ChangeDetectorRef,
  Component,
  EmbeddedViewRef,
  Input,
  OnInit,
} from "@angular/core";
import { CargoMartPlansType, IAirlineRate, IRateInsight, UserTypeEnum } from "@cai-services";
import { datadogRum } from "@datadog/browser-rum";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";
import Popper from "popper.js";
import {
  AppRateService,
  CustomFlightDetails,
  SearchTemplate,
  SessionService,
  UserFeaturesEnum,
} from "../../core";
import { environment } from "../../environments";
import { CaiNumberFormatterPipe } from "../../pipes";
import { HomepageCommonUtil } from "../../utils/homepage.util";
import { ProGenericModal } from "../office-settings/modals/pro-generic-modal/pro-generic-modal.component";

@Component({
  "selector": "cai-cargomate-popup",
  "templateUrl": "./cargomate-popup.component.html",
  "styleUrls": ["cargomate-popup.component.scss"],
})
export class CargomatePopupComponent implements OnInit {
  @Input() searchUUID: string;
  @Input() airlineCode: string;
  @Input() allFlights: CustomFlightDetails[];
  @Input() searchTemplate: SearchTemplate;

  selectedDate: string;
  dates: string[] = [];
  popperRef: Popper;
  view: EmbeddedViewRef<any>;
  ghaName: string;
  ghaAddress: string;
  insight: IRateInsight;
  officeId: number;
  isLoading = true;
  isCopilotActivated = false;
  features = environment.features;

  constructor (
    private readonly appRateService: AppRateService,
    private readonly sessionService: SessionService,
    private readonly cdr: ChangeDetectorRef,
    private readonly caiNumberFormatterPipe: CaiNumberFormatterPipe,
    private readonly modalService: NgbModal,
  ) {}

  ngOnInit () {
    this.addDatadogFlagEvaluations();

    if (this.isCargoMartProEnabled) {
      this.isCopilotActivated = this.isCargoCopilotRateInsightsEnabled;
      if (!this.isCopilotActivated) {
        this.isLoading = false;
        return;
      }
    }

    for (let i = 0; i < 7; i++) {
      const newDate = new Date(this.searchTemplate?.searchDate);
      newDate.setDate(newDate.getDate() + i);
      this.dates.push(moment(newDate).format("DD/MM/YY"));
    }
    this.selectedDate = this.dates[0];
    const flightWithGhaInfo = this.allFlights.find(
      (flight) => !!flight.ghaName || !!flight.ghaAddress,
    );
    this.ghaName = flightWithGhaInfo?.ghaName;
    this.ghaAddress = flightWithGhaInfo?.ghaAddress;
    this.officeId = this.allFlights
      ?.find((flight) => flight.rates?.some((rate) => !!rate?.coloaderOfficeId))
      ?.rates?.find((rate) => !!rate?.coloaderOfficeId)?.coloaderOfficeId;
    if (!this.officeId) {
      this.officeId = this.searchTemplate?.office?.id;
    }
    this.fetchRateInsight();
  }

  addDatadogFlagEvaluations () {
    if (environment.datadog) {
      datadogRum.addFeatureFlagEvaluation(
        UserFeaturesEnum.CARGO_COPILOT_RATE_INSIGHTS,
        this.isCargoCopilotRateInsightsEnabled,
      );

      datadogRum.addFeatureFlagEvaluation(
        UserFeaturesEnum.CARGOMART_PRO,
        this.isCargoMartProEnabled,
      );
    }
  }

  fetchRateInsight () {
    const rateInsight = this.sessionService.getRateInsight(
      this.searchUUID.concat(this.airlineCode).concat(this.selectedDate),
    );
    if (rateInsight) {
      this.insight = rateInsight;
      this.isLoading = false;
      this.cdr.detectChanges();
    } else {
      this.appRateService
        .getRateInsight(
          this.airlineCode,
          this.searchTemplate.origin,
          this.searchTemplate.destination,
          this.getProductCode(),
          this.searchTemplate.chargeableWeight,
          this.officeId,
          this.selectedDate?.replace(/\//g, "-"),
          this.searchTemplate.originCountry,
          this.searchTemplate.destCountry,
        )
        .subscribe({
          "next": (rateInsight) => {
            this.insight = rateInsight;
            this.sessionService.setRateInsight(
              this.searchUUID
                .concat(this.airlineCode)
                .concat(this.selectedDate),
              rateInsight,
            );
            this.isLoading = false;
            this.cdr.detectChanges();
          },
          "error": (error) => {
            console.log(error);
            this.isLoading = false;
            this.cdr.detectChanges();
          },
        });
    }
  }

  select (date: string) {
    if (this.selectedDate !== date) {
      this.selectedDate = date;
      this.isLoading = true;
      this.fetchRateInsight();
      this.cdr.detectChanges();
    }
  }

  isExpress (): boolean {
    return !!this.searchTemplate?.requirements?.find((req) =>
      req?.answer?.specialHandlingCodes?.some((shc) => shc?.shcCode === "XPS"),
    );
  }

  getProductCode (): string {
    if (this.isExpress()) {return "XPS";}
    return this.searchTemplate?.typeOfProduct?.productCode;
  }

  get showRateDetails (): boolean {
    return (
      !!this.insight?.marketRate ||
      !!this.insight?.contractRate ||
      !!this?.insight?.promoRate
    );
  }

  get currency (): string {
    return (
      this.insight?.marketRate?.currency ??
      this.insight?.contractRate?.currency ??
      this.insight?.promoRate?.currency
    );
  }

  get promoValidity (): string {
    return (
      moment(new Date(this.insight?.promoRate?.startDate)).format("DD/MM/YY") +
      " - " +
      moment(new Date(this.insight?.promoRate?.endDate)).format("DD/MM/YY")
    );
  }

  getRateValue (
    airlineRate: IAirlineRate,
    weightType: string,
    weight?: number,
  ): string {
    let amount = 0;
    if (weight) {
      amount = airlineRate?.rates?.find(
        (rate) => rate?.weightTypeRate?.weight === weight,
      )?.amount;
    } else {
      amount = airlineRate?.rates?.find(
        (rate) => rate?.weightTypeRate?.weightType === weightType,
      )?.amount;
    }
    if (amount === 0) {
      return "-";
    }
    if (weightType !== "M") {
      amount =
        amount +
        airlineRate.fuelSurcharge +
        airlineRate.securitySurcharge +
        airlineRate.miscellaneousCharge;
    }
    return this.caiNumberFormatterPipe.transform(amount);
  }

  shouldHighlight (min: number, max?: number): boolean {
    return (
      this.searchTemplate.chargeableWeight >= min &&
      (!max || this.searchTemplate.chargeableWeight < max)
    );
  }

  getGeneralConditions (): string {
    let disclaimer = "";
    if (!!this.insight?.coloaderDisclaimer) {
      disclaimer = disclaimer
        .concat(
          "<div style='font-size: 13px; font-weight: 500px'>Partner disclaimer:</div><br/><br/>",
        )
        .concat(this.insight.coloaderDisclaimer.replace(/\n/g, "<br/>"));
    }
    if (!!this.insight?.generalConditions) {
      disclaimer = disclaimer
        .concat(
          "<div style='font-size: 13px; font-weight: 500px'>Airline disclaimer:</div><br/><br/>",
        )
        .concat(
          this.insight?.generalConditions
            ?.map((gc) => gc.terms?.replace(/\n/g, "<br/>"))
            ?.join("<br/><br/>"),
        );
    }
    return disclaimer;
  }

  showUpgradeMessage () {
    if(this.sessionService.getSelectedOffice()?.plan === CargoMartPlansType.PRO || this.sessionService.getCurrentUser()?.type === UserTypeEnum.AIRLINE){
      return;
    }
    const branchesSelectorModal = this.modalService.open(ProGenericModal, {
      "centered": true,
    });
    branchesSelectorModal.componentInstance.type = "SUGGEST-UPGRADE";
  }

  get isCargoCopilotRateInsightsEnabled (): boolean {
    const searchOfficeId = this.sessionService.getSearchOfficeId(),
     officeAccess = this.sessionService.getCurrentUser()?.officeAccesses?.find((oa) => oa.office.id == searchOfficeId),
     office = officeAccess ? officeAccess.office : null;
    return HomepageCommonUtil.isFeatureEnabled(
      office?.activatedFeatures,
      UserFeaturesEnum.CARGO_COPILOT_RATE_INSIGHTS,
    );
  }

  get isCargoMartProEnabled (): boolean {
    return (
      HomepageCommonUtil.isFeatureEnabled(
        this.sessionService.getCurrentUser()?.activatedFeatures,
        UserFeaturesEnum.CARGOMART_PRO,
      ) || this.features?.cargomartPro
    );
  }
}
