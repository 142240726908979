import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import { IDocumentExport } from "../../model/document-export.model";
import { IFlight } from "../../model/flight.model";
import { IPaginationResults } from "../../model/pagination-result.model";
import { IQuote } from "../../model/quote.model";
import { ErrorUtil } from "../../utils/error.util";
import { API_CORE } from "../../constant/api-core.const";

@Injectable({
  "providedIn": "root",
})
export class FlightService {
  async getFlights (
    page: number,
    size: number,
    sort: string,
    filterOptions?: { [key: string]: string }
  ): Promise<IPaginationResults<IFlight>> {
    const queryStringParameters = {
      page,
      size,
      sort,
    };
    if (filterOptions && Object.keys(filterOptions).length > 0) {
      Object.keys(filterOptions).forEach((key) => {
        if (filterOptions[key]) {
          queryStringParameters[key] = filterOptions[key];
        }
      });
    }
    return API.get(API_CORE.name, API_CORE.URLs.Flight.Flights, {
      queryStringParameters,
    }).catch(ErrorUtil.handleError);
  }

  async getFlightNo (
    origin: string,
    destination: string,
    airline: string,
    date: string
  ): Promise<string[]> {
    const extraOptions = {
      "queryStringParameters": {
        origin,
        destination,
        airline,
        date,
      },
    };
    return API.get(
      API_CORE.name,
      API_CORE.URLs.Flight.FlightNumbers,
      extraOptions
    ).catch(ErrorUtil.handleError);
  }

  async getQuoteFlight (flightId: number): Promise<IQuote[]> {
    const url = API_CORE.URLs.Flight.FlightQuotesById.replace(
      "{flightId}",
      flightId.toString()
    );
    return API.get(API_CORE.name, url, {}).catch(ErrorUtil.handleError);
  }

  async getQuoteFlightExcel (flightId: number): Promise<IDocumentExport> {
    const url = API_CORE.URLs.Flight.FlightQuotesExcelById.replace(
      "{flightId}",
      flightId.toString()
    );
    return API.get(API_CORE.name, url, {}).catch(ErrorUtil.handleError);
  }

  async updateFlight (flightId: number, flight: IFlight): Promise<IFlight> {
    const url = API_CORE.URLs.Flight.FlightById.replace(
      "{flightId}",
      flightId.toString()
    );
    return API.put(API_CORE.name, url, {
      "body": flight,
    }).catch((err) => {
      ErrorUtil.handleError(err);
      throw err;
    });
  }

  async getAlternativeFlight (
    origin: string,
    destination: string,
    flightNumber: string,
    date: string,
    token?: string
  ): Promise<IFlight> {
    const extraOptions: any = {
      "queryStringParameters": {
        origin,
        destination,
        flightNumber,
        date,
      },
    };
    if (token) {
      extraOptions.queryStringParameters.token = token;
    }

    return API.get(
      API_CORE.name,
      API_CORE.URLs.Flight.Alternatives,
      extraOptions
    ).catch(ErrorUtil.handleError);
  }

  async getCarbonEmissions (
    aircraftCode: string,
    origin: string,
    destination: string,
    weight: number
  ): Promise<number> {
    const extraOptions: any = {
      "queryStringParameters": {
        aircraftCode,
        origin,
        destination,
        weight,
      },
    };

    return API.get(
      API_CORE.name,
      API_CORE.URLs.Flight.CarbonEmission,
      extraOptions
    ).catch(ErrorUtil.handleError);
  }

  async getMaxCapacityOfFlight (
    legs: { flightNo: string; departureDate: string; aircraftType: string }[]
  ): Promise<{ optionWeightKg: number }> {
    const extraOptions: any = {
      "queryStringParameters": {
        "flightNos": legs.map((leg) => leg.flightNo).join(","),
        "departureDates": legs.map((leg) => leg.departureDate).join(","),
        "aircraftTypes": legs.map((leg) => leg.aircraftType).join(","),
      },
    },

     url = API_CORE.URLs.Flight.MaxCapacity;

    return API.get(API_CORE.name, url, extraOptions).catch(
      ErrorUtil.handleError
    );
  }
}
