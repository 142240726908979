import {
  AdvancedBookingApiDetails,
  IAdvancedBookingItemTemplate,
  LoadTypeEnum,
  MeasurementSystemEnum,
  PackingTypeEnum,
  WeightTypeEnum,
} from "@cai-services";
import { cloneDeep } from "lodash";
import moment from "moment";
import { MeasurementUtil } from "../core/_base/crud/utils/measurement.util";
import { AdvancedBookingDetails } from "../core/_models/advanced-booking-details.model";
import { AirlineBrand } from "../core/_models/airline.model";
import { AirportLight } from "../core/_models/airport-light.model";
import { BUP } from "../core/_models/bup.model";
import { Dimension } from "../core/_models/dimension.model";
import { MeasurementUnits } from "../core/_models/measurement-system.model";
import { TypeOfProduct } from "../core/_models/type-of-product.model";
import { SearchUtil } from "./search.util";

export class AdvancedBookingUtil {
  static processData (
    dataList: AdvancedBookingApiDetails[],
    isFavorite?: boolean,
    favoriteList?: AdvancedBookingApiDetails[],
    measurementSystem?: MeasurementSystemEnum,
  ): AdvancedBookingDetails[] {
    return (dataList || []).map((data) => ({
        "shipmentId": null,
        "favoriteId": data?.id,
        "shipmentStatus": null,
        "loadTypeMode": data.advancedBookingItemTemplates?.[0]?.numOfItems
          ? LoadTypeEnum[data?.advancedBookingItemTemplates?.[0]?.itemType]
          : LoadTypeEnum.TOTAL,
        "airline": data?.airlineBrand?.airlineCompanyCode,
        "origin": data?.originAirport?.airportCode,
        "destination": data?.destinationAirport?.airportCode,
        "flightNo": data?.flightNumber,
        "allotmentCode": data?.allotmentCode,
        "awb": data?.awb?.slice(0, 3) || data?.airlineBrand?.awbPrefix,
        "weight":
          data?.advancedBookingItemTemplates?.reduce(
            (acc, item) => acc + item?.weight,
            0,
          ) || 1,
        "volume": this.convertVolume(
          data?.advancedBookingItemTemplates?.reduce(
            (acc, item) => acc + item?.volume,
            0,
          ) || 1,
          measurementSystem,
          data?.measurementSystem,
        ),
        "pieces":
          data?.advancedBookingItemTemplates?.reduce(
            (acc, item) => acc + item?.numOfItems,
            0,
          ) || 1,
        "goodsType": data?.commodity?.name,
        "departureDate": data?.departureDate,
        "commodities": [data.commodity],
        "isFavorite": isFavorite
          ? true
          : this.checkFavorite(data, favoriteList, measurementSystem),
        "bups":
          data.advancedBookingItemTemplates?.[0]?.itemType === "BUP" &&
          data.advancedBookingItemTemplates?.[0].numOfItems
            ? this.constructBUP(data.advancedBookingItemTemplates)
            : [],
        "dimensions":
          data.advancedBookingItemTemplates?.[0]?.itemType === "DIMENSIONS" &&
          data.advancedBookingItemTemplates?.[0].numOfItems
            ? this.constructDimensions(
                data.advancedBookingItemTemplates,
                measurementSystem,
                data?.measurementSystem,
              )
            : [],
        "ApiRawData": data,
        "airlineCompanyName": data?.airlineBrand?.airlineCompanyName,
      }));
  }

  static checkFavorite (
    data: AdvancedBookingApiDetails,
    favoriteList: AdvancedBookingApiDetails[],
    measurementSystem: MeasurementSystemEnum,
  ): boolean {
    const favorite = favoriteList?.find((favoriteData) => {
      const clonedFavoriteData = JSON.parse(JSON.stringify(favoriteData));
      clonedFavoriteData.advancedBookingItemTemplates.forEach(
        (advancedBookingItemTemplate) => {
          advancedBookingItemTemplate.id = null;
          advancedBookingItemTemplate.volume =
            +advancedBookingItemTemplate.volume.toFixed(1);
        },
      );
      const clonedData = cloneDeep(data);
      clonedData?.advancedBookingItemTemplates.forEach(
        (advancedBookingItemTemplate) => {
          advancedBookingItemTemplate.volume = +this.convertVolume(
            +advancedBookingItemTemplate.volume,
            measurementSystem,
            data?.measurementSystem,
          ).toFixed(1);
          advancedBookingItemTemplate.width =
            advancedBookingItemTemplate.width < 0 ||
            !advancedBookingItemTemplate.width
              ? -100
              : this.convertMeasure(
                  advancedBookingItemTemplate.width,
                  measurementSystem,
                  data?.measurementSystem,
                );
          advancedBookingItemTemplate.length =
            advancedBookingItemTemplate.length < 0 ||
            !advancedBookingItemTemplate.length
              ? -100
              : this.convertMeasure(
                  advancedBookingItemTemplate.length,
                  measurementSystem,
                  data?.measurementSystem,
                );
          advancedBookingItemTemplate.height =
            advancedBookingItemTemplate.height < 0 ||
            !advancedBookingItemTemplate.height
              ? -100
              : this.convertMeasure(
                  advancedBookingItemTemplate.height,
                  measurementSystem,
                  data?.measurementSystem,
                );
          advancedBookingItemTemplate.packingType =
            !advancedBookingItemTemplate.packingType
              ? PackingTypeEnum.NONE
              : advancedBookingItemTemplate.packingType;
        },
      );
      if (
        favoriteData.flightNumber === data.flightNumber &&
        favoriteData.airlineBrand?.airlineCompanyCode ===
          data.airlineBrand?.airlineCompanyCode &&
        data?.destinationAirport?.airportCode ===
          favoriteData?.destinationAirport?.airportCode &&
        data?.originAirport?.airportCode ===
          favoriteData?.originAirport?.airportCode &&
        moment(favoriteData?.departureDate).format("YYYY-MM-DD") ===
          moment(data?.departureDate).format("YYYY-MM-DD") &&
        JSON.stringify(clonedData?.advancedBookingItemTemplates) ===
          JSON.stringify(clonedFavoriteData?.advancedBookingItemTemplates)
      ) {
        return favoriteData;
      }
      return false;
    });
    return !!favorite;
  }

  static constructBUP (
    advancedBookingItemTemplates: IAdvancedBookingItemTemplate[],
  ): BUP[] {
    return advancedBookingItemTemplates.map((advancedBookingItemTemplate) => ({
        "uldType": advancedBookingItemTemplate?.uldType,
        "quantity": advancedBookingItemTemplate?.numOfItems,
        "weight": advancedBookingItemTemplate?.weight,
        "weightType": advancedBookingItemTemplate?.weightType,
        "weightUnit": "kg",
        "volume": advancedBookingItemTemplate?.volume,
        "volumeUnit": "m³",
        "isNewRow": false,
        "isTouched": false,
        "isFocused": false,
        "errors": [],
      }));
  }

  static constructDimensions (
    advancedBookingItemTemplates: IAdvancedBookingItemTemplate[],
    measurementSystem: MeasurementSystemEnum,
    advancedBookingMeasurementSystem: MeasurementSystemEnum,
  ): Dimension[] {
    return advancedBookingItemTemplates.map((advancedBookingItemTemplate) => ({
        "quoteItemId": undefined,
        "quoteRequest": null,
        "numOfItems": advancedBookingItemTemplate?.numOfItems,
        "weight": advancedBookingItemTemplate?.weight,
        "dimensionLength": this.convertMeasure(
          advancedBookingItemTemplate?.length,
          measurementSystem,
          advancedBookingMeasurementSystem,
        ),
        "dimensionWidth": this.convertMeasure(
          advancedBookingItemTemplate?.width,
          measurementSystem,
          advancedBookingMeasurementSystem,
        ),
        "dimensionHeight": this.convertMeasure(
          advancedBookingItemTemplate?.height,
          measurementSystem,
          advancedBookingMeasurementSystem,
        ),
        "packing": advancedBookingItemTemplate?.packingType,
        "weightType": advancedBookingItemTemplate?.weightType,
        "typeOfProduct": {} as TypeOfProduct,
        "dimensionsUnit": MeasurementUnits.METRIC.MEASURE.display,
        "weightUnit": MeasurementUnits.METRIC.WEIGHT.display,
        "loadType": LoadTypeEnum.DIMENSIONS,
        "isNewRow": false,
        "isTouched": false,
        "isFocused": false,
        "errors": [],
      }));
  }

  static constructBupBookingItemTemplate (
    bups: BUP[],
    weightUnit: MeasurementSystemEnum,
  ): IAdvancedBookingItemTemplate[] {
    const bupsList = [];
    bups.forEach((bup) => {
      if (bup.uldType) {
        bupsList.push({
          "advancedBookingTemplate": null,
          "height": -100,
          "id": null,
          "itemType": LoadTypeEnum.BUP,
          "length": -100,
          "numOfItems": bup.quantity,
          "packingType": PackingTypeEnum.NONE,
          "uldType": bup.uldType,
          "volume": +bup.volume,
          "weight": SearchUtil.convertWeight(+bup.weight, weightUnit),
          "weightType": bup.weightType,
          "width": -100,
        });
      }
    });
    return bupsList;
  }

  static constructDimensionBookingItemTemplate (
    dimensions: Dimension[],
    volume: number,
    weightUnit: MeasurementSystemEnum,
    dimensionUnit: MeasurementSystemEnum,
    defaultMeasurementSystem: MeasurementSystemEnum,
  ): IAdvancedBookingItemTemplate[] {
    const dimensionsList = [];
    dimensions.forEach((dimension) => {
      if (dimension.dimensionLength) {
        dimensionsList.push({
          "advancedBookingTemplate": null,

          "height": SearchUtil.convertDimension(
            +dimension.dimensionHeight,
            defaultMeasurementSystem,
            dimensionUnit,
          ),
          "id": null,
          "itemType": LoadTypeEnum.DIMENSIONS,
          "length": SearchUtil.convertDimension(
            +dimension.dimensionLength,
            defaultMeasurementSystem,
            dimensionUnit,
          ),
          "numOfItems": +dimension.numOfItems,
          "packingType": dimension.packing,
          "uldType": null,
          "volume": +volume,
          "weight": SearchUtil.convertWeight(+dimension.weight, weightUnit),
          "weightType": dimension.weightType,
          "width": SearchUtil.convertDimension(
            +dimension.dimensionWidth,
            defaultMeasurementSystem,
            dimensionUnit,
          ),
        });
      }
    });
    return dimensionsList;
  }

  static constructTotalBookingItemTemplate (
    total: AdvancedBookingDetails,
    weightUnit: MeasurementSystemEnum,
  ): IAdvancedBookingItemTemplate[] {
    return [
      {
        "advancedBookingTemplate": null,
        "height": -100,
        "id": null,
        "itemType": LoadTypeEnum.TOTAL,
        "length": -100,
        "numOfItems": +total.pieces,
        "packingType": PackingTypeEnum.NONE,
        "uldType": null,
        "volume": +total.volume,
        "weight": SearchUtil.convertWeight(+total.weight, weightUnit),
        "weightType": WeightTypeEnum.TOTAL,
        "width": -100,
      },
    ];
  }

  static updateApiRawData (
    advancedBooking: AdvancedBookingDetails,
    regionAirports: AirportLight[],
    airlines: AirlineBrand[],
    weightUnit: MeasurementSystemEnum,
    dimensionUnit: MeasurementSystemEnum,
    defaultMeasurementSystem: MeasurementSystemEnum,
  ): AdvancedBookingApiDetails {
    return {
      "airlineBrand": airlines.find(
        (airline) => airline.airlineCompanyCode === advancedBooking.airline,
      ),
      "originAirport": regionAirports.find(
        (regionAirport) => regionAirport.airportCode === advancedBooking.origin,
      ),
      "destinationAirport": regionAirports.find(
        (regionAirport) =>
          regionAirport.airportCode === advancedBooking.destination,
      ),
      "allotmentCode": advancedBooking.allotmentCode,
      "awb": advancedBooking.awb,
      "commodity": advancedBooking.commodities[0],
      "departureDate": advancedBooking.departureDate,
      "favorite": true,
      "flightNumber": advancedBooking.flightNo,
      "id": advancedBooking.shipmentId,
      "user": null,
      "advancedBookingItemTemplates": advancedBooking.bups[0].uldType
        ? this.constructBupBookingItemTemplate(advancedBooking.bups, weightUnit)
        : advancedBooking.dimensions[0].dimensionHeight
          ? this.constructDimensionBookingItemTemplate(
              advancedBooking.dimensions,
              advancedBooking.volume,
              weightUnit,
              dimensionUnit,
              defaultMeasurementSystem,
            )
          : this.constructTotalBookingItemTemplate(advancedBooking, weightUnit),
      "measurementSystem": advancedBooking.measurementSystem,
    } as AdvancedBookingApiDetails;
  }

  static convertVolume (
    value: number,
    measurementSystem: MeasurementSystemEnum,
    advancedBookingMeasurementSystem: MeasurementSystemEnum,
  ) {
    if (measurementSystem === advancedBookingMeasurementSystem) {
      return value;
    } else {
      return MeasurementUtil.convertVolume(
        value,
        advancedBookingMeasurementSystem,
        measurementSystem,
      );
    }
  }

  static convertMeasure (
    value: number,
    measurementSystem: MeasurementSystemEnum,
    advancedBookingMeasurementSystem: MeasurementSystemEnum,
  ) {
    if (measurementSystem === advancedBookingMeasurementSystem) {
      return value;
    } else {
      return MeasurementUtil.convertMeasure(
        value,
        advancedBookingMeasurementSystem,
        measurementSystem,
      );
    }
  }
}
