<div class="modal-header">
  <span i18n="@@global.copy-paste-your-packing-list-text"
    >COPY-PASTE YOUR PACKING LIST TEXT</span
  >
  <svg
    (click)="closeModal()"
    width="24"
    height="26"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.48329 5.76877C5.78352 5.4834 6.25824 5.49544 6.54361 5.79567L12 11.5361L17.4564 5.79567C17.7418 5.49544 18.2165 5.4834 18.5167 5.76877C18.8169 6.05414 18.829 6.52886 18.5436 6.82909L13.0348 12.6247L18.5436 18.4203C18.829 18.7206 18.8169 19.1953 18.5167 19.4806C18.2165 19.766 17.7418 19.754 17.4564 19.4537L12 13.7133L6.54361 19.4537C6.25824 19.754 5.78352 19.766 5.48329 19.4806C5.18306 19.1953 5.17102 18.7206 5.45639 18.4203L10.9652 12.6247L5.45639 6.82909C5.17102 6.52886 5.18306 6.05414 5.48329 5.76877Z"
      fill="#679EC6"
    />
  </svg>
</div>
<div class="modal-body">
  <textarea
    name="message"
    [(ngModel)]="inputText"
    i18n-placeholder="@@global.enter-your-text"
    placeholder="Enter your text"
    maxlength="2000"
  ></textarea>
</div>
<div class="model-footer">
  <span class="text-uppercase" i18n="@@global.cancel" (click)="closeModal()"
    >Cancel</span
  >
  <button
    i18n="@@global.autofill-dimensions"
    [disabled]="!inputText"
    (click)="onTextAutofillClick()"
    class="text-uppercase"
  >
    Autofill Dimensions
  </button>
</div>
