import moment from "moment";

export class DateTimeUtil {
  private static readonly SUNDAY = 0;
  private static readonly SATURDAY = 6;

  static businessDaysFrom (date: Date | string, businessDays: number): Date {
    const currentDate = moment(date).toDate(),
     increment = businessDays > 0 ? 1 : -1;
    let numberOfDaysLeft = Math.abs(businessDays);
    while (numberOfDaysLeft > 0) {
      currentDate.setDate(currentDate.getDate() + increment);

      if (!this.isWeekend(currentDate)){
        numberOfDaysLeft--;
      }
    }
  return currentDate;
  }

  private static isWeekend (date: Date): boolean {
    return [this.SUNDAY, this.SATURDAY].includes(date.getDay());
  }
}
