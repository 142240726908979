import { Injectable } from "@angular/core";
import { API } from "@aws-amplify/api";
import { ISpecialHandlingCode } from "../../model/special-handling-code.model";
import { ErrorUtil } from "../../utils/error.util";
import { API_CORE } from "../../constant/api-core.const";

@Injectable({
  "providedIn": "root",
})
export class SpecialHandlingCodeService {
  async getSpecialHandlingCodes (): Promise<ISpecialHandlingCode[]> {
    return API.get(
      API_CORE.name,
      API_CORE.URLs.SpecialHandlingCode.SpecialHandlingCodes,
      {}
    ).catch(ErrorUtil.handleError);
  }

  async getSpecialHandlingCode (code: string): Promise<ISpecialHandlingCode> {
    const url =
      API_CORE.URLs.SpecialHandlingCode.SpecialHandlingCodeByCode.replace(
        "{code}",
        code
      );
    return API.get(API_CORE.name, url, {}).catch(ErrorUtil.handleError);
  }
}
