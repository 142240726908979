import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  signal, OnInit,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import {
  AdvancedBookingAlertTypeEnum,
  AdvancedBookingApiDetails,
  AdvancedBookingCardTypeEnum,
  AdvancedBookingsService,
  AwbTypeEnum,
  BookingTypeEnum,
  CommodityResultTypeEnum,
  CommodityService,
  ContactTypeEnum,
  FlightService,
  IAirlineUser,
  ICommodity,
  ICommodityLight,
  ICommoditySearchResult,
  IFlightSearchDetail,
  IRequirementQuote,
  IRequirementSearchTemplate,
  LoadTypeEnum,
  MeasurementSystemEnum,
  PackingTypeEnum,
  QuoteActionEnum,
  QuoteErrorTypeEnum,
  QuoteOrigin,
  QuoteRequestStatusEnum,
  QuoteStatusEnum,
  RateTypeEnum,
  RateWeightTypeEnum,
  RequirementCodeEnum,
  SearchService,
  UserService,
  UserTypeEnum,
  WalletPaymentTypesEnum,
} from "@cai-services";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep } from "lodash";
import moment from "moment";
import { Observable, Subscription } from "rxjs";
import {
  bookingDetailsTemplate,
  bupsTemplate,
  copyAdvancedBookingDetailTemplate,
  dimensionsTemplate,
} from "../../constant/copy-template.const";
import {
  DANGEROUS_GOODS,
  DEFAULT_GOODS_TYPE,
  KNOWN_SHIPPER_COUNTRIES,
} from "../../constant/homepage-default.const";
import { ExpressAnswer } from "../../constant/requirements/express.const";
import { ScreenedAnswer } from "../../constant/requirements/screened.const";
import { MeasurementUtil } from "../../core/_base/crud/utils/measurement.util";
import { DATE_FORMAT_WITH_TIMEZONE } from "../../core/_constants/constants";
import { AdvancedBookingDetails } from "../../core/_models/advanced-booking-details.model";
import {
  AwbContent,
  EmailContent,
  SelectedFlightContent,
} from "../../core/_models/advanced-booking-list-request.model";
import { Aircraft } from "../../core/_models/aircraft.model";
import { AirlineBrand } from "../../core/_models/airline.model";
import { AirportLight } from "../../core/_models/airport-light.model";
import { BUP } from "../../core/_models/bup.model";
import { Dimension } from "../../core/_models/dimension.model";
import { DropdownOption } from "../../core/_models/dropdown-option.model";
import { FlightInput, Leg } from "../../core/_models/flight-input.model";
import { Forwarder } from "../../core/_models/forwarder.model";
import { MeasurementUnit } from "../../core/_models/measurement-system.model";
import { ContactQuote } from "../../core/_models/quote-contact.model.model";
import { QuoteItem } from "../../core/_models/quote-item.model";
import {
  QuoteRequest,
  QuoteRequestWithErrors,
} from "../../core/_models/quote-request.model";
import { Quote, QuoteWithErrors } from "../../core/_models/quote.model";
import { SpecialHandlingCode } from "../../core/_models/special-handling-code.model";
import { Surcharges } from "../../core/_models/surcharges.model";
import { TypeOfProduct } from "../../core/_models/type-of-product.model";
import { User } from "../../core/_models/user.model";
import { AppOfficeService } from "../../core/_services";
import { AdvancedBookingsRequestService } from "../../core/_services/advanced-bookings.service";
import { AppFileService } from "../../core/_services/app-file.service";
import { AppQuoteService } from "../../core/_services/app-quote.service";
import { QuoteListService } from "../../core/_services/quote-list/quote-list.service";
import { QuotecartService } from "../../core/_services/quotecart.service";
import { SessionService } from "../../core/_services/session.service";
import { AdvancedBookingActionEnum } from "../../enum/advanced-booking-action.enum";
import { mapFlightSearchToTrip } from "../../helpers/search_helpers";
import { AdvancedBookingUtil } from "../../utils/advanced-booking.util";
import { CommodityUtil } from "../../utils/commodity.util";
import { RequirementUtil } from "../../utils/requirement.util";
import { SearchResultUtil } from "../../utils/search-result.util";
import { SearchUtil } from "../../utils/search.util";
import { AllotmentValidators } from "../../validators/allotment.validator";
import { AwbValidators } from "../../validators/awb.validator";
import { FlightNoValidators } from "../../validators/flight-no.validator";
import { FormValidators } from "../../validators/form.validator";
import { AdvancedLoadTypeComponent } from "../advanced-load-type/advanced-load-type.component";
import { AdvancedSearchResultsComponent } from "../advanced-search-results/advanced-search-results.component";
import { CaiLoadTypeBupComponent } from "../load-type/components/load-type-bup/load-type-bup.component";
import { CaiLoadTypeDimensionComponent } from "../load-type/components/load-type-dimension/load-type-dimension.component";
import { LoadTypeUnit } from "../load-type/model/load-type-unit.model";
import { CaiBUPUtil } from "../load-type/utils/bup.util";
import { CaiDimensionUtil } from "../load-type/utils/dimension.util";
import { AirlineUser, QuoteRequestAirline } from "../../core";

const MAX_COMMODITY_LEVEL_TO_USE_SHC = 3,
 SPECIAL_REQUIREMENTS_TO_USE_SHC = [
  RequirementCodeEnum.DIPLOMATIC,
  RequirementCodeEnum.EXPRESS,
  RequirementCodeEnum.SCREENED,
  RequirementCodeEnum.KNOWN_SHIPPER,
  RequirementCodeEnum.CARGO_AIRCRAFT_ONLY,
  RequirementCodeEnum.LITHIUM_BATTERY_DETAIL,
];

@Component({
  "selector": "kt-booking-details-item",
  "templateUrl": "./booking-details-item.component.html",
  "styleUrls": ["./booking-details-item.component.scss"],
})
export class BookingDetailsItemComponent
  implements OnChanges, AfterViewInit, OnDestroy, OnInit
{
  @ViewChildren("searchField") searchFields: QueryList<any>;
  @ViewChildren("bookingField") bookingFields: QueryList<any>;
  @ViewChild("loadTypeCmp") loadTypeCmp: AdvancedLoadTypeComponent;
  @ViewChild("dimensionTable") dimensionTable: CaiLoadTypeDimensionComponent;
  @ViewChild("bupTable") bupTable: CaiLoadTypeBupComponent;
  @Input() typeOfProducts: TypeOfProduct[] = [];
  @Input() advancedBookingDetails: AdvancedBookingDetails;
  @Input() favorites: AdvancedBookingApiDetails[];
  @Input() isActive: boolean;
  @Input() readOnly: boolean;
  @Input() shouldSubmit: boolean;
  @Input() hideFavorite: boolean;
  @Input() disableMenu: boolean;
  @Input() isSuggestion: boolean;
  @Input() isFavorite: boolean;
  @Input() bupSHCs: SpecialHandlingCode[] = [];
  @Input() nstSHC: SpecialHandlingCode;
  @Input() parentCommodities: ICommodity[] = [];
  @Input() airlines: AirlineBrand[];
  @Input() aircrafts: Aircraft[] = [];
  @Input() index: number;
  @Input() noUpdateOnBlur: boolean;
  @Input() emailAdded: Observable<EmailContent>;
  @Input() awbChanged: Observable<AwbContent>;
  @Input() flightSelected: Observable<SelectedFlightContent>;
  @Input() regionAirports: AirportLight[] = [];
  @Input() popup: boolean;
  @Input() disableUnitSelector: boolean;
  @Input() isAllotmentModification: boolean;
  @Input() showSpinner: boolean;
  @Input() cardType: AdvancedBookingCardTypeEnum;
  @Output() removeItem = new EventEmitter();
  @Output() checkAddItem = new EventEmitter();
  @Output() addToRequests = new EventEmitter();
  @Output() addToResults = new EventEmitter();
  @Output() detectChange = new EventEmitter();
  @Output() addItem = new EventEmitter<AdvancedBookingDetails>();
  @Output() favoriteUpdated = new EventEmitter();
  @Output() revertShouldSubmitFlag = new EventEmitter();
  @Output() requestInProgressUpdated = new EventEmitter();
  @Output() autoFillItem = new EventEmitter();
  @Output() autoFillSuggestions = new EventEmitter();
  disableAutoFill = signal(false);

  collapsed = false;
  isInitialized = false;
  loadTypeUnit: MeasurementSystemEnum = MeasurementSystemEnum.METRIC;
  dimensionTypeUnit: MeasurementSystemEnum = MeasurementSystemEnum.METRIC;
  bupTypeUnit: MeasurementSystemEnum = MeasurementSystemEnum.METRIC;
  isLoading = false;
  isBatchLoading = false;
  isRequested = false;
  isRequestInProgress: boolean;
  isModified = false;
  isFailed = false;
  showCancel = false;
  searchUUID: string;
  numberOfReturns = Infinity;
  checkGetFlightBatches: NodeJS.Timeout;
  batches = new Set();
  airlineOptions: DropdownOption[] = [];
  flightNumberOptions: DropdownOption[] = [];
  recentAirlineOptions: DropdownOption[] = [];
  recentAllotmentOptions: DropdownOption[] = [];
  recentOriginOptions: DropdownOption[] = [];
  recentDestinationOptions: DropdownOption[] = [];
  recentAllotmentOption: DropdownOption[] = [];
  regionAirportsOptions: DropdownOption[] = [];
  fieldComponents: any[] = [];
  searchResults: FlightInput[] = [];
  airlineContacts: User[] = [];
  bups: BUP[] = [];
  dimensions: Dimension[] = [];
  commodities: ICommodity[] = [];
  requirements: IRequirementSearchTemplate[] = [];
  flightDays = [];
  currentUser: User;
  goodsTypeLabel: string;
  addEmailEvent: Subscription;
  awbChangedEvent: Subscription;
  flightSelectedEvent: Subscription;
  defaultCommodityResult: ICommoditySearchResult;
  commodityWithParent: ICommodity;
  selectedAirline: AirlineBrand;
  commodityResult: ICommoditySearchResult;
  measurementSystem = MeasurementSystemEnum.METRIC;
  defaultMeasurementSystem = MeasurementSystemEnum.METRIC;
  loadTypeMode: LoadTypeEnum = LoadTypeEnum.TOTAL;
  flightSubscription$ = new Subscription();
  retryEndTime: Date;
  quote: Quote;
  isInitializingForms: boolean;
  modalRef: NgbModalRef;
  flightNumbers: string[];
  airlinesList: IAirlineUser[];
  errors: any[] = [];
  advancedBookingAlertTypeEnum = AdvancedBookingAlertTypeEnum;
  formGroup: FormGroup = new FormGroup({
    "airline": new FormControl(null, [FormValidators.required()]),
    "origin": new FormControl(null, [FormValidators.required()]),
    "destination": new FormControl(null, [FormValidators.required()]),
    "flightNo": new FormControl(null, [FlightNoValidators.format()]),
    "departureDate": new FormControl(null, [FormValidators.required()]),
    "goodsType": new FormControl(null, [FormValidators.required()]),
    "weight": new FormControl(null, [
      FormValidators.required(),
      FormValidators.minValue(0.1),
      FormValidators.lessThan(
        MeasurementUtil.convertWeight(
          1000000,
          MeasurementSystemEnum.METRIC,
          this.defaultMeasurementSystem,
        ),
      ),
    ]),
    "volume": new FormControl(null, [
      FormValidators.required(),
      FormValidators.minValue(0.0001),
      FormValidators.lessThan(
        MeasurementUtil.convertVolume(
          1000,
          MeasurementSystemEnum.METRIC,
          this.defaultMeasurementSystem,
        ),
      ),
    ]),
    "pieces": new FormControl(1, [
      FormValidators.required(),
      FormValidators.minValue(1),
      FormValidators.lessThan(1000),
    ]),
    "awb": new FormControl(null, [AwbValidators.valid(), AwbValidators.format()]),
    "allotment": new FormControl(null, [AllotmentValidators.format()]),
    "chargeableWeight": new FormControl(null, []),
  });
  prevFormValue: any;

  constructor (
    private readonly fileService: AppFileService,
    private readonly searchService: SearchService,
    private readonly sessionService: SessionService,
    private readonly commodityService: CommodityService,
    private readonly quoteCartService: QuotecartService,
    private readonly quoteListService: QuoteListService,
    private readonly quotesService: AppQuoteService,
    private readonly cdr: ChangeDetectorRef,
    private readonly flightService: FlightService,
    private readonly advancedBookingService: AdvancedBookingsService,
    private readonly advancedBookingsRequestService: AdvancedBookingsRequestService,
    private readonly userService: UserService,
    private readonly modalService: NgbModal,
    private readonly officeService: AppOfficeService,
  ) {}

  ngOnInit () {
    this.formGroup.valueChanges.subscribe((value) => {
      if (JSON.stringify(this.prevFormValue) !== JSON.stringify(value)) {
        this.advancedBookingsRequestService.copyAdvancedBooking(
          {} as AdvancedBookingDetails,
        );
      }
      this.prevFormValue = value;
    });

    this.formGroup.get("airline").valueChanges.subscribe((value) => {
      if (
        this.formGroup.value.airline !== value &&
        value &&
        !this.isInitializingForms &&
        (this.formGroup.get("airline").touched ||
          this.formGroup.get("airline").dirty)
      ) {
        this.selectedAirline = this.airlines?.find(
          (airline) => airline.airlineCompanyId.toString() === value,
        );
        this.formGroup.get("awb").setValue(this.selectedAirline?.awbPrefix);
        this.fetchAutoFill(true);
        this.getRecentSuggestions(false, true, true);
        this.initFlightNoSuggestions();
      }
    });
    this.formGroup.get("origin").valueChanges.subscribe((value) => {
      if (
        this.formGroup.value.origin !== value &&
        this.selectedAirline &&
        !this.isInitializingForms &&
        value &&
        (this.formGroup.get("origin").touched ||
          this.formGroup.get("origin").dirty)
      ) {
        this.getRecentSuggestions(false, true, true);
        this.initFlightNoSuggestions();
        if (!this.disableAutoFill()) {
          this.fetchAutoFill();
        }
      }
    });
    this.formGroup.get("destination").valueChanges.subscribe((value) => {
      if (
        this.formGroup.value.destination !== value &&
        this.selectedAirline &&
        !this.isInitializingForms &&
        value &&
        (this.formGroup.get("destination").touched ||
          this.formGroup.get("destination").dirty)
      ) {
        this.initFlightNoSuggestions();
        if (!this.disableAutoFill()) {
          this.fetchAutoFill();
        }
      }
    });
    this.formGroup?.valueChanges.subscribe(() => {
      this.errors = this.fieldComponents
        ?.filter((field) => field && field.showError)
        .map((field) => ({
            "message": field.errorMessage,
            "boundingClientRect": field.boundingClientRect,
          }));
      this.cdr.detectChanges();
    });
  }

  async ngOnChanges (): Promise<void> {
    if (
      this.parentCommodities?.length &&
      this.airlines?.length &&
      this.regionAirports?.length &&
      this.advancedBookingDetails &&
      !this.isInitialized
    ) {
      await this.initData();
      this.isInitialized = true;
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy () {
    this.flightSubscription$.unsubscribe();
    if (this.checkGetFlightBatches) {
      clearInterval(this.checkGetFlightBatches);
    }
    if (this.addEmailEvent) {
      this.addEmailEvent.unsubscribe();
    }
    if (this.awbChangedEvent) {
      this.awbChangedEvent.unsubscribe();
    }
    if (this.flightSelectedEvent) {
      this.flightSelectedEvent.unsubscribe();
    }
  }

  async isAirlineContactsPresent (
    airlineCompanyCode?: string,
    airportCode?: string,
  ) {
    this.airlinesList = await this.userService.getAirlineByCompanyAndOrigin(
      airlineCompanyCode,
      airportCode,
      ContactTypeEnum.RESERVATION,
    );
    const availableContacts = this.airlinesList?.map((airlineData) => ({
        "value": airlineData.userId,
        "label": airlineData.userName,
        "description": airlineData.userEmail,
      }));
    if (availableContacts?.length) {
      return true;
    } else {
      return false;
    }
  }

  async initData (): Promise<void> {
    this.loadDefaultValues();
    this.initFieldOptions();
    this.initFormGroup();
    this.subscribeDepartDateChanges();
    if (this.advancedBookingDetails?.loadTypeUnitState) {
      this.loadTypeCmp.loadTypeUnitState =
        this.advancedBookingDetails.loadTypeUnitState;
      this.cdr.detectChanges();
    }
    if (this.shouldSubmit || this.advancedBookingDetails?.requested) {
      this.isRequested = !!!this.advancedBookingDetails.shipmentId;
      if (!this.advancedBookingDetails?.requested && this.shouldSubmit) {
        this.revertShouldSubmitFlag.emit();
        this.addToRequests.emit(this.advancedBookingDetails);
      }
    } else {
      this.formGroup.valueChanges.subscribe(() => {
        this.checkAddItem.emit();
      });
    }
    if (
      this.advancedBookingDetails.possibleActions?.includes(
        QuoteActionEnum.CANCEL_BOOKING,
      )
    ) {
      this.showCancel = true;
    }
    if (
      this.advancedBookingDetails.shipmentStatus ===
      QuoteStatusEnum.REQUEST_FAILED
    ) {
      this.isFailed = true;
    }
    this.cdr.detectChanges();
  }

  ngAfterViewInit () {
    this.fieldComponents = this.searchFields
      ?.toArray()
      .concat(this.loadTypeCmp?.qtyFields?.toArray())
      .concat(this.bookingFields.toArray());

    this.cdr.detectChanges();
  }

  loadDefaultValues (): void {
    this.currentUser = this.sessionService.getCurrentUser();
    this.measurementSystem = this.currentUser.measurementSystem;
    this.defaultMeasurementSystem = this.currentUser.measurementSystem;
    this.measurementSystem =
      this.advancedBookingDetails.measurementSystem || this.measurementSystem;
    this.loadTypeMode =
      this.advancedBookingDetails.loadTypeMode || LoadTypeEnum.TOTAL;
    this.commodityResult =
      this.advancedBookingDetails.commodityResult ||
      this.getDefaultCommodityResult();
    this.bups = this.advancedBookingDetails.bups;
    this.dimensions = this.advancedBookingDetails.dimensions;
    this.commodities = this.advancedBookingDetails.commodities;
    this.requirements = this.advancedBookingDetails.requirements;
    this.commodityWithParent =
      this.advancedBookingDetails.commodityWithParent ||
      this.commodityResult.object;
  }

  initFormGroup (): void {
    this.isInitializingForms = true;
    this.cdr.detectChanges();
    const airlineDetails = this.airlines.find(
      (item) => item.airlineCompanyCode === this.advancedBookingDetails.airline,
    ),
     originDetails = this.regionAirports.find(
      (item) => item.airportCode === this.advancedBookingDetails.origin,
    ),
     destinationDetails = this.regionAirports.find(
      (item) => item.airportCode === this.advancedBookingDetails.destination,
    ),
     defaultDepartureDate = moment(new Date())
      .add(this.currentUser?.defaultSearchDay, "days")
      .toDate(),
     airline = airlineDetails?.airlineCompanyId.toString(),
     origin = originDetails?.airportCode.toString(),
     destination = destinationDetails?.airportCode.toString();
    this.formGroup?.get("airline").setValue(airline);
    this.formGroup?.get("origin").setValue(origin);
    this.formGroup?.get("destination").setValue(destination);
    this.formGroup
      ?.get("flightNo")
      .setValue(this.advancedBookingDetails.flightNo);
    this.formGroup
      ?.get("departureDate")
      .setValue(
        this.advancedBookingDetails.departureDate || defaultDepartureDate,
      );
    this.formGroup?.get("goodsType").setValue(this.goodsType);
    this.formGroup?.get("weight").setValue(this.advancedBookingDetails.weight);
    this.formGroup?.get("volume").setValue(this.advancedBookingDetails.volume);
    this.formGroup?.get("pieces").setValue(this.advancedBookingDetails.pieces);
    this.formGroup?.get("awb").setValue(this.advancedBookingDetails.awb);
    this.formGroup
      ?.get("allotment")
      .setValue(this.advancedBookingDetails.allotmentCode);
    this.flightDays = [...Array(7).keys()].map((i) =>
      moment(this.formGroup?.get("departureDate").value).add(i, "d").format(),
    );
    this.isInitializingForms = false;
    this.prevFormValue = this.formGroup.value;
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
    this.cdr.detectChanges();
  }

  getOriginDetails (origin?: string): AirportLight {
    return this.regionAirports.find((item) => {
      if (
        origin
          ? item.airportCode.toString() === origin
          : item.airportCode === this.advancedBookingDetails.origin
      ) {
        return item;
      }
      return false;
    });
  }

  getDestinationDetails (destination?: string): AirportLight {
    return this.regionAirports.find((item) => {
      if (
        destination
          ? item.airportCode.toString() === destination
          : item.airportCode === this.advancedBookingDetails.destination
      ) {
        return item;
      }
      return false;
    });
  }

  getAirlineDetails (airline?: string): AirlineBrand {
    return this.airlines.find((item) => {
      if (
        airline
          ? item.airlineCompanyId.toString() === airline
          : item.airlineCompanyCode === this.advancedBookingDetails.airline
      ) {
        return item;
      }
      return false;
    });
  }

  async initFlightNoSuggestions (init = false): Promise<void> {
    try {
      const origin = this.formGroup.get("origin").value,
       destination = this.formGroup.get("destination").value,
       airline = this.formGroup.get("airline").value,
       deptDate = this.formGroup.get("departureDate").value;

      if ((!origin || !destination || !airline || !deptDate) && !init) {
        this.flightNumberOptions = [];
        return;
      }
      const originDetails = this.getOriginDetails(origin),
       destinationDetails = this.getDestinationDetails(destination),
       airlineDetails = this.getAirlineDetails(airline),

       defaultDepartureDate = moment(new Date()).add(3, "days").toDate();

      if (
        originDetails?.airportCode &&
        destinationDetails?.airportCode &&
        airlineDetails?.airlineCompanyCode
      ) {
        this.flightNumbers = await this.flightService.getFlightNo(
          originDetails?.airportCode,
          destinationDetails?.airportCode,
          airlineDetails?.airlineCompanyCode,
          moment(
            deptDate ||
              this.advancedBookingDetails.departureDate ||
              defaultDepartureDate,
          ).format(DATE_FORMAT_WITH_TIMEZONE),
        );
        this.flightNumberOptions = this.flightNumbers
          ?.map((flightNumber) => ({
              "value": flightNumber,
              "display": flightNumber,
              "label": flightNumber,
              "criteria": [
                {
                  "value": flightNumber,
                  "textCase": "uppercase",
                },
                {
                  "value": flightNumber,
                  "textCase": "lowercase",
                },
              ],
            } as DropdownOption))
          .sort((a, b) => a?.label?.localeCompare(b.label));
      }
      this.cdr.detectChanges();
    } catch (err) {
      console.error(err);
    }
  }

  async fetchAutoFill (isAirlineAutoFill?: boolean) {
    try {
      let originDetails,
       destinationDetails;

      originDetails = this.regionAirports.find(
        (item) =>
          item.airportCode.toString() === this.formGroup?.get("origin").value,
      );

      destinationDetails = this.regionAirports.find(
        (item) =>
          item.airportCode.toString() ===
          this.formGroup?.get("destination").value,
      );

      if (this.selectedAirline?.airlineCompanyCode) {
        const result = await this.advancedBookingService.autoFill(
          this.selectedAirline?.airlineCompanyCode,
          originDetails?.airportCode,
          destinationDetails?.airportCode,
        );
        if (!result?.flightNumber && isAirlineAutoFill) {
          this.disableAutoFill.set(true);
          return;
        }
        this.disableAutoFill.set(false);
        if (!result?.airlineBrand?.awbPrefix && result?.airlineBrand) {
          result.airlineBrand.awbPrefix = this.airlines?.find(
            (airline) =>
              airline?.airlineCompanyId?.toString() ===
              result?.airlineBrand?.airlineCompanyId?.toString(),
          )?.awbPrefix;
        }
        if (result?.flightNumber) {
          this.currentUser = this.sessionService.getCurrentUser();
          const measurementSystem = this.currentUser.measurementSystem;
          this.advancedBookingDetails = AdvancedBookingUtil.processData(
            [result],
            false,
            [],
            measurementSystem,
          )[0];
          if (this.isSuggestion || this.isFavorite) {
            this.autoFillSuggestions.emit({
              "index": this.index,
              "value": this.advancedBookingDetails,
            });
          } else {
            this.autoFillItem.emit({
              "index": this.index,
              "value": this.advancedBookingDetails,
            });
          }
        }
      }
      this.cdr.detectChanges();
    } catch (err) {
      this.disableAutoFill.set(true);
      console.log("ERROR:", err);
    }
  }

  subscribeDepartDateChanges () {
    this.formGroup.get("departureDate").valueChanges.subscribe((value) => {
      const deptDate = this.formGroup.value.departureDate;

      if (
        moment(deptDate).format("YYYY-MM-DD") !==
        moment(value).format("YYYY-MM-DD")
      ) {
        this.initFlightNoSuggestions();
        this.flightDays = [...Array(7).keys()].map((i) =>
          moment(this.formGroup?.get("departureDate").value)
            .add(i, "d")
            .format(),
        );
      }
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize () {
    this.initFieldOptions();
  }

  initFieldOptions () {
    const isExpandAirlineLabel = window.innerWidth >= 2560 && !this.popup;
    this.airlineOptions = this?.airlines
      ?.filter((airline) => airline.airlineCompanyCode !== "UNKN")
      .map((airline) => ({
          "value": airline.airlineCompanyId.toString(),
          "display": isExpandAirlineLabel
            ? `${airline.airlineCompanyCode.toUpperCase()} - ${
                airline.airlineCompanyName
              }`
            : airline.airlineCompanyCode.toUpperCase(),
          "label": `${airline.airlineCompanyCode.toUpperCase()} - ${
            airline.airlineCompanyName
          }`,
          "criteria": [
            {
              "value": airline.airlineCompanyCode,
              "textCase": "uppercase",
            },
            {
              "value": airline.airlineCompanyName,
              "textCase": "lowercase",
            },
          ],
        } as DropdownOption))
      .sort((a, b) => a?.label?.localeCompare(b.label));

    const isBiggerScreen = window.innerWidth >= 1825 && !this.popup;
    this.regionAirportsOptions = this.regionAirports
      .map((airport) => ({
          "value": airport?.airportCode.toString(),
          "display": isBiggerScreen
            ? `${airport?.airportCode} - ${airport.cityName} ${airport.airportName}`
            : airport?.airportCode.toUpperCase(),
          "label": `${airport?.airportCode} - ${airport.cityName} ${airport.airportName}`,
          "criteria": [
            {
              "value": airport.airportCode,
              "textCase": "uppercase",
            },
            {
              "value": airport?.cityCode,
              "textCase": "uppercase",
            },
            {
              "value": airport?.cityName,
              "textCase": "lowercase",
            },
          ],
        } as DropdownOption))
      .sort((a, b) => a?.label?.localeCompare(b.label));
    this.cdr.detectChanges();
  }

  get airlineCenterText (): boolean {
    return window.innerWidth < 2560;
  }

  get centerText (): boolean {
    return window.innerWidth < 1825;
  }

  getDefaultCommodityResult (): ICommoditySearchResult {
    const defaultGoodsType = this.parentCommodities.find(
      (item) =>
        item.name ===
        (this.advancedBookingDetails?.commodities?.[0]?.name ||
          DEFAULT_GOODS_TYPE),
    );
    return {
      "name": defaultGoodsType?.name,
      "type": CommodityResultTypeEnum.COMMODITY,
      "object": defaultGoodsType,
    };
  }

  handleOriginFocus () {
    if (!this.recentOriginOptions.length) {
      this.getRecentSuggestions(false, true);
    }
  }

  handleDestinationFocus () {
    if (!this.recentDestinationOptions.length) {
      this.getRecentSuggestions(false, false, true);
    }
  }

  handleAirlineFocus () {
    if (!this.recentAirlineOptions.length) {
      this.getRecentSuggestions(true);
    }
  }

  handleAllotmentFocus () {
    if (!this.recentAllotmentOptions.length) {
      this.getRecentSuggestions(false, false, false, true);
    }
  }

  async getRecentSuggestions (
    isAirline = false,
    isOrigin = false,
    isDestination = false,
    isAllotment = false,
  ) {
    const origin = this.formGroup.get("origin").value,
     airline = this.formGroup.get("airline").value,

     originDetails = this.getOriginDetails(origin),
     airlineDetails = this.getAirlineDetails(airline);

    let recentAirlineOptions,
     recentOriginOptions,
     recentDestinationOptions,
     recentAllotmentOptions;

    if (isAirline) {
      recentAirlineOptions =
        await this.advancedBookingService.getRecentSuggestion("airlines");
    }
    if (isAllotment) {
      recentAllotmentOptions =
        await this.advancedBookingService.getRecentSuggestion("allotments");
    }
    if ((!isDestination && airlineDetails?.airlineCompanyCode) || isOrigin) {
      recentOriginOptions =
        await this.advancedBookingService.getRecentSuggestion(
          "origin",
          airlineDetails?.airlineCompanyCode,
        );
    }
    if ((!isOrigin && airlineDetails?.airlineCompanyCode) || isDestination) {
      recentDestinationOptions =
        await this.advancedBookingService.getRecentSuggestion(
          "destination",
          airlineDetails?.airlineCompanyCode,
          originDetails?.airportCode,
        );
    }
    if ((!isOrigin && airlineDetails?.airlineCompanyCode) || isDestination) {
      const isBiggerScreen = window.innerWidth >= 1825 && !this.popup;
      this.recentDestinationOptions = recentDestinationOptions?.recentInputs
        .map((airport) => ({
            "criteria": [
              {
                "value": airport.airportCode,
                "textCase": "uppercase",
              },
              {
                "value": airport?.city?.cityCode,
                "textCase": "uppercase",
              },
              {
                "value": airport?.city?.cityName,
                "textCase": "lowercase",
              },
            ],

            "display": isBiggerScreen
              ? `${airport?.airportCode} - ${airport?.city?.cityName} ${airport?.airportName}`
              : airport?.airportCode?.toUpperCase(),
            "label": `${airport?.airportCode} - ${airport?.city?.cityName} ${airport?.airportName}`,
            "value": airport?.airportCode?.toString(),
          } as DropdownOption))
        .sort((a, b) => a?.label?.localeCompare(b.label));
    }
    if ((!isDestination && airlineDetails?.airlineCompanyCode) || isOrigin) {
      const isBiggerScreen = window.innerWidth >= 1825 && !this.popup;
      this.recentOriginOptions = recentOriginOptions?.recentInputs
        .map((airport) => ({
            "value": airport?.airportCode?.toString(),
            "label": `${airport?.airportCode} - ${airport?.city?.cityName} ${airport?.airportName}`,
            "display": isBiggerScreen
              ? `${airport?.airportCode} - ${airport?.city?.cityName} ${airport?.airportName}`
              : airport?.airportCode?.toUpperCase(),
            "criteria": [
              {
                "value": airport.airportCode,
                "textCase": "uppercase",
              },
              {
                "value": airport?.city?.cityName,
                "textCase": "lowercase",
              },
              {
                "value": airport?.city?.cityCode,
                "textCase": "uppercase",
              },
            ],
          } as DropdownOption))
        .sort((a, b) => a?.label?.localeCompare(b.label));
    }

    if (recentAllotmentOptions?.recentInputs?.length) {
      this.recentAllotmentOptions = recentAllotmentOptions?.recentInputs
        .map((allotment) => ({
            "value": allotment,
            "label": allotment,
            "display": allotment,
            "criteria": [
              {
                "value": allotment,
                "textCase": "uppercase",
              },
            ],
          } as DropdownOption))
        .sort((a, b) => a?.localeCompare(b));
    }

    if (recentAirlineOptions?.recentInputs?.length) {
      const isExpandAirlineLabel = window.innerWidth >= 2560 && !this.popup,
       isItaCargoGate = window.location.href?.includes("ita");
      this.recentAirlineOptions = recentAirlineOptions?.recentInputs
        .filter(
          (airlineValue) =>
            airlineValue.airlineCompanyCode !== "UNKN" &&
            (isItaCargoGate ? airlineValue.airlineCompanyCode === "AZ" : true),
        )
        .map((airlineValue) => ({
            "value": airlineValue.airlineCompanyId.toString(),
            "display": isExpandAirlineLabel
              ? `${airlineValue.airlineCompanyCode.toUpperCase()} - ${
                  airlineValue.airlineCompanyName
                }`
              : airlineValue.airlineCompanyCode.toUpperCase(),
            "label": `${airlineValue.airlineCompanyCode.toUpperCase()} - ${
              airlineValue.airlineCompanyName
            }`,
            "criteria": [
              {
                "value": airlineValue.airlineCompanyCode,
                "textCase": "uppercase",
              },
              {
                "value": airlineValue.airlineCompanyName,
                "textCase": "lowercase",
              },
            ],
          } as DropdownOption))
        .sort((a, b) => a?.label?.localeCompare(b.label));
    }
    this.cdr.detectChanges();
  }

  toggleCollapse (value: boolean) {
    this.collapsed = value;
    if (!value && !this.flightNumbers?.length) {
      this.initFlightNoSuggestions(true);
    }
  }

  changeBUP ($event) {
    if (JSON.stringify($event) !== JSON.stringify(this.bups)) {
      this.advancedBookingsRequestService.copyAdvancedBooking(
        {} as AdvancedBookingDetails,
      );
    }
    this.bups = $event;
    this.loadTypeCmp.bups = $event;
    this.cdr.detectChanges();
  }

  changeDimensions ($event) {
    if (JSON.stringify($event) !== JSON.stringify(this.dimensions)) {
      this.advancedBookingsRequestService.copyAdvancedBooking(
        {} as AdvancedBookingDetails,
      );
    }
    this.dimensions = $event;
    this.loadTypeCmp.dimensions = $event;
    this.cdr.detectChanges();
  }

  async toggleFavorite (removeFavorite?: boolean) {
    this.advancedBookingDetails.isFavorite =
      !this.advancedBookingDetails.isFavorite;
    if (
      this.advancedBookingDetails?.favoriteId &&
      !this.advancedBookingDetails.ApiRawData.favorite
    ) {
      await this.advancedBookingService.setFavoriteById(
        this.advancedBookingDetails.favoriteId,
        this.advancedBookingDetails.isFavorite,
      );
    } else {
      this.advancedBookingDetails.ApiRawData.favorite =
        this.advancedBookingDetails.isFavorite;
      if (removeFavorite && !this.advancedBookingDetails?.favoriteId) {
        this.prepareAdvancedBookingDetails();
        const favorite = this.favorites.find((favoriteData) => {
          const clonedFavoriteData = JSON.parse(JSON.stringify(favoriteData));
          clonedFavoriteData.advancedBookingItemTemplates.forEach(
            (advancedBookingItemTemplate) => {
              advancedBookingItemTemplate.id = null;
            },
          );

          if (
            favoriteData.flightNumber ===
              this.advancedBookingDetails.flightNo &&
            favoriteData.airlineBrand?.airlineCompanyCode ===
              this.advancedBookingDetails.airline &&
            this.advancedBookingDetails.destination ===
              favoriteData?.destinationAirport?.airportCode &&
            this.advancedBookingDetails.origin ===
              favoriteData?.originAirport?.airportCode &&
            moment(favoriteData?.departureDate).format("YYYY-MM-DD") ===
              moment(this.advancedBookingDetails.departureDate).format(
                "YYYY-MM-DD",
              ) &&
            this.advancedBookingDetails?.pieces ===
              favoriteData?.advancedBookingItemTemplates?.reduce(
                (acc, item) => acc + item?.numOfItems,
                0,
              ) &&
            +this.advancedBookingDetails?.volume.toFixed(1) ===
              +favoriteData?.advancedBookingItemTemplates
                ?.reduce((acc, item) => acc + item?.volume, 0)
                .toFixed(1) &&
            this.advancedBookingDetails?.weight ===
              favoriteData?.advancedBookingItemTemplates?.reduce(
                (acc, item) => acc + item?.weight,
                0,
              )
          ) {
            favoriteData.favorite = false;
            return favoriteData;
          }
          return false;
        });
        if (favorite?.id) {
          await this.advancedBookingService.setFavoriteById(
            favorite.id,
            favorite.favorite,
          );
          this.favoriteUpdated.emit();
        }
        return;
      }
      if (this.advancedBookingDetails?.favoriteId) {
        await this.advancedBookingService.setFavoriteById(
          this.advancedBookingDetails.ApiRawData.id,
          this.advancedBookingDetails.ApiRawData.favorite,
        );
      } else {
        try {
          this.prepareAdvancedBookingDetails();
          await this.advancedBookingService.setFavorite(
            AdvancedBookingUtil.updateApiRawData(
              this.advancedBookingDetails,
              this.regionAirports,
              this.airlines,
              this.loadTypeCmp?.loadTypeUnitState?.[this.loadTypeMode]
                ?.weightUnitDetail?.code === "kg"
                ? MeasurementSystemEnum.METRIC
                : MeasurementSystemEnum.IMPERIAL,
              this.loadTypeCmp?.loadTypeUnitState?.[this.loadTypeMode]
                ?.dimensionUnitDetail?.code === "cm"
                ? MeasurementSystemEnum.METRIC
                : MeasurementSystemEnum.IMPERIAL,
              this.measurementSystem,
            ),
          );
        } catch (err) {
          this.advancedBookingDetails.isFavorite =
            !this.advancedBookingDetails.isFavorite;
          this.advancedBookingDetails.ApiRawData.favorite =
            this.advancedBookingDetails.isFavorite;
        }
      }
    }
    this.cdr.detectChanges();
    this.favoriteUpdated.emit();
  }

  selectAction (action: AdvancedBookingActionEnum) {
    switch (action) {
      case AdvancedBookingActionEnum.DELETE:
      case AdvancedBookingActionEnum.HIDE_CARD: {
        this.removeItem.emit();
        break;
      }
      case AdvancedBookingActionEnum.SAVE_AS_FAVORITE: {
        this.toggleFavorite();
        break;
      }
      case AdvancedBookingActionEnum.REMOVE_FROM_FAVORITE: {
        this.toggleFavorite(true);
        break;
      }
      case AdvancedBookingActionEnum.COPIED: {
        this.advancedBookingsRequestService.copyAdvancedBooking(
          {} as AdvancedBookingDetails,
        );
        break;
      }
      case AdvancedBookingActionEnum.COPY: {
        this.prepareAdvancedBookingDetails();
        this.advancedBookingDetails.index = this.index;
        if (this.isSuggestion) {
          this.advancedBookingDetails.cardType =
            AdvancedBookingCardTypeEnum.SUGGESTION;
        } else if (this.isFavorite) {
          this.advancedBookingDetails.cardType =
            AdvancedBookingCardTypeEnum.FAVORITE;
        } else {
          this.advancedBookingDetails.cardType =
            AdvancedBookingCardTypeEnum.ADVANCED_BOOKING;
        }
        this.advancedBookingsRequestService.copyAdvancedBooking(
          this.advancedBookingDetails,
        );
        const copyBody = this.prepareTemplateDetails(
          copyAdvancedBookingDetailTemplate,
          dimensionsTemplate,
          bupsTemplate,
        );
        this.copyToClipboard(copyBody);
        break;
      }
    }
  }

  copyToClipboard (templateDetails) {
    let clipboardData = bookingDetailsTemplate;
    Object.keys(templateDetails).forEach((key) => {
      clipboardData = clipboardData.replace(`{${key}}`, templateDetails[key]);
    });
    const container = document.createElement("div");
    container.style.background = "white";
    container.style.fontFamily = "Arial";
    container.style.fontSize = "18px";
    container.innerHTML = clipboardData;
    container.style.left = "0";
    container.style.top = "0";
    container.style.opacity = "0";
    container.style.color = "black";
    container.style.pointerEvents = "none";
    container.style.position = "fixed";
    document.body.appendChild(container);
    window.getSelection().removeAllRanges();
    const range = document.createRange();
    range.selectNode(container);
    window.getSelection().addRange(range);
    document.execCommand("copy");
    document?.body?.removeChild(container);
  }

  prepareTemplateDetails (
    advancedBookingTemplate: string,
    dimensionsTemplateString: string,
    bupsTemplateString: string,
  ) {
    const originAirport = this.regionAirports?.find((regionAirport) => {
      if (this.formGroup.get("origin").value === regionAirport.airportCode) {
        return regionAirport;
      }
      return null;
    }),

     destinationAirport = this.regionAirports?.find((regionAirport) => {
      if (
        this.formGroup.get("destination").value === regionAirport.airportCode
      ) {
        return regionAirport;
      }
      return null;
    }),
     advancedBookingDetails = {
      "airline": `${this.selectedAirline?.airlineCompanyCode}-${this.selectedAirline?.airlineCompanyName}`,
      "airlineName": this.selectedAirline?.airlineCompanyName,
      "origin": originAirport
        ? `${originAirport?.airportCode || ""}-${
            originAirport?.airportName || ""
          }`
        : "",
      "destination": destinationAirport
        ? `${destinationAirport?.airportCode || ""}-${
            destinationAirport?.airportName || ""
          }`
        : "",
      "flightNo": this.formGroup.get("flightNo").value,
      "productType":
        this.formGroup.get("goodsType").value?.name ||
        this.parentCommodities.find(
          (commodity) =>
            commodity.id === +this.formGroup.get("goodsType").value,
        )?.name,
      "weight": this.formGroup.get("weight").value,
      "weightUnit":
        this.loadTypeCmp?.loadTypeUnitState?.[this.loadTypeMode]
          ?.weightUnitCode,
      "volume": this.formGroup.get("volume").value,
      "pieces": this.formGroup.get("pieces").value,
      "awb": this.formGroup.get("awb").value,
      "allotment": this.formGroup.get("allotment").value,
    },
     dimensions = this.dimensions.map((dimension) => ({
        "pieces": dimension.numOfItems,
        "width": dimension.dimensionWidth,
        "height": dimension.dimensionHeight,
        "length": dimension.dimensionLength,
        "weight": dimension.weight,
        "weightUnit":
          this.loadTypeCmp?.loadTypeUnitState[this.loadTypeMode].weightUnitCode,
        "dimensionUnit":
          this.loadTypeCmp?.loadTypeUnitState[this.loadTypeMode]
            .dimensionUnitCode,
        "packingType": dimension?.packing?.toLowerCase(),
      })),
     bups = this.bups.map((bup) => ({
        "uldType": bup.uldType,
        "quantity": bup.quantity,
        "weight": bup.weight,
        "weightUnit":
          this.loadTypeCmp?.loadTypeUnitState[this.loadTypeMode].weightUnitCode,
        "dimensionUnit":
          this.loadTypeCmp?.loadTypeUnitState[this.loadTypeMode]
            .dimensionUnitCode,
        "volume": bup?.volume,
        "volumeUnit": bup.volumeUnit,
      }));

    let advancedBookingText = advancedBookingTemplate;
    Object.keys(advancedBookingDetails).forEach((key) => {
      const value = advancedBookingDetails[key];
      advancedBookingText = advancedBookingText.replace(
        `{${key}}`,
        value || "",
      );
    });

    const dimensionFullText = [];
    if (this.advancedBookingDetails.dimensions?.[0]?.dimensionHeight) {
      dimensions.forEach((dimension) => {
        if (dimension?.weight) {
          let dimensionText = dimensionsTemplateString;
          Object.keys(dimension).forEach((key) => {
            const value = dimension[key];
            dimensionText = dimensionText.replace(`{${key}}`, value || "");
          });
          dimensionFullText.push(dimensionText);
        }
      });
    }

    const bupFullText = [];
    if (this.advancedBookingDetails.bups?.[0]?.uldType) {
      bups.forEach((bup) => {
        if (bup?.uldType) {
          let bupText = bupsTemplateString;
          Object.keys(bup).forEach((key) => {
            const value = bup[key];
            bupText = bupText.replace(`{${key}}`, value || "");
          });
          bupFullText.push(bupText);
        }
      });
    }
    return {
      advancedBookingText,
      "dimensionText":
        this.advancedBookingDetails.loadTypeMode === LoadTypeEnum.DIMENSIONS
          ? dimensionFullText.join("")
          : "",
      "bupText":
        this.advancedBookingDetails.loadTypeMode === LoadTypeEnum.BUP
          ? bupFullText.join("")
          : "",
    };
  }

  requestBooking (event?: Event) {
    event?.stopPropagation();
    event?.preventDefault();
    if (this.isLoading) {
      return;
    }
    this.prepareAdvancedBookingDetails();
    if (this.isSuggestion || this.isFavorite) {
      this.showSpinner = true;
      setTimeout(() => {
        this.showSpinner = false;
        this.cdr.detectChanges();
      }, 2000);
      this.addItem.emit({
        ...this.advancedBookingDetails,
        "isFromSuggestion": true,
      });
    } else {
      this.addToRequests.emit(this.advancedBookingDetails);
    }
  }

  prepareAdvancedBookingDetails () {
    const airline = this.airlines.find(
      (airport) =>
        airport?.airlineCompanyId.toString() ===
        this.formGroup.get("airline").value,
    ),
     origin = this.regionAirports.find(
      (airport) =>
        airport?.airportCode.toString() === this.formGroup.get("origin").value,
    ),
     destination = this.regionAirports.find(
      (airport) =>
        airport?.airportCode.toString() ===
        this.formGroup.get("destination").value,
    );
    this.advancedBookingDetails = {
      ...this.advancedBookingDetails,
      "airline": airline?.airlineCompanyCode,
      "origin": origin?.airportCode,
      "destination": destination?.airportCode,
      "goodsType": this.goodsType?.name,
      "flightNo": this.formGroup.get("flightNo").value,
      "allotmentCode": this.formGroup?.get("allotment").value,
      "awb": this.formGroup?.get("awb").value,
      "weight": this.formGroup?.get("weight").value,
      "chargeableWeight": this.formGroup?.get("chargeableWeight").value,
      "volume": this.formGroup?.get("volume").value,
      "pieces": this.formGroup?.get("pieces").value,
      "departureDate": this.formGroup?.get("departureDate").value,
      "bups": cloneDeep(this.bups),
      "dimensions": cloneDeep(this.dimensions),
      "commodities": cloneDeep(this.prepareCommodities()),
      "requirements": cloneDeep(this.prepareRequirements()),
      "commodityWithParent": cloneDeep(this.commodityWithParent),
      "commodityResult": cloneDeep(this.commodityResult),
      "loadTypeMode": this.loadTypeMode,
      "measurementSystem": this.measurementSystem,
      "loadTypeUnit": this.loadTypeUnit,
      "dimensionTypeUnit": this.dimensionTypeUnit,
      "bupTypeUnit": this.bupTypeUnit,
      "loadTypeUnitState": cloneDeep(this.loadTypeCmp.loadTypeUnitState),
      "quote": {
        ...this.advancedBookingDetails.quote,
        "origin": QuoteOrigin.ADVANCED_BOOKING,
      },
    };
  }

  getFlights (searchUUID: string) {
    this.isBatchLoading = true;
    this.isRequested = true;
    this.searchUUID = searchUUID;
    this.numberOfReturns = Infinity;
    this.batches = new Set();
    this.searchResults = [];
    this.retryEndTime = undefined;
    this.cdr.detectChanges();

    this.flightSubscription$ = this.searchService
      .subscribeFlight(searchUUID)
      .subscribe({
        "next": async (res) => {
          if (res.value.errors) {
            return;
          }
          if (this.shouldProcessBatch(res.value.data.FlightUpdated)) {
            this.processBatch(res.value.data.FlightUpdated);
          }
        },
        "error": (err) => {
          console.error(err);
        },
      });

    this.getFlightBatches(searchUUID);
    this.retryGetFlightBatches(searchUUID, 10);
    this.cdr.detectChanges();
  }

  retryGetFlightBatches (searchUUID: string, seconds: number) {
    if (this.retryEndTime == undefined) {
      this.retryEndTime = new Date(
        new Date().setMinutes(new Date().getMinutes() + 2),
      );
    }
    clearInterval(this.checkGetFlightBatches);
    if (this.retryEndTime > new Date()) {
      this.checkGetFlightBatches = setInterval(() => {
        if (this.isBatchLoading) {
          this.getFlightBatches(searchUUID);
        }
      }, seconds * 1000);
    } else {
      this.isBatchLoading = false;
      this.cdr.detectChanges();
      this.handleFinalResults();
    }
    this.cdr.detectChanges();
  }

  getFlightBatches (searchUUID: string) {
    this.searchService
      .getFlights(searchUUID)
      .then(async (res) => {
        this.retryGetFlightBatches(searchUUID, 20);
        res.data.getFlights.forEach((batch) => {
          if (this.shouldProcessBatch(batch)) {
            this.processBatch(batch);
          }
        });
      })
      .catch(async (err) => {
        if (err.data && err.data.getFlights) {
          console.error("getFlight Error Response", err);
          err.data.getFlights.forEach((batch) => {
            if (this.shouldProcessBatch(batch)) {
              this.processBatch(batch);
            }
          });
        } else {
          console.error(err);
        }
      });
    this.cdr.detectChanges();
  }

  shouldProcessBatch (newResult: IFlightSearchDetail): boolean {
    if (!this.isBatchLoading) {
      return false;
    }
    if (!newResult.flights.length) {
      this.numberOfReturns = newResult.batchTotal - 1;
      this.cdr.detectChanges();
    } else {
      this.batches.add(newResult.batchTotal);
    }

    if (this.batches.size === this.numberOfReturns) {
      clearInterval(this.checkGetFlightBatches);
      this.isBatchLoading = false;
      this.isLoading = false;
      this.flightSubscription$.unsubscribe();
      this.cdr.detectChanges();
      console.info(
        `Received stop batch: ${this.batches.size}/${this.numberOfReturns}.`,
      );
    } else {
      console.info(
        `Received batch: ${this.batches.size}/${this.numberOfReturns}. ${newResult.sources}`,
      );
    }
    return true;
  }

  handleMeasurementUnitChange (unit: MeasurementSystemEnum) {
    this.loadTypeUnit = unit;
    this.cdr.detectChanges();
  }

  processBatch (newResult: IFlightSearchDetail) {
    let filteredFlights = [...this.searchResults];
    newResult.flights.forEach((newFlight: FlightInput, index: number) => {
      if (!newFlight.sortingFlightKey) {
        newFlight.sortingFlightKey = `${newResult.id}-${index}`;
      }

      const flightIndex = filteredFlights.findIndex(
        (f) => f.sortingFlightKey === newFlight.sortingFlightKey,
      );

      if (newFlight.loading !== true) {
        newFlight.loading = false;
      }

      if (flightIndex === -1) {
        if (newFlight.loading === true) {
          filteredFlights = [newFlight, ...filteredFlights];
        } else {
          filteredFlights.push(newFlight);
        }
      } else if (
        filteredFlights[flightIndex].loading === true &&
        !newFlight.loading === true
      ) {
        filteredFlights[flightIndex] = {
          ...newFlight,
          "checked": filteredFlights[flightIndex].checked,
        };
      }
    });

    this.searchResults = SearchResultUtil.computeFlights(
      filteredFlights,
      this.flightDays,
      this.airlines,
    );

    const matchedFlight = this.findFlight();
    if (
      this.findFlight() &&
      this.isBatchLoading &&
      (this.isRequestBooking(matchedFlight) ||
        this.isInstantBooking(matchedFlight))
    ) {
      clearInterval(this.checkGetFlightBatches);
      this.isBatchLoading = false;
      this.cdr.detectChanges();
    }

    if (this.isBatchLoading === false) {
      filteredFlights.forEach((flight: FlightInput, index: number) => {
        if (flight.loading === true) {
          filteredFlights[index].available = false;
        }
        if (flight.rates) {
          flight.rates.forEach((rate) => {
            rate.formattedName = SearchResultUtil.getRateName(rate);
          });
          flight.selectedRate = flight.rates[0];
        }
      });
    }
    this.searchResults = SearchResultUtil.computeFlights(
      filteredFlights,
      this.flightDays,
      this.airlines,
    );

    this.cdr.detectChanges();
    if (this.isBatchLoading === false) {
      this.handleFinalResults();
    }
  }

  handleFlightSearch (matchedFlight: FlightInput) {
    const filteredFlights = this.searchResults.filter((searchResult) => {
      if (
        this.isInstantBooking(searchResult) ||
        this.isRequestBooking(searchResult)
      ) {
        return searchResult;
      }
      return false;
    });

    this.addToResults.emit({
      "type": this.advancedBookingAlertTypeEnum.FLIGHTS,
      "payload": matchedFlight,
      "searchResults": filteredFlights,
    });
    this.flightSelectedEvent = this.flightSelected?.subscribe((result) => {
      if (result.index === this.index) {
        this.formGroup.get("flightNo").setValue(result.value.flightNumber);
        this.advancedBookingDetails.flightNo = result?.value?.flightNumber;
        this.advancedBookingDetails.departureDate = moment(
          result.value.departureTime,
        ).toISOString();
        this.formGroup
          .get("departureDate")
          .setValue(moment(result.value.departureTime).toISOString());
        if (
          this.advancedBookingDetails?.awb?.length < 11 &&
          !result.value?.features?.awbOptional
        ) {
          this.handleAwbAlert(result.value);
        } else {
          this.handleAirlineContactAlert(result.value);
        }
        this.flightSelectedEvent.unsubscribe();
      }
    });
  }

  handleAwbAlert (
    matchedFlight,
    flightInput?: FlightInput,
    isDuplicateAWB?: boolean,
  ) {
    this.addToResults.emit({
      "type": isDuplicateAWB
        ? this.advancedBookingAlertTypeEnum.DUPLICATE_AWB
        : this.advancedBookingAlertTypeEnum.AWB,
      "awbPrefix": matchedFlight?.airlineCompany?.awbPrefix,
    });
    this.awbChangedEvent = this.awbChanged?.subscribe((result) => {
      if (result.index === this.index) {
        this.advancedBookingDetails.awb = result.value;
        this.formGroup.get("awb").setValue(result.value);
        this.awbChangedEvent.unsubscribe();
        this.handleAirlineContactAlert(flightInput || matchedFlight);
      }
    });
  }

  async handleAirlineContactAlert (matchedFlight: FlightInput) {
    const isRequestBook = this.isRequestBooking(matchedFlight),
     isContactAvailable = await this.isAirlineContactsPresent(
      matchedFlight?.airlineCompany?.airlineCompanyCode,
      matchedFlight?.departureAirport,
    );
    if (!isContactAvailable && !this.airlineContacts?.length && isRequestBook) {
      this.addToResults.emit({
        "type": this.advancedBookingAlertTypeEnum.AIRLINE_CONTACT,
      });
      this.addEmailEvent = this.emailAdded?.subscribe((result) => {
        if (result.index === this.index) {
          this.airlineContacts.push(result.value);
          this.sendQuoteRequest(matchedFlight, true);
          this.addEmailEvent.unsubscribe();
        }
      });
    } else {
      this.sendQuoteRequest(matchedFlight, true);
    }
  }

  handleFinalResults (duplicateAwb = false, flightInput?: FlightInput) {
    const matchedFlight = this.findFlight();
    if (this.isRequestInProgress && !duplicateAwb) {
      return;
    }
    if (matchedFlight || duplicateAwb) {
      if (
        duplicateAwb ||
        (this.advancedBookingDetails?.awb?.length < 11 &&
          matchedFlight?.features &&
          !matchedFlight?.features?.awbOptional &&
          matchedFlight.features?.bookable &&
          matchedFlight.rates?.length &&
          matchedFlight.available)
      ) {
        this.requestInProgressUpdated.emit(false);
        this.handleAwbAlert(matchedFlight, flightInput, duplicateAwb);
        this.requestInProgressUpdated.emit(true);
        return;
      }
      if (this.isInstantBooking(matchedFlight)) {
        this.sendQuoteRequest(matchedFlight, false);
      } else if (this.isRequestBooking(matchedFlight)) {
        console.log(">> non-instant booking", matchedFlight);
        //CASE: flight found and available for non-instant booking
        this.handleAirlineContactAlert(matchedFlight);
        //show airline contact popup, add contacts to this.airlineContacts
        //call this.sendQuoteRequest(matchedFlight, true) after adding contact
      } else {
        console.log(">> not available", matchedFlight);
        this.handleFlightSearch(matchedFlight);
        //CASE: flight found but not available
        //emit addToResults to show popup
        //should show flight preview
        //if this.searchResults.length > 0, show results table
      }
    } else {
      console.log(">> flight not found", matchedFlight);
      //CASE: no flight found
      //emit addToResults to show popup
      //if this.searchResults.length > 0, show results table
      this.handleFlightSearch(matchedFlight);
    }
    this.detectChange.emit();
  }

  isInstantBooking (flightDetail: FlightInput): boolean {
    return (
      flightDetail.features?.instantBookable &&
      flightDetail.features?.bookable &&
      flightDetail.rates?.length &&
      flightDetail.available
    );
  }

  isRequestBooking (flightDetail: FlightInput): boolean {
    return (
      !flightDetail.features?.instantBookable &&
      flightDetail.features?.bookable &&
      flightDetail.rates?.length &&
      flightDetail.available
    );
  }

  getCo2 (legs: Leg[]): number {
    return +legs
      .filter((path) => path?.co2)
      .map((path) => Math.ceil(path?.co2?.value) || 0)
      .reduce((sum, current) => sum + current, 0)
      .toFixed();
  }

  handleFailure (): void {
    this.isLoading = false;
    this.isRequestInProgress = false;
    this.requestInProgressUpdated.emit(false);
    this.advancedBookingDetails.shipmentStatus = QuoteStatusEnum.REQUEST_FAILED;
    this.isFailed = true;
    this.advancedBookingsRequestService.updateAdvancedBookingDetails(
      this.advancedBookingDetails,
      this.index,
    );
    this.cdr.detectChanges();
    this.detectChange.emit();
  }

  async sendQuoteRequest (
    flightInput: FlightInput,
    isRequestBook: boolean,
  ): Promise<void> {
    this.isLoading = true;
    this.isRequestInProgress = true;
    this.prepareAdvancedBookingDetails();
    const quoteRequest = this.prepareQuoteRequest(flightInput, isRequestBook);
    if (this.isInstantBooking(flightInput)) {
      const instantBookableFlights = this.searchResults.filter(
        (selectedFlight) => {
          if (this.isInstantBooking(selectedFlight)) {
            return selectedFlight;
          }
        },
      ),
       co2Efficiency = +(
        Math.min(
          ...instantBookableFlights.map((item) => this.getCo2(item.legs)),
        ) / this.getCo2(flightInput.legs)
      ).toFixed(1);
      quoteRequest.quotes[0].co2Efficiency = co2Efficiency;
    } else {
      const bookableFlights = this.searchResults.filter((selectedFlight) => {
        if (
          this.isInstantBooking(selectedFlight) ||
          this.isRequestBooking(selectedFlight)
        ) {
          return selectedFlight;
        }
      }),
       co2Efficiency = +(
        Math.min(...bookableFlights.map((item) => this.getCo2(item.legs))) /
        this.getCo2(flightInput.legs)
      ).toFixed(1);
      quoteRequest.quotes[0].co2Efficiency = co2Efficiency;
    }
    if (flightInput.selectedRate?.coloaderOfficeId) {
      quoteRequest.quotes[0].isCoLoaderShipment = true;
      const coLoaderOffice = await this.officeService.getColoaderOfficeDetails(
        flightInput.selectedRate.coloaderOfficeId,
      );
      quoteRequest.quotes[0].coLoaderOffice = coLoaderOffice;
    } else {
      quoteRequest.quotes[0].isCoLoaderShipment = false;
    }
    if(this.sessionService.getCurrentUser()?.type == UserTypeEnum.AIRLINE){
      const quoteRequestAirline = new QuoteRequestAirline(quoteRequest);
      quoteRequestAirline.airline = quoteRequestAirline.airlineCreator =
        this.sessionService.getCurrentUser() as AirlineUser;
      quoteRequestAirline.forwarderOffice = this.sessionService.getSelectedOffice();
      this.quoteCartService.createNewQuoteRequestAirline(quoteRequestAirline).subscribe({
        "next": (res: QuoteRequestWithErrors) => {
          this.handleQuoteResponse(res, flightInput, isRequestBook);
        },
        "error": (error) => {
          this.handleQuoteError(error, flightInput, isRequestBook);
        },
      });
    }
    else{
      this.quoteCartService.createNewQuoteRequest(quoteRequest).subscribe({
        "next": (res: QuoteRequestWithErrors) => {
          this.handleQuoteResponse(res, flightInput, isRequestBook);
        },
        "error": (error) => {
          this.handleQuoteError(error, flightInput, isRequestBook);
        },
      });
    }
  }

  async handleQuoteResponse (res: QuoteRequestWithErrors, flightInput: any, isRequestBook: boolean) {
    if (res.errors?.length) {
      const awbAlreadyUsed = res.errors.find(
        (err) => err.type === QuoteErrorTypeEnum.AWB_ALREADY_USED,
      );
      if (awbAlreadyUsed) {
        this.addToResults.emit({
          "type": this.advancedBookingAlertTypeEnum.DUPLICATE_AWB,
          "awbPrefix": flightInput?.awbPrefix,
        });
        this.awbChangedEvent = this.awbChanged.subscribe((value) => {
          this.formGroup.get("awb").setValue(value);
          this.sendQuoteRequest(flightInput, isRequestBook);
        });
        this.isLoading = false;
        this.isRequestInProgress = false;
        this.cdr.detectChanges();
        this.detectChange.emit();
      } else {
        this.handleFailure();
      }
    } else {
      console.log("[sendQuoteRequest] quoteRequestRes", res);
      try {
        const createdQuote = res.quoteRequest?.quotes?.[0];
        if (createdQuote?.quoteId) {
          try {
            const quote = await this.quotesService.retrieveQuoteById(
              createdQuote.quoteId,
            );
            this.handleQuoteRes(quote);
          } catch (err) {
            console.error(err);
            this.handleQuoteRes(createdQuote);
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  handleQuoteError (error: any, flightInput: any, isRequestBook: boolean) {
    if (error["quotes[0].awb"]?.[0]?.message === "AWB is already used") {
      this.addToResults.emit({
        "type": this.advancedBookingAlertTypeEnum.DUPLICATE_AWB,
        "awbPrefix": flightInput?.awbPrefix,
      });
      this.awbChangedEvent = this.awbChanged.subscribe((value) => {
        this.formGroup.get("awb").setValue(value);
        this.sendQuoteRequest(flightInput, isRequestBook);
      });
      this.isLoading = false;
      this.isRequestInProgress = false;
      this.cdr.detectChanges();
      this.detectChange.emit();
    } else {
      this.handleFailure();
    }
  }

  cancelQuote () {
    let quote = new Quote();
    quote = Object.assign(
      quote,
      this.quote || this.advancedBookingDetails?.quote,
    );

    this.modalRef = this.modalService.open(AdvancedSearchResultsComponent, {
      "windowClass": "advanced-search-results-dialog-homepage",
      "keyboard": false,
      "backdrop": "static",
      "centered": true,
    });
    const resultsComponent = this.modalRef
      .componentInstance as AdvancedSearchResultsComponent;
    resultsComponent.advancedBookingDetails = this.advancedBookingDetails;
    resultsComponent.regionAirports = this.regionAirports;
    resultsComponent.commodities = this.parentCommodities;
    resultsComponent.airlines = this.airlines;
    resultsComponent.type = AdvancedBookingAlertTypeEnum.CANCEL;
    this.modalRef.closed.subscribe({
      "next": (result) => {
        if (result?.cancel) {
          quote.quoteStatus = QuoteStatusEnum.CANCELLATION_REQUESTED;
          this.advancedBookingDetails.shipmentStatus =
            QuoteStatusEnum.CANCELLATION_REQUESTED;
          this.cdr.detectChanges();
          this.quoteListService.updateQuoteStatus(quote).subscribe({
            "next": (res: QuoteWithErrors) => {
              console.log("[cancelQuote] quoteRes", res);
              this.handleQuoteRes(res.quote);
              this.isLoading = false;
            },
            "error": (error) => {
              console.log(">> request failed", error);
              this.isLoading = false;
              this.isRequestInProgress = false;
              this.requestInProgressUpdated.emit(false);
              this.advancedBookingDetails.shipmentStatus =
                QuoteStatusEnum.REQUEST_FAILED;
              this.isFailed = true;
              this.advancedBookingsRequestService.updateAdvancedBookingDetails(
                this.advancedBookingDetails,
                this.index,
              );
              this.cdr.detectChanges();
              this.detectChange.emit();
            },
          });
        } else {
          this.isLoading = false;
          this.cdr.detectChanges();
        }
      },
    });
  }

  handleQuoteRes (quoteRes: Quote) {
    this.quote = quoteRes;
    this.advancedBookingDetails.shipmentId = this.quote.quoteId;
    this.advancedBookingDetails.shipmentStatus = this.quote.quoteStatus;
    this.advancedBookingDetails.awb = this.quote.awb;
    this.formGroup.get("awb").setValue(this.quote.awb);
    this.advancedBookingDetails.quote = quoteRes;
    this.showCancel = this.quote.possibleActions?.includes(
      QuoteActionEnum.CANCEL_BOOKING,
    );
    this.advancedBookingDetails.possibleActions = this.quote.possibleActions;
    this.advancedBookingsRequestService.updateAdvancedBookingDetails(
      this.advancedBookingDetails,
      this.index,
    );
    this.isRequestInProgress = false;
    this.isFailed = false;
    this.isLoading = false;
    this.cdr.detectChanges();
    this.detectChange.emit();
    this.requestInProgressUpdated.emit(false);
  }

  prepareQuoteRequest (
    flightInput: FlightInput,
    isRequestBook: boolean,
  ): QuoteRequest {
    const newQuote = this.prepareQuote(flightInput, isRequestBook),
     newQuoteRequest = new QuoteRequest();
    newQuoteRequest.bookingType = BookingTypeEnum.SEARCH_BOOK;
    newQuoteRequest.quoteRequestStatus = QuoteRequestStatusEnum.PENDING;
    newQuoteRequest.quotes = [newQuote];
    newQuoteRequest.forwarder = newQuoteRequest.forwarderCreator =
      this.sessionService.getCurrentUser() as Forwarder;
    newQuoteRequest.originalQuoteCapacity = this.calculateTotalWeight(newQuote);
    newQuoteRequest.originalQuotePrice = newQuote.suggestedPrice;
    newQuote.acceptedPrice = newQuote.suggestedPrice;
    newQuoteRequest.isShipmentScreened =
      newQuote.quoteRequest.isShipmentScreened;
    newQuoteRequest.specialHandlingCodes =
      newQuote.quoteRequest.specialHandlingCodes;
    newQuoteRequest.quoteItems = newQuote.quoteRequest.quoteItems;
    newQuoteRequest.commodities = newQuote.quoteRequest.commodities;
    newQuoteRequest.requirements = newQuote.quoteRequest.requirements;
    newQuoteRequest.dangerousGoods = newQuote.quoteRequest.dangerousGoods;
    newQuoteRequest.forwarderOffice = this.sessionService.getSelectedOffice();
    newQuoteRequest.measurementSystem = this.measurementSystem;
    return newQuoteRequest;
  }

  prepareQuote (flightInput: FlightInput, isRequestBook: boolean): Quote {
    const newQuote = new Quote(),
     awb = this.advancedBookingDetails?.awb?.replace("-", ""),
     awbStartingCode = awb?.length
      ? awb.substring(0, 3)
      : flightInput?.awbPrefix,
     awbNumber = awb?.substring(3);
    newQuote.quoteDate = new Date();
    newQuote.lastUpdatedDate = new Date();
    newQuote.apiContacts = flightInput?.contacts;
    newQuote.quoteStatus = QuoteStatusEnum.PENDING_AIRLINE_INFORMATION;
    newQuote.awb = awbStartingCode + awbNumber;
    newQuote.awbType = AwbTypeEnum.PAPER_AWB;
    newQuote.allotmentCode = this.advancedBookingDetails.allotmentCode;
    newQuote.attachments = [];
    newQuote.origin = QuoteOrigin.ADVANCED_BOOKING;

    if (isRequestBook) {
      newQuote.contacts = this.airlineContacts.map((contact) => ({
          contact,
          "isDefault": false,
          "contactType": ContactTypeEnum.RESERVATION,
          "quote": undefined,
        }));
      if (!newQuote.contacts?.length) {
        newQuote.contacts = this.getAirlineContacts();
      }
    }

    const selectedRate = flightInput.selectedRate;
    if (selectedRate) {
      newQuote.quoteCurrency = selectedRate.currency;
      newQuote.suggestedPrice = selectedRate.allInRate;
      newQuote.rateDetail = {} as any;
      newQuote.rateDetail.currency = selectedRate.currency;
      newQuote.rateDetail.allInRate = selectedRate.allInRate;
      newQuote.rateDetail.total = selectedRate.total;
      newQuote.paymentType = WalletPaymentTypesEnum.IATA_CASS;
      newQuote.rateDetail.totalWithCharges = selectedRate.total;
      newQuote.rateDetail.weightType = RateWeightTypeEnum.CHARGEABLE_WEIGHT;
      newQuote.rateId = selectedRate.id;
      if (selectedRate?.charges) {
        newQuote.rateDetail.rateSurcharges = selectedRate?.charges.map(
          (rate) =>
            ({
              "surchargeName": rate.label,
              "surchargeCode": rate.code,
              "formulaKg": rate.rate,
              "weightType": rate.type,
              "airlineBrand": flightInput.airlineCompany,
            }) as Surcharges,
        ) as any[];
        newQuote.rateDetail.netRate = selectedRate.netRate;
        newQuote.rateDetail.rateType = RateTypeEnum.NET_RATE;
        newQuote.rateDetail.rate = selectedRate.netRate;
      } else {
        newQuote.rateDetail.rateType = RateTypeEnum.ALL_IN_RATE;
        newQuote.rateDetail.rate = selectedRate.allInRate;
      }
      newQuote.airlineConditions = flightInput.airlineConditions;
      newQuote.salesConditions = flightInput.salesConditions;
    }
    newQuote.searchUUID = this.searchUUID;
    newQuote.flightUUID = flightInput.flightUUID;
    newQuote.awbDisabled = flightInput.features?.awbDisabled;
    newQuote.maxAttachmentCount =
      flightInput.features?.maxAttachmentCount == null
        ? 3
        : flightInput.features?.maxAttachmentCount;
    newQuote.maxAttachmentTotalSize =
      flightInput.features?.maxAttachmentTotalSize == null
        ? 8900000
        : flightInput.features?.maxAttachmentTotalSize;
    newQuote.trips = [mapFlightSearchToTrip(flightInput, this.aircrafts)];
    newQuote.originAirport = {
      "regionCode": undefined,
      "regionName": undefined,
      "timezone": undefined,
      "city": undefined,
      "airportName": flightInput.legs[0].departureAirport,
      "destFlights": undefined,
      "originFlights": undefined,
      "airportCode": flightInput.legs[0].departureAirport,
    };
    newQuote.destinationAirport = {
      "regionCode": undefined,
      "regionName": undefined,
      "timezone": undefined,
      "city": undefined,
      "airportName": flightInput.legs[flightInput.legs.length - 1].arrivalAirport,
      "destFlights": undefined,
      "originFlights": undefined,
      "airportCode": flightInput.legs[flightInput.legs.length - 1].arrivalAirport,
    };
    newQuote.quoteRequest = new QuoteRequest();
    newQuote.quoteRequest.quoteItems = this.prepareQuoteItems();
    newQuote.quoteRequest.specialHandlingCodes =
      this.prepareSpecialHandlingCodes();
    if (flightInput?.selectedRate?.formattedName?.includes("Express")) {
      const shcExpress = this.requirements
        .find(
          (requirement) =>
            requirement.requirement.code === RequirementCodeEnum.EXPRESS,
        )
        .requirement.answers.find((answer) => answer.code === ExpressAnswer.YES)
        .specialHandlingCodes[0],
       isMissingExpress =
        newQuote.quoteRequest.specialHandlingCodes
          .map((currentShc) => currentShc.shcId)
          ?.indexOf(shcExpress.shcId) === -1;
      if (isMissingExpress) {
        newQuote.quoteRequest.specialHandlingCodes.push(shcExpress);
      }
    }
    if (flightInput?.selectedRate?.formattedName?.includes("Live")) {
      const shcs = newQuote.quoteRequest.specialHandlingCodes.filter((shc) => shc?.shcCode !== "XPS");
      newQuote.quoteRequest.specialHandlingCodes = shcs;
    }
    newQuote.quoteRequest.commodities = this.commodities;
    newQuote.quoteRequest.requirements = this.prepareQuoteRequirements();
    newQuote.quoteRequest.dangerousGoods = [];
    newQuote.quoteRequest.measurementSystem = this.measurementSystem;

    newQuote.quoteRequest.isShipmentScreened =
      this.requirements.find(
        (requirement) =>
          requirement.requirement.code === RequirementCodeEnum.SCREENED,
      )?.answer.code === ScreenedAnswer.YES;
    newQuote.quoteRequest.originalQuotePrice = newQuote.suggestedPrice;

    this.currentUser = this.sessionService.getCurrentUser();
    newQuote.quoteRequest.forwarder = newQuote.quoteRequest.forwarderCreator =
      this.sessionService.getCurrentUser() as Forwarder;
    newQuote.airlineBrand = flightInput.airlineCompany;

    newQuote.possibleActions = [QuoteActionEnum.BOOK_QUOTE];
    newQuote.source = flightInput.source;
    newQuote.actualWeight = this.convertWeight(
      +this.advancedBookingDetails.weight,
    );
    newQuote.actualVolume = this.advancedBookingDetails.volume;
    newQuote.chargeableWeight = this.convertWeight(
      +this.advancedBookingDetails.chargeableWeight,
    );
    return newQuote;
  }

  convertWeight (value) {
    return SearchUtil.convertWeight(
      value,
      this.loadTypeCmp?.loadTypeUnitState?.[this.loadTypeMode]?.weightUnitDetail
        ?.code === "kg"
        ? MeasurementSystemEnum.METRIC
        : MeasurementSystemEnum.IMPERIAL,
    );
  }

  getAirlineContacts (): ContactQuote[] {
    const contacts: ContactQuote[] = [];
    for (const user of this.airlinesList) {
      contacts.push({
        "contact": user,
        "isDefault": false,
        "contactType": ContactTypeEnum.RESERVATION,
        "quote": undefined,
      });
    }
    return contacts;
  }

  findFlight (): FlightInput {
    return this.searchResults.find((flight) => (
        (flight.flightNumber === this.advancedBookingDetails.flightNo ||
          !!flight.legs.find(
            (leg) => leg.flightNumber === this.advancedBookingDetails.flightNo,
          )) &&
        moment(flight.departureTime.split("T")[0]).format("yyyy-MM-DD") ===
          moment(this.advancedBookingDetails.departureDate).format("yyyy-MM-DD")
      ));
  }

  prepareSpecialHandlingCodes (): SpecialHandlingCode[] {
    const shcCodes: SpecialHandlingCode[] = [];
    if (this.loadTypeMode === LoadTypeEnum.BUP) {
      this.bupSHCs.forEach((shc) => {
        shcCodes.push(shc);
      });
    } else if (
      this.loadTypeMode === LoadTypeEnum.DIMENSIONS &&
      this.dimensions.some(
        (dims) =>
          [PackingTypeEnum.NONE, PackingTypeEnum.TURNABLE].includes(
            dims.packing,
          ) && dims.errors.length === 0,
      )
    ) {
      shcCodes.push(this.nstSHC);
    }
    if (this.commodities) {
      this.commodities.forEach((comm) => {
        comm.specialHandlingCodes.forEach((commodityShc) => {
          const existing = shcCodes.find(
            (shc) => shc?.shcId === commodityShc.shcId,
          );
          if (!existing && comm.level <= MAX_COMMODITY_LEVEL_TO_USE_SHC) {
            shcCodes.push(commodityShc);
          }
        });
      });
    }
    if (this.requirements) {
      SPECIAL_REQUIREMENTS_TO_USE_SHC.forEach((requirementCode) => {
        const matchedRequirement = this.requirements.find(
          (req) => req.answer && req.requirement.code === requirementCode,
        );
        if (matchedRequirement) {
          matchedRequirement.answer.specialHandlingCodes.forEach(
            (requirementShc) => {
              shcCodes.push(requirementShc);
            },
          );
        }
      });

      this.requirements.forEach((req) => {
        if (req.answer) {
          req.answer.specialHandlingCodes.forEach((requirementShc) => {
            const existing = shcCodes.find(
              (shc) => shc.shcId === requirementShc.shcId,
            );
            if (!existing) {
              shcCodes.push(requirementShc);
            }
          });
        }
      });
    }

    return shcCodes;
  }

  prepareQuoteRequirements (): IRequirementQuote[] {
    return this.requirements.map((req) => ({
        "requirement": req.requirement,
        "answer": req.answer,
        "freeChoice": req.freeChoice,
      }));
  }

  prepareCommoditiesList (): ICommodityLight[] {
    return this.commodities.map((commodity) => ({
        "id": commodity.id.toString(),
        "name": commodity.name,
      } as ICommodityLight));
  }

  prepareCommodities (): ICommodity[] {
    const levelThreeItems = CommodityUtil.getCommodityByLevel(
      3,
      this.commodityWithParent,
    );
    return [this.goodsType, this.subType, levelThreeItems].filter(
      (commodity) => commodity,
    );
  }

  prepareRequirements (): IRequirementSearchTemplate[] {
    const requirements = RequirementUtil.prepareRequirementsAnswer(
      this.goodsType,
      this.formGroup,
      new FormGroup({}),
    ),
     defaultOffice = this.sessionService.getSelectedOffice();
    if (
      !KNOWN_SHIPPER_COUNTRIES.includes(defaultOffice?.country?.countryCode)
    ) {
      return requirements.filter(
        (req) => req.requirement.code !== RequirementCodeEnum.KNOWN_SHIPPER,
      );
    }
    return requirements;
  }

  prepareTypeOfProduct (): TypeOfProduct {
    return SearchUtil.prepareTypeOfProduct(
      this.typeOfProducts,
      this.goodsType,
      new FormGroup({}),
    );
  }

  prepareQuoteItems (): QuoteItem[] {
    return SearchUtil.prepareQuoteItems(
      this.loadTypeMode,
      this.formGroup,
      this.measurementSystem,
      this.loadTypeCmp?.totalUnitsState?.measurementSystem,
      this.prepareTypeOfProduct(),
      this.dimensions,
      this.bups,
      this.loadTypeCmp?.loadTypeUnitState?.[this.loadTypeMode]
        ?.weightUnitDetail,
      this.loadTypeCmp?.loadTypeUnitState?.[this.loadTypeMode]
        ?.dimensionUnitDetail,
      this.loadTypeCmp?.loadTypeUnitState?.DIMENSIONS,
    );
  }

  calculateTotalWeight (quote): number {
    if (quote.actualWeight) {
      return quote.actualWeight;
    }
    let totalWeight = 0;
    if (quote) {
      quote.quoteRequest.quoteItems.forEach((element) => {
        if (element.weightType === "PER_ITEM") {
          totalWeight += element.weight * element.numOfItems;
        } else {
          totalWeight += element.weight;
        }
      });
    }
    return totalWeight;
  }

  weightUnitChange ($event: MeasurementUnit, unitState: LoadTypeUnit) {
    unitState.weightUnitDetail = $event;
    this.loadTypeCmp.convertTotalWeight(unitState, $event);
  }

  dimensionUnitChange ($event: MeasurementUnit, unitState: LoadTypeUnit) {
    unitState.dimensionUnitDetail = $event;
    this.loadTypeCmp.handleDimensionUnitChange($event);
    this.cdr.detectChanges();
  }

  measurementSystemChange (
    $event: MeasurementSystemEnum,
    unitState: LoadTypeUnit,
    isDimension: boolean,
  ) {
    if (isDimension) {
      this.dimensionTypeUnit = $event;
    } else {
      this.bupTypeUnit = $event;
    }
    unitState.measurementSystem = $event;
    this.cdr.detectChanges();
  }

  changeMode ($event: LoadTypeEnum) {
    this.loadTypeCmp.changeMode($event);
    this.loadTypeCmp.updateTotals();
  }

  async changeProductType (value: ICommoditySearchResult) {
    this.formGroup.markAsDirty();

    switch (value?.type) {
      case CommodityResultTypeEnum.COMMODITY: {
        this.commodityWithParent =
          await this.commodityService.getCommodityParent(
            value.object,
            this.parentCommodities,
          );
        const goodsType = CommodityUtil.getCommodityByLevel(
          1,
          this.commodityWithParent,
        );
        this.formGroup?.get("goodsType").setValue(goodsType?.id.toString());
        if (value?.object) {
          this.commodities = [value.object];
        }
        this.cdr.detectChanges();
        break;
      }
      case CommodityResultTypeEnum.DANGEROUS_GOOD: {
        this.commodityWithParent = this.parentCommodities.find(
          (commodity) => commodity.name === DANGEROUS_GOODS,
        );
        this.formGroup
          ?.get("goodsType")
          .setValue(this.commodityWithParent?.id?.toString());
        if (value?.object) {
          this.commodities = [value.object];
        }
        this.cdr.detectChanges();
        break;
      }
      default:
        this.formGroup?.get("goodsType").setValue(null);
        this.commodityWithParent = null;
        if (value?.object) {
          this.commodities = [value.object];
        }
        this.cdr.detectChanges();
        break;
    }
  }

  isFormValid (): boolean {
    if (this.formGroup.invalid) {
      Object.keys(this.formGroup.controls).forEach((key) => {
        const control = this.formGroup.controls[key];
        if (control && control.invalid) {
          control.markAsTouched();
          if (control.value === undefined || control.value === null) {
            control.setValue(null);
          }
        }
      });
      if (
        this.dimensionTable &&
        typeof this.dimensionTable.validate === "function"
      ) {
        this.dimensionTable.validate();
      }
      if (this.bupTable && typeof this.bupTable.validate === "function") {
        this.bupTable.validate();
      }
      return false;
    }
    return true;
  }

  viewShipment () {
    window.open(
      `/shipment-details/${this.advancedBookingDetails.shipmentId}`,
      "_blank",
    );
  }

  get goodsType (): ICommodity {
    return CommodityUtil.getCommodityByLevel(1, this.commodityWithParent);
  }

  get subType (): ICommodity {
    return CommodityUtil.getCommodityByLevel(2, this.commodityWithParent);
  }

  get airlineLogo (): string {
    this.selectedAirline = this.airlines?.find(
      (airline) =>
        airline.airlineCompanyId.toString() ===
        this.formGroup?.get("airline")?.value,
    );
    if (this.selectedAirline) {
      return this.fileService.getAirlineLogoPath(
        this.selectedAirline?.airlineCompanyCode,
      );
    } else {
      return null;
    }
  }

  get isPartiallyFilled (): boolean {
    return (
      !!this.formGroup.get("airline").value ||
      !!this.formGroup.get("origin").value ||
      !!this.formGroup.get("destination").value ||
      !!this.formGroup.get("flightNo").value ||
      !!this.formGroup.get("weight").value ||
      !!this.formGroup.get("volume").value ||
      !!this.formGroup.get("awb").value ||
      !!this.formGroup.get("allotment").value
    );
  }

  get isValidDimensions (): boolean {
    return (
      this.dimensions.filter((item) => (
          CaiDimensionUtil.isDimensionValid(
            item,
            this.loadTypeCmp?.loadTypeUnitState?.[this.loadTypeMode]
              ?.weightUnitDetail,
            this.loadTypeCmp?.loadTypeUnitState?.[this.loadTypeMode]
              ?.dimensionUnitDetail,
          ) || CaiDimensionUtil.isPartiallyFilled(item)
        )).length > 0
    );
  }

  get isValidBUPs (): boolean {
    return (
      this.bups.filter((item) => (
          CaiBUPUtil.isBUPValid(
            item,
            this.loadTypeCmp?.loadTypeUnitState?.[this.loadTypeMode]
              ?.weightUnitDetail,
          ) || CaiBUPUtil.isPartiallyFilled(item)
        )).length > 0
    );
  }

  get showDimensions (): boolean {
    return (
      this.loadTypeMode === LoadTypeEnum.DIMENSIONS &&
      (!this.collapsed ||
        (!this.isValidDimensions && this.dimensions.length <= 1)) &&
      !this.isViewOnly &&
      this.isActive &&
      !this.isSubmitted
    );
  }

  get showBUP (): boolean {
    return (
      this.loadTypeMode === LoadTypeEnum.BUP &&
      (!this.collapsed || (!this.isValidBUPs && this.bups.length <= 1)) &&
      !this.isViewOnly &&
      !this.isSubmitted &&
      this.isActive
    );
  }

  get isViewOnly (): boolean {
    if (this.isLoading || this.readOnly) {
      return true;
    }
    return (
      (this.isRequested || !!this.advancedBookingDetails?.shipmentStatus) &&
      this.advancedBookingDetails?.shipmentStatus !==
        QuoteStatusEnum.REQUEST_FAILED &&
      !this.advancedBookingDetails?.isEditable
    );
  }

  get isSubmitted (): boolean {
    return (
      (this.isRequested &&
        !!this.advancedBookingDetails?.shipmentId &&
        this.advancedBookingDetails?.shipmentStatus !==
          QuoteStatusEnum.REQUEST_FAILED) ||
      (!!this.advancedBookingDetails?.shipmentStatus &&
        this.advancedBookingDetails?.shipmentStatus !==
          QuoteStatusEnum.REQUEST_FAILED)
    );
  }

  get actions (): AdvancedBookingActionEnum[] {
    if (this.isSubmitted) {
      return [AdvancedBookingActionEnum.HIDE_CARD];
    } else {
      if (this.isSuggestion) {
        if (this.advancedBookingDetails.isFavorite) {
          return [AdvancedBookingActionEnum.REMOVE_FROM_FAVORITE];
        } else {
          return [AdvancedBookingActionEnum.SAVE_AS_FAVORITE];
        }
      } else if (this.isFavorite) {
        return [AdvancedBookingActionEnum.REMOVE_FROM_FAVORITE];
      } else {
        return [AdvancedBookingActionEnum.DELETE];
      }
    }
  }

  handleDimensionUnitStateChange (loadTypeUnitState) {
    this.loadTypeCmp.loadTypeUnitState[this.loadTypeMode] = loadTypeUnitState;
  }
}
