<div class="wrapper">
  <div class="dropdown">
    <input
      #input
      class="select-trigger"
      [placeholder]="placeholder || getSelectedRadioLabel()"
      [class.invalid]="invalid || !isOpen()"
      [(ngModel)]="inputText"
      [title]="inputText"
      (click)="open(dropdown, input)"
      readonly
    />
    <span (click)="open(dropdown, input)" class="caret">▼</span>
    <ng-template #dropdown>
      <div class="date-filter-dropdown">
        <div class="text-wrapper" i18n="@@global.date-Label">Date</div>
        <mat-radio-group
          [(ngModel)]="selectedOption"
          (change)="setRadioDateRange($event.value)"
          class="date-filters"
        >
          <mat-radio-button
            *ngFor="let option of options"
            [value]="option.value"
            >{{ option.label }}</mat-radio-button
          >
        </mat-radio-group>
        <mat-divider></mat-divider>
        <div class="text-wrapper my-4" i18n="@@global.choose-Date-Range">
          Choose a date range
        </div>
        <mat-form-field
          class="date-filter-range"
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <mat-label i18n="@@global.enter-date-Range"
            >Enter a date range</mat-label
          >
          <mat-date-range-input
            [rangePicker]="rangePicker"
            class="mat-date-range-input-wrapper"
            [min]="minimumDate"
            [max]="maximumDate"
          >
            <input
              matStartDate
              placeholder="start date"
              [(ngModel)]="startDate"
              i18n-placeholder="@@global.start-Date-Placeholder"
            />
            <input
              matEndDate
              placeholder="end date"
              [(ngModel)]="endDate"
              i18n-placeholder="@@global.end-Date-Placeholder"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="rangePicker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #rangePicker class="mat-date-range-picker">
            <mat-date-range-picker-actions>
              <button
                mat-button
                matDateRangePickerCancel
                i18n="@@global.cancel-Button"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                color="primary"
                (click)="applyDateRange()"
                matDateRangePickerApply
                i18n="@@global.apply-Button"
              >
                Apply
              </button>
            </mat-date-range-picker-actions>
          </mat-date-range-picker>
        </mat-form-field>
      </div>
    </ng-template>
  </div>
</div>
