import { Injectable } from '@angular/core';
import { API } from '@aws-amplify/api';
import { IAwb } from '../../model/awb.model';
import { ErrorUtil } from '../../utils/error.util';
import { API_CORE } from '../../constant/api-core.const';
import { IAwbStockInfo } from '../../model/awb-stock-info.model';

@Injectable({
  providedIn: 'root',
})
export class AwbService {
  async checkAwbStatus(
    quoteId: number,
    awb: string,
    token?: string,
  ): Promise<IAwb> {
    const queryStringParams = { quote: quoteId } as any;
    if (token) {
      queryStringParams.token = token;
    }
    const url = API_CORE.URLs.Awb.AwbStatus.replace('{awb}', awb);
    return API.get(API_CORE.name, url, {
      queryStringParameters: queryStringParams,
    }).catch(ErrorUtil.handleError);
  }

  async createAwbStock(awbStockInfo: IAwbStockInfo): Promise<IAwbStockInfo> {
    return API.post(API_CORE.name, API_CORE.URLs.Solutions.AwbManagement, {
      body: awbStockInfo,
    }).catch(ErrorUtil.handleError);
  }

  async getAwbStock(officeId: number): Promise<IAwbStockInfo[]> {
    const extraOptions: any = {
      queryStringParameters: {},
    };
    extraOptions.queryStringParameters.officeId = officeId;

    return API.get(
      API_CORE.name,
      API_CORE.URLs.Solutions.AwbManagement,
      extraOptions,
    ).catch(ErrorUtil.handleError);
  }
}
