import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  OnChanges,
} from '@angular/core';
import { QuoteStatusEnum } from '@cai-services';
import Popper from 'popper.js';
import { filter, fromEvent, takeWhile } from 'rxjs';

@Component({
  selector: 'kt-booking-details-card',
  templateUrl: './booking-details-card.component.html',
  styleUrls: ['./booking-details-card.component.scss'],
})
export class BookingDetailsCardComponent implements OnChanges {
  @ViewChild('menuItemsTpl') menuItemsTpl: TemplateRef<any>;
  @ViewChild('menuToggler') menuToggler: ElementRef;
  @Input() isActive: boolean;
  @Input() showFooter: boolean;
  @Input() status: QuoteStatusEnum;
  @Input() showMenu: boolean;
  @Input() disableMenu: boolean;
  @Input() popup: boolean;
  @Input() invalid: boolean;
  @Input() isCollapsed: boolean;
  @Output() onClose = new EventEmitter();

  popperRef: Popper;
  view: EmbeddedViewRef<any>;
  bgColor: string;

  constructor(
    private readonly vcr: ViewContainerRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('status')) {
      switch (this.status) {
        case QuoteStatusEnum.PENDING_AIRLINE_INFORMATION:
          this.bgColor = '#2DCE89';
          break;
        case QuoteStatusEnum.PENDING_DELIVERY:
          this.bgColor = '#2DCE89';
          break;
        case QuoteStatusEnum.REQUEST_FAILED:
          this.bgColor = '#EF75B4';
          break;
        case QuoteStatusEnum.BOOKING_REJECTED:
          this.bgColor = '#FF708B';
          break;
        case QuoteStatusEnum.CANCELLED:
          this.bgColor = '#FF708B';
          break;
        case QuoteStatusEnum.FAILED:
          this.bgColor = '#F8A243';
          break;
        case QuoteStatusEnum.CANCELLATION_REQUESTED:
          this.bgColor = '#F8A243';
          break;
        case QuoteStatusEnum.BOOKING_FAILED:
          this.bgColor = '#F8A243';
          break;
        case QuoteStatusEnum.BOOKING_CONFIRMED:
          this.bgColor = '#2DCE89';
          break;
        default:
          this.bgColor = '#679EC6';
          break;
      }
    }
    if (changes.hasOwnProperty('invalid') && this.invalid && !this.status) {
      this.bgColor = '#DADADA';
    }
    if (
      changes.hasOwnProperty('invalid') &&
      !this.invalid &&
      this.bgColor === '#DADADA'
    ) {
      this.bgColor = '#679EC6';
    }
    this.cdr.detectChanges();
  }

  isOpen(): boolean {
    return !!this.popperRef;
  }

  open(event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.disableMenu) {
      return;
    }
    if (!!this.popperRef) {
      return this.close();
    }
    this.view = this.vcr.createEmbeddedView(this.menuItemsTpl);
    const menuItems = this.view.rootNodes[0];
    document.body.appendChild(menuItems);

    setTimeout(() => {
      this.popperRef = new Popper(this.menuToggler.nativeElement, menuItems, {
        placement: 'top-end',
        removeOnDestroy: true,
        modifiers: {
          offset: {
            enabled: true,
            offset: '-10, 6',
          },
        },
      });

      fromEvent(document, 'click')
        .pipe(
          takeWhile(() => this.isOpen()),
          filter(({ target }) => {
            const origin = this.popperRef?.reference as HTMLElement;
            return !origin?.contains(target as HTMLElement);
          }),
        )
        .subscribe(() => {
          this.close();
        });
    });
    return null;
  }

  close() {
    this.popperRef?.destroy();
    this.view?.destroy();
    this.popperRef = null;
    this.view = null;
    this.cdr.detectChanges();
  }
}
