import { Injectable } from '@angular/core';
import { API } from '@aws-amplify/api';
import { IAircraft } from '../../model/aircraft.model';
import { ErrorUtil } from '../../utils/error.util';
import { API_CORE } from '../../constant/api-core.const';

@Injectable({
  providedIn: 'root',
})
export class AircraftService {
  async getAircrafts(token?: string): Promise<IAircraft[]> {
    const queryStringParams = {} as any;
    if (token) {
      queryStringParams.token = token;
    }
    return API.get(API_CORE.name, API_CORE.URLs.Aircraft.Aircrafts, {
      queryStringParameters: queryStringParams,
    }).catch(ErrorUtil.handleError);
  }
}
