import { Injectable } from "@angular/core";
import {
  IPrefillInformation,
  ISendConfirmResponse,
  UserService, UserTypeEnum
} from "@cai-services";
import { from, Observable } from "rxjs";
import { AirlineUser } from "../_models/airline-user.model";
import { Forwarder } from "../_models/forwarder.model";
import { NotificationSetting } from "../_models/notification-setting.model";
import { User } from "../_models/user.model";

@Injectable({
  "providedIn": "root",
})
export class RegisterService {
  constructor (private readonly userService: UserService) {}

  registerAirlineUser (user: any): Observable<User> {
    return this.registerUser(user, "airlines");
  }

  registerStandardUser (user: any): Observable<User> {
    return this.registerUser(user, "standards");
  }

  registerForwarder (user: any): Observable<User> {
    return this.registerUser(user, "forwarders");
  }

  private registerUser (user: any, type: string): Observable<User> {
    return from(this.userService.registerUser(user, type));
  }

  getUser (token?: string): Promise<User> {
    return this.userService.getCurrentUser(token) as Promise<User>;
  }

  getForwarders (): Promise<Forwarder[]> {
    return this.userService.getForwarders() as Promise<Forwarder[]>;
  }

  updateAirline (
    airline: AirlineUser,
    airlineCompanyCode: string,
    token?: string
  ): Observable<AirlineUser> {
    return from(
      this.userService.updateAirline(
        airline,
        airlineCompanyCode,
        token
      ) as Promise<AirlineUser>
    );
  }

  updateForwarder (forwarder: Forwarder, token?: string): Observable<Forwarder> {
    return from(
      this.userService.updateForwarder(forwarder, token) as Promise<Forwarder>
    );
  }
  updateStandard (standard: User, token?: string): Observable<User> {
    return from(
      this.userService.updateStandards(standard, token) as Promise<User>
    );
  }
  validateEmail (token: string): Promise<void> {
    return this.userService.validateEmail(token);
  }

  resendEmail (token: string): Promise<ISendConfirmResponse> {
    return this.userService.resendEmail(token);
  }

  checkExistingEmail (email: string, userType: UserTypeEnum): Observable<boolean> {
    return from(this.userService.checkExistingEmail(email, userType));
  }

  getPrefillInformation (token?: string): Observable<IPrefillInformation> {
    return from(this.userService.getPrefillInformation(token));
  }

  getNSByForwarder (): Promise<NotificationSetting[]> {
    return this.userService.getNSByForwarder() as Promise<
      NotificationSetting[]
    >;
  }

  updateNSByForwarder (
    body: NotificationSetting[]
  ): Promise<NotificationSetting[]> {
    return this.userService.updateNSByForwarder(body) as Promise<
      NotificationSetting[]
    >;
  }
}
