import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  WalletService,
  TransactionStatusEnum,
  WalletTransaction,
  CustomerService,
  TransactionCategoryLabelEnum,
} from "@cai-services";
import moment from "moment";
import { Router } from "@angular/router";
import { cloneDeep } from "lodash";
import { Subscription } from "rxjs";
import { Title } from "@angular/platform-browser";
import { SessionService } from "../../../../core/_services/session.service";
import { AwbFormatter } from "../../../../core/_base/layout/pipes/awb-formatter.pipe";
import { WalletUtil } from "../../../../../lib/utils/wallet.util";
import { APP_PROPS } from "../../../../cai-common.module";
import { ApplicationProperties } from "../../../../core/_base/layout/models/app-properties.model";
import { TransactionStatus } from "../../../../constant/wallet-transaction-status.const";

@Component({
  "selector": "kt-wallet-transactions",
  "templateUrl": "./wallet-transactions.component.html",
  "styleUrls": ["./wallet-transactions.component.scss"],
  "providers": [AwbFormatter],
})
export class WalletTransactionsComponent implements OnInit, OnDestroy {
  constructor (
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private readonly sessionService: SessionService,
    private readonly walletService: WalletService,
    private readonly customerService: CustomerService,
    private readonly awbFormatter: AwbFormatter,
    private _ref: ChangeDetectorRef,
    private router: Router,
    private readonly titleService: Title,
  ) {}

  onHoldTransactionsList: Array<WalletTransaction>;
  transactionsList: Array<WalletTransaction>;
  private officeChangedStatusSub: Subscription;
  moment = moment;
  Math = Math;
  showFilter = false;
  walletLoader = false;
  transactionStatusEnum = TransactionStatusEnum;
  transactionStatus = TransactionStatus;
  transactionTypeFilters = [
    {
      "label": "Booking Adjustment",
      "value": "Booking Adjustment",
    },
    {
      "label": "Booking Prepayment",
      "value": "Booking Prepayment",
    },
    {
      "label": "Top Up",
      "value": "CargoWALLET Top Up",
    },
    {
      "label": "PayLater 30 days",
      "value": "PayLater 30 days",
    },
    {
      "label": "PayLater 60 days",
      "value": "PayLater 60 days",
    },
    {
      "label": "PayLater 30 days Fee",
      "value": "CargoWALLET PayLater 30 days Fee",
    },
    {
      "label": "PayLater 60 days Fee",
      "value": "CargoWALLET PayLater 60 days Fee",
    },
    {
      "label": "Export Fee",
      "value": "CargoWALLET Export Fee",
    },
    {
      "label": "Conversion Fee",
      "value": "Conversion Fee",
    },
    {
      "label": "Prepaid Fee",
      "value": "Prepaid Fee",
    },
    {
      "label": "Payment issued",
      "value": "Payment issued",
    },
    {
      "label": "Payment Received",
      "value": "Payment Received",
    },
    {
      "label": "Payment issued - Credit Card",
      "value": "Payment issued - Credit Card",
    },
    {
      "label": "Credit Card Fee",
      "value": "Credit Card Fee",
    },
    {
      "label": "Payout",
      "value": "Payout",
    },
    {
      "label": "Payout Fee",
      "value": "Payout Fee",
    },
  ];
  selectedType = "";
  selectedTranType = [];
  ngOnInit (): void {
    this.officeChangedStatusSub =
      this.sessionService.officeChangedStatusObs.subscribe((_) =>
        this.getTransactions(),
      );
    if (this.isCargoWallet) {
      this.transactionTypeFilters.push({
        "label": "Payment Fee",
        "value": "Payment Fee",
      });
    }

    this.titleService.setTitle(
      $localize`:@@wallet-history.tab-title:History - CargoWALLET`,
    );
  }

  ngOnDestroy (): void {
    if (this.officeChangedStatusSub) {
      this.officeChangedStatusSub.unsubscribe();
    }
  }

  async getTransactions (): Promise<void> {
    try {
      this.walletLoader = true;
      const office = this.sessionService.getSelectedOffice();
      if (!office?.walletId) {
        this.router.navigateByUrl(this.appProperties.walletBaseUrl);
        return;
      }
      const officeId = office?.id?.toString();
      if (officeId) {
        let getTransactionsList;
        if (this.isCargoWallet) {
          const getEventResponse =
            await this.customerService.getTransactionsList(office.walletId);
          getTransactionsList = getEventResponse.events;
        } else {
          const getEventResponse =
            await this.walletService.getTransactionsList(officeId);
          getTransactionsList = getEventResponse.events;
        }
        const clonedTransactions = cloneDeep(getTransactionsList);

        this.transactionsList = clonedTransactions
          .filter((x) => {
            const isValidTransactionToShow =
              (x.transaction_status ===
                TransactionStatusEnum.AWAITING_PAYMENT &&
                x.transaction_type !== "GET_PAID" &&
                !x.transaction_type?.includes("TAZAPAY_PAYOUT")) ||
              (x.transaction_status ===
                TransactionStatusEnum.AWAITING_PAYMENT &&
                x.transaction_type === "GET_PAID" &&
                !!x.metadata_user?.data?.fee_type);

            if (
              x.transaction_status === TransactionStatusEnum.ON_HOLD_BENEFICIARY
            ) {
              return x.transaction_type === "GET_PAID";
            }
            return (
              x.transaction_status !== TransactionStatusEnum.ON_HOLD &&
              !isValidTransactionToShow &&
              x.transaction_status !== TransactionStatusEnum.WAITING_FUND &&
              x.transaction_status !==
                TransactionStatusEnum.ON_HOLD_BENEFICIARY &&
              !this.creditCardFailedTransaction(x)
            );
          })
          .map((transaction) => ({
              ...transaction,
              "additional_info": transaction.is_conversion_transaction
                ? ` - Original amount ${transaction.conversion_data?.converted_amount} ${transaction.conversion_data?.converted_currency}`
                : "",
              "tran_type": WalletUtil.getTransactionType(transaction),
            }));
        this.onHoldTransactionsList = clonedTransactions
          .filter(
            (x) =>
              x.transaction_status === TransactionStatusEnum.ON_HOLD ||
              (x.transaction_status ===
                TransactionStatusEnum.ON_HOLD_BENEFICIARY &&
                x.transaction_type !== "GET_PAID"),
          )
          .map((transaction) => ({
              ...transaction,
              "additional_info": transaction.is_conversion_transaction
                ? ` - Original amount ${transaction.conversion_data?.converted_amount} ${transaction.conversion_data?.converted_currency}`
                : "",
              "tran_type": WalletUtil.getTransactionType(transaction),
            }));
        this.filterTransactions();
        this.walletLoader = false;
        this._ref.detectChanges();
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.walletLoader = false;
    }
  }
  onChangeTransaction ($event: boolean, value: string): void {
    if ($event) {this.selectedTranType.push(value);}
    else {this.selectedTranType.splice(this.selectedTranType.indexOf(value), 1);}
    this.filterTransactions();
  }
  referenceType (item: WalletTransaction): string {
    if (WalletUtil.getMetadata(item, "receipt_number"))
      {return $localize`:@@wallet.top-up-receipt:Top Up Receipt: `;}
    else if (WalletUtil.getMetadata(item, "invoice_number"))
      {return $localize`:@@wallet.top-up-invoice:Top Up Invoice: `;}
    else if (item.metadata_user?.type === "STANDALONE")
      {return TransactionCategoryLabelEnum[item.transaction_category] + ": ";}
    return WalletUtil.getMetadata(item, "awb")
      ? $localize`:@@global.awb:AWB: `
      : "";
  }

  creditCardFailedTransaction (item: WalletTransaction): boolean {
    if (
      item.transaction_type?.includes("TAZAPAY") &&
      item.operation === "CREDIT" &&
      item.transaction_status === this.transactionStatus.DECLINED
    ) {
      return true;
    }
    return false;
  }

  referenceValue (item: WalletTransaction): string {
    if (WalletUtil.getMetadata(item, "receipt_number"))
      {return WalletUtil.getMetadata(item, "receipt_number");}
    if (WalletUtil.getMetadata(item, "invoice_number"))
      {return WalletUtil.getMetadata(item, "invoice_number");}
    if (item.metadata_user?.type === "STANDALONE")
      {return WalletUtil.getMetadata(item, "reference");}
    else
      {return this.awbFormatter.transform(WalletUtil.getMetadata(item, "awb"));}
  }
  openFilter (): void {
    this.showFilter = !this.showFilter;
  }
  setTranType (type): void {
    this.selectedType = this.selectedType === type ? "" : type;
    this.filterTransactions();
  }
  filterTransactions (): void {
    this.onHoldTransactionsList = this.onHoldTransactionsList.map(
      (transaction) => ({
          ...transaction,
          "isShow":
            this.ensureOperationType(transaction) &&
            this.ensureTransactionType(transaction),
        }),
    );
    this.transactionsList = this.transactionsList.map((transaction) => ({
        ...transaction,
        "isShow":
          this.ensureOperationType(transaction) &&
          this.ensureTransactionType(transaction),
      }));
    this._ref.detectChanges();
  }
  ensureOperationType (transaction: WalletTransaction): boolean {
    return (
      !this.selectedType ||
      transaction?.operation?.toLowerCase() === this.selectedType?.toLowerCase()
    );
  }
  ensureTransactionType (transaction: WalletTransaction): boolean {
    return (
      !this.selectedTranType?.length ||
      this.selectedTranType?.some(
        (tranType) => tranType == transaction.tran_type,
      )
    );
  }

  getFromTo (item: WalletTransaction): string {
    if (item?.tran_type?.includes("Fee")) {
      return "";
    }
    let office = item.metadata_user.data?.office;
    if (item.operation === "CREDIT") {
      office = item?.metadata_user?.data?.buyer_office;
    }
    const officeInfo = [
      office?.company_name,
      office?.country?.country_code,
      office?.office_id,
    ]
      .filter((info) => !!info)
      .join(" - ");
    return (
      item.email_pay_to ||
      officeInfo ||
      item.destination_pay_from ||
      item.destination_pay_to ||
      item.email_pay_from ||
      "-"
    );
  }

  get visibleOnHoldTransactionsList (): Array<WalletTransaction> {
    return this.onHoldTransactionsList?.filter(
      (transaction) => transaction.isShow,
    );
  }
  get visibleTransactionsList (): Array<WalletTransaction> {
    const clonedTransactionsList = cloneDeep(this.transactionsList);
    clonedTransactionsList?.forEach((transaction) => {
      if (
        transaction?.transaction_type.includes("TAZAPAY_PAYOUT") &&
        transaction.transaction_status === "AWAITING_PAYMENT"
      ) {
        transaction.transaction_status = "PENDING";
      }
      if (
        transaction?.transaction_type === "GET_PAID" &&
        transaction?.due_date < moment().toISOString() &&
        transaction?.transaction_status !== "SETTLED"
      ) {
        transaction.transaction_status = "OVER_DUE";
      }
    });
    return clonedTransactionsList?.filter((transaction) => transaction.isShow);
  }

  get walletBaseUrl (): string {
    return this.appProperties.walletBaseUrl;
  }

  get isCargoWallet (): boolean {
    return this.appProperties.name === "cargowallet";
  }
}
