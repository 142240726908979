import moment from 'moment';
import { MeasurementSystemEnum } from '@cai-services';
import { TimeFormatPipe } from '../core/_base/layout/pipes/time-format.pipe';
import {
  DEFAULT_DATETIME_FORMAT,
  NARROW_AIRCRAFT_MAX_HEIGHT,
  NARROW_AIRCRAFT_MAX_LENGTH,
  NARROW_AIRCRAFT_MAX_WEIGHT,
  NARROW_AIRCRAFT_MAX_WIDTH,
  WIDEBODY_AIRCRAFT_MAX_HEIGHT,
  WIDEBODY_AIRCRAFT_MAX_LENGTH,
  WIDEBODY_AIRCRAFT_MAX_WEIGHT,
  WIDEBODY_AIRCRAFT_MAX_WIDTH,
} from '../core/_constants/constants';
import { AirportLight } from '../core/_models/airport-light.model';
import {
  CustomFlightDetails,
  FlightInput,
  Leg,
} from '../core/_models/flight-input.model';
import { FlightRate } from '../core/_models/flight-rate.model';
import { getStringDate } from '../helpers/date-helpers';
import { SPECIAL_AIRLINE_CASES } from '../helpers/search_helpers';
import { AircraftTypes } from '../../lib/core/_enums/aircraft-enum';
import { MeasurementUtil } from '../../lib/core/_base/crud/utils/measurement.util';
import { SearchTemplate } from '../core';

export class SearchResultUtil {
  private static readonly timeFormatPipe = new TimeFormatPipe();

  private static formatArrivalTime(arrivalTime: string): Date {
    const stringDate = getStringDate(arrivalTime),
      stringTime = SearchResultUtil.timeFormatPipe.transform(arrivalTime);
    return moment(
      `${stringDate} ${stringTime}`,
      DEFAULT_DATETIME_FORMAT,
    ).toDate();
  }

  private static cheaperComparator(flightA: FlightInput, flightB: FlightInput) {
    const rateDifference =
      flightA.rates[0].allInRate - flightB.rates[0].allInRate;
    if (rateDifference !== 0) {
      return rateDifference;
    }
    return SearchResultUtil.greenerComparator(flightA, flightB);
  }

  private static greenerComparator(flightA: FlightInput, flightB: FlightInput) {
    const co2Difference =
      +SearchResultUtil.getCO2(flightA.legs) -
      +SearchResultUtil.getCO2(flightB.legs);
    if (co2Difference !== 0) {
      return co2Difference;
    }
    return SearchResultUtil.earlierArrivalComparator(flightA, flightB);
  }

  private static earlierArrivalComparator(
    flightA: FlightInput,
    flightB: FlightInput,
  ) {
    return (
      +SearchResultUtil.formatArrivalTime(flightA.arrivalTime) -
      +SearchResultUtil.formatArrivalTime(flightB.arrivalTime)
    );
  }

  static getSortedFlightsByEarliestArrival(flights: FlightInput[]) {
    return flights.slice().sort(this.earlierArrivalComparator);
  }

  static getSortedFlightsByExpressRate(flights: FlightInput[]) {
    const flightsWithRate = flights.filter(
        (flight) => flight.rates?.length > 0 && flight.features.bookable,
      ),
      flightsWithExpressRate = flightsWithRate.filter((flight) =>
        flight.rates.some((rate) => rate.type === 'express'),
      );
    flightsWithExpressRate.sort(SearchResultUtil.cheaperComparator);

    return [...flightsWithExpressRate];
  }

  static getSortedFlightsByCheapest(flights: FlightInput[]) {
    const [flightsWithRate, flightsWithoutRate] = flights.reduce(
      ([withRate, withoutRate], flight) => {
        if (flight.rates?.length > 0) {
          withRate.push(flight);
        } else {
          withoutRate.push(flight);
        }
        return [withRate, withoutRate];
      },
      [[], []] as [FlightInput[], FlightInput[]],
    );

    flightsWithRate.sort(SearchResultUtil.cheaperComparator);
    return [...flightsWithRate, ...flightsWithoutRate];
  }

  static getSortedFlightsByGreenest(flights: FlightInput[]): FlightInput[] {
    return flights.slice().sort(SearchResultUtil.greenerComparator);
  }

  static sortAirlineFlightsByBest(flightInputs: FlightInput[]): FlightInput[] {
    let sortedByRate = [];
    const flightsWithoutRate = flightInputs.filter(
        (flight) => !flight.rates || !flight.rates.length,
      ),
      flightsWithRate = flightInputs.filter(
        (flight) => flight.rates && flight.rates.length,
      ),
      flightsAvailable = flightsWithRate.filter((flight) => flight.available),
      flightsUnavailable = flightsWithRate.filter(
        (flight) => !flight.available,
      ),
      groupedByRate = this.groupFlightsByRate(flightsAvailable);
    groupedByRate.forEach((group) => {
      sortedByRate = sortedByRate.concat(this.sortByArrivalDate(group.flights));
    });

    return [
      ...sortedByRate,
      ...this.sortByArrivalDate(flightsUnavailable),
      ...this.sortByArrivalDate(flightsWithoutRate),
    ];
  }

  static groupLowerShipmentCost(flights) {
    const flightsWithoutRate = flights.filter(
        (flight) => !flight.rates || !flight.rates.length,
      ),
      flightsWithRate = flights.filter(
        (flight) => flight.rates && flight.rates.length,
      ),
      flightsAvailable = flightsWithRate.filter((flight) => flight.available),
      flightsUnavailable = flightsWithRate.filter(
        (flight) => !flight.available,
      ),
      sortByRate = (rateFlights) => {
        let sortedByRate = [];
        const groupedByRate = this.groupFlightsByRate(rateFlights);
        groupedByRate.forEach((group) => {
          sortedByRate = sortedByRate.concat(
            group.flights.sort(SearchResultUtil.earlierArrivalComparator),
          );
        });
        return sortedByRate;
      },
      availableSortedByRate = sortByRate(flightsAvailable),
      unavailableSortedByRate = sortByRate(flightsUnavailable);

    return [
      ...availableSortedByRate,
      ...unavailableSortedByRate,
      ...flightsWithoutRate.sort(SearchResultUtil.earlierArrivalComparator),
    ];
  }

  static groupDirectFlights(flights) {
    const flightsDirect = flights.filter((flight) => flight.legs.length === 1),
      otherFlights = flights.filter((flight) => flight.legs.length > 1);
    return [
      ...this.groupLowerShipmentCost(flightsDirect),
      ...this.groupLowerShipmentCost(otherFlights),
    ];
  }

  static sortByArrivalDate(flights) {
    let sortedByArrival = [];
    const groupedByArrival = this.groupFlightsByArrivalDate(flights);
    groupedByArrival.forEach((group) => {
      sortedByArrival = sortedByArrival.concat(
        this.getSortedFlightsByGreenest(group.flights),
      );
    });
    return sortedByArrival;
  }

  static groupFlightsByRate(flightInputs: FlightInput[]) {
    const groupedByRate = [];
    flightInputs.forEach((flight) => {
      const allInRate = flight.rates ? flight.rates[0].allInRate : 0,
        existingGroup = groupedByRate.find((group) => group.rate === allInRate);
      if (existingGroup) {
        existingGroup.flights.push(flight);
      } else {
        groupedByRate.push({
          rate: allInRate,
          flights: [flight],
        });
      }
    });

    return groupedByRate.sort((a, b) => a.rate - b.rate);
  }

  static groupFlightsByArrivalDate(flightInputs: FlightInput[]) {
    const groupedByArrival = [];
    flightInputs.forEach((flight) => {
      const arrivalTime = new Date(
          flight.legs[flight.legs.length - 1].arrivalTime,
        ),
        existingGroup = groupedByArrival.find(
          (group) => group.arrivalDate === +arrivalTime,
        );
      if (existingGroup) {
        existingGroup.flights.push(flight);
      } else {
        groupedByArrival.push({
          arrivalDate: +arrivalTime,
          flights: [flight],
        });
      }
    });

    return groupedByArrival.sort((a, b) => a.arrivalDate - b.arrivalDate);
  }

  static getCO2(legs: Leg[]): string {
    return legs
      ?.filter((path) => path.co2)
      .map((path) => Math.ceil(path.co2.value))
      .reduce((sum, current) => sum + current, 0)
      .toFixed();
  }

  static getFlightDaysToView(
    shipmentDate: Date,
    flights: FlightInput[],
    searchDate: Date,
    nav: 'prev' | 'next',
  ) {
    let days = 3;
    const minFlightDate = moment(shipmentDate).startOf('day'),
      maxFlightDate = moment(this.getMaxArrivalDate(flights)),
      tempSearchDate = moment(
        nav === 'next' ? this.addDaysToDate(searchDate, 4) : searchDate,
      ).startOf('day'),
      newSearchDate = moment(
        this.addDaysToDate(
          tempSearchDate.toDate(),
          nav === 'next' ? days : -days,
        ),
      ).startOf('day');

    if (nav === 'prev') {
      if (newSearchDate < minFlightDate) {
        days = moment(tempSearchDate).diff(minFlightDate, 'days');
      }
    } else if (nav === 'next') {
      if (newSearchDate > maxFlightDate) {
        days = moment(maxFlightDate).diff(tempSearchDate, 'days');
      }
    }

    return days;
  }

  static addDaysToDate(date: Date, days: number): Date {
    return moment(date).add(days, 'days').toDate();
  }

  static getMaxArrivalDate(flights): Date {
    const maxArrivalDate = new Date(
      Math.max.apply(
        null,
        flights.map((flight) => new Date(flight.arrivalTime.split('T')[0])),
      ),
    );
    return moment(maxArrivalDate).startOf('day').toDate();
  }

  static getFormattedRateName(selectedRate): string {
    let rateName = '';
    if (selectedRate) {
      if (!!selectedRate.rateId) {
        if (!!selectedRate.airlineProdCode) {
          rateName = selectedRate.airlineProdCode + '_';
        }
        if (!!selectedRate.type && selectedRate.type === 'Express') {
          rateName = rateName + selectedRate.type + '_' + selectedRate.name;
        } else {
          rateName = rateName + selectedRate.type;
        }
        rateName = rateName + ' Rate';
        return rateName;
      }
      if (selectedRate.formattedName) {
        rateName = selectedRate.formattedName;
      }

      if (selectedRate.name) {
        if (rateName.length) {
          rateName += ' ';
        }
        rateName += selectedRate.name;
      }
    }
    return rateName;
  }

  static computeFlights(
    data: FlightInput[],
    flightDays,
    airlineCompanies,
  ): FlightInput[] {
    return data
      .filter((item) => {
        const fromDate = moment(new Date(flightDays[0]))
            .startOf('day')
            .toDate(),
          toDate = moment(new Date(flightDays[flightDays.length - 1]))
            .endOf('day')
            .toDate(),
          departureDate = +new Date(
            new Date(new Date(item.departureTime).setHours(0)).setMinutes(0),
          ).setSeconds(0);
        return +fromDate <= departureDate && departureDate <= +toDate;
      })
      .map((item) => {
        let airlineCode = item.airlineCode;
        const specialCase = SPECIAL_AIRLINE_CASES.find(
          (airline) => airline.id === airlineCode,
        );
        if (specialCase) {
          airlineCode = specialCase.group;
        }
        item.airlineCompany = this.getAirlineByCode(
          airlineCode,
          airlineCompanies,
        );
        return item;
      })
      .filter((item) => {
        if (!item.airlineCompany) {
          console.warn(`Unknown airline ${item.airlineCode}`);
        }
        return item.airlineCompany;
      });
  }

  static getAirlineByCode(code: string, airlineCompanies) {
    return airlineCompanies.find((ac) => ac.airlineCompanyCode === code);
  }

  static getRateName(rate: FlightRate): string {
    switch (rate.type) {
      case 'market':
        return $localize`:@@global.market-rate:Market Rate`;
      case 'live':
        return $localize`:@@global.live-rate:Live Rate`;
      case 'fresh':
        return $localize`:@@global.fresh-rate:Fresh Rate`;
      case 'express':
        return $localize`:@@global.express-rate:Express Rate`;
      case 'contract':
        return $localize`:@@global.contract-rate:Contract Rate`;
      case 'promo':
        return $localize`:@@global.promo-rate:Promo Rate`;
      default:
        return '';
    }
  }

  static prepareAirportName(airport: AirportLight): string {
    if (!airport) {
      return '';
    }
    const airportDetails = [];
    if (airport.airportName === 'International') {
      airportDetails.push(`${airport.cityName}`);
    }
    airportDetails.push(`${airport.airportName}`);
    airportDetails.push(`(${airport?.airportCode})`);
    return airportDetails.join(' ');
  }

  static isQuotable(
    flightDetail: CustomFlightDetails,
    allowedCraftTypes: AircraftTypes[],
  ): boolean {
    return (
      (flightDetail.features?.mailQuotable == null ||
        flightDetail.features?.mailQuotable ||
        flightDetail.features?.equote) &&
      flightDetail.legs.every((leg) =>
        allowedCraftTypes?.includes(
          leg.bodyType?.toLowerCase() as AircraftTypes,
        ),
      )
    );
  }

  static getAllowedAircraftTypes(
    searchTemplate: SearchTemplate,
  ): AircraftTypes[] {
    if (this.isFreighterOnly(searchTemplate)) {
      return [AircraftTypes.FREIGHTER, AircraftTypes.SURFACE];
    } else if (this.isFreighterAndWidebody(searchTemplate)) {
      return [
        AircraftTypes.FREIGHTER,
        AircraftTypes.WIDEBODY,
        AircraftTypes.SURFACE,
      ];
    }
    return [
      AircraftTypes.FREIGHTER,
      AircraftTypes.WIDEBODY,
      AircraftTypes.NARROWBODY,
      AircraftTypes.SURFACE,
    ];
  }

  static isFreighterOnly(searchTemplate: SearchTemplate): boolean {
    const items = searchTemplate?.searchItemTemplates;
    return (
      items?.filter(
        (quoteItem) =>
          quoteItem.length >
            this.convertMeasure(WIDEBODY_AIRCRAFT_MAX_LENGTH, searchTemplate) ||
          quoteItem.width >
            this.convertMeasure(WIDEBODY_AIRCRAFT_MAX_WIDTH, searchTemplate) ||
          quoteItem.height >
            this.convertMeasure(WIDEBODY_AIRCRAFT_MAX_HEIGHT, searchTemplate) ||
          quoteItem.weight > WIDEBODY_AIRCRAFT_MAX_WEIGHT,
      ).length > 0
    );
  }

  static isFreighterAndWidebody(searchTemplate: SearchTemplate): boolean {
    const items = searchTemplate?.searchItemTemplates;
    return (
      items?.filter(
        (quoteItem) =>
          quoteItem.length >
            this.convertMeasure(NARROW_AIRCRAFT_MAX_LENGTH, searchTemplate) ||
          quoteItem.width >
            this.convertMeasure(NARROW_AIRCRAFT_MAX_WIDTH, searchTemplate) ||
          quoteItem.height >
            this.convertMeasure(NARROW_AIRCRAFT_MAX_HEIGHT, searchTemplate) ||
          quoteItem.weight > NARROW_AIRCRAFT_MAX_WEIGHT,
      ).length > 0
    );
  }

  static convertMeasure(value: number, searchTemplate: SearchTemplate): number {
    return MeasurementUtil.convertMeasure(
      value,
      MeasurementSystemEnum.METRIC,
      searchTemplate.measurementSystem,
    );
  }
}
