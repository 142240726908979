import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";

@Component({
  "selector": "kt-advanced-datepicker",
  "templateUrl": "./advanced-datepicker.component.html",
  "styleUrls": ["./advanced-datepicker.component.scss"],
})
export class AdvancedDatepickerComponent
  implements OnInit, ControlValueAccessor
{
  @ViewChild("dp") dp: MatDatepicker<Date>;
  @ViewChild("input") input: ElementRef;
  @Input() label: string;
  @Input() minDate = new Date();
  @Input() maxDate: Date;
  @Input() viewOnly: boolean;
  @Input() floatLabel: boolean;
  @Input() labelCenter = true;
  "MMM dd";
  @Input() displayFormat = "MMM dd";

  value: Date = new Date();

  onChange = (value: Date) => {};
  onTouched = () => {};

  constructor (
    @Self()
    @Optional()
    public control: NgControl,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit (): void {}

  open (dp: MatDatepicker<Date>) {
    dp.open();
  }

  close (e) {
    this.onChange(this.value);
  }

  registerOnChange (onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched (onTouched: any) {
    this.onTouched = onTouched;
  }

  writeValue (value: Date) {
    this.value = value;
    this.onChange(value);
  }

  get showError (): boolean {
    if (!this.control) {
      return false;
    }
    const { dirty, touched } = this.control;
    return this.invalid ? dirty || touched : false;
  }

  get invalid (): boolean {
    return this.control?.touched ? this.control.invalid : false;
  }

  get isFocused (): boolean {
    return (
      this.input?.nativeElement === document.activeElement || this.dp?.opened
    );
  }
}
