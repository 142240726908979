import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { API } from "@aws-amplify/api";
import { updateNotification } from "../../graphql/mutation/notification.mutation";
import { getNotifications } from "../../graphql/query/notification.query";
import { subscribeNotifications } from "../../graphql/subscription/notification.subscription";
import { GraphqlUtil } from "../../utils/graphql.util";
import { IAppNotification } from "../../model/app-notification.model";

@Injectable({
  "providedIn": "root",
})
export class NotificationService {
  async getNotifications (user: string): Promise<{
    data: {
      getNotifications: IAppNotification[];
    };
  }> {
    return API.graphql({
      "query": getNotifications,
      "variables": { user },
      "authMode": GraphqlUtil.getAuthMode(false),
    }) as any;
  }

  async updateNotification (data: {
    user: string;
    timestamp: number;
    read: boolean;
    seen: boolean;
  }): Promise<{
    data: {
      setNotification: IAppNotification;
    };
  }> {
    return API.graphql({
      "query": updateNotification,
      "variables": data,
      "authMode": GraphqlUtil.getAuthMode(false),
    }) as any;
  }

  subscribeNotifications (user: string): Observable<{
    value: {
      data: { Notification: IAppNotification };
    };
  }> {
    return API.graphql({
      "query": subscribeNotifications,
      "variables": { user },
      "authMode": GraphqlUtil.getAuthMode(false),
    }) as any;
  }
}
