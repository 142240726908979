import {
  AuthenticationMethodEnum,
  IAirlineUser,
  IAirport,
  IOfficeAccess,
  IUserContactInfo,
  MeasurementSystemEnum,
  UserStatusEnum,
  UserTypeEnum,
} from "@cai-services";
import { Airport } from "./region-airport.model";
import { Company } from "./company.model";
import { Country } from "./country.model";

export class AirlineUser implements IAirlineUser {
  userId: number;
  type: UserTypeEnum;
  authenticationMethod: AuthenticationMethodEnum;
  userName: string;
  userEmail: string;
  lastName: string;
  comments: string;
  firstName: string;
  contact: IUserContactInfo;
  userStatus: UserStatusEnum;
  branchName: string;
  branchCode: string;
  logoPath: string;
  sita: string;
  smtp: string;
  pima: string;
  defaultCurrency: string;
  company: Company;
  country: Country;
  linkedInProfile: string;
  measurementSystem: MeasurementSystemEnum;
  defaultSearchDay: number;
  defaultTimezone: string;
  language: string;
  originAirport: IAirport;
  officeAccesses: IOfficeAccess[];
  createdBy: string;
  createdAt: Date;
  updatedBy: string;
  updatedAt: Date;
  cassCode: string;
  class = "co.cargoai.core.entity.AirlineUser";
  createdDomain: string;

  constructor (originAirport?: Airport) {
    this.originAirport = originAirport;
    this.company = new Company();
  }
}
