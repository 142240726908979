// Anglar
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
// Layout Directives
// Services
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { AutocompleteSelectComponent } from "./_base/general/autocomplete-select/autocomplete-select.component";
import { ErrorComponent } from "./_base/general/error/error.component";
import { InputSelectComponent } from "./_base/general/input-select/input-select.component";
import {
  AircraftColorPipe,
  AwbFormatter,
  Co2FormatPipe,
  CommentsFormatPipe,
  ContentAnimateDirective,
  DayDiffer,
  HeaderDirective,
  IataCassFormatterPipe,
  MenuDirective,
  NgbTimeFormatPipe,
  NumbersAndHyphenOnlyDirective,
  NumbersOnlyDirective,
  NumberUnitPipe,
  OffcanvasDirective,
  PhoneFormatterPipe,
  ScrollTopDirective,
  ShipmentDetail,
  StickyDirective,
  StringReplacePipe,
  TimeDiffer,
  TimeDurationPipe,
  TimeFormatPipe,
  DateNoTimezonePipe,
  TimeFromStartDifferPipe,
  MeasureFormatter,
  VolumeFormatter,
  WeightFormatter,
  TwoDigitNumberDirective,
  NoNumbersDirective,
  ReplaceAccentsDirective,
  TrimOnValueChangeDirective,
} from "./_base/layout";

@NgModule({
  "imports": [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
  ],
  "declarations": [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    MenuDirective,
    ContentAnimateDirective,
    StickyDirective,
    NumbersOnlyDirective,
    NumbersAndHyphenOnlyDirective,
    TwoDigitNumberDirective,
    NoNumbersDirective,
    ReplaceAccentsDirective,
    TrimOnValueChangeDirective,
    // pipes
    ShipmentDetail,
    DayDiffer,
    TimeFromStartDifferPipe,
    TimeDiffer,
    AircraftColorPipe,
    AwbFormatter,
    IataCassFormatterPipe,
    PhoneFormatterPipe,
    MeasureFormatter,
    VolumeFormatter,
    WeightFormatter,
    ShipmentDetail,
    TimeFormatPipe,
    DateNoTimezonePipe,
    TimeDurationPipe,
    CommentsFormatPipe,
    Co2FormatPipe,
    NgbTimeFormatPipe,
    StringReplacePipe,
    NumberUnitPipe,
    ErrorComponent,
    AutocompleteSelectComponent,
    InputSelectComponent,
  ],
  "exports": [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    IataCassFormatterPipe,
    PhoneFormatterPipe,
    MenuDirective,
    ContentAnimateDirective,
    StickyDirective,
    NumbersOnlyDirective,
    NumbersAndHyphenOnlyDirective,
    TwoDigitNumberDirective,
    NoNumbersDirective,
    ReplaceAccentsDirective,
    TrimOnValueChangeDirective,
    // pipes
    ShipmentDetail,
    DayDiffer,
    TimeFromStartDifferPipe,
    TimeDiffer,
    AircraftColorPipe,
    AwbFormatter,
    MeasureFormatter,
    VolumeFormatter,
    WeightFormatter,
    ShipmentDetail,
    TimeFormatPipe,
    DateNoTimezonePipe,
    TimeDurationPipe,
    CommentsFormatPipe,
    Co2FormatPipe,
    NgbTimeFormatPipe,
    StringReplacePipe,
    NumberUnitPipe,
    ErrorComponent,
    AutocompleteSelectComponent,
    InputSelectComponent,
  ],
  "providers": [],
})
export class CoreModule {}
