import { Pipe, PipeTransform } from "@angular/core";
import { MeasurementSystemEnum } from "@cai-services";
import { MeasurementUtil } from "../../crud/utils/measurement.util";

@Pipe({
  "name": "co2",
})
export class Co2FormatPipe implements PipeTransform {
  transform (
    flights: any,
    measurementSystem = MeasurementSystemEnum.METRIC,
    onlyCo2?: number,
  ): string {
    if (!flights) {
      return "";
    }
    let totalCo2: number;
    if (onlyCo2) {
      totalCo2 = Math.ceil(onlyCo2);
    } else {
      totalCo2 = Math.ceil(
        flights.reduce((acc: any, item: any) => {
          acc += item?.co2 ? item?.co2?.value || item?.co2 : 0;
          return acc;
        }, 0),
      );
    }
    return totalCo2
      ? MeasurementUtil.convertVolume(
          totalCo2,
          MeasurementSystemEnum.METRIC,
          measurementSystem,
        ).toFixed()
      : "-";
  }
}
