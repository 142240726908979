import { Injectable } from '@angular/core';
import { API } from '@aws-amplify/api';
import { IQuoteRequestAirline } from '@cai-services';
import { MetadataTypeEnum } from '../../enum/metadata-type.enum';
import { QuoteRequestStatusEnum } from '../../enum/quote-request-status.enum';
import { QuoteStatusEnum } from '../../enum/quote-status.enum';
import { IPaginationResults } from '../../model/pagination-result.model';
import { IPresignedURL } from '../../model/presigned-url.model';
import { IQuoteRequestMetadata } from '../../model/quote-request-metadata.model';
import { IQuoteRequestWithErrors } from '../../model/quote-request-with-errors.model';
import { IQuoteRequest } from '../../model/quote-request.model';
import { ErrorUtil } from '../../utils/error.util';
import { API_CORE } from '../../constant/api-core.const';

@Injectable({
  providedIn: 'root',
})
export class QuoteRequestService {
  async createQuoteRequest(
    request: IQuoteRequest,
  ): Promise<IQuoteRequestWithErrors> {
    return API.post(API_CORE.name, API_CORE.URLs.QuoteRequest.QuoteRequests, {
      body: request,
    }).catch(ErrorUtil.handleError);
  }

  async createQuoteRequestAirline(
    request: IQuoteRequestAirline,
  ): Promise<IQuoteRequestWithErrors> {
    return API.post(
      API_CORE.name,
      API_CORE.URLs.QuoteRequest.QuoteRequestsAirline,
      {
        body: request,
      },
    ).catch(ErrorUtil.handleError);
  }

  async partialUpdateQuoteRequest(
    request: IQuoteRequest,
  ): Promise<IQuoteRequest> {
    const url = API_CORE.URLs.QuoteRequest.QuoteRequestById.replace(
      '{quoteRequestId}',
      request.quoteRequestId.toString(),
    );
    return API.patch(API_CORE.name, url, {
      body: request,
    });
  }

  async updateQuoteRequestRating(
    request: IQuoteRequest,
  ): Promise<IQuoteRequest> {
    const url = API_CORE.URLs.QuoteRequest.QuoteRequestById.replace(
      '{quoteRequestId}',
      request.quoteRequestId.toString(),
    );
    return API.put(API_CORE.name, url, {
      body: request,
    });
  }

  async generateUploadFileUrlsForQuoteRequest(
    request: IQuoteRequest,
  ): Promise<IPresignedURL[]> {
    return API.post(API_CORE.name, API_CORE.URLs.QuoteRequest.Attachment, {
      body: request,
    }).catch(ErrorUtil.handleError);
  }

  async getQuoteRequests(
    page: number,
    size: number,
    sort: string,
    inQuoteStatus?: QuoteStatusEnum[],
    inStatus?: QuoteRequestStatusEnum[],
    fromDate?: Date,
    toDate?: Date,
    origin?: string,
    destination?: string,
    airline?: string,
    commodity?: string,
    frequentFilter?: string,
  ): Promise<IPaginationResults<IQuoteRequest>> {
    const extraOptions = {
      queryStringParameters: {
        page,
        size,
        sort,
      },
    } as any;
    if (inQuoteStatus) {
      extraOptions.queryStringParameters.inQuoteStatus =
        inQuoteStatus.join(',');
    }
    if (inStatus) {
      extraOptions.queryStringParameters.inStatus = inStatus.join(',');
    }
    if (fromDate) {
      extraOptions.queryStringParameters.fromDate = fromDate;
    }
    if (toDate) {
      extraOptions.queryStringParameters.toDate = toDate;
    }
    if (origin) {
      extraOptions.queryStringParameters.origin = origin;
    }
    if (destination) {
      extraOptions.queryStringParameters.destination = destination;
    }
    if (airline) {
      extraOptions.queryStringParameters.airline = airline;
    }
    if (commodity) {
      extraOptions.queryStringParameters.commodity = commodity;
    }
    if (frequentFilter) {
      extraOptions.queryStringParameters.frequentFilter = frequentFilter;
    }
    return API.get(
      API_CORE.name,
      API_CORE.URLs.QuoteRequest.QuoteRequests,
      extraOptions,
    ).catch(ErrorUtil.handleError);
  }

  async getQuoteRequestMetadata(
    type: MetadataTypeEnum,
    fromDate?: Date,
    toDate?: Date,
    origin?: string[],
    destination?: string[],
    airline?: string[],
    commodity?: string[],
    search?: string,
  ): Promise<IQuoteRequestMetadata> {
    const extraOptions = {
      queryStringParameters: {
        type,
      } as any,
    };
    if (fromDate) {
      extraOptions.queryStringParameters.fromDate = fromDate;
    }
    if (toDate) {
      extraOptions.queryStringParameters.toDate = toDate;
    }
    if (origin) {
      extraOptions.queryStringParameters.origin = origin.join(',');
    }
    if (destination) {
      extraOptions.queryStringParameters.destination = destination.join(',');
    }
    if (airline) {
      extraOptions.queryStringParameters.airline = airline.join(',');
    }
    if (commodity) {
      extraOptions.queryStringParameters.commodity = commodity.join(',');
    }
    if (search) {
      extraOptions.queryStringParameters.search = search;
    }
    return API.get(
      API_CORE.name,
      API_CORE.URLs.QuoteRequest.Metadata,
      extraOptions,
    ).catch(ErrorUtil.handleError);
  }
}
