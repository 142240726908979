export const getNotifications = `query getNotifications(
  $user: String!
) {
  getNotifications(user: $user) {
    title
    description
    type
    link
    user
    timestamp
    read
    seen
    parameters
  }
}`;
