import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { QuoteRequestService } from "@cai-services";
import {
  QuoteRequest,
  QuoteRequestWithErrors,
} from "../_models/quote-request.model";
import { PresignedURL } from "../_models/presigned-url.model";
import { QuoteRequestAirline } from "../_models/quote-request-airline.model";

@Injectable({
  "providedIn": "root",
})
export class QuotecartService {
  constructor (private readonly quoteRequestService: QuoteRequestService) {}

  public createNewQuoteRequest (
    newQuoteRequest: QuoteRequest
  ): Observable<QuoteRequestWithErrors> {
    return from(
      this.quoteRequestService.createQuoteRequest(
        newQuoteRequest
      ) as Promise<QuoteRequestWithErrors>
    );
  }

  public createNewQuoteRequestAirline (
    newQuoteRequest: QuoteRequestAirline
  ): Observable<QuoteRequestWithErrors> {
    return from(
      this.quoteRequestService.createQuoteRequestAirline(
        newQuoteRequest
      ) as Promise<QuoteRequestWithErrors>
    );
  }

  public generateUploadUrlsForQuoteRequest (
    quoteRequest: QuoteRequest
  ): Promise<PresignedURL[]> {
    if (!quoteRequest) {
      throw new Error(
        "Required parameter body was null or undefined when calling generateUploadUrlsForQuoteRequest."
      );
    }
    return this.quoteRequestService.generateUploadFileUrlsForQuoteRequest(
      quoteRequest
    );
  }
}
