import { RequirementCodeEnum } from "@cai-services";
import { RequirementActiveContainerComponent } from "../components/requirements/active-container/requirement-active-container.component";
import { RequirementCargoAircraftOnlyComponent } from "../components/requirements/cargo-aircraft-only/requirement-cargo-aircraft-only.component";
import { RequirementCoolingPackagingComponent } from "../components/requirements/cooling-packaging/requirement-cooling-packaging.component";
import { RequirementDangerousGoodTableComponent } from "../components/requirements/dangerous-good-table/requirement-dangerous-good-table.component";
import { RequirementPharmaDangerousGoodsComponent } from "../components/requirements/pharma-dangerous-goods/requirement-pharma-dangerous-goods.component";
import { RequirementTemperatureFullComponent } from "../components/requirements/temperature-full/requirement-temperature-full.component";
import { RequirementTemperatureLivingComponent } from "../components/requirements/temperature-living/requirement-temperature-living.component";
import { RequirementTemperatureTrackingBatteryComponent } from "../components/requirements/temperature-tracking-battery/requirement-temperature-tracking-battery.component";
import { RequirementTemperatureTrackingDeviceComponent } from "../components/requirements/temperature-tracking-device/requirement-temperature-tracking-device.component";
import { RequirementTemperatureTrackingNumberComponent } from "../components/requirements/temperature-tracking-number/requirement-temperature-tracking-number.component";
import { RequirementComponent } from "../core/_models/requirement.model";
import { ActiveContainerConfig } from "./requirements/active-container.const";
import { CargoAircraftOnlyConfig } from "./requirements/cargo-aircraft-only.const";
import { CoolingPackagingConfig } from "./requirements/cooling-packaging.const";
import { DangerousGoodTableConfig } from "./requirements/dangerous-goods-table.const";
import { DiplomaticConfig } from "./requirements/diplomatic.const";
import { ExpressConfig } from "./requirements/express.const";
import { KnownShipperConfig } from "./requirements/known-shipper.const";
import { LithiumBatteryDetailConfig } from "./requirements/lithium-battery-detail.const";
import { LithiumBatteryConfig } from "./requirements/lithium-battery.const";
import { PharmaDangerousGoodsConfig } from "./requirements/pharma-dangerous-goods.const";
import { ScreenedConfig } from "./requirements/screened.const";
import { TemperatureFullConfig } from "./requirements/temperature-full.const";
import { TemperatureLivingConfig } from "./requirements/temperature-living.const";
import { TemperatureTrackingBatteryConfig } from "./requirements/temperature-tracking-battery.const";
import { TemperatureTrackingDeviceConfig } from "./requirements/temperature-tracking-device.const";

const CSS_INLINE_BLOCK = "inline-block";

//Do not change the order
export const RequirementsCmp: RequirementComponent[] = [
  {
    "code": RequirementCodeEnum.TEMPERATURE_FULL,
    "type": null,
    "answer": null,
    "defaultAnswer": null,
    "answers": [],
    "config": TemperatureFullConfig,
    "component": RequirementTemperatureFullComponent,
    "isDynamicChild": false,
    "isDynamic": true,
    "isSpecial": false,
    "isSpecialSHC": false,
    "isEnabled": true,
  },
  {
    "code": RequirementCodeEnum.TEMPERATURE_LIVING,
    "type": null,
    "answer": null,
    "defaultAnswer": null,
    "answers": [],
    "config": TemperatureLivingConfig,
    "component": RequirementTemperatureLivingComponent,
    "isDynamicChild": false,
    "isDynamic": true,
    "isSpecial": false,
    "isSpecialSHC": false,
    "isEnabled": true,
  },
  {
    "code": RequirementCodeEnum.COOLING_PACKAGING,
    "type": null,
    "answer": null,
    "defaultAnswer": null,
    "answers": [],
    "config": CoolingPackagingConfig,
    "component": RequirementCoolingPackagingComponent,
    "isDynamicChild": false,
    "isDynamic": true,
    "isSpecial": false,
    "isSpecialSHC": false,
    "isEnabled": true,
  },
  {
    "code": RequirementCodeEnum.TEMPERATURE_TRACKING_DEVICE,
    "type": null,
    "answer": null,
    "defaultAnswer": null,
    "answers": [],
    "config": TemperatureTrackingDeviceConfig,
    "component": RequirementTemperatureTrackingDeviceComponent,
    "isDynamicChild": false,
    "isDynamic": true,
    "isSpecial": false,
    "isSpecialSHC": false,
    "isEnabled": true,
    "customStyles": {
      "display": CSS_INLINE_BLOCK,
      "width": "60%",
    },
  },
  {
    "code": RequirementCodeEnum.TRACKING_DEVICE_BATTERY,
    "type": null,
    "answer": null,
    "defaultAnswer": null,
    "answers": [],
    "config": TemperatureTrackingBatteryConfig,
    "component": RequirementTemperatureTrackingBatteryComponent,
    "isDynamicChild": false,
    "isDynamic": true,
    "isSpecial": false,
    "isSpecialSHC": false,
    "isEnabled": false,
  },
  {
    "code": RequirementCodeEnum.TRACKING_DEVICE_NUMBER,
    "type": null,
    "answer": null,
    "defaultAnswer": null,
    "answers": [],
    "config": TemperatureTrackingDeviceConfig,
    "component": RequirementTemperatureTrackingNumberComponent,
    "isDynamicChild": true,
    "isDynamic": false,
    "isSpecial": false,
    "isSpecialSHC": false,
    "isEnabled": true,
  },
  {
    "code": RequirementCodeEnum.PHARMA_DANGEROUS_GOOD,
    "type": null,
    "answer": null,
    "defaultAnswer": null,
    "answers": [],
    "config": PharmaDangerousGoodsConfig,
    "component": RequirementPharmaDangerousGoodsComponent,
    "isDynamicChild": false,
    "isDynamic": true,
    "isSpecial": false,
    "isSpecialSHC": false,
    "isEnabled": true,
    "customStyles": {
      "display": CSS_INLINE_BLOCK,
      "width": "60%",
    },
  },
  {
    "code": RequirementCodeEnum.NEED_ACTIVE_CONTAINER,
    "type": null,
    "answer": null,
    "defaultAnswer": null,
    "answers": [],
    "config": ActiveContainerConfig,
    "component": RequirementActiveContainerComponent,
    "isDynamicChild": false,
    "isDynamic": true,
    "isSpecial": false,
    "isSpecialSHC": false,
    "isEnabled": true,
    "customStyles": {
      "display": CSS_INLINE_BLOCK,
      "width": "40%",
    },
  },
  {
    "code": RequirementCodeEnum.DANGEROUS_GOOD_TABLE,
    "type": null,
    "answer": null,
    "defaultAnswer": null,
    "answers": [],
    "config": null,
    "tableConfig": DangerousGoodTableConfig,
    "component": RequirementDangerousGoodTableComponent,
    "isDynamicChild": false,
    "isDynamic": true,
    "isSpecial": false,
    "isSpecialSHC": false,
    "isEnabled": true,
  },
  {
    "code": RequirementCodeEnum.CARGO_AIRCRAFT_ONLY,
    "type": null,
    "answer": null,
    "defaultAnswer": null,
    "answers": [],
    "config": CargoAircraftOnlyConfig,
    "component": RequirementCargoAircraftOnlyComponent,
    "isDynamicChild": false,
    "isDynamic": true,
    "isSpecial": true,
    "isSpecialSHC": false,
    "isEnabled": true,
  },
  {
    "code": RequirementCodeEnum.CONTAIN_LITHIUM_BATTERY,
    "type": null,
    "answer": null,
    "defaultAnswer": null,
    "answers": [],
    "config": LithiumBatteryConfig,
    "component": null,
    "isDynamicChild": false,
    "isDynamic": false,
    "isSpecial": true,
    "isSpecialSHC": false,
    "isEnabled": true,
  },
  {
    "code": RequirementCodeEnum.LITHIUM_BATTERY_DETAIL,
    "type": null,
    "answer": null,
    "defaultAnswer": null,
    "answers": [],
    "config": LithiumBatteryDetailConfig,
    "component": null,
    "isDynamicChild": true,
    "isDynamic": false,
    "isSpecial": true,
    "isSpecialSHC": false,
    "isEnabled": true,
  },
  {
    "code": RequirementCodeEnum.DIPLOMATIC,
    "type": null,
    "answer": null,
    "defaultAnswer": null,
    "answers": [],
    "config": DiplomaticConfig,
    "component": null,
    "isDynamicChild": false,
    "isDynamic": false,
    "isSpecial": true,
    "isSpecialSHC": true,
    "isEnabled": true,
  },
  {
    "code": RequirementCodeEnum.EXPRESS,
    "type": null,
    "answer": null,
    "defaultAnswer": null,
    "answers": [],
    "config": ExpressConfig,
    "component": null,
    "isDynamicChild": false,
    "isDynamic": false,
    "isSpecial": true,
    "isSpecialSHC": true,
    "isEnabled": true,
  },
  {
    "code": RequirementCodeEnum.SCREENED,
    "type": null,
    "answer": null,
    "defaultAnswer": null,
    "answers": [],
    "config": ScreenedConfig,
    "component": null,
    "isDynamicChild": false,
    "isDynamic": false,
    "isSpecial": true,
    "isSpecialSHC": true,
    "isEnabled": true,
  },
  {
    "code": RequirementCodeEnum.KNOWN_SHIPPER,
    "type": null,
    "answer": null,
    "defaultAnswer": null,
    "answers": [],
    "config": KnownShipperConfig,
    "component": null,
    "isDynamicChild": false,
    "isDynamic": false,
    "isSpecial": true,
    "isSpecialSHC": true,
    "isEnabled": true,
  },
];
