import { GeneralCondition } from "./general-condition.model";

export class GeneralConditionAttachment {
  id: number;
  fileName: string;
  fileType: string;
  fileSize: number;
  generalCondition: GeneralCondition;

  constructor (
    fileName: string,
    fileType: string,
    fileSize: number,
    generalCondition?: GeneralCondition,
    id?: number
  ) {
    this.fileName = fileName;
    this.fileType = fileType;
    this.fileSize = fileSize;
    this.generalCondition = generalCondition;
    this.id = id;
  }
}
