<div class="basic-popup">
  <div class="header">
    <span [style.color]="titleColor">{{ header }}</span>
    <span class="icon-close" (click)="cancel()"
      ><svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z"
          [attr.fill]="closeButtonColor ? closeButtonColor : '#679EC6'"
        />
      </svg>
    </span>
  </div>
  <div class="body" [innerHTML]="body"></div>
  <div class="footer">
    <span>{{ footer }}</span>
  </div>
  <div footer class="basic-popup__footer pb-2">
    <span
      i18n="@@global.cancel"
      class="cancel-button"
      (click)="cancel()"
      *ngIf="!hideCancel"
      [style.color]="secondaryButtonColor"
      >cancel</span
    >
    <button
      class="primary-button"
      (click)="handleClick()"
      [style.background-color]="primaryButtonColor"
    >
      {{ primaryButtonText }}
    </button>
  </div>
</div>
