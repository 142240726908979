import { RequirementConfig } from "../../core/_models/requirement-config.model";

export enum PharmaDangerousGoodsAnswer {
  NO = "default_no",
  YES = "default_yes",
}

export const PharmaDangerousGoodsConfig: RequirementConfig = {
  "summaryLabel": "Dangerous Goods :",
  "summaryColor": "#22B7B4",
  "question": "Contains dangerous goods ?",
  "options": [
    {
      "label": "No",
      "value": PharmaDangerousGoodsAnswer.NO,
    },
    {
      "label": "Yes",
      "value": PharmaDangerousGoodsAnswer.YES,
    },
  ],
};
