<div style="clear: both">
  <div class="input-group mb-3">
    <input
      type="text"
      disabled
      class="form-control"
      [attr.placeholder]="label"
      [value]="
        isSelectAll && elements?.length > 1
          ? 'All users'
          : chosenValue
          ? this.formatInput(chosenValue)
          : ''
      "
      onfocus="this.select();"
    />
    <div class="input-group-append">
      <div class="">
        <button
          #toggleButton
          class="btn btn-primary"
          type="button"
          [ngStyle]="{
            display: elements && elements.length <= 1 ? 'none' : ''
          }"
          (click)="toggleDropdownMenu()"
        >
          <em
            [ngClass]="{
              'fa-chevron-down': !displayMenu,
              'fa-chevron-up': displayMenu
            }"
            class="fa input-caret"
          ></em>
        </button>
      </div>
      <ul
        #menu
        class="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdownMenu"
        [style.display]="displayMenu ? 'block' : 'none'"
      >
        <li *ngIf="elements && elements.length > 1" class="option-container">
          <div class="option-checkbox">
            <label class="kt-checkbox kt-checkbox--brand">
              <input
                type="checkbox"
                [checked]="isSelectAll"
                (change)="onSelectAll($event)"
              />
              &nbsp; <span></span>
            </label>
          </div>
          <div class="option-main">
            <div class="option-heading">All users</div>
          </div>
        </li>
        <li
          *ngFor="let element of elements; let i = index"
          class="option-container"
        >
          <div class="option-checkbox">
            <label class="kt-checkbox kt-checkbox--brand">
              <input
                type="checkbox"
                (click)="chooseElement(i)"
                [checked]="element.checked"
              />
              &nbsp; <span></span>
            </label>
          </div>
          <div class="option-main" (click)="chooseElement(i)">
            <div class="option-heading">{{ element.heading }}</div>
            <div class="option-value">{{ element.value.userEmail }}</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="input-group-append" *ngIf="!disableAdd">
      <div class="">
        <button class="btn btn-primary" type="button" (click)="onAddClicked()">
          <em class="fa fa-plus"></em>
        </button>
      </div>
    </div>
  </div>
</div>
