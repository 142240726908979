import { Pipe, PipeTransform } from "@angular/core";

/**
 * Returns string from Array
 */
@Pipe({
  "name": "caiNumberFormatter",
})
export class CaiNumberFormatterPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param value: any
   * @param args: any
   */
  transform (
    value: any,
    optionalDecimal = false,
    decimalPlace = 2,
    skipPrecision = false,
    showZeroTillNextTwo = false,
  ): string {
    if (!value) {
      return value;
    }

    if (value === "-") {
      return value;
    }

    const num = Number(value);

    let result;
    if (num <= 0.01 && !skipPrecision) {
      result = num.toPrecision(1);
    } else {
      result = num.toFixed(decimalPlace);
    }

    if (optionalDecimal) {
      result = (
        Math.round(+result.replace(",", "") * +`1e${decimalPlace}`) /
        +`1e${decimalPlace}`
      ).toString();
    }

    if (showZeroTillNextTwo && /\.\d{0,2}0$/.test(result)) {
      result = result.replace(/(0+)$/g, "");
    }

    return this.addComma(result);
  }

  addComma (numberAsString: string) {
    const separated = numberAsString.split(".");
    return [separated[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","), separated[1]]
      .filter((value) => value)
      .join(".");
  }
}
