import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IRequirementAnswer, RequirementCodeEnum } from "@cai-services";
import { LithiumBatteryDetailConfig } from "../../../constant/requirements/lithium-battery-detail.const";
import { LithiumBatteryAnswer } from "../../../constant/requirements/lithium-battery.const";

const PARENT_COMPONENT = RequirementCodeEnum.CONTAIN_LITHIUM_BATTERY;

@Component({
  "selector": "kt-requirement-lithium-battery-detail",
  "templateUrl": "./requirement-lithium-battery-detail.component.html",
  "styleUrls": ["./requirement-lithium-battery-detail.component.scss"],
})
export class RequirementLithiumBatteryDetailComponent implements OnInit {
  @Input() formGroup: FormGroup = new FormGroup({});
  @Input() defaultAnswer = {} as IRequirementAnswer;
  @Output() onFocus = new EventEmitter();

  code: string = RequirementCodeEnum.LITHIUM_BATTERY_DETAIL;
  description = "";
  selectedAnswer: any = {};
  question: string = LithiumBatteryDetailConfig.question;
  options: any[] = LithiumBatteryDetailConfig.options;
  visible = false;

  constructor (private readonly cdr: ChangeDetectorRef) {}

  ngOnInit (): void {
    this.updateAnswer(this.formGroup.get(this.code).value);
    this.formGroup.get(this.code).valueChanges.subscribe((value) => {
      this.updateAnswer(value);
    });

    const initialValue = this.formGroup.get(PARENT_COMPONENT).value;
    this.updateAnswerBasedOnParent(initialValue);
    this.formGroup.get(PARENT_COMPONENT).valueChanges.subscribe((value) => {
      this.updateAnswerBasedOnParent(value);
    });
    this.cdr.detectChanges();
  }

  selectAnswer (option) {
    if (this.selectedAnswer !== option) {
      this.selectedAnswer = option;
      this.formGroup.get(this.code).setValue(option.value);
      this.onFocus.emit();
    }
  }

  updateAnswerBasedOnParent (value) {
    const visible = value === LithiumBatteryAnswer.YES,
     field = this.formGroup.get(this.code);
    if (visible) {
      this.visible = true;
      if (field.value) {
        this.updateAnswer(field.value);
      } else {
        field.setValue(this.defaultAnswer.code);
      }
    } else {
      this.visible = false;
      field.setValue(null);
    }
  }

  updateAnswer (value) {
    const selected = this.options.find((opt) => opt.value === value);
    if (selected) {
      this.description = selected.description;
      this.selectedAnswer = selected;
      this.cdr.detectChanges();
    }
  }

  get descriptionArray (): string[] {
    if (this.description) {
      const descArr = this.description.split(",");
      return descArr.map((d) => d.trim());
    }
    return [];
  }
}
