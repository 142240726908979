const MEMORY_STORAGE_KEY_PREFIX = "@MemoryStorage:",
 memoryStorage = {};

export class ExpirableStorage {
  static syncPromise = null;

  static setItem (key, value) {
    const now = new Date(),
     expiryMillis = 3600000 * 12, // expired in 12 hours
     item = {
      value,
      "expiry": now.getTime() + expiryMillis,
    };
    localStorage.setItem(MEMORY_STORAGE_KEY_PREFIX + key, JSON.stringify(item));

    memoryStorage[key] = value;
    return memoryStorage[key];
  }

  static getItem (key) {
    return Object.prototype.hasOwnProperty.call(memoryStorage, key)
      ? memoryStorage[key]
      : undefined;
  }

  static removeItem (key) {
    localStorage.removeItem(MEMORY_STORAGE_KEY_PREFIX + key);
    return delete memoryStorage[key];
  }

  static clear () {
    const memoryKeys = this.getMemoryKeys();
    memoryKeys.forEach((key) => {
      this.removeItem(key);
    });
    return memoryStorage;
  }

  static sync () {
    if (!ExpirableStorage.syncPromise) {
      ExpirableStorage.syncPromise = new Promise((res) => {
        const memoryKeys = this.getMemoryKeys();
        memoryKeys.forEach((key) => {
          const memoryKey = key.replace(MEMORY_STORAGE_KEY_PREFIX, "");
          memoryStorage[memoryKey] = this.checkAndRetrieve(key);
        });
        res(memoryStorage);
      });
    }
    return ExpirableStorage.syncPromise;
  }

  private static getMemoryKeys () {
    const keys = Object.keys(localStorage);
    return keys.filter((key) => key.startsWith(MEMORY_STORAGE_KEY_PREFIX));
  }

  private static checkAndRetrieve (key: string) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr),
     now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }
}
