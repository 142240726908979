import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { CaiTooltipDirective } from "@cai-framework";
import { AdvancedBookingCardTypeEnum } from "@cai-services";
import { Observable } from "rxjs";
import { AdvancedBookingsRequestService } from "../../core/_services/advanced-bookings.service";
import { AdvancedBookingActionEnum } from "../../enum/advanced-booking-action.enum";

@Component({
  "selector": "kt-booking-details-menu",
  "templateUrl": "./booking-details-menu.component.html",
  "styleUrls": ["./booking-details-menu.component.scss"],
})
export class BookingDetailsMenuComponent implements OnInit {
  @ViewChildren(CaiTooltipDirective) tooltips: QueryList<CaiTooltipDirective>;
  @Input() actions: AdvancedBookingActionEnum[] = [];
  @Input() index: number;
  @Input() cardType: AdvancedBookingCardTypeEnum;
  @Output() actionSelected = new EventEmitter<AdvancedBookingActionEnum>();
  isCopied: boolean;
  resetCopyFlag: Observable<{
    index?: number;
    cardType?: AdvancedBookingCardTypeEnum;
  }>;

  actionEnum = AdvancedBookingActionEnum;

  constructor (
    private readonly cdr: ChangeDetectorRef,
    private readonly advancedBookingsRequestService: AdvancedBookingsRequestService
  ) {}

  ngOnInit () {
    this.resetCopyFlag =
      this.advancedBookingsRequestService.returnResetCopyFlagObs();
    this.resetCopyFlag?.subscribe((value) => {
      if (value?.index === this.index && value?.cardType === this.cardType) {
        this.isCopied = false;
      } else if (!value?.cardType) {
        this.isCopied = false;
      }
      this.cdr.detectChanges();
    });
  }

  shouldShowAction (action: AdvancedBookingActionEnum): boolean {
    return this.actions.includes(action);
  }

  selectAction (action: AdvancedBookingActionEnum) {
    if (action === AdvancedBookingActionEnum.COPY) {
      this.isCopied = true;
    }
    if (action === AdvancedBookingActionEnum.COPIED) {
      this.actionSelected.emit(action);
      this.isCopied = false;
      this.tooltips.toArray().forEach((tooltip) => {
        tooltip.close();
      });
      return;
    }
    this.actionSelected.emit(action);
    this.tooltips.toArray().forEach((tooltip) => {
      tooltip.close();
    });
    this.cdr.detectChanges();
  }
}
