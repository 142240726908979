import { SharedInfo } from "./shared-info.model";

/**
 *
 */
export class Comment {
  /**
   *
   */
  commentId: number;
  /**
   *
   */
  commentMessage: string;

  sharedInfo: SharedInfo;

  commentDate: Date;

  commenter: Comment.CommenterTypeEnum;

  constructor (commentMessage?: string) {
    this.commentMessage = commentMessage;
  }
}

export namespace Comment {
  export type CommenterTypeEnum = "FORWARDER" | "AIRLINE";
  export const CommenterTypeEnum = {
    "ACCEPT": "FORWARDER" as CommenterTypeEnum,
    "REJECT": "AIRLINE" as CommenterTypeEnum,
  };
}
