import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  LoadTypeEnum,
  MeasurementSystemEnum,
  PackingTypeEnum,
  ShipmentInputs,
  WeightTypeEnum,
} from "@cai-services";
import {
  MeasurementUnit,
  MeasurementUnits,
} from "../../../../core/_models/measurement-system.model";
import { Dimension } from "../../../../core/_models/dimension.model";
import { DimensionsConfig } from "../../constants/dimension.const";
import { LoadTypeMeasurementUnits } from "../../constants/measurements.const";
import { DimensionFieldsEnum } from "../../enum/dimensions.enum";
import { LoadTypeInputTypesEnum } from "../../enum/input-types.enum";
import { MeasurementTypeEnum } from "../../enum/measurement-type.enum";
import { CaiDimensionUtil } from "../../utils/dimension.util";
import { CaiLoadTypeMeasurementUtil } from "../../utils/measurement.util";
import { MeasurementUtil } from "../../../../core/_base/crud/utils/measurement.util";
import { TypeOfProduct } from "../../../../core/_models/type-of-product.model";

const DETAILS_ROW_HEIGHT = 46,
 ERROR_ROW_HEIGHT = 26;

@Component({
  "selector": "cai-load-type-dimension",
  "templateUrl": "./load-type-dimension.component.html",
  "styleUrls": ["./load-type-dimension.component.scss"],
})
export class CaiLoadTypeDimensionComponent implements OnChanges {
  @ViewChild("tbody") tbody: ElementRef;
  @Input() dimensions: Dimension[] = [];
  @Input() measurementUnit = MeasurementUnits.METRIC;
  @Output() dimensionsChange = new EventEmitter<Dimension[]>();
  @Output() onUpdateTotals = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Input() enableUnitSwitch: boolean;
  @Input() dimensionUnitsState: any;
  @Input() noUpdateOnBlur: boolean;
  @Input() minRowBeforeScroll: number;
  @Input() dimensionUnit: MeasurementUnit;
  @Input() defaultMeasurementSystem: MeasurementSystemEnum;
  @Input() chargeableUnitChangeCount: number;
  @Input() weightUnit: MeasurementUnit;
  @Input() measurementSystem: MeasurementSystemEnum;
  @Input() mode: LoadTypeEnum;
  @Output() measurementSystemChange = new EventEmitter<MeasurementSystemEnum>();
  @Output() dimensionUnitChange = new EventEmitter<MeasurementUnit>();
  @Output() weightUnitChange = new EventEmitter<MeasurementUnit>();
  @Output() dimensionUnitsStateChange = new EventEmitter<any>();

  weightType = WeightTypeEnum;
  measurementTypeEnum = MeasurementTypeEnum;
  loadTypeInputTypesEnum = LoadTypeInputTypesEnum;
  measurementSystemEnum = MeasurementSystemEnum;
  dimensionFieldsEnum = DimensionFieldsEnum;
  currentIndex: number = null;
  stackableLabel = $localize`:@@global.stackable:Stackable`;
  turnableLabel = $localize`:@@global.turnable:Turnable`;

  constructor (private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.hasOwnProperty("dimensions")) {
      this.checkAddNewRow();
      this.checkErrors();
      this.checkUpdateTotals();
      this.markAsTouched();
    }

    if (
      changes.hasOwnProperty("chargeableUnitChangeCount") &&
      changes.chargeableUnitChangeCount.currentValue &&
      this.mode === LoadTypeEnum.DIMENSIONS
    ) {
      this.changeUnit(this.weightUnit, this.measurementTypeEnum.WEIGHT);
    }
    if (changes.hasOwnProperty("weightUnit")) {
      this.checkErrors();
    }
  }

  isStackable (dimension: Dimension): boolean {
    return [
      PackingTypeEnum.STACKABLE,
      PackingTypeEnum.STACKABLE_TURNABLE,
    ].includes(dimension.packing);
  }

  isTurnable (dimension: Dimension): boolean {
    return [
      PackingTypeEnum.TURNABLE,
      PackingTypeEnum.STACKABLE_TURNABLE,
    ].includes(dimension.packing);
  }

  toggleStackable (checked, dimension: Dimension): void {
    if (checked) {
      dimension.packing =
        dimension.packing === PackingTypeEnum.TURNABLE
          ? PackingTypeEnum.STACKABLE_TURNABLE
          : PackingTypeEnum.STACKABLE;
    } else {
      dimension.packing =
        dimension.packing === PackingTypeEnum.STACKABLE_TURNABLE
          ? PackingTypeEnum.TURNABLE
          : PackingTypeEnum.NONE;
    }
    this.onFocus.emit();
    this.updateDimensions();
    this.cdr.detectChanges();
  }

  toggleTurnable (checked: boolean, dimension: Dimension): void {
    if (checked) {
      dimension.packing =
        dimension.packing === PackingTypeEnum.STACKABLE
          ? PackingTypeEnum.STACKABLE_TURNABLE
          : PackingTypeEnum.TURNABLE;
    } else {
      dimension.packing =
        dimension.packing === PackingTypeEnum.STACKABLE_TURNABLE
          ? PackingTypeEnum.STACKABLE
          : PackingTypeEnum.NONE;
    }
    this.onFocus.emit();
    this.updateDimensions();
    this.cdr.detectChanges();
  }

  toggleWeightType (dimension: Dimension): void {
    switch (dimension.weightType) {
      case WeightTypeEnum.PER_ITEM:
        dimension.weightType = WeightTypeEnum.TOTAL;
        break;
      case WeightTypeEnum.TOTAL:
        dimension.weightType = WeightTypeEnum.PER_ITEM;
        break;
    }
    this.onFocus.emit();
    this.updateDimensions();
    this.cdr.detectChanges();
  }

  deleteRow (rowIndex: number): void {
    if (this.dimensions.length === 1) {
      this.dimensions = [];
      this.checkAddNewRow();
    } else {
      this.dimensions.splice(rowIndex, 1);
      this.cdr.detectChanges();
    }
    this.currentIndex = null;
    this.updateDimensions();
    this.checkUpdateTotals();
    this.checkErrors();
    this.markAsTouched();
    this.checkUpdateTotals();
  }

  markAsTouched () {
    this.dimensions.forEach((dimension) => {
      if (!dimension.isNewRow) {
        dimension.isTouched = true;
      }
    });
  }

  changeValue () {
    this.checkUpdateTotals();
    this.checkAddNewRow();
    this.updateDimensions();
  }

  updateDimensions () {
    this.dimensionsChange.emit(this.dimensions);
    this.checkUpdateTotals();
  }

  touchRow (dimension: Dimension) {
    dimension.isFocused = true;
    dimension.isNewRow = false;
    dimension.isTouched = true;
    this.onFocus.emit();
  }

  focusRow (dimension: Dimension, index: number) {
    this.currentIndex = index;
    this.dimensions
      .filter((d) => d.isFocused)
      .forEach((d) => {
        d.isFocused = false;
      });
    dimension.isFocused = true;
    if (!dimension.isNewRow) {
      dimension.isTouched = true;
    }
    this.checkErrors();
    this.onFocus.emit();
    this.cdr.detectChanges();
  }

  unfocusRow (dimension: Dimension) {
    this.currentIndex = null;
    dimension.isFocused = false;
    this.checkErrors();
    this.cdr.detectChanges();
    this.updateDimensions();
  }

  isInvalidField (
    field: DimensionFieldsEnum,
    dimension: Dimension,
    index: number,
  ): boolean {
    return (
      !dimension.isNewRow &&
      !dimension.isFocused &&
      dimension.isTouched &&
      dimension.errors &&
      dimension.errors.find((error) => error.field === field) &&
      this.currentIndex !== index
    );
  }

  isInvalidRow (dimension: Dimension, index: number): boolean {
    return (
      !dimension.isNewRow &&
      !dimension.isFocused &&
      dimension.isTouched &&
      dimension.errors &&
      !!dimension.errors.length &&
      this.currentIndex !== index
    );
  }

  checkAddNewRow (): void {
    const newDimension = new Dimension(
      1,
      null,
      null,
      null,
      null,
      PackingTypeEnum.STACKABLE,
      WeightTypeEnum.PER_ITEM,
    );
    newDimension.isNewRow = true;
    let shouldAdd = false;
    if (this.dimensions?.length) {
      const invalidRows = this.dimensions.filter(
        (item) => !CaiDimensionUtil.isPartiallyFilled(item),
      );
      if (!invalidRows.length) {
        shouldAdd = true;
      }
    } else {
      shouldAdd = true;
    }
    if (shouldAdd) {
      this.dimensions.push(newDimension);
      this.cdr.detectChanges();
      this.tbody.nativeElement.scrollTop =
        this.tbody.nativeElement.scrollHeight;
    }
  }

  checkUpdateTotals (): void {
    this.onUpdateTotals.emit();
  }

  showDelete (dimension: Dimension, index: number): boolean {
    return (
      CaiDimensionUtil.isPartiallyFilled(dimension) ||
      (!dimension.isNewRow &&
        !dimension.isFocused &&
        dimension.isTouched &&
        !this.isInvalidRow(dimension, index))
    );
  }

  validate (): void {
    const validRows = this.dimensions.filter((item) =>
      CaiDimensionUtil.isPartiallyFilled(item),
    );
    if (!validRows.length) {
      this.currentIndex = null;
      this.dimensions.forEach((dimension) => {
        dimension.isNewRow = false;
        dimension.isTouched = true;
        dimension.isFocused = false;
      });
      this.checkErrors();
      this.cdr.detectChanges();
    }
  }

  checkErrors (): void {
    this.dimensions.forEach((dimension) => {
      dimension.errors = [];
      for (const key of Object.keys(DimensionFieldsEnum)) {
        const error = this.getFieldError(DimensionFieldsEnum[key], dimension),
         exists = dimension.errors.find(
          (err) => err.field === DimensionFieldsEnum[key],
        );
        if (error && !exists) {
          dimension.errors.push({
            "field": DimensionFieldsEnum[key],
            "message": error,
          });
        }
      }
    });
  }

  getFieldError (field: DimensionFieldsEnum, dimension: Dimension): string {
    let fieldError = null,
     maxValue,
     minValue;
    const config = DimensionsConfig.find((c) => c.field === field);
    if (config) {
      const value = dimension[field];
      if (this.dimensionUnit?.code === "in") {
        maxValue = MeasurementUtil.convertMeasure(
          1000,
          MeasurementSystemEnum.METRIC,
          MeasurementSystemEnum.IMPERIAL,
        );
        minValue = 0;
      } else {
        maxValue = 1000;
        minValue = 0;
      }
      if (field === "weight" && this.weightUnit?.code === "lb") {
        maxValue = MeasurementUtil.convertWeight(
          1000000,
          MeasurementSystemEnum.METRIC,
          MeasurementSystemEnum.IMPERIAL,
        );
        minValue = 0;
      } else if (field === "weight") {
        maxValue = 1000000;
        minValue = 0;
      }
      for (const key of Object.keys(config.validators)) {
        const error = config.validators[key](
          value,
          field === "weight" ? this.weightUnit?.code : this.dimensionUnit?.code,
          maxValue,
          minValue,
        );
        if (error && !fieldError) {
          fieldError = error;
        }
      }
    }
    return fieldError;
  }

  changeUnit (unit: MeasurementUnit, type: MeasurementTypeEnum) {
    switch (type) {
      case MeasurementTypeEnum.WEIGHT: {
        this.dimensionUnitsState.weightMeasurementUnit =
          this.weightUnit?.code === "kg"
            ? MeasurementSystemEnum.METRIC
            : MeasurementSystemEnum.IMPERIAL;
        this.weightUnitChange.emit(unit);
        this.dimensionUnitsStateChange.emit(this.dimensionUnitsState);
        break;
      }
      case MeasurementTypeEnum.MEASURE: {
        this.dimensionUnitsState.dimensionMeasurementUnit =
          this.dimensionUnit?.code === "cm"
            ? MeasurementSystemEnum.METRIC
            : MeasurementSystemEnum.IMPERIAL;
        this.dimensionUnitChange.emit(unit);
        this.dimensionUnitsStateChange.emit(this.dimensionUnitsState);
        break;
      }
    }
    this.dimensions.forEach((dimension) => {
      ["dimensionLength", "dimensionWidth", "dimensionHeight"].forEach(
        (field) => {
          if (dimension[field] && type === MeasurementTypeEnum.MEASURE) {
            const measurementUnit =
              this.dimensionUnit?.code === "cm"
                ? MeasurementSystemEnum.METRIC
                : MeasurementSystemEnum.IMPERIAL;
            dimension[field] = MeasurementUtil.convertMeasure(
              dimension[field],
              MeasurementUtil.toggleMeasurementSystem(measurementUnit),
              measurementUnit,
            );
          }
        },
      );
      if (dimension.weight && type === MeasurementTypeEnum.WEIGHT) {
        const measurementUnit =
          this.weightUnit?.code === "kg"
            ? MeasurementSystemEnum.METRIC
            : MeasurementSystemEnum.IMPERIAL;
        dimension.weight = MeasurementUtil.convertWeight(
          dimension.weight,
          MeasurementUtil.toggleMeasurementSystem(measurementUnit),
          measurementUnit,
        );
      }
    });
    this.updateDimensions();
    this.checkErrors();
  }

  changeWeightUnit (unit: MeasurementUnit) {
    this.weightUnitChange.emit(unit);
  }

  matchMeasurementSystem (
    name: MeasurementSystemEnum,
    isDimension: boolean,
  ): boolean {
    const unit = isDimension ? this.dimensionUnit : this.weightUnit;
    if (
      name === MeasurementSystemEnum.METRIC &&
      (unit?.code === "cm" || unit?.code === "kg")
    ) {
      return true;
    }
    if (
      name === MeasurementSystemEnum.IMPERIAL &&
      (unit?.code == "in" || unit?.code === "lb")
    ) {
      return true;
    }
    return false;
  }

  getMeasurementUnits (type: MeasurementTypeEnum) {
    return CaiLoadTypeMeasurementUtil.getMeasurementUnits(type);
  }

  get maxHeight (): number {
    if (this.dimensions.length > this.minRowBeforeScroll) {
      return this.dimensions
        .slice(0, this.minRowBeforeScroll)
        .map((dimension, index) => (
            DETAILS_ROW_HEIGHT +
            (this.isInvalidRow(dimension, index) ? ERROR_ROW_HEIGHT : 0)
          ))
        .reduce((a, b) => a + b, 0);
    }
    return null;
  }

  autofillDimensionsChange (event: ShipmentInputs) {
    const convertMeasure = { "dimension": true, "weight": true },
     weightUnitsSet = new Set(
      event.dimensions.map((item) => item.weightUnit),
    );
    if (
      weightUnitsSet.size === 1 &&
      !weightUnitsSet.has(null) &&
      !weightUnitsSet.has(this.weightUnit.code)
    ) {
      const weightMeasurementSystem =
        weightUnitsSet[0] === "kg"
          ? MeasurementSystemEnum.METRIC
          : MeasurementSystemEnum.IMPERIAL;
      this.weightUnit =
        LoadTypeMeasurementUnits[weightMeasurementSystem].WEIGHT;
      this.weightUnitChange.emit(this.weightUnit);
      this.measurementSystemChange.emit(weightMeasurementSystem);
      convertMeasure.weight = false;
    }
    const dimensionsUnitsSet = new Set(
      event.dimensions.map((item) => item.distanceUnit),
    );
    if (
      dimensionsUnitsSet.size === 1 &&
      !dimensionsUnitsSet.has(null) &&
      !dimensionsUnitsSet.has(this.dimensionUnit.code)
    ) {
      const dimensionMeasurementSystem =
        dimensionsUnitsSet[0] === "cm"
          ? MeasurementSystemEnum.METRIC
          : MeasurementSystemEnum.IMPERIAL;
      this.dimensionUnit =
        LoadTypeMeasurementUnits[dimensionMeasurementSystem].MEASURE;
      this.dimensionUnitChange.emit(this.dimensionUnit);
      this.measurementSystemChange.emit(dimensionMeasurementSystem);
      convertMeasure.dimension = false;
    }
    this.dimensions = event.dimensions.map((item) => {
      let weight, length, width, height;
      if (
        convertMeasure.weight &&
        ![this.weightUnit.code, null].includes(item.weightUnit)
      ) {
        const originalSystem =
          item.weightUnit === "kg"
            ? MeasurementSystemEnum.METRIC
            : MeasurementSystemEnum.IMPERIAL;
        weight = MeasurementUtil.convertWeight(
          item.weight,
          originalSystem,
          MeasurementUtil.toggleMeasurementSystem(originalSystem),
        );
      }
      if (
        convertMeasure.dimension &&
        ![this.dimensionUnit.code, null].includes(item.distanceUnit)
      ) {
        const originalSystem =
          item.distanceUnit === "cm"
            ? MeasurementSystemEnum.METRIC
            : MeasurementSystemEnum.IMPERIAL;
        [length, width, height] = [item.length, item.width, item.height].map(
          (measure) =>
            MeasurementUtil.convertMeasure(
              measure,
              originalSystem,
              MeasurementUtil.toggleMeasurementSystem(originalSystem),
            ),
        );
      }
      return {
        "quoteItemId": 0,
        "quoteRequest": null,
        "numOfItems": item.pieces,
        "weight": weight ? weight : item.weight,
        "dimensionLength": length ? length : item.length,
        "dimensionWidth": width ? width : item.width,
        "dimensionHeight": height ? height : item.height,
        "packing": PackingTypeEnum.STACKABLE,
        "weightType": WeightTypeEnum.PER_ITEM,
        "typeOfProduct": new TypeOfProduct(),
        "dimensionsUnit": this.dimensionUnit.code,
        "weightUnit": this.weightUnit.code,
        "loadType": LoadTypeEnum.DIMENSIONS,
        "isNewRow": false,
        "isTouched": false,
        "isFocused": false,
        "errors": [],
      } as Dimension;
    });
    this.updateDimensions();
  }
}
