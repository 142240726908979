import { Office } from "./office.model";
import { AdminUser } from "./admin-user.model";
import { PermissionSet } from "./permission-set.model";

export class OfficeAccess {
  id: number;
  user: AdminUser;
  office: Office;
  roles: PermissionSet[];
  type: RelationType;
  muteNotifications: boolean;
}

export enum RelationType {
  ALL = "ALL",
  SALES = "SALES",
  RESERVATION = "RESERVATION",
}
