<input
  #input
  [class.invalid]="invalid"
  [class.readonly]="readonly"
  [disabled]="disabled || readonly ? true : null"
  [class.mandatory-disabled]="mandatoryDisabled"
  [attr.placeholder]="placeholder"
  [(ngModel)]="value"
  (ngModelChange)="valueChange.emit($event)"
  [style.color]="inputColor"
  [style.font-size.px]="fontSize"
  (focus)="onFocus.emit()"
  (keypress)="onKeyPress($event)"
  (keydown)="onKeyDown($event)"
  (keyup)="onKeyUp($event)"
  (paste)="onPaste($event)"
  (blur)="blur()"
  autocomplete="off"
/>
