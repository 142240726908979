<label
  #wrapper
  class="advanced-autocomplete"
  [class.with-icon]="showIcon"
  [class.viewonly]="viewOnly"
  [class.empty-placeholder]="viewOnly && !display"
  [class.flightno-empty-placeholder]="
    viewOnly && !display && label === 'Flight no' && popup
  "
  [class.invalid]="showError"
  [class.focused]="isFocused"
  [class.popup-label]="popup"
  [class.airlineInput]="label === 'Airline'"
  [class.flightNoInput]="label === 'Flight no'"
  [attr.for]="undefined"
  caiTooltip
  [tooltip]="input.value"
  [detectOverflow]="true"
  [target]="input"
>
  <span
    class="item-icon d-flex align-items-center bg-transparent"
    *ngIf="showCurrencyIcon && value"
  >
    <div
      class="currency-flag currency-flag-img-size ml-2 mr-1"
      [ngClass]="'currency-flag-' + getFlagName(selected?.value)?.toLowerCase()"
    ></div>
  </span>
  <input
    #input
    [placeholder]="label"
    [(ngModel)]="display"
    (blur)="blur(input.value)"
    [disabled]="viewOnly ? true : null"
    (keyup)="keyup($event, dropdown, wrapper)"
    (focus)="open(dropdown, wrapper, showAllOnFocus)"
    [type]="inputType"
    [class.text-center]="textCenter"
    [class.text-uppercase]="uppercase"
    [class.float-label]="floatLabel"
  />
  <span class="toggle-icon" [class.rotate]="isOpen">
    <ng-content select="[toggle-icon]"></ng-content>
  </span>
  <span class="label" [class.label-placeholder]="viewOnly && !display">{{
    !display && viewOnly ? "" : label
  }}</span>
  <ng-template #dropdown>
    <div
      class="dropdown"
      [ngClass]="customClass"
      [style.min-width.px]="minWidth"
      [style.max-width.px]="maxWidth"
    >
      <ng-container *ngIf="!showNoResults">
        <div
          class="dropdown-inner"
          [style.height.px]="getHeight()"
          [style.min-width.px]="minWidth"
          [style.max-width.px]="maxWidth"
        >
          <div *ngFor="let option of filteredOptions; let i = index">
            <span
              class="group-label"
              (click)="$event.stopPropagation()"
              *ngIf="i == 0 && filteredRecentOptions.length"
              >RECENT</span
            >
            <span
              class="group-label"
              (click)="$event.stopPropagation()"
              *ngIf="
                i == filteredRecentOptions.length &&
                filteredRecentOptions.length &&
                !shouldHide(i)
              "
              >ALL</span
            >
            <div
              class="select-item"
              caiTooltip
              class="item"
              [class.d-none]="shouldHide(i)"
              [tooltip]="option.label"
              placement="bottom-start"
              [class.active]="selected === option"
              (mousedown)="select(option); $event.preventDefault()"
            >
              <span *ngIf="!showCurrencyIcon">{{ option.label }}</span>
              <span
                class="item-icon d-flex align-items-center bg-transparent"
                *ngIf="showCurrencyIcon"
              >
                <div
                  class="currency-flag currency-flag-img-size"
                  [ngClass]="
                    'currency-flag-' + getFlagName(option.value)?.toLowerCase()
                  "
                ></div>
                <span class="label-space"> {{ option.label }}</span>
              </span>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="showNoResults && !allowManualInput" class="no-results">
        <div i18n="@@global.no-results-found">NO RESULTS FOUND</div>
        <div *ngIf="noResultsText">{{ noResultsText }}</div>
      </div>
    </div>
  </ng-template>
</label>
