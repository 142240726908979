<div class="advanced-load-type" [formGroup]="formGroup">
  <div class="pieces">
    <kt-advanced-qty-input
      #qtyField
      label="Pieces"
      [addErrorLabel]="true"
      [floatLabel]="floatLabel"
      formControlName="pieces"
      [readonly]="disableTotals"
      [noUpdateOnBlur]="noUpdateOnBlur"
      [viewOnly]="viewOnly"
      [disableDecimal]="true"
      [unit]="'pc(s)'"
    ></kt-advanced-qty-input>
  </div>
  <div class="weight">
    <kt-advanced-qty-input
      #qtyField
      label="Weight"
      formControlName="weight"
      [readonly]="disableTotals"
      [viewOnly]="viewOnly"
      [addErrorLabel]="true"
      [floatLabel]="floatLabel"
      [disableUnitSelector]="disableUnitSelector"
      [noUpdateOnBlur]="noUpdateOnBlur"
      [unit]="totalUnitsState?.weightUnitCode"
      [selectedUnit]="totalUnitsState?.weightUnitDetail"
      [measurementSystem]="totalUnitsState?.measurementSystem"
      [measurementUnits]="getMeasurementUnits(measurementTypeEnum.WEIGHT)"
      (selectedUnitChange)="selectedUnitChange(this.totalUnitsState, $event)"
      (measurementSystemChange)="
        measurementSystemChange(totalUnitsState, $event)
      "
    ></kt-advanced-qty-input>
  </div>
  <div class="volume">
    <kt-advanced-qty-input
      #qtyField
      label="Volume"
      formControlName="volume"
      [readonly]="disableTotals"
      [noUpdateOnBlur]="noUpdateOnBlur"
      [viewOnly]="viewOnly"
      [addErrorLabel]="true"
      [floatLabel]="floatLabel"
      [unit]="measurementUnit?.VOLUME.display"
      [decimalPlace]="4"
    ></kt-advanced-qty-input>
  </div>
</div>
