<cai-settings-layout
  tabTitle="Personal Settings"
  [items]="navItems"
  [selectedNavItem]="selectedTab"
  (onSelectNavItem)="onSelectTab($event)"
>
  <div class="right-session">
    <div class="container mb-5">
      <!--begin::Profile Card-->
      <div class="d-flex align-items-start flex-column profile-header">
        <kt-profile-image
          [user]="currentUser"
          [logoPath]="logoPath"
          (change)="updateProfilePicture($event)"
          [loading]="loading"
          [border]="true"
          [disableUpload]="selectedTab !== Tabs.ABOUT"
        ></kt-profile-image>
      </div>
      <!--begin::Card-->
      <div>
        <ng-container *ngIf="selectedTab === Tabs.ABOUT">
          <kt-about-personal-settings
            [user]="currentUser"
            (updateSuccess)="handleUpdateSuccess($event)"
          ></kt-about-personal-settings>
          <kt-security-personal-settings
            [user]="currentUser"
          ></kt-security-personal-settings>
        </ng-container>
        <ng-container *ngIf="selectedTab === Tabs.PREFERENCES">
          <kt-preferences-personal-settings
            [user]="currentUser"
          ></kt-preferences-personal-settings>
        </ng-container>
        <ng-container *ngIf="selectedTab === Tabs.NOTIFICATIONS">
          <kt-notifications-personal-settings
            [user]="currentUser"
          ></kt-notifications-personal-settings>
        </ng-container>
        <ng-container *ngIf="selectedTab === Tabs.INTEGRATIONS">
          <mart-integrations [user]="currentUser"></mart-integrations>
        </ng-container>
      </div>
    </div>
  </div>
</cai-settings-layout>
