import { Office } from "./office.model";

export class ColoaderOfficeContact {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  office: Office;

  constructor (
    email?: string,
    firstName?: string,
    lastName?: string,
    phoneNumber?: string
  ) {
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phoneNumber = phoneNumber;
  }
}
