import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

const HEADER_ELEMENT_ID = "kt_header",
 MOBILE_HEADER_ELEMENT_ID = "kt_header_mobile";

@Injectable({
  "providedIn": "root",
})
export class HeaderService {
  headerHeight$ = new Subject<number>();

  updateHeight () {
    this.headerHeight$.next(this.getHeaderHeight());
  }

  getHeaderHeight (): number {
    const mobileHeader = document.getElementById(MOBILE_HEADER_ELEMENT_ID);
    if (mobileHeader?.offsetHeight) {
      return mobileHeader.offsetHeight;
    }
    const header = document.getElementById(HEADER_ELEMENT_ID);
    if (header?.offsetHeight) {
      return header.offsetHeight;
    }
    return 0;
  }
}
