import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Self,
  Optional,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";

@Component({
  "selector": "cai-datepicker",
  "templateUrl": "./datepicker.component.html",
  "styleUrls": ["./datepicker.component.scss"],
})
export class CaiDatepicker implements OnInit, ControlValueAccessor {
  @ViewChild("input", { "static": true }) input: ElementRef;
  @Input() minDate: Date = new Date();
  @Input() maxDate: Date;
  @Input() label: string;
  @Input() viewOnly: boolean;
  @Input() isHideIcon: boolean;
  @Input() disabled: boolean;
  @Input() hideLabel: boolean;
  @Input() placeholder: string;
  @Input() id: string;
  @Output() onFocus = new EventEmitter();
  @Output() dateChanged = new EventEmitter();

  value: Date = new Date();
  focused: boolean;
  private touched: boolean;

  onChange = (value: Date) => {};
  onTouched = () => {};

  constructor (
    @Self()
    @Optional()
    public control: NgControl
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit (): void {}

  public get invalid (): boolean {
    return this.control ? this.control.invalid : false;
  }
  get calenderId (): string {
    return this.id ? this.id : "";
  }
  public get showError (): boolean {
    if (!this.control) {
      return false;
    }
    const { dirty, touched } = this.control;
    return this.invalid ? dirty || touched : false;
  }

  public get errorMessage (): string {
    const error = Object.values(this.control.errors)[0].message || null;
    if (error) {
      return error.replace("{field}", this.label);
    }
    return null;
  }

  writeValue (value: Date) {
    this.value = value;
  }

  registerOnChange (onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched (onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched () {
    if (!this.touched && typeof this.onTouched === "function") {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState (disabled: boolean) {
    this.disabled = disabled;
  }

  openCalendar (dp: MatDatepicker<Date>) {
    this.onFocus.emit();
    this.markAsTouched();
    dp.open();
    setTimeout(() => this.input?.nativeElement.focus());
  }

  closeCalendar () {
    this.onFocus.emit(this.value);
    if (typeof this.onChange === "function") {
      this.onChange(this.value);
      this.dateChanged.emit(this.value);
    }
    setTimeout(() => this.input?.nativeElement.blur());
  }
}
