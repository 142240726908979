import {
  CompanyTypeEnum,
  IOffice,
  WalletStatusTypeEnum,
  WalletApplicationStatusEnum,
  WalletAccount,
  CargoMartPlansType,
} from "@cai-services";
import { AirlineBrand } from "./airline.model";
import { Company } from "./company.model";
import { Country } from "./country.model";
import { Airport } from "./region-airport.model";
import { OfficeAccess } from "./office-access.model";
import { ColoaderOfficeContact } from "./coloader-office-contact.model";
import { OfficeAdditionalInfo } from "./office-additional-info.model";
import { PartnerExportFacility } from "./partner-export.model";
import { Currency } from "./currency.model";
import { Feature } from "./feature.model";

export class Office implements IOffice {
  id: number;
  type: CompanyTypeEnum;
  name: string;
  company: Company;
  country: Country;
  iata: string;
  pima: string;
  sita: string;
  smtp: string;
  cass: string;
  customerOfficeNumber: string;
  airlineBrands: AirlineBrand[];
  airports: Airport[];
  walletId: string;
  beneficiaryId: string;
  walletStatus: WalletStatusTypeEnum;
  financeEmail: string;
  plan: CargoMartPlansType;
  planExpiryDate: Date;
  registrationNumber: string;
  comments: string;
  addrStreet: string;
  addrCity: string;
  addrZipcode: string;
  addrCountry: Country;
  shareQuotes: boolean;
  officeAccesses: OfficeAccess[] = [];
  userPopulation: number;
  contacts: ColoaderOfficeContact[];
  additionalInfos: OfficeAdditionalInfo[];
  wallet: WalletAccount;
  terms: string;
  partnerWebsite: string;
  disclaimer: string;
  walletApplicationStatus: WalletApplicationStatusEnum;
  minBookingTime: number;
  partnerExportFacilities: PartnerExportFacility[];
  additionalInfosSize: number;
  satWorkingDay: boolean;
  sunWorkingDay: boolean;
  markupFlat: number;
  markupPercent: number;
  isWalletPublic: boolean;
  providers: string[];
  supportedCargoGate: string[];
  activatedFeatures?: Feature[];
  // admin fields
  sortKey: string;
  marginPercent?: number;
  marginCurrency?: Currency;
  marginMinFlat?: number;
  marginMaxFlat?: number;
}
