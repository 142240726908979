import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef, OnChanges,
} from "@angular/core";
import { filter, takeUntil, takeWhile } from "rxjs/operators";
import { fromEvent } from "rxjs";
import Popper from "popper.js";

@Component({
  "selector": "cai-load-type-select",
  "templateUrl": "./load-type-select.component.html",
  "styleUrls": ["./load-type-select.component.scss"],
})
export class CaiLoadTypeSelectComponent implements OnChanges {
  @ViewChild("input") input: ElementRef;
  @Input() invalid: boolean;
  @Input() placeholder: string;
  @Input() options: any[] = [];
  @Input() value: any;
  @Output() valueChange = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onLostFocus = new EventEmitter();

  selected: any;
  popperRef: Popper;
  text: string;
  view: EmbeddedViewRef<any>;

  constructor (
    private readonly vcr: ViewContainerRef,
    private readonly zone: NgZone,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.hasOwnProperty("value") || changes.hasOwnProperty("options")) {
      const matched = (this.options || []).find(
        (option) => option.value === this.value
      );
      if (matched) {
        this.selected = matched;
        this.text = matched.label;
      }
    }
  }

  isOpen (): boolean {
    return !!this.popperRef;
  }

  blur () {
    this.onLostFocus.emit();
  }

  open (dropdownTpl: TemplateRef<any>, input: HTMLElement) {
    this.onFocus.emit();
    if (!!this.popperRef) {
      this.close();
    }

    if (this.options?.length) {
      this.view = this.vcr.createEmbeddedView(dropdownTpl);
      const dropdown = this.view.rootNodes[0];

      document.body.appendChild(dropdown);
      dropdown.style["z-index"] = "1";
      dropdown.style["min-width"] = `${input.offsetWidth}px`;

      this.zone.runOutsideAngular(() => {
        this.popperRef = new Popper(input, dropdown, {
          "placement": "bottom-end",
          "removeOnDestroy": true,
          "modifiers": {
            "preventOverflow": { "escapeWithReference": true },
          },
        });
      });

      this.cdr.detectChanges();
      this.handleClickOutside();
    }
  }

  handleClickOutside () {
    fromEvent(document, "click")
      .pipe(
        takeWhile(() => this.isOpen()),
        filter(({ target }) => {
          const origin = this.popperRef.reference as HTMLElement;
          return origin.contains(target as HTMLElement) === false;
        }),
        takeUntil(this.onClose)
      )
      .subscribe(() => {
        this.close();
        this.cdr.detectChanges();
      });
  }

  select (option) {
    if (this.selected !== option || this.value !== option.value) {
      const value = option ? option.value : null,
       label = option ? option.label : null;
      this.selected = option;
      this.text = label;
      this.value = value;
      this.close();
      this.valueChange.emit(value);
      this.cdr.detectChanges();
      this.onLostFocus.emit();
    }
  }

  close () {
    this.onClose.emit();
    if (this.view) {
      this.view.destroy();
      this.view = null;
    }
  }
}
