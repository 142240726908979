import { QuoteItem } from "./quote-item.model";

export class Dimension extends QuoteItem {
  dimensionsUnit: string;
  weightUnit: string;
  isNewRow: boolean;
  isTouched: boolean;
  isFocused: boolean;
  errors: any[];
}
