<!--begin::Form-->
<form class="form personal-settings-form" [formGroup]="updatePasswordForm" (ngSubmit)="onSubmit()">
  <section>
    <h2 i18n="@@profile.change-you-password" class="mb-4">Change Password</h2>

    <div class="row">
      <div class="form-group col-12">
        <label for="old-password" required i18n="@@global.old-password"
          >Old Password</label
        >
        <input
          id="old-password"
          type="password"
          formControlName="oldPassword"
          class="form-control form-control-solid"
          placeholder="Enter current password"
          [class.input-error]="
            isControlHasError('oldPassword', 'required')
          "
        />
        <mat-error *ngIf="isControlHasError('oldPassword', 'required')">
          <span i18n="@@global.required">Required</span>
        </mat-error>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-12">
        <label for="new-password" required i18n="@@global.new-password"
          >New Password</label
        >
        <input
          id="new-password"
          type="password"
          formControlName="newPassword"
          class="form-control form-control-solid"
          placeholder="Enter new password"
          [class.input-error]="
            isControlHasError('newPassword', 'required') ||
            isControlHasError('newPassword', 'minlength') ||
            isControlHasError('newPassword', 'hasLowercase') ||
            isControlHasError('newPassword', 'hasUppercase') ||
            isControlHasError('newPassword', 'hasNumber')
          "
        />
        <mat-error *ngIf="isControlHasError('newPassword', 'required')">
          <div i18n="@@global.required">Required</div>
        </mat-error>
        <mat-error *ngIf="isControlHasError('newPassword', 'minlength')">
          <div i18n="@@global.minimum-eight-characters">
            Minimum of 8 characters
          </div>
        </mat-error>
        <mat-error
          *ngIf="isControlHasError('newPassword', 'hasLowercase')"
        >
          <div i18n="@@global.minimum-one-lowercase-letter">
            Minimum of 1 lowercase letter
          </div>
        </mat-error>
        <mat-error
          *ngIf="isControlHasError('newPassword', 'hasUppercase')"
        >
          <div i18n="@@global.minimum-one-uppercase-letter">
            Minimum of 1 uppercase letter
          </div>
        </mat-error>
        <mat-error *ngIf="isControlHasError('newPassword', 'hasNumber')">
          <div i18n="@@global.minimum-one-number">
            Minimum of 1 number
          </div>
        </mat-error>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-12">
        <label
          for="confirm-new-password"
          required
          i18n="@@global.confirm-new-password"
          >Confirm New Password</label
        >
        <input
          id="confirm-new-password"
          type="password"
          formControlName="confirmNewPassword"
          class="form-control form-control-solid"
          placeholder="Re-enter new password"
          [class.input-error]="
            isControlHasError('confirmNewPassword', 'required') ||
            isControlHasError('confirmNewPassword', 'mustMatch')
          "
        />
        <mat-error
          *ngIf="isControlHasError('confirmNewPassword', 'required')"
        >
          <span i18n="@@global.required">Required</span>
        </mat-error>
        <mat-error
          *ngIf="isControlHasError('confirmNewPassword', 'mustMatch')"
        >
          <span i18n="@@global.text-error-password-must-match"
            >Password must match</span
          >
        </mat-error>
      </div>
    </div>
    <button
      type="submit"
      [disabled]="!updatePasswordForm.dirty || !updatePasswordForm.valid"
      [ngClass]="{
        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
          loading
      }"
      class="btn btn-save"
      i18n="@@global.save-changes"
    >
      Save changes
    </button>
  </section>
  <!--end::Body-->
</form>
<!--end::Form-->
