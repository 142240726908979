<div class="kt-login__lhs" [class.wallet-bg]="isWalletApp">
  <div class="kt-login__lhs-topWave">
    <img
      [src]="
        isWalletApp
          ? '/assets/media/login/walletTopWave.png'
          : '/assets/media/login/topWave.png'
      "
      alt=""
    />
  </div>
  <div class="kt-login__innerContainer">
    <div class="kt-login__lhsBodyContainer">
      <div class="kt-login__linearWave">
        <div class="kt-login__icons">
          <kt-sliders [currentSlider]="currentSlider"></kt-sliders>
        </div>
        <div class="kt-login__labelWrapper">
          <img
            [src]="
              isWalletApp
                ? '/assets/media/login/walletLinearWave.png'
                : '/assets/media/login/linearWave.png'
            "
            alt=""
          />
          <div
            class="kt-login__label"
            [class.wallet-blue]="isWalletApp"
            i18n="@@loginLhs.sliderLabel"
          >
            ANOTHER WAY TO THINK CARGO
          </div>
          <div class="kt-login__controller">
            <span
              class="kt-login__point"
              [class.wallet-blue]="isWalletApp"
              [class.activePoint1]="currentSlider === 1"
              [class.active]="currentSlider === 1"
              [class.grow]="currentSlider === 3"
              (click)="handleController(1)"
            ></span>
            <span
              class="kt-login__point"
              [class.wallet-blue]="isWalletApp"
              [class.activePoint2]="currentSlider === 2"
              [class.active]="currentSlider === 2"
              (click)="handleController(2)"
            ></span>
            <span
              class="kt-login__point"
              [class.wallet-blue]="isWalletApp"
              [class.activePoint3]="currentSlider === 3"
              [class.active]="currentSlider === 3"
              (click)="handleController(3)"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <div class="kt-login__laptop">
      <img src="/assets/media/login/laptop.png" alt="" />
      <span class="kt-login__laptopScreenContainer">
        <img
          class="kt-login__laptopScreen"
          [class.activeIcon1]="currentSlider === 1"
          [class.fadeIn]="currentSlider === 3"
          [attr.src]="getLaptopScreen1(1)"
          loading="lazy"
          alt=""
        />
      </span>
      <span class="kt-login__laptopScreenContainer">
        <img
          class="kt-login__laptopScreen"
          loading="lazy"
          [class.activeIcon2]="currentSlider === 2"
          [attr.src]="getLaptopScreen1(2)"
          alt=""
        />
      </span>
      <span class="kt-login__laptopScreenContainer">
        <img
          class="kt-login__laptopScreen"
          [class.activeIcon3]="currentSlider === 3"
          loading="lazy"
          [attr.src]="getLaptopScreen1(3)"
          alt=""
        />
      </span>
    </div>
  </div>
  <div class="kt-login__lhs-bottomWave">
    <img
      [src]="
        isWalletApp
          ? '/assets/media/login/walletBottomWave.png'
          : '/assets/media/login/bottomWave.png'
      "
      alt=""
    />
  </div>
</div>
