import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ "name": "numberToKMBUnit" })
export class NumberUnitPipe implements PipeTransform {
  transform (value: any): any {
    value = Math.floor(value)?.toFixed(0);
    const si = [
      { "value": 1, "symbol": "" },
      { "value": 1e3, "symbol": "k" },
      { "value": 1e6, "symbol": "M" },
      { "value": 1e9, "symbol": "B" },
      { "value": 1e12, "symbol": "T" },
      { "value": 1e15, "symbol": "P" },
      { "value": 1e18, "symbol": "E" },
    ];
    let postFix = "";
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (value >= si[i].value) {
        break;
      }
    }
    if (i < 1) {
      postFix = "";
    } else if (i < 2) {
      if (+value < 100000) {
        const selectedPoints = value.slice(-3)[0];
        if (+selectedPoints > 0) {
          postFix = "." + selectedPoints;
        }
      }
    } else if (i < 3) {
      const selectedPoints = value.slice(-6).slice(0, 2);
      if (+selectedPoints > 0) {
        postFix = "." + selectedPoints;
      }
    }
    return (
      Math.floor(value / si[i].value)
        .toFixed(0)
        .replace(rx, "$1") +
      postFix +
      si[i].symbol
    );
  }
}
