import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  "selector": "kt-new-popup",
  "templateUrl": "./new-popup.component.html",
  "styleUrls": ["./new-popup.component.scss"],
})
export class NewPopupComponent {
  @Output() onClose = new EventEmitter();
}
