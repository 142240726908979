// Angular
import { Pipe, PipeTransform } from "@angular/core";
import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";

@Pipe({
  "name": "ngbTime",
})
export class NgbTimeFormatPipe implements PipeTransform {
  transform (date: string | Date): NgbTimeStruct {
    if (date instanceof Date) {
      date = moment(date).format();
    }
    const [ time] = date?.split("T") ?? [],
     [hours, minutes] = time?.split(":") ?? [];
    return {
      "hour": parseInt(hours),
      "minute": parseInt(minutes),
      "second": 0,
    };
  }
}
