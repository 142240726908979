<div class="kt-login__container">
  <kt-login-lhs class="kt-login__lhsContainer"></kt-login-lhs>
  <div class="kt-login__rhs">
    <div class="kt-login__head">
      <kt-language-selector
        *ngIf="features.i18n"
        class="language-selector"
        [isLoginPage]="true"
        [isWalletApp]="isCargoWallet"
      >
      </kt-language-selector>
    </div>
    <div class="kt-login__body" *ngIf="!requireOTP" @myInsertRemoveTrigger>
      <div class="kt-login__form login">
        <div class="kt-login__title">
          <div
            class="kt-login__logo"
            [class.other-brand]="otherBrand"
            [class.wallet-app-logo]="isCargoWallet"
            (click)="openSite()"
            [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')' }"
          ></div>
        </div>
        <form
          class="kt-form"
          [formGroup]="loginForm"
          *ngIf="loginForm"
          autocomplete="off"
          [class.wallet-login-form]="isCargoWallet"
        >
          <div class="form-group">
            <mat-label i18n="@@global.email" class="text-uppercase"
              >Email</mat-label
            >
            <kt-custom-input
              id="email"
              formControlName="email"
              type="email"
              placeholder=""
              autocomplete="off"
              [hasError]="
                isControlHasError('email', '', true) ||
                !!unknownUserMessage ||
                !!wrongPasswordMessage
              "
              [otherErrorMessage]="unknownUserMessage"
              [linkMessage]="isCargoMart ? linkMessage : null"
              linkUrl="/register"
              [isWalletApp]="isCargoWallet"
            ></kt-custom-input>
          </div>
          <div
            class="form-group"
            [class.hide-password]="hideOtherLoginFields || showOnlySso"
          >
            <mat-label i18n="@@global.password" class="text-uppercase"
              >Password</mat-label
            >
            <kt-custom-input
              id="password"
              formControlName="password"
              [type]="isShowPassword ? 'text' : 'password'"
              placeholder=""
              autocomplete="off"
              [hasError]="
                isControlHasError('password', '', true) ||
                !!wrongPasswordMessage
              "
              [otherErrorMessage]="wrongPasswordMessage"
              [isWalletApp]="isCargoWallet"
            >
              <span
                class="kt-form__eye"
                [class.activeEye]="isShowPassword"
                icon
                (click)="toggleEye()"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 4.25C8.30768 4.25 5.62703 5.5736 3.86629 7.1989C2.99041 8.0074 2.34365 8.88909 1.91294 9.71605C1.48923 10.5296 1.25 11.3384 1.25 12C1.25 12.6616 1.48923 13.4704 1.91294 14.284C2.34365 15.1109 2.99041 15.9926 3.86629 16.8011C5.62703 18.4264 8.30768 19.75 12 19.75C15.6923 19.75 18.373 18.4264 20.1337 16.8011C21.0096 15.9926 21.6564 15.1109 22.0871 14.284C22.5108 13.4704 22.75 12.6616 22.75 12C22.75 11.3384 22.5108 10.5296 22.0871 9.71605C21.6564 8.88909 21.0096 8.0074 20.1337 7.1989C18.373 5.5736 15.6923 4.25 12 4.25ZM11.9999 9.75003C10.7572 9.75003 9.74988 10.7574 9.74988 12C9.74988 13.2427 10.7572 14.25 11.9999 14.25C13.2425 14.25 14.2499 13.2427 14.2499 12C14.2499 10.7574 13.2425 9.75003 11.9999 9.75003ZM8.24988 12C8.24988 9.92896 9.92881 8.25003 11.9999 8.25003C14.0709 8.25003 15.7499 9.92896 15.7499 12C15.7499 14.0711 14.0709 15.75 11.9999 15.75C9.92881 15.75 8.24988 14.0711 8.24988 12Z"
                  />
                </svg> </span
            ></kt-custom-input>
          </div>
          <div
            class="kt-login__optionsWrapper"
            *ngIf="!hideOtherLoginFields && !showOnlySso"
          >
            <kt-checkbox
              [(ngModel)]="rememberLogin"
              [ngModelOptions]="{ standalone: true }"
              [label]="rememberMe"
              [isLoginPage]="true"
              [isWalletApp]="isCargoWallet"
            ></kt-checkbox>
            <a
              href="javascript:;"
              routerLink="/auth/forgot-password"
              class="kt-link kt-login__link-forgot"
              i18n="@@login.forgot-password"
            >
              FORGOT PASSWORD?
            </a>
          </div>
          <div
            class="kt-login__actions"
            *ngIf="!showOnlySso"
            [class.only-log-in-btn]="hideOtherLoginFields"
          >
            <button
              (click)="submit()"
              [disabled]="banDeadline != null || loginDisabled || loading"
              [ngClass]="{
                'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                  loading,
              }"
              id="kt_login_signin_submit"
              class="btn btn-primary kt-login__btn-primary d-flex align-items-center justify-content-center"
              [class.wallet-blue]="isCargoWallet"
              i18n="@@global.button-login-with-cargoai"
            >
              LOG IN with CargoAi
            </button>
          </div>
          <div
            class="login-cargoai-apps"
            [class.wallet-black]="isCargoWallet"
            *ngIf="!showOnlySso"
          >
            Login with the same credentials as your other CargoAi apps
          </div>
          <div
            class="divider d-flex w-100 align-items-center"
            *ngIf="
              (isCargoAdmin || isCargoWallet) &&
              !hideOtherLoginFields &&
              !showOnlySso &&
              showSso
            "
          >
            <div class="border"></div>
            <span i18n="@@global.or">Or</span>
            <div class="border"></div>
          </div>
          <div
            class="kt-login__actions"
            *ngIf="
              (isCargoAdmin || isCargoWallet) &&
              !hideOtherLoginFields &&
              showSso
            "
          >
            <button
              (click)="submitMsftLogin()"
              [disabled]="banDeadline != null || loading"
              [ngClass]="{
                'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                  loading,
              }"
              id="login_with_msft"
              class="btn btn-primary kt-login__btn-sso d-flex align-items-center justify-content-center"
              i18n="@@global.button-login-with-msft"
            >
              <img [src]="SsoSrc" style="padding-right: 10px" />
              <span>{{ ssoProvider }}</span>
            </button>
          </div>
          <div class="kt-login__signUp-wrapper" *ngIf="registerUrl">
            <span
              class="kt-login__signup-label"
              i18n="@@login.no-account-yet"
              [class.wallet-black]="isCargoWallet"
              >Don't have an account yet?</span
            >&nbsp;&nbsp;
            <a
              href="javascript:;"
              [routerLink]="registerUrl"
              [queryParams]="{ prefillRegisterEmail: prefillRegisterEmail }"
              class="kt-link kt-login__signup-link"
              i18n="@@global.sign-up"
              >Sign Up</a
            >
          </div>

          <!-- <div
            *ngIf="isWalletApp && showLoginAsGuestOption"
            class="d-flex justify-content-center mt-2 flex-column justify-content-center align-items-center"
          >
            <div i18n="@@global.or" class="or-label">OR</div>
            <div class="kt-login__actions login-guest">
              <button
                [disabled]="banDeadline != null || loading"
                id="kt_login_guest_submit"
                class="btn btn-primary kt-login__btn-primary d-flex align-items-center justify-content-center wallet-blue"
                i18n="@@global.button-login-as-guest"
                (click)="loginAsGuest()"
              >
                LOG IN AS A GUEST
              </button>
            </div>
          </div> -->
          <h5
            *ngIf="banDeadline"
            class="text-danger"
            i18n="@@login.text-device-banned"
          >
            Your device is banned until
            {{
              banDeadline.toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })
            }}
            because of trying wrong credentials more than 5 times. <br />Please
            reload later to try again.
          </h5>
        </form>
      </div>
    </div>
    <div class="brand-container" *ngIf="otherBrand || isCargoWallet">
      <span i18n="@@global.powered-by">Powered by</span>
      <div
        class="cargoai-icon"
        [ngStyle]="{
          'background-image':
            'url(' + '/assets/media/logos/cargoai-without-logo.png' + ')',
          'text-align': 'center',
        }"
      ></div>
    </div>
    <kt-new-otp-container
      [class.kt-login__otpContainer]="requireOTP"
      [isShowOTP]="requireOTP"
      [otpStatus]="OTPStatus"
      [errorMessage]=""
      [title]="title"
      [expiredOTPMessage]="expiredOTPMessage"
      [successMessage]="OTPSuccessMessage"
      (onReceiveOTP)="checkOTP($event)"
      (onRequestNewOTP)="resendOTP()"
      (goBack)="hideOTP()"
    ></kt-new-otp-container>
  </div>
</div>
