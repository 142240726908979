import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { UserActions, UserActionTypes } from "../_actions/user.actions";
import { AppUser } from "../../_models/app-user.model";
import { QueryParamsModel } from "../../_base/crud/models/query-models/query-params.model";

export interface UsersState extends EntityState<AppUser> {
  listLoading: boolean;
  actionsloading: boolean;
  totalCount: number;
  lastCreatedUserId: number;
  lastQuery: QueryParamsModel;
  showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AppUser> = createEntityAdapter<AppUser>();

export const initialUsersState: UsersState = adapter.getInitialState({
  "listLoading": false,
  "actionsloading": false,
  "totalCount": 0,
  "lastQuery": new QueryParamsModel({}),
  "lastCreatedUserId": undefined,
  "showInitWaitingMessage": true,
});

export function usersReducer (
  state = initialUsersState,
  action: UserActions
): UsersState {
  switch (action.type) {
    case UserActionTypes.UsersPageToggleLoading:
      return {
        ...state,
        "listLoading": action.payload.isLoading,
        "lastCreatedUserId": undefined,
      };
    case UserActionTypes.UsersActionToggleLoading:
      return {
        ...state,
        "actionsloading": action.payload.isLoading,
      };
    case UserActionTypes.UserOnServerCreated:
      return {
        ...state,
      };
    case UserActionTypes.UserCreated:
      return adapter.addOne(action.payload.user, {
        ...state,
        "lastCreatedUserId": action.payload.user.id,
      });
    case UserActionTypes.UserUpdated:
      return adapter.updateOne(action.payload.partialUser, state);
    case UserActionTypes.UserDeleted:
      return adapter.removeOne(action.payload.id, state);
    case UserActionTypes.UsersPageCancelled:
      return {
        ...state,
        "listLoading": false,
        "lastQuery": new QueryParamsModel({}),
      };
    case UserActionTypes.UsersPageLoaded: {
      return adapter.addMany(action.payload.users, {
        ...initialUsersState,
        "totalCount": action.payload.totalCount,
        "lastQuery": action.payload.page,
        "listLoading": false,
        "showInitWaitingMessage": false,
      });
    }
    default:
      return state;
  }
}


export const { selectAll } =
  adapter.getSelectors();
