import {
  IService,
  IServiceConfigField,
  IServiceFieldOption,
} from '@cai-services';

export class Service implements IService {
  configType?: string;
  id?: string;
  provider?: string;
  version?: number = 0;
  active?: boolean = false;
  apikeys?: string[];
  doublecalls?: boolean;
  iataCassOptional?: boolean;
  allowInternalRates?: boolean;
  disableAllEmails?: boolean;
  equote?: any;
  markets?: any;
  products?: any;
  customers?: any;
  airlines?: any[];
  routes?: any;
  originCountries?: any;
  originAirports?: any;
  destinationCountries?: any;
  destinationAirports?: any;
  feature?: boolean;
  isOnlyInternalRequest?: boolean;
  markupFlat?: number;
  markupPercent?: number;
  subdomain?: string;
}
export class ServiceFieldOption implements IServiceFieldOption {
  key: string;
  type: ProviderConfigTypeEnum;
}
export enum ProviderConfigTypeEnum {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  JSON_ARRAY = 'JSON_ARRAY',
  JSON_MAP = 'JSON_MAP',
  BOOL = 'BOOL',
}
export class ServiceConfigField implements IServiceConfigField {
  [key: string]: {
    valueInJSON: string;
    valueType: string;
  };
}
