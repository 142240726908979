import { IBlobFile } from "@cai-services";

export class BlobFile implements IBlobFile {
  fileName: string;
  fileType: string;
  dataURI: string;

  constructor (fileName: string, fileType: string, dataURI: string) {
    this.fileName = fileName;
    this.fileType = fileType;
    this.dataURI = dataURI;
  }
}
